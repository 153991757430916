﻿@import (reference) "../_imports";

.slidepage, .tutorial__simple {
  background-color: var(--tutorial-primary);
}

.slidepage__content {
  overflow: hidden;

  &--mobile {
    .tns-ovh {
      overflow: visible !important;
    }
  }

  &--desktop {
    display: none;
  }

  @media @md {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
      padding-top: 5rem;
    }
  }

  .slidepage__slider {
    display: flex;
    flex-direction: column;

    @media @md {
      flex-direction: row;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background: @ds-white;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        opacity: 0;
        transition-duration: 0.2s;
      }

      &--loading {
        &::before {
          opacity: 1;
        }
      }

    }

    .slidepage__steps {
      transition-duration: 0.3s;
      transform: translateX(3rem);
      display: flex;
      position: relative;

      @media @xs {
        margin-left: auto;
        margin-right: auto;
      }
      
      @media @md {
        width: initial;
        transform: none;
        display: flex !important; // to override TinySlider's 'display: inline-block;'
        justify-content: center;
      }

      &--fade {
        opacity: 0.5;
        transform: scale(0.8);
      }

      .slidepage__step {
        display: inline-block;
        background: @ds-white;
        box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
        height: 37.2rem;
        transition-duration: 0.4s;
        margin: 4.5rem 0 2rem;
        transform-origin: 0 center;

        @media @md {
          flex: 0 0 26.4rem;
          width: 21.9rem;
          height: 34.8rem;

          &:first-child {
            margin-left: 2rem;
          }
        }

        @media @xl {
          height: 48rem;
          flex: 0 0 30rem;
        }

        &--selected {
          transform: rotate(0) !important;
        }

        &.slidepage__step-description {
          margin-right: 2rem;
          transform-origin: right bottom;
          transition-delay: 0.15s;
          transform: rotate(-5deg);
          border-radius: 2rem;
          width: 30rem;

          @media @md {
            height: 38.2rem;
            width: 26.4rem;
            transform: none;
            z-index: 1;
            position: relative;
            left: 1rem;
          }

          @media @lg {
            width: 29rem;
            height: 47.6rem;
            margin-right: 0;
            left: 0;
          }

          .slidepage__step-text-wrapper {
            width: 65%;
            margin: 2rem auto 0 auto;

            .slidepage__step-header {
              margin-top: 4rem;
              margin-bottom: 2rem;
              color: var(--tutorial-primary);
              font-size: 2rem;
              font-family: @font-impact;
              text-align: center;
              line-height: 2rem;
              font-weight: 800;
              font-style: italic;
            }

            .slidepage__step-text {
              font-size: 1.2rem;
              line-height: 2.2rem;

              @media @md {
                font-size: 1.4rem;
              }
            }
          }

          .slidepage__step-help-wrapper {
            position: absolute;
            width: 75%;
            top: 85%;

            .slidepage__step-help-circle {
              border-radius: 50%;
              width: 1.5rem;
              height: 1.5rem;
              background-color: var(--tutorial-primary);
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 105%;
              bottom: 50%;

              &::after {
                content: 'i';
                color: @ds-white;
                font-size: 1rem;
                font-weight: bold;
              }
            }

            .slidepage__step-help-text {
              font-size: 1.2rem;
              font-weight: bold;
              color: var(--tutorial-primary);
              display: flex;
              flex-direction: column;
            }
          }

          .slidepage__step-number {
            background-color: var(--tutorial-primary);
            padding: 0.7rem;
            width: 4rem;
            height: 4rem;
            font-weight: 700;
            font-size: 1.5rem;
            top: 3.5rem;
            left: -2rem;
            color: white;
            position: absolute;
            display: grid;
            justify-items: center;
            border-radius: 0.8rem;
          }
        }
      }

      .slidepage__step.slidepage__step-illustration {
        overflow: hidden;
        border-radius: 2rem;
        transform-origin: left bottom;
        transform: rotate(5deg);
        transition-delay: 0.1s;

        @media @xs {
          transform: none;
        }
      }

      iframe.slidepage__step.slidepage__step-illustration {
        
        @media @md {
          transform: none;
          flex: 0 0 65.5rem;
          position: relative;
          left: -3rem;
          top: -3rem;
          padding: 0;
        }
        
        @media @lg {
          flex: 0 0 67rem;
        }
        
        @media @xl {
          left: -3.6rem;
          flex: 0 0 90rem;
          padding: 0;
        }
      }
    }

    .slidepage__radio-buttons {
      align-self: center;
      display: flex;

      .slidepage__radio {
        border-radius: 50%;
        border: @ds-black solid 0.2rem;
        width: 1rem;
        height: 1rem;

        &:first-child {
          margin-right: 1rem;
        }

        &.tns-nav-active {
          background-color: @ds-black;
        }
      }
    }
  }

  &--desktop {
    .slidepage__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      max-width: 132rem;
      margin: auto;
      position: relative;

      @media @md {
        top: -7rem;
        right: 6rem;
      }

      @media @lg {
        top: -4rem;
        right: 8.5rem;
      }

      @media @xxl {
        //width: 86rem;
      }

      &:focus {
        outline: none;
      }

      .slidepage__arrow {
        width: 6.5rem;
        height: 4.5rem;
        background: var(--tutorial-primary);
        border-radius: 1rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &--hide {
          opacity: 0;
          pointer-events: none;
        }

        &:focus {
          outline: none;
        }

        &::after {
          content: '';
          position: absolute;
          width: 1.3rem;
          height: 1.3rem;
          border-bottom: @ds-white 0.3rem solid;
          border-left: @ds-white 0.3rem solid;
        }

        &--next {
          &::after {
            transform: rotate(-135deg);
          }
        }

        &--prev {
          margin-right: 2rem;

          &::after {
            transform: rotate(45deg);
          }
        }
      }

      .slidepage__slide-counter {
        display: flex;
        align-items: center;
        margin-right: 0.8rem;
        font-weight: 800;
        font-size: 1.4rem;

        .slidepage__current-slide {
          background: var(--tutorial-primary);
          color: @ds-white;
          border-radius: 0.8rem;
          font-size: 1.6rem;
        }

        & > div {
          padding: 0.4rem 1.2rem;

          &:first-child {
            margin-right: 1rem;
          }
        }

        .slidepage__separator {
          padding: 0.4rem 0;
        }
      }
    }
  }
}

.slidepage__action-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25.5rem;
  height: 5rem;
  background-color: var(--tutorial-cta);
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
  margin: 4rem auto;

  @media @md {
    margin: 4rem auto;
  }

  .slidepage__action-button {
    font-family: @font-impact;
    font-style: italic;
    font-size: 2rem;
    color: @ds-black;
  }
}

.slidepage__related-help {
  background-color: var(--tutorial-secondary);
  max-width: 144rem;
  margin: auto;
  padding-bottom: 1rem;

  @media @md {
    padding-bottom: 4rem;
  }

  .slidepage__related-help-title {
    padding-top: 2.9rem;
    font-weight: 800;
    font-family: @font-impact;
    font-size: 2.5rem;
    font-style: italic;
    text-align: center;
    line-height: 4rem;
    color: @color-white;
    margin-bottom: 3.5rem;

    @media @md {
      font-size: 3rem;
      text-align: left;
      margin-left: 6rem;
    }

    @media @lg {
      margin: 0 auto;
      padding-top: 4rem;
      padding-bottom: 2rem;
      font-size: 3.6rem;
      width: fit-content;
    }
  }

  .slidepage__related-help-wrapper {
    position: relative;
    background-color: var(--tutorial-secondary);

    @media @lg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      max-width: 75%;
    }

    .slidepage__related-help-wrapper-block {
      border-radius: 2rem;
      background-color: @ds-white;
      padding: 1.5rem;
      margin: 0 2.3rem 1.7rem;
      display: grid;
      grid-template-columns: 5.5rem auto 2.5rem;
      align-items: center;

      &:not(:last-child) {
        margin-right: 2rem;
      }

      @media @lg {
        margin: 0;
        width: 31rem;
        position: relative;

        &:hover {
          background-color: var(--tutorial-light-grey);
        }
      }

      .slidepage__related-help-image-wrapper {
        width: 3.5rem;
        height: 3.5rem;
        background-color: var(--tutorial-primary);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media @md {
          width: 5.5rem;
          height: 5.5rem;
        }

        @media @lg {
          width: 6.5rem;
          height: 6.5rem;
        }

        .slidepage__related-help-image {
          width: 45%;
          height: 45%;
        }
      }

      .slidepage__related-help-text-wrapper {

        .slidepage__related-help-element-title {
          color: @ds-black;
          font-size: 1.4rem;
          font-weight: bold;
          margin-left: 1.9rem;
          line-height: 1.5rem;

          @media @md {
            font-size: 1.8rem;
          }
        }

        .slidepage__related-help-element-text {
          color: @ds-black;
          font-size: 1.2rem;
          margin-left: 1.9rem;
          line-height: 1.5rem;

          @media @md {
            font-size: 1.6rem;
          }
        }
      }

      .slidepage__related-help-arrow {
        content: "";
        width: 1.3rem;
        height: 1.3rem;
        border-bottom: 0.3rem solid @ds-black;
        border-right: 0.3rem solid @ds-black;
        transform: rotate(-45deg);
        position: absolute;
        right: 5rem;
        transition-duration: 0.2s;

        @media @md {
          width: 1.5rem;
          height: 1.5rem;
          right: 6rem;
        }

        @media @lg {
          position: absolute;
          right: 2.5rem;
        }
      }
    }
  }
}
