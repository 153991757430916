/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.confirm-page * {
  box-sizing: border-box;
}
.confirm-page .confirm-page__extra-campaigns {
  width: calc(100% - 4rem);
  max-width: 49rem;
}
@media screen and (min-width: 1024px) {
  .confirm-page .confirm-page__extra-campaigns {
    max-width: 54rem;
  }
}
.confirm-page .confirm-page__extra-campaigns .confirm-page__collapsible {
  max-width: 100%;
  width: 100%;
}
.confirm-page .confirm-page__extra-campaigns .confirm-page__collapsible .confirm-page__collapsible-top-row-text {
  text-align: right;
  color: #c50005;
  flex: 0;
}
.confirm-page .confirm-page__extra-campaigns .confirm-page__collapsible .confirm-page__collapsible-top-row-highlight-text {
  flex: 1;
}
.confirm-page .confirm-page__extra-campaigns .confirm-page__collapsible .confirm-page__collapsible-top-row-read-more {
  font-size: 1.2rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: underline;
}
.confirm-page .confirm-page__extra-campaigns .confirm-page__collapsible-top-row-icon {
  color: #c50005;
}
.confirm-page .confirm-page__extra-campaigns-headline {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  padding-top: 2.2rem;
}
.confirm-page__wrapper {
  background: white;
  padding: 2rem 0 0;
  box-sizing: border-box;
}
.confirm-page__wrapper .confirm-page__content {
  background-color: transparent;
  padding: 0;
}
@media screen and (min-width: 1024px) {
  .confirm-page__wrapper {
    padding-top: 4rem;
  }
}
.confirm-page__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  padding: 2rem 0 0;
}
.confirm-page__title {
  margin: 0 2rem 2rem;
  font-size: 2.3rem;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .confirm-page__title {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .confirm-page__title {
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }
}
.confirm-page__collapsible {
  width: calc(100% - 4rem);
  max-width: 49rem;
  border-bottom: 0.2rem dashed #dadbdd;
  font-size: 1.6rem;
  line-height: 1.2;
}
@media screen and (min-width: 1024px) {
  .confirm-page__collapsible {
    max-width: 54rem;
  }
}
.confirm-page__collapsible-top {
  position: relative;
  height: 5.8rem;
  display: flex;
  align-items: center;
}
.confirm-page__collapsible--can-open .confirm-page__collapsible-top {
  cursor: pointer;
}
.confirm-page__collapsible--can-open .confirm-page__collapsible-top::after {
  content: "";
  position: absolute;
  z-index: 1;
  transition: transform 100ms ease-out;
  height: 1.6rem;
  width: 1.6rem;
  right: 0;
  top: 2.1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNDA0MyAxLjk2NzlMNy4yNzAwOCA4LjE4NDUyQzcuNjY5MTQgOC42MDc0NCA4LjM0MzUxIDguNjAyMDEgOC43MzU3IDguMTcyNzJMMTQuNDA0MyAxLjk2NzkiIHN0cm9rZT0iIzIyMjIyMiIgc3Ryb2tlLXdpZHRoPSIyLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
}
.confirm-page__collapsible--show .confirm-page__collapsible-top::after {
  transform: rotate(0.5turn);
}
.confirm-page__collapsible-bottom {
  transition: transform 200ms cubic-bezier(0, 1.7, 0.3, 1), padding-top 200ms cubic-bezier(0, 1.7, 0.3, 1);
  transform: translateY(-1rem);
  padding: 0;
  height: 0;
  overflow: hidden;
}
.confirm-page__collapsible--show .confirm-page__collapsible-bottom {
  padding: 0 0 2rem;
  height: auto;
  transform: translateY(0);
}
.confirm-page__collapsible-top-row {
  display: flex;
  font-weight: bold;
  align-items: center;
  width: 100%;
}
.confirm-page__collapsible-action-cell {
  flex: 1;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.confirm-page__action-button {
  font-size: 1.4rem;
  padding: 0.3rem 1rem;
  border: 1px solid;
  border-radius: 1.6rem;
  cursor: pointer;
  transition: scale 100ms ease-out;
}
.confirm-page__action-button:hover {
  scale: 1.02;
}
.confirm-page__edit-button-wrapper {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 2rem;
  right: -2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: scale 100ms ease-out;
}
@media screen and (min-width: 768px) {
  .confirm-page__edit-button-wrapper {
    right: -3.2rem;
  }
}
.confirm-page__edit-button-wrapper:hover {
  scale: 1.04;
}
.confirm-page__edit-button {
  width: 1.8rem;
  height: 1.8rem;
}
.confirm-page__collapsible-top-row-icon {
  flex: 0 0 5rem;
  margin-bottom: -0.2rem;
}
@media screen and (min-width: 768px) {
  .confirm-page__collapsible-top-row-icon {
    flex: 0 0 6rem;
  }
}
.confirm-page__collapsible-top--disabled .confirm-page__collapsible-top-row-icon {
  opacity: 0.6;
}
.confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-draw {
  width: 2.7rem;
  height: 2.6rem;
}
.confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-joker {
  width: 3.6rem;
  height: 2.1rem;
}
.confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-rows {
  width: 2.7rem;
  height: 3.5rem;
}
.confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-calendar {
  width: 2.7rem;
  height: 3.1rem;
}
.confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-stake-per-row {
  width: 3.1rem;
  height: 2.6rem;
}
.confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign {
  width: 3.2rem;
  height: 3.2rem;
  color: currentColor;
}
.region-eurojackpot .confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign {
  color: #bb7f36;
}
.region-keno .confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign {
  color: #00a5eb;
}
.region-lotto .confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign {
  color: #c50005;
}
.region-vikinglotto .confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign {
  color: #0000ff;
}
.confirm-page__collapsible-top-row-highlight-text {
  flex: 0 0 11rem;
  padding-right: 1rem;
  color: #000000;
}
.confirm-page__collapsible-top--disabled .confirm-page__collapsible-top-row-highlight-text {
  opacity: 0.6;
}
.confirm-page__collapsible-top-row-highlight-text--full-width {
  flex: auto;
}
.region-allornothing .confirm-page__collapsible-top-row-highlight-text {
  color: #312F51;
}
.region-eurojackpot .confirm-page__collapsible-top-row-highlight-text {
  color: #bb7f36;
}
.region-keno .confirm-page__collapsible-top-row-highlight-text {
  color: #00a5eb;
}
.region-lotto .confirm-page__collapsible-top-row-highlight-text {
  color: #c50005;
}
.region-vikinglotto .confirm-page__collapsible-top-row-highlight-text {
  color: #0000ff;
}
@media screen and (min-width: 768px) {
  .confirm-page__collapsible-top-row-highlight-text {
    flex: 0 0 18rem;
  }
  .confirm-page__collapsible-top-row-highlight-text--full-width {
    flex: auto;
  }
}
.confirm-page__collapsible-top-row-text {
  flex: 1;
}
.confirm-page__collapsible--can-open .confirm-page__collapsible-top-row-text {
  padding-right: 2rem;
}
.confirm-page__table-header {
  font-size: 1.6rem;
  padding: 0.2rem 0 0.2rem;
  margin: 0 auto;
  min-height: 3rem;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .confirm-page__table-header {
    max-width: 37rem;
  }
}
@media screen and (min-width: 1024px) {
  .confirm-page__table-header {
    max-width: 42rem;
  }
}
.confirm-page__table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .confirm-page__table {
    max-width: 37rem;
    margin-left: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .confirm-page__table {
    max-width: 42rem;
  }
}
.confirm-page__table .confirm-page__table-row {
  display: flex;
}
.confirm-page__table .confirm-page__table-row--has-joker {
  background-repeat: no-repeat;
  background-size: 3rem 3rem;
  background-position: calc(100% - 1rem) center;
}
.region-eurojackpot .confirm-page__table .confirm-page__table-row--has-joker {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#eurojackpot-joker_use");
}
.region-lotto .confirm-page__table .confirm-page__table-row--has-joker {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#lotto-joker_use");
}
.region-vikinglotto .confirm-page__table .confirm-page__table-row--has-joker {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-joker_use");
}
.confirm-page__table .confirm-page__table-row:nth-child(odd) {
  background-color: #dadbdd;
}
.confirm-page__table .confirm-page__table-cell {
  padding: 0.5rem 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 3rem;
  flex: 1;
}
.confirm-page__table .confirm-page__table-cell--index {
  flex: 0 0 8rem;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
.confirm-page__table .confirm-page__table-cell--center {
  text-align: center;
  justify-content: center;
}
.confirm-page__table .confirm-page__table-cell-icon-delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.confirm-page__table .confirm-page__table-cell-icon-delete svg {
  width: 1rem;
  height: 1rem;
}
.confirm-page__total {
  width: calc(100% - 4rem);
  height: 5.8rem;
  max-width: 49rem;
  border-bottom: 0.2rem solid #000000;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  font-weight: 800;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1024px) {
  .confirm-page__total {
    max-width: 54rem;
  }
}
.confirm-page__total-label {
  flex: 0 0 16rem;
  padding-left: 5rem;
}
@media screen and (min-width: 768px) {
  .confirm-page__total-label {
    flex: 0 0 24rem;
    padding-left: 6rem;
  }
}
.confirm-page__terms {
  max-width: 49rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #000000;
  text-align: center;
  margin-bottom: 2rem;
}
@media screen and (min-width: 1024px) {
  .confirm-page__terms {
    margin-bottom: 4rem;
  }
}
.confirm-page__terms a {
  color: #000000;
  font-weight: bold;
}
.confirm-page__go-to-payment-subheader {
  font-size: 1.6rem;
  padding: 2rem 0;
  color: #000000;
  font-weight: 600;
}
.confirm-page__go-to-payment-subheader > span {
  color: #000000;
  font-weight: 700;
}
@keyframes info-message-animation {
  0% {
    transform: scale(1) rotate(0);
  }
  10% {
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
.confirm-page__crossed {
  text-decoration: line-through;
}
.confirm-page__info-message {
  background-color: #ffce51;
  border: 1px solid #FEB700;
  padding: 1rem 2rem;
  margin: 1rem 0 3rem;
  width: calc(100% - 4rem);
  max-width: 49rem;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #000000;
  animation: info-message-animation 0.8s forwards;
}
@media screen and (min-width: 1024px) {
  .confirm-page__info-message {
    max-width: 54rem;
  }
}
