@import url("../Mixins/_mixins.less");

.eurojackpot-receipt,
.eurojackpot-no-open-draw {
  .countdown {
    width: 100%;
    max-width: 88vw;
    text-align: center;
    margin: auto;
    padding: 4vw;

    @media (min-width: @eurojackpot-desktop-width) {
      max-width: 96rem;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    .countdown-headline {
      font-size: 3vw;
      font-weight: normal;
      color: @eurojackpot-white;

      @media (min-width: @eurojackpot-desktop-width) {
        font-size: 2rem;
        line-height: 0.9;
        margin-top: 0;
      }
    }

    .number-container {
      display: inline-block;
      margin: 0 3vw;
      color: #fff;

      @media (min-width: @eurojackpot-desktop-width) {
        margin: 0 1.5rem;
      }

      .number {
        font-size: 7.47vw;
        font-weight: normal;
        margin-right: 1vw;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 3.8rem;
          line-height: normal;
          margin-right: 1rem;
        }
      }

      .time-denominator {
        font-size: 5.4vw;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.eurojackpot-no-open-draw {
  .countdown {
    .countdown-headline {
      color: @eurojackpot-black;
    }

    .number-container {
      color: @eurojackpot-black;
    }
  }
}
