.eurojackpot-classic-game .statistics-component-for-game-client-wrapper,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper {
  background-color: #2E3535;
}
.eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper {
  background-color: #1f1f1f;
}
.eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box {
  background-color: #1f1f1f;
}
.eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
  width: 8vw;
  height: 8vw;
  font-size: 4vw;
  line-height: 2.1;
  margin: 1vw;
  color: #000;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#square-shape-dark_grey_use);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
  .eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
  .eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
    line-height: 1.4;
    margin: .5rem;
    padding-top: .9rem;
  }
}
.eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell {
  line-height: 2.3;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-grey.svg);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
  .eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
  .eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell {
    line-height: 1.3;
    padding-top: 1.1rem;
  }
}
.eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell {
  color: #fff;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-gold_with-gradient.svg);
}
.eurojackpot-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected.special .number-cell,
.eurojackpot-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected.special .number-cell,
.eurojackpot-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected.special .number-cell {
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .statistics-component-for-game-client-wrapper {
    margin-bottom: 2.3rem;
  }
}
