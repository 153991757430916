﻿@import (reference) "../_imports";

.tutorial-tips {
  --transition-duration: 0.1s;
  background-color: var(--tutorial-primary);
}

.tutorial-tips__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutorial-tips__content-inner {
  margin-top: 4rem;
  width: 28rem;
}

.tutorial-tips__header {
  font-weight: 800;
  font-style: italic;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;

  .tutorial-tips__popup-trigger {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    border-radius: 50%;
    color: @ds-white;
    background: var(--tutorial-secondary);
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
}

.tutorial-tips__button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  .tutorial-tips__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    width: 13.4rem;
    height: 4.6rem;
    background: var(--tutorial-light-grey);
    filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.25));
    font-weight: 800;
    font-style: italic;
    cursor: pointer;
    transition-duration: var(--transition-duration);

    &--selected {
      background: var(--tutorial-secondary);
      color: white;
    }

    &:first-child {
      margin-right: 2rem;
    }
  }
}

.tutorial-tips__read-more {
  text-align: center;
  margin-bottom: 3.5rem;
}

.tutorial-tips__dropdown {
  width: 28rem;
  min-height: 4.5rem;
  background: var(--tutorial-light-grey);
  filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 800;
  font-style: italic;
  cursor: pointer;
  position: relative;
  margin-bottom: 3.7rem;
  border-radius: 0.5rem;
  transition-duration: var(--transition-duration);

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-bottom: @ds-black solid 0.4rem;
    border-left: @ds-black solid 0.4rem;
    right: 2rem;
    top: 1rem;
    transform: rotate(-45deg);
    transform-origin: 25% 75%;
    transition-duration: var(--transition-duration);
  }

  .tutorial-tips__dropdown-content {
    display: none;
    width: 100%;
    background: @ds-white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &--open {
    background: var(--tutorial-secondary);
    color: @ds-white;

    &::after {
      transform: rotate(135deg);
      border-color: @ds-white;
    }

    .tutorial-tips__dropdown-content {
      display: block;
    }
  }

  &--selected {
    background: var(--tutorial-secondary);
    color: @ds-white;

    &::after {
      border-color: @ds-white;
    }
  }
}

.tutorial-tips__label {
  height: 4.5rem;
  display: grid;
  align-content: center;
}

.tutorial-tips__combination-key {
  color: @ds-black;
  height: 4.5rem;
  display: grid;
  align-content: center;
  text-align: center;
  border-top: #A8A8A8 solid 0.1rem;

  &--selected {
    background: var(--tutorial-primary);
    color: @ds-white;
  }
}

.tutorial-tips__table-wrapper {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
}

.tutorial-tips__table-selectors {
  border-radius: 2rem 2rem 0 0;
  height: 5.7rem;
  background: var(--tutorial-primary);
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  color: @ds-white;
  font-weight: 800;
  font-style: italic;

  @media @sm {
    padding: 0 10rem;
  }

  .tutorial-tips__table-selector {
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      display: none;
      position: absolute;
      width: 100%;
      height: 0.2rem;
      background: white;
      bottom: 0.2rem;
      left: 0;
      border-radius: 1rem;
    }
    
    &--active {
      &::after {
        display: block;
      }
    }
  }
}

.tutorial-tips__table {
  table {
    color: @ds-white;
    text-align: center;
    width: 100%;

    tr {
      height: 4rem;
    }

    tr:nth-child(odd) {
      background: var(--tutorial-tips-table-odd);
    }

    tr:nth-child(even) {
      background: var(--tutorial-tips-table-even);
    }
  }

  &--guarantee {
    table {
      tr > th:last-child,
      tr > td:last-child {
        float: right;
        margin-top: 0.7rem;
        margin-right: 1rem;

        @media @sm {
          margin-right: 9rem;
        }
      }
    }
  }

  .tutorial-tips__table-title {
    padding: 2rem 0;
    font-weight: 800;
    font-style: italic;
  }

  &--description {
    color: @ds-white;
    background: var(--tutorial-tips-table-odd);
    padding: 0 5rem 2rem 5rem;
  }
}

.tutorial-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutorial-overlay__title {
  font-size: 3.5rem;
  margin-bottom: 4rem;
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
}

.tutorial-overlay__text {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 4.5rem;
}

.tutorial-overlay__image {
  max-width: 30rem;
}
