@import url("../../Mixins/_mixins.less");

.region-keno {
  .news-list {

    .section {
      // News list-overview
      .news-heading {
        background-color: @keno-powderblue;
        padding: 12vw 0 24vw;

        @media (min-width: @desktop-width) {
          min-height: 34rem;
          padding: 5rem 0;
        }

        .topspot-headline {
          font-size: 6.6vw;
          font-style: italic;
          text-transform: uppercase;
          margin-bottom: 2vw;

          @media (min-width: @desktop-width) {
            font-size: 4rem;
            margin-bottom: 1rem;
          }
        }

        .topspot-subheadline {
          text-transform: uppercase;
          line-height: 1.2;

          @media (min-width: @desktop-width) {
            margin-top: 2rem;
          }
        }
      }

      .content-wrapper {
        @media (min-width: @desktop-width) {
          margin-top: 0;
        }

        .news-block {
          padding: 2rem 0 4rem 0;
          @media (min-width: @desktop-width) {
            padding: 4rem 0;
          }

          .date {
            color: @numbergames-lightgray;
          }

          a.heading-link {
            .headline {
              text-transform: uppercase;
              color: @keno-royalblue;
              margin: 1.5rem auto;

              @media (min-width: @desktop-width) {
                font-size: 2.5rem;
                line-height: 3rem;
              }
            }
          }

          .btn {
            background-color: @keno-mediumblue;
          }
        }
      }
      // News single item view
      .content-wrapper {

        .news-item-headline {
          color: @keno-royalblue;
        }
      }
    }
  }
}
