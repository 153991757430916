﻿@import url("../../Mixins/_mixins");

.winning-numbers-drawn-balls {
  margin-bottom: 0.3rem;
  display: inline-flex;
  flex-direction: column;

  @media @sm {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.winning-numbers-drawn-balls__section {
  @media @sm {
    flex: 1 1 50%;

    &:last-child:not(:first-child) {
      text-align: right;
    }
  }
}

.winning-numbers-drawn-balls__title {
  flex: 0 0 100%;
  order: 10;
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: bold;
  letter-spacing: 0.04rem;
  color: @numbergames-blue-grey;
  text-transform: uppercase;
  margin-bottom: 0.3rem;

  @media @xxl {
    margin-bottom: 1.7rem;
  }
}

.winning-numbers-drawn-balls__balls {
  overflow: hidden;
  box-sizing: border-box;
  display: inline-flex;
  padding: 0.8rem 0.3rem 0.8rem 0.8rem;
  background: @ds-gray-100;
  height: 5.6rem;
  border-radius: 4rem;
  margin-bottom: 3.7rem;
  transform-origin: left;

  @media @xxl {
    height: 8rem;
    padding-right: 0;
    margin-bottom: 2.9rem;
  }
}

.winning-numbers-drawn-balls__ball {
  position: relative;
  z-index: 0;
  right: -50rem;
  pointer-events: none;
  background-color: @ds-black;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  color: #e2c000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding-bottom: 0.3rem;
  box-sizing: border-box;

  @media @xxl {
    flex: 0 0 6.4rem;
    height: 6.4rem;
    width: 6.4rem;
    margin-right: 0.8rem;
    font-size: 2rem;
  }

  .winning-numbers-drawn-balls__balls--lotto &,
  .winning-numbers-drawn-balls__balls--lotto-bonus & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-lotto_use");
  }

  .winning-numbers-drawn-balls__balls--vikinglotto &,
  .winning-numbers-drawn-balls__balls--vikinglotto-bonus & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-vikinglotto_use");
  }

  .winning-numbers-drawn-balls__balls--eurojackpot &,
  .winning-numbers-drawn-balls__balls--eurojackpot-bonus & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-eurojackpot_use");
  }

  .winning-numbers-drawn-balls__balls--joker & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-joker_use");
  }

  .winning-numbers-drawn-balls--animate & {
    animation: winning-numbers-drawn-balls-bounce 1000ms ease forwards, winning-numbers-drawn-balls-direction 600ms ease-out forwards;
  }

  .winning-numbers-drawn-balls--no-animation & {
    animation: none;
  }


  &:nth-child(1) {
    animation-delay: 0ms;
    z-index: 10;
  }

  &:nth-child(2) {
    animation-delay: 100ms;
    z-index: 9;
  }

  &:nth-child(3) {
    animation-delay: 100ms;
    z-index: 8;
  }

  &:nth-child(4) {
    animation-delay: 70ms;
    z-index: 7;
  }

  &:nth-child(5) {
    animation-delay: 150ms;
    z-index: 6;
  }

  &:nth-child(6) {
    animation-delay: 160ms;
    z-index: 5;
  }

  &:nth-child(7) {
    animation-delay: 130ms;
    z-index: 4;
  }
}

@keyframes winning-numbers-drawn-balls-expand {
  0% {
    transform: scaleX(0%);
  }
  60% {
    transform: scaleX(110%);
  }
  80% {
    transform: scaleX(95%);
  }
  100% {
    width: auto;
    transform: scaleX(100%);
  }
}

@keyframes winning-numbers-drawn-balls-bounce {
  0% {
    opacity: 0;
    transform: translateX(30rem) rotate(70deg);
  }
  60% {
    opacity: 1;
    transform: translateX(-0.5rem) rotate(0deg);
  }
  80% {
    transform: translateX(0.2rem) rotate(-1deg);
  }
  90% {
    transform: translateX(0.1rem) rotate(0deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}


@keyframes winning-numbers-drawn-balls-direction {
  to {
    right: 0;
  }
}
