﻿@import url("../Mixins/_mixins.less");

.multi-teaser-cards{
  position: relative;
  max-width: 114rem;
  width: 100%;
  margin: 0 auto;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 3.2rem 2rem;
  box-sizing: border-box;
  @media @sm {
    padding: 6rem 2rem;
  }

  & *{
    box-sizing: border-box;
  }
  &--plus{
    .multi-teaser-cards__card{
      background-color: fade(@ds-green-200, 9%);
    }
  }
  &--lotto{
    .multi-teaser-cards__card{
      background-color: fade(@ds-red, 9%);
    }
  }
  &--vikinglotto{
    .multi-teaser-cards__card{
      background-color: fade(@ds-blue, 9%);
    }
  }
  &--eurojackpot{
    .multi-teaser-cards__card{
      background-color: fade(@ds-honey, 25%);
    }
  }
  &--center-mobile{
    .multi-teaser-cards__cards{
      justify-content: center;
      gap:1.6rem;
      @media @sm {
        justify-content: inherit;
        gap: inherit;
      }
    }
  }
  &--center-desktop{
    .multi-teaser-cards__cards{
      @media @sm {
        justify-content: center;
        gap: 3rem;
      }
    }
  }

  &--items-per-row-mobile-1{
    .multi-teaser-cards__cards{
      flex-direction: column;
    }
    .multi-teaser-cards__card{
      flex: 0 1 100%;
    }
    h3.multi-teaser-cards__card__title{
      text-align: left;
    }
  }
  &--items-per-row-mobile-2{
    .multi-teaser-cards__cards{
      flex-direction: row;
    }
    .multi-teaser-cards__card{
      flex: 0 1 calc(~'50% - 0.8rem');
      padding: 1.6rem 1.6rem 1.6rem 1.6rem;
    }

    .multi-teaser-cards__card__text{
      text-align: center;
    }
    .multi-teaser-cards__card__image{
      position: relative;
      margin: 1.5rem auto;
      left: auto;
      width: 5rem;
      height: 5rem;
      top: 0;
    }
    .multi-teaser-card__cta-container{
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    } 
  }

  @media @sm{

    &--items-per-row-desktop-1{
      .multi-teaser-cards__cards{
        flex-direction: column;
        gap: 3rem;
      }
      .multi-teaser-cards__card{
        flex: 0 1 100%;
        min-height: 20rem;
        padding: 3.2rem;
      }
      h3.multi-teaser-cards__card__title{
        margin-top: 1.5rem;
        text-align: center;
      }
    }

    &--items-per-row-desktop-2{
      .multi-teaser-cards__cards{
        flex-direction: row;
        gap: 3rem;
      }
      .multi-teaser-cards__card{
        flex: 0 1 calc(~'50% - 2rem');
        padding: 3.2rem;
      }
      h3.multi-teaser-cards__card__title{
        margin-top: 1.5rem;
        text-align: center;
      }
    }

    &--items-per-row-desktop-3{
      .multi-teaser-cards__cards{
        flex-direction: row;
        gap: 3rem;
      }
      .multi-teaser-cards__card{
        flex: 0 1 calc(~'33.333% - 2rem');
        padding: 3.2rem;
      }
      h3.multi-teaser-cards__card__title{
        margin-top: 1.5rem;
        text-align: center;
      }
    }

    &--items-per-row-desktop-4{
      .multi-teaser-cards__cards{
        flex-direction: row;
        gap: 2rem;
      }
      .multi-teaser-cards__card{
        flex: 0 1 calc(~'25% - 2rem');
        padding: 3.2rem;
      }
      h3.multi-teaser-cards__card__title{
        margin-top: 1.5rem;
        text-align: center;
      }
    }
  }
}

h2.multi-teaser-cards__header {
  font-size: 2.8rem;
  line-height: 3.813rem;
  text-align: center;
  font-weight: 700;
  margin: 1.2rem auto;

  @media @sm {
    font-size: 4rem;
    line-height: 5.447rem;
    margin: 2rem auto;
  }
}

.multi-teaser-cards__content {
  color: @ds-gray-525;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  margin-bottom: 4rem;

  @media @sm {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom:7rem;
  }
}

.multi-teaser-cards__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.6rem;
  min-width: 33rem;

  @media @sm {
    flex-direction: row;
    gap: 3rem;
  }
}

.multi-teaser-cards__card{
  position: relative;
  flex: 0 1 calc(~'33.333% - 2rem');
  min-height: 10.4rem;
  background-color: @ds-gray-100; /* Light grey background for visibility */
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;
  padding: 1.6rem 1.6rem 1.6rem 10rem;
  text-align: left;

  @media @sm {
    min-height: 30.8rem;
    align-items: center;
  }
}

.multi-teaser-cards__card__image{
  position: absolute;
  left: 2.6rem;
  width: 4.5rem;
  height: 4.5rem;
  top: 2.6rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: 1;

  @media @sm {
    position: relative;
    margin: 1.5rem auto;
    left: auto;
    width: 7rem;
    height: 7rem;
    top: auto;
  }

  &:before{
    content: "";
    position: absolute;
    top: -1rem;
    left: -1rem;
    right: -1rem;
    bottom: -1rem;
    border-radius: 50%;
    mix-blend-mode: multiply;
    background: rgba(255, 255, 255, 1);
    z-index: -1;
    @media @sm {
      top: -1.5rem;
      left: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
    }
  }
}

h3.multi-teaser-cards__card__title{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  letter-spacing: 0.5px;
  margin-top: 0.3rem;
  text-align:center;

  @media @sm {
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
    text-align: center;
    line-height: 3.2rem;
  }
}
.multi-teaser-cards__card__text{
  flex-grow: 1;
  @media @sm {
    text-align: center;
  }
}
.multi-teaser-card__cta-container{
  position: relative;
  flex-grow: 0;
  padding-top: 3rem;
  margin-left: -8.4rem;
  width: calc(~'100% + 8.4rem');
  @media @sm {
    margin-left: auto;
    width: 100%;
  }

  .multi-teaser-cards__cta{
    margin-top: 0;
    text-align: center;
    @media @sm {
      text-align: center;
    }
  }
}

.multi-teaser-cards__cta{
  margin-top: 5rem;
  text-align: center;
  @media @sm {
    margin-top: 7rem;
  }
}

.multi-teaser-cards__cta_button {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: @ds-black;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 5.6rem;
  max-width: 24rem;
  width:100%;  

  &:not(:disabled):hover::after {
    transform: scale(1.03);
  }
  &:after {
    border-radius: 2.8rem;
    transition: transform 200ms ease-out;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.1rem solid transparent;
    background-color: @ds-yellow; 
    border-color: @ds-yellow;
  } 
}