﻿@import "../Components/_components";

// oddset as standard
.tutorial-container {
  --tutorial-primary: var(--primaryColor, #1750DC);
  --tutorial-secondary: var(--secondaryColor, #063D9B);
  --tutorial-cta: var(--ctaColor, #FEAF00);
  --tutorial-light-grey: #eceded;
  --tutorial-grey: #A0A0A0;
  --tutorial-tips-table-odd: var(--tipsTableEvenColor, #357856);
  --tutorial-tips-table-even: var(--tipsTableOddColor, #439665);
}
