.region-eurojackpot .header-spot {
  background-color: #000;
}
.region-eurojackpot .header-spot .content-wrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7vw 0;
}
@media (min-width: 950px) {
  .region-eurojackpot .header-spot .content-wrapper {
    padding: 5rem 0;
  }
}
.region-eurojackpot .header-spot .content-wrapper .top-logo img {
  width: 10vw;
  height: 10vw;
}
@media (min-width: 950px) {
  .region-eurojackpot .header-spot .content-wrapper .top-logo img {
    width: 6.4rem;
    height: 6.4rem;
  }
}
.region-eurojackpot .header-spot .content-wrapper h1 {
  font-size: 7vw;
  text-transform: uppercase;
  line-height: 0.9;
}
@media (min-width: 950px) {
  .region-eurojackpot .header-spot .content-wrapper h1 {
    font-size: 3.5rem;
  }
}
.region-eurojackpot .header-spot .content-wrapper h2 {
  font-size: 4.5vw;
  text-transform: uppercase;
  line-height: 0.8;
  margin: 0;
}
@media (min-width: 950px) {
  .region-eurojackpot .header-spot .content-wrapper h2 {
    font-size: 2rem;
  }
}
.eurojackpot-no-open-draw .eurojackpot-section {
  background-color: #fff;
  border-color: #fff;
}
.eurojackpot-no-open-draw .eurojackpot-section .eurojackpot-content-wrapper {
  z-index: 4;
  padding: 8vw;
}
@media (min-width: 950px) {
  .eurojackpot-no-open-draw .eurojackpot-section .eurojackpot-content-wrapper {
    padding: 4rem;
  }
}
.eurojackpot-no-open-draw .eurojackpot-section .eurojackpot-content-wrapper .no-open-draw-text {
  max-width: 86.5vw;
  font-size: 5vw;
  color: #2E3535;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .eurojackpot-no-open-draw .eurojackpot-section .eurojackpot-content-wrapper .no-open-draw-text {
    font-size: 3.5rem;
  }
}
