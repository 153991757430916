// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

// Variables
@cell-width--tablet: calc(~'20% - 10px');
@cell-height--tablet: 6vw;

.eurojackpot-classic-game, .eurojackpot-system-game, .eurojackpot-lucky-game, .eurojackpot-winning-numbers {
  .eurojackpot-section {
    background-color: @ds-white;
  }

  .number-picker-footer {
    margin-top: 1.3vw;
    padding: 0 1.9% 0;
    position: relative;
    .clearfix();

    @media (min-width: @eurojackpot-desktop-width) {
      margin-top: 1.6rem;
      padding: 0 1rem 0;
      text-align: center;
    }

    .buttons-container {
      padding: 4.034vw 0 2.134vw;
      border-top: .2rem solid @eurojackpot-mediumgray;
      white-space: nowrap;

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 2.9rem 0 1.1rem;
      }

      .button {
        min-width: 23.2vw;
        font-size: 3.734vw;
        line-height: 1.36;
        font-weight: bold;
        color: @ds-white;
        text-align: left;
        background-color:  @eurojackpot-carbon;
        margin-left: 3.334vw;
        padding: 2.8vw 3.6vw 2.8vw;
        border-radius: 6px;
        display: inline-block;
        text-transform: uppercase;
        .box-sizing();
        position: relative;
        transition: opacity .25s, background-color .25s, color .25s, transform .25s;
        user-select: none;
        overflow: hidden;
        will-change: transform;

        &:hover {
          @media (min-width: @eurojackpot-desktop-width) {
            transform: scale(1.05);
          }
        }

        &:first-child {
          margin-left: 0;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          font-size: 1.7rem;
          text-align: center;
        }

        @media (min-width: @eurojackpot-desktop-width) {
          min-width: initial;
          min-width: inherit;
          font-size: 1.6rem;
          margin-left: 2rem;
          padding: 1.3rem 1.8rem 1.2rem 1.8rem;
          cursor: pointer;
        }

        &.inactive {
          .opacity(.3);
          cursor: default;

          &:hover {
            @media (min-width: @eurojackpot-desktop-width) {
              transform: scale(1);
            }
          }
        }

        &.autogenerating-numbers {
          opacity: .3;

          @keyframes eurojackpot-autogenerate-button-animation {
            0% {
              transform: translate(-50%, -50%) scale(0);
              opacity: 0;
            }

            50% {
              opacity: .5;
            }

            100% {
              transform: translate(-50%, -50%) scale(1.5);
              opacity: 0;
            }
          }

          &:before, &:after {
            content: '';
            width: 100%;
            padding-top: 100%;
            border-radius: 50%;
            background: radial-gradient(center, ellipse farthest-corner, rgba(43, 43, 43,0) 0%, rgba(43, 43, 43,0) 20%, rgba(43, 43, 43,0.65) 45%, rgba(43, 43, 43,0.9) 55%, rgba(43, 43, 43,0.5) 65%, rgba(43, 43, 43,0) 90%, rgba(43, 43, 43,0) 100%);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scale(0);
          }

          &:before {
            animation: eurojackpot-autogenerate-button-animation .8s linear infinite;
          }

          &:after {
            animation: eurojackpot-autogenerate-button-animation .8s .3s linear infinite;
          }

          &:hover {
            cursor: default;

            @media (min-width: @eurojackpot-desktop-width) {
              transform: scale(1);
            }
          }
        }

        &.reset-row-button {
          float: right;

          @media (min-width: @eurojackpot-desktop-width) {
            float: none;
          }
        }
      }

      .autogenerate-button .icon, .reset-row-button .icon {
        width: 2.934vw;
        height: 3.7vw;
        fill: #4f5254;
        position: absolute;
        top: 3.4vw;
        right: 3.7vw;
        .transition(fill .25s);
        overflow: visible;

        @media (min-width: @eurojackpot-desktop-width) {
          width: 1.6rem;
          height: 1.6rem;
          top: 1.1rem;
          right: 1.4rem;
        }
      }
    }

    .autogenerate-box {
      display: inline-block;
      -webkit-touch-callout: none;
      .user-select(none);

      .autogenerate-button {
        position: relative;
      }

      .options {
        width: 75.235vw;
        background-color: #fff;
        position: absolute;
        top: 16.4vw;
        left: 0;
        .border-radius(6px);
        display: none;
        z-index: 1;

        @media (min-width: @eurojackpot-desktop-width) {
          width: 58rem;
          top: 8rem;
        }

        .item {
          width: 50%;
          font-size: 3.2vw;
          font-weight: bold;
          color: #0891d8;
          text-align: center;
          float: left;
          padding: 4vw 0;
          border-width: 0 .2rem .2rem 0;
          border-style: solid;
          border-color: #cdd3d7;
          .box-sizing();
          position: relative;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 20%;
            font-size: 1.4rem;
            padding: 1.1rem 0 1rem;
            border-width: 0 .2rem .2rem 0;
            cursor: pointer;
          }

          &:first-child {
            &:before {
              content: '';
              height: 3.4vw;
              background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
              background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,0) 100%);
              background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
              position: absolute;
              top: 0;
              left: 4vw;
              right: -1vw;

              @media (min-width: @eurojackpot-desktop-width) {
                height: 3.4rem;
                left: 1rem;
                right: 80%;
              }
            }
          }

          &.hidden, &.hidden.inactive {
            color: #fff;
            cursor: default;
          }

          &:nth-child(2n) {
            border-width: 0 0 .2rem 0;
          }

          &:last-child {
            border-width: 0 0 0 0;
          }

          &:nth-last-child(2) {
            border-width: 0 .2rem 0 0;
          }

          @media (min-width: @eurojackpot-desktop-width) {
            &:nth-child(2n) {
              border-width: 0 .2rem .2rem 0;
            }

            &:nth-child(5n) {
              border-width: 0 0 .2rem 0;
            }

            &:nth-last-child(-n+5) {
              border-width: 0 .2rem 0 0;
            }

            &:last-child {
              border-width: 0 0 0 0;
            }
          }

          &.inactive {
            color: #cdd3d7;

            @media (min-width: @eurojackpot-desktop-width) {
              cursor: default;
            }
          }
        }
      }

      &.active {
        .button {

          &:after {
            display: block;
          }

          .icon {
            fill: #fff;
          }
        }

        .options {
          display: block;
        }
      }
    }
  }

  .row-container {
    .keyframes(eurojackpot-number-cell-shake-animation; {
      0% {
        .transform(scale(1) rotate(0));
      }

      10% {
        .transform(scale(1.13) rotate(5deg));
      }

      20% {
        .transform(scale(1.13) rotate(-5deg));
      }

      30% {
        .transform(scale(1.13) rotate(5deg));
      }

      40% {
        .transform(scale(1.13) rotate(-5deg));
      }

      50% {
        .transform(scale(1.13) rotate(5deg));
      }

      60% {
        .transform(scale(1.13) rotate(-5deg));
      }

      70% {
        .transform(scale(1.13) rotate(5deg));
      }

      80% {
        .transform(scale(1.13) rotate(-5deg));
      }

      90% {
        .transform(scale(1.13) rotate(5deg));
      }

      100% {
        .transform(scale(1) rotate(0));
      }
    }

  );

    .keyframes(eurojackpot-rules-pulse-animation; {
      0% {
        .transform(scale(1));
      }

      25% {
        .transform(scale(1.13));
      }

      50% {
        .transform(scale(1));
      }

      75% {
        .transform(scale(1.13));
      }

      100% {
        .transform(scale(1));
      }
    }

  );

    &.shake-state, &.shake-star-numbers-state {
      .header-rules-text {
        .animation(eurojackpot-rules-pulse-animation .8s forwards);
      }

      .row-header {
        .tab-game-rules-text {
          display: inline-block;
          animation: eurojackpot-rules-pulse-animation .8s forwards;
        }
      }
    }

    &.shake-state {
      .cells-container {
        .selected {
          .animation(eurojackpot-number-cell-shake-animation .8s forwards);
        }
      }
    }

    &.shake-star-numbers-state {
      .row-stars-container {
        .selected {
          .animation(eurojackpot-number-cell-shake-animation .6s forwards);
        }
      }
    }
  }

  .row-header {
    font-size: 3.734vw;
    line-height: 1.3;
    color: @ds-black;
    padding-bottom: 1.634vw;
    border-bottom: .2rem solid @eurojackpot-mediumgray;
    margin: 1.8vw 1.4vw 1.3vw;
    .clearfix();

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 1.8rem;
      padding-bottom: 2.2rem;
      margin: 2.3rem 1rem 2rem;
    }

    @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
      font-size: 2rem;
      margin: 1.8vw 0 1.3vw;
    }

    .counter {
      width: 83%;
      text-align: center;
      float: left;
      opacity: .6;
      color: @ds-gray-450;

      @media (min-width: @eurojackpot-desktop-width) {
        width: auto;
        padding-right: 2rem;
        text-align: left;
        float: inherit;
      }
    }

    .right-side {
      padding-right: 2.667vw;
      position: relative;

      @media (min-width: @eurojackpot-desktop-width) {
        padding-right: 1.5rem;
        display: inline-block;
        position: static;
      }

      .row-delete-button {
        display: block;
        position: absolute;
        top: 2.15vw;
        right: 3.5vw;
        cursor: pointer;
        transition: opacity .25s, transform .15s;

        @media (min-width: @eurojackpot-desktop-width) {
          top: .1rem;
          right: 1rem;
        }

        &:hover {
          @media (min-width: @eurojackpot-desktop-width) {
            transform: scale(1.4);
          }
        }

        .icon {
          width: 2.667vw;
          height: 2.667vw;
          fill: @ds-black;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 1.2rem;
            height: 1.2rem;
          }
        }

        &.inactive {
          .opacity(.3);
          cursor: default;

          &:hover {
            @media (min-width: @eurojackpot-desktop-width) {
              transform: scale(1);
            }
          }
        }
      }
    }

    .header-rules-text {
      width: 86%;
      display: inline-block;
      text-align: center;

      @media (min-width: @eurojackpot-desktop-width) {
        width: 100%;
        text-align: left;
      }
    }
  }

  .number-picker-wrapper {
    position: relative;
    display: flex;

    &.complete-row {
      .selected {
        @keyframes eurojackpot-complete-grow {
          0% {
            transform: scale(1);
          }

          15% {
            transform: scale(1.1);
          }

          85% {
            transform: scale(1.1);
          }

          100% {
            transform: scale(1);
          }
        }
      }

      .shine-star-wrapper {
        width: 100%;
        padding-top: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @keyframes eurojackpot-sparkle {
          0% {
            opacity: 0;
            transform: rotate(0deg);
          }

          20% {
            opacity: 1;
          }

          50% {
            transform: rotate(80deg);
          }

          80% {
            opacity: 0;
          }

          100% {
            opacity: 0;
            transform: rotate(160deg);
          }
        }

        .shine-star {
          width: 20%;
          height: 20%;
          background: -moz-radial-gradient(center, ellipse cover, rgba(247,245,237,1) 0%, rgba(247,245,237,1) 6%, rgba(247,245,237,0.1) 70%, rgba(247,245,237,0) 100%);
          background: -webkit-radial-gradient(center, ellipse cover, rgba(247,245,237,1) 0%,rgba(247,245,237,1) 6%,rgba(247,245,237,0.1) 70%,rgba(247,245,237,0) 100%);
          background: radial-gradient(ellipse at center, rgba(247,245,237,1) 0%,rgba(247,245,237,1) 6%,rgba(247,245,237,0.1) 70%,rgba(247,245,237,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f5ed', endColorstr='#00f7f5ed',GradientType=1 );
          border-radius: 50%;
          position: absolute;
          opacity: 0;
          animation: eurojackpot-sparkle .8s ease-in-out forwards;

          &:nth-child(1) {
            top: -2%;
            animation-delay: .5s;
          }

          &:nth-child(2) {
            top: 50%;
            left: 0;
            animation-delay: .8s;
          }

          &:nth-child(3) {
            top: 70%;
            right: 0;
            animation-delay: 1s;
          }

          &:after, &:before {
            height: 300%;
            width: 50%;
            content: ' ';
            background: -moz-radial-gradient(center, ellipse cover, rgba(247,245,237,1) 0%, rgba(247,245,237,1) 6%, rgba(247,245,237,0.1) 70%, rgba(247,245,237,0) 100%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(247,245,237,1) 0%,rgba(247,245,237,1) 6%,rgba(247,245,237,0.1) 70%,rgba(247,245,237,0) 100%);
            background: radial-gradient(ellipse at center, rgba(247,245,237,1) 0%,rgba(247,245,237,1) 6%,rgba(247,245,237,0.1) 70%,rgba(247,245,237,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f5ed', endColorstr='#00f7f5ed',GradientType=1 );
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &:before {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }
  }

  .number-picker-container {
    margin: 0 -1.9%;
    position: relative;
    white-space: normal;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    .user-select(none);

    @media (min-width: @eurojackpot-desktop-width) {
      margin: 0 -1rem;
    }

    .cells-container {
      width: 61.068vw;
      position: relative;

      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        width: 100%;
        margin-right: 2.5vw;
      }

      @media (min-width: @eurojackpot-desktop-width) {
        width: 63rem;
        margin-right: 2.8rem;
      }
    }

    .cell {
      text-align: center;
      position: relative;
      display: inline-block;
      margin: 1.334vw;
      transition: transform .15s linear;
      will-change: transform;

      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        margin: 2.075vw 0.648vw;
      }

      @media (min-width: @eurojackpot-desktop-width) {
        margin: 0.8rem 1.2rem;
        cursor: pointer;
      }

      &:hover {
        @media (min-width: @eurojackpot-desktop-width) {
          transform: scale(1.2);
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .cell-container {
        width: 12.267vw;
        height: 12.267vw;
        position: relative;
        left: 0;
        top: 0;

        @media (min-width: @eurojackpot-desktop-width) {
          width: 4.6rem;
          height: 4.6rem;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          width: 5.967vw;
          height: 5.967vw;
        }
      }

      @eurojackpot-flip-animation-duration: .7s;
      @eurojackpot-flip-animation-function: linear;

      .face {
        width: 100%;
        height: 100%;
        font-size: 3.733vw;
        line-height: 3.2;
        font-weight: 800;
        position: absolute;
        top: 0;
        left: 0;
        color: @eurojackpot-white;
        border-radius: 50%;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 1.4rem;
          line-height: 4.7rem;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          font-size: 1.815vw;
          line-height: 5.967vw;
        }

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          overflow: hidden;
        }

        &.front {
          background-color: @eurojackpot-carbon;
        }

        &.back {
          visibility: hidden;
          background-color: @eurojackpot-gold;

          .number {
            position: relative;
            z-index: 2;
          }
        }
      }

      @keyframes eurojackpot-animation-flip-timer {
        0% {
          will-change: transform;
        }

        100% {
          will-change: transform;
        }
      }

      @keyframes eurojackpot-animation-flip-timer-IE {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }

      @keyframes eurojackpot-check {
        0% {
          transform: rotateY(0deg) scale(1);
        }

        50% {
          transform: rotateY(-195deg) scale(1.1);
        }

        75% {
          transform: rotateY(-165deg) scale(1.1);
        }

        100% {
          transform: rotateY(-180deg) scale(1);
        }
      }

      @keyframes eurojackpot-uncheck {
        0% {
          transform: rotateY(180deg);
        }

        50% {
          transform: rotateY(-15deg);
        }

        75% {
          transform: rotateY(15deg);
        }

        100% {
          transform: rotateY(0deg);
        }
      }

      &.flip-animation-in-progress {
        perspective: 10rem;
        animation: eurojackpot-animation-flip-timer @eurojackpot-flip-animation-duration forwards;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          animation: eurojackpot-animation-flip-timer-IE .4s forwards;
        }

        .cell-container {
          transform-style: preserve-3d;

          .face {
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            &.front {
              visibility: visible;
              transform: rotateY(0deg);
            }

            &.back {
              visibility: visible;
              transform: rotateY(180deg);

              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                transform: rotateY(0);
              }
            }
          }
        }

        &.select-number-animation {
          @keyframes eurojackpot-shine {
            0% {
              transform: translate(-50%, -100%);
              opacity: 0;
            }

            25% {
              opacity: .4;
            }

            50% {
              opacity: .6;
            }

            75% {
              opacity: .4;
            }

            100% {
              transform: translate(0%, 80%);
              opacity: 0;
            }
          }

          .cell-container {
            transform: rotateY(-180deg) scale(1);
            animation: eurojackpot-check @eurojackpot-flip-animation-duration;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              transform: rotateY(0) scale(1);
              animation: none;
            }

            .face {
              &.front {
                visibility: visible;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  opacity: 0;
                  transition: opacity .25s, visibility 0 .25s;
                  z-index: 1;
                }
              }

              &.back {
                @keyframes number-scale {
                  0% {
                    transform: scale(1.1);
                  }

                  60% {
                    transform: scale(1.1);
                  }

                  100% {
                    transform: scale(1);
                  }
                }

                .number {
                  transform: scale(1.1);
                  animation: eurojackpot-number-scale @eurojackpot-flip-animation-duration @eurojackpot-flip-animation-function forwards;
                  display: block;

                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    transform: scale(1);
                    animation: none;
                  }
                }

                .shine {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 42%;
                  overflow: hidden;
                  mask: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotCommonIcons/EurojackpotSquareShape.svg);

                  @media screen and (-webkit-min-device-pixel-ratio:2) {
                    border-radius: 0;
                    overflow: visible;
                  }

                  &:after {
                    content: '';
                    width: 200%;
                    height: 200%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: -moz-linear-gradient(-45deg, rgba(247,245,237,0) 0%, rgba(247,245,237,0) 40%, rgba(247,245,237,1) 50%, rgba(247,245,237,1) 52%, rgba(247,245,237,0) 67%, rgba(247,245,237,0) 100%);
                    background: -webkit-linear-gradient(-45deg, rgba(247,245,237,0) 0%,rgba(247,245,237,0) 40%,rgba(247,245,237,1) 50%,rgba(247,245,237,1) 52%,rgba(247,245,237,0) 67%,rgba(247,245,237,0) 100%);
                    background: linear-gradient(135deg, rgba(247,245,237,0) 0%,rgba(247,245,237,0) 40%,rgba(247,245,237,1) 50%,rgba(247,245,237,1) 52%,rgba(247,245,237,0) 67%,rgba(247,245,237,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f7f5ed', endColorstr='#00f7f5ed',GradientType=1 );
                    transform: translate(-50%, -100%);
                    animation: eurojackpot-shine .5s .8s ease-in-out forwards;
                  }
                }
              }
            }
          }
        }

        &.deselect-number-animation {
          .cell-container {
            animation: eurojackpot-uncheck .5s @eurojackpot-flip-animation-function;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              animation: none;
            }

            .face {
              &.front {
                opacity: 1;
              }

              &.back {
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  opacity: 0;
                  transition: opacity .25s, visibility 0 .25s;
                  z-index: 1;
                }
              }
            }
          }
        }
      }

      &.selected {
        .cell-container {
          .face {
            &.front {
              visibility: hidden;
            }

            &.back {
              visibility: visible;
            }
          }
        }
      }
    }

    .row-stars-container {
      width: 13.335vw;
      text-align: center;
      padding: 1.334vw 0 1.334vw;
      box-sizing: content-box;
      position: relative;
      right: -.4vw;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-end;

      @media (min-width: @eurojackpot-mobile-width) {
        align-content: space-between;
        justify-content: space-between;
      }

      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        width: 16.075vw;
        padding: 2.075vw 0 2.075vw 3.242vw;
        right: 0;
      }

      @media (min-width: @eurojackpot-desktop-width) {
        width: 12rem;
        padding: .8rem 0 .8rem 4rem;
        right: -.3rem;
      }

      &::before {
        content: '';
        width: .2rem;
        background-color: @eurojackpot-mediumgray;
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          top: 2.075vw;
          bottom: 2.075vw;
        }
        @media (min-width: @eurojackpot-desktop-width) {
          top: 0.8rem;
          bottom: 0.8rem;
        }
      }

      .shine-star-wrapper {
        width: 70%;
        padding-top: 70%;
        margin-left: 15%;
      }

      .star-box {
        width: 12vw;
        height: 12vw;
        margin-bottom: 2.9vw;
        box-sizing: border-box;
        position: relative;
        transition: transform .1s linear;

        @media (min-width: @eurojackpot-mobile-width) {
          margin-bottom: 0;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          width: 5.836vw;
          height: 5.836vw;
        }

        @media (min-width: @eurojackpot-desktop-width) {
          width: 4.5rem;
          height: 4.5rem;
        }

        &:hover {
          @media (min-width: @eurojackpot-desktop-width) {
            transform: scale(1.2);
          }
        }

        .number {
          width: 100%;
          font-size: 4vw;
          font-weight: 800;
          line-height: 3.5;
          display: block;
          color: @eurojackpot-gold;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;

          @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
            font-size: 1.815vw;
          }

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.4rem;
          }
        }

        .icon {
          width: 100%;
          height: 100%;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          &.main-outline {
            stroke: @eurojackpot-gold;
            transform: scale(1);
            transition: transform .15s;
            stroke-width: 1rem;
          }

          &.gold {
            transform: scale(0);
            opacity: 0;
            animation: eurojackpot-star-implosition .15s linear forwards;
            background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
          }

          &.outline-1, &.outline-2, &.outline-3 {
            stroke: @eurojackpot-gold;
            transform: scale(0);
          }

          &.outline-1 {
            stroke-width: 1rem;
          }

          &.outline-2, &.outline-3 {
            stroke-width: .5rem;
          }

          &.icon-small-star {
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            transform: translateY(-40%);
            opacity: 0;
          }
        }

        @keyframes eurojackpot-star-implosition {
          0% {
            transform: scale(1);
            opacity: 1;
          }

          100% {
            transform: scale(0);
            opacity: 0;
          }
        }

        @keyframes eurojackpot-star-expansion {
          0% {
            transform: scale(0);
            opacity: 0;
          }

          75% {
            transform: scale(1.4);
            opacity: 1;
          }

          100% {
            transform: scale(1);
            opacity: 1;
          }
        }

        @keyframes eurojackpot-star-expansion-otline {
          0% {
            transform: scale(0);
          }

          70% {
            transform: scale(1.4);
            opacity: 1;
          }

          100% {
            transform: scale(1.6);
            opacity: 0;
          }
        }

        @keyframes eurojackpot-small-star-explosion-1 {
          40% {
            opacity: 0.8;
          }
          100% {
            transform: translateY(-400%) translateX(220%) scale(1.2);
            opacity: 0;
          }
        }
        @keyframes eurojackpot-small-star-explosion-2 {
          30% {
            opacity: 1;
          }
          100% {
            transform: translateY(-300%) translateX(200%) scale(.6);
            opacity: 0;
          }
        }
        @keyframes eurojackpot-small-star-explosion-3 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(-230%) translateX(160%) scale(1);
            opacity: 0.9;
          }
        }
        
        @keyframes eurojackpot-small-star-explosion-4 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(-250%) translateX(-240%) scale(1);
            opacity: 0.7;
          }
        }
        @keyframes eurojackpot-small-star-explosion-5 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(-190%) translateX(-110%) scale(.6);
            opacity: 0.8;
          }
        }
        
        @keyframes eurojackpot-small-star-explosion-6 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(190%) translateX(0%) scale(1.05);
            opacity: 0.9;
          }
        }
        @keyframes eurojackpot-small-star-explosion-7 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(70%) translateX(-280%) scale(0.92);
            opacity: 0.9;
          }
        }
        @keyframes eurojackpot-small-star-explosion-8 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(80%) translateX(180%) scale(0.5);
            opacity: 0.4;
          }
        }
        @keyframes eurojackpot-small-star-explosion-9 {
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(50%) translateX(300%) scale(1.1);
            opacity: 0.8;
          }
        }
        @keyframes eurojackpot-small-star-explosion-10 {
          30% {
            opacity: 1;
          }
          100% {
            transform: translateY(100%) translateX(400%) scale(1.3);
            opacity: 0;
          }
        }
        @keyframes eurojackpot-small-star-explosion-11 {
          30% {
            opacity: 1;
          }
          100% {
            transform: translateY(-120%) translateX(-500%) scale(1.3);
            opacity: 0;
          }
        }
        @keyframes eurojackpot-small-star-explosion-12 {
          0%, 100% {
            transform: translateY(140%) translateX(-230%) scale(0);
            opacity: 0;
          }
          50% {
            transform: translateY(140%) translateX(-230%) scale(0.92);
            opacity: 0.7;
          }
        }
        @keyframes eurojackpot-small-star-explosion-13 {
          0%, 100% {
            transform: translateY(-230%) translateX(160%) scale(0.6);
            opacity: 0;
          }
          50% {
            transform: translateY(-230%) translateX(160%) scale(1.2);
            opacity: 0.7;
          }
        }

        &.selected {
          .number {
            color: @ds-white;
          }
          .icon {
            &.main-outline {
              transform: scale(.9);
            }

            &.gold {
              transform: scale(1);
              opacity: 1;
              animation: eurojackpot-star-expansion .3s linear forwards;
            }

            &.outline-1 {
              animation: eurojackpot-star-expansion-otline .3s linear forwards;
            }

            &.outline-2 {
              animation: eurojackpot-star-expansion-otline .3s .15s linear forwards;
            }

            &.outline-3 {
              animation: eurojackpot-star-expansion-otline .15s .15s linear forwards;
            }
          }

          .icon-small-star--1 {
            animation: eurojackpot-small-star-explosion-1 .5s .1s forwards;
          }
          .icon-small-star--2 {
            animation: eurojackpot-small-star-explosion-2 .4s .2s forwards;
          }
          .icon-small-star--3 {
            animation: eurojackpot-small-star-explosion-3 .3s .1s forwards;
          }
          .icon-small-star--4 {
            animation: eurojackpot-small-star-explosion-4 .3s .1s forwards;
          }
          .icon-small-star--5 {
            animation: eurojackpot-small-star-explosion-5 .3s .1s forwards;
          }
          .icon-small-star--6 {
            animation: eurojackpot-small-star-explosion-6 .3s .1s forwards;
          }
          .icon-small-star--7 {
            animation: eurojackpot-small-star-explosion-7 .3s .1s forwards;
          }
          .icon-small-star--8 {
            animation: eurojackpot-small-star-explosion-8 .3s .1s forwards;
          }
          .icon-small-star--9 {
            animation: eurojackpot-small-star-explosion-9 .3s .1s forwards;
          }
          .icon-small-star--10 {
            animation: eurojackpot-small-star-explosion-10 .3s .1s forwards;
          }
          .icon-small-star--11 {
            animation: eurojackpot-small-star-explosion-11 .3s .1s forwards;
          }
          .icon-small-star--12 {
            animation: eurojackpot-small-star-explosion-12 .15s .05s forwards;
            animation-iteration-count: 2;
          }
          .icon-small-star--13 {
            animation: eurojackpot-small-star-explosion-13 .15s .05s forwards;
            animation-iteration-count: 2;
          }
        }
      }
    }
  }
}
