@import (reference) "../Mixins/_mixins.less";

.notification-box {
  max-width: 60rem;
  margin: 0 auto 2rem;
  padding: 2rem;
  border: 1px solid @ds-green;
  border-radius: 20px;
  display: flex;
  box-sizing: content-box;
  align-items: center;
  flex-direction: column;
  background-color: @ds-white;

  @media @sm {
    flex-direction: row;
  }

  .notification-box__image-wrapper {
    width: 13rem;
    height: 13rem;
    margin-bottom: 1.4rem;
    @media @sm {
      width: 5rem;
      height: 5rem;
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  .notification-box__image {
    width: 100%;
    height: 100%;
    @media @sm {
      width: 5rem;
      height: 5rem;
    }
  }

  .notification-box__text {
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: @ds-black;
    text-align: left;
  }
}