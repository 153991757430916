@import "../_imports";
@import "../Mixins/mixins";

.tutorial-frontpage {
  padding-top: 2rem;
  background-color: var(--tutorial-primary);
}

.tutorial-frontpage__hero {
  background-color: var(--tutorial-primary);

  .tutorial-frontpage__hero-title {
    .hero-title();
    padding-bottom: 2rem;

    @media @lg {
      padding-top: 3.3rem;
    }
  }
}

.tutorial__frontpage-wrapper {
  position: relative;
  min-height: 46rem;

  .tutorial__frontpage-inner-wrapper {
    padding: 3.5rem 1.5rem;
    display: flex;
    max-width: 144rem;
    margin: 0 auto;
    transition: 0.3s ease-out;
    overflow: hidden;
    left: 0;
    right: 0;

    &--hide {
      transform: translateY(50rem);
      position: absolute;
      padding: 0;
    }

    .tutorial__categories {
      flex: 1;

      @media @lg {
        flex: 60%
      }

      .tutorial__category {
        display: block;
        color: black;
        box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
        margin-bottom: 1.6rem;
        position: relative;
        transition: 0.1s ease-out;
        overflow: hidden;
        cursor: pointer;
        border-radius: 2rem;

        .tutorial__category-tutorials {
          transition-duration: 0.3s;
          max-height: 0;
        }

        @media @lg {
          margin-left: 5.9rem;
          margin-right: 5.9rem;
          margin-bottom: 2.3rem;

          &:hover:not(.tutorial__category--show) {
            transform: scale(1.015);

            .tutorial__category-title {
              color: var(--tutorial-primary);
              transition-duration: 0.2s;
            }
          }
        }

        .tutorial__category-wrapper {
          width: 100%;
          position: relative;
          padding: 2rem 2.5rem;
          display: grid;
          grid-template-columns: auto 3.5rem;

          &:not(&--tutorial)::after {
            content: "";
            position: absolute;
            height: 0.1rem;
            width: 95%;
            background-color: @ds-black;
            display: block;
            opacity: 0.25;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @media @sm {
              bottom: -0.2rem;
            }
          }

          .tutorial__category-title {
            text-transform: uppercase;
            font-size: 2rem;
            font-family: @font-impact;
            font-style: italic;
            font-weight: 800;

            @media @sm {
              font-size: 2.3rem;
            }

          }

          .tutorial__category-label {
            font-style: italic;
            font-size: 1.4rem;
            color: rgba(0, 0, 0, 0.7);
          }

          .tutorial__category-cross {
            width: 3.2rem;
            height: 3.2rem;
            background-color: var(--tutorial-primary);
            border-radius: 50%;
            margin: auto 0 auto auto;
            transition-duration: 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);

            @media @sm {
              width: 3.2rem;
              height: 3.2rem;
              top: 2.6rem;
              margin-right: 1.2rem;
            }

            @media @lg {
              top: 2.5rem;
            }

            &::after,
            &::before {
              content: '';
              width: 1.7rem;
              display: block;
              height: 0.37rem;
              border-radius: 0.1rem;
              background: @ds-white;
              position: absolute;
              transition: 0.1s ease-out;
            }

            &::after {
              transform: rotate(90deg);
            }
          }
        }

        &--show {
          .tutorial__category-tutorials {
            max-height: 100rem;
          }

          .tutorial__category-wrapper .tutorial__category-cross {
            &::after {
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    .tutorial__most-visited {
      display: none;

      @media @lg {
        border-radius: 2rem;
        flex: 40%;
        max-width: 55rem;
        height: fit-content;
        background-color: var(--tutorial-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 0;
        margin-right: 5.4rem;
      }

      .tutorial__most-visited-title {
        @media @lg {
          font-size: 3rem;
          font-weight: 800;
          text-align: center;
          padding: 0 2rem;
          font-style: italic;
          font-family: @font-impact;
          color: @ds-white;
          margin: auto auto 2rem;
        }
      }

      .tutorial__most-visited-wrapper {
        @media @lg {
          position: relative;
          display: flex;
          width: 100%;
        }

        .tutorial__most-visited-element-wrapper {
          @media @lg {
            border-radius: 2rem;
            box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
            margin: 1.1rem 3rem;
            padding: 1.2rem 2.5rem;
            position: relative;
            background-color: @ds-white;
            width: 100%;
            min-height: 8.5rem;
            display: grid;
            grid-template-columns: 5.5rem auto 2.5rem;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            transition: 0.1s ease-out;
          }

          &:hover {
            transform: scale(1.015);
          }

          .tutorial__most-visited-element-image-wrapper {
            @media @lg {
              width: 5.5rem;
              height: 5.5rem;
              background-color: var(--tutorial-primary);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .tutorial__most-visited-element-image {
              @media @lg {
                width: 45%;
                height: 45%;
              }
            }
          }

          .tutorial__most-visited-element-text-wrapper {
            @media @lg {
              display: flex;
              flex-direction: column;
              padding-left: 1.8rem;
              line-height: 1.7rem;
              color: @color-black;
              justify-content: center;
            }

            .tutorial__most-visited-element-title {
              @media @lg {
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 0.9rem;
              }
            }

            .tutorial__most-visited-element-text {
              @media @lg {
                font-size: 1.4rem;
                font-weight: 400;
                font-style: italic;
              }
            }
          }

          .tutorial__most-visited-element-arrow {
            @media @lg {
              width: 1.5rem;
              height: 1.5rem;
              border-bottom: 0.2rem solid @color-black;
              border-right: 0.2rem solid @color-black;
              transform: rotate(-45deg);
              position: absolute;
              top: 40%;
              left: 90%;
              transition: 0.2s ease-out;
            }
          }
        }
      }
    }
  }

  .tutorial__search-result {
    transition: 0.3s ease-out;
    position: absolute;
    left: 0;
    right: 0;
    top: 100rem;
    background-color: var(--tutorial-primary);
    opacity: 0;
    pointer-events: none;
    min-height: 46rem;

    &--show {
      top: 0;
      opacity: 1;
      pointer-events: auto;
      bottom: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      .tutorial__search-result-wrapper {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
      }

      .tutorial__search-result-related-wrapper {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
      }
    }

    .tutorial__search-result-wrapper {
      padding: 0 2rem;
      flex: 1;

      @media @sm {
        width: 55rem;
        margin: 0 auto;
        padding: 0;
      }

      @media @lg {
        width: 63rem;
        padding: 0 0 5rem;
        flex: 1;
      }
    }

    .tutorial__search-result-related-wrapper {
      background-color: @ds-white;
      padding: 3.5rem 1.5rem;
      min-height: 25rem;
      max-width: 144rem;
      width: 100%;
      margin: 0 auto;

      @media @sm {
        padding-left: 0;
        padding-right: 0;
      }

      .tutorial__search-result-related-inner-wrapper {
        @media @sm {
          margin: 0 auto;
          width: 55rem;
        }

        @media @lg {
          width: 63rem;
        }

        .tutorial__search-result-related-help-text {
          font-style: italic;
          font-size: 1.4rem;
          padding-bottom: 2.3rem;

          @media @lg {
            display: flex;
            align-items: flex-start;
            font-size: 2rem;
            width: fit-content;
            margin: 1.7rem auto 4.3rem;
            padding-left: 0;
            padding-bottom: 0;
          }
        }

        .tutorial__search-result-related-help-categories {
          @media @lg {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            width: 63.2rem;
            padding-left: 0;
            padding-right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .tutorial__search-result-null-wrapper {
    transition-duration: 0.5s;
    position: absolute;
    left: 0;
    right: 0;
    top: 100rem;
    background-color: var(--tutorial-primary);
    opacity: 0;
    pointer-events: none;

    &--show {
      top: 0;
      opacity: 1;
      pointer-events: auto;
    }

    .tutorial__search-result-null-inner-wrapper {
      color: @ds-white;
      max-width: 62.2rem;
      margin: 0 auto;
      padding-left: 2rem;

      @media @sm {
        max-width: 51rem;
        padding-left: 0;
      }

      @media @lg {
        max-width: 63.2rem;
      }

      .tutorial__search-result-null-title {
        font-size: 1.2rem;
        padding-right: 1.5rem;
        line-height: 1.5rem;

        @media @lg {
          width: 63.2rem;
          margin: 0 auto;
          padding-left: 0;
        }
        @media @xl {
          font-size: 1.6rem;
        }
      }

      .tutorial__search-result-null-search-query {
        color: @ds-white;
        font-size: 2rem;
        font-weight: bold;
        padding: 1.2rem 0 1.8rem;

        @media @lg {
          width: 63.2rem;
          margin: 0 auto;
          padding-left: 0;
        }
        @media @xl {
          font-size: 2.5rem;
        }
      }

      .tutorial__search-result-null-text {
        font-size: 1.2rem;
        
        padding-bottom: 3.7rem;

        @media @lg {
          width: 63.2rem;
          margin: 0 auto;
          padding-left: 0;
          padding-bottom: 4rem;
        }
        @media @xl {
          font-size: 1.6rem;
        }
      }

      .tutorial__search-last-visited-wrapper {

        .tutorial__search-last-visited-title {
          font-size: 1.5rem;
          font-weight: 400;
          margin-bottom: 1.5rem;
          

          @media @sm {
            font-size: 1.8rem;
          }
        }

        .tutorial__search-last-visited-element-wrapper {
          margin-bottom: 1.75rem;
          display: flex;
          flex-direction: column;

          .tutorial__search-last-visited-element-innerwrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 2.4rem;
            padding-bottom: 1.7rem;

            .tutorial__search-last-visited-element-image {
              width: 1.3rem;
              height: 1.3rem;
              margin-right: 1.7rem;

              @media @sm {
                width: 1.4rem;
                height: 1.4rem;
              }
            }

            .tutorial__search-last-visited-element-title {
              color: @ds-white;
              font-size: 1.4rem;
              font-weight: 400;

              @media @sm {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}


.tutorial__category-tutorials-wrapper {
  position: relative;
  display: grid;
  padding: 2.5rem;
  grid-template-columns: 5.5rem auto 2.5rem;
  align-items: center;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 0.1rem;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media @lg {
    width: 100%;
    padding: 1.4rem 2.5rem;
  }

  .tutorial__category-tutorials-image-wrapper {
    width: 5.5rem;
    height: 5.5rem;
    background-color: var(--tutorial-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.4rem 2rem rgba(0, 27, 48, 0.2);

    @media @sm {
      width: 5.5rem;
      height: 5.5rem;
    }

    .tutorial__category-tutorials-image {
      width: 45%;
      height: 45%;
    }
  }

  .tutorial__category-tutorials-text-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 1.8rem;
    color: @ds-black;
    justify-content: center;

    .tutorial__category-tutorials-title {
      font-weight: bold;
      font-size: 2rem;

      @media @sm {
        font-size: 1.8rem;
      }
    }

    .tutorial__category-tutorials-text {
      font-size: 1.6rem;
      font-style: italic;

      @media @sm {
        font-size: 1.6rem;
      }
    }
  }

  &--filtered {
    margin-bottom: 1.4rem;
    padding-left: 0;

    @media @sm {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 auto 1.4rem;
    }
    @media @lg {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-left: 0;
      width: 63.2rem;
      margin: 0 auto;
    }

    .tutorial__category-tutorials-image-wrapper {
      background-color: @color-white;
      width: 5.3rem;
      height: 5.3rem;

      @media @lg {
        margin-left: 0;
      }
    }

    .tutorial__category-tutorials-text-wrapper {
      color: white;

      .tutorial__category-tutorials-title {
        font-weight: normal;
      }
    }

    .tutorial__category-tutorials-arrow {
      border-bottom: 0.2rem solid @color-white;
      border-right: 0.2rem solid @color-white;
      right: 4rem;

      @media @sm {
        right: 4rem;
      }
    }
  }
}

.tutorial__category-tutorials-arrow {
  width: 1.5rem;
  height: 1.5rem;
  border-bottom: 0.3rem solid @ds-black;
  border-right: 0.3rem solid @ds-black;
  transform: rotate(-45deg);
  position: relative;
  margin: auto 0 auto auto;
  transition: 0.2s ease-out;

  @media @sm {
    width: 1.5rem;
    height: 1.5rem;
    right: 2.5rem;
  }
}

.tutorial__highlight {
  background: var(--highlightBackgroundColor, transparent);
  color: var(--highlightColor, inherit);
  padding: 0 0.1rem;
  border-radius: 0.6rem;
}


