﻿@import url("../../Mixins/_mixins");

.winning-numbers-game-selector {
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 30rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 3.2rem;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0.2rem;
    bottom: 0.2rem;
    left: 0.2rem;
    right: 0.2rem;
    border: 0.1rem solid @ds-gray-100;
    border-radius: 2.3rem;
  }

  &--no-joker {
    cursor: auto;
  }

  @media @xxl {
    margin-bottom: 4.2rem;
  }
}

.winning-numbers-game-selector__button {
  position: relative;
  z-index: 1;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-repeat: no-repeat;
  mask-position: center;

  &--lotto {
    flex: 0 0 60%;
    background-color: @ds-red;
    mask-size: 8rem 3rem;
    mask-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#lotto-white_use");
  }

  &--vikinglotto {
    flex: 0 0 60%;
    background-color: @ds-blue;
    mask-size: 12rem 3rem;
    mask-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#vikinglotto-white_use");
  }

  &--eurojackpot {
    flex: 0 0 60%;
    background-size: 13rem 3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#eurojackpot_use");
  }

  &--joker {
    position: absolute;
    left: 55%;
    width: 45%;
    background-color: @ds-lightgreen;
    mask-size: 7.5rem 2rem;
    mask-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#joker-white_use");
  }

  &--selected {
    background-color: @ds-white;

    .winning-numbers-game-selector__button--eurojackpot& {
      background-color: transparent;
    }
  }

  .winning-numbers-game-selector--no-joker & {
    flex: 1;
  }
}

.winning-numbers-game-selector__animation {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 2.4rem;
  transition: all 200ms ease;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 0;
    width: 0;
    border-right: solid 0.7rem transparent;
    border-left: solid 0.7rem transparent;
    border-top: solid 0.4rem transparent;
    transform: translateX(-50%);
    bottom: -0.4rem;
    left: 50%;
  }

  &--lotto {
    background: @ds-red;
    width: 60%;

    &::after {
      border-top-color: @ds-red;
    }
  }

  &--vikinglotto {
    background: @ds-blue;
    width: 60%;

    &::after {
      border-top-color: @ds-blue;
    }
  }

  &--eurojackpot {
    background: @ds-black;
    width: 60%;

    &::after {
      border-top-color: @ds-black;
    }
  }

  &--joker {
    left: 55%;
    background: @ds-lightgreen;
    width: 45%;

    &::after {
      border-top-color: @ds-lightgreen;
    }
  }

  .winning-numbers-game-selector--no-joker & {
    left: 0;
    width: 100%;
  }
}
