/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.multi-teaser-cards {
  position: relative;
  max-width: 114rem;
  width: 100%;
  margin: 0 auto;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 3.2rem 2rem;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards {
    padding: 6rem 2rem;
  }
}
.multi-teaser-cards * {
  box-sizing: border-box;
}
.multi-teaser-cards--plus .multi-teaser-cards__card {
  background-color: rgba(0, 101, 67, 0.09);
}
.multi-teaser-cards--lotto .multi-teaser-cards__card {
  background-color: rgba(197, 0, 5, 0.09);
}
.multi-teaser-cards--vikinglotto .multi-teaser-cards__card {
  background-color: rgba(0, 0, 255, 0.09);
}
.multi-teaser-cards--eurojackpot .multi-teaser-cards__card {
  background-color: rgba(232, 163, 50, 0.25);
}
.multi-teaser-cards--center-mobile .multi-teaser-cards__cards {
  justify-content: center;
  gap: 1.6rem;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards--center-mobile .multi-teaser-cards__cards {
    justify-content: inherit;
    gap: inherit;
  }
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards--center-desktop .multi-teaser-cards__cards {
    justify-content: center;
    gap: 3rem;
  }
}
.multi-teaser-cards--items-per-row-mobile-1 .multi-teaser-cards__cards {
  flex-direction: column;
}
.multi-teaser-cards--items-per-row-mobile-1 .multi-teaser-cards__card {
  flex: 0 1 100%;
}
.multi-teaser-cards--items-per-row-mobile-1 h3.multi-teaser-cards__card__title {
  text-align: left;
}
.multi-teaser-cards--items-per-row-mobile-2 .multi-teaser-cards__cards {
  flex-direction: row;
}
.multi-teaser-cards--items-per-row-mobile-2 .multi-teaser-cards__card {
  flex: 0 1 calc(50% - 0.8rem);
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
}
.multi-teaser-cards--items-per-row-mobile-2 .multi-teaser-cards__card__text {
  text-align: center;
}
.multi-teaser-cards--items-per-row-mobile-2 .multi-teaser-cards__card__image {
  position: relative;
  margin: 1.5rem auto;
  left: auto;
  width: 5rem;
  height: 5rem;
  top: 0;
}
.multi-teaser-cards--items-per-row-mobile-2 .multi-teaser-card__cta-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards--items-per-row-desktop-1 .multi-teaser-cards__cards {
    flex-direction: column;
    gap: 3rem;
  }
  .multi-teaser-cards--items-per-row-desktop-1 .multi-teaser-cards__card {
    flex: 0 1 100%;
    min-height: 20rem;
    padding: 3.2rem;
  }
  .multi-teaser-cards--items-per-row-desktop-1 h3.multi-teaser-cards__card__title {
    margin-top: 1.5rem;
    text-align: center;
  }
  .multi-teaser-cards--items-per-row-desktop-2 .multi-teaser-cards__cards {
    flex-direction: row;
    gap: 3rem;
  }
  .multi-teaser-cards--items-per-row-desktop-2 .multi-teaser-cards__card {
    flex: 0 1 calc(50% - 2rem);
    padding: 3.2rem;
  }
  .multi-teaser-cards--items-per-row-desktop-2 h3.multi-teaser-cards__card__title {
    margin-top: 1.5rem;
    text-align: center;
  }
  .multi-teaser-cards--items-per-row-desktop-3 .multi-teaser-cards__cards {
    flex-direction: row;
    gap: 3rem;
  }
  .multi-teaser-cards--items-per-row-desktop-3 .multi-teaser-cards__card {
    flex: 0 1 calc(33.333% - 2rem);
    padding: 3.2rem;
  }
  .multi-teaser-cards--items-per-row-desktop-3 h3.multi-teaser-cards__card__title {
    margin-top: 1.5rem;
    text-align: center;
  }
  .multi-teaser-cards--items-per-row-desktop-4 .multi-teaser-cards__cards {
    flex-direction: row;
    gap: 2rem;
  }
  .multi-teaser-cards--items-per-row-desktop-4 .multi-teaser-cards__card {
    flex: 0 1 calc(25% - 2rem);
    padding: 3.2rem;
  }
  .multi-teaser-cards--items-per-row-desktop-4 h3.multi-teaser-cards__card__title {
    margin-top: 1.5rem;
    text-align: center;
  }
}
h2.multi-teaser-cards__header {
  font-size: 2.8rem;
  line-height: 3.813rem;
  text-align: center;
  font-weight: 700;
  margin: 1.2rem auto;
}
@media screen and (min-width: 768px) {
  h2.multi-teaser-cards__header {
    font-size: 4rem;
    line-height: 5.447rem;
    margin: 2rem auto;
  }
}
.multi-teaser-cards__content {
  color: #828282;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  margin-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__content {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 7rem;
  }
}
.multi-teaser-cards__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.6rem;
  min-width: 33rem;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__cards {
    flex-direction: row;
    gap: 3rem;
  }
}
.multi-teaser-cards__card {
  position: relative;
  flex: 0 1 calc(33.333% - 2rem);
  min-height: 10.4rem;
  background-color: #eeeeee;
  /* Light grey background for visibility */
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;
  padding: 1.6rem 1.6rem 1.6rem 10rem;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__card {
    min-height: 30.8rem;
    align-items: center;
  }
}
.multi-teaser-cards__card__image {
  position: absolute;
  left: 2.6rem;
  width: 4.5rem;
  height: 4.5rem;
  top: 2.6rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__card__image {
    position: relative;
    margin: 1.5rem auto;
    left: auto;
    width: 7rem;
    height: 7rem;
    top: auto;
  }
}
.multi-teaser-cards__card__image:before {
  content: "";
  position: absolute;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  bottom: -1rem;
  border-radius: 50%;
  mix-blend-mode: multiply;
  background: #ffffff;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__card__image:before {
    top: -1.5rem;
    left: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
  }
}
h3.multi-teaser-cards__card__title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  letter-spacing: 0.5px;
  margin-top: 0.3rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  h3.multi-teaser-cards__card__title {
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
    text-align: center;
    line-height: 3.2rem;
  }
}
.multi-teaser-cards__card__text {
  flex-grow: 1;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__card__text {
    text-align: center;
  }
}
.multi-teaser-card__cta-container {
  position: relative;
  flex-grow: 0;
  padding-top: 3rem;
  margin-left: -8.4rem;
  width: calc(100% + 8.4rem);
}
@media screen and (min-width: 768px) {
  .multi-teaser-card__cta-container {
    margin-left: auto;
    width: 100%;
  }
}
.multi-teaser-card__cta-container .multi-teaser-cards__cta {
  margin-top: 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .multi-teaser-card__cta-container .multi-teaser-cards__cta {
    text-align: center;
  }
}
.multi-teaser-cards__cta {
  margin-top: 5rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .multi-teaser-cards__cta {
    margin-top: 7rem;
  }
}
.multi-teaser-cards__cta_button {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 5.6rem;
  max-width: 24rem;
  width: 100%;
}
.multi-teaser-cards__cta_button:not(:disabled):hover::after {
  transform: scale(1.03);
}
.multi-teaser-cards__cta_button:after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0.1rem solid transparent;
  background-color: #FEB700;
  border-color: #FEB700;
}
