@import url("../../Mixins/_mixins.less");

.region-eurojackpot {

  .informationswitcherspotview {

    .circlepromotionspot {

      .richtext {

        .circle-with-number {
          background: @numbergames-white;
          border-radius: 43%;

          .number {
            color: @eurojackpot-gray;
          }
        }

        .circle-promotion-headline {
          max-width: 86.5vw;
          font-size: 9vw;
          font-weight: 800;
          line-height: 9vw;
          color: @numbergames-white;
          padding-bottom: 2.666vw;
          margin: 0 auto;

          @media (min-width: @desktop-width) {
            font-size: 3.8rem;
            line-height: 4rem;
            padding-bottom: 3rem;
          }
        }

        .circle-promotion-text {
          color: @numbergames-white;
        }
      }
    }
  }
}
