@import "_imports.less";

@multiStepsBannerBackgroundColor: @ds-gray-925;

:root {
  --multiStepsBannerBackgroundColor: @multiStepsBannerBackgroundColor;

  .region-vikinglotto, .region-avalon-vikinglotto {
    --multiStepsBannerBackgroundColor: @ds-blue;
  }

  .region-allornothing, .region-avalon-allornothing {
    --multiStepsBannerBackgroundColor: @all-or-nothing-dark-purple;
  }

  .region-eurojackpot, .region-avalon-eurojackpot {
    --multiStepsBannerBackgroundColor: @ds-gray-950;
  }

  .region-keno, .region-avalon-keno {
    --multiStepsBannerBackgroundColor: @ds-light-blue;
  }

  .region-lotto, .region-avalon-lotto {
    --multiStepsBannerBackgroundColor: @ds-red;
  }

  .region-playtogether, .region-avalon-playtogether {
    --multiStepsBannerBackgroundColor: @play-together-blue-700;
  }

  .region-quick, .region-avalon-quick {
    --multiStepsBannerBackgroundColor: @ds-purple;
  }

  .region-subscription, .region-avalon-subscription {
    --multiStepsBannerBackgroundColor: @color-dark-grey;
  }
}

.multi-steps-banner {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  height: auto;
  justify-content: center;
  align-content: center;
  display: flex;
  max-width: @xxl-breakpoint;
  margin-left: auto;
  margin-right: auto;
}

.multi-steps-banner__wrapper {
  background-color: var(--multiStepsBannerBackgroundColor, @multiStepsBannerBackgroundColor);
  border-radius: 3rem;
  padding: 3rem 2rem;
  display: inline-block;
  width: 100%;
  margin: 0 2rem;
  text-align: center;

  @media @sm {
    padding: 3rem;
    margin: 0 5rem;
    width: 100%;
  }

  @media @xl {
    display: flex;
    padding: 0;
    width: 100%;
    text-align: center;
    max-width: @xxl-breakpoint;
    margin: 0 5rem;
  }
}

.multi-steps-banner__step {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  @media @sm {
    width: 33.3%;
    margin-bottom: 0;
  }

  @media @xl {
    width: 0;
    flex: 1 1 0;
    padding: 5rem 0;
    margin-top: 0;
  }
}

.multi-steps-banner__img {
  width: 10rem;
  height: 10rem;
  margin-bottom: 1.2rem;
}

.multi-steps-banner__text {
  font-size: @font-size;
  line-height: 2.8rem;
  padding: 0 1rem;
  color: @numbergames-white;
  font-weight: 700;
  overflow-wrap: break-word;
  margin-bottom: 0;

  @media @sm {
    padding: 0 4rem;
  }

  a {
    color: @numbergames-white;
    text-decoration: underline;
  }
}
