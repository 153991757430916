/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.winning-numbers-game-selector {
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 30rem;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 3.2rem;
}
.winning-numbers-game-selector::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0.2rem;
  bottom: 0.2rem;
  left: 0.2rem;
  right: 0.2rem;
  border: 0.1rem solid #eeeeee;
  border-radius: 2.3rem;
}
.winning-numbers-game-selector--no-joker {
  cursor: auto;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-game-selector {
    margin-bottom: 4.2rem;
  }
}
.winning-numbers-game-selector__button {
  position: relative;
  z-index: 1;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-repeat: no-repeat;
  mask-position: center;
}
.winning-numbers-game-selector__button--lotto {
  flex: 0 0 60%;
  background-color: #c50005;
  mask-size: 8rem 3rem;
  mask-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#lotto-white_use");
}
.winning-numbers-game-selector__button--vikinglotto {
  flex: 0 0 60%;
  background-color: #0000ff;
  mask-size: 12rem 3rem;
  mask-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#vikinglotto-white_use");
}
.winning-numbers-game-selector__button--eurojackpot {
  flex: 0 0 60%;
  background-size: 13rem 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#eurojackpot_use");
}
.winning-numbers-game-selector__button--joker {
  position: absolute;
  left: 55%;
  width: 45%;
  background-color: #009a60;
  mask-size: 7.5rem 2rem;
  mask-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#joker-white_use");
}
.winning-numbers-game-selector__button--selected {
  background-color: white;
}
.winning-numbers-game-selector__button--eurojackpot.winning-numbers-game-selector__button--selected {
  background-color: transparent;
}
.winning-numbers-game-selector--no-joker .winning-numbers-game-selector__button {
  flex: 1;
}
.winning-numbers-game-selector__animation {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 2.4rem;
  transition: all 200ms ease;
  left: 0;
}
.winning-numbers-game-selector__animation::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 0;
  width: 0;
  border-right: solid 0.7rem transparent;
  border-left: solid 0.7rem transparent;
  border-top: solid 0.4rem transparent;
  transform: translateX(-50%);
  bottom: -0.4rem;
  left: 50%;
}
.winning-numbers-game-selector__animation--lotto {
  background: #c50005;
  width: 60%;
}
.winning-numbers-game-selector__animation--lotto::after {
  border-top-color: #c50005;
}
.winning-numbers-game-selector__animation--vikinglotto {
  background: #0000ff;
  width: 60%;
}
.winning-numbers-game-selector__animation--vikinglotto::after {
  border-top-color: #0000ff;
}
.winning-numbers-game-selector__animation--eurojackpot {
  background: #000000;
  width: 60%;
}
.winning-numbers-game-selector__animation--eurojackpot::after {
  border-top-color: #000000;
}
.winning-numbers-game-selector__animation--joker {
  left: 55%;
  background: #009a60;
  width: 45%;
}
.winning-numbers-game-selector__animation--joker::after {
  border-top-color: #009a60;
}
.winning-numbers-game-selector--no-joker .winning-numbers-game-selector__animation {
  left: 0;
  width: 100%;
}
