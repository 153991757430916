@import (reference) "../Mixins/_mixins.less";
@import url("../Mixins/_button.less");
@import "../../../AvalonComponents/Styles/_imports.less";

.lottie-top-spot {
  color: var(--text-color, @ds-white);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  z-index: 1;

  .responsive-bg {
    background-color: var(--background-color, @ds-green);
    position: absolute;
    z-index: -2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
    picture {
      width: 100%;
      height: 100%;
  
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.lottie-top-spot__content-wrapper {
  text-align: center;
  padding: 4rem 0 5rem;
}

.lottie-top-spot__header {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 3rem;
}

.lottie-top-spot__logo:not(:empty) {
  padding: 0 2rem;

  img {
    max-width: 100%;
    height: auto;
  }
}

.lottie-top-spot__description {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 700;
  white-space: break-spaces;
  margin-top: 3rem;
}

.lottie-top-spot__cta {
  .btn();
  .btn-width-default();
  .btn-height-default();
  .btn-primary();
  .btn-hover-scale();
  display: inline-flex;
  margin-top: 1.8rem;
}

.lottie-top-spot__lottie {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .lottie-top-spot__lottie-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .lottie-top-spot__lottie-player {
    position: absolute;
    top: 0;
    width: 500rem;
    height: 100%;
  }
}

.lottie-top-spot--as-banner {
  max-width: @content-max-width;
  width: calc(100% - 4rem);
  margin: auto;
  overflow: hidden;
  padding: 2rem 0;

  .responsive-bg {
    border-radius: 40px;
    overflow: hidden;
    top: 2rem;
    bottom: 2rem;
  }

  .lottie-top-spot__content {
    max-width: 100%;
    padding: 0 2.4rem;
  }

  .lottie-top-spot__content-wrapper {
    padding: 5.6rem 0;
  }

  .lottie-top-spot__header {
    margin-bottom: 2.4rem;
  }

  .lottie-top-spot__description {
    font-size: 10rem;
    line-height: 1;
    font-weight: 800;
    margin-top: 0;
  }

  .lottie-top-spot__cta {
    margin-top: 4rem;
  }
}