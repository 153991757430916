@import url("../Mixins/_mixins.less");

.circlepromotionspot {

  .richtext {
    width: 86.667vw;
    text-align: center;
    padding: 7.333vw 0;
    margin: auto;

    @media (min-width: @desktop-width) {
      width: 74rem;
      padding: 3rem 0;
    }

    .circle-promotion-text {
      max-width: 86.667vw;
      line-height: 1.3;
      margin: 0 auto;
      margin-bottom: 8vw;

      @media (min-width: @desktop-width) {
        width: 45rem;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    .circle-with-number {
      display: inline-block;
      position: relative;
      width: 10rem;
      height: 10rem;
      margin: 0 auto 1rem;
      border-radius: 50%;

      .number {
        font-size: 4.7rem;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}
