﻿@import (reference) "_button";
@import (reference) "../../../AvalonComponents/Styles/Colors/_colors";
@import (reference) "../../../AvalonComponents/Styles/Variables/breakpoints";
@import "../../../AvalonComponents/Styles/Utility/loading-spinner";

.themeColorsVars() {
  --multiclient-campaign-primary-color-l-remainder: ~'(100 - var(--multiclient-campaign-primary-color-l))';
  --multiclient-campaign-primary-hsl-values: ~'var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1%)';
  --multiclient-campaign-primary-color-100: ~'hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.8)) * 1%))';
  --multiclient-campaign-primary-color-300: ~'hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%))';
  --multiclient-campaign-primary-color-500: ~'hsl(var(--multiclient-campaign-primary-hsl-values))';
  --multiclient-campaign-primary-color-900: ~'hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) - (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%))';

  --multiclient-campaign-secondary-color-l-remainder: ~'(100 - var(--multiclient-campaign-secondary-color-l))';
  --multiclient-campaign-secondary-hsl-values: ~'var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1%)';
  --multiclient-campaign-secondary-color-100: ~'hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%))';
  --multiclient-campaign-secondary-color-300: ~'hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%))';
  --multiclient-campaign-secondary-color-500: ~'hsl(var(--multiclient-campaign-secondary-hsl-values))';
  --multiclient-campaign-secondary-color-900: ~'hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) - (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%))';

  --multiclient-campaign-tertiary-color-l-remainder: ~'(100 - var(--multiclient-campaign-tertiary-color-l))';
  --multiclient-campaign-tertiary-hsl-values: ~'var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1%)';
  --multiclient-campaign-tertiary-color-100: ~'hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.8)) * 1%))';
  --multiclient-campaign-tertiary-color-300: ~'hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%))';
  --multiclient-campaign-tertiary-color-500: ~'hsl(var(--multiclient-campaign-tertiary-hsl-values))';
  --multiclient-campaign-tertiary-color-900: ~'hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) - (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%))';
}

@desktop-width: 960px;
@mobile-width: 768px;

// Defaults:s

// General
@numbergames-white: #FFFFFF;
@numbergames-black: #000000;
@numbergames-cta-yellow: #feb700;
@numbergames-cta-yellow-hover: #f5d900; 
@numbergames-darkgray: #1d1d1d;
@numbergames-mediumgray: #4f5254;
@numbergames-lightgray: #a3a6ab;
@numbergames-extra-lightgray: #dadbdd;
@numbergames-slight-grey: #f1f2f2;
@numbergames-grey: #322b00;
@numbergames-blue-grey: #92969E;

// Subscription
@subscription-blue-gray: #2e3535;

// AllOrNothing
@all-or-nothing-purple: #454362;
@all-or-nothing-light-purple: #65617A;
@all-or-nothing-super-light-purple: #4d496b;
@all-or-nothing-mid-purple: #312F51;
@all-or-nothing-dark-purple: #191339;
@all-or-nothing-sea-green: #56F4AD;
@all-or-nothing-mid-sea-green: #44C289;

// Lotto
@lotto-red: #c50005;
@lotto-maroonred: #a30003;
@lotto-darkred: rgba(149,0,2,1);
@lotto-lightred: #c50105;
@lotto-whitesmoke: #ededed;

// Eurojackpot
@eurojackpot-whitesmoke: #f2f2f2;
@eurojackpot-gray: #1f1f1f;
@eurojackpot-lightgray: #8e8e8e;
@eurojackpot-gold: #bb7f36;
@eurojackpot-lightdeepgold: #47412f;
@eurojackpot-graygold: #74653c;
@eurojackpot-carbon: #171717;
@eurojackpot-darksmoke: #201e20;
@eurojackpot-gold-medium: #E8A332;

// Vikinglotto
@vikinglotto-mediumblue: #0000b4;
@vikinglotto-blue: #0000ff;
@vikinglotto-darkblue: rgba(0,0,120,1); // same as #000078
@vikinglotto-lightgray: #4f5254;
@vikinglotto-whitesmoke: #ededee;

// Keno
@keno-royalblue: #1e55a0;
@keno-powderblue: #00a5eb;
@keno-mediumblue: #99dbf7;
@keno-cadetblue: #b8e6f9;
@keno-lightskyblue: #e0f4fd;
@keno-light-grey: #a3a6ab;

// SummerLotto
@summer-lotto-orange: #E95801;


// Media querie:
@media-querie-for-mobile-portrait: ~"screen and (min-width: 320px) and (max-width: 480px)";
@media-querie-for-mobile-landscape: ~"screen and (min-width: 481px) and (max-width: 767px)";
@media-querie-for-tablet-landscape: ~"screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)";
@media-querie-for-tablet-portrait: ~"screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)";
@media-querie-for-desktop: ~"screen and (min-width: 1024px) and (max-width: 1408px)";
@media-querie-for-large-desktop: ~"screen and (min-width: 1408px)";
