/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.product-links-cta {
  position: relative;
  width: 100%;
  max-width: initial;
  margin: 0 auto;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 2rem 2rem 6rem 2rem;
  box-sizing: border-box;
}
.product-links-cta__content-wrapper {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .product-links-cta__content-wrapper {
    max-width: 76rem;
  }
}
.product-links-cta * {
  box-sizing: border-box;
}
.product-links-cta--plus .product-links-cta__item-image {
  background-color: #006543;
}
.product-links-cta--plus h3.product-links-cta__item-title {
  color: #006543;
}
.product-links-cta--lotto .product-links-cta__item-image {
  background-color: #c50005;
}
.product-links-cta--lotto h3.product-links-cta__item-title {
  color: #c50005;
}
.product-links-cta--vikinglotto .product-links-cta__item-image {
  background-color: #0000ff;
}
.product-links-cta--vikinglotto h3.product-links-cta__item-title {
  color: #0000ff;
}
.product-links-cta--eurojackpot .product-links-cta__item-image {
  background-color: #e8a332;
}
.product-links-cta--eurojackpot h3.product-links-cta__item-title {
  color: #000000;
}
h2.product-links-cta__header {
  font-size: 2.8rem;
  line-height: 3.4rem;
  text-align: center;
  margin: 2rem auto;
}
@media screen and (min-width: 768px) {
  h2.product-links-cta__header {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}
.product-links-cta__content {
  position: relative;
  color: #828282;
  text-align: center;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .product-links-cta__content {
    margin: 0 auto;
  }
}
.product-links-cta__items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4.8rem;
  row-gap: 2rem;
  margin: 4.8rem auto 0 auto;
  max-width: 24rem;
}
@media screen and (min-width: 768px) {
  .product-links-cta__items {
    row-gap: normal;
    margin: 6rem auto 0 auto;
    max-width: initial;
  }
}
.product-links-cta__item {
  position: relative;
  display: flex;
  flex-basis: 9rem;
  flex-direction: column;
  text-decoration: none;
  min-height: 15rem;
}
.product-links-cta__item:visited {
  text-decoration: none;
}
.product-links-cta__item:hover {
  text-decoration: none;
}
.product-links-cta__item:active {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .product-links-cta__item {
    flex-basis: 14rem;
    min-height: 30rem;
  }
}
.product-links-cta__item-image {
  position: relative;
  width: 8.4rem;
  height: 8.4rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 4.2rem 4.2rem;
  border-radius: 2rem;
  margin: 0 auto;
  background-color: #eeeeee;
  /* Light grey background for visibility */
}
@media screen and (min-width: 768px) {
  .product-links-cta__item-image {
    width: 12rem;
    height: 12rem;
    border-radius: 2.6rem;
    background-size: 6.8rem 6.8rem;
  }
}
h3.product-links-cta__item-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0.7rem 0 0rem 0;
  letter-spacing: 0.1rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  h3.product-links-cta__item-title {
    font-size: 1.6rem;
    margin: 1.4rem 0 1.8rem 0;
  }
}
.product-links-cta__item-text {
  color: #8e8e8e;
  font-weight: 600;
  line-height: 1.8rem;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  max-width: 9rem;
  text-align: center;
  margin: 0 auto -2rem auto;
}
@media screen and (min-width: 768px) {
  .product-links-cta__item-text {
    max-width: 11.5rem;
    font-size: 1.6rem;
  }
}
.product-links-cta__item-container {
  position: relative;
  margin-top: auto;
  text-align: center;
}
.product-links-cta__item-button {
  position: relative;
  display: none;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 5.6rem;
  max-width: 14rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .product-links-cta__item-button {
    display: inline-flex;
  }
}
.product-links-cta__item-button:not(:disabled):hover::after {
  transform: scale(1.03);
}
.product-links-cta__item-button:after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0.1rem solid transparent;
  background-color: #FEB700;
  border-color: #FEB700;
}
