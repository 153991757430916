@keyframes eurojackpot-shake-overlay-slide-animation {
  0% {
    transform: translateX(0);
  }
  92% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(105%);
  }
}
.eurojackpot-classic-game .shake-overlay-wrapper,
.eurojackpot-system-game .shake-overlay-wrapper {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #2E3535;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  animation: eurojackpot-shake-overlay-slide-animation 4s linear forwards;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper {
  position: relative;
  transform: translateY(25vh);
}
@keyframes eurojackpot-phone-shake-animation {
  0% {
    visibility: visible;
    transform: translateY(-100%) rotate(0);
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  8% {
    transform: translateY(0) rotate(0);
  }
  15% {
    transform: translateY(-25%) rotate(0);
  }
  25% {
    transform: translateY(0) rotate(0);
  }
  30% {
    transform: translateY(-10%) rotate(0);
  }
  35% {
    transform: translateY(0) rotate(0);
  }
  37.5% {
    transform: translateY(-5%) rotate(0);
  }
  40% {
    transform: translateY(0) rotate(0);
  }
  42.5% {
    transform: rotate(20deg);
  }
  45% {
    transform: rotate(0);
  }
  47.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(0);
  }
  52.5% {
    transform: rotate(20deg);
  }
  55% {
    transform: rotate(0);
  }
  57.5% {
    transform: rotate(-20deg);
  }
  60% {
    transform: rotate(0);
  }
  62.5% {
    transform: rotate(20deg);
  }
  65% {
    transform: rotate(0);
  }
  67.5% {
    transform: rotate(-20deg);
  }
  70% {
    transform: rotate(0);
  }
  72.5% {
    transform: rotate(15deg);
  }
  75% {
    transform: rotate(0);
  }
  77.5% {
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(0);
  }
  82.5% {
    transform: rotate(15deg);
  }
  85% {
    transform: rotate(0);
  }
  87.5% {
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(0);
  }
  92.5% {
    transform: rotate(10deg);
  }
  95% {
    transform: rotate(0);
  }
  97.5% {
    transform: rotate(-10deg);
  }
  100% {
    visibility: visible;
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes eurojackpot-phone-shadow-animation {
  0% {
    transform: translateY(-200%) scale(0.5);
    opacity: 0;
  }
  15% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  30% {
    transform: translateY(-75%) scale(0.7);
    opacity: .6;
  }
  40% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-25%) scale(0.9);
    opacity: .8;
  }
  60% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper {
  width: 26vw;
  position: relative;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  animation: eurojackpot-phone-shake-animation 2.5s 0.7s linear forwards;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .phone-shape,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .phone-shape {
  width: 26vw;
  height: 46.666vw;
  fill: #635839;
  position: relative;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines {
  width: 4.733vw;
  height: 9.333vw;
  position: absolute;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:before,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:before,
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:after,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:after {
  content: '';
  width: 1.333vw;
  background-color: #111111;
  position: absolute;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:before,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:before {
  height: 100%;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:after,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:after {
  height: 70%;
  top: 15%;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:first-of-type,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:first-of-type {
  top: 6.666vw;
  left: -7.666vw;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:first-of-type:before,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:first-of-type:before {
  right: 0;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:first-of-type:after,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:first-of-type:after {
  left: 0;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:last-of-type,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:last-of-type {
  bottom: 6.666vw;
  right: -7.666vw;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:last-of-type:before,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:last-of-type:before {
  left: 0;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:last-of-type:after,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shape-whrapper .shake-lines:last-of-type:after {
  right: 0;
}
.eurojackpot-classic-game .shake-overlay-wrapper .phone-whrapper .phone-shadow,
.eurojackpot-system-game .shake-overlay-wrapper .phone-whrapper .phone-shadow {
  width: 26vw;
  height: 2.5vw;
  background-color: #111111;
  border-radius: 50%;
  position: absolute;
  bottom: -7.5vw;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  animation: eurojackpot-phone-shadow-animation 1.4s 0.7s linear forwards;
}
@keyframes eurojackpot-shake-text-animation {
  0% {
    transform: translateY(-50vh) scale(1.4);
  }
  10% {
    transform: translateY(-50vh) scale(1.4);
  }
  20% {
    transform: translateY(-50vh) scale(1.6);
  }
  30% {
    transform: translateY(-50vh) scale(1.4);
  }
  40% {
    transform: translateY(-50vh) scale(1.6);
  }
  50% {
    transform: translateY(-50vh) scale(1.4);
  }
  100% {
    transform: translateY(-10vh) scale(1);
  }
}
.eurojackpot-classic-game .shake-overlay-wrapper .shake-overlay-text,
.eurojackpot-system-game .shake-overlay-wrapper .shake-overlay-text {
  width: 60%;
  font-size: 5.6vw;
  line-height: 1.3;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-50vw) scale(1);
  animation: eurojackpot-shake-text-animation 0.5s 0.1s linear forwards;
}
@keyframes eurojackpot-shake-overlay-remove-animation {
  0% {
    left: 0;
  }
  100% {
    left: 100vw;
  }
}
.eurojackpot-classic-game .shake-overlay-wrapper.remove,
.eurojackpot-system-game .shake-overlay-wrapper.remove {
  animation: eurojackpot-shake-overlay-slide-animation 1s forwards linear;
}
