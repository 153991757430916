@import url("../Mixins/_mixins.less");

.eurojackpot-promotion-spot {

  .eurojackpot-section {
    position: relative;
    // Position all other elements above background and video
    > * {
      position: relative;
      z-index: 2;
    }
  }
  .eurojackpot-image-wrapper {
    padding: 5vh;
  }
  .btn {
    margin: 3vh
  }

}
