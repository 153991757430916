@import (reference) "../../Mixins/_mixins.less";

.multi-packages-section {
  position: relative;
  transform: translateZ(0);
  padding: 4rem 0 2rem;
  background-color: @ds-white;

  .popover-drawer {
    height: 100%;
    border-bottom: 1px solid @ds-black;

    .popover-drawer__card {
      height: 96%;
    }
  }

  .responsive-bg {
    justify-content: center;
    top: 5rem;
  }
}

.multi-packages-header {
  max-width: 74rem;
  margin: auto;
  text-align: center;
  padding: 0 2.4rem;
  @media @sm {
    padding: 0;
  }

  .multi-packages-header__heading {
    font-size: 2.8rem;
    line-height: 3.4rem;
    font-weight: 700;

    @media @sm {
      font-size: 4rem;
      line-height: 4.8rem;
    }
  }

  .multi-packages-header__subheading {
    max-width: 52rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin: 1.6rem auto 0;
  }

  & + .multi-packages-inner-container {
    margin-top: 4rem;
    @media @sm {
      margin-top: 0;
    }
  }
}

.multi-packages-inner-container {
  display: flex;
  flex-direction: column;

  .multi-packages-tabs__wrapper {
    @media @sm {
      margin-top: 2.4rem;
    }
  }

  .multi-packages-tabs {
    margin: 0 2.4rem;
    background-color: fade(@ds-white, 80%);
  }
}

.multi-packages-packages-list,
.multi-packages-packages-list.tns-autowidth {
  width: fit-content;
  display: inline-flex;
  padding: 2.4rem 0 3.4rem;
  box-sizing: border-box;
  margin: auto;

  @media @sm {
    display: flex;
    align-items: flex-end;
    padding: 4rem 0 0;
  }
}

.multi-packages-package-card__divider {
  width: 12.8rem;
  color: @numbergames-blue-grey;
  font-family: "Shadows Into Light", cursive;
  text-align: center;
  padding: 0 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.multi-packages-package-card__divider-text {
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 500;
}

//packages-tabs
.multi-packages-tabs__wrapper {
  display: flex;
  justify-content: center;
}

.multi-packages-tabs {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 1px solid @ds-gray-400;
  border-radius: 28px;
  position: relative;

  @media @sm {
    width: fit-content;
  }

  .multi-packages-tab {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0.8rem;
    transition: opacity 0.15s linear, color 0.15s 0.1s linear;
    cursor: pointer;
    position: relative;
    margin: 0 0.4rem;
    text-align: center;
    flex: 1;
    white-space: nowrap;

    @media @sm {
      font-size: 1.6rem;
      line-height: 1.4rem;
      padding: 1rem 2rem;
      font-weight: 800;
    }

    &:hover:not(.multi-packages-tab--active) {
      opacity: 0.6;
    }

    &--active {
      color: @ds-white;
    }
  }

  .multi-packages-tab__active-indicator {
    position: absolute;
    height: 100%;
    transition: width 0.15s linear, left 0.15s linear, background-color 0.15s linear;
    scale: 1.15;
    border-radius: 28px;
    left: 0;

    .region-lotto & {
      background-color: @ds-red;
    }
    .region-vikinglotto & {
      background-color: @ds-blue;
    }
    .region-eurojackpot & {
      background-color: @ds-honey;
    }

    &.multi-packages-tab__active-indicator--lotto {
      background-color: @ds-red;
    }
    &.multi-packages-tab__active-indicator--vikinglotto {
      background-color: @ds-blue;
    }
    &.multi-packages-tab__active-indicator--eurojackpot {
      background-color: @ds-honey;
    }
    &.multi-packages-tab__active-indicator--joker {
      background-color: @ds-lightgreen-400;
    }
  }
}

.multi-packages-button {
  .btn();
  .btn-primary();
  .btn-height-default();
  .btn-with-x-padding();
  .btn-hover-scale();

  &.multi-packages-button--outlined {
    &::after {
      background-color: @ds-white;
    }
  }
}