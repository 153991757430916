.check-button {
  padding: .6rem;
  border-radius: 2.5rem;
  transition: background-color 0.1s linear;
  cursor: pointer;
  display: flex;
  position: relative;
}
.check-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
  transform: scaleX(0);
  opacity: 0;
}
.check-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.check-button.check-button--disabled {
  opacity: 0.5;
  cursor: default;
}
.check-button.check-button--disabled .check-button__dot {
  cursor: default;
}
.check-button.check-button--disabled .check-button__label {
  text-decoration: line-through;
}
.check-button .check-button__dot {
  position: relative;
  z-index: 1;
  background-color: white;
  border: 0.4rem solid;
  border-radius: 50%;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  transition: border 100ms ease-out;
  flex-shrink: 0;
  box-sizing: content-box;
}
.check-button .check-button__dot::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  border: 1rem solid #ffffff;
  border-radius: 50%;
  opacity: 0;
}
.check-button .check-button__body {
  flex: 1;
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-weight: bold;
  padding-left: 1.3rem;
  transition: color 200ms ease-out;
  justify-content: center;
}
.check-button .check-button__body .check-button__labels {
  min-height: 3.8rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.check-button .check-button__body .check-button__labels .check-button__label {
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.check-button .check-button__body .check-button__labels .check-button__sublabel {
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.check-button .icon-indicator {
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: color 150ms ease-out;
}
.check-button .icon-indicator .icon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
  fill: currentColor;
}
.check-button .icon-indicator + .secondary-cta {
  margin-left: -1.2rem;
}
.check-button .secondary-cta {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.8rem;
  margin-top: 0.2rem;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.check-button .secondary-cta .icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
  transition: transform 100ms linear;
}
.check-button .secondary-cta:hover .icon {
  transform: scale(1.1);
}
.check-button .secondary-cta.active:hover .arrow-down-icon {
  transform: scale(1.1) rotate(180deg);
}
.check-button .secondary-cta.active .arrow-down-icon {
  transform: rotate(180deg);
}
.check-button .details-wrapper {
  overflow: hidden;
  opacity: 0;
  padding-bottom: 0;
  transition: max-height 0.1s linear, opacity 0.1s linear, padding-bottom 0.1s linear;
}
.check-button .details-wrapper.active {
  opacity: 0.8;
  padding-bottom: 0.9rem;
}
.check-button .details-wrapper .detail-item {
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 700;
}
@keyframes multi-client-checked-curtain {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  40% {
    transform: scaleX(1.05);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes multi-client-checked-dot-after {
  from {
    border-width: 1.4rem;
    opacity: 0;
  }
  to {
    border-width: 1rem;
    opacity: 1;
  }
}
.check-button.check-button--selected {
  color: white;
}
.check-button.check-button--selected::before {
  animation: multi-client-checked-curtain 450ms ease-out forwards;
}
.check-button.check-button--selected .check-button__dot::after {
  animation: multi-client-checked-dot-after 150ms ease-in forwards;
}
