﻿@import url("../Mixins/_mixins.less");

@eurojackpot-background-alt: #FDF6EA;

.biggest-wins-overview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 100rem;
  padding: 2.5rem 2rem;
  align-items: center;

  @media @md {
    flex-direction: row;
  }
}

.biggest-wins-overview-table {
  position: relative;
  border-radius: 20px;
  max-width: 42.7rem;
  min-width: 32.0rem;
  overflow: hidden;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 1px;
  order: 2;
  width: 100%;

  @media @md {
    order: initial;
    width: 100%
  }
}

.biggest-wins-overview-table__header {
  height: 6.4rem;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.biggest-wins-overview-table__header-cell {
  &:first-child {
    width: 45%;
  }

  &:last-child {
    width: 55%;
  }
}

.biggest-wins-overview-table__content {
  color: @ds-black;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
}

.biggest-wins-overview-table__content-row {
  display: flex;
  font-weight: 700;
  height: 5.6rem;
  align-items: center; /* Vertically center content */
}

.biggest-wins-overview-table__content-cell {
  box-sizing: border-box;
  text-align: center;

  &:first-child {
    width: 45%;

    & > span {
      width: 4rem;
    }
  }

  &:last-child {
    width: 55%;

    & > span {
      width: 13.5rem;
     
    }
  }

  & > span {
    text-align: right;
    display: inline-block;
  }
}

.biggest-wins-overview-text {
  position: relative;
  order: 1;
  padding-bottom: 4rem;

  @media @md {
    order: initial;
    padding-bottom: initial;
    max-width: 42.7rem;
    padding-left: 13rem;
  }
}

.biggest-wins-overview-text__content {
  color: #92969E;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  text-align: left;

  @media @md {
    font-size: 1.6rem;
    width: 100%;
  }
}

.biggest-wins-overview-text__content-cta {
  margin-top: 1.8rem;
  display: none;

  @media @md {
    display: inline-flex;
  }
}

.biggest-wins-overview-cta {
  position: relative;
  order: 3;
  margin-top: 3rem;

  @media @md {
    order: initial;
    display: none;
  }
}

.biggest-wins-overview--lotto {
  .biggest-wins-overview-table__content {
    border-left: solid 1px fade(@ds-red, 5%);
    border-right: solid 1px fade(@ds-red, 5%);
    border-bottom: solid 1px fade(@ds-red, 5%);
  }

  .biggest-wins-overview-table__header {
    background-color: @ds-red;
    color: #fff;
  }

  .biggest-wins-overview-table__content-row {
    &:nth-child(2n-1) {
      background-color: fade(@ds-red, 5%);
    }
  }
}

.biggest-wins-overview--vikinglotto {
  .biggest-wins-overview-table__content {
    border-left: solid 1px fade(@ds-blue, 5%);
    border-right: solid 1px fade(@ds-blue, 5%);
    border-bottom: solid 1px fade(@ds-blue, 5%);
  }

  .biggest-wins-overview-table__header {
    background-color: @ds-blue;
    color: #fff;
  }

  .biggest-wins-overview-table__content-row {
    &:nth-child(2n-1) {
      background-color: fade(@ds-blue, 5%);
    }
  }
}

.biggest-wins-overview--eurojackpot {
  .biggest-wins-overview-table__content {
    border-left: solid 1px @eurojackpot-background-alt;
    border-right: solid 1px @eurojackpot-background-alt;
    border-bottom: solid 1px @eurojackpot-background-alt;
  }

  .biggest-wins-overview-table__header {
    background-color: @ds-gray-825;
    color: #fff;
  }

  .biggest-wins-overview-table__content-row {
    &:nth-child(2n-1) {
      background-color: @eurojackpot-background-alt;
    }
  }
}

.biggest-wins-overview__btn-cta {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: @ds-black;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 5.6rem;
  min-width:24rem;

  &:not(:disabled):hover::after {
    transform: scale(1.03);
  }

  &:after {
    border-radius: 2.8rem;
    transition: transform 200ms ease-out;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.1rem solid transparent;
    background-color: @ds-yellow; 
    border-color: @ds-yellow;
  }
}
