.tutorial-container {
  --tutorial-primary: var(--primaryColor, #1750DC);
  --tutorial-secondary: var(--secondaryColor, #063D9B);
  --tutorial-cta: var(--ctaColor, #FEAF00);
  --tutorial-light-grey: #eceded;
  --tutorial-grey: #A0A0A0;
  --tutorial-tips-table-odd: var(--tipsTableEvenColor, #357856);
  --tutorial-tips-table-even: var(--tipsTableOddColor, #439665);
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}
.tns-slider {
  transition: all 0s;
}
.tns-slider > .tns-item {
  box-sizing: border-box;
}
.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  transition: all 0.25s;
}
.tns-autowidth {
  display: inline-block;
}
.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}
.tns-ah {
  transition: height 0s;
}
.tns-ovh {
  overflow: hidden;
}
.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}
.tns-transparent {
  opacity: 0;
  visibility: hidden;
}
.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}
.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}
.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: calc(100% / 70);
  height: 10px;
  float: left;
}
.slidepage,
.tutorial__simple {
  background-color: var(--tutorial-primary);
}
.slidepage__content {
  overflow: hidden;
}
.slidepage__content--mobile .tns-ovh {
  overflow: visible !important;
}
.slidepage__content--desktop {
  display: none;
}
@media screen and (min-width: 950px) {
  .slidepage__content--mobile {
    display: none;
  }
  .slidepage__content--desktop {
    display: block;
    padding-top: 5rem;
  }
}
.slidepage__content .slidepage__slider {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 950px) {
  .slidepage__content .slidepage__slider {
    flex-direction: row;
    position: relative;
  }
  .slidepage__content .slidepage__slider::before {
    content: '';
    display: block;
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    transition-duration: 0.2s;
  }
  .slidepage__content .slidepage__slider--loading::before {
    opacity: 1;
  }
}
.slidepage__content .slidepage__slider .slidepage__steps {
  transition-duration: 0.3s;
  transform: translateX(3rem);
  display: flex;
  position: relative;
}
@media screen and (min-width: 480px) {
  .slidepage__content .slidepage__slider .slidepage__steps {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 950px) {
  .slidepage__content .slidepage__slider .slidepage__steps {
    width: initial;
    transform: none;
    display: flex !important;
    justify-content: center;
  }
}
.slidepage__content .slidepage__slider .slidepage__steps--fade {
  opacity: 0.5;
  transform: scale(0.8);
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step {
  display: inline-block;
  background: white;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
  height: 37.2rem;
  transition-duration: 0.4s;
  margin: 4.5rem 0 2rem;
  transform-origin: 0 center;
}
@media screen and (min-width: 950px) {
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step {
    flex: 0 0 26.4rem;
    width: 21.9rem;
    height: 34.8rem;
  }
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step:first-child {
    margin-left: 2rem;
  }
}
@media screen and (min-width: 1216px) {
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step {
    height: 48rem;
    flex: 0 0 30rem;
  }
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step--selected {
  transform: rotate(0) !important;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description {
  margin-right: 2rem;
  transform-origin: right bottom;
  transition-delay: 0.15s;
  transform: rotate(-5deg);
  border-radius: 2rem;
  width: 30rem;
}
@media screen and (min-width: 950px) {
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description {
    height: 38.2rem;
    width: 26.4rem;
    transform: none;
    z-index: 1;
    position: relative;
    left: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description {
    width: 29rem;
    height: 47.6rem;
    margin-right: 0;
    left: 0;
  }
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-text-wrapper {
  width: 65%;
  margin: 2rem auto 0 auto;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-text-wrapper .slidepage__step-header {
  margin-top: 4rem;
  margin-bottom: 2rem;
  color: var(--tutorial-primary);
  font-size: 2rem;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  text-align: center;
  line-height: 2rem;
  font-weight: 800;
  font-style: italic;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-text-wrapper .slidepage__step-text {
  font-size: 1.2rem;
  line-height: 2.2rem;
}
@media screen and (min-width: 950px) {
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-text-wrapper .slidepage__step-text {
    font-size: 1.4rem;
  }
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-help-wrapper {
  position: absolute;
  width: 75%;
  top: 85%;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-help-wrapper .slidepage__step-help-circle {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--tutorial-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 105%;
  bottom: 50%;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-help-wrapper .slidepage__step-help-circle::after {
  content: 'i';
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-help-wrapper .slidepage__step-help-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--tutorial-primary);
  display: flex;
  flex-direction: column;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-description .slidepage__step-number {
  background-color: var(--tutorial-primary);
  padding: 0.7rem;
  width: 4rem;
  height: 4rem;
  font-weight: 700;
  font-size: 1.5rem;
  top: 3.5rem;
  left: -2rem;
  color: white;
  position: absolute;
  display: grid;
  justify-items: center;
  border-radius: 0.8rem;
}
.slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-illustration {
  overflow: hidden;
  border-radius: 2rem;
  transform-origin: left bottom;
  transform: rotate(5deg);
  transition-delay: 0.1s;
}
@media screen and (min-width: 480px) {
  .slidepage__content .slidepage__slider .slidepage__steps .slidepage__step.slidepage__step-illustration {
    transform: none;
  }
}
@media screen and (min-width: 950px) {
  .slidepage__content .slidepage__slider .slidepage__steps iframe.slidepage__step.slidepage__step-illustration {
    transform: none;
    flex: 0 0 65.5rem;
    position: relative;
    left: -3rem;
    top: -3rem;
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .slidepage__content .slidepage__slider .slidepage__steps iframe.slidepage__step.slidepage__step-illustration {
    flex: 0 0 67rem;
  }
}
@media screen and (min-width: 1216px) {
  .slidepage__content .slidepage__slider .slidepage__steps iframe.slidepage__step.slidepage__step-illustration {
    left: -3.6rem;
    flex: 0 0 90rem;
    padding: 0;
  }
}
.slidepage__content .slidepage__slider .slidepage__radio-buttons {
  align-self: center;
  display: flex;
}
.slidepage__content .slidepage__slider .slidepage__radio-buttons .slidepage__radio {
  border-radius: 50%;
  border: #000000 solid 0.2rem;
  width: 1rem;
  height: 1rem;
}
.slidepage__content .slidepage__slider .slidepage__radio-buttons .slidepage__radio:first-child {
  margin-right: 1rem;
}
.slidepage__content .slidepage__slider .slidepage__radio-buttons .slidepage__radio.tns-nav-active {
  background-color: #000000;
}
.slidepage__content--desktop .slidepage__controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 132rem;
  margin: auto;
  position: relative;
}
@media screen and (min-width: 950px) {
  .slidepage__content--desktop .slidepage__controls {
    top: -7rem;
    right: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .slidepage__content--desktop .slidepage__controls {
    top: -4rem;
    right: 8.5rem;
  }
}
@media screen and (min-width: 1440px) {
}
.slidepage__content--desktop .slidepage__controls:focus {
  outline: none;
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow {
  width: 6.5rem;
  height: 4.5rem;
  background: var(--tutorial-primary);
  border-radius: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.2s;
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow--hide {
  opacity: 0;
  pointer-events: none;
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow:focus {
  outline: none;
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow::after {
  content: '';
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  border-bottom: white 0.3rem solid;
  border-left: white 0.3rem solid;
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow--next::after {
  transform: rotate(-135deg);
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow--prev {
  margin-right: 2rem;
}
.slidepage__content--desktop .slidepage__controls .slidepage__arrow--prev::after {
  transform: rotate(45deg);
}
.slidepage__content--desktop .slidepage__controls .slidepage__slide-counter {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  font-weight: 800;
  font-size: 1.4rem;
}
.slidepage__content--desktop .slidepage__controls .slidepage__slide-counter .slidepage__current-slide {
  background: var(--tutorial-primary);
  color: white;
  border-radius: 0.8rem;
  font-size: 1.6rem;
}
.slidepage__content--desktop .slidepage__controls .slidepage__slide-counter > div {
  padding: 0.4rem 1.2rem;
}
.slidepage__content--desktop .slidepage__controls .slidepage__slide-counter > div:first-child {
  margin-right: 1rem;
}
.slidepage__content--desktop .slidepage__controls .slidepage__slide-counter .slidepage__separator {
  padding: 0.4rem 0;
}
.slidepage__action-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25.5rem;
  height: 5rem;
  background-color: var(--tutorial-cta);
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
  margin: 4rem auto;
}
@media screen and (min-width: 950px) {
  .slidepage__action-button-wrapper {
    margin: 4rem auto;
  }
}
.slidepage__action-button-wrapper .slidepage__action-button {
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 2rem;
  color: #000000;
}
.slidepage__related-help {
  background-color: var(--tutorial-secondary);
  max-width: 144rem;
  margin: auto;
  padding-bottom: 1rem;
}
@media screen and (min-width: 950px) {
  .slidepage__related-help {
    padding-bottom: 4rem;
  }
}
.slidepage__related-help .slidepage__related-help-title {
  padding-top: 2.9rem;
  font-weight: 800;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  text-align: center;
  line-height: 4rem;
  color: #ffffff;
  margin-bottom: 3.5rem;
}
@media screen and (min-width: 950px) {
  .slidepage__related-help .slidepage__related-help-title {
    font-size: 3rem;
    text-align: left;
    margin-left: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .slidepage__related-help .slidepage__related-help-title {
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 2rem;
    font-size: 3.6rem;
    width: fit-content;
  }
}
.slidepage__related-help .slidepage__related-help-wrapper {
  position: relative;
  background-color: var(--tutorial-secondary);
}
@media screen and (min-width: 1024px) {
  .slidepage__related-help .slidepage__related-help-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 75%;
  }
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block {
  border-radius: 2rem;
  background-color: white;
  padding: 1.5rem;
  margin: 0 2.3rem 1.7rem;
  display: grid;
  grid-template-columns: 5.5rem auto 2.5rem;
  align-items: center;
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block:not(:last-child) {
  margin-right: 2rem;
}
@media screen and (min-width: 1024px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block {
    margin: 0;
    width: 31rem;
    position: relative;
  }
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block:hover {
    background-color: var(--tutorial-light-grey);
  }
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-image-wrapper {
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--tutorial-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 950px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-image-wrapper {
    width: 5.5rem;
    height: 5.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-image-wrapper {
    width: 6.5rem;
    height: 6.5rem;
  }
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-image-wrapper .slidepage__related-help-image {
  width: 45%;
  height: 45%;
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-text-wrapper .slidepage__related-help-element-title {
  color: #000000;
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 1.9rem;
  line-height: 1.5rem;
}
@media screen and (min-width: 950px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-text-wrapper .slidepage__related-help-element-title {
    font-size: 1.8rem;
  }
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-text-wrapper .slidepage__related-help-element-text {
  color: #000000;
  font-size: 1.2rem;
  margin-left: 1.9rem;
  line-height: 1.5rem;
}
@media screen and (min-width: 950px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-text-wrapper .slidepage__related-help-element-text {
    font-size: 1.6rem;
  }
}
.slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-arrow {
  content: "";
  width: 1.3rem;
  height: 1.3rem;
  border-bottom: 0.3rem solid #000000;
  border-right: 0.3rem solid #000000;
  transform: rotate(-45deg);
  position: absolute;
  right: 5rem;
  transition-duration: 0.2s;
}
@media screen and (min-width: 950px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-arrow {
    width: 1.5rem;
    height: 1.5rem;
    right: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .slidepage__related-help .slidepage__related-help-wrapper .slidepage__related-help-wrapper-block .slidepage__related-help-arrow {
    position: absolute;
    right: 2.5rem;
  }
}
.tutorial__searchbar {
  background-color: #ffffff;
  height: 5rem;
  position: relative;
  margin: 4rem 1.5rem 2.7rem 1.5rem;
  display: flex;
  align-items: center;
  padding-left: 1.6rem;
  border-radius: 2rem;
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
}
@media screen and (min-width: 768px) {
  .tutorial__searchbar {
    margin: 5.2rem auto;
    width: 55rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__searchbar {
    margin: 5.8rem auto;
    width: 63.2rem;
  }
}
.tutorial__searchbar .tutorial__searchbar-input {
  border: none;
  margin-left: 1.2rem;
  width: calc(100% - 7rem);
}
.tutorial__searchbar .tutorial__searchbar-input:focus {
  outline: none;
}
.tutorial__searchbar .tutorial__searchbar-input::placeholder {
  color: var(--tutorial-grey);
  font-style: italic;
  font-weight: normal;
}
@media screen and (min-width: 1024px) {
  .tutorial__searchbar .tutorial__searchbar-input::placeholder {
    font-size: 2rem;
  }
}
.tutorial__searchbar .tutorial__searchbar-loop {
  transform: rotate(270deg);
  height: 2.2rem;
  width: 2.2rem;
  stroke: #000000;
  stroke-width: 0.3rem;
  fill: none;
}
.tutorial__searchbar .tutorial__searchbar-clear {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}
.tutorial__searchbar .tutorial__searchbar-clear::after,
.tutorial__searchbar .tutorial__searchbar-clear::before {
  content: '';
  width: 2rem;
  height: 0.2rem;
  background-color: #000000;
  display: block;
  transform-origin: center;
  top: 50%;
  position: absolute;
}
.tutorial__searchbar .tutorial__searchbar-clear::after {
  transform: rotate(45deg);
}
.tutorial__searchbar .tutorial__searchbar-clear::before {
  transform: rotate(-45deg);
}
.tutorial-tips {
  --transition-duration: 0.1s;
  background-color: var(--tutorial-primary);
}
.tutorial-tips__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorial-tips__content-inner {
  margin-top: 4rem;
  width: 28rem;
}
.tutorial-tips__header {
  font-weight: 800;
  font-style: italic;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}
.tutorial-tips__header .tutorial-tips__popup-trigger {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  border-radius: 50%;
  color: white;
  background: var(--tutorial-secondary);
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.tutorial-tips__button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.tutorial-tips__button-wrapper .tutorial-tips__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: 13.4rem;
  height: 4.6rem;
  background: var(--tutorial-light-grey);
  filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.25));
  font-weight: 800;
  font-style: italic;
  cursor: pointer;
  transition-duration: var(--transition-duration);
}
.tutorial-tips__button-wrapper .tutorial-tips__button--selected {
  background: var(--tutorial-secondary);
  color: white;
}
.tutorial-tips__button-wrapper .tutorial-tips__button:first-child {
  margin-right: 2rem;
}
.tutorial-tips__read-more {
  text-align: center;
  margin-bottom: 3.5rem;
}
.tutorial-tips__dropdown {
  width: 28rem;
  min-height: 4.5rem;
  background: var(--tutorial-light-grey);
  filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 800;
  font-style: italic;
  cursor: pointer;
  position: relative;
  margin-bottom: 3.7rem;
  border-radius: 0.5rem;
  transition-duration: var(--transition-duration);
}
.tutorial-tips__dropdown::after {
  content: '';
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-bottom: #000000 solid 0.4rem;
  border-left: #000000 solid 0.4rem;
  right: 2rem;
  top: 1rem;
  transform: rotate(-45deg);
  transform-origin: 25% 75%;
  transition-duration: var(--transition-duration);
}
.tutorial-tips__dropdown .tutorial-tips__dropdown-content {
  display: none;
  width: 100%;
  background: white;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.tutorial-tips__dropdown--open {
  background: var(--tutorial-secondary);
  color: white;
}
.tutorial-tips__dropdown--open::after {
  transform: rotate(135deg);
  border-color: white;
}
.tutorial-tips__dropdown--open .tutorial-tips__dropdown-content {
  display: block;
}
.tutorial-tips__dropdown--selected {
  background: var(--tutorial-secondary);
  color: white;
}
.tutorial-tips__dropdown--selected::after {
  border-color: white;
}
.tutorial-tips__label {
  height: 4.5rem;
  display: grid;
  align-content: center;
}
.tutorial-tips__combination-key {
  color: #000000;
  height: 4.5rem;
  display: grid;
  align-content: center;
  text-align: center;
  border-top: #A8A8A8 solid 0.1rem;
}
.tutorial-tips__combination-key--selected {
  background: var(--tutorial-primary);
  color: white;
}
.tutorial-tips__table-wrapper {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
}
.tutorial-tips__table-selectors {
  border-radius: 2rem 2rem 0 0;
  height: 5.7rem;
  background: var(--tutorial-primary);
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  color: white;
  font-weight: 800;
  font-style: italic;
}
@media screen and (min-width: 768px) {
  .tutorial-tips__table-selectors {
    padding: 0 10rem;
  }
}
.tutorial-tips__table-selectors .tutorial-tips__table-selector {
  cursor: pointer;
  position: relative;
}
.tutorial-tips__table-selectors .tutorial-tips__table-selector::after {
  content: "";
  display: none;
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background: white;
  bottom: 0.2rem;
  left: 0;
  border-radius: 1rem;
}
.tutorial-tips__table-selectors .tutorial-tips__table-selector--active::after {
  display: block;
}
.tutorial-tips__table table {
  color: white;
  text-align: center;
  width: 100%;
}
.tutorial-tips__table table tr {
  height: 4rem;
}
.tutorial-tips__table table tr:nth-child(odd) {
  background: var(--tutorial-tips-table-odd);
}
.tutorial-tips__table table tr:nth-child(even) {
  background: var(--tutorial-tips-table-even);
}
.tutorial-tips__table--guarantee table tr > th:last-child,
.tutorial-tips__table--guarantee table tr > td:last-child {
  float: right;
  margin-top: 0.7rem;
  margin-right: 1rem;
}
@media screen and (min-width: 768px) {
  .tutorial-tips__table--guarantee table tr > th:last-child,
  .tutorial-tips__table--guarantee table tr > td:last-child {
    margin-right: 9rem;
  }
}
.tutorial-tips__table .tutorial-tips__table-title {
  padding: 2rem 0;
  font-weight: 800;
  font-style: italic;
}
.tutorial-tips__table--description {
  color: white;
  background: var(--tutorial-tips-table-odd);
  padding: 0 5rem 2rem 5rem;
}
.tutorial-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorial-overlay__title {
  font-size: 3.5rem;
  margin-bottom: 4rem;
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
}
.tutorial-overlay__text {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 4.5rem;
}
.tutorial-overlay__image {
  max-width: 30rem;
}
.tutorial-navbar {
  width: 100%;
  height: 4.5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  color: white;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .tutorial-navbar {
    margin-left: 5.9rem;
    margin-right: 5.9rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial-navbar {
    display: none;
  }
}
.tutorial-navbar .tutorial-navbar-container {
  overflow-x: auto;
  overflow-y: hidden;
  height: 4.5rem;
  padding-bottom: 12rem;
}
.tutorial-navbar .tutorial-navbar-label {
  position: relative;
  z-index: 2;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-style: italic;
  height: 4.5rem;
  flex: 0 0 12rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-left: 2rem;
  font-size: 1.5rem;
}
.tutorial-navbar .tutorial-navbar-cave {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  left: -0.5rem;
  width: 14rem;
  height: 5.5rem;
  filter: none;
  transition: filter 200ms ease-out;
}
.tutorial-navbar .tutorial-navbar-cave--open {
  filter: drop-shadow(0.3rem 0.3rem 0.4rem rgba(0, 0, 50, 0.25));
}
.tutorial-navbar .tutorial-navbar-cave-inner {
  width: 14rem;
  height: 7rem;
  clip-path: polygon(100% 0%, 96% 30%, 95% 55%, 100% 100%, 0% 100%, 0% 0%, 25% 0%);
  background: var(--tutorial-primary);
}
.tutorial-navbar .tutorial-navbar-navs {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  padding-right: 5rem;
  font-size: 1.4rem;
}
.tutorial-navbar .tutorial-navbar-nav {
  position: relative;
  z-index: 0;
  height: 4.5rem;
  margin-left: 1rem;
  user-select: none;
  padding: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: white;
}
.tutorial-navbar .tutorial-navbar-nav--hide {
  display: none;
}
.tutorial-navbar .tutorial-navbar-nav:last-child {
  padding-right: 3rem;
}
.tutorial-navbar .tutorial-navbar-nav::after {
  position: absolute;
  content: "";
  top: 1.2rem;
  left: -0.5rem;
  bottom: 1rem;
  width: 0.1rem;
  background: rgba(255, 255, 255, 0.2);
}
.tutorial-navbar .tutorial-navbar-nav:first-child::after {
  width: 0;
}
.tutorial__avalon {
  background: var(--tutorial-primary);
}
.tutorial-frontpage {
  padding-top: 2rem;
  background-color: var(--tutorial-primary);
}
.tutorial-frontpage__hero {
  background-color: var(--tutorial-primary);
}
.tutorial-frontpage__hero .tutorial-frontpage__hero-title {
  font-size: 6rem;
  color: white;
  margin: 0 auto;
  padding: 5rem 0 2rem 0;
  width: 70rem;
  max-width: 70%;
  display: flex;
  justify-content: center;
  line-height: 0.8;
  font-family: "AvenirNext", sans-serif;
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.01em;
  transform: rotate(-10deg);
  text-transform: uppercase;
  text-shadow: 0.2rem 0.5rem 1.6rem rgba(0, 0, 0, 0.15);
  padding-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .tutorial-frontpage__hero .tutorial-frontpage__hero-title {
    font-size: 9rem;
    max-width: unset;
    padding-bottom: 8rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial-frontpage__hero .tutorial-frontpage__hero-title {
    padding-top: 3.3rem;
  }
}
.tutorial__frontpage-wrapper {
  position: relative;
  min-height: 46rem;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper {
  padding: 3.5rem 1.5rem;
  display: flex;
  max-width: 144rem;
  margin: 0 auto;
  transition: 0.3s ease-out;
  overflow: hidden;
  left: 0;
  right: 0;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper--hide {
  transform: translateY(50rem);
  position: absolute;
  padding: 0;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories {
  flex: 1;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories {
    flex: 60%;
  }
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category {
  display: block;
  color: black;
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1.6rem;
  position: relative;
  transition: 0.1s ease-out;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2rem;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-tutorials {
  transition-duration: 0.3s;
  max-height: 0;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category {
    margin-left: 5.9rem;
    margin-right: 5.9rem;
    margin-bottom: 2.3rem;
  }
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category:hover:not(.tutorial__category--show) {
    transform: scale(1.015);
  }
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category:hover:not(.tutorial__category--show) .tutorial__category-title {
    color: var(--tutorial-primary);
    transition-duration: 0.2s;
  }
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper {
  width: 100%;
  position: relative;
  padding: 2rem 2.5rem;
  display: grid;
  grid-template-columns: auto 3.5rem;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper:not(.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper--tutorial)::after {
  content: "";
  position: absolute;
  height: 0.1rem;
  width: 95%;
  background-color: #000000;
  display: block;
  opacity: 0.25;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper:not(.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper--tutorial)::after {
    bottom: -0.2rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-title {
  text-transform: uppercase;
  font-size: 2rem;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 800;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-title {
    font-size: 2.3rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-label {
  font-style: italic;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.7);
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-cross {
  width: 3.2rem;
  height: 3.2rem;
  background-color: var(--tutorial-primary);
  border-radius: 50%;
  margin: auto 0 auto auto;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-cross {
    width: 3.2rem;
    height: 3.2rem;
    top: 2.6rem;
    margin-right: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-cross {
    top: 2.5rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-cross::after,
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-cross::before {
  content: '';
  width: 1.7rem;
  display: block;
  height: 0.37rem;
  border-radius: 0.1rem;
  background: white;
  position: absolute;
  transition: 0.1s ease-out;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category .tutorial__category-wrapper .tutorial__category-cross::after {
  transform: rotate(90deg);
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category--show .tutorial__category-tutorials {
  max-height: 100rem;
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__categories .tutorial__category--show .tutorial__category-wrapper .tutorial__category-cross::after {
  transform: rotate(0deg);
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited {
  display: none;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited {
    border-radius: 2rem;
    flex: 40%;
    max-width: 55rem;
    height: fit-content;
    background-color: var(--tutorial-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 0;
    margin-right: 5.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-title {
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    padding: 0 2rem;
    font-style: italic;
    font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
    color: white;
    margin: auto auto 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper {
    position: relative;
    display: flex;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper {
    border-radius: 2rem;
    box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
    margin: 1.1rem 3rem;
    padding: 1.2rem 2.5rem;
    position: relative;
    background-color: white;
    width: 100%;
    min-height: 8.5rem;
    display: grid;
    grid-template-columns: 5.5rem auto 2.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: 0.1s ease-out;
  }
}
.tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper:hover {
  transform: scale(1.015);
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper .tutorial__most-visited-element-image-wrapper {
    width: 5.5rem;
    height: 5.5rem;
    background-color: var(--tutorial-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper .tutorial__most-visited-element-image-wrapper .tutorial__most-visited-element-image {
    width: 45%;
    height: 45%;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper .tutorial__most-visited-element-text-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 1.8rem;
    line-height: 1.7rem;
    color: #000000;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper .tutorial__most-visited-element-text-wrapper .tutorial__most-visited-element-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.9rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper .tutorial__most-visited-element-text-wrapper .tutorial__most-visited-element-text {
    font-size: 1.4rem;
    font-weight: 400;
    font-style: italic;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__frontpage-inner-wrapper .tutorial__most-visited .tutorial__most-visited-wrapper .tutorial__most-visited-element-wrapper .tutorial__most-visited-element-arrow {
    width: 1.5rem;
    height: 1.5rem;
    border-bottom: 0.2rem solid #000000;
    border-right: 0.2rem solid #000000;
    transform: rotate(-45deg);
    position: absolute;
    top: 40%;
    left: 90%;
    transition: 0.2s ease-out;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result {
  transition: 0.3s ease-out;
  position: absolute;
  left: 0;
  right: 0;
  top: 100rem;
  background-color: var(--tutorial-primary);
  opacity: 0;
  pointer-events: none;
  min-height: 46rem;
}
.tutorial__frontpage-wrapper .tutorial__search-result--show {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
.tutorial__frontpage-wrapper .tutorial__search-result--show .tutorial__search-result-wrapper {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
}
.tutorial__frontpage-wrapper .tutorial__search-result--show .tutorial__search-result-related-wrapper {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
}
.tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-wrapper {
  padding: 0 2rem;
  flex: 1;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-wrapper {
    width: 55rem;
    margin: 0 auto;
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-wrapper {
    width: 63rem;
    padding: 0 0 5rem;
    flex: 1;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper {
  background-color: white;
  padding: 3.5rem 1.5rem;
  min-height: 25rem;
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper .tutorial__search-result-related-inner-wrapper {
    margin: 0 auto;
    width: 55rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper .tutorial__search-result-related-inner-wrapper {
    width: 63rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper .tutorial__search-result-related-inner-wrapper .tutorial__search-result-related-help-text {
  font-style: italic;
  font-size: 1.4rem;
  padding-bottom: 2.3rem;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper .tutorial__search-result-related-inner-wrapper .tutorial__search-result-related-help-text {
    display: flex;
    align-items: flex-start;
    font-size: 2rem;
    width: fit-content;
    margin: 1.7rem auto 4.3rem;
    padding-left: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result .tutorial__search-result-related-wrapper .tutorial__search-result-related-inner-wrapper .tutorial__search-result-related-help-categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 63.2rem;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper {
  transition-duration: 0.5s;
  position: absolute;
  left: 0;
  right: 0;
  top: 100rem;
  background-color: var(--tutorial-primary);
  opacity: 0;
  pointer-events: none;
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper--show {
  top: 0;
  opacity: 1;
  pointer-events: auto;
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper {
  color: white;
  max-width: 62.2rem;
  margin: 0 auto;
  padding-left: 2rem;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper {
    max-width: 51rem;
    padding-left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper {
    max-width: 63.2rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-title {
  font-size: 1.2rem;
  padding-right: 1.5rem;
  line-height: 1.5rem;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-title {
    width: 63.2rem;
    margin: 0 auto;
    padding-left: 0;
  }
}
@media screen and (min-width: 1216px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-title {
    font-size: 1.6rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-search-query {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 1.2rem 0 1.8rem;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-search-query {
    width: 63.2rem;
    margin: 0 auto;
    padding-left: 0;
  }
}
@media screen and (min-width: 1216px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-search-query {
    font-size: 2.5rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-text {
  font-size: 1.2rem;
  padding-bottom: 3.7rem;
}
@media screen and (min-width: 1024px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-text {
    width: 63.2rem;
    margin: 0 auto;
    padding-left: 0;
    padding-bottom: 4rem;
  }
}
@media screen and (min-width: 1216px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-result-null-text {
    font-size: 1.6rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-title {
    font-size: 1.8rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-element-wrapper {
  margin-bottom: 1.75rem;
  display: flex;
  flex-direction: column;
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-element-wrapper .tutorial__search-last-visited-element-innerwrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2.4rem;
  padding-bottom: 1.7rem;
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-element-wrapper .tutorial__search-last-visited-element-innerwrapper .tutorial__search-last-visited-element-image {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 1.7rem;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-element-wrapper .tutorial__search-last-visited-element-innerwrapper .tutorial__search-last-visited-element-image {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-element-wrapper .tutorial__search-last-visited-element-innerwrapper .tutorial__search-last-visited-element-title {
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .tutorial__frontpage-wrapper .tutorial__search-result-null-wrapper .tutorial__search-result-null-inner-wrapper .tutorial__search-last-visited-wrapper .tutorial__search-last-visited-element-wrapper .tutorial__search-last-visited-element-innerwrapper .tutorial__search-last-visited-element-title {
    font-size: 1.6rem;
  }
}
.tutorial__category-tutorials-wrapper {
  position: relative;
  display: grid;
  padding: 2.5rem;
  grid-template-columns: 5.5rem auto 2.5rem;
  align-items: center;
}
.tutorial__category-tutorials-wrapper:not(:last-child)::after {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 0.1rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 1024px) {
  .tutorial__category-tutorials-wrapper {
    width: 100%;
    padding: 1.4rem 2.5rem;
  }
}
.tutorial__category-tutorials-wrapper .tutorial__category-tutorials-image-wrapper {
  width: 5.5rem;
  height: 5.5rem;
  background-color: var(--tutorial-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.4rem 2rem rgba(0, 27, 48, 0.2);
}
@media screen and (min-width: 768px) {
  .tutorial__category-tutorials-wrapper .tutorial__category-tutorials-image-wrapper {
    width: 5.5rem;
    height: 5.5rem;
  }
}
.tutorial__category-tutorials-wrapper .tutorial__category-tutorials-image-wrapper .tutorial__category-tutorials-image {
  width: 45%;
  height: 45%;
}
.tutorial__category-tutorials-wrapper .tutorial__category-tutorials-text-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 1.8rem;
  color: #000000;
  justify-content: center;
}
.tutorial__category-tutorials-wrapper .tutorial__category-tutorials-text-wrapper .tutorial__category-tutorials-title {
  font-weight: bold;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .tutorial__category-tutorials-wrapper .tutorial__category-tutorials-text-wrapper .tutorial__category-tutorials-title {
    font-size: 1.8rem;
  }
}
.tutorial__category-tutorials-wrapper .tutorial__category-tutorials-text-wrapper .tutorial__category-tutorials-text {
  font-size: 1.6rem;
  font-style: italic;
}
@media screen and (min-width: 768px) {
  .tutorial__category-tutorials-wrapper .tutorial__category-tutorials-text-wrapper .tutorial__category-tutorials-text {
    font-size: 1.6rem;
  }
}
.tutorial__category-tutorials-wrapper--filtered {
  margin-bottom: 1.4rem;
  padding-left: 0;
}
@media screen and (min-width: 768px) {
  .tutorial__category-tutorials-wrapper--filtered {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto 1.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__category-tutorials-wrapper--filtered {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 0;
    width: 63.2rem;
    margin: 0 auto;
  }
}
.tutorial__category-tutorials-wrapper--filtered .tutorial__category-tutorials-image-wrapper {
  background-color: #ffffff;
  width: 5.3rem;
  height: 5.3rem;
}
@media screen and (min-width: 1024px) {
  .tutorial__category-tutorials-wrapper--filtered .tutorial__category-tutorials-image-wrapper {
    margin-left: 0;
  }
}
.tutorial__category-tutorials-wrapper--filtered .tutorial__category-tutorials-text-wrapper {
  color: white;
}
.tutorial__category-tutorials-wrapper--filtered .tutorial__category-tutorials-text-wrapper .tutorial__category-tutorials-title {
  font-weight: normal;
}
.tutorial__category-tutorials-wrapper--filtered .tutorial__category-tutorials-arrow {
  border-bottom: 0.2rem solid #ffffff;
  border-right: 0.2rem solid #ffffff;
  right: 4rem;
}
@media screen and (min-width: 768px) {
  .tutorial__category-tutorials-wrapper--filtered .tutorial__category-tutorials-arrow {
    right: 4rem;
  }
}
.tutorial__category-tutorials-arrow {
  width: 1.5rem;
  height: 1.5rem;
  border-bottom: 0.3rem solid #000000;
  border-right: 0.3rem solid #000000;
  transform: rotate(-45deg);
  position: relative;
  margin: auto 0 auto auto;
  transition: 0.2s ease-out;
}
@media screen and (min-width: 768px) {
  .tutorial__category-tutorials-arrow {
    width: 1.5rem;
    height: 1.5rem;
    right: 2.5rem;
  }
}
.tutorial__highlight {
  background: var(--highlightBackgroundColor, transparent);
  color: var(--highlightColor, inherit);
  padding: 0 0.1rem;
  border-radius: 0.6rem;
}
.tutorial__hero {
  background-color: var(--tutorial-primary);
  position: relative;
}
.tutorial__hero--disable {
  display: none;
}
.tutorial__hero .tutorial__hero-arrow {
  background-color: white;
  border-radius: 50%;
  height: 4.1rem;
  width: 4.2rem;
  margin-left: 1.6rem;
  position: absolute;
  top: 5rem;
  box-shadow: 0.2rem 1.5rem 1.5rem 0.2rem rgba(0, 0, 0, 0.15);
}
@media screen and (min-width: 768px) {
  .tutorial__hero .tutorial__hero-arrow {
    margin-left: 6.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__hero .tutorial__hero-arrow {
    top: 3rem;
  }
}
.tutorial__hero .tutorial__hero-arrow::after {
  content: "";
  width: 1.75rem;
  height: 1.75rem;
  border-bottom: 0.4rem solid #000000;
  border-right: 0.4rem solid #000000;
  border-radius: 10%;
  display: block;
  transform: rotate(135deg);
  position: absolute;
  top: 30%;
  left: 35%;
}
.tutorial__hero .tutorial__hero-title {
  font-size: 6rem;
  color: white;
  margin: 0 auto;
  padding: 5rem 0 2rem 0;
  width: 70rem;
  max-width: 70%;
  display: flex;
  justify-content: center;
  line-height: 0.8;
  font-family: "AvenirNext", sans-serif;
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.01em;
  transform: rotate(-10deg);
  text-transform: uppercase;
  text-shadow: 0.2rem 0.5rem 1.6rem rgba(0, 0, 0, 0.15);
}
@media screen and (min-width: 768px) {
  .tutorial__hero .tutorial__hero-title {
    font-size: 9rem;
    max-width: unset;
    padding-bottom: 8rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial__hero {
    padding: 2.9rem;
  }
}
.tutorial-footer {
  background-color: var(--tutorial-primary);
  position: relative;
  padding: 0 2rem;
  border-top: solid 0.1rem white;
}
.tutorial-footer--disable {
  display: none;
}
@media screen and (min-width: 1216px) {
  .tutorial-footer {
    width: 100%;
    margin: 0 auto;
    padding: 0 4rem;
  }
}
.tutorial-footer .tutorial-footer-inner {
  max-width: 144rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .tutorial-footer .tutorial-footer-inner {
    align-items: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial-footer .tutorial-footer-inner {
    flex-direction: row;
  }
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__text-wrapper {
  width: 100%;
}
@media screen and (min-width: 1216px) {
  .tutorial-footer .tutorial-footer-inner .tutorial-footer__text-wrapper {
    flex-basis: 50%;
  }
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__text-wrapper .tutorial-footer__title {
  padding-top: 2.9rem;
  font-weight: 800;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  line-height: 4rem;
  color: white;
}
@media screen and (min-width: 768px) {
  .tutorial-footer .tutorial-footer-inner .tutorial-footer__text-wrapper .tutorial-footer__title {
    font-size: 2.3rem;
    line-height: 6rem;
  }
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__text-wrapper .tutorial-footer__text {
  font-size: 1.4rem;
  color: white;
  padding: 2rem 0;
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__text-wrapper .tutorial-footer__text p {
  margin: 0;
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    padding-right: 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement {
    justify-content: center;
    grid-column-gap: 3rem;
    padding-top: 6.6rem;
    padding-bottom: 5.7rem;
    width: auto;
  }
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper {
  display: flex;
  padding-bottom: 3.3rem;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper {
    flex-basis: 50%;
  }
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper .tutorial-footer__footerElement-image-wrapper {
  width: 5.5rem;
  height: 5.5rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  box-shadow: 0 0.4rem 2rem rgba(0, 27, 48, 0.2);
}
@media screen and (min-width: 768px) {
  .tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper .tutorial-footer__footerElement-image-wrapper {
    margin-right: 3rem;
  }
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper .tutorial-footer__footerElement-image-wrapper .tutorial-footer__footerElement-image {
  width: 50%;
  height: 50%;
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper .tutorial-footer__footerElement-text-wrapper .tutorial-footer__footerElement-title {
  font-weight: bold;
  font-size: 2rem;
  color: white;
  padding-bottom: 0.2rem;
}
.tutorial-footer .tutorial-footer-inner .tutorial-footer__footerElement .tutorial-footer__footerElement-wrapper .tutorial-footer__footerElement-text-wrapper .tutorial-footer__footerElement-text {
  font-size: 1.4rem;
  color: white;
  font-weight: 400;
}
.tutorial__content-box {
  max-width: 144rem;
  margin: 0 auto 0;
  background-color: white;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
}
.tutorial__content-box--full-width {
  max-width: 100%;
}
.tutorial__content-box--no-radius {
  border-radius: 0;
}
