﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@cell-width--tablet: calc(~'16.666% - 16px');
@cell-height--tablet: 8vw;

.statistics-component-for-game-client-wrapper {
  font-size: 0;
  line-height: 0;
  border-top: 2px solid;
  user-select: none;

  .statistics-component-outer-wrapper {
    width: 100%;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    text-align: left;

    @media (min-width: @desktop-width) {
      width: 96rem;
    }

    .statistics-component-toggle-button-wrapper {
      width: 100%;

      @media (min-width: @desktop-width) {
        width: initial;
      }

      .toggle-button {
        color: #fff;
        background-color: rgba(0,0,0,.2);
        padding: 4.2vw 0 5vw 6vw;
        overflow: hidden;
        transition: background-color .25s linear;

        @media (min-width: @desktop-width) {
          cursor: pointer;
          display: inline-block;
          padding: 1.2rem 0 1.2rem 2rem;
          border-radius: 0 0 6px 6px;
        }

        .icon-wrapper {
          width: 6vw;
          height: 4vw;
          margin-right: 2vw;
          display: inline-block;
          transition: transform .15s linear;

          @media (min-width: @desktop-width) {
            width: 2rem;
            height: 1.3rem;
            margin-right: 1rem;
          }

          .icon {
            width: 100%;
            height: 100%;
            display: block;
            fill: #fff;
            transition: transform .25s linear;
          }
        }

        &:hover {
          @media (min-width: @desktop-width) {
            background-color: rgba(0,0,0,.4);

            .icon-wrapper {
              transform: translateY(20%);
            }
          }
        }

        .text-wrapper {
          font-size: 4.266vw;
          line-height: 1;

          @media (min-width: @desktop-width) {
            font-size: 1.5rem;
          }
        }

        .numbers-count-wrapper {
          width: 0;
          height: 12.8vw;
          margin: -4vw 0 -4.5vw;
          border-radius: 6px 0 0 6px;
          display: inline-block;
          position: relative;
          overflow: hidden;
          float: right;
          transition: width .1s linear;

          @media (min-width: @desktop-width) {
            height: 4rem;
            margin: -1.4rem 0 -1.5rem 2rem;
            border-radius:0;
            float: none;
          }

          .count-value {
            width: 16vw;
            font-size: 4.266vw;
            line-height: 1;
            text-align: center;
            display: inline-block;
            padding: 4.3vw 0;
            position: absolute;
            top: 0;
            right: 0;

            @media (min-width: @desktop-width) {
              width: 4rem;
              font-size: 1.5rem;
              padding: 1.2rem 0;
            }
          }
        }
      }
    }

    @keyframes statistics-component-inner-wrapper_fold-animation {
      100% {
        height: 0;
        padding-top: 0;
      }
    }

    @keyframes statistics-component-inner-wrapper_expand-animation {
      0% {
        height: 0;
        padding-top: 0;
      }
    }

    .statistics-component-inner-wrapper {
      padding: 2vw 0 0;
      overflow: hidden;

      @media (min-width: @desktop-width) {
        padding: .8rem 0;
      }

      .numbers-from-statistics-wrapper {
        opacity: 1;
        padding: 0 2vw 14vw;
        transition: opacity .15s .1s linear;

        @media (min-width: @desktop-width) {
          margin-left: -.25rem;
          padding: 0;
        }

        .selected-number-box {
          width: 10vw;
          height: 10vw;
          text-align: center;
          border-radius: 6px;
          display: inline-block;
          margin: 1vw;
          position: relative;
          transition: background-color 0.15s linear;

          @media (min-width: @desktop-width) {
            width: 5rem;
            height: 5rem;
            margin: .25rem;
            cursor: pointer;
          }

          .selected-number-delete-button {
            width: 6vw;
            height: 6vw;
            position: absolute;
            border-radius: 50%;
            right: 0;
            top: 0;
            transform: translate(50%, -50%) scale(0.8);
            opacity: 0;
            pointer-events: none;
            transition: transform .15s linear, opacity .15s linear;
            will-change: transform;
            z-index: 1;
            background-color: @numbergames-extra-lightgray;

            @media (min-width: @desktop-width) {
              width: 2rem;
              height: 2rem;
            }

            .icon {
              width: 3vw;
              height: 4vw;
              margin-top: 1vw;
              fill: @numbergames-darkgray;

              @media (min-width: @desktop-width) {
                width: 1rem;
                height: 1rem;
                margin-top: .5rem;
              }
            }
          }

          .number-cell {
            position: absolute;
          }

          &:not(.special) + .special {
            @media (min-device-width: @desktop-width) {
              margin-left: 1.15rem;
            }

            &:before {
              content: '';
              position: absolute;
              background-color: #fff;
              border-radius: 2px;

              @media (min-device-width: @desktop-width) {
                width: .4rem;
                top: .6rem;
                left: -.95rem;
                bottom: .6rem;
              }
            }

            & + .special {
              @media (min-device-width: @desktop-width) {
                margin-left: .25rem;
              }

              &:before {
                content: none;
              }
            }
          }

          &:hover {
            @media (min-width: @desktop-width) {
              .selected-number-delete-button {
                opacity: 1;
                pointer-events: initial;
                cursor: pointer;

                &:hover {
                  transform: translate(50%, -50%) scale(1.1);
                }
              }
            }
          }
        }
      }

      .actions-buttons-wrapper {
        background-color: rgba(0,0,0,.2);
        white-space: nowrap;
        display: flex;
        position: relative;
        top: -12vw;

        @media (min-width: @desktop-width) {
          //display: block;
          border-radius: 0 0 6px 6px;
          position: absolute;
          top: 0;
          right: 0;
        }

        .action-button-wrapper {
          overflow: hidden;
          padding: 3.6vw 3vw;
          text-align:center;
          border-right: 2px solid rgba(255,255,255,.4);
          flex-grow: 1;
          transition: background-color .15s linear;

          @media (min-width: @desktop-width) {
            padding: 1.2rem 2rem;
            //display: inline-block;
          }

          .text-wrapper {
            font-size: 4vw;
            line-height: 1;
            color: #fff;
            float: left;
            display: none;

            @media (min-width: @desktop-width) {
              font-size: 1.5rem;
              font-weight: 400;
              display: inline-block;
            }
          }

          .icon {
            width: 5vw;
            height: 5vw;
            display: inline-block;
            fill: #fff;

            @media (min-width: @desktop-width) {
              width: 2rem;
              height: 2rem;
              margin-left: 1.2rem;
              margin-top: -.3rem;
              margin-bottom: -.2rem;
              float: right;
            }
          }

          &:hover {
            @media (min-width: @desktop-width) {
              background-color: rgba(0,0,0,.2);
              cursor: pointer;
            }
          }

          &:first-child {
            .text-wrapper {
              display: inline-block;
            }

            .icon {
              margin-left: 2vw;

              @media (min-width: @desktop-width) {
                margin-left: 1.2rem;
              }
            }
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  &.opened {
    .statistics-component-outer-wrapper {
      .statistics-component-toggle-button-wrapper {
        .toggle-button {
          &:hover {
            @media (min-width: @desktop-width) {
              .icon-wrapper {
                transform: translateY(-20%);
              }
            }
          }
        }

        .numbers-count-wrapper {
          width: 0;
        }
      }

      .statistics-component-inner-wrapper {
        animation: statistics-component-inner-wrapper_expand-animation .15s linear forwards;
      }
    }
  }

  &.closed {
    .statistics-component-outer-wrapper {
      .statistics-component-toggle-button-wrapper {
        .toggle-button {
          .icon-wrapper {
            .icon {
              transform: rotate(-180deg);
            }
          }
        }

        .numbers-count-wrapper {
          width: 16vw;

          @media (min-width: @desktop-width) {
            width: 4rem;
          }
        }
      }

      .statistics-component-inner-wrapper {
        animation: statistics-component-inner-wrapper_fold-animation .15s linear forwards;

        .numbers-from-statistics-wrapper {
          transition: opacity .1s linear;
          opacity: 0;
        }
      }
    }
  }
}
