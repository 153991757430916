.btn-with-x-padding {
  padding-left: 2rem;
}
@keyframes btn-pulse-inner-loading-animation {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  95%,
  100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0);
  }
}
@keyframes btn-pulse-loading-animation {
  38% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  65%,
  100% {
    transform: scale(1);
  }
}
.most-winning-numbers {
  --right-image-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  padding: 5.6rem 2.4rem;
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
  }
}
.most-winning-numbers .responsive-bg {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.most-winning-numbers .responsive-bg img {
  max-width: 100%;
  max-height: var(--right-image-height);
}
.most-winning-numbers .responsive-bg .responsive-lottie-bg {
  z-index: 0;
}
.most-winning-numbers--eurojackpot .most-winning-numbers__select-wrapper {
  background: #E8A332;
  color: #FFFFFF;
}
.most-winning-numbers--lotto .most-winning-numbers__select-wrapper {
  background: #ededed;
  color: #c50005;
}
.most-winning-numbers--vikinglotto .most-winning-numbers__select-wrapper {
  background: #0000ff;
  color: #FFFFFF;
}
.most-winning-numbers__text-container {
  box-sizing: border-box;
  padding-right: 0.6rem;
  margin-bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  max-width: 43rem;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__text-container {
    flex-basis: 50%;
    align-items: start;
  }
}
.most-winning-numbers__title {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.6rem;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__title {
    font-size: 4rem;
    line-height: 6.4rem;
  }
}
.most-winning-numbers__select-wrapper {
  position: relative;
  display: inline;
  margin-left: 1.2rem;
  border-radius: 1.6rem;
}
.most-winning-numbers__select {
  border: 0;
  cursor: pointer;
  appearance: none;
  background: inherit;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1;
  letter-spacing: 0.5px;
  padding-right: 5.7rem;
  padding-left: 1.6rem;
  color: inherit;
  border-radius: 1.6rem;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__select {
    font-size: 4rem;
    line-height: 6.4rem;
  }
}
.most-winning-numbers__select:focus {
  outline: none;
}
.most-winning-numbers__select-option {
  color: inherit;
  background: inherit;
  font-weight: inherit;
}
.most-winning-numbers__select-arrow {
  position: absolute;
  top: 0.6rem;
  right: 1.6rem;
  pointer-events: none;
  height: 2.4rem;
  width: 2.4rem;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__select-arrow {
    top: 1.5rem;
  }
}
.most-winning-numbers__text {
  color: #92969E;
  font-size: 1.4rem;
  text-align: center;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .most-winning-numbers__text {
    text-align: initial;
  }
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__text {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
.most-winning-numbers__lottie-wrapper {
  position: relative;
  max-width: 51rem;
  width: 100%;
  height: var(--right-image-height);
  margin-bottom: 4.8rem;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__lottie-wrapper {
    flex-basis: 50%;
    margin-bottom: 0;
  }
}
.most-winning-numbers__loader {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 12rem;
  margin-bottom: 4.8rem;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__loader {
    flex-basis: 50%;
    height: 40rem;
    margin-bottom: 0;
  }
}
.most-winning-numbers__cta {
  cursor: pointer;
  height: 5.6rem;
  min-width: 24rem;
  background-color: #FEB700;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.1rem;
}
.most-winning-numbers__cta--desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__cta--desktop {
    display: flex;
    margin-top: 3.2rem;
  }
}
.most-winning-numbers__cta--mobile {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .most-winning-numbers__cta--mobile {
    display: none;
  }
}
