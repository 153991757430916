@import (reference) "../../../Mixins/_mixins.less";

.multi-client__flow-step-header {
  padding: 0 0 3rem;
  text-align: center;
  position: relative;
}

.multi-client__flow-step-title {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 700;
  padding-bottom: 1.4rem;
  @media @sm {
    font-size: 4rem;
    line-height: 3.4rem;
    padding-bottom: 2.4rem;
  }

  span {
    display: inline-block;
  }
}

.multi-client__flow-step-subtitle {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: @numbergames-blue-grey;
  @media @sm {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  strong {
    font-weight: 800;
    color: @ds-black;
  }
}

.multi-client__flow-step-description {
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: @ds-black;
  margin-bottom: 1.2rem;
  @media @sm {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

.multi-client__flow-step-description a {
  color: inherit;
}

.multi-client__client-wrapper {
  display: flex;

  .multi-client__client-game,
  .multi-client__flow-wrapper {
    width: 100%;
    flex-shrink: 0;
  }

  .multi-client__client-game {
    padding-top: 11rem;
    max-height: 0;
    opacity: 0;
    transition: max-height .15s linear, opacity .1s linear;
    pointer-events: none;
    @media @sm {
      padding-top: 10rem;
    }

    .multi-client-rotary-selector .selector-numbers .number-wrapper.in-view .number {
      pointer-events: none;
    }

    &.active {
      max-height: max-content;
      opacity: 1;
      pointer-events: all;

      .multi-client-rotary-selector .selector-numbers .number-wrapper.in-view .number {
        pointer-events: all;
      }
    }

    .multi-client__flow-step-header {
      padding: 1rem 3rem 1rem;
      @media @sm {
        padding: 5rem 3rem 1rem;
      }
    }

    .multi-client-rotary-selector {
      margin-bottom: 1rem;
    }

    .multi-client-rotary-selector__outer + .multi-client__client-game-description {
      width: 25rem;
      position: relative;
      margin-top: -7.4rem;
      margin-bottom: 3rem;
      margin-left: auto;
      margin-right: auto;
    }

    // hide subnavigation bar from number picking game clients
    .eurojackpot-games-subnavigation-bar,
    .lotto-games-subnavigation-bar,
    .vikinglotto-games-subnavigation-bar {
      display: none;
    }
    .eurojackpot-classic-game .rows-container,
    .lotto-classic-game .rows-container,
    .vikinglotto-classic-game .rows-container {
      margin-top: 0;
    }

    .shake-overlay-wrapper {
      display: none;
    }

    .tabs-container {
      @media @sm {
        width: ~'calc(100% - 28vw)';
        padding-right: 14vw;
      }
      @media @md {
        width: ~'calc(100% - 14rem)';
        padding-right: 7rem;
      }
    }

    
    .lotto-game-tabs-wrapper {
      .rows-container {
        @media @media-querie-for-tablet-portrait {
          width: 80.21%;
        }
      }
    }

    .vikinglotto-game-tabs-wrapper {
      .rows-container {
        @media @media-querie-for-tablet-portrait {
          width: 71.3%;
        }
      }
    }

    .eurojackpot-game-tabs-wrapper {
      .rows-container {
        @media @media-querie-for-tablet-portrait {
          width: 82%;
        }
      }
    }

    .multi-client__client-game-description {
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: @numbergames-blue-grey;
      text-align: center;
      margin: 0 auto 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
    }

    .client-game-description__question-button {
      width: 4rem;
      height: 4rem;
      color: @ds-black;
      position: relative;
      top: 0.2rem;
      cursor: pointer;

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    // Lotto and Vikinglotto number picker theming:
    .lotto-classic-game .lotto-section,
    .vikinglotto-classic-game .vikinglotto-section {
      .left-gradient {
        background: linear-gradient(to right, @ds-white 0%, fade(@ds-white, 0%) 100%);
      }
      .right-gradient  {
        background: linear-gradient(to right, fade(@ds-white, 0%) 0%, @ds-white 100%);
      }
    }

    .lotto-system-game,
    .lotto-lucky-game,
    .vikinglotto-system-game,
    .vikinglotto-lucky-game {
      .lotto-game-tabs-wrapper .tabs-container,
      .vikinglotto-game-tabs-wrapper .tabs-container {
        .inner-wrapper {
          color: @ds-gray-875;
        }
      }
      .number-picker-footer {
        border-top-color: transparent;
      }
    }

    .lotto-classic-game .lotto-section .row-header {
      border-bottom-color: @ds-red-700;
    }

    .vikinglotto-classic-game .vikinglotto-section .row-header {
      border-bottom-color: @ds-blue;
    }

    .lotto-game-tabs-wrapper .tabs-container,
    .vikinglotto-game-tabs-wrapper .tabs-container {
      background-color: @ds-white;
    }

    // Lotto number picker styling
    .lotto-classic-game,
    .lotto-system-game,
    .lotto-lucky-game,
    .lotto-winning-numbers {
      .number-picker-container {
        @light-red-cell: #eecece;
        .cell {
          .front-face {
            background-color: @light-red-cell;
            color: @ds-red-700;
          }
          .back-face {
            background-color: @ds-red-700;
            color: @ds-white;
          }

          &.flip-animation-in-progress {
            @keyframes multiclient-lotto-flip-face-animation {
              0% {
                color: @ds-red-700;
                background-color: @light-red-cell;
              }

              35% {
                color: @ds-red-700;
                background-color: @light-red-cell;
              }

              65% {
                color: @ds-red-700;
                background-color: @light-red-cell;
              }

              80% {
                color: @ds-white;
                background-color: @ds-red-700;
              }

              100% {
                color: @ds-white;
                background-color: @ds-red-700;
              }
            }

            @keyframes multiclient-lotto-reverse-flip-face-animation {
              0% {
                color: @ds-white;
                background-color: @ds-red-700;
              }

              35% {
                color: @ds-white;
                background-color: @ds-red-700;
              }

              65% {
                color: @ds-white;
                background-color: @ds-red-700;
              }

              80% {
                color: @ds-red-700;
                background-color: @light-red-cell;
              }

              100% {
                color: @ds-red-700;
                background-color: @light-red-cell;
              }
            }
            &.select-number-animation {
              animation-name: multiclient-lotto-flip-face-animation;
            }

            &.deselect-number-animation {
              animation-name: multiclient-lotto-reverse-flip-face-animation;
            }
          }
        }
      }
      .lotto-section {
        background-color: white;

        .row-header {
          color: @ds-red-700;
          
          .header-rules-text {
            color: @ds-red-700;
          }

          .right-side .row-delete-button .icon {
            fill: @ds-red-700;
          }
        }

        .number-picker-footer .buttons-container .button {
          background-color: @ds-red-700;
          color: @ds-white;
        }
      }
    }

    // Vikinglotto number picker styling
    .vikinglotto-classic-game,
    .vikinglotto-system-game,
    .vikinglotto-lucky-game,
    .vikinglotto-winning-numbers {
      .number-picker-container {
        @light-blue-cell: #E6E6FE;

        .numbers-vikings-separator {
          background-color: @light-blue-cell;
        }

        .row-vikings-container .viking-box {
          .icon-viking.white {
            // when using the number piker in the multiclient the background is white so we need to change the color of the white viking helmet
            // the viking helmet svg has the fill color set inline so we can't change it from CSS by using the fill property
            // that's why we use the filter property to convert the white icon to @light-blue-cell
            filter: brightness(0) saturate(100%) invert(90%) sepia(85%) saturate(5600%) hue-rotate(180deg) brightness(100%) contrast(113%);
          }

          .number {
            color: @ds-blue;
          }
        }
        .cell {
          .front-face {
            background-color: @light-blue-cell;
            color: @ds-blue;
          }
          .back-face {
            background-color: @ds-blue;
            color: @ds-white;
          }

          &.flip-animation-in-progress {
            @keyframes multiclient-vikinglotto-flip-face-animation {
              0% {
                color: @ds-blue;
                background-color: @light-blue-cell;
              }

              35% {
                color: @ds-blue;
                background-color: @light-blue-cell;
              }

              65% {
                color: @ds-blue;
                background-color: @light-blue-cell;
              }

              80% {
                color: @ds-white;
                background-color: @ds-blue;
              }

              100% {
                color: @ds-white;
                background-color: @ds-blue;
              }
            }

            @keyframes multiclient-vikinglotto-reverse-flip-face-animation {
              0% {
                color: @ds-white;
                background-color: @ds-blue;
              }

              35% {
                color: @ds-white;
                background-color: @ds-blue;
              }

              65% {
                color: @ds-white;
                background-color: @ds-blue;
              }

              80% {
                color: @ds-blue;
                background-color: @light-blue-cell;
              }

              100% {
                color: @ds-blue;
                background-color: @light-blue-cell;
              }
            }
            &.select-number-animation {
              animation-name: multiclient-vikinglotto-flip-face-animation;
            }

            &.deselect-number-animation {
              animation-name: multiclient-vikinglotto-reverse-flip-face-animation;
            }
          }
        }
      }
      .vikinglotto-section {
        background-color: white;

        .row-header {
          color: @ds-blue;

          .header-rules-text {
            color: @ds-blue;
          }

          .right-side .row-delete-button .icon {
            fill: @ds-blue;
          }
        }

        .number-picker-footer .buttons-container .button {
          background-color: @ds-blue;
          color: @ds-white;
        }
      }
    }

    // Summer lotto theming:
    .multi-client-wrapper__campaign-Sommerlotto & {
      .lotto-classic-game {
        .lotto-section {
          .row-header {
            border-bottom-color: @summer-lotto-orange;
          }
          .number-picker-footer {
            border-top-color: @summer-lotto-orange;
          }
        }
      }

      .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title {
        color: @ds-white;
      }
      .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
        color: fade(@ds-white, 30%);
        &.active {
          color: @ds-white;
        }
      }

      .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
        fill: fade(@ds-white, 30%);
        color: fade(@ds-white, 30%);
      }

      .lotto-system-game,
      .lotto-lucky-game {
        .lotto-system-game-header {
          background-color: @summer-lotto-orange;
          
          .game-navigation-item {
            color: fade(@ds-white, 30%);

            &.active {
              background-color: fade(@ds-white, 30%);
              color: @ds-white;
              .tab-navigation-icon {
                fill: @ds-white;
                color: @ds-white;
              }
            }
          }
        }
        .game-switch-wrapper {
          background-color: @summer-lotto-orange;

          .switch-button {
            &.next {
              background: linear-gradient(to right, fade(@summer-lotto-orange, 0%) 0%, @summer-lotto-orange 100%);
            }

            &.prev {
              background: linear-gradient(to right, @summer-lotto-orange 0%, fade(@summer-lotto-orange, 0%) 100%);
            }
          }
        }
        .lotto-game-tabs-wrapper {
          background-color: @summer-lotto-orange;

          .tabs-navigation .tab-navigation-item.active {
            background-color: fade(@ds-white, 30%);

            .tab-navigation-icon {
              fill: @ds-white;
              color: @ds-white;
            }
          }
        }

        .lotto-content-wrapper .rows-container {
          &:before {
            background-color: @summer-lotto-orange;
          }
          .row-container .row-header:before {
            background-color: @summer-lotto-orange;
          }
        }
      }

      .lotto-classic-game,
      .lotto-system-game,
      .lotto-lucky-game,
      .lotto-winning-numbers {
        .number-picker-container {
          @light-orange: #fcdfce;
          .cell  {
            .front-face {
              background-color: @light-orange;
              color: @summer-lotto-orange;
            }
            .back-face {
              background-color: @summer-lotto-orange;
              color: @ds-white;
            }

            &.flip-animation-in-progress {
              @keyframes multiclient-summer-lotto-flip-face-animation {
                0% {
                  color: @ds-red-700;
                  background-color: @light-orange;
                }
  
                35% {
                  color: @ds-red-700;
                  background-color: @light-orange;
                }
  
                65% {
                  color: @ds-red-700;
                  background-color: @light-orange;
                }
  
                80% {
                  color: @ds-white;
                  background-color: @ds-red-700;
                }
  
                100% {
                  color: @ds-white;
                  background-color: @ds-red-700;
                }
              }
  
              @keyframes multiclient-summer-lotto-reverse-flip-face-animation {
                0% {
                  color: @ds-white;
                  background-color: @ds-red-700;
                }
  
                35% {
                  color: @ds-white;
                  background-color: @ds-red-700;
                }
  
                65% {
                  color: @ds-white;
                  background-color: @ds-red-700;
                }
  
                80% {
                  color: @ds-red-700;
                  background-color: @light-orange;
                }
  
                100% {
                  color: @ds-red-700;
                  background-color: @light-orange;
                }
              }
              &.select-number-animation {
                animation-name: multiclient-summer-lotto-flip-face-animation;
              }
  
              &.deselect-number-animation {
                animation-name: multiclient-summer-lotto-reverse-flip-face-animation;
              }
            }
          }
        }

        .lotto-section {
          .row-header {
            color: @summer-lotto-orange;

            .header-rules-text {
              color: @summer-lotto-orange;
            }
    
            .right-side .row-delete-button .icon {
              fill: @summer-lotto-orange;
            }
          }
  
          .number-picker-footer .buttons-container .button {
            background-color: @summer-lotto-orange;
          }
        }
      }
    }
  }
}

.multi-client__flow-wrapper {
  display: flex;
  z-index: 2;
  transition: transform .15s linear;
}

.multi-client__flow-step {
  flex: 0 0 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: @ds-white;
  position: relative;
  opacity: 0;
  transition: opacity .15s linear, background-color .15s linear;

  &:not(.multi-client__flow-step-receipt) {
    padding-top: 8rem;

    @media @sm {
      padding-top: 14rem;
    }
  }

  &.multi-client__flow-step--active {
    opacity: 1;
  }

  .multi-client__flow-step-inner {
    width: 100%;
    max-width: 72rem;
    padding: 0 3rem;
    box-sizing: border-box;

    @media @sm {
      padding: 0;
    }
  }
}

.multi-client__flow-step-pre-client {
  width: 100%;
  flex-shrink: 0;
  z-index: 1;

  &:not(.multi-client__flow-step-pre-client--active) {
    display: none;
  }

  &.multi-client__flow-step-pre-client--active {
    & + .multi-client__client-game {
      display: none;
    }
  }
}

// Print rules for when the multi-client is inserted
@media print {
  .top-spot-page-header,
  .eurojackpot-top-spot-responsive-frame,
  .lotto-winning-numbers-spot, .lotto-top-spot,
  .vikinglotto-top-spot, .vikinglotto-winning-numbers-spot,
  .multi-client-header, .multi-client__flow-step-header, .multi-client-footer,
  .eurojackpot-winning-numbers-spot, .plus-sign-up, .plus-sign-up__content,
  .promotion-spot, .package-game, .two-column-spot, .time-consuming-notification-static-fix
   {
    visibility: hidden;
    display: none !important;
  }

  .multi-client__client-wrapper .multi-client__client-game {
    padding-top: 0;
  }
}
