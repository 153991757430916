﻿@import url("../Mixins/_mixins.less");

.eurojackpot-games-subnavigation-bar {
  background-color: @eurojackpot-darkgold;
  .clearfix();

  @media (min-width: @eurojackpot-desktop-width) {
    background-color: @ds-white;
    pointer-events: none;
  }

  .eurojackpot-games-subnavigation {
    width: 84.266vw;
    position: relative;
    background-color: @eurojackpot-black;
    float: left;

    @media (min-width: @eurojackpot-desktop-width) {
      width: 100%;
      float: none;
      background-color: transparent;
    }

    .subnavigation-placeholder {
      border-right: .2rem solid #000000;
      margin: 0;

      .subnavigation-arrow {
        position: absolute;
        top: 4.8vw;
        right: 5.2vw;
        transform: translate3d(0,0,0);
        opacity: 1;
        transition: transform .25s, opacity .25s;

        @media (min-width: @eurojackpot-desktop-width) {
          display: none;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          top: initial;
        }

        .icon {
          width: 3.466vw;
          height: 2.133vw;
          fill: #fff;

          @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
            width: 2.5vw;
            height: 1.5vw;
          }
        }
      }
    }

    .subnavigation-placeholder,
    .subnavigation-options .item {
      font-size: 4vw;
      color: #fff;
      padding: 5.6vw 0 4.935vw 6.666vw;
      cursor: pointer;

      @media (min-width: @eurojackpot-desktop-width) {
        border-right: none;
        font-size: 2rem;
        font-weight: bold;
        color: @eurojackpot-gray;
        text-align: center;
        padding: 2.15rem 1rem;
        margin: 0;
        display: block;
      }

      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        font-size: 2.5vw;
        padding: 2.5vw 0 2.5vw 6.666vw;
      }
    }

    .subnavigation-options a {
      color: @eurojackpot-white;
      text-decoration: none;
    }

    .subnavigation-options {
      max-height: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      transition: max-height .25s linear;

      .item {
        background-color: @eurojackpot-darkgold;
        border-bottom: .2rem solid @eurojackpot-black;
        border-right: .2rem solid @eurojackpot-black;

        &:first-child {
          border-top: .2rem solid @eurojackpot-black;
        }

        &:last-child {
          border-bottom-right-radius: .6rem;
        }
      }
    }

    &.active {
      .subnavigation-arrow {
        transform: translate3d(0,100%,0);
        opacity: 0;
      }

      .subnavigation-options {
        max-height: 100vw;
      }
    }
  }

  .eurojackpot-information-button {
    width: 15.734vw;
    height: 16vw;
    position: relative;
    float: left;
    cursor: pointer;

    @media (min-width: @eurojackpot-desktop-width) {
      width: 4rem;
      height: 4rem;
      background-color: @eurojackpot-gray;
      border-radius: 0 0 0 6px;
      float: right;
      display: none;
    }

    @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
      height: 8.4vw;
    }

    &:before {
      content: '?';
      font-size: 5.6vw;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);

      @media (min-width: @eurojackpot-desktop-width) {
        font-size: 2rem;
      }

      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        font-size: 3.6vw;
      }
    }
  }
}
