.eurojackpot-games-subnavigation-bar {
  background-color: #595036;
}
.eurojackpot-games-subnavigation-bar:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-games-subnavigation-bar {
    background-color: white;
    pointer-events: none;
  }
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation {
  width: 84.266vw;
  position: relative;
  background-color: #000;
  float: left;
}
@media (min-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation {
    width: 100%;
    float: none;
    background-color: transparent;
  }
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder {
  border-right: .2rem solid #000000;
  margin: 0;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder .subnavigation-arrow {
  position: absolute;
  top: 4.8vw;
  right: 5.2vw;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform .25s, opacity .25s;
}
@media (min-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder .subnavigation-arrow {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder .subnavigation-arrow {
    top: initial;
  }
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder .subnavigation-arrow .icon {
  width: 3.466vw;
  height: 2.133vw;
  fill: #fff;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder .subnavigation-arrow .icon {
    width: 2.5vw;
    height: 1.5vw;
  }
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder,
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options .item {
  font-size: 4vw;
  color: #fff;
  padding: 5.6vw 0 4.935vw 6.666vw;
  cursor: pointer;
}
@media (min-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder,
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options .item {
    border-right: none;
    font-size: 2rem;
    font-weight: bold;
    color: #1f1f1f;
    text-align: center;
    padding: 2.15rem 1rem;
    margin: 0;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-placeholder,
  .eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options .item {
    font-size: 2.5vw;
    padding: 2.5vw 0 2.5vw 6.666vw;
  }
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options a {
  color: #fff;
  text-decoration: none;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options {
  max-height: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  transition: max-height 0.25s linear;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options .item {
  background-color: #595036;
  border-bottom: 0.2rem solid #000;
  border-right: 0.2rem solid #000;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options .item:first-child {
  border-top: 0.2rem solid #000;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation .subnavigation-options .item:last-child {
  border-bottom-right-radius: .6rem;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation.active .subnavigation-arrow {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-games-subnavigation.active .subnavigation-options {
  max-height: 100vw;
}
.eurojackpot-games-subnavigation-bar .eurojackpot-information-button {
  width: 15.734vw;
  height: 16vw;
  position: relative;
  float: left;
  cursor: pointer;
}
@media (min-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-information-button {
    width: 4rem;
    height: 4rem;
    background-color: #1f1f1f;
    border-radius: 0 0 0 6px;
    float: right;
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-information-button {
    height: 8.4vw;
  }
}
.eurojackpot-games-subnavigation-bar .eurojackpot-information-button:before {
  content: '?';
  font-size: 5.6vw;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-information-button:before {
    font-size: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-games-subnavigation-bar .eurojackpot-information-button:before {
    font-size: 3.6vw;
  }
}
