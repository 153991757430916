.eurojackpot-receipt .countdown,
.eurojackpot-no-open-draw .countdown {
  width: 100%;
  max-width: 88vw;
  text-align: center;
  margin: auto;
  padding: 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-receipt .countdown,
  .eurojackpot-no-open-draw .countdown {
    max-width: 96rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.eurojackpot-receipt .countdown .countdown-headline,
.eurojackpot-no-open-draw .countdown .countdown-headline {
  font-size: 3vw;
  font-weight: normal;
  color: #fff;
}
@media (min-width: 950px) {
  .eurojackpot-receipt .countdown .countdown-headline,
  .eurojackpot-no-open-draw .countdown .countdown-headline {
    font-size: 2rem;
    line-height: 0.9;
    margin-top: 0;
  }
}
.eurojackpot-receipt .countdown .number-container,
.eurojackpot-no-open-draw .countdown .number-container {
  display: inline-block;
  margin: 0 3vw;
  color: #fff;
}
@media (min-width: 950px) {
  .eurojackpot-receipt .countdown .number-container,
  .eurojackpot-no-open-draw .countdown .number-container {
    margin: 0 1.5rem;
  }
}
.eurojackpot-receipt .countdown .number-container .number,
.eurojackpot-no-open-draw .countdown .number-container .number {
  font-size: 7.47vw;
  font-weight: normal;
  margin-right: 1vw;
}
@media (min-width: 950px) {
  .eurojackpot-receipt .countdown .number-container .number,
  .eurojackpot-no-open-draw .countdown .number-container .number {
    font-size: 3.8rem;
    line-height: normal;
    margin-right: 1rem;
  }
}
.eurojackpot-receipt .countdown .number-container .time-denominator,
.eurojackpot-no-open-draw .countdown .number-container .time-denominator {
  font-size: 5.4vw;
}
@media (min-width: 950px) {
  .eurojackpot-receipt .countdown .number-container .time-denominator,
  .eurojackpot-no-open-draw .countdown .number-container .time-denominator {
    font-size: 1.8rem;
  }
}
.eurojackpot-no-open-draw .countdown .countdown-headline {
  color: #000;
}
.eurojackpot-no-open-draw .countdown .number-container {
  color: #000;
}
