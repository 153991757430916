﻿@import url("../Mixins/_mixins.less");

.top-spot-page-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0 4vw;
  font-size: 0;

  @media (min-width: @desktop-width) {
    min-height: 40rem;
    padding: 4rem 0;
  }

  .background-image-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;

    .background-image {
      min-height: 100%;
      min-width: 100vw;
      position: absolute;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
    }
  }

  .top-spot-splash {
    width: 100%;
    position: relative;
    z-index: 1;
    text-align: left;
    font-size: 0;
    margin-bottom: -4vw;
    pointer-events: none;

    @media (min-width: @desktop-width) {
      width: auto;
      position: absolute;
      margin-bottom: 0;
    }

    &.right {
      text-align: right;
    }

    img {
      width: 40vw;

      @media (min-width: @desktop-width) {
        width: auto;
      }
    }
  }

  .top-spot__content-wrapper {
    text-align: center;
    margin-top: 6vw;
    z-index: 1;

    @media (min-width: @desktop-width) {
      margin-top: 0;
      width: 96rem;
    }

    .top-spot__icon-box {
      @media (min-width: @desktop-width) {
        margin-bottom: 3rem;
      }

      .icon__image {
        height: 9vw;

        @media (min-width: @desktop-width) {
          height: 3rem;
        }
      }

      .icon-tag__line {
        font-size: 3vw;
        color: #fff;
        display: block;

        @media (min-width: @desktop-width) {
          font-size: 1.5rem;
        }
      }
    }

    .top-spot__text-wrapper {
      color: #fff;
      margin: auto;
      font-weight: bold;

      .preface-text__line {
        font-size: 4vw;
        letter-spacing: .05em;

        @media (min-width: @desktop-width) {
          font-size: 2.8rem;
        }
      }

      .title-text__line {
        font-weight: 900;
        font-size: 18vw;
        line-height: 1;

        @media (min-width: @desktop-width) {
          font-size: 14rem;
        }
      }

      .subtitle-text__line {
        font-size: 6vw;

        @media (min-width: @desktop-width) {
          font-size: 4rem;
        }
      }
    }

    .top-spot__button {
      color: @numbergames-darkgray;
      font-weight: 700;
      font-size: 4vw;
      text-decoration: none;
      line-height: 10.7vw;
      padding: 0 4vw;
      background-color: @numbergames-cta-yellow;
      display: inline-block;
      border-radius: 6px;
      margin: 4vw auto;

      @media (min-width: @mobile-width) and (max-width: @desktop-width) {
        font-size: 3vw;
        line-height: 8vw;
      }

      @media (min-width: @desktop-width) {
        min-width: 12rem;
        font-size: 1.4rem;
        line-height: 4rem;
        padding: 0 1.2rem;
        margin: 2rem auto 0;
      }
    }

    .top-spot__flipclock-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 2rem;
    }
  }

  &.manchete-top {
    width: 200%;
    transform: translateX(-25%);
    border-radius: ~"50% / 10vw";
    overflow: hidden;
    margin-top: -1.5vw;
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.manchete-bottom {
    width: 200%;
    transform: translateX(-25%);
    border-radius: ~"50% / 10vw";
    overflow: hidden;
    margin-bottom: -1.5vw;
    position: relative;
    z-index: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.manchete-top.manchete-bottom {
    border-radius: ~"50% / 10vw";
  }
}
