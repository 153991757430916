@import url("../Mixins/_mixins.less");

.calltoactionspot {
  text-align: center;

  .call-to-action-box {
    max-width: 45rem;
    margin: 2vw auto 4vw;

    @media (min-width: @desktop-width) {
      margin: 2rem auto 2rem;
    }

    .btn.cta {
      margin-top: 4.5vw;

      @media (min-width: @desktop-width) {
        margin-top: 2rem;
      }
    }
  }
}

.informationswitchercontent {
  .two-column-spot {
    margin-bottom: 1vw;

    @media (min-width: @desktop-width) {
      margin-bottom: 1rem;
    }

    .call-to-action-box {

      @media (min-width: @desktop-width) {
        margin: 0rem auto 2rem;
      }

      .call-to-action-text {

        @media (min-width: @desktop-width) {
          min-height: 7.6rem;
        }
      }
    }
  }
}
