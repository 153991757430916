﻿@import url("../Mixins/_mixins.less");
// new upsale spot
.upsale-spot-view {

  .upsale-spot {
    position: relative;
    margin: 0 auto;

    .upsale-image-container {
      font-size: 0;
      line-height: 0;
      display: block;

      .upsale-image {
        width: 100%;
        height: 100%;
      }
    }

    .upsale-content-container {
      text-align: center;
      position: absolute;
      line-height: 0.9;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 3rem;

      .upsale-title {
        font-size: 12vw;
        color: #fff;
        position: absolute;
        margin: 0;
        width: 100%;
        transform: translate(-50%, -50%);

        @media (min-width: @desktop-width) {
          font-size: 6rem;
          width: auto;
        }
      }

      .upsale-text {
        font-size: 12vw;
        color: #fff;
        position: absolute;
        margin: 0;
        width: 100%;
        transform: translate(-50%, -50%);

        @media (min-width: @desktop-width) {
          font-size: 6rem;
          width: auto;
        }
      }

      .upsale-cta {
        position: static;
        margin-top: 45.6vw;

        @media (min-width: @desktop-width) {
          margin-top: 12.8rem;
        }

        &:hover {
          background-color: @numbergames-cta-yellow-hover;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }

      .upsale-logo-wrapper {
        font-size: 0;
        position: absolute;
        right: 2vw;
        bottom: 2vw;
        text-align: right;
        z-index: -1;

        @media (min-width: @desktop-width) {
          right: 1rem;
          bottom: 1rem;
        }

        .upsale-logo {
          max-height: 4vw;

          @media (min-width: @desktop-width) {
            max-width: 8rem;
            max-height: 2rem;
          }
        }
      }
    }
  }
}
