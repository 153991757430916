.eurojackpot-top-spot .eurojackpot-section {
  min-height: 55vw;
  position: relative;
  box-sizing: border-box;
  padding: 0;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section {
    min-height: 40rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-content-wrapper {
  padding: 5vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .eurojackpot-content-wrapper {
    padding: 5rem 0;
  }
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-content-wrapper > * {
  position: relative;
  z-index: 2;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .eurojackpot-content-wrapper {
    padding: 2rem 0;
  }
}
.eurojackpot-top-spot .eurojackpot-section #eurojackpot-winning-number-subheader {
  text-transform: none;
  margin: 0 auto 14vw;
  font-weight: 400;
  height: 0;
  font-size: 4.4vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section #eurojackpot-winning-number-subheader {
    /*font-size: 16vw;
        margin: 4rem auto;*/
    margin: 0 auto 6rem;
    font-size: 3rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .hd {
  font-size: 18vw;
  letter-spacing: .3rem;
  letter-spacing: .2rem;
  line-height: 1.2;
  margin: 2vw auto 0;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .hd {
    height: 18rem;
    font-size: 11rem;
    word-spacing: normal;
    line-height: 7.2rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .hd .small-text {
  font-size: 8vw;
  letter-spacing: .2rem;
  display: block;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .hd .small-text {
    font-size: 5rem;
    margin-bottom: 0;
  }
}
.eurojackpot-top-spot .eurojackpot-section .hd .big-text {
  font-size: 17.333vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .hd .big-text {
    font-size: 12rem;
    display: block;
    margin-top: 1.7rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .hd .big-text .svg-text-wrapper {
  width: 100%;
  overflow: visible;
  position: relative;
  top: 4.5vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .hd .big-text .svg-text-wrapper {
    top: .6rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .hd.winningnumbers {
  font-size: 12vw;
  height: unset;
  margin: 3vw auto;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .hd.winningnumbers {
    font-size: 8rem;
    margin: 3rem auto 4rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .btn {
  clear: both;
  margin-top: 2vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .btn {
    margin-top: 3rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .help-link {
  text-decoration: none;
  border-bottom: 0.1rem solid #fff;
  font-size: 3.7vw;
  line-height: 4.2vw;
  margin: 10.2vw 0 4.2vw;
  display: inline-block;
}
.eurojackpot-top-spot .eurojackpot-section .help-link:hover {
  border-color: transparent;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .help-link {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2.6rem;
    margin-top: 2.6rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo-wrapper {
  display: none;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo-wrapper {
    display: block;
  }
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo-wrapper-alt {
  display: block;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo-wrapper-alt {
    display: block;
  }
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo {
  width: 33vw;
  margin: 0 auto 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo {
    width: 12.6rem;
    margin: 0 auto 1rem;
  }
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo img {
  width: 100%;
  height: auto;
}
.eurojackpot-top-spot .eurojackpot-section .eurojackpot-logo.logo-for-ugepakke {
  width: 26vw;
}
.eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper {
  padding: 15.2vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper {
    padding: 9.5rem 0 4rem;
  }
}
.eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper .eurojackpot-logo-wrapper-alt .eurojackpot-logo {
  width: 13.6vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper .eurojackpot-logo-wrapper-alt .eurojackpot-logo {
    width: 6.5rem;
  }
}
.eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper .hd.winningnumbers {
  font-size: 8vw;
  margin: -4vw auto 5vw;
  line-height: 1;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper .hd.winningnumbers {
    font-size: 5rem;
    margin: 0 auto 7.1rem;
  }
}
.eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper .hd.winningnumbers .small-text {
  font-size: 4.266vw;
  margin-top: 3vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper .hd.winningnumbers .small-text {
    font-size: 2.6rem;
    font-weight: 900;
    margin-top: 1rem;
  }
}
.eurojackpot-top-spot.faq-top-spot .eurojackpot-section .eurojackpot-content-wrapper #eurojackpot-winning-number-subheader {
  margin: 0 auto -1.5vw;
}
.eurojackpot-top-spot.faq-top-spot + .informationswitcherspotview {
  padding: 0 0;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot.faq-top-spot + .informationswitcherspotview {
    padding: 3.5rem 0;
  }
}
.eurojackpot-top-spot.faq-top-spot + .informationswitcherspotview .informationswitcher-selector {
  margin-top: 9vw;
}
@media (min-width: 950px) {
  .eurojackpot-top-spot.faq-top-spot + .informationswitcherspotview .informationswitcher-selector {
    margin-top: 0;
  }
}
