@import url("../../Mixins/_mixins.less");

.region-lotto {
  .news-list {

    .section {
      // News list-overview
      .news-heading {
        background-color: @lotto-red;
        padding: 6vw 0 10vw;

        @media (min-width: @desktop-width) {
          min-height: 38rem;
          padding: 5rem 0;
        }

        .topspot-headline {
          text-transform: uppercase;
          margin-bottom: 5vw;

          @media (min-width: @desktop-width) {
            margin-bottom: 3rem;
          }
        }
      }

      .content-wrapper {

        @media (min-width: @desktop-width) {
          margin-top: 0;
        }

        .news-block {
          padding: 2rem 0 4rem 0;

          @media (min-width: @desktop-width) {
            padding: 2rem 0;
          }

          .date {
            color: @numbergames-lightgray;
          }

          a.heading-link {
            .headline {
              color: @lotto-red;
              text-transform: uppercase;
            }
          }
        }
      }
      // News single item view
      .content-wrapper {

        .news-block.single {

          .news-item-headline {
            text-transform: uppercase;
            color: @lotto-red;
          }
        }
      }
    }
  }
}
