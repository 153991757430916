/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.upsale-spot-view .upsale-spot {
  position: relative;
  margin: 0 auto;
}
.upsale-spot-view .upsale-spot .upsale-image-container {
  font-size: 0;
  line-height: 0;
  display: block;
}
.upsale-spot-view .upsale-spot .upsale-image-container .upsale-image {
  width: 100%;
  height: 100%;
}
.upsale-spot-view .upsale-spot .upsale-content-container {
  text-align: center;
  position: absolute;
  line-height: 0.9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 3rem;
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-title {
  font-size: 12vw;
  color: #fff;
  position: absolute;
  margin: 0;
  width: 100%;
  transform: translate(-50%, -50%);
}
@media (min-width: 960px) {
  .upsale-spot-view .upsale-spot .upsale-content-container .upsale-title {
    font-size: 6rem;
    width: auto;
  }
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-text {
  font-size: 12vw;
  color: #fff;
  position: absolute;
  margin: 0;
  width: 100%;
  transform: translate(-50%, -50%);
}
@media (min-width: 960px) {
  .upsale-spot-view .upsale-spot .upsale-content-container .upsale-text {
    font-size: 6rem;
    width: auto;
  }
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-cta {
  position: static;
  margin-top: 45.6vw;
}
@media (min-width: 960px) {
  .upsale-spot-view .upsale-spot .upsale-content-container .upsale-cta {
    margin-top: 12.8rem;
  }
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-cta:hover {
  background-color: #f5d900;
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-logo-wrapper {
  font-size: 0;
  position: absolute;
  right: 2vw;
  bottom: 2vw;
  text-align: right;
  z-index: -1;
}
@media (min-width: 960px) {
  .upsale-spot-view .upsale-spot .upsale-content-container .upsale-logo-wrapper {
    right: 1rem;
    bottom: 1rem;
  }
}
.upsale-spot-view .upsale-spot .upsale-content-container .upsale-logo-wrapper .upsale-logo {
  max-height: 4vw;
}
@media (min-width: 960px) {
  .upsale-spot-view .upsale-spot .upsale-content-container .upsale-logo-wrapper .upsale-logo {
    max-width: 8rem;
    max-height: 2rem;
  }
}
