@import url("../Mixins/_mixins");

.accordion-info-overlay__list {
  max-width: 45.4rem;
  margin: 0 auto 5rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.accordion-info-overlay__item {
  border: 1px solid @ds-gray-100;
  border-radius: 16px;
  text-align: left;

  .item-header {
    display: flex;
    padding: 1.6rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    transition: background-color 0.1s linear;
    cursor: pointer;

    &:not(.accordion-info-overlay__item--active):hover {
      background-color: @ds-gray-100;
    }
  }

  .item-header__title {
    line-height: 2.2rem;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .item-header__button {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background-color: @ds-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .item-header__button-icon {
    width: 1.2rem;
    height: 0.8rem;
    position: relative;
    top: 0.1rem;
    transition: transform 0.1s linear;
  }

  .item-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s linear;
  }

  .item-body__inner {
    padding: 1.6rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  &.accordion-info-overlay__item--active {
    .item-header {
      background-color: @ds-gray-100;
    }

    .item-header__button-icon {
      transform: scaleY(-1);
    }
  }
}