.region-eurojackpot .video-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.region-eurojackpot .video-wrapper video::-webkit-media-controls {
  display: none !important;
}
.region-eurojackpot .video-wrapper video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
.region-eurojackpot .video-wrapper video {
  object-fit: inherit;
}
@media (min-width: 950px) {
  .region-eurojackpot .video-wrapper {
    display: block;
  }
}
.region-eurojackpot .video {
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out;
}
.region-eurojackpot .video.is-loading {
  opacity: 0;
}
.region-eurojackpot .video.can-playthrough {
  opacity: 1;
}
