﻿@import url("../Mixins/_mixins.less");

.carouselspot {
  position: relative;
  background-color: #f1f2f2;
  padding: 7vw 0 14vw;
  height: 56vw;

  @media (min-width: @desktop-width) {
    padding: 4rem 0;
    height: auto;
  }

  .carousel {
    position: absolute;
    width: 300vw;
    left: 10vw;
    margin: auto;
    overflow: hidden;
    transition:transform .5s ease-out;

    @media (min-width: @desktop-width) {
      position: relative;
      left: auto;
      width: 96rem;
    }

    .subitem {
      //display: inline-block;
      margin-right: 4vw;
      cursor: pointer;
      float: left;

      @media (min-width: @desktop-width) {
        margin-right: 0;
      }

      &.small {
        @media (min-width: @desktop-width) {
          margin-right: 1.5rem;
          width: 31rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &.medium {
        @media (min-width: @desktop-width) {
          width: 46rem;
          margin-right: 4rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &.large {
        @media (min-width: @desktop-width) {
          width: 96rem;
          margin-bottom: 4rem;
        }
      }

      &.small, &.medium, &.large {
        width: 80vw;
        height: 56vw;
        @media (min-width: @desktop-width) {
          width:31rem;
          height: 21.4rem;
        }
      }
    }
  }

  .carousel-nav {
    bottom: 3.5vw;
    position: absolute;
    width: 100%;
    text-align: center;

    span {
      background-color: #cfd0d1;
      width: 2.67vw;
      height: 2.67vw;
      border-radius: 50%;
      display: inline-block;
      margin: 1.34vw;
      cursor: pointer;

      &.active {
        background-color: #808285;
      }
    }
  }
}
