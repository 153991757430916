.responsive-bg {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}
@media (max-width: 767px) {
  .responsive-bg--mobile-top-center {
    justify-content: center;
    align-items: flex-start;
  }
  .responsive-bg--mobile-bottom-center {
    justify-content: center;
    align-items: flex-end;
  }
  .responsive-bg--mobile-center-left {
    justify-content: flex-start;
    align-items: center;
  }
  .responsive-bg--mobile-center-right {
    justify-content: flex-end;
    align-items: center;
  }
  .responsive-bg--mobile-center {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--mobile-cover {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--mobile-cover picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .responsive-bg--mobile-cover img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .responsive-bg--sm-top-center {
    justify-content: center;
    align-items: flex-start;
  }
  .responsive-bg--sm-bottom-center {
    justify-content: center;
    align-items: flex-end;
  }
  .responsive-bg--sm-center-left {
    justify-content: flex-start;
    align-items: center;
  }
  .responsive-bg--sm-center-right {
    justify-content: flex-end;
    align-items: center;
  }
  .responsive-bg--sm-center {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--sm-cover {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--sm-cover picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .responsive-bg--sm-cover img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .responsive-bg--lg-top-center {
    justify-content: center;
    align-items: flex-start;
  }
  .responsive-bg--lg-bottom-center {
    justify-content: center;
    align-items: flex-end;
  }
  .responsive-bg--lg-center-left {
    justify-content: flex-start;
    align-items: center;
  }
  .responsive-bg--lg-center-right {
    justify-content: flex-end;
    align-items: center;
  }
  .responsive-bg--lg-center {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--lg-cover {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--lg-cover picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .responsive-bg--lg-cover img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1440px) {
  .responsive-bg--xxl-top-center {
    justify-content: center;
    align-items: flex-start;
  }
  .responsive-bg--xxl-bottom-center {
    justify-content: center;
    align-items: flex-end;
  }
  .responsive-bg--xxl-center-left {
    justify-content: flex-start;
    align-items: center;
  }
  .responsive-bg--xxl-center-right {
    justify-content: flex-end;
    align-items: center;
  }
  .responsive-bg--xxl-center {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--xxl-cover {
    justify-content: center;
    align-items: center;
  }
  .responsive-bg--xxl-cover picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .responsive-bg--xxl-cover img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}
.responsive-bg picture {
  line-height: 0;
}
.responsive-lottie-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.responsive-lottie-bg .responsive-lottie-bg__lottie-player {
  position: absolute;
  top: 0;
  width: 500rem;
  height: 100%;
}
