@import url("../Mixins/_mixins.less");

.news-list {
  text-align: center;

  .section {
    position: relative;
    // fonts
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    background-color: @numbergames-white;
    border-color: @numbergames-white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (min-width: @desktop-width) {
      padding-top: 0rem;
    }
    // News list-overview
    .news-heading {
      color: @numbergames-white;
      position: relative;
      z-index: 2;

      @media (min-width: @desktop-width) {
        box-sizing: border-box;
      }

      .top-logo {
        margin-bottom: 4vw;

        @media (min-width: @desktop-width) {
          margin-bottom: 3rem;
        }

        img {
          width: 17.33vw;
          height: 17.33vw;

          @media (min-width: @desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
          }
        }
      }

      .topspot-headline {
        max-width: 86.5vw;
        font-size: 10.6vw;
        line-height: 10vw;
        margin: 0 auto;

        @media (min-width: @mobile-width) {
          font-size: 6rem;
          line-height: 5.4rem;
        }
      }

      .topspot-subheadline {
        max-width: 86.5vw;
        font-size: 5.15vw;
        text-transform: none;
        line-height: 5.5vw;
        margin: 0 auto;

        @media (min-width: @mobile-width) {
          font-size: 2.5rem;
          line-height: 1.3;
        }
      }
    }

    .content-wrapper {
      text-align: center;
      z-index: 4;
      padding: 0;
      margin: 0 auto;

      .news-block {
        width: 100%;
        max-width: 86.67vw;
        border-bottom: .1rem solid @numbergames-lightgray;
        padding: 8vw 0;
        margin: auto;

        @media (min-width: @desktop-width) {
          max-width: 58rem;
          padding: 4rem 0;
        }

        &:last-child {
          border-bottom: none;
        }

        .date {
          font-size: 3.73vw;
          line-height: normal;

          @media (min-width: @mobile-width) {
            font-size: 2rem;
            line-height: 2.0rem;
          }
        }

        a.heading-link {
          text-decoration: none;

          .headline {
            max-width: 86.5vw;
            font-size: 5.4vw;
            font-weight: 900;
            line-height: 5.6vw;
            margin: 1.5rem auto 2rem;

            @media (min-width: @mobile-width) {
              font-size: 3.8rem;
              line-height: 4rem;
            }
            
            @media (min-width: @mobile-width) and (max-width: @desktop-width) {
              font-size: 3.3rem;
            }
          }
        }

        .teaser-details {
          font-size: 3.73vw;
          color: @numbergames-mediumgray;
          display: block;
          margin-bottom: 5vw;

          @media (min-width: @mobile-width) {
            font-size: 1.6rem;
            margin-bottom: 2rem;
          }
          
          @media (min-width: @mobile-width) and (max-width: @desktop-width) {
            font-size: 1.8rem;
          }
        }

        .svg-icon-arrow-right {
          position: absolute;
          top: -0.3vw;
          right: 3vw;

          @media (min-width: @desktop-width) {
            position: absolute;
            top: -0.1rem;
            right: 1.3rem;
          }

          svg {
            fill: #322b00;
            width: 3.47vw;
            height: 2.134vw;

            @media (min-width: @desktop-width) {
              width: 1.3rem;
              height: 0.8rem;
            }
          }
        }

        .btn {
          color: #322b00;
          background-color: @numbergames-cta-yellow;
          text-decoration: none;
          box-sizing: border-box;
          display: inline-block;
          padding: 0 5.5vw 0 2vw;
          margin: 2rem auto 0;

          @media (min-width: @desktop-width) {
            padding: 0 2.5rem 0 1.2rem;
          }
        }
      }
    }
    // News single item view
    .top-spot-filling {
      min-height: 35rem;
    }

    .content-wrapper {

      .news-block.single {
        padding: 15vw 0 6vw;

        @media (min-width: @desktop-width) {
          padding: 4rem 0;
          max-width: 58rem;
        }

        .news-item-headline {
          max-width: 86.5vw;
          font-size: 5.4vw;
          font-weight: 900;
          line-height: 5.6vw;
          margin: 1.5rem auto 2rem;

          @media (min-width: @desktop-width) {
            font-size: 3.5rem;
            line-height: 3.5rem;
          }
        }
      }

      .svg-social-share {
        width: 4rem;
        height: 4rem;
        margin: 2.0rem 1.2rem;
      }
    }
  }
}
