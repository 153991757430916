.eurojackpot-system-game-header {
  background-color: #eeeeee;
  overflow: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-system-game-header {
    height: 7rem;
  }
}
.eurojackpot-system-game-header .game-switch-wrapper {
  position: relative;
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper {
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  overflow-x: auto;
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper {
  display: inline-block;
  padding-right: 6vw;
}
@media (min-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper {
    padding-right: 0;
  }
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title,
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
  font-size: 4vw;
  text-transform: uppercase;
  display: inline-block;
  padding: 5.3vw 0 5.235vw;
  margin: 0 0 0 6vw;
}
@media (min-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title,
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
    font-size: 1.6rem;
    padding: 2.4rem 0 2.4rem;
    margin: 0 0 0 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title,
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
    font-size: 2.5vw;
    padding: 2.5vw 0;
  }
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title:last-child,
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item:last-child {
  margin: 0 0 0 6vw;
}
@media (min-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title:last-child,
  .eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item:last-child {
    margin: 0 9.5rem 0 2.5rem;
  }
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
  cursor: pointer;
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item.active {
  font-weight: 700;
  cursor: default;
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button {
  width: 11vw;
  height: 16vw;
  position: absolute;
  top: 0;
  transition: transform .2s linear;
  z-index: 1;
  cursor: pointer;
}
@media (min-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-button {
    width: 11rem;
    height: 7rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-button {
    width: 7vw;
    height: 8.4vw;
  }
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button.next {
  right: 0;
  background: linear-gradient(to right, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);
  transform: translate3d(100%, 0, 0);
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button.next:hover .icon {
  transform: translate3d(0, -50%, 0);
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button.prev {
  left: 0;
  background: linear-gradient(to right, #eeeeee 0%, rgba(238, 238, 238, 0) 100%);
  transform: translate3d(-100%, 0, 0);
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button.prev:hover .icon {
  transform: translate3d(-100%, -50%, 0);
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button.active {
  transform: translate3d(0, 0, 0);
}
.eurojackpot-system-game-header .game-switch-wrapper .switch-button .icon {
  width: 2.133vw;
  height: 3.466vw;
  fill: #1f1f1f;
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: transform .15s linear;
}
@media (min-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-button .icon {
    top: 50%;
    width: 2rem;
    height: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-system-game-header .game-switch-wrapper .switch-button .icon {
    height: 2.5vw;
  }
}
.eurojackpot-system-game-header.overflown-left .game-switch-wrapper .switch-button.prev {
  transform: translate3d(0, 0, 0);
}
.eurojackpot-system-game-header.overflown-right .game-switch-wrapper .switch-button.next {
  transform: translate3d(0, 0, 0);
}
