@import url("../Mixins/_mixins.less");

.eurojackpot-system-game-header {
  background-color:@ds-gray-100;
  overflow:hidden;
  @media (min-width: @eurojackpot-desktop-width) {
    height:7rem;
  }
  .game-switch-wrapper {
    position:relative;
    .switch-inner-wrapper {
      white-space:nowrap;
      user-select:none;
      overflow:hidden;
      overflow-x:auto;
      .switch-items-wrapper {
        display:inline-block;
        padding-right:6vw;
        @media (min-width: @eurojackpot-desktop-width) {
          padding-right:0;
        }
        .switch-title, .switch-item {
          font-size:4vw;
          text-transform:uppercase;
          display:inline-block;
          padding:5.3vw 0 5.235vw;
          margin:0 0 0 6vw;
          @media (min-width: @eurojackpot-desktop-width) {
            font-size:1.6rem;
            padding:2.4rem 0 2.4rem;
            margin:0 0 0 2.5rem;
          }

          @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
            font-size: 2.5vw;
            padding: 2.5vw 0;
          }

          &:last-child {
            margin:0 0 0 6vw;
            @media (min-width: @eurojackpot-desktop-width) {
              margin:0 9.5rem 0 2.5rem;
            }
          }
        }
        .switch-item {
          cursor:pointer;
          &.active {
            font-weight: 700;
            cursor:default;
          }
        }
      }
    }

    .switch-button {
      width:11vw;
      height:16vw;
      position:absolute;
      top:0;
      transition:transform .2s linear;
      z-index:1;
      cursor:pointer;
      @media (min-width: @eurojackpot-desktop-width) {
        width:11rem;
        height:7rem;
      }

      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        width: 7vw;
        height: 8.4vw;
      }

      &.next {
        right:0;
        background: linear-gradient(to right,  rgba(238, 238, 238,0) 0%,rgba(238, 238, 238,1) 100%);
        transform:translate3d(100%, 0, 0);
        &:hover {
          .icon {
            transform:translate3d(0, -50%, 0);
          }
        }
      }
      &.prev {
        left:0;
        background: linear-gradient(to right,  rgba(238, 238, 238,1) 0%,rgba(238, 238, 238,0) 100%);
        transform:translate3d(-100%, 0, 0);
        &:hover {
          .icon {
            transform:translate3d(-100%, -50%, 0);
          }
        }
      }
      &.active {
        transform:translate3d(0, 0, 0);
      }
      .icon {
        width:2.133vw;
        height:3.466vw;
        fill: @eurojackpot-gray;
        position:absolute;
        top:50.5%;
        left:50%;
        transform:translate3d(-50%, -50%, 0);
        transition:transform .15s linear;
        @media (min-width: @eurojackpot-desktop-width) {
          top:50%;
          width:2rem;
          height:3rem;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          height: 2.5vw;
        }
      }
    }
  }
	
  &.overflown-left {
    .game-switch-wrapper {
      .switch-button.prev {
        transform:translate3d(0, 0, 0);
      }
    }
  }
  &.overflown-right {
    .game-switch-wrapper {
      .switch-button.next {
        transform:translate3d(0, 0, 0);
      }
    }
  }
}