@import (reference) "../_imports";

.tutorial__content-box {
  max-width: 144rem;
  margin: 0 auto 0;
  background-color: @ds-white;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);
  
  &--full-width {
    max-width: 100%;
  }
  
  &--no-radius {
    border-radius: 0;
  }
}
