.eurojackpot-classic-game {
  background-color: white;
}
.eurojackpot-classic-game .eurojackpot-content-wrapper {
  width: 100vw;
  text-align: left;
  overflow: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .eurojackpot-content-wrapper {
    width: 100%;
    overflow: visible;
  }
}
.eurojackpot-classic-game .rows-container {
  height: 229.87vw;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 4.8vw;
  position: relative;
  will-change: transform;
  transform-origin: right center;
  transition: transform .2s linear;
  overflow: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-container {
    height: 55.8rem;
    padding-top: 0;
    padding-bottom: 2rem;
    margin-top: -2.3rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-container {
    height: 98vw;
  }
}
@-webkit-keyframes eurojackpot-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes eurojackpot-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes eurojackpot-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes eurojackpot-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes eurojackpot-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes eurojackpot-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes eurojackpot-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes eurojackpot-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes eurojackpot-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes eurojackpot-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes eurojackpot-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes eurojackpot-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes eurojackpot-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes eurojackpot-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes eurojackpot-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes eurojackpot-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes eurojackpot-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes eurojackpot-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes eurojackpot-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes eurojackpot-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes eurojackpot-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes eurojackpot-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes eurojackpot-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes eurojackpot-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(119%, 1%, 0);
    transform: scale(0.9) translate3D(119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes eurojackpot-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes eurojackpot-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes eurojackpot-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes eurojackpot-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
    transform: scale(0.9) translate3D(-119%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@media (min-width: 950px) {
  @-webkit-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
@media (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  @-webkit-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  @-webkit-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes eurojackpot-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes eurojackpot-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes eurojackpot-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
.eurojackpot-classic-game .rows-container.slide-to-right {
  -webkit-animation: eurojackpot-slide-to-right-animation 0.6s forwards ease-in-out;
  -moz-animation: eurojackpot-slide-to-right-animation 0.6s forwards ease-in-out;
  -ms-animation: eurojackpot-slide-to-right-animation 0.6s forwards ease-in-out;
  animation: eurojackpot-slide-to-right-animation 0.6s forwards ease-in-out;
}
.eurojackpot-classic-game .rows-container.slide-to-right .in-focus {
  -webkit-animation: eurojackpot-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  -moz-animation: eurojackpot-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  -ms-animation: eurojackpot-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  animation: eurojackpot-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
}
.eurojackpot-classic-game .rows-container.slide-to-right .next-row {
  -webkit-animation: eurojackpot-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  -moz-animation: eurojackpot-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  -ms-animation: eurojackpot-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  animation: eurojackpot-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
}
.eurojackpot-classic-game .rows-container.slide-to-right .prev-row {
  -webkit-animation: eurojackpot-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  -moz-animation: eurojackpot-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  -ms-animation: eurojackpot-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  animation: eurojackpot-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
}
.eurojackpot-classic-game .rows-container.slide-to-right .prev-prev-row {
  -webkit-animation: eurojackpot-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  -moz-animation: eurojackpot-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  -ms-animation: eurojackpot-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  animation: eurojackpot-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
}
.eurojackpot-classic-game .rows-container.slide-to-left,
.eurojackpot-classic-game .rows-container.slide-to-left-half {
  -webkit-animation: eurojackpot-slide-to-left-animation 0.6s forwards linear;
  -moz-animation: eurojackpot-slide-to-left-animation 0.6s forwards linear;
  -ms-animation: eurojackpot-slide-to-left-animation 0.6s forwards linear;
  animation: eurojackpot-slide-to-left-animation 0.6s forwards linear;
}
.eurojackpot-classic-game .rows-container.slide-to-left .in-focus,
.eurojackpot-classic-game .rows-container.slide-to-left-half .in-focus {
  -webkit-animation: eurojackpot-slide-to-left-infocus-row-animation 0.6s forwards linear;
  -moz-animation: eurojackpot-slide-to-left-infocus-row-animation 0.6s forwards linear;
  -ms-animation: eurojackpot-slide-to-left-infocus-row-animation 0.6s forwards linear;
  animation: eurojackpot-slide-to-left-infocus-row-animation 0.6s forwards linear;
}
.eurojackpot-classic-game .rows-container.slide-to-left .next-row,
.eurojackpot-classic-game .rows-container.slide-to-left-half .next-row {
  -webkit-animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
  -moz-animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
  -ms-animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
  animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
}
.eurojackpot-classic-game .rows-container.slide-to-left .next-next-row,
.eurojackpot-classic-game .rows-container.slide-to-left-half .next-next-row {
  -webkit-animation: eurojackpot-slide-to-left-next-next-row-animation 0.6s forwards linear;
  -moz-animation: eurojackpot-slide-to-left-next-next-row-animation 0.6s forwards linear;
  -ms-animation: eurojackpot-slide-to-left-next-next-row-animation 0.6s forwards linear;
  animation: eurojackpot-slide-to-left-next-next-row-animation 0.6s forwards linear;
}
.eurojackpot-classic-game .rows-container.slide-to-left .prev-row,
.eurojackpot-classic-game .rows-container.slide-to-left-half .prev-row {
  -webkit-animation: eurojackpot-slide-to-left-prev-row-animation 0.6s forwards linear;
  -moz-animation: eurojackpot-slide-to-left-prev-row-animation 0.6s forwards linear;
  -ms-animation: eurojackpot-slide-to-left-prev-row-animation 0.6s forwards linear;
  animation: eurojackpot-slide-to-left-prev-row-animation 0.6s forwards linear;
}
.eurojackpot-classic-game .rows-container.slide-to-left .in-focus + .next-row:not(.interacted-row):last-of-type,
.eurojackpot-classic-game .rows-container.slide-to-left-half .in-focus + .next-row:not(.interacted-row):last-of-type {
  -webkit-animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
  -moz-animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
  -ms-animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
  animation: eurojackpot-slide-to-left-next-row-animation 0.6s forwards linear;
}
@-webkit-keyframes eurojackpot-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes eurojackpot-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes eurojackpot-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes eurojackpot-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1500, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
.eurojackpot-classic-game .rows-container.delete-row > .in-focus {
  -webkit-animation: eurojackpot-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  -moz-animation: eurojackpot-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  -ms-animation: eurojackpot-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  animation: eurojackpot-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
}
.eurojackpot-classic-game .rows-container.delete-row > .in-focus + .next-row {
  -webkit-animation: eurojackpot-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  -moz-animation: eurojackpot-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  -ms-animation: eurojackpot-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  animation: eurojackpot-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
}
.eurojackpot-classic-game .rows-container.delete-row > .in-focus + .next-row + div {
  -webkit-animation: eurojackpot-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  -moz-animation: eurojackpot-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  -ms-animation: eurojackpot-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  animation: eurojackpot-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
}
.eurojackpot-classic-game .left-gradient,
.eurojackpot-classic-game .right-gradient {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .left-gradient,
  .eurojackpot-classic-game .right-gradient {
    width: 19vw;
  }
}
.eurojackpot-classic-game .left-gradient {
  left: 0;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.eurojackpot-classic-game .right-gradient {
  right: -19vw;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  transition: right .25S linear;
}
.eurojackpot-classic-game .right-gradient.active {
  right: 0;
}
.eurojackpot-classic-game .rows-wrapper {
  filter: url("#rows-side-sliding-blur");
}
.eurojackpot-classic-game .row-container {
  width: 72.799%;
  padding-bottom: 1.6vw;
  display: inline-block;
  position: absolute;
  visibility: hidden;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .row-container {
    width: 81%;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container {
    width: 81rem;
    padding-bottom: 1.9rem;
  }
}
.eurojackpot-classic-game .row-container.in-focus,
.eurojackpot-classic-game .row-container.next-row,
.eurojackpot-classic-game .row-container.prev-row,
.eurojackpot-classic-game .row-container.next-next-row,
.eurojackpot-classic-game .row-container.prev-prev-row {
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  visibility: visible;
  will-change: transform, opacity;
}
.eurojackpot-classic-game .row-container.in-focus {
  -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  z-index: 1;
}
.eurojackpot-classic-game .row-container.next-row:after,
.eurojackpot-classic-game .row-container.prev-row:after,
.eurojackpot-classic-game .row-container.prev-prev-row:after,
.eurojackpot-classic-game .row-container.next-next-row:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.eurojackpot-classic-game .row-container.next-row {
  -webkit-transform: scale(0.9) translate3D(119%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(119%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(119%, 1%, 0);
  -o-transform: scale(0.9) translate3D(119%, 1%, 0);
  transform: scale(0.9) translate3D(119%, 1%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
  }
}
@media (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .eurojackpot-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 660, 9, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .eurojackpot-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 880, 9, 0, 1);
  }
}
.eurojackpot-classic-game .row-container.next-next-row {
  -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
  -o-transform: scale(0.9) translate3D(185%, 1%, 0);
  transform: scale(0.9) translate3D(185%, 1%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
  }
}
@media (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .eurojackpot-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1125, 9, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .eurojackpot-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1500, 9, 0, 1);
  }
}
.eurojackpot-classic-game .row-container.prev-row {
  -webkit-transform: scale(0.9) translate3D(-119%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(-119%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(-119%, 1%, 0);
  -o-transform: scale(0.9) translate3D(-119%, 1%, 0);
  transform: scale(0.9) translate3D(-119%, 1%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
  }
}
@media (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .eurojackpot-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -660, 9, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .eurojackpot-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -880, 9, 0, 1);
  }
}
.eurojackpot-classic-game .row-container.prev-prev-row {
  -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
  transform: scale(0.9) translate3D(-185%, 1%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
  }
}
@media (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .eurojackpot-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1125, 9, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .eurojackpot-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1500, 9, 0, 1);
  }
}
@-webkit-keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.eurojackpot-classic-game .row-container.shake-state .header-rules-text,
.eurojackpot-classic-game .row-container.shake-star-numbers-state .header-rules-text {
  -webkit-animation: eurojackpot-rules-pulse-animation 0.8s forwards;
  -moz-animation: eurojackpot-rules-pulse-animation 0.8s forwards;
  -ms-animation: eurojackpot-rules-pulse-animation 0.8s forwards;
  animation: eurojackpot-rules-pulse-animation 0.8s forwards;
}
.eurojackpot-classic-game .row-container.shake-state .cells-container .selected {
  -webkit-animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
  -moz-animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
  -ms-animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
  animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
}
.eurojackpot-classic-game .row-container.shake-star-numbers-state .row-stars-container .selected {
  -webkit-animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
  -moz-animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
  -ms-animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
  animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
}
@keyframes eurojackpot-not-completed-animation {
  0% {
    transform: scale(1);
  }
  14.28% {
    transform: scale(1.2);
  }
  28.57% {
    transform: scale(1);
  }
  42.85% {
    transform: scale(1.2);
  }
  57.13% {
    transform: scale(1);
  }
  71.41% {
    transform: scale(1.2);
  }
  85.69% {
    transform: scale(1);
  }
}
@keyframes eurojackpot-not-completed-text-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .row-container.row-not-completed .cell:not(.selected) {
  animation: eurojackpot-not-completed-animation 1s forwards;
}
.eurojackpot-classic-game .row-container.row-not-completed .star-box:not(.selected) {
  animation: eurojackpot-not-completed-animation 1s forwards;
}
.eurojackpot-classic-game .row-container.row-not-completed .header-rules-text {
  animation: eurojackpot-not-completed-text-animation 1s forwards;
}
@keyframes eurojackpot-cell-selection-animation-demo {
  0% {
    width: 0%;
    height: 0%;
    top: 50%;
    left: 50%;
    margin: 0;
  }
  40% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    margin: -50%;
  }
  70% {
    width: 130%;
    height: 130%;
    top: 50%;
    left: 50%;
    margin: -65%;
  }
  100% {
    width: 0%;
    height: 0%;
    top: 50%;
    left: 50%;
    margin: 0;
  }
}
@keyframes eurojackpot-row-demo-animation {
  0% {
    opacity: .5;
    left: 0%;
  }
  20% {
    opacity: .9;
    left: -5%;
  }
  60% {
    opacity: .9;
    left: -5%;
  }
  100% {
    opacity: .5;
    left: 0%;
  }
}
@keyframes eurojackpot-row-demo1-animation {
  0% {
    filter: blur(0);
  }
  10% {
    filter: blur(5px);
  }
  90% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}
@keyframes eurojackpot-row-demo2-animation {
  0% {
    opacity: .5;
  }
  40% {
    opacity: .7;
  }
  60% {
    opacity: .7;
  }
  100% {
    opacity: .5;
  }
}
.eurojackpot-classic-game .row-container.row-completed.row-blury.row-number-1 {
  animation: eurojackpot-row-demo1-animation 2.8s 1.5s linear forwards;
}
.eurojackpot-classic-game .row-container.row-completed + .row-number-2.new-row {
  animation: eurojackpot-row-demo-animation 0.7s 4s linear forwards;
}
.eurojackpot-classic-game .row-container.row-completed + .new-row {
  animation: eurojackpot-row-demo2-animation 0.7s 1.5s linear forwards;
}
.eurojackpot-classic-game .row-container.row-completed + .new-row .cell {
  perspective: 10rem;
}
@keyframes eurojackpot-check-row-demo {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(-100deg) scale(1);
  }
  35% {
    transform: rotateY(-180deg) scale(1.05);
  }
  40% {
    transform: rotateY(-200deg) scale(1.1);
  }
  80% {
    transform: rotateY(-240deg) scale(1.1);
  }
  85% {
    transform: rotateY(-300deg) scale(1.05);
  }
  100% {
    transform: rotateY(-360deg) scale(1);
  }
}
@keyframes eurojackpot-check-row-demo-ie {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1.1);
  }
  85% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .row-container.row-completed + .new-row .cell .cell-container {
  transform-style: preserve-3d;
  transform: rotateY(0deg) scale(1);
  animation: eurojackpot-check-row-demo 1s linear;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell .cell-container {
    animation-name: eurojackpot-check-row-demo-ie;
  }
}
.eurojackpot-classic-game .row-container.row-completed + .new-row .cell .cell-container .face {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.eurojackpot-classic-game .row-container.row-completed + .new-row .cell .cell-container .face.front {
  visibility: visible;
  transform: rotateY(0deg);
}
@keyframes eurojackpot-check-row-back-cell-demo-ie {
  20% {
    opacity: 0;
    visibility: hidden;
  }
  60% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.eurojackpot-classic-game .row-container.row-completed + .new-row .cell .cell-container .face.back {
  visibility: visible;
  transform: rotateY(180deg);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell .cell-container .face.back {
    visibility: hidden;
    transform: rotateY(0);
    animation: eurojackpot-check-row-back-cell-demo-ie 1s linear;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+1) .cell-container {
    animation-delay: .73s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+2) .cell-container {
    animation-delay: .81s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+3) .cell-container {
    animation-delay: .89s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+4) .cell-container {
    animation-delay: .97s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+5) .cell-container {
    animation-delay: 1.05s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+6) .cell-container {
    animation-delay: 1.13s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+7) .cell-container {
    animation-delay: 1.21s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+8) .cell-container {
    animation-delay: 1.29s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+9) .cell-container {
    animation-delay: 1.37s;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-container.row-completed + .new-row .cell:nth-child(10n+10) .cell-container {
    animation-delay: 1.45s;
  }
}
.eurojackpot-classic-game .prev-row-button {
  width: 10.5vw;
  height: 32vw;
  background-color: #eeeeee;
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  -webkit-transform: translateY(-50%) translateX(-10.5vw);
  -moz-transform: translateY(-50%) translateX(-10.5vw);
  -ms-transform: translateY(-50%) translateX(-10.5vw);
  -o-transform: translateY(-50%) translateX(-10.5vw);
  transform: translateY(-50%) translateX(-10.5vw);
  -webkit-transition: -webkit-transform 0.15s;
  -moz-transition: -moz-transform 0.15s;
  -ms-transition: -ms-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .prev-row-button {
    width: 4.5vw;
    transform: translateY(-50%) translateX(-4.5vw);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .prev-row-button {
    width: 5rem;
    height: 14rem;
    -webkit-transform: translateY(-50%) translateX(-5rem);
    -moz-transform: translateY(-50%) translateX(-5rem);
    -ms-transform: translateY(-50%) translateX(-5rem);
    -o-transform: translateY(-50%) translateX(-5rem);
    transform: translateY(-50%) translateX(-5rem);
    cursor: pointer;
  }
}
.eurojackpot-classic-game .prev-row-button .icon {
  width: 2.4vw;
  height: 3.468vw;
  font-size: 4.5vw;
  line-height: .55;
  background-color: transparent;
  fill: #1f1f1f;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.5vw);
  -moz-transform: translateY(-50%) translateX(-1.5vw);
  -ms-transform: translateY(-50%) translateX(-1.5vw);
  -o-transform: translateY(-50%) translateX(-1.5vw);
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .prev-row-button .icon {
    width: 2vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .prev-row-button .icon {
    width: 1.2rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.2rem);
    -moz-transform: translateY(-50%) translateX(-0.2rem);
    -ms-transform: translateY(-50%) translateX(-0.2rem);
    -o-transform: translateY(-50%) translateX(-0.2rem);
    transform: translateY(-50%) translateX(-0.2rem);
  }
}
.eurojackpot-classic-game .prev-row-button.active {
  transform: translateY(-50%) translateX(0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .prev-row-button.active {
    transform: translateY(-50%) translateX(-0.8rem);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .prev-row-button.active:hover {
    transform: translateY(-50%) translateX(0);
  }
}
.eurojackpot-classic-game .create-new-row-button,
.eurojackpot-classic-game .next-row-button {
  width: 10.5vw;
  height: 32vw;
  background-color: #eeeeee;
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 6px 0 0 6px;
  transform: translateY(-50%) translateX(10.5vw);
  transition: right .15s, transform .15s;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button,
  .eurojackpot-classic-game .next-row-button {
    width: 4.5vw;
    transform: translateY(-50%) translateX(4.5vw);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button,
  .eurojackpot-classic-game .next-row-button {
    width: 5rem;
    height: 14rem;
    -webkit-transform: translateY(-50%) translateX(5rem);
    -moz-transform: translateY(-50%) translateX(5rem);
    -ms-transform: translateY(-50%) translateX(5rem);
    -o-transform: translateY(-50%) translateX(5rem);
    transform: translateY(-50%) translateX(5rem);
    cursor: pointer;
  }
}
.eurojackpot-classic-game .create-new-row-button .icon,
.eurojackpot-classic-game .next-row-button .icon {
  width: 2.4vw;
  height: 3.468vw;
  font-size: 4.5vw;
  line-height: .55;
  fill: #1f1f1f;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button .icon,
  .eurojackpot-classic-game .next-row-button .icon {
    width: 2vw;
    transform: translateY(-50%) translateX(-0.8vw);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button .icon,
  .eurojackpot-classic-game .next-row-button .icon {
    width: 1.2rem;
    height: 1.2rem;
    transform: translateY(-50%) translateX(-0.8rem);
  }
}
.eurojackpot-classic-game .create-new-row-button.active,
.eurojackpot-classic-game .next-row-button.active {
  transform: translateY(-50%) translateX(0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button.active,
  .eurojackpot-classic-game .next-row-button.active {
    transform: translateY(-50%) translateX(0.8rem);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button.active:hover,
  .eurojackpot-classic-game .next-row-button.active:hover {
    transform: translateY(-50%) translateX(0);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button {
    width: 6.4rem;
    transform: translateY(-50%) translateX(6.4rem);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button .icon {
    -webkit-transform: translateY(-50%) translateX(-1.6rem);
    -moz-transform: translateY(-50%) translateX(-1.6rem);
    -ms-transform: translateY(-50%) translateX(-1.6rem);
    -o-transform: translateY(-50%) translateX(-1.6rem);
    transform: translateY(-50%) translateX(-1.6rem);
  }
}
@keyframes eurojackpot-create-button-demo-animation {
  0% {
    transform: translateY(-50%) translateX(6.4rem);
  }
  40% {
    background-color: rgba(235, 248, 253, 0.8);
    transform: translateY(-50%) translateX(0);
  }
  100% {
    transform: translateY(-50%) translateX(2.3rem);
  }
}
.eurojackpot-classic-game .create-new-row-button.active {
  transform: translateY(-50%) translateX(0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button.active {
    -webkit-transform: translateY(-50%) translateX(2.3rem);
    -moz-transform: translateY(-50%) translateX(2.3rem);
    -ms-transform: translateY(-50%) translateX(2.3rem);
    -o-transform: translateY(-50%) translateX(2.3rem);
    transform: translateY(-50%) translateX(2.3rem);
    animation: eurojackpot-create-button-demo-animation 0.95s linear forwards;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .create-new-row-button.active:hover {
    right: .8rem;
  }
}
.eurojackpot-classic-game .create-new-row-button.active ~ .next-row-button.active {
  display: none;
}
@keyframes eurojackpot-create-new-row-text-animation {
  0% {
    transform: translateY(0vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 0;
    filter: blur(5px);
  }
  5% {
    transform: translateY(75vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  10% {
    transform: translateY(77vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  75% {
    transform: translateY(77vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  80% {
    transform: translateY(77vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: translateY(77vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes eurojackpot-create-new-row-text-animation-desktop {
  0% {
    transform: translateY(0rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 0;
    filter: blur(5px);
  }
  5% {
    transform: translateY(20rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  10% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  75% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  80% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
  width: 64vw;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transform: translateY(0vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
  animation: eurojackpot-create-new-row-text-animation 6s 2.15s linear forwards;
  z-index: 3;
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
    top: -25%;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
    width: 115%;
    transform: translateY(1rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    animation: eurojackpot-create-new-row-text-animation-desktop 6s 2.15s linear forwards;
  }
}
@keyframes eurojackpot-create-new-row-text-box-animation {
  0% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
    filter: blur(5px);
  }
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-box {
  font-size: 6vw;
  line-height: 1.2;
  color: #E8A332;
  background-color: #fff;
  padding: 1.3vw 2.6vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
  animation: eurojackpot-create-new-row-text-box-animation 0.5s 3.6s linear forwards;
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-box {
    font-size: 2.5rem;
    line-height: 1.5;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-box {
    font-size: 1.5vw;
    padding: 1rem 2rem;
  }
}
@keyframes eurojackpot-create-new-row-text-arrow-animation {
  0% {
    transform: translateX(0) translateY(-50%) scale(0.2);
    opacity: 0;
  }
  40% {
    transform: translateX(40vw) translateY(-50%) scale(1);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(80vw) translateY(-50%) scale(1);
    opacity: 0;
    display: none;
  }
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow {
  height: 35vw;
  width: 1.5vw;
  position: absolute;
  top: 50%;
  right: 25%;
  z-index: -1;
  transform: translateX(0) translateY(-50%) scale(0.2);
  opacity: 0;
  animation: eurojackpot-create-new-row-text-arrow-animation 1s 3.5s linear forwards;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow {
    height: 25rem;
    right: 15%;
  }
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:before,
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:after {
  content: '';
  height: 63%;
  width: 5vw;
  background-color: #fff;
  position: absolute;
  right: 0;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:before,
  .eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:after {
    width: 5rem;
    height: 66%;
  }
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:before {
  top: 10%;
  transform-origin: center top;
  transform: rotate(-45deg);
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:after {
  bottom: 10%;
  transform-origin: center bottom;
  transform: rotate(45deg);
}
@keyframes eurojackpot-create-new-row-blink-animation {
  0% {
    background-color: transparent;
  }
  12.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  25% {
    background-color: transparent;
  }
  37.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  50% {
    background-color: transparent;
  }
  62.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  75% {
    background-color: transparent;
  }
  87.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  100% {
    background-color: transparent;
  }
}
.eurojackpot-classic-game .row-number-2.new-row ~ .create-new-row-button.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 6px 0 0 6px;
  animation: eurojackpot-create-new-row-blink-animation 1.5s 3.5s linear forwards;
  z-index: -1;
}
.eurojackpot-classic-game .rows-list-box {
  width: 70vw;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 16vw;
  overflow: visible;
  background-color: #0a0a0a;
  transform: translateX(-100%);
  transition: transform .25s linear;
  will-change: transform;
  z-index: 2;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box {
    width: 30rem;
    bottom: 7rem;
  }
}
.eurojackpot-classic-game .rows-list-box.active {
  transition: transform .15s linear;
  transform: translateX(0);
}
.eurojackpot-classic-game .rows-list-box.active .toggle-button .icon-list {
  opacity: 0;
}
.eurojackpot-classic-game .rows-list-box.active .toggle-button .icon-arrow {
  opacity: 1;
}
.eurojackpot-classic-game .rows-list-box .toggle-button {
  width: 10.5vw;
  height: 10.5vw;
  background-color: #eeeeee;
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  transform: translateY(-30vw) translateX(10.5vw);
  transition: transform 0.15s, background-color 0.25s;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .toggle-button {
    width: 4.5vw;
    transform: translateY(-30vw) translateX(4.5vw);
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .toggle-button {
    width: 4.2rem;
    height: 7rem;
    transform: translateY(-16rem) translateX(4.2rem);
    cursor: pointer;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .toggle-button:hover .icon-list,
  .eurojackpot-classic-game .rows-list-box .toggle-button:hover .icon-arrow {
    transform: translateY(-50%) translateX(-50%) scale(1.3);
  }
}
.eurojackpot-classic-game .rows-list-box .toggle-button .icon-list,
.eurojackpot-classic-game .rows-list-box .toggle-button .icon-arrow {
  width: 3.8vw;
  height: 4vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
  transition: transform .25s, opacity .25s;
  fill: #0a0a0a;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .toggle-button .icon-list,
  .eurojackpot-classic-game .rows-list-box .toggle-button .icon-arrow {
    width: 2vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .toggle-button .icon-list,
  .eurojackpot-classic-game .rows-list-box .toggle-button .icon-arrow {
    width: 1.7rem;
    height: 1.2rem;
  }
}
.eurojackpot-classic-game .rows-list-box .toggle-button .icon-arrow {
  opacity: 0;
}
@keyframes eurojackpot-button-count-wrapper-animation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.eurojackpot-classic-game .rows-list-box .toggle-button .button-count {
  font-size: 3vw;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 0 1.5vw;
  user-select: none;
  border-radius: 3vw;
  background-color: #0a0a0a;
  position: absolute;
  top: -10%;
  left: 50%;
  opacity: 0;
  animation: eurojackpot-button-count-wrapper-animation 0.25s linear forwards;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .toggle-button .button-count {
    min-width: 1.5rem;
    font-size: 1.4rem;
    padding: .05rem .5rem .1rem;
    border-radius: 1rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.eurojackpot-classic-game .rows-list-box .container .items-box {
  font-size: 0;
  position: relative;
}
.eurojackpot-classic-game .rows-list-box .container .items-box .rows-list-header {
  font-size: 3.5vw;
  line-height: 2.6;
  color: #fff;
  text-align: center;
  background-color: #0a0a0a;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .rows-list-header {
    font-size: 1.6rem;
    line-height: 2.8;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box {
    font-size: 2.6vw;
  }
}
@keyframes eurojackpot-information-text-wrapper-fade-in-animation {
  0% {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
  }
  100% {
    max-height: 25rem;
    opacity: 1;
    margin-bottom: 2rem;
  }
}
@keyframes eurojackpot-information-text-wrapper-fade-out-animation {
  0% {
    max-height: 25rem;
    opacity: 1;
    margin-bottom: 2rem;
  }
  100% {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .information-text-wrapper {
  width: 80%;
  max-height: 0;
  margin: auto;
  text-align: center;
  margin-bottom: 0;
  overflow: hidden;
  animation: eurojackpot-information-text-wrapper-fade-in-animation 0.25s linear forwards;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .information-text-wrapper {
    margin-bottom: 2rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .information-text-wrapper.remove {
  animation: eurojackpot-information-text-wrapper-fade-out-animation 0.25s linear forwards;
}
.eurojackpot-classic-game .rows-list-box .container .items-box .information-text-wrapper .text-row {
  color: #fff;
  font-size: 6vw;
  font-weight: 600;
  margin-top: 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .information-text-wrapper .text-row {
    font-size: 2.6rem;
    margin-top: 3rem;
  }
}
@keyframes eurojackpot-rows-list-in-animation {
  0% {
    transform: scaleY(0);
    opacity: 0;
    margin-bottom: -4rem;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
    margin-bottom: 0;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item {
  width: 100%;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  padding-left: 2.666vw;
  box-sizing: border-box;
  transform-origin: top;
  transition: background-color 0.25s linear;
  animation: eurojackpot-rows-list-in-animation 0.15s linear forwards;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item {
    cursor: pointer;
    padding-left: 1.45rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item {
    height: auto;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item:nth-child(odd) {
  background-color: #1f1f1f;
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item:nth-child(even) {
  background-color: #2E3535;
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item.active-row {
  background-color: #7b7b7b;
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item:hover {
  transition: background-color 0.15s linear;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item:hover {
    background-color: #a9a9a9;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner {
  width: 100%;
  margin: auto;
  position: relative;
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .headline {
  width: 30%;
  font-size: 3.5vw;
  line-height: 2.8;
  color: #fff;
  position: relative;
  float: left;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .headline {
    width: 24%;
    font-size: 1.2rem;
    line-height: 3.3;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container {
  width: 70%;
  font-size: 3.5vw;
  line-height: 2.8;
  color: #fff;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container {
    width: 76%;
    font-size: 1.4rem;
    line-height: 2.8;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .plus-separator {
  width: 1.867vw;
  height: 1.867vw;
  fill: #fff;
  position: absolute;
  top: 3.9vw;
  left: 30.4vw;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .plus-separator {
    width: 1rem;
    height: 1rem;
    top: 1.5rem;
    left: 13rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .plus-separator {
    position: static;
    margin: 0 2vw;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .star-numbers {
  position: absolute;
  left: 34vw;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .star-numbers {
    left: 15rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .star-numbers {
    position: static;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button {
  width: 8vw;
  height: 8.3vw;
  position: absolute;
  right: 0;
  top: .7vw;
  transition: transform .15s linear;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button {
    width: 1rem;
    height: 1rem;
    padding: 1.45rem;
    top: 0;
    cursor: pointer;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button:hover {
    transform: scale(1.4);
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button {
    top: 1vw;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button .icon {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  fill: #fff;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button .icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row {
  box-sizing: border-box;
  padding-left: 5vw;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row {
    padding-left: 3rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
  content: '!';
  width: 3.734vw;
  height: 3.734vw;
  display: inline-block;
  font-size: 2.7vw;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #d01212;
  margin-right: 0.934vw;
  position: absolute;
  top: 2.8vw;
  left: -4vw;
  line-height: 1.4;
  text-indent: 1.5vw;
  color: #fff;
  cursor: pointer;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.4rem;
    margin-right: .7rem;
    line-height: 1.6;
    text-indent: .95rem;
    top: .8rem;
    left: -2.7rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
    top: 3.3vw;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box {
  background-color: #e9e9e9;
  margin-top: -6vw;
  margin-left: -1.333vw;
  padding: 4.666vw 4vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: absolute;
  -webkit-transform: translateY(-110%);
  -moz-transform: translateY(-110%);
  -ms-transform: translateY(-110%);
  -o-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  -o-transition: -o-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  transition: transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box {
    margin-top: -3rem;
    margin-left: -1rem;
    padding: 1.5rem 2rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box:after {
  content: '';
  border-left: 1.333vw solid transparent;
  border-right: 1.333vw solid transparent;
  border-top: 1.333vw solid #e9e9e9;
  position: absolute;
  bottom: -1.333vw;
  left: 2vw;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box:after {
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-top: .6rem solid #e9e9e9;
    bottom: -0.6rem;
    left: 1.3rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box .error-headline {
  font-size: 3.2vw;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box .error-headline {
    font-size: 1.6rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box .error-subheadline {
  font-size: 3.2vw;
  font-weight: 300;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .items-inner-box .error-message-box .error-subheadline {
    font-size: 1.5rem;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .quick-button-wrapper {
  position: relative;
  padding: 6vw 0;
  text-align: center;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .quick-button-wrapper {
    height: 4rem;
    padding: 2rem 0;
  }
}
@keyframes eurojackpot-quick-button-fade-in-animation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes eurojackpot-quick-button-fade-out-animation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(120%);
    opacity: 0;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button {
  width: 90%;
  font-size: 3.734vw;
  font-weight: 600;
  color: #171717;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  padding: 3vw;
  margin: auto;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  transition: background-color 0.15s linear;
  animation: eurojackpot-quick-button-fade-in-animation 0.25s linear forwards;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button {
    font-size: 1.6rem;
    padding: .9rem;
    cursor: pointer;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button:hover {
    background-color: #cacaca;
  }
}
.eurojackpot-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button.inactive {
  animation: eurojackpot-quick-button-fade-out-animation 0.25s linear forwards;
}
.eurojackpot-classic-game .rows-list-box .container .button {
  width: 10.5vw;
  height: 10.5vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  overflow: hidden;
  -webkit-transform: translateY(-30vw) translateX(-10.5vw);
  -moz-transform: translateY(-30vw) translateX(-10.5vw);
  -ms-transform: translateY(-30vw) translateX(-10.5vw);
  -o-transform: translateY(-30vw) translateX(-10.5vw);
  transform: translateY(-30vw) translateX(-10.5vw);
  -webkit-transition: -webkit-transform 0.15s, background-color 0.1s;
  -moz-transition: -moz-transform 0.15s, background-color 0.1s;
  -ms-transition: -ms-transform 0.15s, background-color 0.1s;
  -o-transition: -o-transform 0.15s, background-color 0.1s;
  transition: transform 0.15s, background-color 0.1s;
  z-index: 1;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .button {
    width: 4.2rem;
    height: 7rem;
    -webkit-transform: translateY(-16rem) translateX(-4.2vw);
    -moz-transform: translateY(-16rem) translateX(-4.2vw);
    -ms-transform: translateY(-16rem) translateX(-4.2vw);
    -o-transform: translateY(-16rem) translateX(-4.2vw);
    transform: translateY(-16rem) translateX(-4.2vw);
    cursor: pointer;
  }
}
.eurojackpot-classic-game .rows-list-box .container .button .icon-list {
  width: 3.8vw;
  height: 4vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.6vw);
  -moz-transform: translateY(-50%) translateX(-1.6vw);
  -ms-transform: translateY(-50%) translateX(-1.6vw);
  -o-transform: translateY(-50%) translateX(-1.6vw);
  transform: translateY(-50%) translateX(-1.6vw);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .button .icon-list {
    width: 1.5rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.7rem);
    -moz-transform: translateY(-50%) translateX(-0.7rem);
    -ms-transform: translateY(-50%) translateX(-0.7rem);
    -o-transform: translateY(-50%) translateX(-0.7rem);
    transform: translateY(-50%) translateX(-0.7rem);
  }
}
.eurojackpot-classic-game .rows-list-box .container .button .icon-arrow-left {
  width: 3.8vw;
  height: 3.47vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(7vw);
  -moz-transform: translateY(-50%) translateX(7vw);
  -ms-transform: translateY(-50%) translateX(7vw);
  -o-transform: translateY(-50%) translateX(7vw);
  transform: translateY(-50%) translateX(7vw);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .button .icon-arrow-left {
    width: 1.5rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.7rem);
    -moz-transform: translateY(-50%) translateX(-0.7rem);
    -ms-transform: translateY(-50%) translateX(-0.7rem);
    -o-transform: translateY(-50%) translateX(-0.7rem);
    transform: translateY(-50%) translateX(-0.7rem);
  }
}
.eurojackpot-classic-game .rows-list-box .container .button.active {
  -webkit-transform: translateY(-30vw) translateX(-1.5vw);
  -moz-transform: translateY(-30vw) translateX(-1.5vw);
  -ms-transform: translateY(-30vw) translateX(-1.5vw);
  -o-transform: translateY(-30vw) translateX(-1.5vw);
  transform: translateY(-30vw) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container .button.active {
    -webkit-transform: translateY(-16rem) translateX(0);
    -moz-transform: translateY(-16rem) translateX(0);
    -ms-transform: translateY(-16rem) translateX(0);
    -o-transform: translateY(-16rem) translateX(0);
    transform: translateY(-16rem) translateX(0);
  }
}
.eurojackpot-classic-game .rows-list-box .container.active .container {
  transform: translate3d(0, 0, 0);
}
.eurojackpot-classic-game .rows-list-box .container.active .container .informations-box,
.eurojackpot-classic-game .rows-list-box .container.active .container > .headline {
  background-color: #ffffff;
}
.eurojackpot-classic-game .rows-list-box .container.active .container.single-row {
  transform: translate3d(0, 30vw, 0);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container.active .container.single-row {
    transform: translate3d(0, 8rem, 0);
  }
}
.eurojackpot-classic-game .rows-list-box .container.active .button {
  background-color: #ffffff;
  -webkit-transform: translateY(-30vw) translateX(85.6vw);
  -moz-transform: translateY(-30vw) translateX(85.6vw);
  -ms-transform: translateY(-30vw) translateX(85.6vw);
  -o-transform: translateY(-30vw) translateX(85.6vw);
  transform: translateY(-30vw) translateX(85.6vw);
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container.active .button {
    -webkit-transform: translateY(-16rem) translateX(48.8rem);
    -moz-transform: translateY(-16rem) translateX(48.8rem);
    -ms-transform: translateY(-16rem) translateX(48.8rem);
    -o-transform: translateY(-16rem) translateX(48.8rem);
    transform: translateY(-16rem) translateX(48.8rem);
  }
}
.eurojackpot-classic-game .rows-list-box .container.active .button .icon-list {
  -webkit-transform: translateY(-50%) translateX(-11vw);
  -moz-transform: translateY(-50%) translateX(-11vw);
  -ms-transform: translateY(-50%) translateX(-11vw);
  -o-transform: translateY(-50%) translateX(-11vw);
  transform: translateY(-50%) translateX(-11vw);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
.eurojackpot-classic-game .rows-list-box .container.active .button .icon-arrow-left {
  -webkit-transform: translateY(-50%) translateX(-1.6vw);
  -moz-transform: translateY(-50%) translateX(-1.6vw);
  -ms-transform: translateY(-50%) translateX(-1.6vw);
  -o-transform: translateY(-50%) translateX(-1.6vw);
  transform: translateY(-50%) translateX(-1.6vw);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .rows-list-box .container.active .button .icon-arrow-left {
    -webkit-transform: translateY(-50%) translateX(-0.3rem);
    -moz-transform: translateY(-50%) translateX(-0.3rem);
    -ms-transform: translateY(-50%) translateX(-0.3rem);
    -o-transform: translateY(-50%) translateX(-0.3rem);
    transform: translateY(-50%) translateX(-0.3rem);
  }
}
