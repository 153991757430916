@import (reference) "../../Mixins/_mixins.less";

.themeColorsVars() {
  --multiclient-campaign-primary-hsl-values: ~'var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1%)';
  --multiclient-campaign-primary-color-100: ~'hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1.6%))';
  --multiclient-campaign-primary-color-300: ~'hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1.2%))';
  --multiclient-campaign-primary-color-500: ~'hsl(var(--multiclient-campaign-primary-hsl-values))';
  --multiclient-campaign-primary-color-900: ~'hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 0.6%))';

  --multiclient-campaign-secondary-hsl-values: ~'var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1%)';
  --multiclient-campaign-secondary-color-100: ~'hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1.6%))';
  --multiclient-campaign-secondary-color-300: ~'hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1.2%))';
  --multiclient-campaign-secondary-color-500: ~'hsl(var(--multiclient-campaign-secondary-hsl-values))';
  --multiclient-campaign-secondary-color-900: ~'hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 0.6%))';

  --multiclient-campaign-tertiary-hsl-values: ~'var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1%)';
  --multiclient-campaign-tertiary-color-100: ~'hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1.6%))';
  --multiclient-campaign-tertiary-color-300: ~'hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1.2%))';
  --multiclient-campaign-tertiary-color-500: ~'hsl(var(--multiclient-campaign-tertiary-hsl-values))';
  --multiclient-campaign-tertiary-color-900: ~'hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 0.6%))';
}

.rotarySelector() {
  .selector-numbers {
    .number {
      color: var(--multiclient-campaign-primary-color-500);
    }
  }

  .selector-foreground {
    box-shadow: inset 0px 0px 5em var(--multiclient-campaign-primary-color-300);

    .navigation-button {
      .icon {
        color: var(--multiclient-campaign-primary-color-500);
      }
    }
  }

  .selector-background {
    background-color: var(--multiclient-campaign-primary-color-500);
  }

  .selected-number-wrapper {
    .number {
      color: @ds-white;
    }
  }

  .multi-client__client-game-description .client-game-description__inner {
    color: var(--multiclient-campaign-primary-color-500);
  }
}

.rotarySelectorWithSpecialGraphics() {
  .selector-numbers {
    .number-wrapper {
      width: 28.366em;
      margin-left: -14.183em;
    }
    .number {
      color: var(--multiclient-campaign-primary-color-500);
      font-size: 16em;
      line-height: 1.7;
    }
  }

  .selected-number-wrapper {
    width: 28.366em;

    .selector-numbers {
      left: -35.3vw;
      @media @sm {
        left: -56em;
      }

      .number {
        color: @ds-white;
      }
    }
  }

  .selector-background,
  .selector-foreground {
    color: var(--multiclient-campaign-primary-color-500);
    border-radius: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  
  .selector-background {
    width: 29.092em;
    height: 40.91em;
    top: 8em;
    background-color: var(--multiclient-campaign-primary-color-500);
    -webkit-mask-image: url('/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons/campaign_rotary_selector_back.svg');
    mask-image: url('/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons/campaign_rotary_selector_back.svg');
    -webkit-mask-mode: alpha;
    mask-mode: alpha;
  }
  .selector-foreground {
    width: 29.092em;
    height: 40.91em;
    box-shadow: none;
    top: 8em;

    .navigation-button {
      top: 28%;
    }

    .label {
      font-size: 2.9em;
      top: 6.9em;
    }

    .multi-client__client-game-description {
      height: 10.8em;
      transform: translateY(0);
      display: inline-block;
      padding: 0 0.4em;
      line-height: 1.2;
      font-size: inherit;

      .client-game-description__inner {
        color: white;
        display: inline;
        font-size: 2.6em;
      }

      .client-game-description__question-button {
        width: 3em;
        height: 3em;
        color: white;
        display: inline-block;
        margin-left: 1em;

        .icon {
          margin-top: -0.5em;
          position: relative;
          top: 0.6em;
        }
      }
    }
  }

  .multi-client-rotary-selector__outer + .multi-client__client-game-description {
    font-size: 5px;
    width: 18em;
    margin-top: -14em;
    margin-bottom: 10em;
    @media @sm {
      width: 24em;
      margin-top: -18.8em;
    }

    .client-game-description__inner {
      font-size: 1.5em;
      line-height: 1.1;
      font-weight: 700;
      padding-bottom: 0.4rem;
      color: @ds-white;
      @media @sm {
        font-size:2em;
      }

      span {
        font-size: 1.8em;
        display: inline;
        padding-bottom: 0.2rem;
        white-space:pre;

        @media @sm {
          font-size: 2em;
          padding-bottom: 0.4rem;
        }

        &::after {
          content: '\A';
          margin-bottom: -0.8rem;
        }
      }
    }
  }
}

.pickYourself() {
  .lotto-classic-game,
  .lotto-system-game,
  .lotto-lucky-game,
  .lotto-winning-numbers {
    .number-picker-container {
      .cell {
        .front-face {
          background-color: var(--multiclient-campaign-primary-color-100);
          color: var(--multiclient-campaign-primary-color-500);
        }
        .back-face {
          background-color: var(--multiclient-campaign-primary-color-500);
          color: @ds-white;
        }

        &.flip-animation-in-progress {
          @keyframes multiclient-lotto-flip-face-animation {
            0% {
              color: var(--multiclient-campaign-primary-color-500);
              background-color: var(--multiclient-campaign-primary-color-100);
            }

            35% {
              color: var(--multiclient-campaign-primary-color-500);
              background-color: var(--multiclient-campaign-primary-color-100);
            }

            65% {
              color: var(--multiclient-campaign-primary-color-500);
              background-color: var(--multiclient-campaign-primary-color-100);
            }

            80% {
              color: @ds-white;
              background-color: var(--multiclient-campaign-primary-color-500);
            }

            100% {
              color: @ds-white;
              background-color: var(--multiclient-campaign-primary-color-500);
            }
          }

          @keyframes multiclient-lotto-reverse-flip-face-animation {
            0% {
              color: @ds-white;
              background-color: var(--multiclient-campaign-primary-color-500);
            }

            35% {
              color: @ds-white;
              background-color: var(--multiclient-campaign-primary-color-500);
            }

            65% {
              color: @ds-white;
              background-color: var(--multiclient-campaign-primary-color-500);
            }

            80% {
              color: var(--multiclient-campaign-primary-color-500);
              background-color: var(--multiclient-campaign-primary-color-100);
            }

            100% {
              color: var(--multiclient-campaign-primary-color-500);
              background-color: var(--multiclient-campaign-primary-color-100);
            }
          }
          &.select-number-animation {
            animation-name: multiclient-lotto-flip-face-animation;
          }

          &.deselect-number-animation {
            animation-name: multiclient-lotto-reverse-flip-face-animation;
          }
        }
      }
    }
    .lotto-section {
      background-color: white;

      .row-header {
        color: var(--multiclient-campaign-primary-color-500);
        
        .header-rules-text {
          color: var(--multiclient-campaign-primary-color-500);
        }

        .right-side .row-delete-button .icon {
          fill: var(--multiclient-campaign-primary-color-500);
        }
      }

      .number-picker-footer .buttons-container .button {
        background-color: var(--multiclient-campaign-primary-color-500);
        color: @ds-white;
      }
    }
  }

  .lotto-classic-game {
    .rows-list-box {
      background-color: var(--multiclient-campaign-primary-color-500);

      .toggle-button .button-count {
        background-color: var(--multiclient-campaign-primary-color-500);
      }

      .items-box {
        .rows-list-header {
          background-color: var(--multiclient-campaign-primary-color-900);
        }
        .items-inner-box .item .item-inner .numbers {
          color: var(--multiclient-campaign-primary-color-500);
        }
      }
    }
    .lotto-section .row-header {
      border-bottom-color: var(--multiclient-campaign-primary-color-500);
    }
    .number-picker-footer {
      border-top-color: var(--multiclient-campaign-primary-color-500);
    }
  }
}

.luckyAndSystem() {
  .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper {
    .switch-title {
      color: @ds-white;
    }

    .switch-item {
      color: fade(@ds-white, 30%);
      &.active {
        color: @ds-white;
      }
    }
  }

  .lotto-system-game,
  .lotto-lucky-game {
    .lotto-system-game-header {
      background-color: var(--multiclient-campaign-primary-color-500);
      
      .game-navigation-item {
        color: fade(@ds-white, 30%);

        &.active {
          background-color: fade(@ds-white, 30%);
          color: @ds-white;
          .tab-navigation-icon {
            fill: @ds-white;
            color: @ds-white;
          }
        }
      }
    }
    .game-switch-wrapper {
      background-color: var(--multiclient-campaign-primary-color-500);

      .switch-button {
        &.next {
          background: linear-gradient(to right, ~'hsla(var(--multiclient-campaign-primary-hsl-values), 0)' 0%, var(--multiclient-campaign-primary-color-500) 100%);
        }

        &.prev {
          background: linear-gradient(to right, var(--multiclient-campaign-primary-color-500) 0%, ~'hsla(var(--multiclient-campaign-primary-hsl-values), 0)' 100%);
        }
      }
    }
    .lotto-game-tabs-wrapper {
      background-color: var(--multiclient-campaign-primary-color-500);

      .tabs-navigation .tab-navigation-item {
        .tab-navigation-icon {
          fill: fade(@ds-white, 30%);
          color: fade(@ds-white, 30%);
        }

        &.active {
          background-color: fade(@ds-white, 30%);

          .tab-navigation-icon {
            fill: @ds-white;
            color: @ds-white;
          }
        }
      }

      .tabs-container .accordions-container {
        .accordions-header,
        .print-button {
          background-color: var(--multiclient-campaign-primary-color-500);
        }
      }
    }

    .lotto-content-wrapper .rows-container {
      &:before {
        background-color: var(--multiclient-campaign-primary-color-500);
      }
      .row-container .row-header:before {
        background-color: var(--multiclient-campaign-primary-color-500);
      }
    }
  }
}

.flowSteps() {
  .multi-client__flow-wrapper {
    .multi-client-draw-options .check-button {
      background-color: ~'hsla(var(--multiclient-campaign-primary-hsl-values), 0.1)';
      color: var(--multiclient-campaign-primary-color-300);

      &::before {
        background-color: var(--multiclient-campaign-primary-color-500);
      }

      .check-button__dot {
        border-color: ~'hsla(var(--multiclient-campaign-primary-hsl-values), 0.3)';

        &::after {
          background-color: var(--multiclient-campaign-primary-color-500);
        }
      }

      &.check-button--selected {
        color: white;

        .check-button__dot {
          border-color: var(--multiclient-campaign-primary-color-100);
        }
      }
    }

    .confirm-page__collapsible-top-row-icon,
    .confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign,
    .confirm-page__collapsible-top-row-highlight-text,
    .confirm-page__extra-campaigns .confirm-page__collapsible-top-row-text {
      color: var(--multiclient-campaign-primary-color-500);
    }

    .receipt-step .receipt-step__countdown .countdown {
      background-color: var(--multiclient-campaign-primary-color-500);
    }
  }
}

.multi-client-wrapper__custom-campaign {
  .themeColorsVars();
}

.multi-client-wrapper__custom-campaign--theming-on {
  .multi-client-game-selector {
    .game-selector-button.active {
      background-color: var(--multiclient-campaign-primary-color-500);
    }
  }

  .multi-client__client-wrapper {
    .rotarySelector();
    .pickYourself();
    .luckyAndSystem();
    .flowSteps();
  }
}

.multi-client-wrapper__custom-campaign--custom-graphics-on {
  .multi-client__client-wrapper {
    .rotarySelectorWithSpecialGraphics();
  }
}

.campaign-overlay__custom-campaign {
  .themeColorsVars();

  .panel {
    background-color: var(--multiclient-campaign-tertiary-color-500, var(--multiclient-campaign-primary-color-500));
    color: white;

    .generic-overlay__close-button .icon {
      fill: white;
    }

    .icon-wrapper img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .generic-overlay__cta-button {
    color: white;

    &::after {
      background-color: var(--multiclient-campaign-secondary-color-500);
      border-color: white;
    }
  }
}
