﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@cell-width--tablet: calc(~'16.666% - 16px');
@cell-height--tablet: 8vw;

.eurojackpot-classic-game,
.eurojackpot-system-game,
.eurojackpot-lucky-game {

  .statistics-component-for-game-client-wrapper {
    background-color: @eurojackpot-carbon;

    .statistics-component-outer-wrapper {

      .statistics-component-toggle-button-wrapper {
        .toggle-button {
          .numbers-count-wrapper {
            background-color: @eurojackpot-gray;
          }
        }
      }

      .statistics-component-inner-wrapper {

        .numbers-from-statistics-wrapper {
          .selected-number-box {
            background-color: @eurojackpot-gray;

            .number-cell {
              width: 8vw;
              height: 8vw;
              font-size: 4vw;
              line-height: 2.1;
              margin: 1vw;
              color: @eurojackpot-black;
              background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#square-shape-dark_grey_use);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              box-sizing: border-box;

              @media (min-width: @eurojackpot-desktop-width) {
                width: 4rem;
                height: 4rem;
                font-size: 1.6rem;
                line-height: 1.4;
                margin: .5rem;
                padding-top: .9rem;
              }
            }

            &.special {
              .number-cell {
                line-height: 2.3;
                background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-grey.svg);

                @media (min-width: @eurojackpot-desktop-width) {
                  line-height: 1.3;
                  padding-top: 1.1rem;
                }
              }
            }

            &.selected {
              .number-cell {
                color: @eurojackpot-white;
                background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-gold_with-gradient.svg);
              }

              &.special {
                .number-cell {
                  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.eurojackpot-classic-game {
  .statistics-component-for-game-client-wrapper {
    @media (min-width: @eurojackpot-desktop-width) {
      margin-bottom: 2.3rem;
    }
  }
}
