@import url("../Mixins/_mixins.less");

@media print {
  .print-system-row-wrapper {
    width:30%;
    padding-top:10mm;
    margin-right:5%;
    box-sizing:border-box;
    display:inline-block;
    page-break-inside: avoid;
    &:nth-child(3n) {
      margin-right:0;
    }

    .print-system-row-headline {
      font-size:3mm;
      color:@lotto-red;
      font-weight:500;
      border-bottom:.1mm solid @numbergames-lightgray;
      padding-bottom:2.5mm;
      box-sizing:border-box;
      margin-right:5%;
      .region-eurojackpot & {
        color:@eurojackpot-carbon;
      }
      .region-vikinglotto & {
        color:@vikinglotto-blue;
      }
    }

    .print-system-row-body {
      padding-top:2.5mm;
      line-height:2mm;
      .print-system-row-cell {
        width:10%;
        display:inline-block;
        font-size:2.5mm;
        text-align:right;
        color:@numbergames-mediumgray;
        padding-right:5%;
        box-sizing:border-box;
      }
    }
  }

  .print-system-garanti-section-wrapper {
    width:60%;
    padding-top:5mm;
    box-sizing:border-box;

    .print-system-garanti-section-header {
      font-size:3.5mm;
      font-weight:bold;
      color:@lotto-red;
      text-transform:uppercase;
      padding:1.2mm 4mm;
      border-radius:1.5mm;
      box-sizing:border-box;
      background-color:@lotto-whitesmoke;
      -webkit-print-color-adjust:exact;
      .region-eurojackpot & {
        color:@eurojackpot-carbon;
      }
      .region-vikinglotto & {
        color:@vikinglotto-blue;
      }
    }

    .print-system-garanti-section-column {
      width:20%;
      line-height:2mm;
      color:@numbergames-mediumgray;
      display:inline-block;
      text-align:center;
      .print-system-garanti-section-cell {
        display:block;
        font-size:3mm;
        padding:.8mm 0;

        &:first-child {
          padding:2.5mm 0;
          font-weight:500;
          border-bottom:.1mm solid @lotto-whitesmoke;
          margin-bottom:2mm;
          box-sizing:border-box;
        }
      }
    }
  }

  .print-system-garanti-table {
    width:100%;
    table-layout:fixed;
    border-collapse:separate;
    border-spacing:0;
    border:.1mm solid @numbergames-lightgray;
    line-height:3.6mm;
    margin-top:10mm;
    .region-eurojackpot & {
      table-layout:auto;
    }

    .print-system-garanti-table-head {
      tr {
        font-size:3mm;
        color:@lotto-red;
        .region-eurojackpot & {
          color:@eurojackpot-carbon;
        }
        .region-vikinglotto & {
          color:@vikinglotto-blue;
        }
        th {
          padding:2mm 0 2mm 4mm;
          border-left:.1mm solid @numbergames-lightgray;
          box-sizing:border-box;
          text-align:left;
          &:first-child {
            border-left:none;
          }
        }
      }
    }

    .print-system-garanti-table-body {
      tr {
        th, td {
          font-size:2.5mm;
          color:@numbergames-mediumgray;
          padding:2mm 1mm;
          border-top:.1mm solid @numbergames-lightgray;
          border-left:.1mm solid @numbergames-lightgray;
          text-align:center;
          box-sizing:border-box;
          background-color:@lotto-whitesmoke;
          -webkit-print-color-adjust:exact;
          &:first-child {
            border-left:none;
          }
        }
						
      }
    }
  }
}
