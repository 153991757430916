/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.winning-numbers__component {
  background-color: white;
  padding: 4rem 2rem;
}
.winning-numbers {
  max-width: 88rem;
  display: grid;
  justify-content: center;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "select" "result";
}
@media screen and (min-width: 1024px) {
  .winning-numbers {
    margin: 7rem auto;
    grid-template-columns: 31rem 1fr;
    column-gap: 10rem;
    grid-template-areas: "select result";
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers {
    max-width: 110rem;
    column-gap: 14rem;
  }
}
.winning-numbers__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}
.winning-numbers__result {
  grid-area: result;
}
.winning-numbers__select {
  grid-area: select;
}
.winning-numbers__header {
  max-width: 60rem;
}
@media screen and (min-width: 1024px) {
  .winning-numbers__header {
    min-height: 20rem;
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers__header {
    min-height: 24rem;
  }
}
.winning-numbers__title {
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-align: center;
  margin-bottom: 1.3rem;
}
@media screen and (min-width: 1024px) {
  .winning-numbers__title {
    text-align: left;
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers__title {
    font-size: 4rem;
  }
}
.winning-numbers__subtitle {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-align: center;
  margin-bottom: 3.5rem;
}
@media screen and (min-width: 1024px) {
  .winning-numbers__subtitle {
    text-align: left;
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers__subtitle {
    font-size: 1.6rem;
  }
}
.winning-numbers__table-description {
  margin-top: 2.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
}
.winning-numbers__cta-wrapper {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
@media screen and (min-width: 1440px) {
  .winning-numbers__cta-wrapper {
    padding: 4rem 0;
  }
}
.winning-numbers__cta {
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: #d4d4d4;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  color: #000000;
  transition: color 200ms ease-out;
  height: 5.6rem;
  min-width: 24rem;
  display: inline-flex;
}
.winning-numbers__cta::after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.winning-numbers__cta::after {
  background-color: #FEB700;
  border-color: #FEB700;
}
.winning-numbers__cta:not(.btn--disabled):not(.btn--loading):active::after {
  background-color: #f5da00;
  border-color: #f5da00;
}
.winning-numbers__cta.btn--disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.winning-numbers__cta.btn--loading {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.winning-numbers__cta.btn--loading::before {
  content: "";
  border-radius: 2.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 0 0 #ffce51;
  animation: btn-pulse-inner-loading-animation 1.3s infinite;
}
.winning-numbers__cta.btn--loading::after {
  animation: btn-pulse-loading-animation 1.3s infinite;
}
.winning-numbers__cta:not(.btn--disabled):hover::after {
  transform: scale(1.03);
}
