@import (reference) "../../Mixins/_mixins.less";

.multi-client-game-selector__wrapper {
  width: 100%;
  position: absolute;
  top: 3.4rem;
  left: 0;
  padding: 0 0 1rem;
  box-sizing: border-box;
  overflow-x: auto;
  pointer-events: all;
  text-align: center;
  @media @sm {
    width: auto;
    position: relative;
    top: initial;
    left: initial;
    padding: 0;
  }
}
.multi-client-game-selector {
  display: inline-flex;
  border-radius: 2.8rem;
  border: .1rem solid @ds-gray-100;
  margin: 0 1.5rem;

  .game-selector-button {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2rem;
    text-transform: uppercase;
    padding: .9rem 2rem;
    color: @ds-black;
    border-radius: 2.8rem;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    @media @sm {
      font-size: 1.4rem;
      line-height: 1.4rem;
      padding: .9rem 3rem;
    }

    &.active {
      color: @ds-white;
      .multi-client-wrapper__eurojackpot & {
        background-color: @eurojackpot-gold-medium;
      }
      .multi-client-wrapper__lotto & {
        background-color: @lotto-red;
      }
      .multi-client-wrapper__vikinglotto & {
        background-color: @vikinglotto-blue;
      }
      .multi-client-wrapper__campaign-Sommerlotto & {
        background-color: @summer-lotto-orange;
      }
    }
  }
}
