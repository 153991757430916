.btn-with-x-padding {
  padding-left: 2rem;
}
@keyframes btn-pulse-inner-loading-animation {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  95%,
  100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0);
  }
}
@keyframes btn-pulse-loading-animation {
  38% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  65%,
  100% {
    transform: scale(1);
  }
}
.generic-overlay {
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: generic-overlay-background-animation 0.3s forwards linear;
}
@keyframes generic-overlay-background-animation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@keyframes generic-overlay-panel-animation {
  0% {
    transform: translateY(-50%) scale(0.85);
    filter: blur(0.3rem);
    opacity: 0;
  }
  100% {
    transform: translateY(-50%) scale(1);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes generic-overlay-panel-remove-animation {
  0% {
    transform: translateY(-50%) scale(1);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%) scale(0.85);
    filter: blur(0.3rem);
    opacity: 0;
  }
}
.generic-overlay .panel {
  max-height: 86vh;
  max-width: 80%;
  width: 65rem;
  background: white;
  padding: 4rem;
  border-radius: 6px;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  overflow: auto;
  transform: translateY(-50%);
  animation: generic-overlay-panel-animation 0.3s forwards linear;
}
.generic-overlay .panel .icon-wrapper {
  height: 16rem;
  margin-bottom: 3rem;
}
.generic-overlay .panel .image-caption {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
}
.generic-overlay .panel .headline {
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin-top: 0;
}
.generic-overlay .panel .subheading {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
}
.generic-overlay .panel .text {
  max-width: 100%;
  width: 60rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1.5rem auto 2.5rem;
  overflow: hidden;
  white-space: break-spaces;
}
.generic-overlay .panel .button {
  margin: auto;
}
.generic-overlay.generic-overlay--is-shown .panel {
  animation: unset;
}
.generic-overlay.remove-overlay {
  animation: generic-overlay-background-animation 0.15s reverse linear;
}
.generic-overlay.remove-overlay .panel {
  animation: generic-overlay-panel-remove-animation 0.15s forwards linear;
}
.generic-overlay .buttons {
  display: flex;
}
.generic-overlay .generic-overlay__close-button {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
  transition: transform .15s linear, filter .15s linear;
  will-change: transform;
  z-index: 1;
}
.generic-overlay .generic-overlay__close-button .icon {
  width: 30%;
  height: 30%;
  transition: transform .15s linear;
  will-change: transform;
}
.generic-overlay .generic-overlay__close-button:hover {
  transform: scale(1.1);
}
.generic-overlay .generic-overlay__close-button:hover .icon {
  transform: scale(1.3);
}
.generic-overlay .generic-overlay__cta-button {
  max-width: 24rem;
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: #d4d4d4;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  color: #000000;
  height: 5.6rem;
  min-width: 18rem;
  padding-left: 2rem;
  display: flex;
  padding: 0;
  margin: auto;
}
.generic-overlay .generic-overlay__cta-button::after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.generic-overlay .generic-overlay__cta-button::after {
  background-color: white;
  border-color: #000000;
}
@media screen and (min-width: 375px) {
  .generic-overlay .generic-overlay__cta-button {
    min-width: 21.4rem;
  }
}
.generic-overlay .generic-overlay__cta-button:not(.btn--disabled):hover::after {
  transform: scale(1.03);
}
.generic-overlay .generic-overlay__cta-button.primary-button {
  color: #000000;
  transition: color 200ms ease-out;
}
.generic-overlay .generic-overlay__cta-button.primary-button::after {
  background-color: #FEB700;
  border-color: #FEB700;
}
.generic-overlay .generic-overlay__cta-button.primary-button:not(.btn--disabled):not(.btn--loading):active::after {
  background-color: #f5da00;
  border-color: #f5da00;
}
.generic-overlay .generic-overlay__cta-button.primary-button.btn--disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.generic-overlay .generic-overlay__cta-button.primary-button.btn--loading {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.generic-overlay .generic-overlay__cta-button.primary-button.btn--loading::before {
  content: "";
  border-radius: 2.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 0 0 #ffce51;
  animation: btn-pulse-inner-loading-animation 1.3s infinite;
}
.generic-overlay .generic-overlay__cta-button.primary-button.btn--loading::after {
  animation: btn-pulse-loading-animation 1.3s infinite;
}
.generic-overlay.summer-campaign .panel {
  background: radial-gradient(64.86% 53.9% at 67.2% 46.1%, #ED911B 0%, #D56429 100%);
  color: white;
}
.generic-overlay.summer-campaign .panel .generic-overlay__close-button .icon {
  fill: white;
}
.generic-overlay.summer-campaign .panel .generic-overlay__cta-button::after {
  border: none;
  background-color: #FEB700;
}
.generic-overlay.christmas-2022-campaign .panel {
  max-width: 80%;
  width: 94rem;
  background: radial-gradient(122.78% 122.08% at 12.14% 5.08%, #B71817 0%, #791105 100%);
  border-radius: 40px;
}
.generic-overlay.christmas-2022-campaign .panel .icon-wrapper {
  height: auto;
}
.generic-overlay.christmas-2022-campaign .panel .icon-wrapper img {
  max-width: 100%;
}
.generic-overlay.christmas-2022-campaign .panel .headline,
.generic-overlay.christmas-2022-campaign .panel .text {
  color: white;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__close-button .icon {
  fill: white;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button {
  width: 23.5rem;
  color: #852825;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 16px;
  border: none;
  border-radius: 30px;
  background: #BA9142;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: color .15s linear, filter .15s linear;
  z-index: 0;
  overflow: hidden;
  min-height: 5.6rem;
  letter-spacing: 0.1rem;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button::before {
  content: '';
  position: absolute;
  top: .2rem;
  right: .2rem;
  bottom: .2rem;
  left: .2rem;
  border-radius: 30px;
  border: 1px solid currentColor;
  z-index: 1;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -2rem;
  bottom: 0;
  right: 0rem;
  background: linear-gradient(234.19deg, #F2C74E -22.41%, #FDF8CF 29.4%, #BA9142 100%);
  z-index: -1;
  transition: transform .15s linear, opacity .15s linear;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button:hover:not(.inactive) {
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.4));
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button:hover:not(.inactive)::after {
  transform: translateX(2rem);
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.inactive {
  background: #791105;
  color: #B71817;
  cursor: default;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.inactive::after {
  opacity: 0;
}
@keyframes lotto-christmas-loader-fade-animation {
  100% {
    max-width: 5rem;
    margin-left: .6rem;
    opacity: 1;
  }
}
@keyframes lotto-christmas-loader-dot-animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button .loader {
  max-width: 0;
  display: inline-block;
  opacity: 0;
  white-space: nowrap;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button .loader .loader__dot {
  width: .6rem;
  height: .6rem;
  margin-left: .2rem;
  border-radius: 50%;
  background-color: #852825;
  display: inline-block;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.loading {
  cursor: default;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.loading .loader {
  animation: lotto-christmas-loader-fade-animation 0.1s linear forwards;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.loading .loader__dot:nth-child(1n) {
  animation: lotto-christmas-loader-dot-animation 0.5s ease-in-out -0.81s infinite;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.loading .loader__dot:nth-child(2n) {
  animation: lotto-christmas-loader-dot-animation 0.5s ease-in-out -0.48s infinite;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.loading .loader__dot:nth-child(3n) {
  animation: lotto-christmas-loader-dot-animation 0.5s ease-in-out -1.15s infinite;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.btn--disabled {
  opacity: 0.5;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.outlined::before {
  background-color: #791105;
}
.generic-overlay.christmas-2022-campaign .panel .generic-overlay__cta-button.outlined span {
  background: linear-gradient(19.13deg, #FAC826 -69%, #FFFACB -2.4%, #F8EEBA 11.61%, #E7D08D 39.41%, #CBA145 78.1%, #C2912E 89.92%, #EDD688 164.09%, #FFFACB 198.9%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  z-index: 1;
}
.generic-overlay.accordion-info-overlay__readmore .icon-wrapper,
.generic-overlay.super-millionaire .icon-wrapper {
  height: auto;
  margin-bottom: 0;
}
.generic-overlay.accordion-info-overlay__readmore .generic-overlay__cta-button::after,
.generic-overlay.super-millionaire .generic-overlay__cta-button::after {
  border: none;
  background-color: #FEB700;
}
.generic-overlay.campaign-overlay__custom-campaign {
  --multiclient-campaign-primary-color-l-remainder: (100 - var(--multiclient-campaign-primary-color-l));
  --multiclient-campaign-primary-hsl-values: var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1%);
  --multiclient-campaign-primary-color-100: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-primary-color-300: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-primary-color-500: hsl(var(--multiclient-campaign-primary-hsl-values));
  --multiclient-campaign-primary-color-900: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) - (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-secondary-color-l-remainder: (100 - var(--multiclient-campaign-secondary-color-l));
  --multiclient-campaign-secondary-hsl-values: var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1%);
  --multiclient-campaign-secondary-color-100: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-secondary-color-300: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-secondary-color-500: hsl(var(--multiclient-campaign-secondary-hsl-values));
  --multiclient-campaign-secondary-color-900: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) - (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-tertiary-color-l-remainder: (100 - var(--multiclient-campaign-tertiary-color-l));
  --multiclient-campaign-tertiary-hsl-values: var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1%);
  --multiclient-campaign-tertiary-color-100: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-tertiary-color-300: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-tertiary-color-500: hsl(var(--multiclient-campaign-tertiary-hsl-values));
  --multiclient-campaign-tertiary-color-900: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) - (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%));
}
.generic-overlay.campaign-overlay__custom-campaign .panel {
  background-color: var(--multiclient-campaign-tertiary-color-500, var(--multiclient-campaign-primary-color-500));
  color: white;
}
.generic-overlay.campaign-overlay__custom-campaign .panel .generic-overlay__close-button .icon {
  fill: white;
}
.generic-overlay.campaign-overlay__custom-campaign .panel .icon-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.generic-overlay.campaign-overlay__custom-campaign .generic-overlay__cta-button {
  color: white;
}
.generic-overlay.campaign-overlay__custom-campaign .generic-overlay__cta-button::after {
  background-color: var(--multiclient-campaign-secondary-color-500);
  border-color: white;
}
@keyframes generic-overlay-active-animation {
  0% {
    filter: blur(0);
  }
  100% {
    filter: blur(0.3rem);
  }
}
.overlay-active {
  animation: generic-overlay-active-animation 0.3s forwards linear;
}
.remove-overlay {
  pointer-events: none;
  animation: generic-overlay-active-animation 0.15s reverse linear;
}
