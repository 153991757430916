@import url("../Mixins/_mixins.less");

.eurojackpot-game-tabs-wrapper {
  .clearfix();
  background-color: @ds-gray-100;
  font-size: 0;

  .tabs-navigation {
    width: 14vw;
    display: inline-block;
    float: left;

    @media (min-width: @eurojackpot-desktop-width) {
      width: 7rem;
    }

    .tab-navigation-item {
      padding-top: 100%;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      .tab-navigation-icon {
        width: 4.8vw;
        height: 4.8vw;
        text-align: center;
        fill: @eurojackpot-mediumgray;
        color: @eurojackpot-mediumgray;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 0;
        right: 0;
        transform: translate3d(0,-50%,0);

        @media (min-width: @eurojackpot-mobile-width) {
          width: 2.5rem;
          height: 2.5rem;
        }
      }

      &.active {
        background-color: @ds-white;

        .tab-navigation-icon {
          fill: @ds-black;
          color: @ds-black;
        }
      }
    }
  }

  .tabs-container {
    width: 86vw;
    background-color: @ds-white;
    display: inline-block;

    @media (min-width: @eurojackpot-desktop-width) {
      width: ~"calc(100% - 7rem)";
    }

    .tab-container {
      @media (min-width: @eurojackpot-desktop-width) {
        width: 84rem;
        margin: auto;
      }

      .inner-wrapper {
        width: 72.8vw;
        font-size: 3.733vw;
        padding: 0.6vw 0 1.9vw;
        margin: auto;

        @media (min-width: @eurojackpot-desktop-width) {
          width: 100%;
          font-size: 2.8rem;
          padding: 0 0 1.8rem;
        }

        .tab-headline {
          font-size: 3.733vw;
          font-weight: normal;
          margin: 0;
          padding: 3.7vw 0 5.4vw;
          border-bottom: .2rem solid @eurojackpot-mediumgray;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.6rem;
            padding: 2.3rem 0 2.5rem;
            border-bottom: .1rem solid @eurojackpot-mediumgray;
          }
        }

        p {
          font-size: 3.733vw;
          line-height: 1.3;
          margin: 0;
          padding: 2.4vw 0 1.6vw;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 58.5rem;
            font-size: 1.6rem;
            line-height: 1.5;
            padding: 2.3rem 0 .6rem;
            margin: auto;
          }
        }

        ol {
          margin: 0;
          padding: 4vw 0;
          list-style-type: none;
          line-height: 1.3;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 58.5rem;
            font-size: 1.6rem;
            padding: 2rem 0 1.8rem;
            margin: auto;
          }

          li {
            counter-increment: custom-counter;
            position: relative;
            padding: 7.7vw 0 4.8vw;

            @media (min-width: @eurojackpot-desktop-width) {
              padding: 3.7rem 0 2.4rem;
            }

            &:before {
              content: counter(custom-counter);
              color: @eurojackpot-white;
              font-size: 2.933vw;
              width: 6.134vw;
              line-height: 2.2;
              text-indent: .2vw;
              margin: -0.6rem auto;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-dark_grey.svg);
              background-repeat: no-repeat;
              background-position: 50%;


              @media (min-width: @eurojackpot-desktop-width) {
                width: 3.4rem;
                height: 3.4rem;
                font-size: 1.6rem;
                line-height: 2.2;
                text-indent: .1rem;
              }
            }
          }
        }

        .simple-custom-table {
          width: 100%;
          border-collapse: inherit;
          overflow: hidden;
          line-height: 1.3;

          thead {
            th {
              font-size: 3.733vw;
              font-weight: normal;
              color: #fff;
              padding: 3.7vw 0 3.6vw;
              background-color: @eurojackpot-darkgold;

              @media (min-width: @eurojackpot-desktop-width) {
                font-size: 1.6rem;
                padding: 1.8rem 0 1.7rem;
              }

              &:first-child {
                width: 39%;
                border-right: .2rem solid @eurojackpot-gray;

                @media (min-width: @eurojackpot-desktop-width) {
                  width: 40%;
                  border-right: .1rem solid @eurojackpot-gray;
                }
              }
            }
          }

          tbody {
            tr {
              &:nth-child(odd) {
                background-color: #fff;
              }

              &:nth-child(even) {
                background-color: #f1f2f2;
              }

              td {
                font-size: 3.2vw;
                color: @eurojackpot-gray;
                padding: 3.45vw 0 3.2vw;

                @media (min-width: @eurojackpot-desktop-width) {
                  font-size: 1.5rem;
                  padding: 1.6rem 0 1.6rem;
                }

                &:first-child {
                  border-right: .2rem solid @eurojackpot-darkgold;

                  @media (min-width: @eurojackpot-desktop-width) {
                    border-right: .1rem solid @eurojackpot-darkgold;
                  }
                }
              }
            }
          }
        }

        .table-foldable-wrapper {
          max-height: 570vw;
          position: relative;
          overflow: hidden;
          transition: max-height .5s linear;

          @media (min-width: @eurojackpot-desktop-width) {
            max-height: 268rem;
          }

          .expand-action-wrapper {
            text-align: center;
            height: 15rem;
            margin: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: -moz-linear-gradient(top, rgba(29, 29, 29,0) 0%, rgba(29, 29, 29,0) 1%, rgba(29, 29, 29,1) 90%, rgba(29, 29, 29,1) 100%);
            background: -webkit-linear-gradient(top, rgba(29, 29, 29,0) 0%,rgba(29, 29, 29,0) 1%,rgba(29, 29, 29,1) 90%,rgba(29, 29, 29,1) 100%);
            background: linear-gradient(to bottom, rgba(29, 29, 29,0) 0%,rgba(29, 29, 29,0) 1%,rgba(29, 29, 29,1) 90%,rgba(29, 29, 29,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d1d1d', endColorstr='#1d1d1d',GradientType=0 );

            .btn {
              position: relative;
              bottom: -7rem;
              background: @eurojackpot-white;
              display: inline-block;
              text-transform: uppercase;
            }
          }

          &.active {
            padding-bottom: 16rem;

            .simple-custom-table {
              margin-bottom: 14rem;
            }

            .expand-action-wrapper {
              bottom: -1rem;

              .btn {
                bottom: -5rem;
              }
            }
          }
        }
      }
    }

    .accordions-container {
      border-radius: 6px;
      overflow: hidden;
      line-height: 1.3;
      margin: 3.2vw auto 4.7vw;

      @media (min-width: @eurojackpot-desktop-width) {
        width: 52.2rem;
        margin: 2rem auto 1rem;
      }

      .print-button {
        font-size: 0;
        line-height: 0;
        background-color: @eurojackpot-mediumgray;
        float: right;
        border-radius: 6px 6px 0 0;
        padding: 2vw 3vw 3vw;
        margin-bottom: -1vw;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        transition: transform .1s ease-out;

        @media (min-width: @eurojackpot-desktop-width) {
          padding: 1rem 1.5rem 2rem;
          margin-bottom: -.5rem;
          bottom: -.5rem;
        }

        .icon-print {
          width: 4vw;
          height: 4vw;
          fill: @eurojackpot-white;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        &:hover {
          @media (min-width: @eurojackpot-desktop-width) {
            transform: translate3d(0, -.5rem, 0);
          }
        }
      }

      .accordions-header {
        font-size: 3.733vw;
        color: #fff;
        background-color: @eurojackpot-mediumgray;
        padding: 3.668vw 0 3.668vw;
        border-radius: 6px 6px 0 0;
        clear: both;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 1.6rem;
          padding: 2rem 0 2rem;
        }
      }
    }

    .accordion-wrapper {
      line-height: 1.3;
      //border-bottom: .2rem solid @eurojackpot-mediumgray;

      @media (min-width: @eurojackpot-desktop-width) {
        border-bottom: .1rem solid @eurojackpot-mediumgray;
      }

      .accordion-header {
        text-align: left;
        background-color: transparent;
        padding: 4.16vw 8vw 4.11vw 4vw;
        border-width: 0 .2rem .2rem .2rem;
        border-style: solid;
        border-color: @eurojackpot-mediumgray;
        position: relative;
        cursor: pointer;

        @media (min-width: @eurojackpot-desktop-width) {
          padding: 0.5rem 3rem 1.8rem 2rem;
        }

        .title {
          font-size: 3.733vw;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.5rem;
          }
        }

        .icon {
          width: 3.466vw;
          height: 2.733vw;
          position: absolute;
          top: 5.9vw;
          right: 4.266vw;
          fill: @ds-black;
          overflow: visible;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 1.7rem;
            height: 1.7rem;
            top: 2.5rem;
            right: 1.9rem;
          }

          use {
            transition: transform .25s linear, opacity .25s linear;
          }

          .arrow-down {
            transform: translateY(-.3vw);

            @media (min-width: @eurojackpot-desktop-width) {
              width: 1.5rem;
              height: .9rem;
              transform: translateY(-.3rem);
            }
          }

          .close {
            opacity: 0;
            transform: translateY(-150%);

            @media (min-width: @eurojackpot-desktop-width) {
              width: 1.7rem;
              height: 1.7rem;
            }
          }
        }
      }

      .accordion-body {
        max-height: 0;
        overflow: hidden;
        border-width: 0 .2rem 0 .2rem;
        border-style: solid;
        border-color: @eurojackpot-mediumgray;
        transition: max-height .25s linear;

        .accordion-headline {
          text-align: center;
          font-size: 3.466vw;
          padding: 0 4vw 5.6vw 4vw;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.6rem;
            padding: 0 2rem 2.1rem 2rem;
          }
        }

        .accordion-table {
          width: 100%;
          line-height: 1.3;
          font-size: 3.2vw;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.5rem;
          }

          thead {
            tr {
              border-bottom: .2rem solid #f1f2f2;

              @media (min-width: @eurojackpot-desktop-width) {
                border-bottom: .1rem solid #f1f2f2;
              }

              td {
                padding: 2.6vw 0 3.9vw;

                @media (min-width: @eurojackpot-desktop-width) {
                  padding: 1.4rem 0 1.7rem;
                }

                span {
                  display: none;

                  @media (min-width: @eurojackpot-desktop-width) {
                    display: inline-block;
                    padding-left: .5rem;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              &:first-child {
                td {
                  padding-top: 2.5vw;

                  @media (min-width: @eurojackpot-desktop-width) {
                    padding-top: 1.2rem;
                  }
                }
              }

              &:last-child {
                td {
                  padding-bottom: 3.4vw;

                  @media (min-width: @eurojackpot-desktop-width) {
                    padding-bottom: 1.7rem;
                  }
                }
              }

              td {
                width: 17.25%;
                padding-top: 1vw;
                padding-bottom: .9vw;

                @media (min-width: @eurojackpot-desktop-width) {
                  width: 18.5%;
                  padding-top: .5rem;
                  padding-bottom: .5rem;
                }

                &:last-child {
                  width: 31%;

                  @media (min-width: @eurojackpot-desktop-width) {
                    width: 26%;
                  }
                }
              }
            }
          }
        }

        .simple-accordion-table {
          width: 100%;
          line-height: 1.3;
          font-size: 3.733vw;
          color: @eurojackpot-mediumgray;
          text-align: left;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.5rem;
          }

          tbody {
            tr {
              td {
                width: 78%;
                padding: 2.8vw 0 2.8vw 4vw;

                @media (min-width: @eurojackpot-desktop-width) {
                  width: 88%;
                  padding: 1.6rem 0 1.6rem 2rem;
                }

                &:last-child {
                  width: 22%;
                  padding: 2.8vw 0;

                  @media (min-width: @eurojackpot-desktop-width) {
                    width: 12%;
                    padding: 1.6rem 0;
                  }
                }
              }

              &:first-child {
                td {
                  padding: 4.5vw 0 3.3vw 4vw;

                  @media (min-width: @eurojackpot-desktop-width) {
                    padding: 1.6rem 0 1.6rem 2rem;
                  }

                  &:last-child {
                    padding: 4.5vw 0 3.3vw 0;

                    @media (min-width: @eurojackpot-desktop-width) {
                      padding: 1.6rem 0;
                    }
                  }
                }
              }

              &:last-child {
                td {
                  padding: 3.3vw 0 4vw 4vw;

                  @media (min-width: @eurojackpot-desktop-width) {
                    padding: 1.6rem 0 1.6rem 2rem;
                  }

                  &:last-child {
                    padding: 3.3vw 0 4vw 0;

                    @media (min-width: @eurojackpot-desktop-width) {
                      padding: 1.6rem 0;
                    }
                  }
                }
              }
            }
          }
        }

        .accordion-tabs-wrapper {
          text-align: center;
          color: @eurojackpot-white;

          .accordion-tabs-navigation {
            width: 100%;
            table-layout: fixed;

            .accordion-tab-button {
              font-size: 3.733vw;
              border-radius: 6px 6px 0 0;
              cursor: pointer;
              padding: 4.33vw 0 4.34vw 0;
              user-select: none;
              background-color: @eurojackpot-whitesmoke;
              color: @eurojackpot-mediumgray;

              @media (min-width: @eurojackpot-desktop-width) {
                font-size: 1.6rem;
                padding: 2.2rem 0 1.8rem 0;
              }

              &:first-child {
                border-radius: 0 6px 0 0;
              }

              &:last-child {
                border-radius: 6px 0 0 0;
              }

              &.active {
                background-color: @eurojackpot-mediumgray;
                color: @eurojackpot-white;
              }
            }
          }

          .accordion-tabs-body {
            background-color: @eurojackpot-mediumgray;
            padding: 1.3vw 3.333vw 0.3vw 3.333vw;

            @media (min-width: @eurojackpot-desktop-width) {
              padding: 1.2rem 2.5rem 1.1rem 2.5rem;
            }

            .accordion-tab-table {
              width: 100%;
              font-size: 3.2vw;
              table-layout: fixed;
              display: none;

              @media (min-width: @eurojackpot-desktop-width) {
                font-size: 1.4rem;
              }

              &.active {
                display: table;
              }

              tr {
                td {
                  border-bottom: .1rem solid @eurojackpot-gray;
                  padding: 1.8vw 0 3.2vw 0;

                  @media (min-width: @eurojackpot-desktop-width) {
                    padding: 1.4rem 0 1.3rem 0;
                  }
                }

                &:last-child {
                  td {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }

      &.active {
        .accordion-header {
          border-width: 0 .2rem 0 .2rem;

          .icon {
            .arrow-down {
              opacity: 0;
              transform: translateY(150%);
            }

            .close {
              opacity: 1;
              transform: translateY(-.4rem);
            }
          }
        }

        .accordion-body {
          border-width: 0 .2rem .2rem .2rem;
        }
      }

      &:last-child {
        border-bottom: none;

        .accordion-header {
          border-radius: 0 0 6px 6px;
          transition: border-radius 0s .25s linear, border-width 0s .25s linear;
        }

        .accordion-body {
          border-radius: 0 0 6px 6px;

          .accordion-table {
            tbody {
              tr {
                &:last-child {
                  td {
                    padding-bottom: 3.6vw;

                    @media (min-width: @eurojackpot-desktop-width) {
                      padding-bottom: 1.8rem;
                    }
                  }
                }
              }
            }
          }
        }

        &.active {
          .accordion-header {
            border-radius: 0;
            transition: border-radius 0s 0s linear, border-width 0s 0s linear;
          }
        }
      }
    }

    .game-container {
      .rows-container {
        width: 84.535%;
        margin: auto;
        font-size: 0;
        line-height: 0;

        @media (min-width: @eurojackpot-desktop-width) {
          width: 83.2rem;
        }

        @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
          width: 88%;
        }

        .row-header {
          margin: 2.6vw 0 1.3vw;
          padding-bottom: 1.934vw;

          @media (min-width: @eurojackpot-desktop-width) {
            margin: 2.6rem 0 2rem;
            padding-bottom: 2.3rem;
          }

          .number-of-rows {
            clear: both;
            display: block;
            padding-left: 0vw;

            @media (min-width: @eurojackpot-desktop-width) {
              display: inline;
              padding-left: 1vw;
            }

            opacity: .6;
          }
        }

        .number-picker-container {
          text-align: left;

          .buttons-container {
            padding: 4.034vw 0 3.734vw;
            text-align: center;

            @media (min-width: @eurojackpot-desktop-width) {
              padding: 2.9rem 0 3rem;
            }

            span {
              float: none;
            }
          }
        }
      }
    }
  }
}


.eurojackpot-lucky-game {
  .eurojackpot-lucky-game-section {
    .eurojackpot-content-wrapper {
      .rows-container {
        .row-container {
          .row-header {
            padding-top:2.05vw;
            padding-bottom:4.45vw;
            @media (min-width: @eurojackpot-desktop-width) {
              padding-top: 0;
              padding-bottom: 2.1rem;
            }
          }
        }
      }
    }
  }
}
