﻿@import url("../../Mixins/_mixins.less");

.statistics-module {
  .statistics-module-selected-numbers {
    width: 68vw;
    color: #fff;
    font-size: 0;
    position: fixed;
    top: 50vw;
    right: 0;
    transform: translateX(100%);
    transition: transform .15s linear;
    z-index: 1;

    @media (min-device-width: @desktop-width) {
      max-height: initial;
      width: initial;
      position: initial;
      top: initial;
      right: initial;
      transform: translateX(0);
    }

    &.opened {
      transform: translateX(0);
    }

    .no-selected-numbers {
      width: 100%;
      box-sizing: border-box;
      text-align: center;

      @media (min-device-width: @desktop-width) {
        margin: .5rem 0;
        padding: 2.25rem 2rem;
        font-size: 1.8rem;
      }
    }

    .selected-numbers-game-wrapper {
      max-height: 65vh;
      overflow: hidden;
      overflow-y: auto;
      border-radius: 6px 0 0 6px;

      @media (min-device-width: @desktop-width) {
        padding: 1.5rem 5rem;
        border-radius: 0;
      }

      &.lotto-numbers {
        color: #fff;
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
        background-color: @lotto-maroonred;
        fill: #fff;

        .selected-numbers-game-inner-wrapper {
          .selected-numbers-wrapper {
            .selected-number-box {
              &.special {
                .number-cell {
                  background-color: @lotto-darkred;
                }
              }

              .number-cell {
                background-color: @lotto-red;
                border-radius: 50%;
                font-weight: 600;
                padding-top: 2.4vw;

                @media (min-device-width: @desktop-width) {
                  padding-top: .9rem;
                }
              }
            }
          }
        }

        & + .selected-numbers-mobile-toggle-button {
          background-color: @lotto-maroonred;
        }
      }

      &.eurojackpot-numbers {
        color: #fff;
        background-color: #0A0A0A;
        fill: #fff;

        .selected-numbers-game-inner-wrapper {
          .selected-numbers-wrapper {
            .selected-number-box {
              &.special {
                .number-cell {
                  padding-top: 1.1vw;
                  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);

                  @media (min-device-width: @desktop-width) {
                    padding-top: 1.1rem;
                  }
                }
              }

              .number-cell {
                padding-top: .9vw;
                background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-gold_with-gradient.svg);

                @media (min-device-width: @desktop-width) {
                  padding-top: .9rem;
                }
              }
            }
          }
        }

        & + .selected-numbers-mobile-toggle-button {
          background-color: @eurojackpot-carbon;
        }
      }

      &.vikinglotto-numbers {
        color: #fff;
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
        background-color: @vikinglotto-darkblue;
        fill: #fff;

        .selected-numbers-game-inner-wrapper {
          .selected-numbers-wrapper {
            .selected-number-box {
              &.special {
                .number-cell {
                  border-radius: 0;
                  background-color: transparent;
                  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-yellow.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }

              .number-cell {
                color: #1d1d1d;
                border-radius: 50%;
                background-color: @numbergames-cta-yellow;
                font-weight: 600;
                padding-top: 2.4vw;

                @media (min-device-width: @desktop-width) {
                  padding-top: .9rem;
                }
              }
            }
          }
        }

        & + .selected-numbers-mobile-toggle-button {
          background-color: @vikinglotto-darkblue;
        }
      }

      &.keno-numbers {
        color: #fff;
        background-color: @keno-powderblue;
        fill: #fff;

        .selected-numbers-game-inner-wrapper {
          .selected-numbers-wrapper {
            .selected-number-box {
              .number-cell {
                border-radius: 6px;
                background-color: #21375f;
                font-weight: bold;
                padding-top: 3vw;

                @media (min-device-width: @desktop-width) {
                  padding-top: 1.2rem;
                }
              }
            }
          }
        }

        & + .selected-numbers-mobile-toggle-button {
          background-color: @keno-royalblue;
        }
      }

      .selected-numbers-game-inner-wrapper {
        margin: auto;

        @media (min-device-width: @desktop-width) {
          width: 96rem;
        }

        .selected-numbers-headline {
          font-size: 5vw;
          text-align: left;
          position: relative;
          padding: 3vw 4vw 10vw;

          @media (min-device-width: @desktop-width) {
            font-size: 1.6rem;
            padding: 0 0 .5rem;
          }

          .selected-numbers-delete-button {
            font-size: 4vw;
            position: absolute;
            right: 4vw;
            top: 14vw;

            @media (min-device-width: @desktop-width) {
              font-size: initial;
              right: 0;
              top: 0;
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                cursor: pointer;
              }

              &:before {
                opacity: 1;
                transform: scaleX(1);
              }
            }

            &:before {
              content: '';
              height: .1rem;
              width: 105%;
              background-color: #fff;
              position: absolute;
              bottom: -.1rem;
              left: -2.5%;
              transform-origin: center;
              transform: scaleX(0);
              opacity: 0;
              transition: transform .15s linear, opacity .1s linear;
            }

            .icon {
              width: 4vw;
              height: 4vw;
              margin-left: 1vw;
              display: inline-block;
              fill: inherit;

              @media (min-device-width: @desktop-width) {
                width: 1.5rem;
                height: 1.5rem;
                margin-left: .5rem;
              }
            }
          }
        }

        .selected-numbers-wrapper {
          padding: 2vw;

          @media (min-device-width: @desktop-width) {
            padding: 0;
            margin-left: -.5rem;
          }

          .selected-number-box {
            width: 12vw;
            height: 12vw;
            text-align: center;
            background-color: #f9f9f9;
            margin: 2vw;
            border-radius: 6px;
            display: inline-block;
            position: relative;
            transition: background-color .15s linear;

            @media (min-device-width: @desktop-width) {
              width: 5rem;
              height: 5rem;
              margin: .5rem;
            }

            &:hover {
              .selected-number-delete-button {
                pointer-events: inherit;
                transform: translate(50%,-50%) scale(1);
                opacity: 1;

                &:hover {
                  @media (min-device-width: @desktop-width) {
                    cursor: pointer;
                    transform: translate(50%,-50%) scale(1.1);
                  }
                }
              }
            }

            &.hovered {
              background-color: #dcdcdc;
            }

            &:not(.special) + .special {
              @media (min-device-width: @desktop-width) {
                margin-left: 1.4rem;
              }

              &:before {
                content: '';
                position: absolute;
                background-color: #fff;
                border-radius: 2px;

                @media (min-device-width: @desktop-width) {
                  width: .4rem;
                  top: .6rem;
                  left: -1.2rem;
                  bottom: .6rem;
                }
              }

              & + .special {
                @media (min-device-width: @desktop-width) {
                  margin-left: .5rem;
                }

                &:before {
                  content: none;
                }
              }
            }

            .selected-number-delete-button {
              width: 6vw;
              height: 6vw;
              position: absolute;
              border-radius: 50%;
              background-color: #505050;
              right: 0;
              top: 0;
              transform: translate(50%,-50%) scale(.8);
              opacity: 0;
              pointer-events: none;
              transition: transform .15s linear, opacity .15s linear;
              will-change: transform;

              @media (min-device-width: @desktop-width) {
                width: 2rem;
                height: 2rem;
              }

              .icon {
                width: 2vw;
                height: 6vw;
                fill: #fff;

                @media (min-device-width: @desktop-width) {
                  width: 1rem;
                  height: 1rem;
                  margin-top: .5rem;
                }
              }
            }

            .number-cell {
              width: 10vw;
              height: 10vw;
              font-size: 4vw;
              color: #fff;
              box-sizing: border-box;
              margin: 1vw;
              position: absolute;

              @media (min-device-width: @desktop-width) {
                width: 4rem;
                height: 4rem;
                font-size: 1.6rem;
                margin: .5rem;
              }
            }

            .selected-number-value {
              width: 100%;
              position: absolute;
              bottom: .3rem;
              left: 0;
              font-size: 1.4rem;

            }
          }
        }
      }

      .selected-numbers-go-to-game-buttons-wrapper {
        margin: auto;

        @media (min-device-width: @desktop-width) {
          width: 96rem;
          padding-top: 1rem;
        }

        .go-to-game-header {
          text-align: left;

          @media (min-device-width: @desktop-width) {
            font-size: 1.6rem;
          }
        }

        .go-to-game-buttons {
          padding: 0 4vw 2vw;

          @media (min-device-width: @desktop-width) {
            padding: 0;
            display: flex;
          }

          .go-to-game-button {
            font-size: 4vw;
            color: @numbergames-grey;
            padding: 2vw;
            margin-bottom: 2vw;
            text-align: center;
            font-weight: bold;
            border-radius: 6px;
            flex-grow: 1;
            background-color: @numbergames-cta-yellow;
            transition: background-color .15s linear;

            @media (min-device-width: @desktop-width) {
              font-size: 1.4rem;
              padding: 1rem;
              margin: 1rem;
              cursor: pointer;
            }

            &:first-child {
              @media (min-device-width: @desktop-width) {
                margin-left: 0;
              }
            }

            &:last-child {
              @media (min-device-width: @desktop-width) {
                margin-right: 0;
              }
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                background-color: @numbergames-cta-yellow-hover;
              }
            }
          }
        }
      }
    }
  }

  .selected-numbers-mobile-toggle-button {
    width: 12vw;
    height: 12vw;
    position: absolute;
    top: 5vw;
    left: -12vw;
    padding: 3vw;
    box-sizing: border-box;
    border-radius: 6px 0 0 6px;

    @media (min-device-width: @desktop-width) {
      display: none;
    }

    .count-wrapper {
      background-color: #fff;
      font-size: 3.6vw;
      line-height: 1;
      color: @eurojackpot-carbon;
      border-radius: 3vw;
      padding: .4vw 1.2vw;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      border: 1px solid;
    }

    .icon {
      width: 6vw;
      height: 6vw;
      fill: #fff;
    }
  }
}
