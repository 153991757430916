/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.news-list {
  text-align: center;
}
.news-list .section {
  position: relative;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 960px) {
  .news-list .section {
    padding-top: 0rem;
  }
}
.news-list .section .news-heading {
  color: #FFFFFF;
  position: relative;
  z-index: 2;
}
@media (min-width: 960px) {
  .news-list .section .news-heading {
    box-sizing: border-box;
  }
}
.news-list .section .news-heading .top-logo {
  margin-bottom: 4vw;
}
@media (min-width: 960px) {
  .news-list .section .news-heading .top-logo {
    margin-bottom: 3rem;
  }
}
.news-list .section .news-heading .top-logo img {
  width: 17.33vw;
  height: 17.33vw;
}
@media (min-width: 960px) {
  .news-list .section .news-heading .top-logo img {
    width: 6.4rem;
    height: 6.4rem;
  }
}
.news-list .section .news-heading .topspot-headline {
  max-width: 86.5vw;
  font-size: 10.6vw;
  line-height: 10vw;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .news-list .section .news-heading .topspot-headline {
    font-size: 6rem;
    line-height: 5.4rem;
  }
}
.news-list .section .news-heading .topspot-subheadline {
  max-width: 86.5vw;
  font-size: 5.15vw;
  text-transform: none;
  line-height: 5.5vw;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .news-list .section .news-heading .topspot-subheadline {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}
.news-list .section .content-wrapper {
  text-align: center;
  z-index: 4;
  padding: 0;
  margin: 0 auto;
}
.news-list .section .content-wrapper .news-block {
  width: 100%;
  max-width: 86.67vw;
  border-bottom: 0.1rem solid #a3a6ab;
  padding: 8vw 0;
  margin: auto;
}
@media (min-width: 960px) {
  .news-list .section .content-wrapper .news-block {
    max-width: 58rem;
    padding: 4rem 0;
  }
}
.news-list .section .content-wrapper .news-block:last-child {
  border-bottom: none;
}
.news-list .section .content-wrapper .news-block .date {
  font-size: 3.73vw;
  line-height: normal;
}
@media (min-width: 768px) {
  .news-list .section .content-wrapper .news-block .date {
    font-size: 2rem;
    line-height: 2.0rem;
  }
}
.news-list .section .content-wrapper .news-block a.heading-link {
  text-decoration: none;
}
.news-list .section .content-wrapper .news-block a.heading-link .headline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-weight: 900;
  line-height: 5.6vw;
  margin: 1.5rem auto 2rem;
}
@media (min-width: 768px) {
  .news-list .section .content-wrapper .news-block a.heading-link .headline {
    font-size: 3.8rem;
    line-height: 4rem;
  }
}
@media (min-width: 768px) and (max-width: 960px) {
  .news-list .section .content-wrapper .news-block a.heading-link .headline {
    font-size: 3.3rem;
  }
}
.news-list .section .content-wrapper .news-block .teaser-details {
  font-size: 3.73vw;
  color: #4f5254;
  display: block;
  margin-bottom: 5vw;
}
@media (min-width: 768px) {
  .news-list .section .content-wrapper .news-block .teaser-details {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 960px) {
  .news-list .section .content-wrapper .news-block .teaser-details {
    font-size: 1.8rem;
  }
}
.news-list .section .content-wrapper .news-block .svg-icon-arrow-right {
  position: absolute;
  top: -0.3vw;
  right: 3vw;
}
@media (min-width: 960px) {
  .news-list .section .content-wrapper .news-block .svg-icon-arrow-right {
    position: absolute;
    top: -0.1rem;
    right: 1.3rem;
  }
}
.news-list .section .content-wrapper .news-block .svg-icon-arrow-right svg {
  fill: #322b00;
  width: 3.47vw;
  height: 2.134vw;
}
@media (min-width: 960px) {
  .news-list .section .content-wrapper .news-block .svg-icon-arrow-right svg {
    width: 1.3rem;
    height: 0.8rem;
  }
}
.news-list .section .content-wrapper .news-block .btn {
  color: #322b00;
  background-color: #feb700;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  padding: 0 5.5vw 0 2vw;
  margin: 2rem auto 0;
}
@media (min-width: 960px) {
  .news-list .section .content-wrapper .news-block .btn {
    padding: 0 2.5rem 0 1.2rem;
  }
}
.news-list .section .top-spot-filling {
  min-height: 35rem;
}
.news-list .section .content-wrapper .news-block.single {
  padding: 15vw 0 6vw;
}
@media (min-width: 960px) {
  .news-list .section .content-wrapper .news-block.single {
    padding: 4rem 0;
    max-width: 58rem;
  }
}
.news-list .section .content-wrapper .news-block.single .news-item-headline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-weight: 900;
  line-height: 5.6vw;
  margin: 1.5rem auto 2rem;
}
@media (min-width: 960px) {
  .news-list .section .content-wrapper .news-block.single .news-item-headline {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}
.news-list .section .content-wrapper .svg-social-share {
  width: 4rem;
  height: 4rem;
  margin: 2.0rem 1.2rem;
}
