.multi-client-wrapper.multi-client-wrapper__receipt-step > .multi-client-header {
  top: 2rem;
}
.receipt-step {
  width: 100%;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .receipt-step {
    flex-direction: row;
  }
}
.receipt-step > div {
  flex: 1 1 50%;
  padding: 3rem 0;
  position: relative;
}
@media screen and (min-width: 768px) {
  .receipt-step > div {
    padding: 6rem 0;
  }
}
.receipt-step .receipt-step__countdown {
  border-radius: 2.4rem 2.4rem 0 0;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 0 3rem;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown {
    border-radius: 0 2.4rem 2.4rem 0;
    padding: 0 2rem;
  }
}
.receipt-step .receipt-step__countdown .receipt-step__countdown-inner {
  max-width: 42.4rem;
  margin-top: 5rem;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown .receipt-step__countdown-inner {
    margin-top: 13rem;
  }
}
.receipt-step .receipt-step__countdown .receipt-step__countdown-inner .multi-client__flow-step-header {
  padding-bottom: 0;
}
.receipt-step .receipt-step__countdown .receipt-step__countdown-heading {
  font-size: 4rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 2.4rem;
}
.receipt-step .receipt-step__countdown .receipt-step__countdown-subheading {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #959595;
}
.receipt-step .receipt-step__countdown .countdown {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3rem;
  text-align: center;
  padding: 2rem;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown .countdown {
    padding: 3.5rem 4.2rem;
  }
}
.receipt-step .receipt-step__countdown .countdown .countdown-headline {
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 0 0 1rem;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown .countdown .countdown-headline {
    font-size: 2.4rem;
    line-height: 2.6rem;
    font-weight: 700;
  }
}
.receipt-step .receipt-step__countdown .countdown .number-container {
  flex: 1;
}
.receipt-step .receipt-step__countdown .countdown .number-container span {
  display: block;
}
.receipt-step .receipt-step__countdown .countdown .number-container .number {
  font-size: 5rem;
  line-height: 4.7rem;
  font-weight: 800;
  padding-bottom: 0.7rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown .countdown .number-container .number {
    font-size: 6.8rem;
    line-height: 7.8rem;
  }
}
.receipt-step .receipt-step__countdown .countdown .number-container .number::before {
  content: "";
  width: 0.3rem;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  left: 0;
  top: 1rem;
  bottom: 1rem;
  transform: translateX(-50%);
}
.receipt-step .receipt-step__countdown .countdown .number-container .time-denominator {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown .countdown .number-container .time-denominator {
    font-size: 1.7rem;
    line-height: 1.5rem;
  }
}
.receipt-step .receipt-step__countdown .countdown .number-container:first-of-type .number::before {
  display: none;
}
.receipt-step .receipt-step__countdown .reminder-alert {
  margin: 5rem 0 0;
  font-size: 1.6rem;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__countdown .reminder-alert {
    margin-bottom: -5rem;
  }
}
.receipt-step .receipt-step__countdown .reminder-alert input[type=checkbox] {
  display: none;
}
.receipt-step .receipt-step__countdown .reminder-alert input[type=checkbox]:checked + label .checkbox:after {
  content: '';
  position: absolute;
  border: 0.2rem solid #000000;
  border-left: 0;
  border-top: 0;
  font-size: 2rem;
  transform: rotate(45deg);
  left: 0.3em;
  top: 0.1em;
  width: 0.3em;
  height: 0.5em;
}
.receipt-step .receipt-step__countdown .reminder-alert label {
  background: #eeeeee;
  display: inline-block;
  padding: 2.5rem 2.5rem 2.5rem 6rem;
  border-radius: 2rem;
  position: relative;
  text-align: left;
  line-height: 1.5;
  user-select: none;
}
.receipt-step .receipt-step__countdown .reminder-alert label .checkbox {
  background: white;
  width: 2.0rem;
  height: 2.0rem;
  left: 2.5rem;
  top: 2.6rem;
  border-radius: 0.4rem;
  content: '';
  display: inline-block;
  vertical-align: text-top;
  border: 0.1rem solid #a2a2a2;
  position: absolute;
}
.receipt-step .receipt-step__countdown .reminder-alert.reminder-alert--loading label {
  overflow: hidden;
}
.receipt-step .receipt-step__countdown .reminder-alert.reminder-alert--loading label:before {
  position: absolute;
  content: "";
  top: -50%;
  bottom: -50%;
  left: -200%;
  width: 3rem;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0) 100%);
  animation: reminder-alert--loading 1000ms linear infinite;
  transform: rotate(20deg);
}
.receipt-step .receipt-step__countdown .reminder-alert.reminder-alert--loading label:after {
  position: absolute;
  content: "";
  top: -50%;
  bottom: -50%;
  left: -200%;
  width: 1.5rem;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0) 100%);
  animation: reminder-alert--loading 1000ms linear infinite;
  animation-delay: 500ms;
  transform: rotate(20deg) translateX(-2rem);
}
@keyframes reminder-alert--loading {
  100% {
    left: 300%;
  }
}
.receipt-step .multi-client__countdown-item {
  margin: 3rem 0;
  opacity: 0.6;
  transition: opacity 0.15s linear;
  display: none;
}
@media screen and (min-width: 768px) {
  .receipt-step .multi-client__countdown-item {
    display: block;
    margin: 8rem 0 3rem;
  }
}
.receipt-step .multi-client__countdown-item.multi-client__countdown-item--selected {
  opacity: 1;
  display: block;
}
.receipt-step .multi-client__countdown-item + .multi-client__countdown-item {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__coupon::before {
    content: "";
    width: 3.1rem;
    height: 3.1rem;
    background-color: white;
    position: absolute;
    left: -0.3rem;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
.receipt-step .receipt-step__coupon .fireworks-animation {
  height: 50rem;
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
}
.receipt-step .receipt-step__coupon .view-coupon {
  width: 26.5rem;
}
.receipt-step .receipt-step__coupon-promos {
  background-color: white;
  padding: 1.2rem 2.4rem 2rem;
  position: sticky;
  margin-top: 3rem;
  bottom: 0;
}
.receipt-step .receipt-step__coupon-promos::before {
  content: "";
  width: 100%;
  height: 3rem;
  background-color: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
  position: absolute;
  top: -3rem;
  left: 0;
}
.receipt-step .receipt-step__coupon-promos .promo-card {
  flex: 1;
}
.receipt-step .receipt-step__coupon-promos .promo-card .promo-card__box {
  margin: 0;
}
.multi-client-wrapper__eurojackpot .receipt-step .receipt-step__countdown .countdown {
  background-color: #E8A332;
  color: white;
}
.multi-client-wrapper__eurojackpot .receipt-step .receipt-step__promo-cards .promo-card__box {
  background-color: #E8A332;
  color: white;
}
.multi-client-wrapper__lotto .receipt-step .receipt-step__countdown .countdown {
  background-color: #c50005;
  color: white;
}
.multi-client-wrapper__lotto .receipt-step .receipt-step__promo-cards .promo-card__box {
  background-color: #c50005;
  color: white;
}
.multi-client-wrapper__lotto .receipt-step .fireworks-animation {
  filter: brightness(0.1) contrast(0.5) saturate(200) hue-rotate(340deg);
}
.multi-client-wrapper__vikinglotto .receipt-step .receipt-step__countdown .countdown {
  background-color: #0000ff;
  color: white;
}
.multi-client-wrapper__vikinglotto .receipt-step .receipt-step__promo-cards .promo-card__box {
  background-color: #0000ff;
  color: white;
}
.multi-client-wrapper__vikinglotto .receipt-step .fireworks-animation {
  filter: brightness(0.2) contrast(0.8) saturate(150) hue-rotate(220deg);
}
.multi-client-wrapper__campaign-Sommerlotto .receipt-step .receipt-step__countdown .countdown {
  background-color: #E95801;
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .receipt-step .fireworks-animation {
  filter: brightness(0.5) contrast(0.1) saturate(100) hue-rotate(340deg);
}
.receipt-step .receipt-step__promo-cards {
  display: flex;
  flex-direction: row;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__promo-cards {
    flex-direction: column;
  }
}
.receipt-step .receipt-step__promo-cards .promo-card__box {
  min-height: 16rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 6rem 4rem 1.5rem;
  box-sizing: border-box;
  border-radius: 3rem;
  margin: 3rem 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: flex-end;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__promo-cards .promo-card__box {
    min-height: 20rem;
  }
}
.receipt-step .receipt-step__promo-cards .promo-card__box .promo-card__box-content {
  margin-top: auto;
  filter: drop-shadow(0 0 0.2rem #000000);
}
.receipt-step .receipt-step__promo-cards .promo-card__box .promo-card__box-content .promo-card__title {
  font-size: 2.4rem;
  line-height: 2.6rem;
  font-weight: 800;
}
.receipt-step .receipt-step__promo-cards .promo-card__box .promo-card__box-content .promo-card__text {
  font-size: 1.6rem;
  line-height: 2rem;
}
.receipt-step .receipt-step__promo-cards .promo-card__box .promo-card__box-content .promo-card__title + .promo-card__text {
  margin-top: 0.8rem;
}
.receipt-step .receipt-step__promo-cards .promo-card__box .multi-client-cta-btn {
  margin: 1.6rem 0 0;
  height: 3.6rem;
}
@media screen and (min-width: 768px) {
  .receipt-step .receipt-step__promo-cards .promo-card__box .multi-client-cta-btn {
    height: 5.6rem;
  }
}
.multi-client__coupon-tabs-wrapper {
  display: flex;
  margin: 0 auto 2rem;
  position: sticky;
  top: 0;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .multi-client__coupon-tabs-wrapper {
    margin: 0 auto 4rem;
  }
}
.multi-client__coupon-tabs-wrapper .multi-client__coupon-tabs {
  width: calc(100% - 6rem);
  background-color: white;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 1px solid #a2a2a2;
  border-radius: 28px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .multi-client__coupon-tabs-wrapper .multi-client__coupon-tabs {
    width: auto;
    background-color: #eeeeee;
  }
}
.multi-client__coupon-tabs-wrapper .multi-client__coupon-tab {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.8rem;
  transition: opacity 0.15s linear, color 0.15s 0.1s linear;
  cursor: pointer;
  position: relative;
  margin: 0 0.4rem;
  text-align: center;
  flex: 1;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .multi-client__coupon-tabs-wrapper .multi-client__coupon-tab {
    font-size: 1.6rem;
    line-height: 1.4rem;
    padding: 1rem 2rem;
    font-weight: 800;
  }
}
.multi-client__coupon-tabs-wrapper .multi-client__coupon-tab:hover:not(.multi-client__coupon-tab--active) {
  opacity: 0.6;
}
.multi-client__coupon-tabs-wrapper .multi-client__coupon-tab--active {
  color: white;
}
.multi-client__coupon-tabs-wrapper .multi-client__coupon-tab-active-indicator {
  position: absolute;
  height: 100%;
  transition: width 0.15s linear, left 0.15s linear, background-color 0.15s linear;
  scale: 1.15;
  border-radius: 28px;
  left: 0;
}
.region-lotto .multi-client__coupon-tabs-wrapper .multi-client__coupon-tab-active-indicator {
  background-color: #c50005;
}
.region-vikinglotto .multi-client__coupon-tabs-wrapper .multi-client__coupon-tab-active-indicator {
  background-color: #0000ff;
}
.region-eurojackpot .multi-client__coupon-tabs-wrapper .multi-client__coupon-tab-active-indicator {
  background-color: #e8a332;
}
