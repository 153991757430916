@import url("../Mixins/_mixins.less");

.informationspot {

  .richtext {
    width: 86.667vw;
    margin: auto;
    text-align: center;
    padding: 7.333vw 0;

    @media (min-width: @desktop-width) {
      width: 58rem;
      padding: 3rem 0;
    }

    p span a {
      display: block;
      margin-top: 1vw;

      @media (min-width: @desktop-width) {
        margin-top: 1rem;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: inherit;
    border-radius: 7.5px;
    table-layout: fixed;
    overflow: hidden;

    td, th {
      padding: 3.5vw 2.2vw;
      font-size: 3.2vw;
      border: 0.1rem solid;

      @media (min-width: @desktop-width) {
        padding: 1.7rem 1.3rem;
        font-size: 1.5rem;
      }
    }

    th {
      font-weight: bold;
    }

    thead {
      th {
        font-weight: bold;
        font-size: 3.7vw;
        padding: 4vw;

        @media (min-width: @desktop-width) {
          padding: 2rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}
