@import url("../../Mixins/_mixins.less");

.region-eurojackpot {

  .informationswitcherspotview {

    .select-dropdown {
      span {
        text-transform: none;
        background: @eurojackpot-whitesmoke;
        font-weight: 800;
        color: @eurojackpot-gray;

        &:hover {
          color: @numbergames-cta-yellow;
        }
      }

      &.is-active .cs-placeholder {
        color: @numbergames-cta-yellow;
      }

      .cs-placeholder {
        text-transform: none;
        font-weight: 800;
        color: @eurojackpot-gray;
        background: @numbergames-white;
      }

      .select-options {

        ul li {
          &:hover {
            color: @numbergames-cta-yellow;
          }
        }
      }
    }
  }
}
