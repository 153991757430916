/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.top-spot-page-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0 4vw;
  font-size: 0;
}
@media (min-width: 960px) {
  .top-spot-page-header {
    min-height: 40rem;
    padding: 4rem 0;
  }
}
.top-spot-page-header .background-image-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
}
.top-spot-page-header .background-image-wrapper .background-image {
  min-height: 100%;
  min-width: 100vw;
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
}
.top-spot-page-header .top-spot-splash {
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: left;
  font-size: 0;
  margin-bottom: -4vw;
  pointer-events: none;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot-splash {
    width: auto;
    position: absolute;
    margin-bottom: 0;
  }
}
.top-spot-page-header .top-spot-splash.right {
  text-align: right;
}
.top-spot-page-header .top-spot-splash img {
  width: 40vw;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot-splash img {
    width: auto;
  }
}
.top-spot-page-header .top-spot__content-wrapper {
  text-align: center;
  margin-top: 6vw;
  z-index: 1;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper {
    margin-top: 0;
    width: 96rem;
  }
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__icon-box {
    margin-bottom: 3rem;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__icon-box .icon__image {
  height: 9vw;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__icon-box .icon__image {
    height: 3rem;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__icon-box .icon-tag__line {
  font-size: 3vw;
  color: #fff;
  display: block;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__icon-box .icon-tag__line {
    font-size: 1.5rem;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper {
  color: #fff;
  margin: auto;
  font-weight: bold;
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper .preface-text__line {
  font-size: 4vw;
  letter-spacing: .05em;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper .preface-text__line {
    font-size: 2.8rem;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper .title-text__line {
  font-weight: 900;
  font-size: 18vw;
  line-height: 1;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper .title-text__line {
    font-size: 14rem;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper .subtitle-text__line {
  font-size: 6vw;
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__text-wrapper .subtitle-text__line {
    font-size: 4rem;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__button {
  color: #1d1d1d;
  font-weight: 700;
  font-size: 4vw;
  text-decoration: none;
  line-height: 10.7vw;
  padding: 0 4vw;
  background-color: #feb700;
  display: inline-block;
  border-radius: 6px;
  margin: 4vw auto;
}
@media (min-width: 768px) and (max-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__button {
    font-size: 3vw;
    line-height: 8vw;
  }
}
@media (min-width: 960px) {
  .top-spot-page-header .top-spot__content-wrapper .top-spot__button {
    min-width: 12rem;
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 1.2rem;
    margin: 2rem auto 0;
  }
}
.top-spot-page-header .top-spot__content-wrapper .top-spot__flipclock-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 2rem;
}
.top-spot-page-header.manchete-top {
  width: 200%;
  transform: translateX(-25%);
  border-radius: 50% / 10vw;
  overflow: hidden;
  margin-top: -1.5vw;
  position: relative;
  z-index: 1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.top-spot-page-header.manchete-bottom {
  width: 200%;
  transform: translateX(-25%);
  border-radius: 50% / 10vw;
  overflow: hidden;
  margin-bottom: -1.5vw;
  position: relative;
  z-index: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.top-spot-page-header.manchete-top.manchete-bottom {
  border-radius: 50% / 10vw;
}
