.is-mobile-detected .top-navigation-theme-eurojackpot .menu-toggle-right {
  background-color: #bd903d;
}
.top-navigation-theme-eurojackpot .brand-nav {
  background: #bb7f36;
  background: -moz-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: -webkit-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb7f36', endColorstr='#bb7f36', GradientType=1);
}
.top-navigation-theme-eurojackpot .corporate-nav {
  background: #bb7f36;
  background: -moz-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: -webkit-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb7f36', endColorstr='#bb7f36', GradientType=1);
  border-bottom: 1px solid white;
}
.top-navigation-theme-eurojackpot .mobile-navigation-header {
  background: #bb7f36;
  background: -moz-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: -webkit-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb7f36', endColorstr='#bb7f36', GradientType=1);
}
.top-navigation-theme-eurojackpot .mobile-navigation-footer-icon {
  background: #bb7f36;
  background: -moz-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: -webkit-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb7f36', endColorstr='#bb7f36', GradientType=1);
}
.top-navigation-theme-eurojackpot .mobile-navigation-item-link:before {
  background: #bb7f36;
  background: -moz-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: -webkit-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb7f36', endColorstr='#bb7f36', GradientType=1);
}
.top-navigation-theme-eurojackpot .mega-menu__campaign__cta-button {
  background-color: #bd903d;
  color: white;
}
.top-navigation-theme-eurojackpot .mega-menu__campaign__cta-button:hover {
  background-color: #74653c;
}
