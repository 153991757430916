﻿@import "../_imports.less";

.hero-title() {
  font-size: 6rem;
  color: @ds-white;
  margin: 0 auto;
  padding: 5rem 0 2rem 0;
  width: 70rem;
  max-width: 70%;
  display: flex;
  justify-content: center;
  line-height: 0.8;
  font-family: "AvenirNext", sans-serif;
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.01em;
  transform: rotate(-10deg);
  text-transform: uppercase;
  text-shadow: 0.2rem 0.5rem 1.6rem rgba(0, 0, 0, 0.15);
  
  @media @sm {
    font-size: 9rem;
    max-width: unset;
    padding-bottom: 8rem;
  }
}

