﻿@import url("../Mixins/_mixins.less");

.informationswitchercontent {
  .panel {
    display: none;

    &.is-displayed {
      display: block;
    }
    // Show all panels in page edit mode
    body.mode-edit & {
      display: block;
    }
  }
}
