/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-component-for-game-client-wrapper {
  font-size: 0;
  line-height: 0;
  border-top: 2px solid;
  user-select: none;
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper {
  width: 100%;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  text-align: left;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper {
    width: 96rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper {
  width: 100%;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper {
    width: initial;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 4.2vw 0 5vw 6vw;
  overflow: hidden;
  transition: background-color 0.25s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button {
    cursor: pointer;
    display: inline-block;
    padding: 1.2rem 0 1.2rem 2rem;
    border-radius: 0 0 6px 6px;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .icon-wrapper {
  width: 6vw;
  height: 4vw;
  margin-right: 2vw;
  display: inline-block;
  transition: transform .15s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .icon-wrapper {
    width: 2rem;
    height: 1.3rem;
    margin-right: 1rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .icon-wrapper .icon {
  width: 100%;
  height: 100%;
  display: block;
  fill: #fff;
  transition: transform .25s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button:hover .icon-wrapper {
    transform: translateY(20%);
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .text-wrapper {
  font-size: 4.266vw;
  line-height: 1;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .text-wrapper {
    font-size: 1.5rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper {
  width: 0;
  height: 12.8vw;
  margin: -4vw 0 -4.5vw;
  border-radius: 6px 0 0 6px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  float: right;
  transition: width .1s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper {
    height: 4rem;
    margin: -1.4rem 0 -1.5rem 2rem;
    border-radius: 0;
    float: none;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper .count-value {
  width: 16vw;
  font-size: 4.266vw;
  line-height: 1;
  text-align: center;
  display: inline-block;
  padding: 4.3vw 0;
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper .count-value {
    width: 4rem;
    font-size: 1.5rem;
    padding: 1.2rem 0;
  }
}
@keyframes statistics-component-inner-wrapper_fold-animation {
  100% {
    height: 0;
    padding-top: 0;
  }
}
@keyframes statistics-component-inner-wrapper_expand-animation {
  0% {
    height: 0;
    padding-top: 0;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper {
  padding: 2vw 0 0;
  overflow: hidden;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper {
    padding: .8rem 0;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper {
  opacity: 1;
  padding: 0 2vw 14vw;
  transition: opacity .15s .1s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper {
    margin-left: -0.25rem;
    padding: 0;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box {
  width: 10vw;
  height: 10vw;
  text-align: center;
  border-radius: 6px;
  display: inline-block;
  margin: 1vw;
  position: relative;
  transition: background-color 0.15s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box {
    width: 5rem;
    height: 5rem;
    margin: .25rem;
    cursor: pointer;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .selected-number-delete-button {
  width: 6vw;
  height: 6vw;
  position: absolute;
  border-radius: 50%;
  right: 0;
  top: 0;
  transform: translate(50%, -50%) scale(0.8);
  opacity: 0;
  pointer-events: none;
  transition: transform .15s linear, opacity .15s linear;
  will-change: transform;
  z-index: 1;
  background-color: #dadbdd;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .selected-number-delete-button {
    width: 2rem;
    height: 2rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .selected-number-delete-button .icon {
  width: 3vw;
  height: 4vw;
  margin-top: 1vw;
  fill: #1d1d1d;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .selected-number-delete-button .icon {
    width: 1rem;
    height: 1rem;
    margin-top: .5rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
  position: absolute;
}
@media (min-device-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:not(.special) + .special {
    margin-left: 1.15rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:not(.special) + .special:before {
  content: '';
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
}
@media (min-device-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:not(.special) + .special:before {
    width: .4rem;
    top: .6rem;
    left: -0.95rem;
    bottom: .6rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:not(.special) + .special + .special {
    margin-left: .25rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:not(.special) + .special + .special:before {
  content: none;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:hover .selected-number-delete-button {
    opacity: 1;
    pointer-events: initial;
    cursor: pointer;
  }
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box:hover .selected-number-delete-button:hover {
    transform: translate(50%, -50%) scale(1.1);
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  display: flex;
  position: relative;
  top: -12vw;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper {
    border-radius: 0 0 6px 6px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper {
  overflow: hidden;
  padding: 3.6vw 3vw;
  text-align: center;
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  flex-grow: 1;
  transition: background-color 0.15s linear;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper {
    padding: 1.2rem 2rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper .text-wrapper {
  font-size: 4vw;
  line-height: 1;
  color: #fff;
  float: left;
  display: none;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper .text-wrapper {
    font-size: 1.5rem;
    font-weight: 400;
    display: inline-block;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper .icon {
  width: 5vw;
  height: 5vw;
  display: inline-block;
  fill: #fff;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper .icon {
    width: 2rem;
    height: 2rem;
    margin-left: 1.2rem;
    margin-top: -0.3rem;
    margin-bottom: -0.2rem;
    float: right;
  }
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper:first-child .text-wrapper {
  display: inline-block;
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper:first-child .icon {
  margin-left: 2vw;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper:first-child .icon {
    margin-left: 1.2rem;
  }
}
.statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .actions-buttons-wrapper .action-button-wrapper:last-child {
  border-right: none;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper.opened .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button:hover .icon-wrapper {
    transform: translateY(-20%);
  }
}
.statistics-component-for-game-client-wrapper.opened .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .numbers-count-wrapper {
  width: 0;
}
.statistics-component-for-game-client-wrapper.opened .statistics-component-outer-wrapper .statistics-component-inner-wrapper {
  animation: statistics-component-inner-wrapper_expand-animation 0.15s linear forwards;
}
.statistics-component-for-game-client-wrapper.closed .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .icon-wrapper .icon {
  transform: rotate(-180deg);
}
.statistics-component-for-game-client-wrapper.closed .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .numbers-count-wrapper {
  width: 16vw;
}
@media (min-width: 960px) {
  .statistics-component-for-game-client-wrapper.closed .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .numbers-count-wrapper {
    width: 4rem;
  }
}
.statistics-component-for-game-client-wrapper.closed .statistics-component-outer-wrapper .statistics-component-inner-wrapper {
  animation: statistics-component-inner-wrapper_fold-animation 0.15s linear forwards;
}
.statistics-component-for-game-client-wrapper.closed .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper {
  transition: opacity .1s linear;
  opacity: 0;
}
