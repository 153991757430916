.eurojackpot-game-tabs-wrapper {
  background-color: #eeeeee;
  font-size: 0;
}
.eurojackpot-game-tabs-wrapper:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.eurojackpot-game-tabs-wrapper .tabs-navigation {
  width: 14vw;
  display: inline-block;
  float: left;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-navigation {
    width: 7rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-navigation .tab-navigation-item {
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.eurojackpot-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
  width: 4.8vw;
  height: 4.8vw;
  text-align: center;
  fill: #616161;
  color: #616161;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate3d(0, -50%, 0);
}
@media (min-width: 768px) {
  .eurojackpot-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active {
  background-color: white;
}
.eurojackpot-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active .tab-navigation-icon {
  fill: #000000;
  color: #000000;
}
.eurojackpot-game-tabs-wrapper .tabs-container {
  width: 86vw;
  background-color: white;
  display: inline-block;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container {
    width: calc(100% - 7rem);
  }
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container {
    width: 84rem;
    margin: auto;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper {
  width: 72.8vw;
  font-size: 3.733vw;
  padding: 0.6vw 0 1.9vw;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper {
    width: 100%;
    font-size: 2.8rem;
    padding: 0 0 1.8rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .tab-headline {
  font-size: 3.733vw;
  font-weight: normal;
  margin: 0;
  padding: 3.7vw 0 5.4vw;
  border-bottom: 0.2rem solid #616161;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .tab-headline {
    font-size: 1.6rem;
    padding: 2.3rem 0 2.5rem;
    border-bottom: 0.1rem solid #616161;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p {
  font-size: 3.733vw;
  line-height: 1.3;
  margin: 0;
  padding: 2.4vw 0 1.6vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p {
    width: 58.5rem;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 2.3rem 0 .6rem;
    margin: auto;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol {
  margin: 0;
  padding: 4vw 0;
  list-style-type: none;
  line-height: 1.3;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol {
    width: 58.5rem;
    font-size: 1.6rem;
    padding: 2rem 0 1.8rem;
    margin: auto;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li {
  counter-increment: custom-counter;
  position: relative;
  padding: 7.7vw 0 4.8vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li {
    padding: 3.7rem 0 2.4rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li:before {
  content: counter(custom-counter);
  color: #fff;
  font-size: 2.933vw;
  width: 6.134vw;
  line-height: 2.2;
  text-indent: .2vw;
  margin: -0.6rem auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-dark_grey.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li:before {
    width: 3.4rem;
    height: 3.4rem;
    font-size: 1.6rem;
    line-height: 2.2;
    text-indent: .1rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table {
  width: 100%;
  border-collapse: inherit;
  overflow: hidden;
  line-height: 1.3;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th {
  font-size: 3.733vw;
  font-weight: normal;
  color: #fff;
  padding: 3.7vw 0 3.6vw;
  background-color: #595036;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th {
    font-size: 1.6rem;
    padding: 1.8rem 0 1.7rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th:first-child {
  width: 39%;
  border-right: 0.2rem solid #1f1f1f;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th:first-child {
    width: 40%;
    border-right: 0.1rem solid #1f1f1f;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr:nth-child(even) {
  background-color: #f1f2f2;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td {
  font-size: 3.2vw;
  color: #1f1f1f;
  padding: 3.45vw 0 3.2vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td {
    font-size: 1.5rem;
    padding: 1.6rem 0 1.6rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td:first-child {
  border-right: 0.2rem solid #595036;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td:first-child {
    border-right: 0.1rem solid #595036;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper {
  max-height: 570vw;
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s linear;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper {
    max-height: 268rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper .expand-action-wrapper {
  text-align: center;
  height: 15rem;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -moz-linear-gradient(top, rgba(29, 29, 29, 0) 0%, rgba(29, 29, 29, 0) 1%, #1d1d1d 90%, #1d1d1d 100%);
  background: -webkit-linear-gradient(top, rgba(29, 29, 29, 0) 0%, rgba(29, 29, 29, 0) 1%, #1d1d1d 90%, #1d1d1d 100%);
  background: linear-gradient(to bottom, rgba(29, 29, 29, 0) 0%, rgba(29, 29, 29, 0) 1%, #1d1d1d 90%, #1d1d1d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001d1d1d', endColorstr='#1d1d1d', GradientType=0);
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper .expand-action-wrapper .btn {
  position: relative;
  bottom: -7rem;
  background: #fff;
  display: inline-block;
  text-transform: uppercase;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active {
  padding-bottom: 16rem;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active .simple-custom-table {
  margin-bottom: 14rem;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active .expand-action-wrapper {
  bottom: -1rem;
}
.eurojackpot-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active .expand-action-wrapper .btn {
  bottom: -5rem;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordions-container {
  border-radius: 6px;
  overflow: hidden;
  line-height: 1.3;
  margin: 3.2vw auto 4.7vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordions-container {
    width: 52.2rem;
    margin: 2rem auto 1rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .print-button {
  font-size: 0;
  line-height: 0;
  background-color: #616161;
  float: right;
  border-radius: 6px 6px 0 0;
  padding: 2vw 3vw 3vw;
  margin-bottom: -1vw;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s ease-out;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .print-button {
    padding: 1rem 1.5rem 2rem;
    margin-bottom: -0.5rem;
    bottom: -0.5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .print-button .icon-print {
  width: 4vw;
  height: 4vw;
  fill: #fff;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .print-button .icon-print {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .print-button:hover {
    transform: translate3d(0, -0.5rem, 0);
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .accordions-header {
  font-size: 3.733vw;
  color: #fff;
  background-color: #616161;
  padding: 3.668vw 0 3.668vw;
  border-radius: 6px 6px 0 0;
  clear: both;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordions-container .accordions-header {
    font-size: 1.6rem;
    padding: 2rem 0 2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper {
  line-height: 1.3;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper {
    border-bottom: 0.1rem solid #616161;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header {
  text-align: left;
  background-color: transparent;
  padding: 4.16vw 8vw 4.11vw 4vw;
  border-width: 0 .2rem .2rem .2rem;
  border-style: solid;
  border-color: #616161;
  position: relative;
  cursor: pointer;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header {
    padding: 0.5rem 3rem 1.8rem 2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .title {
  font-size: 3.733vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .title {
    font-size: 1.5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon {
  width: 3.466vw;
  height: 2.733vw;
  position: absolute;
  top: 5.9vw;
  right: 4.266vw;
  fill: #000000;
  overflow: visible;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon {
    width: 1.7rem;
    height: 1.7rem;
    top: 2.5rem;
    right: 1.9rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon use {
  transition: transform .25s linear, opacity .25s linear;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .arrow-down {
  transform: translateY(-0.3vw);
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .arrow-down {
    width: 1.5rem;
    height: .9rem;
    transform: translateY(-0.3rem);
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .close {
  opacity: 0;
  transform: translateY(-150%);
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .close {
    width: 1.7rem;
    height: 1.7rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body {
  max-height: 0;
  overflow: hidden;
  border-width: 0 .2rem 0 .2rem;
  border-style: solid;
  border-color: #616161;
  transition: max-height 0.25s linear;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-headline {
  text-align: center;
  font-size: 3.466vw;
  padding: 0 4vw 5.6vw 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-headline {
    font-size: 1.6rem;
    padding: 0 2rem 2.1rem 2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table {
  width: 100%;
  line-height: 1.3;
  font-size: 3.2vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table {
    font-size: 1.5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr {
  border-bottom: .2rem solid #f1f2f2;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr {
    border-bottom: .1rem solid #f1f2f2;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td {
  padding: 2.6vw 0 3.9vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td {
    padding: 1.4rem 0 1.7rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td span {
  display: none;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td span {
    display: inline-block;
    padding-left: .5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:first-child td {
  padding-top: 2.5vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:first-child td {
    padding-top: 1.2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:last-child td {
  padding-bottom: 3.4vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:last-child td {
    padding-bottom: 1.7rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td {
  width: 17.25%;
  padding-top: 1vw;
  padding-bottom: .9vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td {
    width: 18.5%;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td:last-child {
  width: 31%;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td:last-child {
    width: 26%;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table {
  width: 100%;
  line-height: 1.3;
  font-size: 3.733vw;
  color: #616161;
  text-align: left;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table {
    font-size: 1.5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td {
  width: 78%;
  padding: 2.8vw 0 2.8vw 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td {
    width: 88%;
    padding: 1.6rem 0 1.6rem 2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td:last-child {
  width: 22%;
  padding: 2.8vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td:last-child {
    width: 12%;
    padding: 1.6rem 0;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td {
  padding: 4.5vw 0 3.3vw 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td {
    padding: 1.6rem 0 1.6rem 2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td:last-child {
  padding: 4.5vw 0 3.3vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td:last-child {
    padding: 1.6rem 0;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td {
  padding: 3.3vw 0 4vw 4vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td {
    padding: 1.6rem 0 1.6rem 2rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td:last-child {
  padding: 3.3vw 0 4vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td:last-child {
    padding: 1.6rem 0;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper {
  text-align: center;
  color: #fff;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-navigation {
  width: 100%;
  table-layout: fixed;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-navigation .accordion-tab-button {
  font-size: 3.733vw;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  padding: 4.33vw 0 4.34vw 0;
  user-select: none;
  background-color: #f2f2f2;
  color: #616161;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-navigation .accordion-tab-button {
    font-size: 1.6rem;
    padding: 2.2rem 0 1.8rem 0;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-navigation .accordion-tab-button:first-child {
  border-radius: 0 6px 0 0;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-navigation .accordion-tab-button:last-child {
  border-radius: 6px 0 0 0;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-navigation .accordion-tab-button.active {
  background-color: #616161;
  color: #fff;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body {
  background-color: #616161;
  padding: 1.3vw 3.333vw 0.3vw 3.333vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body {
    padding: 1.2rem 2.5rem 1.1rem 2.5rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body .accordion-tab-table {
  width: 100%;
  font-size: 3.2vw;
  table-layout: fixed;
  display: none;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body .accordion-tab-table {
    font-size: 1.4rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body .accordion-tab-table.active {
  display: table;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body .accordion-tab-table tr td {
  border-bottom: 0.1rem solid #1f1f1f;
  padding: 1.8vw 0 3.2vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body .accordion-tab-table tr td {
    padding: 1.4rem 0 1.3rem 0;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-tabs-wrapper .accordion-tabs-body .accordion-tab-table tr:last-child td {
  border-bottom: none;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-header {
  border-width: 0 .2rem 0 .2rem;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-header .icon .arrow-down {
  opacity: 0;
  transform: translateY(150%);
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-header .icon .close {
  opacity: 1;
  transform: translateY(-0.4rem);
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-body {
  border-width: 0 .2rem .2rem .2rem;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child {
  border-bottom: none;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child .accordion-header {
  border-radius: 0 0 6px 6px;
  transition: border-radius 0s 0.25s linear, border-width 0s 0.25s linear;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child .accordion-body {
  border-radius: 0 0 6px 6px;
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child .accordion-body .accordion-table tbody tr:last-child td {
  padding-bottom: 3.6vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child .accordion-body .accordion-table tbody tr:last-child td {
    padding-bottom: 1.8rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child.active .accordion-header {
  border-radius: 0;
  transition: border-radius 0s 0s linear, border-width 0s 0s linear;
}
.eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container {
  width: 84.535%;
  margin: auto;
  font-size: 0;
  line-height: 0;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container {
    width: 83.2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container {
    width: 88%;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .row-header {
  margin: 2.6vw 0 1.3vw;
  padding-bottom: 1.934vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .row-header {
    margin: 2.6rem 0 2rem;
    padding-bottom: 2.3rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .row-header .number-of-rows {
  clear: both;
  display: block;
  padding-left: 0vw;
  opacity: .6;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .row-header .number-of-rows {
    display: inline;
    padding-left: 1vw;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .number-picker-container {
  text-align: left;
}
.eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .number-picker-container .buttons-container {
  padding: 4.034vw 0 3.734vw;
  text-align: center;
}
@media (min-width: 950px) {
  .eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .number-picker-container .buttons-container {
    padding: 2.9rem 0 3rem;
  }
}
.eurojackpot-game-tabs-wrapper .tabs-container .game-container .rows-container .number-picker-container .buttons-container span {
  float: none;
}
.eurojackpot-lucky-game .eurojackpot-lucky-game-section .eurojackpot-content-wrapper .rows-container .row-container .row-header {
  padding-top: 2.05vw;
  padding-bottom: 4.45vw;
}
@media (min-width: 950px) {
  .eurojackpot-lucky-game .eurojackpot-lucky-game-section .eurojackpot-content-wrapper .rows-container .row-container .row-header {
    padding-top: 0;
    padding-bottom: 2.1rem;
  }
}
