@import url("../Mixins/_mixins.less");

.eurojackpot-receipt {
  .eurojackpot-section {
    .eurojackpot-content-wrapper {
      .receipt-confirmation {
        position: relative;
        padding: 5vw;

        @media (min-width: @eurojackpot-desktop-width) {
          padding: 4rem;
        }

        &:after {
          content: "";
          width: 86.67vw;
          height: 0.27vw;
          background-color: @eurojackpot-darkgold;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);

          @media (min-width: @eurojackpot-desktop-width) {
            width: 96rem;
            height: 0.1rem;
          }
        }

        svg.receipt-icon {
          width: 13.33vw;
          height: 13.33vw;
          margin-bottom: 4.67vw;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
            margin-bottom: 1.5rem;
          }
        }

        .receipt-text {
          max-width: 60vw;
          font-size: 3.47vw;
          line-height: 1.3;
          color: @eurojackpot-white;
          margin: 0 auto 1.5rem;

          @media (min-width: @eurojackpot-desktop-width) {
            max-width: 100%;
            font-size: 1.8rem;
            line-height: 2.8rem;
          }
        }

        .reminder-alert {
          margin: 2rem;
          color: white;
          font-size: 3vw;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 1.6rem;
          }

          input[type=checkbox] {
            display: none;

            &:checked + label .checkbox:after {
              content: '';
              position: absolute;
              border: 0.3vw solid black;
              border-left: 0;
              border-top: 0;
              transform: rotate(45deg);
              font-size: 3.4vw;
              left: 0.3em;
              top: 0.1em;
              width: 0.3em;
              height: 0.5em;

              @media (min-width: @eurojackpot-desktop-width) {
                border: 0.2rem solid black;
                border-left: 0;
                border-top: 0;
                font-size: 2rem;
              }

            }
          }

          label {
            background: #444;
            display: inline-block;
            padding: 4vw 4vw 4vw 10vw;
            border-radius: 0.5rem;
            position: relative;
            text-align: left;
            line-height: 1.5;
            user-select: none;

            @media (min-width: @eurojackpot-desktop-width) {
              padding: 2.5rem 2.5rem 2.5rem 6rem;
            }

            .checkbox {
              background: white;
              width: 3.5vw;
              height: 3.5vw;
              left: 4vw;
              top: 4.5vw;
              border-radius: 0.4rem;
              content: '';
              display: inline-block;
              vertical-align: text-top;
              border: 0.1rem solid #a0a0a0;
              position: absolute;

              @media (min-width: @eurojackpot-desktop-width) {
                width: 2.0rem;
                height: 2.0rem;
                left: 2.5rem;
                top: 2.6rem;
              }
            }
          }

          &.reminder-alert--loading label {
            overflow: hidden;

            &:before {
              position: absolute;
              content: "";
              top: -50%;
              bottom: -50%;
              left: -200%;
              width: 3rem;
              background-color: rgba(255, 255, 255, 0.05);
              animation: reminder-alert--loading 1000ms linear infinite;
              transform: rotate(20deg);
            }

            &:after {
              position: absolute;
              content: "";
              top: -50%;
              bottom: -50%;
              left: -200%;
              width: 1.5rem;
              background-color: rgba(255, 255, 255, 0.05);
              animation: reminder-alert--loading 1000ms linear infinite;
              animation-delay: 500ms;
              transform: rotate(20deg) translateX(-2rem);
            }

            @keyframes reminder-alert--loading {
              100% {
                left: 300%;
              }
            }
          }

        }

        .go-to-coupon {
          cursor: pointer;
          position: relative;
          display: inline-block;

          &:after {
            content: "";
            width: 100%;
            height: 0.27vw;
            background-color: @eurojackpot-lightgray;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            margin-top: 0;

            @media (min-width: @eurojackpot-desktop-width) {
              height: 0.1rem;
            }
          }
        }
      }
    }
  }
}
