.eurojackpot-promotion-spot .eurojackpot-section {
  position: relative;
}
.eurojackpot-promotion-spot .eurojackpot-section > * {
  position: relative;
  z-index: 2;
}
.eurojackpot-promotion-spot .eurojackpot-image-wrapper {
  padding: 5vh;
}
.eurojackpot-promotion-spot .btn {
  margin: 3vh;
}
