﻿@import url("../../Mixins/_mixins.less");

.statistics-module {
  .statistics-module-wrapper {
    .statistics-module-large-view {
      overflow: hidden;
      padding: 4vw 0;
      position: relative;
      background-color: #eee;

      @media (min-device-width: @desktop-width) {
        padding: 3rem 0 4.5rem;
        background-color: transparent;
      }

      .statistics-module-large-internal-wrapper {
        width: 80%;
        transition: transform .25s linear;
        box-sizing: border-box;
        position: relative;
        left: 10%;
        display: block;

        @media (min-device-width: @desktop-width) {
          width: inherit;
          min-width: 100%;
          height: 25rem;
          padding: 0 15rem;
          display: inline-block;
          white-space: nowrap;
          text-align: center;
          left: inherit;
        }

        &:before {
          @media (min-device-width: @desktop-width) {
            content: '';
            width: 100%;
            height: 6px;
            background-color: #c9c9c9;
            position: absolute;
            bottom: -6px;
            left: 0;
          }
        }

        .statistics-large-bar-wrapper {
          height: 8vw;
          position: relative;
          display: block;

          @media (min-device-width: @desktop-width) {
            height: 100%;
            width: 3.5rem;
            display: inline-block;
          }

          &:after {
            @media (min-device-width: @desktop-width) {
              content: '';
              height: 6px;
              background-color: #c9c9c9;
              position: absolute;
              bottom: -6px;
              left: -.35rem;
              right: -.35rem;
              z-index: 1;
            }
          }

          &:first-child {
            &:after {
              left: 0;
            }
          }

          &:last-child {
            &:after {
              right: 0;
            }
          }

          &:first-child, &:nth-child(5n) {
            &:before {
              @media (min-device-width: @desktop-width) {
                content: '';
                width: 1px;
                height: 100%;
                background-color: #c9c9c9;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }

          .bar {
            height: 80%;
            border-radius: 0 0 0 0;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: .4vw;
            transition: background-color .15s linear, height .3s linear, width .3s linear;
            cursor: pointer;

            @media (min-device-width: @desktop-width) {
              width: 80%;
              border-radius: 6px 6px 0 0;
              top: inherit;
              left: 0;
              right: 0;
            }

            .amount {
              width: 9vw;
              text-align: center;
              line-height: 1.6;
              padding: 0 3vw;
              border-radius: 0 6px 6px 0;
              position: absolute;
              right: -9vw;
              z-index: 0;
              transition: background-color .15s linear, color .15s linear;
              box-sizing: border-box;

              @media (min-device-width: @desktop-width) {
                width: 100%;
                line-height: normal;
                color: inherit;
                padding: .2rem 0 .6rem;
                border-radius: 6px 6px 0 0;
                top: .6rem;
                right: inherit;
                transform: translateY(-100%);
              }

              &:before {
                @media (min-device-width: @desktop-width) {
                  content: '';
                  width: 100%;
                  height: .6rem;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  border-radius: 6px 6px 0 0;
                  transition: background-color .15s linear;
                }
              }
            }

            .icon-most-drawn, .icon-least-drawn {
              width: 3.5vw;
              height: 5vw;
              margin: auto;
              position: absolute;
              top: .5vw;
              left: 2vw;

              @media (min-device-width: @desktop-width) {
                width: 1.2rem;
                height: 1.8rem;
                top: inherit;
                left: 0;
                right: 0;
              }
            }

            .icon-most-drawn {
              fill: #fff;

              @media (min-device-width: @desktop-width) {
                top: .5rem;
              }
            }

            .icon-least-drawn {
              fill: #171717;
              left: auto;
              right: -14vw;

              @media (min-device-width: @desktop-width) {
                top: -4.5rem;
                left: 0;
                right: 0;
              }
            }
          }

          .number {
            font-size: 2.8vw;
            line-height: 2.6;
            text-align: center;
            font-weight: bold;
            box-sizing: border-box;
            position: absolute;
            left: -8vw;
            bottom: .5vw;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            transition: transform .15s linear, background-color .15s linear, color .15s linear;

            @media (min-device-width: @desktop-width) {
              font-size: inherit;
              line-height: inherit;
              font-weight: normal;
              left: inherit;
              bottom: -1.3rem;
              transform: translateY(100%);
            }
          }
        }
      }

      .statistics-module-graph-slide-button {
        width: 10vw;
        height: 100%;
        background: red;
        position: absolute;
        top: 0;
        z-index: 1;
        display: none;
        cursor: pointer;
        transition: transform .15s linear;

        @media (min-device-width: @desktop-width) {
          display: block;
        }

        &.right {
          right: 0;
          transform: translateX(100%);

          &.active {
            transform: translateX(0);

            &:hover {
              @media (min-device-width: @desktop-width) {
                transform: translateX(10%);
              }

              .icon {
                @media (min-device-width: @desktop-width) {
                  transform: translate(-50%,-50%);
                }
              }
            }
          }
        }

        &.left {
          left: 0;
          transform: translateX(-100%);

          &.active {
            transform: translateX(0);

            &:hover {
              @media (min-device-width: @desktop-width) {
                transform: translateX(-10%);
              }

              .icon {
                @media (min-device-width: @desktop-width) {
                  transform: translate(-50%,-50%);
                }
              }
            }
          }
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(0, -50%);
          transition: transform .15s linear;

          @media (min-device-width: @desktop-width) {
            width: 4rem;
            height: 6rem;
          }
        }
      }
    }
  }
}
