﻿@import url("../Mixins/_mixins.less");

.confirm-page {
  * {
    box-sizing: border-box;
  }

  .confirm-page__extra-campaigns {
    width: ~"calc(100% - 4rem)";
    max-width: 49rem;
  
    @media @lg {
      max-width: 54rem;
    }

    .confirm-page__collapsible {
      max-width: 100%;
      width: 100%;

      .confirm-page__collapsible-top-row-text {
        text-align: right;
        color: @lotto-red;
        flex: 0;
      }

      .confirm-page__collapsible-top-row-highlight-text {
        flex: 1;
      }

      .confirm-page__collapsible-top-row-read-more {
        font-size: 1.2rem;
        line-height: 2rem;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .confirm-page__collapsible-top-row-icon {
      color: @lotto-red;
    }
  }
  .confirm-page__extra-campaigns-headline {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 700;
    padding-top: 2.2rem;
  }
}

.confirm-page__wrapper {
  background: @ds-white;
  padding: 2rem 0 0;
  box-sizing: border-box;

  .confirm-page__content {
    background-color: transparent;
    padding: 0;
  }

  @media @lg {
    padding-top: 4rem;
  }
}

.confirm-page__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: @ds-white;
  padding: 2rem 0 0;
}

.confirm-page__title {
  margin: 0 2rem 2rem;
  font-size: 2.3rem;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  color: @ds-black;

  @media @sm {
    font-size: 2.5rem;
  }

  @media @lg {
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }
}

.confirm-page__collapsible {
  width: ~"calc(100% - 4rem)";
  max-width: 49rem;
  border-bottom: 0.2rem dashed @numbergames-extra-lightgray;
  font-size: 1.6rem;
  line-height: 1.2;

  @media @lg {
    max-width: 54rem;
  }
}

.confirm-page__collapsible-top {
  position: relative;
  height: 5.8rem;
  display: flex;
  align-items: center;

  .confirm-page__collapsible--can-open & {
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      transition: transform 100ms ease-out;
      height: 1.6rem;
      width: 1.6rem;
      right: 0;
      top: 2.1rem;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNDA0MyAxLjk2NzlMNy4yNzAwOCA4LjE4NDUyQzcuNjY5MTQgOC42MDc0NCA4LjM0MzUxIDguNjAyMDEgOC43MzU3IDguMTcyNzJMMTQuNDA0MyAxLjk2NzkiIHN0cm9rZT0iIzIyMjIyMiIgc3Ryb2tlLXdpZHRoPSIyLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
    }
  }

  .confirm-page__collapsible--show &::after {
    transform: rotate(0.5turn);
  }
}

.confirm-page__collapsible-bottom {
  transition: transform 200ms cubic-bezier(0, 1.7, 0.3, 1), padding-top 200ms cubic-bezier(0, 1.7, 0.3, 1);
  transform: translateY(-1rem);
  padding: 0;
  height: 0;
  overflow: hidden;

  .confirm-page__collapsible--show & {
    padding: 0 0 2rem;
    height: auto;
    transform: translateY(0);
  }
}

.confirm-page__collapsible-top-row {
  display: flex;
  font-weight: bold;
  align-items: center;
  width: 100%;
}

.confirm-page__collapsible-action-cell {
  flex: 1;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.confirm-page__action-button {
  font-size: 1.4rem;
  padding: 0.3rem 1rem;
  border: 1px solid;
  border-radius: 1.6rem;
  cursor: pointer;
  transition: scale 100ms ease-out;

  &:hover {
    scale: 1.02;
  }
}

.confirm-page__edit-button-wrapper {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 2rem;
  right: -2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: scale 100ms ease-out;

  @media @sm {
    right: -3.2rem;
  }

  &:hover {
    scale: 1.04;
  }
}
.confirm-page__edit-button {
  width: 1.8rem;
  height: 1.8rem;
}

.confirm-page__collapsible-top-row-icon {
  flex: 0 0 5rem;
  margin-bottom: -0.2rem;

  @media @sm {
    flex: 0 0 6rem;
  }
  .confirm-page__collapsible-top--disabled & {
    opacity: 0.6;
  }

  svg.confirm-page__collapsible-icon-draw {
    width: 2.7rem;
    height: 2.6rem;
  }

  svg.confirm-page__collapsible-icon-joker {
    width: 3.6rem;
    height: 2.1rem;
  }

  svg.confirm-page__collapsible-icon-rows {
    width: 2.7rem;
    height: 3.5rem;
  }

  svg.confirm-page__collapsible-icon-calendar {
    width: 2.7rem;
    height: 3.1rem;
  }

  svg.confirm-page__collapsible-icon-stake-per-row {
    width: 3.1rem;
    height: 2.6rem;
  }

  svg.confirm-page__collapsible-icon-campaign {
    width: 3.2rem;
    height: 3.2rem;
    color: currentColor;

    .region-eurojackpot & {
      color: @eurojackpot-gold;
    }

    .region-keno & {
      color: @keno-powderblue;
    }

    .region-lotto & {
      color: @lotto-red;
    }

    .region-vikinglotto & {
      color: @vikinglotto-blue;
    }
  }
}

.confirm-page__collapsible-top-row-highlight-text {
  flex: 0 0 11rem;
  padding-right: 1rem;
  color: @ds-black;

  .confirm-page__collapsible-top--disabled & {
    opacity: 0.6;
  }

  &--full-width {
    flex: auto;
  }

  .region-allornothing & {
    color: @all-or-nothing-mid-purple;
  }
  
  .region-eurojackpot & {
    color: @eurojackpot-gold;
  }

  .region-keno & {
    color: @keno-powderblue;
  }

  .region-lotto & {
    color: @lotto-red;
  }

  .region-vikinglotto & {
    color: @vikinglotto-blue;
  }

  @media @sm {
    flex: 0 0 18rem;

    &--full-width {
      flex: auto;
    }
  }
}

.confirm-page__collapsible-top-row-text {
  flex: 1;

  .confirm-page__collapsible--can-open & {
    padding-right: 2rem;
  }
}

.confirm-page__table-header {
  font-size: 1.6rem;
  padding: 0.2rem 0 0.2rem;
  margin: 0 auto;
  min-height: 3rem;
  display: flex;
  align-items: center;

  @media @sm {
    max-width: 37rem;
  }

  @media @lg {
    max-width: 42rem;
  }
}

.confirm-page__table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.2;

  @media @sm {
    max-width: 37rem;
    margin-left: 6rem;
  }

  @media @lg {
    max-width: 42rem;
  }

  .confirm-page__table-row {
    display: flex;

    &--has-joker {
      background-repeat: no-repeat;
      background-size: 3rem 3rem;
      background-position: calc(~'100% - 1rem') center;

      .region-eurojackpot & {
        background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#eurojackpot-joker_use");
      }

      .region-lotto & {
        background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#lotto-joker_use");
      }

      .region-vikinglotto & {
        background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#vikinglotto-joker_use");
      }
    }
  }

  .confirm-page__table-row:nth-child(odd) {
    background-color: @numbergames-extra-lightgray;
  }

  .confirm-page__table-cell {
    padding: 0.5rem 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 3rem;
    flex: 1;

    &--index {
      flex: 0 0 8rem;
      justify-content: center;
      font-weight: bold;
      text-align: center;
    }

    &--center {
      text-align: center;
      justify-content: center;
    }
  }

  .confirm-page__table-cell-icon-delete {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}

.confirm-page__total {
  width: ~"calc(100% - 4rem)";
  height: 5.8rem;
  max-width: 49rem;
  border-bottom: 0.2rem solid @ds-black;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  font-weight: 800;
  margin-bottom: 1.5rem;

  @media @lg {
    max-width: 54rem;
  }
}

.confirm-page__total-label {
  flex: 0 0 16rem;
  padding-left: 5rem;

  @media @sm {
    flex: 0 0 24rem;
    padding-left: 6rem;
  }
}

.confirm-page__terms {
  max-width: 49rem;
  padding: 0 2rem;
  font-size: 1.3rem;
  line-height: 1.3;
  color: @ds-black;
  text-align: center;
  margin-bottom: 2rem;

  @media @lg {
    margin-bottom: 4rem;
  }

  a {
    color: @ds-black;
    font-weight: bold;
  }
}

//QuickDeposit settings
.confirm-page__go-to-payment-subheader{
  font-size:1.6rem;
  padding:2rem 0;
  color:@ds-black;
  font-weight:600;
  > span{
    color:@ds-black;
    font-weight:700;
  }
}


@keyframes info-message-animation {
  0% {
    transform: scale(1) rotate(0);
  }

  10% {
    transform: scale(1.13) rotate(5deg);
  }

  20% {
    transform: scale(1.13) rotate(-5deg);
  }

  30% {
    transform: scale(1.13) rotate(5deg);
  }

  40% {
    transform: scale(1.13) rotate(-5deg);
  }

  50% {
    transform: scale(1.13) rotate(5deg);
  }

  60% {
    transform: scale(1.13) rotate(-5deg);
  }

  70% {
    transform: scale(1.13) rotate(5deg);
  }

  80% {
    transform: scale(1.13) rotate(-5deg);
  }

  90% {
    transform: scale(1.13) rotate(5deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

.confirm-page__crossed {
  text-decoration: line-through;
}

.confirm-page__info-message {
  background-color: @ds-yellow-100;
  border: 1px solid @ds-yellow-500;
  padding: 1rem 2rem;
  margin: 1rem 0 3rem;
  width: calc(~"100% - 4rem");
  max-width: 49rem;
  font-size: 1.3rem;
  line-height: 1.3;
  color: @ds-black;

  animation: info-message-animation .8s forwards;


  @media @lg {
    max-width: 54rem;
  }
}
