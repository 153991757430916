@import url("../Mixins/_mixins.less");

.two-column-spot {
  @media (min-width: @desktop-width) {
    display: flex;
  }

  .column-wrapper {
    @media (min-width: @desktop-width) {
      width: 50%;
      display: inline-block;
    }

    &.grey {
      background-color: @numbergames-slight-grey;
    }

    &:nth-child(odd) {
      text-align: right;

      .column-spots {

        &.fillcolumn {
          @media (min-width: @desktop-width) {
            padding: 0.8rem 0.8rem 1.6rem 1.6rem;
          }
        }
      }
    }

    &:nth-child(even) {
      text-align: left;

      .column-spots {

        &.fillcolumn {
          @media (min-width: @desktop-width) {
            padding: 0.8rem 1.6rem 1.6rem 0.8rem;
          }
        }
      }
    }

    .column-spots {
      @media (min-width: @desktop-width) {
        display: inline-block;
        box-sizing: border-box;
      }

      &.center {
        @media (min-width: @desktop-width) {
          width: 37rem;
          padding: 1.5rem 2rem 2rem;
          margin-bottom: 0;
        }
      }

      &.fillcolumn {
        padding: 0 0 4vw;

        @media (min-width: @desktop-width) {
          width: 48rem;
          padding: 1.6rem;
          margin-bottom: 0;
        }
      }

      &.justify {
        @media (min-width: @desktop-width) {
          width: 50rem;
          padding: 0;
          margin-bottom: -4px;
        }
      }
    }
  }
}
