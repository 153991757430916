﻿@import url("../../Mixins/_mixins.less");

.region-vikinglotto {
  .two-column-spot {

    .column-wrapper.separated {
      &:nth-child(2n+1) {
        @media (min-width: @desktop-width) {
          border-right: .1rem solid @vikinglotto-blue;
        }
      }
    }

    .column-wrapper.fill {
      &:nth-child(2n+0) {
        background-color: @vikinglotto-darkblue;
      }

      &:nth-child(2n+1) {
        background-color: @vikinglotto-mediumblue;
      }
    }
  }
}
