@import "_imports.less";

.header-subtitle {
  background: @numbergames-white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0;
  text-align: center;
  padding-top: 4rem;
  position: relative;
}

.header-subtitle__header {
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin-bottom: 0;
  padding: 0 1rem;
  color: @numbergames-black;
  font-weight: 900;
  margin-top: 0;
  overflow-wrap: break-word;

  @media @sm {
    font-size: 4rem;
    line-height: 4.6rem;
    margin-bottom: 1.6rem;
  }
}

.header-subtitle__sub-header {
  font-size: @font-size--sm;
  line-height: 2.2rem;
  padding: 0 3rem;
  color: @keno-light-grey;
  font-weight: 600;
  overflow-wrap: break-word;
  margin: 0;

  @media @sm {
    padding: 0 19rem;
    font-size: @font-size;
    line-height: 2.4rem;
  }

  @media @md {
    padding: 0 34%;
  }
}
