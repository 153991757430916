@import (reference) "../../Mixins/_mixins.less";

.multi-client-footer {
  min-width: 18rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  transition: transform 50ms linear;
  position: sticky;
  bottom: 0;

  &::after {
    content: '';
    width: 100vw;
    height: 130%;
    pointer-events: none;
    background: linear-gradient(0deg, fade(@ds-white, 100) 80%, fade(@ds-white, 0) 100%);
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50vw) translateY(30%);
    z-index: -1;
    transition: transform 100ms linear;
  }

  &.sticky {
    &::after {
      transform: translateX(-50vw) translateY(0);
    }
  }

  .multi-client__progress-bar {
    padding: 0.8rem;
    margin: 0 3rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    &::before {
      content: '';
      height: 0.2rem;
      background-color: @ds-gray-100;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.8rem;
      right: 0.8rem;
    }

    .multi-client__progress-bar-dot {
      width: 0.6rem;
      height: 0.6rem;
      background-color: @ds-gray-300;
      position: relative;
      border-radius: 50%;
      margin: 0 -0.6rem;
      pointer-events: none;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        border-radius: 50%;
        transition: opacity .15s linear, transform .2s linear;
      }

      &::before {
        width: 1.6rem;
        height: 1.6rem;
        background-color: @ds-yellow;
      }

      &::after {
        width: 0.6rem;
        height: 0.6rem;
        background-color: @ds-white;
      }

      &.multi-client__progress-bar-dot--active {
        pointer-events: all;
        cursor: pointer;
        &::before,
        &::after {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }

        &:hover {
          &::before {
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
      }
    }

    .multi-client__progress-bar-active-bar {
      height: 0.2rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      transition: width 0.1s linear;

      &::before {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0.3rem;
        right: 0;
        background-color: @ds-yellow;
      }
    }
  }
}
