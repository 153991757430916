.multi-client-wrapper__custom-campaign {
  --multiclient-campaign-primary-color-l-remainder: (100 - var(--multiclient-campaign-primary-color-l));
  --multiclient-campaign-primary-color-100: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-primary-color-300: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-primary-color-900: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) - (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-secondary-color-l-remainder: (100 - var(--multiclient-campaign-secondary-color-l));
  --multiclient-campaign-secondary-color-100: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-secondary-color-300: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-secondary-color-900: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) - (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-tertiary-color-l-remainder: (100 - var(--multiclient-campaign-tertiary-color-l));
  --multiclient-campaign-tertiary-color-100: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-tertiary-color-300: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-tertiary-color-900: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) - (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-primary-hsl-values: var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1%);
  --multiclient-campaign-primary-color-100: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1.6%));
  --multiclient-campaign-primary-color-300: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1.2%));
  --multiclient-campaign-primary-color-500: hsl(var(--multiclient-campaign-primary-hsl-values));
  --multiclient-campaign-primary-color-900: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 0.6%));
  --multiclient-campaign-secondary-hsl-values: var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1%);
  --multiclient-campaign-secondary-color-100: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1.6%));
  --multiclient-campaign-secondary-color-300: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1.2%));
  --multiclient-campaign-secondary-color-500: hsl(var(--multiclient-campaign-secondary-hsl-values));
  --multiclient-campaign-secondary-color-900: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 0.6%));
  --multiclient-campaign-tertiary-hsl-values: var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1%);
  --multiclient-campaign-tertiary-color-100: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1.6%));
  --multiclient-campaign-tertiary-color-300: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1.2%));
  --multiclient-campaign-tertiary-color-500: hsl(var(--multiclient-campaign-tertiary-hsl-values));
  --multiclient-campaign-tertiary-color-900: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 0.6%));
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client-game-selector .game-selector-button.active {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .selector-numbers .number {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .selector-foreground {
  box-shadow: inset 0px 0px 5em var(--multiclient-campaign-primary-color-300);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .selector-foreground .navigation-button .icon {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .selector-background {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .selected-number-wrapper .number {
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__client-game-description .client-game-description__inner {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .number-picker-container .cell .front-face,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .number-picker-container .cell .front-face,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .number-picker-container .cell .front-face,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .number-picker-container .cell .front-face {
  background-color: var(--multiclient-campaign-primary-color-100);
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .number-picker-container .cell .back-face,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .number-picker-container .cell .back-face,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .number-picker-container .cell .back-face,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .number-picker-container .cell .back-face {
  background-color: var(--multiclient-campaign-primary-color-500);
  color: white;
}
@keyframes multiclient-lotto-flip-face-animation {
  0% {
    color: var(--multiclient-campaign-primary-color-500);
    background-color: var(--multiclient-campaign-primary-color-100);
  }
  35% {
    color: var(--multiclient-campaign-primary-color-500);
    background-color: var(--multiclient-campaign-primary-color-100);
  }
  65% {
    color: var(--multiclient-campaign-primary-color-500);
    background-color: var(--multiclient-campaign-primary-color-100);
  }
  80% {
    color: white;
    background-color: var(--multiclient-campaign-primary-color-500);
  }
  100% {
    color: white;
    background-color: var(--multiclient-campaign-primary-color-500);
  }
}
@keyframes multiclient-lotto-reverse-flip-face-animation {
  0% {
    color: white;
    background-color: var(--multiclient-campaign-primary-color-500);
  }
  35% {
    color: white;
    background-color: var(--multiclient-campaign-primary-color-500);
  }
  65% {
    color: white;
    background-color: var(--multiclient-campaign-primary-color-500);
  }
  80% {
    color: var(--multiclient-campaign-primary-color-500);
    background-color: var(--multiclient-campaign-primary-color-100);
  }
  100% {
    color: var(--multiclient-campaign-primary-color-500);
    background-color: var(--multiclient-campaign-primary-color-100);
  }
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation {
  animation-name: multiclient-lotto-flip-face-animation;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation {
  animation-name: multiclient-lotto-reverse-flip-face-animation;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .lotto-section,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-section,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-section,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .lotto-section {
  background-color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .lotto-section .row-header,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-section .row-header,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-section .row-header,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .lotto-section .row-header {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .lotto-section .row-header .header-rules-text,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-section .row-header .header-rules-text,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-section .row-header .header-rules-text,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .lotto-section .row-header .header-rules-text {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .lotto-section .row-header .right-side .row-delete-button .icon {
  fill: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-winning-numbers .lotto-section .number-picker-footer .buttons-container .button {
  background-color: var(--multiclient-campaign-primary-color-500);
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .rows-list-box {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .rows-list-box .toggle-button .button-count {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .rows-list-box .items-box .rows-list-header {
  background-color: var(--multiclient-campaign-primary-color-900);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .rows-list-box .items-box .items-inner-box .item .item-inner .numbers {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .lotto-section .row-header {
  border-bottom-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-classic-game .number-picker-footer {
  border-top-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title {
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
  color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item.active {
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-system-game-header,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-system-game-header {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-system-game-header .game-navigation-item,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-system-game-header .game-navigation-item {
  color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-system-game-header .game-navigation-item.active,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-system-game-header .game-navigation-item.active {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-system-game-header .game-navigation-item.active .tab-navigation-icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-system-game-header .game-navigation-item.active .tab-navigation-icon {
  fill: white;
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .game-switch-wrapper,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .game-switch-wrapper {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .game-switch-wrapper .switch-button.next,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .game-switch-wrapper .switch-button.next {
  background: linear-gradient(to right, hsla(var(--multiclient-campaign-primary-hsl-values), 0) 0%, var(--multiclient-campaign-primary-color-500) 100%);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .game-switch-wrapper .switch-button.prev,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .game-switch-wrapper .switch-button.prev {
  background: linear-gradient(to right, var(--multiclient-campaign-primary-color-500) 0%, hsla(var(--multiclient-campaign-primary-hsl-values), 0) 100%);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-game-tabs-wrapper,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-game-tabs-wrapper {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
  fill: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active {
  background-color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active .tab-navigation-icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active .tab-navigation-icon {
  fill: white;
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-game-tabs-wrapper .tabs-container .accordions-container .accordions-header,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-container .accordions-container .accordions-header,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-game-tabs-wrapper .tabs-container .accordions-container .print-button,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-container .accordions-container .print-button {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-content-wrapper .rows-container:before,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-content-wrapper .rows-container:before {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-system-game .lotto-content-wrapper .rows-container .row-container .row-header:before,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .lotto-lucky-game .lotto-content-wrapper .rows-container .row-container .row-header:before {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .multi-client-draw-options .check-button {
  background-color: hsla(var(--multiclient-campaign-primary-hsl-values), 0.1);
  color: var(--multiclient-campaign-primary-color-300);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .multi-client-draw-options .check-button::before {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .multi-client-draw-options .check-button .check-button__dot {
  border-color: hsla(var(--multiclient-campaign-primary-hsl-values), 0.3);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .multi-client-draw-options .check-button .check-button__dot::after {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .multi-client-draw-options .check-button.check-button--selected {
  color: white;
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .multi-client-draw-options .check-button.check-button--selected .check-button__dot {
  border-color: var(--multiclient-campaign-primary-color-100);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .confirm-page__collapsible-top-row-icon,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .confirm-page__collapsible-top-row-icon svg.confirm-page__collapsible-icon-campaign,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .confirm-page__collapsible-top-row-highlight-text,
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .confirm-page__extra-campaigns .confirm-page__collapsible-top-row-text {
  color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--theming-on .multi-client__client-wrapper .multi-client__flow-wrapper .receipt-step .receipt-step__countdown .countdown {
  background-color: var(--multiclient-campaign-primary-color-500);
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-numbers .number-wrapper {
  width: 28.366em;
  margin-left: -14.183em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-numbers .number {
  color: var(--multiclient-campaign-primary-color-500);
  font-size: 16em;
  line-height: 1.7;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selected-number-wrapper {
  width: 28.366em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selected-number-wrapper .selector-numbers {
  left: -35.3vw;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selected-number-wrapper .selector-numbers {
    left: -56em;
  }
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selected-number-wrapper .selector-numbers .number {
  color: white;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-background,
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground {
  color: var(--multiclient-campaign-primary-color-500);
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-background {
  width: 29.092em;
  height: 40.91em;
  top: 8em;
  background-color: var(--multiclient-campaign-primary-color-500);
  -webkit-mask-image: url('/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons/campaign_rotary_selector_back.svg');
  mask-image: url('/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons/campaign_rotary_selector_back.svg');
  -webkit-mask-mode: alpha;
  mask-mode: alpha;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground {
  width: 29.092em;
  height: 40.91em;
  box-shadow: none;
  top: 8em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground .navigation-button {
  top: 28%;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground .label {
  font-size: 2.9em;
  top: 6.9em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground .multi-client__client-game-description {
  height: 10.8em;
  transform: translateY(0);
  display: inline-block;
  padding: 0 0.4em;
  line-height: 1.2;
  font-size: inherit;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground .multi-client__client-game-description .client-game-description__inner {
  color: white;
  display: inline;
  font-size: 2.6em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground .multi-client__client-game-description .client-game-description__question-button {
  width: 3em;
  height: 3em;
  color: white;
  display: inline-block;
  margin-left: 1em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .selector-foreground .multi-client__client-game-description .client-game-description__question-button .icon {
  margin-top: -0.5em;
  position: relative;
  top: 0.6em;
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description {
  font-size: 5px;
  width: 18em;
  margin-top: -14em;
  margin-bottom: 10em;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description {
    width: 24em;
    margin-top: -18.8em;
  }
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description .client-game-description__inner {
  font-size: 1.5em;
  line-height: 1.1;
  font-weight: 700;
  padding-bottom: 0.4rem;
  color: white;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description .client-game-description__inner {
    font-size: 2em;
  }
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description .client-game-description__inner span {
  font-size: 1.8em;
  display: inline;
  padding-bottom: 0.2rem;
  white-space: pre;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description .client-game-description__inner span {
    font-size: 2em;
    padding-bottom: 0.4rem;
  }
}
.multi-client-wrapper__custom-campaign--custom-graphics-on .multi-client__client-wrapper .multi-client-rotary-selector__outer + .multi-client__client-game-description .client-game-description__inner span::after {
  content: '\A';
  margin-bottom: -0.8rem;
}
.campaign-overlay__custom-campaign {
  --multiclient-campaign-primary-color-l-remainder: (100 - var(--multiclient-campaign-primary-color-l));
  --multiclient-campaign-primary-color-100: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-primary-color-300: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) + (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-primary-color-900: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc((var(--multiclient-campaign-primary-color-l) - (var(--multiclient-campaign-primary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-secondary-color-l-remainder: (100 - var(--multiclient-campaign-secondary-color-l));
  --multiclient-campaign-secondary-color-100: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-secondary-color-300: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) + (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-secondary-color-900: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc((var(--multiclient-campaign-secondary-color-l) - (var(--multiclient-campaign-secondary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-tertiary-color-l-remainder: (100 - var(--multiclient-campaign-tertiary-color-l));
  --multiclient-campaign-tertiary-color-100: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.8)) * 1%));
  --multiclient-campaign-tertiary-color-300: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) + (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-tertiary-color-900: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc((var(--multiclient-campaign-tertiary-color-l) - (var(--multiclient-campaign-tertiary-color-l-remainder) * 0.4)) * 1%));
  --multiclient-campaign-primary-hsl-values: var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1%);
  --multiclient-campaign-primary-color-100: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1.6%));
  --multiclient-campaign-primary-color-300: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 1.2%));
  --multiclient-campaign-primary-color-500: hsl(var(--multiclient-campaign-primary-hsl-values));
  --multiclient-campaign-primary-color-900: hsl(var(--multiclient-campaign-primary-color-h), calc(var(--multiclient-campaign-primary-color-s) * 1%), calc(var(--multiclient-campaign-primary-color-l) * 0.6%));
  --multiclient-campaign-secondary-hsl-values: var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1%);
  --multiclient-campaign-secondary-color-100: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1.6%));
  --multiclient-campaign-secondary-color-300: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 1.2%));
  --multiclient-campaign-secondary-color-500: hsl(var(--multiclient-campaign-secondary-hsl-values));
  --multiclient-campaign-secondary-color-900: hsl(var(--multiclient-campaign-secondary-color-h), calc(var(--multiclient-campaign-secondary-color-s) * 1%), calc(var(--multiclient-campaign-secondary-color-l) * 0.6%));
  --multiclient-campaign-tertiary-hsl-values: var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1%);
  --multiclient-campaign-tertiary-color-100: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1.6%));
  --multiclient-campaign-tertiary-color-300: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 1.2%));
  --multiclient-campaign-tertiary-color-500: hsl(var(--multiclient-campaign-tertiary-hsl-values));
  --multiclient-campaign-tertiary-color-900: hsl(var(--multiclient-campaign-tertiary-color-h), calc(var(--multiclient-campaign-tertiary-color-s) * 1%), calc(var(--multiclient-campaign-tertiary-color-l) * 0.6%));
}
.campaign-overlay__custom-campaign .panel {
  background-color: var(--multiclient-campaign-tertiary-color-500, var(--multiclient-campaign-primary-color-500));
  color: white;
}
.campaign-overlay__custom-campaign .panel .generic-overlay__close-button .icon {
  fill: white;
}
.campaign-overlay__custom-campaign .panel .icon-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
.campaign-overlay__custom-campaign .generic-overlay__cta-button {
  color: white;
}
.campaign-overlay__custom-campaign .generic-overlay__cta-button::after {
  background-color: var(--multiclient-campaign-secondary-color-500);
  border-color: white;
}
