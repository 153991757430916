﻿@import (reference) "../_imports";
@import "../Mixins/mixins";

.tutorial__searchbar {
  background-color: @color-white;
  height: 5rem;
  position: relative;
  margin: 4rem 1.5rem 2.7rem 1.5rem;
  display: flex;
  align-items: center;
  padding-left: 1.6rem;
  border-radius: 2rem;
  box-shadow: 0.2rem 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.15);

  @media @sm {
    margin: 5.2rem auto;
    width: 55rem;
  }

  @media @lg {
    margin: 5.8rem auto;
    width: 63.2rem;
  }

  .tutorial__searchbar-input {
    border: none;
    margin-left: 1.2rem;
    width: ~"calc(100% - 7rem)";

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--tutorial-grey);
      font-style: italic;
      font-weight: normal;

      @media @lg {
        font-size: 2rem;
      }
    }
  }

  .tutorial__searchbar-loop {
    transform: rotate(270deg);
    height: 2.2rem;
    width: 2.2rem;
    stroke: @ds-black;
    stroke-width: 0.3rem;
    fill: none;
  }

  .tutorial__searchbar-clear {
    position: relative;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    
    &::after,
    &::before {
      content: '';
      width: 2rem;
      height: 0.2rem;
      background-color: @ds-black;
      display: block;
      transform-origin: center;
      top: 50%;
      position: absolute;
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}
