@import (reference) "../../Mixins/_mixins.less";

// Package card:
.multi-packages-package-card__outer {
  padding: 0 0.4rem 0.4rem 0;
  transition: transform 0.15s ease-in-out;

  & + .multi-packages-package-card__outer {
    padding-left: 2rem;
    @media @sm {
      padding-left: 3rem;
    }
  }

  &.tns-item {
    transform: translateY(3rem);
    @media @sm {
      transform: translateY(0);
    }

    &.multi-packages-package-card--in-focus {
      transform: translateY(0);
    }
  }
}

.multi-packages-package-card {
  width: 28rem;
  background-color: @ds-gray-100;
  border-radius: 32px;
  flex-shrink: 0;
  box-shadow: 4px 4px 0 @ds-gray-175;
  position: relative;
  overflow: hidden;
}

.multi-packages-package-card__ribbon {
  width: 14rem;
  background-color: @ds-red;
  color: @ds-white;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0.5rem 4rem 0.3rem 3.2rem;
  box-sizing: border-box;
  position: absolute;
  top: 1rem;
  left: -3.3rem;
  rotate: -30deg;

  & + .multi-packages-package-card__header .multi-packages-package-card__tagline {
    padding: 0 2rem;
  }
}

// Package card header:
.multi-packages-package-card__header {
  padding: 3.2rem 1.6rem 1.6rem;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
}
.multi-packages-package-card__tagline {
  font-size: 1.4rem;
  line-height: 1.6rem;

  & + .multi-packages-package-card__title {
    margin-top: 0.8rem;
  }
}
.multi-packages-package-card__title {
  font-size: 3.4rem;
  line-height: 4rem;
}

// Package card body:
.multi-packages-package-card__body {
  padding: 0 1.6rem;
}
.multi-packages-package-card__coupons-list {
  .multi-packages-package-card__coupon-card + .multi-packages-package-card__coupon-card {
    margin-top: 1.6rem;
  }
}
.multi-packages-package-card__coupon-card {
  background-color: @ds-white;
  padding: 1.6rem;
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &--allornothing .multi-packages-coupon-card__row {
    color: @ds-dustypurple-900;
  }
  &--lotto .multi-packages-coupon-card__row {
    color: @ds-red;
  }
  &--vikinglotto .multi-packages-coupon-card__row {
    color: @ds-blue;
  }
  &--eurojackpot .multi-packages-coupon-card__row {
    color: @ds-honey;
  }
  &--keno .multi-packages-coupon-card__row {
    color: @ds-light-blue;
  }

  &.multi-packages-package-card__coupon-card--disabled {
    opacity: 0.6;
  }

  .multi-packages-coupon-card__draw-wrapper + .multi-packages-coupon-card__draw-wrapper{
    margin-top: 1rem;
    border-top: 1px dashed @ds-gray-100;
    padding-top: 0.2rem;
  }

  .multi-packages-coupon-card__row {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 0.8rem;
  }

  .multi-packages-coupon-card__row-icon {
    width: 2.5rem;
    height: 1.8rem;
  }

  .multi-packages-coupon-card__row-label {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 700;
    opacity: 0.6;
    margin-left: 0.8rem;
    text-transform: capitalize;
  }

  .multi-packages-coupon-card__row-value {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 700;
    margin-left: auto;
  }

  .multi-packages-coupon-card__header-row {
    padding-top: 0.4rem;
    padding-bottom: 0.8rem;
    border-bottom-width: 1px;
    border-bottom-color: @ds-gray-100;
    border-bottom-style: solid;

    .multi-packages-coupon-card__row-value {
      font-size: 1.4rem;
      font-weight: 800;
      color: @ds-black;
    }
  }
  .multi-packages-coupon-card__header-row--no-open-draw {
    &::after {
      content: '';
      height: 1px;
      background-color: @ds-black;
      position: absolute;
      top: 1.6rem;
      left: -0.6rem;
      right: -0.6rem;
      rotate: -1deg;
    }
  }

  .multi-packages-coupon-card__game-icon {
    height: 1.6rem;

    &--allornothing {
      width: 8.2rem;
    }
    &--lotto {
      width: 6.8rem;
    }
    &--vikinglotto {
      width: 8.2rem;
    }
    &--eurojackpot {
      width: 9.9rem;
    }
    &--keno {
      width: 5.1rem;
    }
  }

  .multi-packages-coupon-card__draw-row {
    color: @ds-black;
    .multi-packages-coupon-card__row-label,
    .multi-packages-coupon-card__row-value {
      font-weight: 400;
    }
  }

  .multi-packages-coupon-card__joker-row {
    color: @ds-black;

    .multi-packages-coupon-card__row-label {
      opacity: 1;
      display: flex;
      align-items: center;
    }
  }

  .multi-packages-coupon-card__joker-row--no-open-draw {
    opacity: 0.6;

    &::after {
      content: '';
      height: 1px;
      background-color: @ds-black;
      position: absolute;
      top: 1.8rem;
      left: -0.6rem;
      right: -0.6rem;
      rotate: -1deg;
    }
  }

  .multi-packages-coupon-card__joker-row-icon {
    height: 1.2rem;
    width: 4.6rem;
  }
  
  .multi-packages-coupon-card__joker-row-name {
    font-weight: 300;
    font-style: italic;
    opacity: 0.8;
    padding-left: 0.2rem;
    text-transform: capitalize;
  }

  &.multi-packages-package-card__coupon-placeholder {
    height: 8.6rem;
    border: 2px dashed @numbergames-blue-grey;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '+';
      width: 4rem;
      height: 4rem;
      font-size: 2.8rem;
      color:  @numbergames-blue-grey;
      border-radius: 50%;
      border: 2px dashed @numbergames-blue-grey;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Package card footer:
.multi-packages-package-card__footer {
  padding: 2.4rem 1.6rem 3.2rem;
  position: sticky;
  bottom: -1.6rem;
}

// Skeleton loader:
.multi-packages-skeleton-loader__wrapper {
  display: flex;
  justify-content: center;
}
@keyframes multi-packages-skeleton-bar-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.multi-packages-skeleton-loader__bar {
  border-radius: 6px;
  background-color: @ds-gray-150;
  margin: auto;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: @ds-gray-125;
    background: linear-gradient(90deg, fade(@ds-gray-125, 0%) 0%, fade(@ds-gray-125, 0%) 40%, fade(@ds-gray-125, 70%) 55%, fade(@ds-gray-125, 95%) 65%, fade(@ds-gray-125, 90%) 75%, fade(@ds-gray-125, 0%) 90%, fade(@ds-gray-125, 0%) 100%);
    animation-name: multi-packages-skeleton-bar-animation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  .multi-packages-package-card__tagline & {
    height: 1.6rem;
    max-width: 12.2rem;
  }
  .multi-packages-package-card__title & {
    height: 4rem;
    max-width: 18.4rem;
  }
  .multi-packages-package-card__body & {
    height: 7rem;
    border-radius: 16px;
  }
  .multi-packages-package-card__footer & {
    height: 5.6rem;
    border-radius: 28px;
  }
}