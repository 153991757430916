/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.winning-numbers-drawn-balls {
  margin-bottom: 0.3rem;
  display: inline-flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .winning-numbers-drawn-balls {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .winning-numbers-drawn-balls__section {
    flex: 1 1 50%;
  }
  .winning-numbers-drawn-balls__section:last-child:not(:first-child) {
    text-align: right;
  }
}
.winning-numbers-drawn-balls__title {
  flex: 0 0 100%;
  order: 10;
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: bold;
  letter-spacing: 0.04rem;
  color: #92969E;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-drawn-balls__title {
    margin-bottom: 1.7rem;
  }
}
.winning-numbers-drawn-balls__balls {
  overflow: hidden;
  box-sizing: border-box;
  display: inline-flex;
  padding: 0.8rem 0.3rem 0.8rem 0.8rem;
  background: #eeeeee;
  height: 5.6rem;
  border-radius: 4rem;
  margin-bottom: 3.7rem;
  transform-origin: left;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-drawn-balls__balls {
    height: 8rem;
    padding-right: 0;
    margin-bottom: 2.9rem;
  }
}
.winning-numbers-drawn-balls__ball {
  position: relative;
  z-index: 0;
  right: -50rem;
  pointer-events: none;
  background-color: #000000;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  color: #e2c000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding-bottom: 0.3rem;
  box-sizing: border-box;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-drawn-balls__ball {
    flex: 0 0 6.4rem;
    height: 6.4rem;
    width: 6.4rem;
    margin-right: 0.8rem;
    font-size: 2rem;
  }
}
.winning-numbers-drawn-balls__balls--lotto .winning-numbers-drawn-balls__ball,
.winning-numbers-drawn-balls__balls--lotto-bonus .winning-numbers-drawn-balls__ball {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-lotto_use");
}
.winning-numbers-drawn-balls__balls--vikinglotto .winning-numbers-drawn-balls__ball,
.winning-numbers-drawn-balls__balls--vikinglotto-bonus .winning-numbers-drawn-balls__ball {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-vikinglotto_use");
}
.winning-numbers-drawn-balls__balls--eurojackpot .winning-numbers-drawn-balls__ball,
.winning-numbers-drawn-balls__balls--eurojackpot-bonus .winning-numbers-drawn-balls__ball {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-eurojackpot_use");
}
.winning-numbers-drawn-balls__balls--joker .winning-numbers-drawn-balls__ball {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#ball-joker_use");
}
.winning-numbers-drawn-balls--animate .winning-numbers-drawn-balls__ball {
  animation: winning-numbers-drawn-balls-bounce 1000ms ease forwards, winning-numbers-drawn-balls-direction 600ms ease-out forwards;
}
.winning-numbers-drawn-balls--no-animation .winning-numbers-drawn-balls__ball {
  animation: none;
}
.winning-numbers-drawn-balls__ball:nth-child(1) {
  animation-delay: 0ms;
  z-index: 10;
}
.winning-numbers-drawn-balls__ball:nth-child(2) {
  animation-delay: 100ms;
  z-index: 9;
}
.winning-numbers-drawn-balls__ball:nth-child(3) {
  animation-delay: 100ms;
  z-index: 8;
}
.winning-numbers-drawn-balls__ball:nth-child(4) {
  animation-delay: 70ms;
  z-index: 7;
}
.winning-numbers-drawn-balls__ball:nth-child(5) {
  animation-delay: 150ms;
  z-index: 6;
}
.winning-numbers-drawn-balls__ball:nth-child(6) {
  animation-delay: 160ms;
  z-index: 5;
}
.winning-numbers-drawn-balls__ball:nth-child(7) {
  animation-delay: 130ms;
  z-index: 4;
}
@keyframes winning-numbers-drawn-balls-expand {
  0% {
    transform: scaleX(0%);
  }
  60% {
    transform: scaleX(110%);
  }
  80% {
    transform: scaleX(95%);
  }
  100% {
    width: auto;
    transform: scaleX(100%);
  }
}
@keyframes winning-numbers-drawn-balls-bounce {
  0% {
    opacity: 0;
    transform: translateX(30rem) rotate(70deg);
  }
  60% {
    opacity: 1;
    transform: translateX(-0.5rem) rotate(0deg);
  }
  80% {
    transform: translateX(0.2rem) rotate(-1deg);
  }
  90% {
    transform: translateX(0.1rem) rotate(0deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
@keyframes winning-numbers-drawn-balls-direction {
  to {
    right: 0;
  }
}
