@import url("../Mixins/_mixins.less");

.region-eurojackpot {

  .header-spot {
    background-color: @eurojackpot-black;

    .content-wrapper {
      color: @eurojackpot-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 7vw 0;

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 5rem 0;
      }

      .top-logo {
        img {
          width: 10vw;
          height: 10vw;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
          }
        }
      }

      h1 {
        font-size: 7vw;
        text-transform: uppercase;
        line-height: 0.9;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 3.5rem;
        }
      }

      h2 {
        font-size: 4.5vw;
        text-transform: uppercase;
        line-height: 0.8;
        margin: 0;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 2rem;
        }
      }
    }
  }
}

.eurojackpot-no-open-draw {

  .eurojackpot-section {
    background-color: @eurojackpot-white;
    border-color: @eurojackpot-white;

    .eurojackpot-content-wrapper {
      z-index: 4;
      padding: 8vw;

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 4rem;
      }

      .no-open-draw-text {
        max-width: 86.5vw;
        font-size: 5vw;
        color: @eurojackpot-carbon;
        margin: 0 auto;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 3.5rem;
        }
      }
    }
  }
}
