@import (reference) "../Mixins/_mixins";
@import (reference) url("../layout");
@import (reference) url("../../../AvalonComponents/Styles/Abstracts/_background-frames");

.eurojackpot-top-spot-responsive-frame {
  text-align: center;
  background-color: @eurojackpot-gold;
  display: flex;
  justify-content: center;
  color: black;
  padding: 0.5rem 1rem;

  .eurojackpot-top-spot-responsive-frame__preface {
    font-weight: 700;
    font-size: 5.5vw;
    line-height: 1.2;

    @media (min-width: @eurojackpot-mobile-width) {
      font-size: 3rem;
    }
  }

  .eurojackpot-top-spot-responsive-frame__title {
    font-weight: 900;
    font-size: 16.5vw;
    line-height: 1.2;
    letter-spacing: -0.02em;
    // title is empty until JS is loaded, so to prevent layout
    // jumping, we add min. dimensions to the element
    min-height: 1.3em;
    min-width: 1em;

    @media (min-width: @eurojackpot-mobile-width) {
      font-size: 12rem;
    }
  }

  .eurojackpot-top-spot-responsive-frame__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 144rem;
    width: 100%;
    padding: 10vw 0;

    @media (min-width: @eurojackpot-mobile-width) {
      padding: 5rem 0;
    }

    @media (min-width: @eurojackpot-tablet-width) {
      min-width: 65rem;
      width: auto;
      padding: 6rem 10rem;
    }
  }

  // background 01
  &.background-frame__eurojackpot01 {
    .framed-background__bg-eurojackpot-gold--small();

    @media (min-width: @eurojackpot-mobile-width) {
      .framed-background__bg-eurojackpot-gold--medium();
    }

    @media (min-width: @eurojackpot-tablet-width) {
      .framed-background__bg-eurojackpot-gold--large();
    }

    .eurojackpot-top-spot-responsive-frame__content {
      .framed-background__frame-eurojackpot01--small();

      @media (min-width: @eurojackpot-mobile-width) {
        .framed-background__frame-eurojackpot01--medium();
      }

      @media (min-width: @eurojackpot-tablet-width) {
        .framed-background__frame-eurojackpot01--large();
      }
    }
  }

  // background 02
  &.background-frame__eurojackpot02 {
    background: black;
    padding: 1.5rem 1rem;

    .eurojackpot-top-spot-responsive-frame__content {
      .framed-background__frame-eurojackpot02--small();
      box-shadow: inset 0 0 0 0.1rem black;
      padding: 14vw 0;

      @media (min-width: @eurojackpot-mobile-width) {
        .framed-background__frame-eurojackpot02--medium();
        padding: 6rem 0;
      }

      @media (min-width: @eurojackpot-tablet-width) {
        .framed-background__frame-eurojackpot02--large();
        padding: 8rem 12rem;
      }
    }
  }

  // background 03
  &.background-frame__eurojackpot03 {
    background: black;
    color: @eurojackpot-gold-light;
    padding: 1.5rem 1rem;

    .eurojackpot-top-spot-responsive-frame__content {
      .framed-background__frame-eurojackpot03--small();
      padding: 14vw 0;

      @media (min-width: @eurojackpot-mobile-width) {
        .framed-background__frame-eurojackpot03--medium();
        padding: 6rem 0;
      }

      @media (min-width: @eurojackpot-tablet-width) {
        .framed-background__frame-eurojackpot03--large();
        padding: 8rem 12rem;
      }
    }
  }

  // background 04
  &.background-frame__eurojackpot04 {
    color: @eurojackpot-gold-light;
    .framed-background__bg-eurojackpot-stars--small();

    @media (min-width: @eurojackpot-mobile-width) {
      .framed-background__bg-eurojackpot-stars--medium();
    }

    @media (min-width: @eurojackpot-tablet-width) {
      .framed-background__bg-eurojackpot-stars--large();
    }

    .eurojackpot-top-spot-responsive-frame__content {
      .framed-background__frame-eurojackpot04--small();

      @media (min-width: @eurojackpot-mobile-width) {
        .framed-background__frame-eurojackpot04--medium();
      }

      @media (min-width: @eurojackpot-tablet-width) {
        .framed-background__frame-eurojackpot04--large();
      }
    }
  }

  // background 05
  &.background-frame__eurojackpot05 {
    .framed-background__bg-eurojackpot-gold--small();

    @media (min-width: @eurojackpot-mobile-width) {
      .framed-background__bg-eurojackpot-gold--medium();
    }

    @media (min-width: @eurojackpot-tablet-width) {
      .framed-background__bg-eurojackpot-gold--large();
    }

    .eurojackpot-top-spot-responsive-frame__content {
      .framed-background__frame-eurojackpot05--small();

      @media (min-width: @eurojackpot-mobile-width) {
        .framed-background__frame-eurojackpot05--medium();
      }

      @media (min-width: @eurojackpot-tablet-width) {
        .framed-background__frame-eurojackpot05--large();
      }
    }
  }

  // background 06
  &.background-frame__eurojackpot06 {
    color: @eurojackpot-gold-light;
    .framed-background__bg-eurojackpot-stars--small();

    @media (min-width: @eurojackpot-mobile-width) {
      .framed-background__bg-eurojackpot-stars--medium();
    }

    @media (min-width: @eurojackpot-tablet-width) {
      .framed-background__bg-eurojackpot-stars--large();
    }

    .eurojackpot-top-spot-responsive-frame__content {
      .framed-background__frame-eurojackpot06--small();

      @media (min-width: @eurojackpot-mobile-width) {
        .framed-background__frame-eurojackpot06--medium();
      }

      @media (min-width: @eurojackpot-tablet-width) {
        .framed-background__frame-eurojackpot06--large();
      }
    }
  }
}
