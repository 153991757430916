.multi-client__joker-hat {
  width: 5.6rem;
  height: 2.8rem;
  margin: 0 auto 1rem;
}
@media screen and (min-width: 768px) {
  .multi-client__joker-hat {
    width: 10rem;
    height: 5rem;
    margin: 0 auto 3rem;
    position: absolute;
    top: -8rem;
    left: 0;
    right: 0;
  }
}
.multi-client-joker-options .check-button {
  margin-bottom: 1rem;
  background-color: rgba(0, 154, 100, 0.1);
  color: #009a64;
}
.multi-client-joker-options .check-button .check-button__dot {
  border-color: rgba(0, 154, 100, 0.1);
}
.multi-client-joker-options .check-button::before {
  background-color: #009a64;
}
.multi-client-joker-options .check-button .check-button__dot::after {
  background-color: #009a64;
}
.multi-client-joker-options .check-button.check-button--selected {
  color: white;
}
.multi-client-joker-options .check-button.check-button--selected .check-button__dot {
  border-color: rgba(0, 154, 100, 0.5);
}
