﻿@import (reference) "../_imports";
@import "../Mixins/mixins";

.tutorial-navbar {
  width: 100%;
  height: 4.5rem; // should be 45px height, but can be increased during development, to see the slider
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  color: @ds-white;
  margin-bottom: 2rem;

  @media @sm {
    margin-left: 5.9rem;
    margin-right: 5.9rem;
  }

  @media @lg {
    display: none;
  }

  .tutorial-navbar-container {
    overflow-x: auto;
    overflow-y: hidden;
    height: 4.5rem;
    padding-bottom: 12rem;
  }

  .tutorial-navbar-label {
    position: relative;
    z-index: 2;
    font-family: @font-impact;
    font-style: italic;
    height: 4.5rem;
    flex: 0 0 12rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-left: 2rem;
    font-size: 1.5rem;
  }

  .tutorial-navbar-cave {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: -0.5rem;
    left: -0.5rem;
    width: 14rem;
    height: 5.5rem;
    filter: none;
    transition: filter 200ms ease-out;

    &--open {
      filter: drop-shadow(0.3rem 0.3rem 0.4rem rgba(0, 0, 50, 0.25));
    }
  }

  .tutorial-navbar-cave-inner {
    width: 14rem;
    height: 7rem;
    clip-path: polygon(100% 0%,
    96% 30%,
    95% 55%,
    100% 100%,
    0% 100%,
    0% 0%,
    25% 0%);
    background: var(--tutorial-primary);
  }

  .tutorial-navbar-navs {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    padding-right: 5rem;
    font-size: 1.4rem;
  }

  .tutorial-navbar-nav {
    position: relative;
    z-index: 0;
    height: 4.5rem;
    margin-left: 1rem;
    user-select: none;
    padding: 1rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: @ds-white;

    &--hide {
      display: none;
    }

    &:last-child {
      padding-right: 3rem;
    }

    &::after {
      position: absolute;
      content: "";
      top: 1.2rem;
      left: -0.5rem;
      bottom: 1rem;
      width: 0.1rem;
      background: rgba(255, 255, 255, 0.2);
    }

    &:first-child::after {
      width: 0;
    }
  }
}
