@import (reference) "../../Mixins/_mixins.less";
@import url("../../Mixins/_button.less");

.multi-client-top-spot {
  color: var(--multi-client-top-spot__color, @ds-white);
  position: relative;
  z-index: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 0 7rem;
  font-size: 1.2rem;
  line-height: 1.3;
  letter-spacing: 0.1rem;
  font-weight: 800;

  .region-eurojackpot & {
    background-color: @ds-black;
  }

  .region-lotto & {
    background-color: @ds-red;
  }

  .region-vikinglotto & {
    background-color: @ds-blue;
  }

  + #multi-client {
    margin-top: -4.5rem;
  }

  &--layout-1 {
    .multi-client-top-spot__count-down-large {
      display: none;
    }
  }

  &--layout-2 {
    padding-top: 3.5rem;

    .multi-client-top-spot__pool-size,
    .multi-client-top-spot__count-down-small {
      display: none;
    }
  }

  @media @xxl {
    font-size: 2rem;
    padding: 4rem 0 9rem;
  }
}

.multi-client-top-spot > .responsive-bg {
  position: absolute;
  z-index: -2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  picture {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.multi-client-top-spot__lottie {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .multi-client-top-spot__lottie-player {
    position: absolute;
    top: 0;
    width: 500rem;
    height: 100%;
  }
}

.multi-client-top-spot__title {
  opacity: 0.8;

  .multi-client-top-spot--layout-1 & {
    margin-bottom: 0.7rem;

    @media @xxl {
      margin-bottom: 1.2rem;
    }
  }

  .multi-client-top-spot--layout-2 & {
    margin-bottom: 0.1rem;

    @media @xxl {
      margin-bottom: 0.5rem;
    }
  }
}

.multi-client-top-spot--layout-1 .multi-client-top-spot__title--layout-2,
.multi-client-top-spot--layout-2 .multi-client-top-spot__title--layout-1 {
  display: none;
}

.multi-client-top-spot__pool-size {
  font-size: 7.6rem;
  line-height: 0.9;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;

  .multi-client-top-spot--layout-2 & {
    display: none;
  }

  @media @xxl {
    font-size: 8rem;
    margin-bottom: 1.5rem;
  }
}

.multi-client-top-spot__cta {
  cursor: pointer;
  height: 5.6rem;
  min-width: 24rem;
  background-color: @ds-yellow;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: @ds-black;
  font-size: 1.2rem;
}

.multi-client-top-spot__count-down {
  display: flex;

  .multi-client-top-spot--layout-1 & {
    margin-bottom: 2.4rem;
  }

  .multi-client-top-spot--layout-2 & {
    margin-bottom: 5rem;
  }
}

.multi-client-top-spot__count-down-label {
  opacity: 0.8;
  margin-right: 0.5rem;

  .multi-client-top-spot--layout-2 & {
    display: none;
  }
}

.multi-client-top-spot__count-down-time {

  .multi-client-top-spot--layout-1 & {

    span:not(:last-child) {
      position: relative;
      padding-right: 1rem;

      &::after {
        content: "";
        position: absolute;
        width: 0.1rem;
        top: 0.3rem;
        bottom: 0.3rem;
        right: 0.5rem;
        background-color: var(--multi-client-top-spot__color, @ds-white);
        opacity: 0.5;
      }
    }
  }

  .multi-client-top-spot--layout-2 & {
    font-size: 4.4rem;
    letter-spacing: normal;

    span {
      position: relative;
    }

    span:not(:last-child) {
      padding-right: 3rem;

      &::after {
        content: "";
        position: absolute;
        width: 0.1rem;
        top: 1.2rem;
        bottom: 0.9rem;
        right: 1.4rem;
        background-color: var(--multi-client-top-spot__color, @ds-white);
        opacity: 0.5;
      }
    }

    span::before {
      text-transform: uppercase;
      position: absolute;
      bottom: -1.3rem;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
    }

    span:not(:last-child)::before {
      padding-right: 3rem;
    }

    span:first-child::before {
      content: "Dage";
    }

    span:nth-child(2)::before {
      content: "Timer";
    }

    span:nth-child(3)::before {
      content: "Min.";
    }

    span:nth-child(4)::before {
      content: "Sek.";
    }
  }
}

.multi-client-top-spot__count-down-small {
  display: flex;
  margin-bottom: 2.4rem;
}

.multi-client-top-spot__count-down-small-label {
  opacity: 0.8;
  margin-right: 0.5rem;
}

.multi-client-top-spot__count-down-small-time {
  span:not(:last-child) {
    position: relative;
    padding-right: 1rem;

    &::after {
      content: "";
      position: absolute;
      width: 0.1rem;
      top: 0.3rem;
      bottom: 0.3rem;
      right: 0.5rem;
      background-color: var(--multi-client-top-spot__color, @ds-white);
      opacity: 0.5;
    }
  }
}

.multi-client-top-spot__count-down-large {
  display: flex;
  margin-bottom: 5rem;
}

.multi-client-top-spot__count-down-large-time {
  font-size: 4.4rem;
  letter-spacing: normal;

  span {
    position: relative;
  }

  span:not(:last-child) {
    padding-right: 3rem;

    &::after {
      content: "";
      position: absolute;
      width: 0.1rem;
      top: 1.2rem;
      bottom: 0.9rem;
      right: 1.4rem;
      background-color: var(--multi-client-top-spot__color, @ds-white);
      opacity: 0.5;
    }
  }

  span::before {
    text-transform: uppercase;
    position: absolute;
    bottom: -1.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
  }

  span:not(:last-child)::before {
    padding-right: 3rem;
  }

  span:first-child::before {
    content: "Timer";
  }

  span:nth-child(2)::before {
    content: "Min.";
  }

  span:nth-child(3)::before {
    content: "Sek.";
  }
}
