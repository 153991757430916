﻿@import url("../../Mixins/_mixins.less");

.statistics-module {
  .statistics-module-wrapper {
    .statistics-module-numbers-type-switch-wrapper {
      max-height:9vw;
      text-align: center;
      overflow: hidden;
      font-size: 0;
      opacity: 1;
      padding:3.2vw 0;
      transition: max-height .15s linear, opacity .15s linear, padding .15s linear, background-color .15s linear;
      will-change: max-height;

      @media (min-device-width: @desktop-width) {
        max-height: 6rem;
        padding: 1rem 0;
      }

      &.hide {
        max-height: 0;
        opacity: 0;
        padding: 0;
      }

      .numbers-type-switch-inner-wrapper {
        display: inline-block;
        border-radius: 6px;
        border: 1px solid;
        overflow: hidden;

        .numbers-type-switch-button-wrapper {
          font-size:3.2vw;
          padding:2vw 3.2vw;
          display: inline-block;
          border-right: 1px solid;
          transition: background-color .15s linear, border-color .15s linear;

          @media (min-device-width: @desktop-width) {
            font-size: 1.4rem;
            padding: .9rem 1rem 1rem;
          }

          &:hover {
            @media (min-device-width: @desktop-width) {
              cursor: pointer;
            }
          }

          &:last-child {
            border-right: none;
          }

          &.active {

            &:hover {
              @media (min-device-width: @desktop-width) {
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}
