.eurojackpot-top-spot-responsive-frame {
  text-align: center;
  background-color: #E8A332;
  display: flex;
  justify-content: center;
  color: black;
  padding: 0.5rem 1rem;
}
.eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__preface {
  font-weight: 700;
  font-size: 5.5vw;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__preface {
    font-size: 3rem;
  }
}
.eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__title {
  font-weight: 900;
  font-size: 16.5vw;
  line-height: 1.2;
  letter-spacing: -0.02em;
  min-height: 1.3em;
  min-width: 1em;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__title {
    font-size: 12rem;
  }
}
.eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 144rem;
  width: 100%;
  padding: 10vw 0;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__content {
    padding: 5rem 0;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame .eurojackpot-top-spot-responsive-frame__content {
    min-width: 65rem;
    width: auto;
    padding: 6rem 10rem;
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot01 {
  background-size: cover;
  background-position: center;
  background-color: #dba238;
  background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot01 {
    background-size: cover;
    background-position: center;
    background-color: #dba238;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-medium.jpg');
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot01 {
    background-size: cover;
    background-position: center;
    background-color: #dba238;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-large.jpg');
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot01 .eurojackpot-top-spot-responsive-frame__content {
  background-repeat: no-repeat;
  background-image: url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-right_use'), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#left_use'), url();
  background-size: 0 0, 30px 20px, 0 0, 30px 20px, 52px 56px, 52px 56px, 52px 56px, 52px 56px, calc(100% - 52px * 2 + 1px) 20px, 20px calc(100% - 56px * 2 + 1px), calc(100% - 52px * 2 + 1px) 20px, 20px calc(100% - 56px * 2 + 1px), cover;
  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot01 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-right_use'), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#left_use'), url();
    background-size: 0 0, 45px 30px, 0 0, 45px 30px, 78px 84px, 78px 84px, 78px 84px, 78px 84px, calc(100% - 78px * 2 + 1px) 30px, 30px calc(100% - 84px * 2 + 1px), calc(100% - 78px * 2 + 1px) 30px, 30px calc(100% - 84px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot01 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-right_use'), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#left_use'), url();
    background-size: 0 0, 60px 40px, 0 0, 60px 40px, 104px 112px, 104px 112px, 104px 112px, 104px 112px, calc(100% - 104px * 2 + 1px) 40px, 40px calc(100% - 112px * 2 + 1px), calc(100% - 104px * 2 + 1px) 40px, 40px calc(100% - 112px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot02 {
  background: black;
  padding: 1.5rem 1rem;
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot02 .eurojackpot-top-spot-responsive-frame__content {
  background-repeat: no-repeat;
  background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
  background-size: 0 0, 0 0, 0 0, 0 0, 89px 88px, 89px 88px, 89px 88px, 89px 88px, calc(100% - 89px * 2 + 1px) 20px, 20px calc(100% - 88px * 2 + 1px), calc(100% - 89px * 2 + 1px) 20px, 20px calc(100% - 88px * 2 + 1px), cover;
  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  box-shadow: inset 0 0 0 0.1rem black;
  padding: 14vw 0;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot02 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
    background-size: 0 0, 0 0, 0 0, 0 0, 134px 132px, 134px 132px, 134px 132px, 134px 132px, calc(100% - 134px * 2 + 1px) 30px, 30px calc(100% - 132px * 2 + 1px), calc(100% - 134px * 2 + 1px) 30px, 30px calc(100% - 132px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
    padding: 6rem 0;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot02 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
    background-size: 0 0, 0 0, 0 0, 0 0, 178px 175px, 178px 175px, 178px 175px, 178px 175px, calc(100% - 178px * 2 + 1px) 40px, 40px calc(100% - 175px * 2 + 1px), calc(100% - 178px * 2 + 1px) 40px, 40px calc(100% - 175px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
    padding: 8rem 12rem;
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot03 {
  background: black;
  color: #ecbb47;
  padding: 1.5rem 1rem;
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot03 .eurojackpot-top-spot-responsive-frame__content {
  background-repeat: no-repeat;
  background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#left_use'), url();
  background-size: 0 0, 0 0, 0 0, 0 0, 52px 77px, 52px 77px, 52px 77px, 52px 77px, calc(100% - 52px * 2 + 1px) 20px, 20px calc(100% - 77px * 2 + 1px), calc(100% - 52px * 2 + 1px) 20px, 20px calc(100% - 77px * 2 + 1px), cover;
  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  padding: 14vw 0;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot03 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 78px 116px, 78px 116px, 78px 116px, 78px 116px, calc(100% - 78px * 2 + 1px) 30px, 30px calc(100% - 116px * 2 + 1px), calc(100% - 78px * 2 + 1px) 30px, 30px calc(100% - 116px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
    padding: 6rem 0;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot03 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 104px 154px, 104px 154px, 104px 154px, 104px 154px, calc(100% - 104px * 2 + 1px) 40px, 40px calc(100% - 154px * 2 + 1px), calc(100% - 104px * 2 + 1px) 40px, 40px calc(100% - 154px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
    padding: 8rem 12rem;
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot04 {
  color: #ecbb47;
  background-size: cover;
  background-position: center;
  background-color: black;
  background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-small.jpg');
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot04 {
    background-size: cover;
    background-position: center;
    background-color: black;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-medium.jpg');
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot04 {
    background-size: cover;
    background-position: center;
    background-color: black;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-large.jpg');
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot04 .eurojackpot-top-spot-responsive-frame__content {
  background-repeat: no-repeat;
  background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#left_use'), url();
  background-size: 0 0, 0 0, 0 0, 0 0, 46px 43px, 46px 43px, 46px 43px, 46px 43px, calc(100% - 46px * 2 + 1px) 20px, 25px calc(100% - 43px * 2 + 1px), calc(100% - 46px * 2 + 1px) 20px, 25px calc(100% - 43px * 2 + 1px), cover;
  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot04 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 69px 65px, 69px 65px, 69px 65px, 69px 65px, calc(100% - 69px * 2 + 1px) 30px, 38px calc(100% - 65px * 2 + 1px), calc(100% - 69px * 2 + 1px) 30px, 38px calc(100% - 65px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot04 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 92px 86px, 92px 86px, 92px 86px, 92px 86px, calc(100% - 92px * 2 + 1px) 40px, 50px calc(100% - 86px * 2 + 1px), calc(100% - 92px * 2 + 1px) 40px, 50px calc(100% - 86px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot05 {
  background-size: cover;
  background-position: center;
  background-color: #dba238;
  background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot05 {
    background-size: cover;
    background-position: center;
    background-color: #dba238;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-medium.jpg');
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot05 {
    background-size: cover;
    background-position: center;
    background-color: #dba238;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-large.jpg');
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot05 .eurojackpot-top-spot-responsive-frame__content {
  background-repeat: no-repeat;
  background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#left_use'), url();
  background-size: 0 0, 0 0, 0 0, 0 0, 69px 68px, 69px 68px, 69px 68px, 69px 68px, calc(100% - 69px * 2 + 1px) 20px, 20px calc(100% - 68px * 2 + 1px), calc(100% - 69px * 2 + 1px) 20px, 20px calc(100% - 68px * 2 + 1px), cover;
  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot05 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 103px 102px, 103px 102px, 103px 102px, 103px 102px, calc(100% - 103px * 2 + 1px) 30px, 30px calc(100% - 102px * 2 + 1px), calc(100% - 103px * 2 + 1px) 30px, 30px calc(100% - 102px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot05 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 137px 136px, 137px 136px, 137px 136px, 137px 136px, calc(100% - 137px * 2 + 1px) 40px, 40px calc(100% - 136px * 2 + 1px), calc(100% - 137px * 2 + 1px) 40px, 40px calc(100% - 136px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot06 {
  color: #ecbb47;
  background-size: cover;
  background-position: center;
  background-color: black;
  background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-small.jpg');
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot06 {
    background-size: cover;
    background-position: center;
    background-color: black;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-medium.jpg');
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot06 {
    background-size: cover;
    background-position: center;
    background-color: black;
    background-image: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-large.jpg');
  }
}
.eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot06 .eurojackpot-top-spot-responsive-frame__content {
  background-repeat: no-repeat;
  background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#left_use'), url();
  background-size: 0 0, 0 0, 0 0, 0 0, 69px 69px, 69px 69px, 69px 69px, 69px 69px, calc(100% - 69px * 2 + 1px) 20px, 20px calc(100% - 69px * 2 + 1px), calc(100% - 69px * 2 + 1px) 20px, 20px calc(100% - 69px * 2 + 1px), cover;
  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
}
@media (min-width: 768px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot06 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 103px 103px, 103px 103px, 103px 103px, 103px 103px, calc(100% - 103px * 2 + 1px) 30px, 30px calc(100% - 103px * 2 + 1px), calc(100% - 103px * 2 + 1px) 30px, 30px calc(100% - 103px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
@media (min-width: 1024px) {
  .eurojackpot-top-spot-responsive-frame.background-frame__eurojackpot06 .eurojackpot-top-spot-responsive-frame__content {
    background-repeat: no-repeat;
    background-image: url(), url(), url(), url(), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-left_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#right_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom_use'), url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#left_use'), url();
    background-size: 0 0, 0 0, 0 0, 0 0, 137px 137px, 137px 137px, 137px 137px, 137px 137px, calc(100% - 137px * 2 + 1px) 40px, 40px calc(100% - 137px * 2 + 1px), calc(100% - 137px * 2 + 1px) 40px, 40px calc(100% - 137px * 2 + 1px), cover;
    background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
  }
}
