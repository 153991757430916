@import (reference) "../variables.less";

body.region-eurojackpot,
body.region-avalon-eurojackpot,
body.color-eurojackpot-gold {
  .seo-text-container {
    background-color: @eurojackpot-background;
  }

  footer .info-bar {
    background: linear-gradient(to right, rgba(187, 127, 54, 1) 0%, rgba(239, 176, 60, 1) 25%, rgba(187, 127, 54, 1) 50%, rgba(239, 176, 60, 1) 75%, rgba(187, 127, 54, 1) 100%);
  }
}
