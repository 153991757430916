@import url("../Mixins/_mixins.less");

.eurojackpot-confirm {
  background-color: @eurojackpot-carbon;

  .eurojackpot-row-display {
    > div.eurojackpot-confirm-page-header {
      background-color: @eurojackpot-darkgray;
      background-size: cover;
      background-position: center center;
      position: relative;
      padding: 13.33vw 0 8vw;

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 6.5rem;
      }
      // Video
      .eurojackpot-video-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
      }

      .eurojackpot-video {
        min-width: 100%;
        min-height: 100%;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }

      & .draw-date {
        font-size: 3.47vw;
        color: @eurojackpot-white;
        position: relative;
        z-index: 1;
        padding-bottom: 0.5rem;
        margin: auto;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 1.8rem;
        }
      }

      & .confirm-headline {
        max-width: 75vw;
        font-size: 8vw;
        font-weight: 600;
        color: @eurojackpot-white;
        z-index: 1;
        margin: auto;

        @media (min-width: @eurojackpot-desktop-width) {
          max-width: 83rem;
          font-size: 5rem;
        }
      }

      & .confirm-subheadline {
        font-size: 3.73vw;
        font-weight: normal;
        color: @eurojackpot-white;
        padding-top: .7rem;
        margin: auto;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 2.4rem;
          font-weight: normal;
        }
      }

      & .joker-container {
        display: block;
        padding-top: 2.4vw;

        @media (min-width: @eurojackpot-desktop-width) {
          padding: 0rem 0 3.5rem;
        }

        & .joker-type-container {
          margin-top: 1vw;
          font-style: italic;

          @media (min-width: @eurojackpot-desktop-width) {
            display: inline-block;
          }

          & .text {
            width: auto;
            font-size: 4.27vw;
            line-height: 1;
            color: @eurojackpot-white;
            display: inline-block;
            font-weight: normal;
            margin: auto;

            @media (min-width: @eurojackpot-desktop-width) {
              font-size: 2.2rem;
            }

            &.and {
              @media (min-width: @eurojackpot-desktop-width) {
                margin-left: 1rem;
              }
            }

            &.joker-type {
              text-transform:uppercase;
              font-weight:900;
            }
          }

          & svg.icon-joker_logo_vindertal {
            width: 28vw;
            height: 5.2vw;
            transform: translateY(0.6vw);
            margin: 0 1.5vw;
            fill:#fff;

            @media (min-width: @eurojackpot-desktop-width) {
              width: 15.3rem;
              height: 2.9rem;
              transform: translateY(0.5rem);
              margin: 0 1rem;
            }
          }
        }
      }

      .alt-text {
        max-width: 86.5vw;
        font-size: 3.47vw;
        color: @eurojackpot-white;
        z-index: 1;
        padding: 3vw 0;
        margin: auto;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 2rem;
          padding: 1.5rem 0;
        }
      }
    }

    & .rows-container {
      position: relative;
      overflow: hidden;
      transition: max-height .5s;

      & .number-row {
        width: 100%;
        font-size: 4.266vw;
        background-color: @eurojackpot-carbon;
        padding: 5.4vw 6.666vw 5.135vw;
        position: relative;
        box-sizing: border-box;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 1.8rem;
          padding: 2.5rem 0 2.2rem;
        }

        &:nth-child(odd) {
          background-color: @eurojackpot-dark;
        }

        &.hidden {
          display: none;
        }

        &.system {

          .number-row-inner {
            width: auto;
            text-align: center;

            .row-title {
              width: 100%;
              display: block;
            }

            & .row-numbers {
              width: auto;
              line-height: 1.5;
              display: inline-block;
              padding-top: 1rem;
            }

            & .plus-separator {
              width: auto;
              padding: 0 1rem;
            }

            & .star-numbers {
              width: auto;
            }
          }
        }

        .number-row-inner {
          text-align: left;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 39.8rem;
            margin: auto;
            position: relative;
          }

          & .row-title {
            width: 32%;
            font-size: 4vw;
            font-weight: normal;
            line-height: 1.3;
            color: @eurojackpot-white;
            display: inline-block;
            margin: 0 auto;

            @media (min-width: @eurojackpot-desktop-width) {
              font-size: 1.8rem;
            }
          }

          .row-numbers {
            width: 37%;
            font-size: 4vw;
            color: @eurojackpot-white;
            display: inline-block;

            @media (min-width: @eurojackpot-desktop-width) {
              font-size: 1.8rem;
            }
          }

          .plus-separator {
            width: 6%;
            color: @eurojackpot-white;
            display: inline-block;
            padding: 0 0.9rem;
          }

          .star-numbers {
            color: #fff;
          }

          & .delete-button {
            width: 8vw;
            height: 8vw;
            position: absolute;
            right: 4vw;
            top: 50%;
            transform: translateY(-50%);

            @media (min-width: @eurojackpot-desktop-width) {
              width: 1.2rem;
              height: 1.4rem;
              top: 50%;
              right: 0;
              cursor: pointer;
            }

            &.hidden {
              display: none;
            }


            svg.icon-x {
              width: 2.667vw;
              height: 2.667vw;
              position: absolute;
              fill: @eurojackpot-white;
              top: 50%;
              left: 50%;
              -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0);

              @media (min-width: @eurojackpot-desktop-width) {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }
        }
      }

      & .bottom-gradient {
        width: 100%;
        height: 10.7vw;
        position: absolute;
        bottom: 0;
        display: block;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 37%, #000000 65%, #000 100%);
        /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#FFF', GradientType=0);*/
        transition: opacity 0.5s;

        @media (min-width: @eurojackpot-desktop-width) {
          height: 7.2rem;
        }

        &.hidden {
          display: none;
        }

        & .show-more-button {
          text-transform: uppercase;
          color: @eurojackpot-black;
          background-color: @eurojackpot-dark-yellow;
          border-radius: 5px;
          display: inline-block;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          user-select: none;
          margin: 0;
        }
      }
    }

    & .terms-container {
      width: 100%;
      max-width: none;
      font-size: 3.47vw;
      background-color: @eurojackpot-gray;
      color: @eurojackpot-white;
      padding: 5vw 0 7vw;
      margin: auto;

      @media (min-width: @eurojackpot-desktop-width) {
        font-size: 1.8rem;
        padding: 2.5rem 0 3rem;
      }

      & .terms-link {
        color: @eurojackpot-white;
        text-decoration: underline;
        display: block;
        padding-top: .5rem;
      }
    }
  }

  .btn {
    margin: 6rem 1rem 2rem;
  }

  .cta-deposit {
    margin: 2rem;
  }
}
