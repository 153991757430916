﻿@import url("../Mixins/_mixins.less");

.promotion-spot {
  text-align: center;
  padding: 10vw 5vw;
  margin: 0 auto;
  position: relative;
}

.promotion-spot__wrapper {
  text-decoration: none;
  display: flex;
  align-items: center;

  @media(min-width: @desktop-width) {
    flex-direction: column;
  }
}

.promotion-spot__icon {
  width: 7rem;
  height: 7rem;

  @media(min-width: @desktop-width) {
    width: 12.8rem;
    height: 12.8rem;
    margin-bottom: 4rem;
    margin-right: 0;
  }
}

.promotion-spot__text {
  text-align: left;
  padding-right: 2.5rem;
  padding-left: 2rem;

  @media(min-width: @desktop-width) {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }
}

.promotion-spot__info-text {
  margin-bottom: 0;

  @media (min-width: @desktop-width) {
    max-width: 45rem;
  }

  &:empty {
    display: none;
  }
}

.promotion-spot__cta {
  display: none;
  width: 100%;
  text-align: center;

  @media(min-width: @desktop-width) {
    display: inline-block;
    width: auto;
  }
}

.promotion-spot__arrow-icon {
  position: absolute;
  right: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  transform: rotate(-90deg);
  fill: white;

  @media(min-width: @desktop-width) {
    display: none;
  }
}