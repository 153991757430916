@import url("../Mixins/_mixins.less");

.eurojackpot-fast-play {
  padding: 8vw;
  @media (min-width: @eurojackpot-desktop-width) {
    padding: 6rem 7.7rem;
  }

}

.eurojackpot-game-promotion-spot {

  @media (min-width: @eurojackpot-desktop-width) {
    display:flex;
  }

  .eurojackpot-section {
    background-color: @eurojackpot-almost-black;
    flex:1;

    .min-height {
      min-height: 4.5rem;
    }
    &.small {
      @media (min-width: @eurojackpot-desktop-width) {
        flex:1;
        
        .eurojackpot-content-wrapper {
          width:48rem;
        }
      }

      
      &:nth-child(1) {
        background: @eurojackpot-darkgray;

        .eurojackpot-content-wrapper {
          margin-right:0;
        }
      }
      &:nth-child(2) {
        background: @eurojackpot-darksmoke;

        .eurojackpot-content-wrapper {
          margin-left:0;
        }
      }
      @media (max-width: 960px) {
        display: block;
        width: 100%;
      }
    }
    .info-text {
      color: @eurojackpot-white;
      line-height: 5vw;
      font-weight: 300;
      margin: 4vw auto;
      font-size: 3.73vw;
      @media (min-width: @eurojackpot-desktop-width) {
        max-width: 45rem;
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 1.5rem auto;
      }
    }
    img {
      padding: 0 0 5vw;
      @media (min-width: @eurojackpot-desktop-width) {
        padding: 0 0 4rem;
      }
    }

  }
}
