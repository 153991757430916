/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-module .statistics-module-selected-numbers {
  width: 68vw;
  color: #fff;
  font-size: 0;
  position: fixed;
  top: 50vw;
  right: 0;
  transform: translateX(100%);
  transition: transform .15s linear;
  z-index: 1;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers {
    max-height: initial;
    width: initial;
    position: initial;
    top: initial;
    right: initial;
    transform: translateX(0);
  }
}
.statistics-module .statistics-module-selected-numbers.opened {
  transform: translateX(0);
}
.statistics-module .statistics-module-selected-numbers .no-selected-numbers {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .no-selected-numbers {
    margin: .5rem 0;
    padding: 2.25rem 2rem;
    font-size: 1.8rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper {
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 6px 0 0 6px;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper {
    padding: 1.5rem 5rem;
    border-radius: 0;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.lotto-numbers {
  color: #fff;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  background-color: #a30003;
  fill: #fff;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.lotto-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box.special .number-cell {
  background-color: #950002;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.lotto-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
  background-color: #c50005;
  border-radius: 50%;
  font-weight: 600;
  padding-top: 2.4vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.lotto-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
    padding-top: .9rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.lotto-numbers + .selected-numbers-mobile-toggle-button {
  background-color: #a30003;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.eurojackpot-numbers {
  color: #fff;
  background-color: #0A0A0A;
  fill: #fff;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.eurojackpot-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box.special .number-cell {
  padding-top: 1.1vw;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.eurojackpot-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box.special .number-cell {
    padding-top: 1.1rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.eurojackpot-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
  padding-top: .9vw;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-gold_with-gradient.svg);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.eurojackpot-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
    padding-top: .9rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.eurojackpot-numbers + .selected-numbers-mobile-toggle-button {
  background-color: #171717;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.vikinglotto-numbers {
  color: #fff;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  background-color: #000078;
  fill: #fff;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.vikinglotto-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box.special .number-cell {
  border-radius: 0;
  background-color: transparent;
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-yellow.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.vikinglotto-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
  color: #1d1d1d;
  border-radius: 50%;
  background-color: #feb700;
  font-weight: 600;
  padding-top: 2.4vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.vikinglotto-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
    padding-top: .9rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.vikinglotto-numbers + .selected-numbers-mobile-toggle-button {
  background-color: #000078;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.keno-numbers {
  color: #fff;
  background-color: #00a5eb;
  fill: #fff;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.keno-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
  border-radius: 6px;
  background-color: #21375f;
  font-weight: bold;
  padding-top: 3vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.keno-numbers .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
    padding-top: 1.2rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper.keno-numbers + .selected-numbers-mobile-toggle-button {
  background-color: #1e55a0;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper {
  margin: auto;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper {
    width: 96rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline {
  font-size: 5vw;
  text-align: left;
  position: relative;
  padding: 3vw 4vw 10vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline {
    font-size: 1.6rem;
    padding: 0 0 .5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button {
  font-size: 4vw;
  position: absolute;
  right: 4vw;
  top: 14vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button {
    font-size: initial;
    right: 0;
    top: 0;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button:hover {
    cursor: pointer;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button:hover:before {
  opacity: 1;
  transform: scaleX(1);
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button:before {
  content: '';
  height: .1rem;
  width: 105%;
  background-color: #fff;
  position: absolute;
  bottom: -0.1rem;
  left: -2.5%;
  transform-origin: center;
  transform: scaleX(0);
  opacity: 0;
  transition: transform .15s linear, opacity .1s linear;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button .icon {
  width: 4vw;
  height: 4vw;
  margin-left: 1vw;
  display: inline-block;
  fill: inherit;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-headline .selected-numbers-delete-button .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper {
  padding: 2vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper {
    padding: 0;
    margin-left: -0.5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box {
  width: 12vw;
  height: 12vw;
  text-align: center;
  background-color: #f9f9f9;
  margin: 2vw;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  transition: background-color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box {
    width: 5rem;
    height: 5rem;
    margin: .5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:hover .selected-number-delete-button {
  pointer-events: inherit;
  transform: translate(50%, -50%) scale(1);
  opacity: 1;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:hover .selected-number-delete-button:hover {
    cursor: pointer;
    transform: translate(50%, -50%) scale(1.1);
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box.hovered {
  background-color: #dcdcdc;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:not(.special) + .special {
    margin-left: 1.4rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:not(.special) + .special:before {
  content: '';
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:not(.special) + .special:before {
    width: .4rem;
    top: .6rem;
    left: -1.2rem;
    bottom: .6rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:not(.special) + .special + .special {
    margin-left: .5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box:not(.special) + .special + .special:before {
  content: none;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .selected-number-delete-button {
  width: 6vw;
  height: 6vw;
  position: absolute;
  border-radius: 50%;
  background-color: #505050;
  right: 0;
  top: 0;
  transform: translate(50%, -50%) scale(0.8);
  opacity: 0;
  pointer-events: none;
  transition: transform .15s linear, opacity .15s linear;
  will-change: transform;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .selected-number-delete-button {
    width: 2rem;
    height: 2rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .selected-number-delete-button .icon {
  width: 2vw;
  height: 6vw;
  fill: #fff;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .selected-number-delete-button .icon {
    width: 1rem;
    height: 1rem;
    margin-top: .5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
  width: 10vw;
  height: 10vw;
  font-size: 4vw;
  color: #fff;
  box-sizing: border-box;
  margin: 1vw;
  position: absolute;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .number-cell {
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
    margin: .5rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-game-inner-wrapper .selected-numbers-wrapper .selected-number-box .selected-number-value {
  width: 100%;
  position: absolute;
  bottom: .3rem;
  left: 0;
  font-size: 1.4rem;
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper {
  margin: auto;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper {
    width: 96rem;
    padding-top: 1rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-header {
  text-align: left;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-header {
    font-size: 1.6rem;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons {
  padding: 0 4vw 2vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons {
    padding: 0;
    display: flex;
  }
}
.statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons .go-to-game-button {
  font-size: 4vw;
  color: #322b00;
  padding: 2vw;
  margin-bottom: 2vw;
  text-align: center;
  font-weight: bold;
  border-radius: 6px;
  flex-grow: 1;
  background-color: #feb700;
  transition: background-color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons .go-to-game-button {
    font-size: 1.4rem;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons .go-to-game-button:first-child {
    margin-left: 0;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons .go-to-game-button:last-child {
    margin-right: 0;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-selected-numbers .selected-numbers-game-wrapper .selected-numbers-go-to-game-buttons-wrapper .go-to-game-buttons .go-to-game-button:hover {
    background-color: #f5d900;
  }
}
.statistics-module .selected-numbers-mobile-toggle-button {
  width: 12vw;
  height: 12vw;
  position: absolute;
  top: 5vw;
  left: -12vw;
  padding: 3vw;
  box-sizing: border-box;
  border-radius: 6px 0 0 6px;
}
@media (min-device-width: 960px) {
  .statistics-module .selected-numbers-mobile-toggle-button {
    display: none;
  }
}
.statistics-module .selected-numbers-mobile-toggle-button .count-wrapper {
  background-color: #fff;
  font-size: 3.6vw;
  line-height: 1;
  color: #171717;
  border-radius: 3vw;
  padding: .4vw 1.2vw;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border: 1px solid;
}
.statistics-module .selected-numbers-mobile-toggle-button .icon {
  width: 6vw;
  height: 6vw;
  fill: #fff;
}
