﻿@import url("../../Mixins/_mixins.less");

.statistics-module {
  .statistics-module-wrapper {
    .statistics-module-small-view {
      width: 100%;
      max-height: 0;
      margin: 0 auto;
      font-size: 0;
      position: relative;
      box-sizing: border-box;
      transition: max-height .15s linear, background-color .15s linear;
      overflow: hidden;

      &.active {
        @media (min-device-width: @desktop-width) {
          max-height: 13.5rem;
        }
      }

      &.minimized {
        .statistics-module-graph-overview-inner-wrapper {
          @media (min-device-width: @desktop-width) {
            padding: 0 0 .5rem 0;
          }

          .statistics-module-graph-overview-expand-wrapper {
            .graph-overview-button {
              &.minimize {
                display: none;
              }

              &.maximize {
                display: block;
              }
            }
          }

          .statistics-internal-wrapper {
            @media (min-device-width: @desktop-width) {
              height: 1rem;
              transform: translateY(-.8rem);
            }

            .statistics-bars-wrapper {
              .statistics-small-bar-wrapper {
                .bar {
                  .number {
                    opacity: 0;
                  }
                }
              }
            }
          }
        }
      }

      .statistics-module-graph-overview-inner-wrapper {
        margin: auto;
        transition: padding .15s linear;

        @media (min-device-width: @desktop-width) {
          width: 96rem;
          position: relative;
          padding: 0 0 3rem 0;
        }

        .statistics-module-graph-overview-expand-wrapper {
          display: inline-block;
          overflow: hidden;

          @media (min-device-width: @desktop-width) {
            padding: 1rem 0;
            margin-bottom: -2rem;
          }

          .graph-overview-button {

            &.maximize {
              display: none;
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                cursor: pointer;
              }
            }

            .icon {
              display: inline-block;
              fill: #505050;

              @media (min-device-width: @desktop-width) {
                width: 2rem;
                height: 2rem;
              }
            }

            .text {
              display: inline-block;
              position: relative;

              @media (min-device-width: @desktop-width) {
                font-size: 1.4rem;
                padding-left: .5rem;
                top: -.2rem;
              }
            }
          }
        }

        .statistics-internal-wrapper {
          width: 79rem;
          height: 8rem;
          position: relative;
          text-align: center;
          margin-left: 17rem;
          transition: height .15s linear, transform .15s linear, background .15s linear;

          .statistics-bars-wrapper {
            height: 100%;
            position: relative;
            white-space: nowrap;

            .statistics-small-bar-wrapper {
              height: 100%;
              display: inline-block;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                will-change: transform;
                transition: background-color .15s linear, transform .25s linear;
                z-index: 0;
              }

              &.hovered {
                z-index: 1;

                &:after {
                  background-color: rgba(0, 149, 255, 0.5);
                  transform: scaleX(1.6);
                }

                .bar {
                  z-index: 1;
                }
              }

              .bar {
                width: 80%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                transition: background-color .15s linear, height .3s linear;

                .number {
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  transform: translate(-50%, 100%);
                  display: none;
                  transition: opacity .15s linear;

                  @media (min-device-width: @desktop-width) {
                    font-size: 1.4rem;
                  }
                }
              }

              &:first-child, &:nth-child(5n) {
                &:before {
                  content: '';
                  width: 1px;
                  height: 100%;
                  background-color: #777;
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                }

                .bar {
                  .number {
                    display: block;
                  }
                }
              }
            }
          }

          .statistics-zoom-finder-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            overflow: hidden;

            .zoom-finder {
              height: 100%;
              box-sizing: border-box;
              opacity: .5;
              position: absolute;
              top: 0;
              cursor: ew-resize;
              transition: transform .25s linear, width 0s linear;

              &:before, &:after {
                content: '';
                width: 100vw;
                height: 100%;
                background-color: #0A0A0A;
                position: absolute;
                top: 0;
                cursor: pointer;
                transition: background-color .15s linear;
              }

              &:before {
                left: 100%;
              }

              &:after {
                right: 100%;
              }
            }
          }
        }
      }
    }
  }
}
