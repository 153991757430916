/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media print {
  .print-system-row-wrapper {
    width: 30%;
    padding-top: 10mm;
    margin-right: 5%;
    box-sizing: border-box;
    display: inline-block;
    page-break-inside: avoid;
  }
  .print-system-row-wrapper:nth-child(3n) {
    margin-right: 0;
  }
  .print-system-row-wrapper .print-system-row-headline {
    font-size: 3mm;
    color: #c50005;
    font-weight: 500;
    border-bottom: 0.1mm solid #a3a6ab;
    padding-bottom: 2.5mm;
    box-sizing: border-box;
    margin-right: 5%;
  }
  .region-eurojackpot .print-system-row-wrapper .print-system-row-headline {
    color: #171717;
  }
  .region-vikinglotto .print-system-row-wrapper .print-system-row-headline {
    color: #0000ff;
  }
  .print-system-row-wrapper .print-system-row-body {
    padding-top: 2.5mm;
    line-height: 2mm;
  }
  .print-system-row-wrapper .print-system-row-body .print-system-row-cell {
    width: 10%;
    display: inline-block;
    font-size: 2.5mm;
    text-align: right;
    color: #4f5254;
    padding-right: 5%;
    box-sizing: border-box;
  }
  .print-system-garanti-section-wrapper {
    width: 60%;
    padding-top: 5mm;
    box-sizing: border-box;
  }
  .print-system-garanti-section-wrapper .print-system-garanti-section-header {
    font-size: 3.5mm;
    font-weight: bold;
    color: #c50005;
    text-transform: uppercase;
    padding: 1.2mm 4mm;
    border-radius: 1.5mm;
    box-sizing: border-box;
    background-color: #ededed;
    -webkit-print-color-adjust: exact;
  }
  .region-eurojackpot .print-system-garanti-section-wrapper .print-system-garanti-section-header {
    color: #171717;
  }
  .region-vikinglotto .print-system-garanti-section-wrapper .print-system-garanti-section-header {
    color: #0000ff;
  }
  .print-system-garanti-section-wrapper .print-system-garanti-section-column {
    width: 20%;
    line-height: 2mm;
    color: #4f5254;
    display: inline-block;
    text-align: center;
  }
  .print-system-garanti-section-wrapper .print-system-garanti-section-column .print-system-garanti-section-cell {
    display: block;
    font-size: 3mm;
    padding: .8mm 0;
  }
  .print-system-garanti-section-wrapper .print-system-garanti-section-column .print-system-garanti-section-cell:first-child {
    padding: 2.5mm 0;
    font-weight: 500;
    border-bottom: 0.1mm solid #ededed;
    margin-bottom: 2mm;
    box-sizing: border-box;
  }
  .print-system-garanti-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    border: 0.1mm solid #a3a6ab;
    line-height: 3.6mm;
    margin-top: 10mm;
  }
  .region-eurojackpot .print-system-garanti-table {
    table-layout: auto;
  }
  .print-system-garanti-table .print-system-garanti-table-head tr {
    font-size: 3mm;
    color: #c50005;
  }
  .region-eurojackpot .print-system-garanti-table .print-system-garanti-table-head tr {
    color: #171717;
  }
  .region-vikinglotto .print-system-garanti-table .print-system-garanti-table-head tr {
    color: #0000ff;
  }
  .print-system-garanti-table .print-system-garanti-table-head tr th {
    padding: 2mm 0 2mm 4mm;
    border-left: 0.1mm solid #a3a6ab;
    box-sizing: border-box;
    text-align: left;
  }
  .print-system-garanti-table .print-system-garanti-table-head tr th:first-child {
    border-left: none;
  }
  .print-system-garanti-table .print-system-garanti-table-body tr th,
  .print-system-garanti-table .print-system-garanti-table-body tr td {
    font-size: 2.5mm;
    color: #4f5254;
    padding: 2mm 1mm;
    border-top: 0.1mm solid #a3a6ab;
    border-left: 0.1mm solid #a3a6ab;
    text-align: center;
    box-sizing: border-box;
    background-color: #ededed;
    -webkit-print-color-adjust: exact;
  }
  .print-system-garanti-table .print-system-garanti-table-body tr th:first-child,
  .print-system-garanti-table .print-system-garanti-table-body tr td:first-child {
    border-left: none;
  }
}
