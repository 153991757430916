/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.joker-overlay {
  padding: 1rem 1.5rem;
  overflow: auto;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .joker-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@keyframes joker-overlay-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.joker-overlay.remove-overlay {
  animation: joker-overlay-fade-out 150ms linear;
}
.joker-overlay.remove-overlay .joker-overlay__content {
  animation: joker-overlay-fade-out 150ms linear;
}
.joker-overlay--small {
  display: block;
}
@media screen and (min-width: 768px) {
  .joker-overlay--small {
    display: none;
  }
}
.joker-overlay--medium {
  display: none;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .joker-overlay--medium {
    display: flex;
  }
}
.joker-overlay__content {
  border: 0.1rem solid #FEB700;
  box-sizing: border-box;
  max-width: 40rem;
  background: #000000;
  border-radius: 1rem;
  font-size: 1.4rem;
  text-align: center;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2), 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem auto auto;
}
@media screen and (min-width: 768px) {
  .joker-overlay__content {
    max-width: 70rem;
    margin: auto;
    padding: 3rem 5rem 0;
  }
}
.lotto-classic-game-overlay .joker-overlay__content,
.lotto-lightning-game-overlay .joker-overlay__content,
.lotto-system-game-overlay .joker-overlay__content,
.lotto-lucky-game-overlay .joker-overlay__content {
  background: #c50005;
}
.vikinglotto-classic-game-overlay .joker-overlay__content,
.vikinglotto-lightning-game-overlay .joker-overlay__content,
.vikinglotto-system-game-overlay .joker-overlay__content,
.vikinglotto-lucky-game-overlay .joker-overlay__content {
  background: #0000ff;
}
.joker-overlay__title {
  order: 1;
  color: white;
  font-size: 2.6rem;
  line-height: 1.25;
  font-weight: 700;
  padding: 0 2rem;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 768px) {
  .joker-overlay__title {
    font-size: 3.6rem;
    margin-bottom: 2.5rem;
  }
}
.joker-overlay__title span {
  color: #FEB700;
}
.joker-overlay__box {
  order: 3;
  user-select: none;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background: white;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 0.4rem solid transparent;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .joker-overlay__box {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 2rem;
  }
}
.joker-overlay__box.active {
  border-color: #FEB700;
  background: #fff8e4;
}
.joker-overlay__box--first {
  order: 2;
}
.joker-overlay__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.joker-overlay__header-logo {
  margin-bottom: 2.6rem;
}
.joker-overlay__header-logo .joker-overlay__logo-hat {
  height: 5rem;
  margin-right: 0;
}
.joker-overlay--medium .joker-overlay__logo {
  margin-bottom: 0.2rem;
}
.joker-overlay__logo-hat {
  height: 2.5rem;
  margin-right: 1rem;
}
.joker-overlay--medium .joker-overlay__logo-hat {
  height: 3.2rem;
}
.joker-overlay__logo-prefix {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 800;
  padding-right: 1rem;
}
@media screen and (min-width: 768px) {
  .joker-overlay__logo-prefix {
    font-size: 3.2rem;
  }
}
.joker-overlay__double .joker-overlay__logo {
  margin-bottom: -0.2rem;
}
.joker-overlay__logo-with-prefix .joker-overlay__pool-label {
  text-align: left;
}
.joker-overlay__logo-text {
  height: 2.2rem;
}
.lotto-classic-game-overlay .joker-overlay__logo-text,
.lotto-lightning-game-overlay .joker-overlay__logo-text,
.lotto-system-game-overlay .joker-overlay__logo-text,
.lotto-lucky-game-overlay .joker-overlay__logo-text {
  filter: invert(10%) sepia(85%) saturate(4939%) hue-rotate(351deg) brightness(101%) contrast(116%);
}
.vikinglotto-classic-game-overlay .joker-overlay__logo-text,
.vikinglotto-lightning-game-overlay .joker-overlay__logo-text,
.vikinglotto-system-game-overlay .joker-overlay__logo-text,
.vikinglotto-lucky-game-overlay .joker-overlay__logo-text {
  filter: invert(9%) sepia(80%) saturate(7106%) hue-rotate(246deg) brightness(100%) contrast(147%);
}
.joker-overlay--medium .joker-overlay__logo-text {
  height: 3rem;
}
.joker-overlay__logo-text {
  height: 2rem;
  margin-bottom: -0.2rem;
}
.joker-overlay__draw-pool {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1.5rem;
}
.joker-overlay__draw {
  font-size: 1.2rem;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.joker-overlay--medium .joker-overlay__draw {
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.6rem;
}
.joker-overlay__draw-value {
  font-size: 1.4rem;
  font-weight: bold;
}
.joker-overlay--medium .joker-overlay__draw-value {
  font-size: 1.6rem;
  padding-left: 0.5ch;
}
.joker-overlay__pool {
  font-size: 1.2rem;
  line-height: 1.3;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.joker-overlay--medium .joker-overlay__pool {
  font-size: 1.6rem;
  flex: 1;
}
.joker-overlay__pool-value {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: -0.2rem;
}
.joker-overlay--medium .joker-overlay__pool-value {
  font-size: 3.4rem;
  margin-bottom: 0;
  line-height: 1;
}
.joker-overlay__box-cta {
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  background: #FEB700;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  border-radius: 0.8rem;
  color: #000000;
  font-size: 1.6rem;
  padding: 0 1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 0.2rem solid transparent;
}
@media screen and (min-width: 768px) {
  .joker-overlay__box-cta {
    min-width: 16.5rem;
    min-height: 6rem;
  }
}
.joker-overlay__box-cta.active {
  background: white;
  border-color: #FEB700;
}
.joker-overlay__box-cta.unavailable {
  opacity: 0.5;
  cursor: not-allowed;
}
.joker-overlay__cta {
  order: 4;
  user-select: none;
  cursor: pointer;
  margin: 0.8rem 0;
  background: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  border-radius: 0.8rem;
  color: #000000;
  font-size: 1.6rem;
  padding: 0 2rem;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .joker-overlay__cta {
    min-height: 6rem;
    width: 34rem;
    margin: 2rem auto;
  }
}
.joker-overlay__cta.active {
  background: #FEB700;
}
.joker-overlay__read-more {
  order: 5;
  padding-bottom: 2rem;
}
.joker-overlay__read-more.active .joker-overlay__read-more-cta::after {
  transform: rotate(225deg);
  top: 1.6rem;
}
.joker-overlay__read-more.active .joker-overlay__read-more-text {
  transform: translateY(0);
  height: auto;
}
.joker-overlay__read-more-cta {
  user-select: none;
  color: white;
  font-size: 1.6rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 1rem 3.5rem 1rem 1rem;
  margin: 0 auto 0.5rem;
}
.joker-overlay__read-more-cta::after {
  content: "";
  position: absolute;
  z-index: 1;
  transition: transform 100ms ease-out;
  border: 0.3rem solid white;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  height: 0.9rem;
  width: 0.9rem;
  right: 1rem;
  top: 1rem;
}
.joker-overlay__read-more-text {
  height: 0;
  color: white;
  font-size: 1.6rem;
  line-height: 1.3;
  overflow: hidden;
  transform: translateY(-1rem);
  transition: transform 200ms cubic-bezier(0, 1.7, 0.3, 1);
}
.joker-overlay__read-more-text p {
  color: white;
  font-size: 1.6rem;
  line-height: 1.3;
  margin: 0 0 0.7rem;
}
