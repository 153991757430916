@import (reference) "../Mixins/_mixins.less";
@import url("../Mixins/_button.less");

@generic-overlay-animation-duration: .3s;

.generic-overlay {
  @keyframes generic-overlay-background-animation {
    0% {
      background-color: rgba(0,0,0,0);
    }
  
    100% {
      background-color: rgba(0,0,0,.3);
    }
  }

  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: generic-overlay-background-animation @generic-overlay-animation-duration forwards linear;

  @keyframes generic-overlay-panel-animation {
    0% {
      transform:translateY(-50%) scale(.85);
      filter: blur(.3rem);
      opacity: 0;
    }

    100% {
      transform: translateY(-50%) scale(1);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes generic-overlay-panel-remove-animation {
    0% {
      transform: translateY(-50%) scale(1);
      filter: blur(0);
      opacity: 1;
    }

    100% {
      transform:translateY(-50%) scale(.85);
      filter: blur(.3rem);
      opacity: 0;
    }
  }

  .panel {
    max-height: 86vh;
    max-width: 80%;
    width: 65rem;
    background: @ds-white;
    padding: 4rem;
    border-radius: 6px;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    overflow: auto;
    transform: translateY(-50%);
    animation: generic-overlay-panel-animation @generic-overlay-animation-duration forwards linear;

    .icon-wrapper {
      height: 16rem;
      margin-bottom: 3rem;
    }

    .image-caption {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 700;
    }

    .headline {
      font-size: 2.8rem;
      line-height: 3.4rem;
      margin-top: 0;
    }

    .subheading {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 700;
    }

    .text {
      max-width: 100%;
      width: 60rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 1.5rem auto 2.5rem;
      overflow: hidden;
      white-space: break-spaces;
    }
  
    .button {
      margin: auto;
    }
  }

  &.generic-overlay--is-shown {
    .panel {
      animation: unset;
    }
  }

  &.remove-overlay {
    animation: generic-overlay-background-animation @generic-overlay-animation-duration/2 reverse linear;

    .panel {
      animation: generic-overlay-panel-remove-animation @generic-overlay-animation-duration/2 forwards linear;
    }
  }

  .buttons {
    display: flex;
  }

  .generic-overlay__close-button {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    transition: transform .15s linear, filter .15s linear;
    will-change: transform;
    z-index: 1;
    
    .icon {
      width: 30%;
      height: 30%;
      transition: transform .15s linear;
      will-change: transform;
    }

    &:hover {
      transform: scale(1.1);
      .icon {
        transform: scale(1.3);
      }
    }
  }

  .generic-overlay__cta-button {
    max-width: 24rem;
    .btn();
    .btn-secondary();
    .btn-height-default();
    .btn-width-default();
    .btn-with-x-padding();
    .btn-hover-scale();
    display: flex;
    padding: 0;
    margin: auto;

    &.primary-button {
      .btn-primary();
    }
  }

  &.summer-campaign {
    .panel {
      background: radial-gradient(64.86% 53.9% at 67.2% 46.1%, #ED911B 0%, #D56429 100%);
      color: @ds-white;

      .generic-overlay__close-button .icon {
        fill: @ds-white;
      }

      .generic-overlay__cta-button::after {
        border: none;
        background-color: @ds-yellow;
      }
    }
  }


  &.christmas-2022-campaign {
    .panel {
      max-width: 80%;
      width: 94rem;
      background: @christmas-red-radial-gradient;
      border-radius: 40px;

      .icon-wrapper {
        height: auto;

        img {
          max-width: 100%;
        }
      }

      .headline, .text {
        color: @ds-white;
      }

      .generic-overlay__close-button .icon {
        fill: @ds-white;
      }

      .generic-overlay__cta-button {
        .christmas-button();
      }
    }
  }

  &.accordion-info-overlay__readmore,
  &.super-millionaire {
    .icon-wrapper {
      height: auto;
      margin-bottom: 0;
    }
    .generic-overlay__cta-button::after {
      border: none;
      background-color: @ds-yellow;
    }
  }

  &.campaign-overlay__custom-campaign {
    .themeColorsVars();
  
    .panel {
      background-color: var(--multiclient-campaign-tertiary-color-500, var(--multiclient-campaign-primary-color-500));
      color: white;
  
      .generic-overlay__close-button .icon {
        fill: white;
      }
  
      .icon-wrapper img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  
    .generic-overlay__cta-button {
      color: white;
  
      &::after {
        background-color: var(--multiclient-campaign-secondary-color-500);
        border-color: white;
      }
    }
  }
}


@keyframes generic-overlay-active-animation {
  0% {
    filter: blur(0);
  }

  100% {
    filter: blur(.3rem);
  }
}
.overlay-active {
  animation: generic-overlay-active-animation @generic-overlay-animation-duration forwards linear;
}

.remove-overlay {
  pointer-events: none;
  animation: generic-overlay-active-animation @generic-overlay-animation-duration/2 reverse linear;
}
