@import url("../Mixins/_mixins.less");

// Style coupon
.number-games-print-wrapper {
  display: none !important;
  visibility: hidden !important;
}

@media print {
  .lotto-system-game, .header-static-fix, .top-navigation, header,
  .notifications-static-fix, .global-footer, .lotto-footer-navigation,
  .eurojackpot-system-game, .eurojackpot-footer-navigation,
  .vikinglotto-system-game, .vikinglotto-footer-navigation, .js-boostAi {
    visibility: hidden;
    display: none !important;
  }

  .number-games-print-wrapper {
    min-height:calc(100vh - 10mm);
    display: block !important;
    visibility: visible !important;
    padding:5mm 0;
    .region-eurojackpot & {
      background-color:@numbergames-white;
    }

    .print-wrapper-header {
      text-align: center;
      padding-bottom: 7mm;

      .print-wrapper-header-icon {
        line-height: 1;

        .icon {
          width: 40mm;
          height: 7mm;
          .region-eurojackpot & {
            width:54mm;
            height:7.2mm;
          }
          .region-vikinglotto & {
            width:51mm;
            height:9mm;
            position:relative;
            top:2mm;
          }
        }

        .text {
          font-size: 10mm;
          font-weight: 600;
          color: @lotto-red;
          text-transform: uppercase;
          .region-eurojackpot & {
            color:@eurojackpot-carbon;
          }
          .region-vikinglotto & {
            color:@vikinglotto-blue;
          }
        }
      }
    }
    .print-wrapper-subheader {
      .row {
        font-size:3.5mm;
        font-weight:500;
        color:@numbergames-mediumgray;
        .numbers {
          font-weight:bold;
          color:@lotto-red;
          word-spacing:3mm;
          padding-left:3mm;
          .region-eurojackpot & {
            color:@eurojackpot-carbon;
          }
          .region-vikinglotto & {
            color:@vikinglotto-blue;
          }
        }
        .description-text {
          font-size:3mm;
          font-weight:normal;
          color:@numbergames-lightgray;
        }
      }
    }
    .print-wrapper-body {
      padding-bottom:40mm;

      .hidden {
        opacity: 0;
      }
    }
    .print-wrapper-footer {
      width:100%;
      position:absolute;
      bottom:0mm;
      page-break-inside: avoid;
      .footer-notes {
        line-height:0;
        padding-bottom:3mm;
        p {
          font-size:3mm;
          font-weight:normal;
          font-style:italic;
          color:@numbergames-mediumgray;
        }
      }
      .footer-bar {
        font-size:0;
        line-height:0;
        border-radius:1.5mm;
        padding:4mm 5mm 3.8mm;
        background-color:@lotto-red;
        -webkit-print-color-adjust:exact;
        box-sizing:border-box;
        page-break-inside: avoid;
        .region-eurojackpot & {
          background: rgb(187,127,54);
          background: -moz-linear-gradient(left, rgba(187,127,54,1) 0%, rgba(239,176,60,1) 25%, rgba(187,127,54,1) 50%, rgba(239,176,60,1) 75%, rgba(187,127,54,1) 100%);
          background: -webkit-linear-gradient(left, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
          background: linear-gradient(to right, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb7f36', endColorstr='#bb7f36',GradientType=1 );
        }
        .region-vikinglotto & {
          background-color:@vikinglotto-blue;
        }
        .icon-cube, .icon-logo {
          height:6mm;
        }
        .icon-cube {
          width:6mm;
        }
        .icon-logo {
          width:27mm;
          float:right;
          .region-eurojackpot & {
            width:35mm;
          }
        }
      }
    }
  }
}
