﻿@import url("../../Mixins/_mixins.less");

.region-eurojackpot {
  .promotion-spot {
    @media (min-width: @desktop-width) {
      padding: 5.3rem 9rem 7rem;
    }
  }

  .promotion-spot__headline {
    max-width: 86.5vw;
    font-size: 2.2rem;
    color: #fff;
    margin: 0 auto;

    @media (min-width: @mobile-width) {
      max-width: 50rem;
      font-size: 4rem;
      line-height: 4rem;
      margin: 1rem auto;
    }
  }

  .promotion-spot__info-text {
    max-width: 86.5vw;
    font-weight: normal;
    font-size: 3.73vw;
    color: #fff;

    @media (min-width: @mobile-width) {
      max-width: 45rem;
      min-height: 6.3rem;
      font-size: 2rem;
      line-height: 1.4;
      margin: 2.5rem auto 2.5rem;
    }
  }
}