﻿@import url("../Mixins/_mixins");

.joker-overlay {
  padding: 1rem 1.5rem;
  overflow: auto;
  box-sizing: border-box;

  @media @sm {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes joker-overlay-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  &.remove-overlay {
    animation: joker-overlay-fade-out 150ms linear;

    .joker-overlay__content {
      animation: joker-overlay-fade-out 150ms linear;
    }
  }
}

.joker-overlay--small {
  display: block;

  @media @sm {
    display: none;
  }
}

.joker-overlay--medium {
  display: none;
  flex: 1;
  flex-direction: row;
  align-items: center;

  @media @sm {
    display: flex;
  }
}

.joker-overlay__content {
  border: 0.1rem solid @ds-yellow;
  box-sizing: border-box;
  max-width: 40rem;
  background: @ds-black;
  border-radius: 1rem;
  font-size: 1.4rem;
  text-align: center;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2), 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem auto auto;

  @media @sm {
    max-width: 70rem;
    margin: auto;
    padding: 3rem 5rem 0;
  }

  .lotto-classic-game-overlay &,
  .lotto-lightning-game-overlay &,
  .lotto-system-game-overlay &,
  .lotto-lucky-game-overlay & {
    background: @ds-red;
  }

  .vikinglotto-classic-game-overlay &,
  .vikinglotto-lightning-game-overlay &,
  .vikinglotto-system-game-overlay &,
  .vikinglotto-lucky-game-overlay & {
    background: @ds-blue;
  }
}

.joker-overlay__title {
  order: 1;
  color: @ds-white;
  font-size: 2.6rem;
  line-height: 1.25;
  font-weight: 700;
  padding: 0 2rem;
  margin-bottom: 1.5rem;

  @media @sm {
    font-size: 3.6rem;
    margin-bottom: 2.5rem;
  }

  span {
    color: @ds-yellow;
  }
}

.joker-overlay__box {
  order: 3;
  user-select: none;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background: @ds-white;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 0.4rem solid transparent;
  color: @ds-black;

  @media @sm {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 2rem;
  }

  &.active {
    border-color: @ds-yellow;
    background: lighten(@ds-yellow, 45%);
  }

  &--first {
    order: 2;
  }
}

.joker-overlay__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.joker-overlay__header-logo {
  margin-bottom: 2.6rem;

  .joker-overlay__logo-hat {
    height: 5rem;
    margin-right: 0;
  }
}

.joker-overlay--medium .joker-overlay__logo {
  margin-bottom: 0.2rem;
}

.joker-overlay__logo-hat {
  height: 2.5rem;
  margin-right: 1rem;
}

.joker-overlay--medium .joker-overlay__logo-hat {
  height: 3.2rem;
}

.joker-overlay__logo-prefix {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 800;
  padding-right: 1rem;
  @media @sm {
    font-size: 3.2rem;
  }
}

.joker-overlay__double .joker-overlay__logo {
  margin-bottom: -0.2rem;
}

.joker-overlay__logo-with-prefix .joker-overlay__pool-label {
  text-align: left;
}

.joker-overlay__logo-text {
  height: 2.2rem;

  .lotto-classic-game-overlay &,
  .lotto-lightning-game-overlay &,
  .lotto-system-game-overlay &,
  .lotto-lucky-game-overlay & {
    // this will turn the svg color into @ds-red. made with https://codepen.io/dsbau/pen/bGqORvK
    filter: invert(10%) sepia(85%) saturate(4939%) hue-rotate(351deg) brightness(101%) contrast(116%);
  }

  .vikinglotto-classic-game-overlay &,
  .vikinglotto-lightning-game-overlay &,
  .vikinglotto-system-game-overlay &,
  .vikinglotto-lucky-game-overlay & {
    // this will turn the svg color into @ds-blue. made with https://codepen.io/dsbau/pen/bGqORvK
    filter: invert(9%) sepia(80%) saturate(7106%) hue-rotate(246deg) brightness(100%) contrast(147%);
  }
}

.joker-overlay--medium .joker-overlay__logo-text {
  height: 3rem;
}

.joker-overlay__logo-text {
  height: 2rem;
  margin-bottom: -0.2rem;
}

.joker-overlay__draw-pool {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1.5rem;
}

.joker-overlay__draw {
  font-size: 1.2rem;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.joker-overlay--medium .joker-overlay__draw {
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.6rem;
}

.joker-overlay__draw-value {
  font-size: 1.4rem;
  font-weight: bold;
}

.joker-overlay--medium .joker-overlay__draw-value {
  font-size: 1.6rem;
  padding-left: 0.5ch;
}

.joker-overlay__pool {
  font-size: 1.2rem;
  line-height: 1.3;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.joker-overlay--medium .joker-overlay__pool {
  font-size: 1.6rem;
  flex: 1;
}

.joker-overlay__pool-value {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: -0.2rem;
}

.joker-overlay--medium .joker-overlay__pool-value {
  font-size: 3.4rem;
  margin-bottom: 0;
  line-height: 1;
}

.joker-overlay__box-cta {
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  background: @ds-yellow;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  border-radius: 0.8rem;
  color: @ds-black;
  font-size: 1.6rem;
  padding: 0 1rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 0.2rem solid transparent;

  @media @sm {
    min-width: 16.5rem;
    min-height: 6rem;
  }

  &.active {
    background: @ds-white;
    border-color: @ds-yellow;
  }

  &.unavailable {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.joker-overlay__cta {
  order: 4;
  user-select: none;
  cursor: pointer;
  margin: 0.8rem 0;
  background: @ds-white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  border-radius: 0.8rem;
  color: @ds-black;
  font-size: 1.6rem;
  padding: 0 2rem;
  font-weight: 700;
  text-transform: uppercase;

  @media @sm {
    min-height: 6rem;
    width: 34rem;
    margin: 2rem auto;
  }

  &.active {
    background: @ds-yellow;
  }
}

.joker-overlay__read-more {
  order: 5;
  padding-bottom: 2rem;

  &.active {
    .joker-overlay__read-more-cta {
      &::after {
        transform: rotate(225deg);
        top: 1.6rem;
      }
    }

    .joker-overlay__read-more-text {
      transform: translateY(0);
      height: auto;
    }
  }
}

.joker-overlay__read-more-cta {
  user-select: none;
  color: @ds-white;
  font-size: 1.6rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 1rem 3.5rem 1rem 1rem;
  margin: 0 auto 0.5rem;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    transition: transform 100ms ease-out;
    border: 0.3rem solid @ds-white;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
    height: 0.9rem;
    width: 0.9rem;
    right: 1rem;
    top: 1rem;
  }
}

.joker-overlay__read-more-text {
  height: 0;
  color: @ds-white;
  font-size: 1.6rem;
  line-height: 1.3;
  overflow: hidden;
  transform: translateY(-1rem);
  transition: transform 200ms cubic-bezier(0, 1.7, 0.3, 1);

  p {
    color: @ds-white;
    font-size: 1.6rem;
    line-height: 1.3;
    margin: 0 0 0.7rem;
  }
}
