/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/**
 * Colors for Subscription
 */
/**
 * Primary
 */
/**
 * Text
 * Mobile font sizes have been halved
 */
/**
 * Buttons
 * Mobile font sizes have been halved
 */
.content-size-xs,
.content-size-sm,
.content-size-md,
.content-size-lg,
.content-size-full {
  width: 100%;
  margin: 0 auto 2rem;
}
.content-size-xs {
  max-width: 42rem;
}
.content-size-sm {
  max-width: 48rem;
}
.content-size-md {
  max-width: 52rem;
}
.content-size-lg {
  max-width: 60rem;
}
.content-size-full {
  max-width: none;
}
.content-size-extra-top {
  margin-top: 1rem !important;
}
.content-size-less-top {
  margin-top: -1rem !important;
}
.content-size-extra-bottom {
  margin-bottom: 3rem !important;
}
.content-size-less-bottom {
  margin-bottom: 1rem !important;
}
:root {
  --highlightBackground: #eeeeee;
}
:root .region-vikinglotto,
:root .region-avalon-vikinglotto {
  --highlightBackground: #0000ff;
}
:root .region-allornothing,
:root .region-avalon-allornothing {
  --highlightBackground: #191339;
}
:root .region-eurojackpot,
:root .region-avalon-eurojackpot {
  --highlightBackground: #151515;
}
:root .region-keno,
:root .region-avalon-keno {
  --highlightBackground: #00a5eb;
}
:root .region-lotto,
:root .region-avalon-lotto {
  --highlightBackground: #c50005;
}
:root .region-playtogether,
:root .region-avalon-playtogether {
  --highlightBackground: #094366;
}
:root .region-quick,
:root .region-avalon-quick {
  --highlightBackground: #7f4488;
}
:root .region-subscription,
:root .region-avalon-subscription {
  --highlightBackground: #2e3535;
}
.card-cta {
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 4rem;
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}
.card-cta__wrapper {
  display: block;
  padding: 0 2rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .card-cta__wrapper {
    padding: 0 1.5rem;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1216px) {
  .card-cta__wrapper {
    padding: 0 5rem;
    width: 100%;
    max-width: 1440px;
  }
}
.card-cta__highlight {
  color: #FFFFFF !important;
  background-color: var(--highlightBackground, #eeeeee) !important;
}
.card-cta__card {
  color: #000000;
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  flex-direction: column;
  background-color: #eeeeee;
  border-radius: 3rem;
  border-bottom: #dbdbdb 0.4rem solid;
  border-right: #dbdbdb 0.4rem solid;
  padding: 2.5rem 0;
  margin-bottom: 4rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .card-cta__card {
    width: 46%;
    margin: 1rem;
  }
}
@media screen and (min-width: 1216px) {
  .card-cta__card {
    width: 0;
    flex: 1 1 0;
  }
}
@media screen and (min-width: 1216px) {
  .card-cta__card:first-child {
    margin-left: 0;
  }
  .card-cta__card:last-child {
    margin-right: 0;
  }
}
.card-cta__img {
  width: 12rem;
  height: 12rem;
  margin-left: auto;
  margin-right: auto;
}
.card-cta__small-header {
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
  padding: 1rem 1.6rem 0;
  margin: 0;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .card-cta__small-header {
    line-height: 1.6rem;
  }
}
@media screen and (min-width: 1216px) {
  .card-cta__small-header {
    line-height: 1.6rem;
  }
}
.card-cta__text {
  line-height: 1.6rem;
  overflow-wrap: break-word;
  font-size: 1.2rem;
  padding: 0 1.6rem 2.2rem;
  margin: 0;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .card-cta__text {
    padding: 0 1.6rem 2.2rem;
  }
}
@media screen and (min-width: 950px) {
  .card-cta__text {
    margin-bottom: 0;
  }
}
.card-cta__large-header {
  font-size: 2rem;
  line-height: 2.7rem;
  margin: 0 0 0.5rem 0 !important;
  padding: 0 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
  overflow-wrap: break-word;
}
@media screen and (min-width: 768px) {
  .card-cta__large-header {
    padding: 0 1.6rem 0;
    font-size: 2rem !important;
    line-height: 2.7rem !important;
  }
}
.card-cta__cta {
  background: #feb700;
  color: #000000;
  border-radius: 2.5rem;
  font-size: 1.2rem;
  text-align: center;
  padding: 0;
  line-height: 4.3rem;
  padding-top: 0.1rem;
  font-weight: 800;
  display: inline-block;
  position: relative;
  outline: none;
  border: none;
  text-decoration: none;
  vertical-align: top;
  overflow: hidden;
  box-sizing: border-box;
  width: auto;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: 0.2s ease-out;
  margin: auto 1.6rem 0;
}
.card-cta__cta:hover {
  transition: 0.2s ease-in 0.05s;
  margin: auto 1.2rem 0;
  cursor: pointer;
}
.card-cta__cta:active,
.card-cta__cta:focus {
  background: #f5d900;
}
.card-cta__label {
  width: 12.8rem;
  height: 8.7rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.card-cta__label-text {
  font-weight: 800;
  font-size: 1.2rem;
  color: #FFFFFF;
  text-align: center;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  position: relative;
  padding: 0.6rem 0;
  left: -5.2rem;
  top: 1.2rem;
  width: 17.4rem;
  background-color: #ff030a;
}
