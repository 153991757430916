@import (reference) "Mixins/_mixins.less";

.hd {
  width: 100%;
  max-width: @eurojackpot-device-width;
  margin: 0 auto;
  text-align: inherit;
  display: block;

  small {
    display: block;
  }
}

.hd,
.label,
.span,
.p {

  &.xxsmall {
    font-size: 5.4vw;
    line-height: 5.9vw;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  &.xsmall {
    font-size: 2.1vw;
    line-height: 3.1vw;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  &.small {
    font-size: 4.3vw;
    line-height: 4.8vw;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 3rem;
      line-height: 3.6rem;
    }
  }

  &.large {
    font-size: 5.4vw;
    line-height: 5.6vw;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 3.5rem;
      line-height: 4.2rem;
    }
  }

  &.xlarge {
    font-size: 10.7vw;
    line-height: 11.2vw;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  &.xxlarge {
    font-size: 14.7vw;
    line-height: normal;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 6rem;
      line-height: 7.2rem;
    }
  }

  &.xxxlarge {
    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 8rem;
      line-height: normal;
    }
  }

  &.italic {
    font-style: italic;
  }

  &.semibold {
    font-weight: 600;
  }

  &.bold {
    font-weight: bold;
  }

  &.red {
    //color: @eurojackpot-red;
  }

  &.white {
    color: @eurojackpot-white;
  }

  &.mediumgray {
    color: @eurojackpot-mediumgray;
  }
}
