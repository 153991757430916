/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.biggest-wins-overview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 100rem;
  padding: 2.5rem 2rem;
  align-items: center;
}
@media screen and (min-width: 950px) {
  .biggest-wins-overview {
    flex-direction: row;
  }
}
.biggest-wins-overview-table {
  position: relative;
  border-radius: 20px;
  max-width: 42.7rem;
  min-width: 32.0rem;
  overflow: hidden;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 1px;
  order: 2;
  width: 100%;
}
@media screen and (min-width: 950px) {
  .biggest-wins-overview-table {
    order: initial;
    width: 100%;
  }
}
.biggest-wins-overview-table__header {
  height: 6.4rem;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.biggest-wins-overview-table__header-cell:first-child {
  width: 45%;
}
.biggest-wins-overview-table__header-cell:last-child {
  width: 55%;
}
.biggest-wins-overview-table__content {
  color: #000000;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
}
.biggest-wins-overview-table__content-row {
  display: flex;
  font-weight: 700;
  height: 5.6rem;
  align-items: center;
  /* Vertically center content */
}
.biggest-wins-overview-table__content-cell {
  box-sizing: border-box;
  text-align: center;
}
.biggest-wins-overview-table__content-cell:first-child {
  width: 45%;
}
.biggest-wins-overview-table__content-cell:first-child > span {
  width: 4rem;
}
.biggest-wins-overview-table__content-cell:last-child {
  width: 55%;
}
.biggest-wins-overview-table__content-cell:last-child > span {
  width: 13.5rem;
}
.biggest-wins-overview-table__content-cell > span {
  text-align: right;
  display: inline-block;
}
.biggest-wins-overview-text {
  position: relative;
  order: 1;
  padding-bottom: 4rem;
}
@media screen and (min-width: 950px) {
  .biggest-wins-overview-text {
    order: initial;
    padding-bottom: initial;
    max-width: 42.7rem;
    padding-left: 13rem;
  }
}
.biggest-wins-overview-text__content {
  color: #92969E;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  text-align: left;
}
@media screen and (min-width: 950px) {
  .biggest-wins-overview-text__content {
    font-size: 1.6rem;
    width: 100%;
  }
}
.biggest-wins-overview-text__content-cta {
  margin-top: 1.8rem;
  display: none;
}
@media screen and (min-width: 950px) {
  .biggest-wins-overview-text__content-cta {
    display: inline-flex;
  }
}
.biggest-wins-overview-cta {
  position: relative;
  order: 3;
  margin-top: 3rem;
}
@media screen and (min-width: 950px) {
  .biggest-wins-overview-cta {
    order: initial;
    display: none;
  }
}
.biggest-wins-overview--lotto .biggest-wins-overview-table__content {
  border-left: solid 1px rgba(197, 0, 5, 0.05);
  border-right: solid 1px rgba(197, 0, 5, 0.05);
  border-bottom: solid 1px rgba(197, 0, 5, 0.05);
}
.biggest-wins-overview--lotto .biggest-wins-overview-table__header {
  background-color: #c50005;
  color: #fff;
}
.biggest-wins-overview--lotto .biggest-wins-overview-table__content-row:nth-child(2n-1) {
  background-color: rgba(197, 0, 5, 0.05);
}
.biggest-wins-overview--vikinglotto .biggest-wins-overview-table__content {
  border-left: solid 1px rgba(0, 0, 255, 0.05);
  border-right: solid 1px rgba(0, 0, 255, 0.05);
  border-bottom: solid 1px rgba(0, 0, 255, 0.05);
}
.biggest-wins-overview--vikinglotto .biggest-wins-overview-table__header {
  background-color: #0000ff;
  color: #fff;
}
.biggest-wins-overview--vikinglotto .biggest-wins-overview-table__content-row:nth-child(2n-1) {
  background-color: rgba(0, 0, 255, 0.05);
}
.biggest-wins-overview--eurojackpot .biggest-wins-overview-table__content {
  border-left: solid 1px #FDF6EA;
  border-right: solid 1px #FDF6EA;
  border-bottom: solid 1px #FDF6EA;
}
.biggest-wins-overview--eurojackpot .biggest-wins-overview-table__header {
  background-color: #353535;
  color: #fff;
}
.biggest-wins-overview--eurojackpot .biggest-wins-overview-table__content-row:nth-child(2n-1) {
  background-color: #FDF6EA;
}
.biggest-wins-overview__btn-cta {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 5.6rem;
  min-width: 24rem;
}
.biggest-wins-overview__btn-cta:not(:disabled):hover::after {
  transform: scale(1.03);
}
.biggest-wins-overview__btn-cta:after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0.1rem solid transparent;
  background-color: #FEB700;
  border-color: #FEB700;
}
