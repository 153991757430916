.multi-packages-section {
  position: relative;
  transform: translateZ(0);
  padding: 4rem 0 2rem;
  background-color: white;
}
.multi-packages-section .popover-drawer {
  height: 100%;
  border-bottom: 1px solid #000000;
}
.multi-packages-section .popover-drawer .popover-drawer__card {
  height: 96%;
}
.multi-packages-section .responsive-bg {
  justify-content: center;
  top: 5rem;
}
.multi-packages-header {
  max-width: 74rem;
  margin: auto;
  text-align: center;
  padding: 0 2.4rem;
}
@media screen and (min-width: 768px) {
  .multi-packages-header {
    padding: 0;
  }
}
.multi-packages-header .multi-packages-header__heading {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .multi-packages-header .multi-packages-header__heading {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}
.multi-packages-header .multi-packages-header__subheading {
  max-width: 52rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 600;
  margin: 1.6rem auto 0;
}
.multi-packages-header + .multi-packages-inner-container {
  margin-top: 4rem;
}
@media screen and (min-width: 768px) {
  .multi-packages-header + .multi-packages-inner-container {
    margin-top: 0;
  }
}
.multi-packages-inner-container {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .multi-packages-inner-container .multi-packages-tabs__wrapper {
    margin-top: 2.4rem;
  }
}
.multi-packages-inner-container .multi-packages-tabs {
  margin: 0 2.4rem;
  background-color: rgba(255, 255, 255, 0.8);
}
.multi-packages-packages-list,
.multi-packages-packages-list.tns-autowidth {
  width: fit-content;
  display: inline-flex;
  padding: 2.4rem 0 3.4rem;
  box-sizing: border-box;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .multi-packages-packages-list,
  .multi-packages-packages-list.tns-autowidth {
    display: flex;
    align-items: flex-end;
    padding: 4rem 0 0;
  }
}
.multi-packages-package-card__divider {
  width: 12.8rem;
  color: #92969E;
  font-family: "Shadows Into Light", cursive;
  text-align: center;
  padding: 0 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.multi-packages-package-card__divider-text {
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 500;
}
.multi-packages-tabs__wrapper {
  display: flex;
  justify-content: center;
}
.multi-packages-tabs {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  border: 1px solid #a2a2a2;
  border-radius: 28px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .multi-packages-tabs {
    width: fit-content;
  }
}
.multi-packages-tabs .multi-packages-tab {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.8rem;
  transition: opacity 0.15s linear, color 0.15s 0.1s linear;
  cursor: pointer;
  position: relative;
  margin: 0 0.4rem;
  text-align: center;
  flex: 1;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .multi-packages-tabs .multi-packages-tab {
    font-size: 1.6rem;
    line-height: 1.4rem;
    padding: 1rem 2rem;
    font-weight: 800;
  }
}
.multi-packages-tabs .multi-packages-tab:hover:not(.multi-packages-tab--active) {
  opacity: 0.6;
}
.multi-packages-tabs .multi-packages-tab--active {
  color: white;
}
.multi-packages-tabs .multi-packages-tab__active-indicator {
  position: absolute;
  height: 100%;
  transition: width 0.15s linear, left 0.15s linear, background-color 0.15s linear;
  scale: 1.15;
  border-radius: 28px;
  left: 0;
}
.region-lotto .multi-packages-tabs .multi-packages-tab__active-indicator {
  background-color: #c50005;
}
.region-vikinglotto .multi-packages-tabs .multi-packages-tab__active-indicator {
  background-color: #0000ff;
}
.region-eurojackpot .multi-packages-tabs .multi-packages-tab__active-indicator {
  background-color: #e8a332;
}
.multi-packages-tabs .multi-packages-tab__active-indicator.multi-packages-tab__active-indicator--lotto {
  background-color: #c50005;
}
.multi-packages-tabs .multi-packages-tab__active-indicator.multi-packages-tab__active-indicator--vikinglotto {
  background-color: #0000ff;
}
.multi-packages-tabs .multi-packages-tab__active-indicator.multi-packages-tab__active-indicator--eurojackpot {
  background-color: #e8a332;
}
.multi-packages-tabs .multi-packages-tab__active-indicator.multi-packages-tab__active-indicator--joker {
  background-color: #00b06e;
}
.multi-packages-button {
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: #d4d4d4;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  color: #000000;
  transition: color 200ms ease-out;
  height: 5.6rem;
  padding-left: 2rem;
}
.multi-packages-button::after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.multi-packages-button::after {
  background-color: #FEB700;
  border-color: #FEB700;
}
.multi-packages-button:not(.btn--disabled):not(.btn--loading):active::after {
  background-color: #f5da00;
  border-color: #f5da00;
}
.multi-packages-button.btn--disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.multi-packages-button.btn--loading {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.multi-packages-button.btn--loading::before {
  content: "";
  border-radius: 2.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 0 0 #ffce51;
  animation: btn-pulse-inner-loading-animation 1.3s infinite;
}
.multi-packages-button.btn--loading::after {
  animation: btn-pulse-loading-animation 1.3s infinite;
}
.multi-packages-button:not(.btn--disabled):hover::after {
  transform: scale(1.03);
}
.multi-packages-button.multi-packages-button--outlined::after {
  background-color: white;
}
