.icon.icon-lotto_logo_vindertal {
  width: 26vw;
  height: 7vw;
  margin: 0;
}
@media (min-width: 960px) {
  .icon.icon-lotto_logo_vindertal {
    width: 126px;
    height: 28px;
    margin: 0;
  }
}
.icon.icon-eurojackpot_logo {
  width: 48vw;
  height: 7vw;
  margin: 0;
}
@media (min-width: 960px) {
  .icon.icon-eurojackpot_logo {
    width: 200px;
    height: 28px;
    margin: 0;
  }
}
.icon.icon-joker_logo_vindertal {
  width: 26vw;
  height: 7vw;
  margin: 0;
}
@media (min-width: 960px) {
  .icon.icon-joker_logo_vindertal {
    width: 123px;
    height: 28px;
    margin: 0;
  }
}
.icon.icon-joker_hue_for_white_bg {
  width: 15vw;
  height: 7vw;
  margin: 0;
}
@media (min-width: 960px) {
  .icon.icon-joker_hue_for_white_bg {
    width: 60px;
    height: 28px;
    margin: 0;
  }
}
