@import url("../Mixins/_mixins.less");

.two-column-banner {
  max-width: @content-max-width;
  display: flex;
  padding: 8rem 1.7rem;
  flex-direction: column;
  margin: auto;

  @media @sm {
    flex-direction: row;
    padding: 5.6rem 2.4rem;
  }
}

.two-column-banner__columns {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;

  @media @sm {
    flex-direction: row;
  }
}

.two-column-banner__column-one,
.two-column-banner__column-two {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 51rem;

  @media @sm {
    box-sizing: border-box;
    flex: 1 1 0;
  }
}

.two-column-banner__column-one {
  @media @sm {
    max-width: 43rem;
  }
}

.two-column-banner__column-media {
  .responsive-bg {
    position: relative;
    align-items: center;

    img {
      max-width: 100%;
    }

    .responsive-lottie-bg  {
      z-index: 0;
    }
  }
}

.two-column-banner__column-information {
  text-align: center;

  @media @sm {
    text-align: left;
  }
}

.two-column-banner__column--header {
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.6rem;
  word-wrap: anywhere;
  margin: auto;
}

.two-column-banner__column-teaser {
  color: @numbergames-blue-grey;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
}

.two-column-banner__cta {
  min-width: 24rem;
  height: 5.6rem;
  padding: 2.2rem 2.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  margin-top: 3.2rem;
  color: @ds-black;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
}
