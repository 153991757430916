.eurojackpot-fast-play {
  padding: 8vw;
}
@media (min-width: 950px) {
  .eurojackpot-fast-play {
    padding: 6rem 7.7rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-game-promotion-spot {
    display: flex;
  }
}
.eurojackpot-game-promotion-spot .eurojackpot-section {
  background-color: #0a0a0a;
  flex: 1;
}
.eurojackpot-game-promotion-spot .eurojackpot-section .min-height {
  min-height: 4.5rem;
}
@media (min-width: 950px) {
  .eurojackpot-game-promotion-spot .eurojackpot-section.small {
    flex: 1;
  }
  .eurojackpot-game-promotion-spot .eurojackpot-section.small .eurojackpot-content-wrapper {
    width: 48rem;
  }
}
.eurojackpot-game-promotion-spot .eurojackpot-section.small:nth-child(1) {
  background: #171717;
}
.eurojackpot-game-promotion-spot .eurojackpot-section.small:nth-child(1) .eurojackpot-content-wrapper {
  margin-right: 0;
}
.eurojackpot-game-promotion-spot .eurojackpot-section.small:nth-child(2) {
  background: #201e20;
}
.eurojackpot-game-promotion-spot .eurojackpot-section.small:nth-child(2) .eurojackpot-content-wrapper {
  margin-left: 0;
}
@media (max-width: 960px) {
  .eurojackpot-game-promotion-spot .eurojackpot-section.small {
    display: block;
    width: 100%;
  }
}
.eurojackpot-game-promotion-spot .eurojackpot-section .info-text {
  color: #fff;
  line-height: 5vw;
  font-weight: 300;
  margin: 4vw auto;
  font-size: 3.73vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-promotion-spot .eurojackpot-section .info-text {
    max-width: 45rem;
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1.5rem auto;
  }
}
.eurojackpot-game-promotion-spot .eurojackpot-section img {
  padding: 0 0 5vw;
}
@media (min-width: 950px) {
  .eurojackpot-game-promotion-spot .eurojackpot-section img {
    padding: 0 0 4rem;
  }
}
