@import url("../variables.less");

.top-navigation-theme-eurojackpot { 
  
  @theme-color-a: #74653c; 
  @theme-color-b: #bd903d;

  .menu-toggle-right {
    .is-mobile-detected & {
      background-color: @theme-color-b;
    }
  }
  .brand-nav {
    //background-color: @theme-color-b;
    background: rgb(187,127,54);
    background: -moz-linear-gradient(left, rgba(187,127,54,1) 0%, rgba(239,176,60,1) 25%, rgba(187,127,54,1) 50%, rgba(239,176,60,1) 75%, rgba(187,127,54,1) 100%);
    background: -webkit-linear-gradient(left, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
    background: linear-gradient(to right, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb7f36', endColorstr='#bb7f36',GradientType=1 );
  }
  .corporate-nav {
    //background-color: @theme-color-a;
    background: rgb(187,127,54);
    background: -moz-linear-gradient(left, rgba(187,127,54,1) 0%, rgba(239,176,60,1) 25%, rgba(187,127,54,1) 50%, rgba(239,176,60,1) 75%, rgba(187,127,54,1) 100%);
    background: -webkit-linear-gradient(left, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
    background: linear-gradient(to right, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb7f36', endColorstr='#bb7f36',GradientType=1 );
    border-bottom:1px solid white;
  }
  .mobile-navigation {
    &-header {
      //background-color: @theme-color-b;
      background: rgb(187,127,54);
      background: -moz-linear-gradient(left, rgba(187,127,54,1) 0%, rgba(239,176,60,1) 25%, rgba(187,127,54,1) 50%, rgba(239,176,60,1) 75%, rgba(187,127,54,1) 100%);
      background: -webkit-linear-gradient(left, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
      background: linear-gradient(to right, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb7f36', endColorstr='#bb7f36',GradientType=1 );
    }
    &-footer {
      &-icon {
        //background-color: @theme-color-b; 
        background: rgb(187,127,54);
        background: -moz-linear-gradient(left, rgba(187,127,54,1) 0%, rgba(239,176,60,1) 25%, rgba(187,127,54,1) 50%, rgba(239,176,60,1) 75%, rgba(187,127,54,1) 100%);
        background: -webkit-linear-gradient(left, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
        background: linear-gradient(to right, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb7f36', endColorstr='#bb7f36',GradientType=1 );
      }
    }
    &-item {
      &-link {
        &:before {
          //background-color: @theme-color-b;
          background: rgb(187,127,54);
          background: -moz-linear-gradient(left, rgba(187,127,54,1) 0%, rgba(239,176,60,1) 25%, rgba(187,127,54,1) 50%, rgba(239,176,60,1) 75%, rgba(187,127,54,1) 100%);
          background: -webkit-linear-gradient(left, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
          background: linear-gradient(to right, rgba(187,127,54,1) 0%,rgba(239,176,60,1) 25%,rgba(187,127,54,1) 50%,rgba(239,176,60,1) 75%,rgba(187,127,54,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bb7f36', endColorstr='#bb7f36',GradientType=1 );
        }
      }
    }

  }

  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: @theme-color-b;
        color: white;
        &:hover {
          background-color: @theme-color-a;
        }
      }
    }
  }
}
