@import (reference) "Mixins/_mixins.less";

.icon {

  &.icon-lotto_logo_vindertal {
    width: 26vw;
    height: 7vw;
    margin: 0;

    @media (min-width: @package-desktop-width) {
      width: 126px;
      height: 28px;
      margin: 0;
    }
  }

  &.icon-eurojackpot_logo {
    width: 48vw;
    height: 7vw;
    margin: 0;

    @media (min-width: @package-desktop-width) {
      width: 200px;
      height: 28px;
      margin: 0;
    }
  }

  &.icon-joker_logo_vindertal {
    width: 26vw;
    height: 7vw;
    margin: 0;

    @media (min-width: @package-desktop-width) {
      width: 123px;
      height: 28px;
      margin: 0;
    }
  }

  &.icon-joker_hue_for_white_bg {
    width: 15vw;
    height: 7vw;
    margin: 0;

    @media (min-width: @package-desktop-width) {
      width: 60px;
      height: 28px;
      margin: 0;
    }
  }
}
