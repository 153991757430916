﻿@import url("../Mixins/_mixins.less");

.product-links-cta {
  position: relative;
  width: 100%;
  max-width: initial;
  margin: 0 auto;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 2rem 2rem 6rem 2rem;
  box-sizing: border-box;

  &__content-wrapper {
    margin: 0 auto;

    @media @sm {
      max-width: 76rem;
    }
  }

  & * {
    box-sizing: border-box;
  }

  &--plus {
    .product-links-cta__item-image {
      background-color: @ds-green-200;
    }

    h3.product-links-cta__item-title {
      color: @ds-green-200;
    }
  }

  &--lotto {
    .product-links-cta__item-image {
      background-color: @ds-red;
    }

    h3.product-links-cta__item-title {
      color: @ds-red;
    }
  }

  &--vikinglotto {
    .product-links-cta__item-image {
      background-color: @ds-blue;
    }

    h3.product-links-cta__item-title {
      color: @ds-blue;
    }
  }

  &--eurojackpot {
    .product-links-cta__item-image {
      background-color: @ds-honey;
    }

    h3.product-links-cta__item-title {
      color: @ds-black;
    }
  }
}

h2.product-links-cta__header {
  font-size: 2.8rem;
  line-height: 3.4rem;
  text-align: center;
  margin: 2rem auto;

  @media @sm {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

.product-links-cta__content {
  position: relative;
  color: @ds-gray-525;
  text-align: center;
  font-weight: 600;

  @media @sm {
    margin: 0 auto;
  }
}

.product-links-cta__items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4.8rem;
  row-gap: 2rem;
  margin: 4.8rem auto 0 auto;
  max-width:24rem;

  @media @sm {
    row-gap: normal;
    margin: 6rem auto 0 auto;
    max-width:initial;
  }
}

.product-links-cta__item {
  position: relative;
  display: flex;
  flex-basis: 9rem;
  flex-direction: column;
  text-decoration: none;
  min-height: 15rem;

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  @media @sm {
    flex-basis: 14rem;
    min-height: 30rem;
  }
}

.product-links-cta__item-image {
  position: relative;
  width: 8.4rem;
  height: 8.4rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 4.2rem 4.2rem;
  border-radius: 2rem;
  margin: 0 auto;
  background-color: @ds-gray-100; /* Light grey background for visibility */

  @media @sm {
    width: 12rem;
    height: 12rem;
    border-radius: 2.6rem;
    background-size: 6.8rem 6.8rem;
  }
}

h3.product-links-cta__item-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0.7rem 0 0rem 0;
  letter-spacing: 0.1rem;
  text-align: center;

  @media @sm {
    font-size: 1.6rem;
    margin: 1.4rem 0 1.8rem 0;
  }
}

.product-links-cta__item-text {
  color: @ds-gray-475;
  font-weight: 600;
  line-height: 1.8rem;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  max-width: 9rem;
  text-align: center;
  margin: 0 auto -2rem auto;

  @media @sm {
    max-width: 11.5rem;
    font-size: 1.6rem;
  }
}

.product-links-cta__item-container {
  position: relative;
  margin-top: auto;
  text-align: center;
}

.product-links-cta__item-button {
  position: relative;
  display: none;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  color: @ds-black;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 5.6rem;
  max-width: 14rem;
  width: 100%;

  @media @sm {
    display: inline-flex;
  }

  &:not(:disabled):hover::after {
    transform: scale(1.03);
  }

  &:after {
    border-radius: 2.8rem;
    transition: transform 200ms ease-out;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.1rem solid transparent;
    background-color: @ds-yellow;
    border-color: @ds-yellow;
  }
}
