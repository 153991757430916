.multi-client-game-selector__wrapper {
  width: 100%;
  position: absolute;
  top: 3.4rem;
  left: 0;
  padding: 0 0 1rem;
  box-sizing: border-box;
  overflow-x: auto;
  pointer-events: all;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .multi-client-game-selector__wrapper {
    width: auto;
    position: relative;
    top: initial;
    left: initial;
    padding: 0;
  }
}
.multi-client-game-selector {
  display: inline-flex;
  border-radius: 2.8rem;
  border: 0.1rem solid #eeeeee;
  margin: 0 1.5rem;
}
.multi-client-game-selector .game-selector-button {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2rem;
  text-transform: uppercase;
  padding: .9rem 2rem;
  color: #000000;
  border-radius: 2.8rem;
  cursor: pointer;
  pointer-events: auto;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .multi-client-game-selector .game-selector-button {
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding: .9rem 3rem;
  }
}
.multi-client-game-selector .game-selector-button.active {
  color: white;
}
.multi-client-wrapper__eurojackpot .multi-client-game-selector .game-selector-button.active {
  background-color: #E8A332;
}
.multi-client-wrapper__lotto .multi-client-game-selector .game-selector-button.active {
  background-color: #c50005;
}
.multi-client-wrapper__vikinglotto .multi-client-game-selector .game-selector-button.active {
  background-color: #0000ff;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-game-selector .game-selector-button.active {
  background-color: #E95801;
}
