@import url("../Mixins/_mixins");

.winning-numbers__component {
  background-color: @ds-white;
  padding: 4rem 2rem;
}

.winning-numbers {
  max-width: 88rem;
  display: grid;
  justify-content: center;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "select" "result";

  @media @lg {
    margin: 7rem auto;
    grid-template-columns: 31rem 1fr;
    column-gap: 10rem;
    grid-template-areas: "select result" ;
  }

  @media @xxl {
    max-width: 110rem;
    column-gap: 14rem;
  }
}

.winning-numbers__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}

.winning-numbers__result {
  grid-area: result;
}

.winning-numbers__select {
  grid-area: select;
}

.winning-numbers__header {
  max-width: 60rem;

  @media @lg {
    min-height: 20rem;
  }

  @media @xxl {
    min-height: 24rem;
  }
}

.winning-numbers__title {
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-align: center;
  margin-bottom: 1.3rem;

  @media @lg {
    text-align: left;
  }

  @media @xxl {
    font-size: 4rem;
  }
}

.winning-numbers__subtitle {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-align: center;
  margin-bottom: 3.5rem;

  @media @lg {
    text-align: left;
  }

  @media @xxl {
    font-size: 1.6rem;
  }
}

.winning-numbers__table-description {
  margin-top: 2.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
}

.winning-numbers__cta-wrapper {
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  @media @xxl {
    padding: 4rem 0;
  }
}

.winning-numbers__cta {
  .btn();
  .btn-primary();
  .btn-height-default();
  .btn-hover-scale();
  min-width: 24rem;
  display: inline-flex;
}
