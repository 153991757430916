﻿@import url("../../Mixins/_mixins.less");

.region-eurojackpot {
  .two-column-spot {

    .column-wrapper.separated {
      &:nth-child(2n+1) {
        @media (min-width: @desktop-width) {
          border-right: .1rem solid @numbergames-white;
        }
      }
    }

    .column-wrapper.fill {
      &:nth-child(2n+0) {
        background-color: @eurojackpot-darksmoke;
      }

      &:nth-child(2n+1) {
        background-color: @eurojackpot-carbon;
      }
    }
  }
}
