.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper {
  width: 100%;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__content {
  width: 100%;
  max-width: 54rem;
  padding: 0 1rem;
  margin: 0 auto 5.5rem;
  box-sizing: border-box;
  background-color: transparent;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__total-label {
  flex: 1;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__collapsible-icon-campaign {
  width: 3.2rem;
  height: 3.2rem;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns {
  width: calc(100% - 4rem);
  max-width: 49rem;
}
@media screen and (min-width: 1024px) {
  .multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns {
    max-width: 54rem;
  }
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns .confirm-page__collapsible {
  max-width: 100%;
  width: 100%;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns .confirm-page__collapsible .confirm-page__collapsible-top-row-text {
  text-align: right;
  color: #c50005;
  flex: 0;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns .confirm-page__collapsible .confirm-page__collapsible-top-row-highlight-text {
  flex: 1;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns .confirm-page__collapsible .confirm-page__collapsible-top-row-read-more {
  font-size: 1.2rem;
  line-height: 2rem;
  text-decoration: underline;
  cursor: pointer;
}
.multi-client__flow-step-confirm .multi-client__flow-step-confirm-wrapper .confirm-page__extra-campaigns-headline {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  padding-top: 2.2rem;
  text-transform: uppercase;
}
.multi-client-wrapper__eurojackpot .multi-client__flow-step-confirm .confirm-page__collapsible-top-row-icon {
  color: #bb7f36;
}
.multi-client-wrapper__vikinglotto .multi-client__flow-step-confirm .confirm-page__collapsible-top-row-icon {
  color: #0000ff;
}
.multi-client-wrapper__lotto .multi-client__flow-step-confirm .confirm-page__collapsible-top-row-icon {
  color: #c50005;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__flow-step-confirm .confirm-page__collapsible-top-row-icon {
  color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__flow-step-confirm .confirm-page__collapsible-top-row-highlight-text {
  color: #E95801;
}
