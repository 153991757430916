/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper {
  font-size: 0;
  overflow: hidden;
  transition: background-color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper.opened .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header {
    margin-bottom: 1rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper.opened .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row {
  width: 100%;
  max-height: 30vw;
  padding: 3vw 0 0 30vw;
  opacity: 1;
  overflow: hidden;
  box-sizing: border-box;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper.opened .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row {
    max-height: 6rem;
    padding: 1rem 0 .5rem 2rem;
    overflow: inherit;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper.opened .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row:last-child {
  padding-bottom: 1.5rem;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper {
  margin: auto;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper {
    width: 96rem;
    display: flex;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button {
  width: 50vw;
  padding: 3.035vw 4vw;
  box-sizing: border-box;
  float: left;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button {
    width: 15rem;
    padding: 1.1rem 0 .9rem;
    float: none;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button:hover {
    cursor: pointer;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .icon {
  width: 5vw;
  height: 5vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .icon {
    width: 2rem;
    height: 2.1rem;
    margin: -0.1rem 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .text {
  position: relative;
  font-size: 3.2vw;
  padding-left: 3vw;
  top: -1vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .text {
    font-size: 1.4rem;
    padding-left: .5rem;
    top: -0.4rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper {
  width: 100%;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header {
  width: 100%;
  box-sizing: border-box;
  transition: margin .15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header {
    height: 4.2rem;
    font-size: 1.6rem;
    padding: 1rem 0 1rem 2rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .filter-text-wrapper {
    width: 61rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row {
  max-height: 0;
  position: relative;
  opacity: 0;
  transition: max-height 0.15s linear, opacity 0.15s linear, padding-top 0.15s linear, padding-bottom 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row {
    padding: 0 0 0 2rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name {
  font-size: 3.4vw;
  padding: 1vw 0;
  position: absolute;
  left: 5vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name {
    font-size: 1.4rem;
    padding: .4rem 0;
    left: 0;
    transform: translateX(-100%);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset {
  width: 3vw;
  height: 3vw;
  font-size: 0;
  padding-right: 1vw;
  position: absolute;
  top: 1.7vw;
  left: 0;
  opacity: 0;
  transform: translateX(-100%);
  transform-origin: center;
  transition: transform .15s linear, opacity .15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset {
    width: 1.2rem;
    height: 1.2rem;
    padding-right: .5rem;
    top: .7rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset.active {
  opacity: 1;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset.active:hover {
    cursor: pointer;
    transform: translateX(-100%);
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset.active:hover .icon {
    transform: rotate(45deg);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset .icon {
  width: 100%;
  height: 100%;
  transition: transform .15s linear;
  will-change: transform;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option {
  font-size: 3vw;
  padding: 1vw 1vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
  display: inline-block;
  border: 1px solid #777;
  border-radius: 6px;
  background-color: #f9f9f9;
  transition: color 0.15s linear, background-color 0.15s linear, opacity 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option {
    font-size: 1.2rem;
    padding: .5rem .8rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    box-sizing: border-box;
    cursor: pointer;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option:hover {
    background-color: #c9ccd0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option .filter-icon {
  width: 4vw;
  height: 4vw;
  margin: -1vw 0;
  transition: fill .15s linear;
  fill: #4f5254;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option .filter-icon {
    height: 2rem;
    width: 2rem;
    margin: -0.5rem 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.active {
  color: #f9f9f9;
  background-color: #777;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.active .filter-icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.inactive {
  opacity: .5;
  cursor: default;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.inactive.active:hover {
    background-color: #777;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.inactive:hover {
    background-color: #f9f9f9;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-desc-button,
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-normal-button,
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-asc-button {
  padding: 1vw 3vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-desc-button,
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-normal-button,
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-asc-button {
    padding: .5rem 1.5rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-desc-button {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-normal-button {
  margin-right: 0;
  border-radius: 0;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option.filter-asc-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown {
  width: 50vw;
  display: inline-block;
  text-align: center;
  font-size: 0;
  float: right;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown {
    width: 16rem;
    margin-top: -1rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown.active .game-client-dropdown-list {
  transform: scaleY(1);
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder {
  font-size: 3.2vw;
  padding: 3.867vw 4vw;
  cursor: pointer;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  transition: background-color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder .icon {
  width: 3.2vw;
  height: 3vw;
  position: absolute;
  right: 3vw;
  top: 5vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder .icon {
    width: 1.1rem;
    height: 1rem;
    margin-left: 1rem;
    top: 1.8rem;
    right: 2rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder:after {
  content: '';
  width: 200vw;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: -100vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-placeholder:after {
    height: .1rem;
    margin-top: 3.2rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-list {
  width: 50vw;
  position: absolute;
  border-radius: 0 0 6px 6px;
  border: 1px solid #dcdcdc;
  border-top: none;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform .2s linear;
  z-index: 2;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-list {
    width: 16rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-list .game-client-dropdown-list-item {
  font-size: 3.2vw;
  padding: 3.867vw 4vw;
  cursor: pointer;
  border-top: 1px solid #dcdcdc;
  transition: background-color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-list .game-client-dropdown-list-item {
    font-size: 1.6rem;
    padding: .5rem 3.3rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-game-client-dropdown .game-client-dropdown-list .game-client-dropdown-list-item:hover {
  background-color: #eee;
}
