@import url("../../Mixins/_mixins.less");

.region-eurojackpot {

  .informationswitcherspotview {

    .calltoactionspot {

      .richtext {
        color: @numbergames-white;
      }

      .call-to-action-headline {
        max-width: 86.5vw;
        font-size: 9vw;
        font-weight: 800;
        line-height: 9vw;
        color: @numbergames-white;
        margin: 0 auto;

        @media(min-width:@desktop-width) {
          font-size: 2.8rem;
          line-height: 2.8rem;
        }
      }

      .call-to-action-text {
        max-width: 86.667vw;
        font-size: 4vw;
        line-height: 1.3;
        color: @numbergames-white;
        padding-bottom: 5.333vw;
        margin: 1vw auto 0;

        @media (min-width: @desktop-width) {
          font-size: 1.5rem;
          padding-bottom: 2rem;
          margin: 1rem auto 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
}
