/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view {
  width: 100%;
  max-height: 0;
  margin: 0 auto;
  font-size: 0;
  position: relative;
  box-sizing: border-box;
  transition: max-height 0.15s linear, background-color 0.15s linear;
  overflow: hidden;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view.active {
    max-height: 13.5rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view.minimized .statistics-module-graph-overview-inner-wrapper {
    padding: 0 0 .5rem 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view.minimized .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button.minimize {
  display: none;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view.minimized .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button.maximize {
  display: block;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view.minimized .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper {
    height: 1rem;
    transform: translateY(-0.8rem);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view.minimized .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar .number {
  opacity: 0;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper {
  margin: auto;
  transition: padding .15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper {
    width: 96rem;
    position: relative;
    padding: 0 0 3rem 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper {
  display: inline-block;
  overflow: hidden;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper {
    padding: 1rem 0;
    margin-bottom: -2rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button.maximize {
  display: none;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button:hover {
    cursor: pointer;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .icon {
  display: inline-block;
  fill: #505050;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .icon {
    width: 2rem;
    height: 2rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .text {
  display: inline-block;
  position: relative;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .text {
    font-size: 1.4rem;
    padding-left: .5rem;
    top: -0.2rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper {
  width: 79rem;
  height: 8rem;
  position: relative;
  text-align: center;
  margin-left: 17rem;
  transition: height .15s linear, transform .15s linear, background .15s linear;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper {
  height: 100%;
  position: relative;
  white-space: nowrap;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper {
  height: 100%;
  display: inline-block;
  position: relative;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  will-change: transform;
  transition: background-color 0.15s linear, transform 0.25s linear;
  z-index: 0;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered {
  z-index: 1;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered:after {
  background-color: rgba(0, 149, 255, 0.5);
  transform: scaleX(1.6);
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered .bar {
  z-index: 1;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar {
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: background-color 0.15s linear, height 0.3s linear;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar .number {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  display: none;
  transition: opacity .15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar .number {
    font-size: 1.4rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper:first-child:before,
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper:nth-child(5n):before {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #777;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper:first-child .bar .number,
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper:nth-child(5n) .bar .number {
  display: block;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder {
  height: 100%;
  box-sizing: border-box;
  opacity: .5;
  position: absolute;
  top: 0;
  cursor: ew-resize;
  transition: transform .25s linear, width 0s linear;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:before,
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:after {
  content: '';
  width: 100vw;
  height: 100%;
  background-color: #0A0A0A;
  position: absolute;
  top: 0;
  cursor: pointer;
  transition: background-color 0.15s linear;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:before {
  left: 100%;
}
.statistics-module .statistics-module-wrapper .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:after {
  right: 100%;
}
