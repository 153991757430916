@import url("../../Mixins/_mixins.less");

.region-eurojackpot {

  .informationswitcherspotview {

    .informationspot {

      .richtext {
        color: @numbergames-white;
      }

      .information-spot-headline {
        max-width: 86.5vw;
        font-size: 9vw;
        font-weight: 800;
        line-height: 9vw;
        color: @numbergames-white;
        padding-bottom: 2.666vw;
        margin: 0 auto;

        @media(min-width:@desktop-width) {
          font-size: 3.8rem;
          line-height: 4rem;
          padding-bottom: 3rem;
        }
      }

      h3 {
        font-weight: 800;
        font-size: 2.8rem;
        line-height: 2.8rem;
        color: @numbergames-white;
      }

      p {
        color: @numbergames-white;
      }


      table {
        border: 0.1rem solid fade(@eurojackpot-lightgray, 40%);

        td, th {
          color: @numbergames-white;
          border: 0.1rem solid fade(@eurojackpot-lightgray, 40%);
        }

        tr {
          &:nth-child(even) {
            background-color: @eurojackpot-lightdeepgold;
          }
        }

        thead {
          th {
            color: @numbergames-white;
            background-color: @eurojackpot-graygold;
          }
        }
      }
    }
  }
}
