/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.numbers-rotary-selector {
  font-size: 1vw;
  margin: auto;
  overflow: hidden;
  user-select: none;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector {
    font-size: 5.5px;
  }
}
.numbers-rotary-selector .selector-container {
  height: 41em;
  max-width: 140em;
  position: relative;
  padding: 6em 0;
  perspective: 150em;
  margin: auto;
  box-sizing: content-box;
}
.numbers-rotary-selector .selector-numbers {
  width: 100vw;
  height: 0;
  padding-bottom: 150vw;
  position: absolute;
  transform-origin: center center;
  transform-style: preserve-3d;
  transition: transform 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
  transform: rotateX(-25deg) rotateZ(0deg) translateZ(-45em);
  top: 83vw;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers {
    width: 140em;
    padding-bottom: 140em;
    top: 75em;
  }
}
.numbers-rotary-selector .selector-numbers .number-wrapper {
  width: 36.366em;
  height: 100%;
  position: absolute;
  left: 50%;
  top: -50%;
  margin-left: -18.183em;
  text-align: center;
  transform-origin: center bottom;
  transform-style: preserve-3d;
  pointer-events: none;
  /* Define two variables as the loop limits */
  /* Create a Parametric mixin and add a guard operation */
  /* the mixin is called, css outputted and iterations called */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(0n + 0) {
  transform: rotate(-8deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(1n + 0) {
  transform: rotate(0deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(2n + 0) {
  transform: rotate(8deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(3n + 0) {
  transform: rotate(16deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(4n + 0) {
  transform: rotate(24deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(5n + 0) {
  transform: rotate(32deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(6n + 0) {
  transform: rotate(40deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(7n + 0) {
  transform: rotate(48deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(8n + 0) {
  transform: rotate(56deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(9n + 0) {
  transform: rotate(64deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(10n + 0) {
  transform: rotate(72deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(11n + 0) {
  transform: rotate(80deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(12n + 0) {
  transform: rotate(88deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(13n + 0) {
  transform: rotate(96deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(14n + 0) {
  transform: rotate(104deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(15n + 0) {
  transform: rotate(112deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(16n + 0) {
  transform: rotate(120deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(17n + 0) {
  transform: rotate(128deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(18n + 0) {
  transform: rotate(136deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(19n + 0) {
  transform: rotate(144deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper:nth-child(20n + 0) {
  transform: rotate(152deg) translateZ(0);
}
.numbers-rotary-selector .selector-numbers .number-wrapper .number {
  font-size: 22em;
  font-weight: 800;
  position: relative;
  top: 0em;
  transform: scale(0.6) translateZ(0) rotateX(0deg) translateY(0em);
  opacity: 0;
  transition: transform .3s, opacity .25s;
  line-height: 1.4;
  /* Fix for Signa font */
  pointer-events: none;
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view .number {
  cursor: pointer;
  transform: scale(0.7) translateY(0em) translateZ(0) rotateX(0deg);
  opacity: .85;
  z-index: 1;
  pointer-events: auto;
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number {
  transform: scale(0.6) translateY(-10%) translateX(45%) rotate(-3deg);
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number {
    transform: scale(0.7) translateY(5%) translateX(75%) rotate(-4deg);
  }
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number:hover {
    transform: scale(0.75) translateY(5%) translateX(70%) rotate(-4deg);
  }
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number {
  transform: scale(0.7) translateY(-10%) translateX(-45%) rotate(3deg);
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number {
    transform: scale(0.7) translateY(5%) translateX(-75%) rotate(4deg);
  }
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number:hover {
    transform: scale(0.75) translateY(5%) translateX(-70%) rotate(4deg);
  }
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number {
  transform: scale(0.45) translateY(-30%) translateX(60%) rotate(-4deg);
  opacity: 0.3;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number {
    transform: scale(0.55) translateY(-20%) translateX(120%) rotate(-8deg);
  }
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number:hover {
    transform: scale(0.6) translateY(-20%) translateX(110%) rotate(-8deg);
  }
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number {
  transform: scale(0.45) translateY(-30%) translateX(-60%) rotate(4deg);
  opacity: 0.3;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number {
    transform: scale(0.55) translateY(-20%) translateX(-120%) rotate(8deg);
  }
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number:hover {
    transform: scale(0.6) translateY(-20%) translateX(-110%) rotate(8deg);
  }
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus3'] .number {
  transform: scale(0.45) translateY(-65%) translateX(150%) rotate(-10deg);
  opacity: 0.1;
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus3'] .number:hover {
  transform: scale(0.5) translateY(-60%) translateX(135%) rotate(-10deg);
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus3'] .number {
  transform: scale(0.45) translateY(-65%) translateX(-150%) rotate(10deg);
  opacity: 0.1;
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus3'] .number:hover {
  transform: scale(0.5) translateY(-60%) translateX(-135%) rotate(10deg);
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus4'] .number {
  transform: scale(0.35) translateY(-135%) translateX(160%) rotate(-12deg);
  opacity: 0.05;
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus4'] .number:hover {
  transform: scale(0.4) translateY(-130%) translateX(145%) rotate(-12deg);
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus4'] .number {
  transform: scale(0.35) translateY(-135%) translateX(-160%) rotate(12deg);
  opacity: 0.05;
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus4'] .number:hover {
  transform: scale(0.4) translateY(-130%) translateX(-145%) rotate(12deg);
}
.numbers-rotary-selector .selector-numbers .number-wrapper.in-focus .number {
  transform: scale(0.9);
  opacity: .95;
}
.numbers-rotary-selector .selector-foreground,
.numbers-rotary-selector .selector-background {
  width: 36.366em;
  height: 36.366em;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 7.4em;
}
.numbers-rotary-selector .selector-foreground .navigation-button {
  width: 3em;
  height: 6em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: default;
  transition: transform .15s linear, opacity .1s linear;
  opacity: 0;
  display: none;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selector-foreground .navigation-button {
    display: block;
  }
}
.numbers-rotary-selector .selector-foreground .navigation-button .icon {
  width: 100%;
  height: 100%;
}
.numbers-rotary-selector .selector-foreground .navigation-button.active {
  opacity: 1;
  cursor: pointer;
}
.numbers-rotary-selector .selector-foreground .navigation-button.next {
  right: -6.5em;
}
.numbers-rotary-selector .selector-foreground .navigation-button.next:hover {
  transform: translateY(-50%) translateX(20%);
}
.numbers-rotary-selector .selector-foreground .navigation-button.previous {
  left: -6.5em;
}
.numbers-rotary-selector .selector-foreground .navigation-button.previous:hover {
  transform: translateY(-50%) translateX(-20%);
}
.numbers-rotary-selector .selector-foreground .label {
  font-size: 3.6em;
  font-weight: 800;
  color: white;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 7.8em;
  left: 0;
  right: 0;
}
.numbers-rotary-selector .selector-background {
  background-color: #000000;
}
.numbers-rotary-selector .selected-number-wrapper {
  width: 36.366em;
  height: 36.366em;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  overflow: hidden;
}
.numbers-rotary-selector .selected-number-wrapper .selector-numbers {
  left: -32.3vw;
  top: 75vw;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selected-number-wrapper .selector-numbers {
    left: -52em;
    top: 69em;
  }
}
.numbers-rotary-selector .selected-number-wrapper .number-wrapper .number {
  color: white;
}
.numbers-rotary-selector .selected-number-wrapper .number-wrapper.in-view[data-id='plus1'] .number {
  transform: scale(0.9) translateY(-2%) translateX(43%) rotate(-4deg);
  opacity: 0.9;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selected-number-wrapper .number-wrapper.in-view[data-id='plus1'] .number {
    transform: scale(1) translateY(3%) translateX(60%) rotate(-4deg);
  }
}
.numbers-rotary-selector .selected-number-wrapper .number-wrapper.in-view[data-id='minus1'] .number {
  transform: scale(0.9) translateY(-2%) translateX(-43%) rotate(4deg);
  opacity: 0.9;
}
@media screen and (min-width: 768px) {
  .numbers-rotary-selector .selected-number-wrapper .number-wrapper.in-view[data-id='minus1'] .number {
    transform: scale(1) translateY(3%) translateX(-60%) rotate(4deg);
  }
}
.numbers-rotary-selector .selected-number-wrapper .number-wrapper.in-focus .number {
  transform: scale(1);
  opacity: 1;
}
.numbers-rotary-selector.eurojackpot .selector-numbers .number {
  color: #E8A332;
}
.numbers-rotary-selector.eurojackpot .selector-foreground {
  box-shadow: inset 0px 0px 5em rgba(232, 163, 50, 0.9);
}
.numbers-rotary-selector.eurojackpot .selector-foreground .navigation-button .icon {
  color: #E8A332;
}
.numbers-rotary-selector.eurojackpot .selector-foreground .multi-client__client-game-description {
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  transform: translateY(100%);
  margin-bottom: 0;
  padding-top: 1rem;
}
.numbers-rotary-selector.eurojackpot .selector-foreground .multi-client__client-game-description .client-game-description__question-button {
  width: 4rem;
  height: 4rem;
}
.numbers-rotary-selector.eurojackpot .selector-background {
  background-color: #E8A332;
}
.numbers-rotary-selector.eurojackpot .selected-number-wrapper .number {
  color: white;
}
.numbers-rotary-selector.vikinglotto .selector-numbers .number {
  color: #0000ff;
}
.numbers-rotary-selector.vikinglotto .selector-foreground {
  box-shadow: inset 0px 0px 5em rgba(0, 0, 255, 0.9);
}
.numbers-rotary-selector.vikinglotto .selector-foreground .navigation-button .icon {
  color: #0000ff;
}
.numbers-rotary-selector.vikinglotto .selector-background {
  background-color: #0000ff;
}
.numbers-rotary-selector.vikinglotto .selected-number-wrapper .number {
  color: white;
}
.numbers-rotary-selector.lotto .selector-numbers .number {
  color: #c50005;
}
.numbers-rotary-selector.lotto .selector-foreground {
  box-shadow: inset 0px 0px 5em rgba(197, 0, 5, 0.9);
}
.numbers-rotary-selector.lotto .selector-foreground .navigation-button .icon {
  color: #c50005;
}
.numbers-rotary-selector.lotto .selector-background {
  background-color: #c50005;
}
.numbers-rotary-selector.lotto .selected-number-wrapper .number {
  color: white;
}
.numbers-rotary-selector.allornothing {
  font-family: MergePro, "Open Sans", Helvetica, Arial, sans-serif;
}
.numbers-rotary-selector.allornothing .selector-numbers .number {
  color: white;
}
.numbers-rotary-selector.allornothing .selector-foreground {
  box-shadow: inset 0px 0px 5em rgba(25, 19, 57, 0.9);
}
.numbers-rotary-selector.allornothing .selector-foreground .navigation-button .icon {
  color: white;
}
.numbers-rotary-selector.allornothing .selector-background {
  background-color: #191339;
}
.numbers-rotary-selector.allornothing .selected-number-wrapper .number {
  color: white;
}
.numbers-rotary-selector.keno .selector-numbers .number {
  color: #00a5eb;
}
.numbers-rotary-selector.keno .selector-foreground {
  box-shadow: inset 0px 0px 5em rgba(0, 165, 235, 0.9);
}
.numbers-rotary-selector.keno .selector-foreground .navigation-button .icon {
  color: #00a5eb;
}
.numbers-rotary-selector.keno .selector-background {
  background-color: #00a5eb;
}
.numbers-rotary-selector.keno .selected-number-wrapper .number {
  color: white;
}
