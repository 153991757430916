@import url("../Mixins/_mixins.less");
// Colors:
@eurojackpot-receipt-light-gold: #FAEDD6;
@lotto-receipt-light-red: #FBD2DA;
@vikinglotto-receipt-light-blue: #E5E6FD;

// Style coupon
.coupon {
  width: 31rem;
  font-size: 1.3rem;
  padding: 0.4rem 0.7rem 0;
  text-align: center;
  margin: 0 auto;
  position: relative;

  .coupon__printer {
    width: 100%;
    height: 0.9rem;
    background-color: #92969E;
    border-radius: 0.4rem;
    position: absolute;
    left: 0;
    top: 0;

    &::before {
      content: '';
      height: 0.5rem;
      background: linear-gradient(0deg, rgba(146,150,158,0) 0%, rgba(146,150,158,1) 95%, rgba(146,150,158,1) 100%);
      position: absolute;
      top: 0.4rem;
      left: 0.4rem;
      right: 0.4rem;
      z-index: 1;
    }
  }

  &.loading {
    .loading-content {
      animation: pulsate 2s infinite;
    }
  }

  p {
    line-height: 2rem;
    padding: 0 2rem;
  }

  .play-type {
    text-transform: uppercase;
    margin: 0.5rem 0 0;
  }

  .campaign__tickets-counter {
    margin: 1rem;
  }

  p.rebuy,
  .playtogether-winning-section {
    a {
      background: @numbergames-cta-yellow;
      border: solid 0.05rem rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      color: @numbergames-black;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 4rem;
      padding: 1.2rem;
      text-decoration: none;

      &:visited {
        color: @numbergames-black;
      }
    }
  }

  p.rebuy.rebuy--inactive {
    a {
      cursor: not-allowed;
      background: @numbergames-lightgray;
      color: @numbergames-slight-grey;
    }

    .rebuy-inactive-notice {
      display: block;
      margin-top: 1.2rem;
      color: @ds-red;
    }

  }

  .coupon-paper .playtogether-winning-section .total {
    margin-bottom: 2rem;
  }

  // GENERIC
  .coupon-paper {
    margin: 0 auto;
    padding: 2.4rem 0 1.2rem;
    background-color: @numbergames-white;
    background-repeat: repeat;
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/coupon-background.jpg");
    border-radius: 0 0 2.4rem 2.4rem;
    position: relative;
    transition: max-height .15s linear;
    overflow: hidden;
    box-sizing: content-box;

    > div {
      overflow: hidden;
    }

    @keyframes coupon-paper-content-fade-in {
      to { opacity: 1; }
    }
    .coupon-paper__content {
      opacity: 0;
      animation: coupon-paper-content-fade-in 100ms 50ms linear forwards;
    }

    .play-type {
      font-weight: bold;
    }

    .multi-wager-count {
      text-transform: uppercase;
      margin: 0;
    }

    .transaction-date {
      margin-bottom: 0;
    }

    .coupon-id {
      font-size: 1rem;
      margin-top: 0;
    }

    .chosen-system-numbers-heading {
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .chosen-system-numbers {
      .chosen-numbers {
        background-color: rgba(195, 8, 21, 0.08);
        padding: 1.5rem 0 0.9rem;

        .number-table {
          background-color: transparent;
          max-width: 80%;
          margin: auto;

          tr {
            &:nth-child(odd) {
              background-color: transparent;
            }
          }

          td {
            width: 2.7rem;
            padding: 0 0 0.3rem;
            display: inline-block;

            &.additional {
              color: @ds-red;
            }

            &.correct {
              font-weight: bold;
            }
          }
        }
      }

      .number-rows {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.5s, opacity 0.8s;

        tr {
          &:nth-child(odd) {
            background-color: rgba(195, 8, 21, 0.08);
          }
        }
      }

      .toggle-row {
        padding: 1.5rem 0;

        .toggle-link {
          font-size: 1.1rem;
          color: @ds-blue;
          text-transform: uppercase;
          text-decoration: underline;
          cursor: pointer;
          user-select: none;

          &.open {
            display: block;
          }

          &.close {
            display: none;
          }
        }
      }

      &.active {
        .toggle-link.open {
          display: none;
        }

        .toggle-link.close {
          display: block;
        }
      }
    }

    .number-tables__wrapper {
      .number-table__wrapper {
        &:nth-child(odd) {
          background-color: rgba(195, 8, 21, 0.08);
        }
  
        .number-table tr {
          background-color: transparent !important;
        }
      }
    }

    .number-table {
      table-layout: fixed;
      width: 100%;

      tr {
        &:nth-child(odd) {
          background-color: rgba(195, 8, 21, 0.08);
        }

        td {
          padding: .4rem 0 .5rem 0;
          width: 2.2rem;

          &.row-number {
            font-weight: bold;
            width: 4.4rem;
            padding-right: 1.2rem;
            text-align: right;
          }

          &.divider {
            width: 1.6rem;
          }

          &.additional {
            color: @ds-red;
          }

          &.correct {
            font-weight: bold;
          }
        }

        td.won-indicator {
          position: relative;
          padding-left: 0.5rem;

          svg {
            display: none;
          }
        }

        &.row-won td.won-indicator {

          svg {
            width: 1.1rem;
            height: 1.1rem;
            display: block;
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .multi-wager-list {
      margin: 0 2rem;
      border-top: .1rem solid @numbergames-black;

      table {
        width: 100%;
        margin: 1.2rem 0;
        text-align: right;
        text-transform: uppercase;

        td {
          padding: .3rem .6rem;
          white-space: nowrap;
        }

        td:first-child {
          padding-left: 0;
        }

        td:last-child {
          padding-right: 0;
        }

        svg.joker-hat {
          width: 2rem;
          height: 1.2rem;
        }

        svg.campaign-ticket {
          width: 1.1rem;
          height: 1.6rem;
          position: relative;
          top: 0.2rem;
        }
      }
    }

    .coupon-separator {
      height: 1.5rem;
      width: 100%;
      position: relative;

      &::Before,
      &::after {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        background-color: @ds-gray-100;
        border-radius: 50%;
        position: absolute;
        top: 0;
      }

      &::before {
        right: 0;
        transform: translateX(50%);
      }

      &::after {
        left: 0;
        transform: translateX(-50%);
      }

      .coupon-separator__line {
        width: 100%;
        height: 0.3rem;
        color: @ds-gray-100;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
      }
    }

    // Draw date
    .draw-week {
      font-size: 1rem;
      font-weight: bold;

      .draw-date {
        display: block;
      }
    }

    .keno-draw-date-title {
      text-transform: uppercase;
      margin-bottom: 0.2rem;
    }

    .keno-draw-date {
      font-weight: normal;
      display: inline-block;
      margin: 0.5rem 0 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .keno-wager-title {
      text-transform: uppercase;
      margin-bottom: 0.2rem;
    }

    .keno-wager {
      font-weight: normal;
      display: inline-block;
      padding: 0.5rem 0;

      &:last-child {
        margin-right: 0;
      }
    }

    // Joker games
    .game-type {
      font-weight: bold;
      border-top: .1rem solid @numbergames-black;
      padding: 1.5rem 0 0 0;
      margin: 0 2rem;
    }

    .jokernumbers {
      background-color: rgba(195, 8, 21, 0.08);
      letter-spacing: 0.5rem;
      padding: 0.8rem 2rem 0.8rem;

      .line {
        position: relative;
      }

      .number {
        padding: 0 0.4rem;

        &.additional {
          color: @ds-red;
        }

        &.correct {
          font-weight: bold;
        }
      }

      span.won-indicator {
        width: 1.1rem;
        height: 1.1rem;
        padding-left: 0.5rem;
        display: inline-block;

        svg {
          display: none;
        }
      }

      .row-won span.won-indicator {

        svg {
          width: 1.1rem;
          height: 1.1rem;
          display: block;
          padding: 0;
          margin: 0;
        }
      }
    }

    // Correct numbers
    &.prizes-available .correct {
      font-weight: bold !important;

      &.additional {
        color: @ds-red;
      }
    }

    // Total price + Total prize
    .total {
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding: 1.4rem 0 1.2rem 0;
      margin: 0 2rem;
      font-weight: bold;
      text-align: left;
      border-top: .1rem solid @numbergames-black;
      border-bottom: 0.1rem solid @numbergames-black;

      span {
        float: right;
      }
    }

    // Company name disclaimer text
    .company-name {
      font-size: 1rem;
      font-style: italic;
    }

    .campaign-footer {
      padding: 1rem 2rem 0;

      &__logo {
        width: 100%;
        height: 7rem;

        &.Sommerlotto {
          height: 25rem;
          margin: -3rem 0;
        }
      }

      &__text {
        margin-top: 1rem;
      }

      &__tickets-count {
        display: none;
      }
    }

    // PRIZES
    .prizes {

      .prizes-header {
        font-size: 2.5rem;
        font-weight: bold;
        padding: 1.5rem 0 1rem;
        margin: 0 2rem;
      }

      .prize-section {
        margin-bottom: 3rem;
      }

      .prize-section-header {
        font-size: 1.3rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 1;
        margin: 1rem 0 0;
      }

      .prize-section-date {
        font-size: 1rem;
        margin-top: 0;

        span {
          text-transform: uppercase;
        }
      }

      .prizes-table {
        table-layout: fixed;
        width: 100%;
        margin-bottom: 1rem;

        tr {

          td, th {
            font-size: 1.3rem;
            padding: .4rem 0 .5rem 0;
            width: 2.9rem;
          }

          th {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  // THEMES
  // AllOrNothing
  &.game-AllOrNothing {
    .coupon-paper {
      .icon {
        width: 11rem;
        height: 2.5rem;
        fill: #3a3a3a;
      }

      .keno-title-table {
        width: 100%;

        .keno-row-title {
          width: 100%;
          padding-top: 1rem;
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      .number-table {
        table-layout: fixed;
        width: 100%;

        tr {
          &:nth-child(odd) {
            background-color: rgba(195, 8, 21, 0.08);
          }

          td {
            width: 2.05rem;
            padding: 0 0 .5rem 0;
            display: inline-block;

            &.won-indicator {
              width: 0;
              text-align: center;

              svg.svg {
                display: inline-block;
              }
            }

            &.row-number {
              display: none;
            }

            &.divider {
              width: 1.6rem;
            }

            &.hide {
              display: none;
            }
          }
        }
      }
    }
  }

  // Lotto
  &.game-LottoSaturday {
    .coupon-paper {
      .icon {
        width: 10.5rem;
        height: 2.5rem;
      }

      .chosen-numbers {
        background-color: @lotto-receipt-light-red;
      }

      .number-tables__wrapper {
        .number-table__wrapper {
          &:nth-child(odd) {
            background-color: @lotto-receipt-light-red;
          }
        }
      }

      .jokernumbers {
        background-color: transparent;
        padding: 0;

        .line {
          padding: 0.8rem;
          margin-bottom: 0.4rem;
          background-color: @lotto-receipt-light-red;
        }
      }
    }

    //  Christmas campaign
    .campaign-footer--campaign-christmas,
    .campaign-footer--JuleLotto2022 {
      .campaign-footer__logo {
        height: 8rem;
        margin-top: 2rem;
      }
      .campaign-footer__tickets-count {
        font-family: "OliveVillage";
        font-size: 5.6rem;
        line-height: 8rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: -1rem;

        &::before,
        &::after {
          content: "";
          width: 3.6rem;
          height: 1.2rem;
          background-image: url("/Components/DanskeSpil/Domain/Lotto/Graphics/Campaigns/Christmas/christmas-tickets-counter-vector.svg");
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          margin-top: -1rem;
        }

        &::before {
          transform: rotate(180deg);
        }
      }

      .campaign-footer__text {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }
  }

  .campaign-header-icon {
    max-width: ~"calc(100% - 4rem)";
  }

  // VikingLotto
  &.game-VikingLotto {
    .coupon-paper {
      .icon {
        width: 24rem;
        height: 4.7rem;
      }

      .chosen-numbers {
        background-color: @vikinglotto-receipt-light-blue;
      }

      .number-tables__wrapper {
        .number-table__wrapper {
          &:nth-child(odd) {
            background-color: @vikinglotto-receipt-light-blue;
          }
        }
      }

      .jokernumbers {
        background-color: transparent;
        padding: 0;

        .line {
          padding: 0.8rem;
          margin-bottom: 0.4rem;
          background-color: @vikinglotto-receipt-light-blue;
        }
      }

      .chosen-system-numbers {
        .chosen-numbers {
          .ekstra-number-table {
            margin: 0 auto;

            .ekstra-number-container {
              width: 3.4rem;
              position: relative;
              display: inline-block;
              margin: 0.2rem;

              .ekstra-number {
                width: 1.7rem;
                position: relative;
                z-index: 1;
                padding-top: 0.8rem;
              }

              .additional {
                color: red;
              }

              .correct {
                font-weight: bold;
              }

              svg {
                width: 3.2rem;
                height: 3rem;
                left: 0.1rem;
                top: 0.1rem;
                position: absolute;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  // Eurojackpot
  &.game-Eurojackpot {
    .coupon-paper {
      .icon {
        width: 17.8rem;
        height: 2.5rem;
      }

      .chosen-system-numbers {
        .chosen-numbers {
          .ekstra-number-table {
            margin: 0 auto;

            .ekstra-number-container {
              width: 3.4rem;
              position: relative;
              display: inline-block;
              margin: -0.3rem 0.3rem;

              td {
                width: 1.7rem;
                position: relative;
                z-index: 1;
                padding-top: 1rem;

                &.additional {
                  color: red;
                }

                &.correct {
                  font-weight: bold;
                }
              }

              svg {
                width: 3.2rem;
                height: 3rem;
                left: 0.1rem;
                top: 0.2rem;
                position: absolute;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }

      .number-table tr td.row-number {
        width: 3.4rem;
      }

      .chosen-numbers {
        background-color: @eurojackpot-receipt-light-gold;
      }

      .number-tables__wrapper {
        .number-table__wrapper {
          &:nth-child(odd) {
            background-color: @eurojackpot-receipt-light-gold;
          }
        }
      }

      .jokernumbers {
        background-color: transparent;
        padding: 0;

        .line {
          padding: 0.8rem;
          margin-bottom: 0.4rem;
          background-color: @eurojackpot-receipt-light-gold;
        }
      }
    }
  }

  // Keno
  &.game-Keno {
    .coupon-paper {
      .icon {
        width: 10.5rem;
        height: 2.5rem;
      }

      .chosen-system-numbers-heading {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        font-weight: normal;
        text-transform: uppercase;
      }

      .keno-title-table {
        width: 100%;

        .keno-row-title {
          width: 100%;
          padding-top: 1rem;
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      .number-table {
        table-layout: fixed;
        width: 100%;

        tr {
          &:nth-child(odd) {
            background-color: rgba(195, 8, 21, 0.08);
          }

          td {
            width: 2.2rem;
            padding: 0 0 .5rem 0;
            display: inline-block;

            &.row-number {
              display: none;
            }

            &.divider {
              width: 1.6rem;
            }
          }
        }
      }
    }
  }

  .play-together-title {
    font-size: 2rem;
    font-weight: bold;
  }

  .play-together {
    text-align: left;
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  .play-together-rows,
  .play-together-amount {
    padding: 0 2rem;
  }

  .play-together-club-name {
    font-style: italic;
    font-weight: bold;
  }

  .play-together-label {
    font-weight: bold;
  }

  .campaign-footer--campaign-engine {
    line-height: 1;

    .campaign-footer__logo {
      margin-bottom: 0.6rem;
    }

    .campaign-footer__tickets-count {
      display: block;
      font-size: 5rem;
      font-weight: 700;
    }

    .campaign-footer__tickets-count-label {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
}

@keyframes pulsate {
  50% {
    opacity: 0.5;
    transform: scale(0.97);
  }
}

.coupon-extra-campaign-draws {
  margin-bottom: 2.4rem;
  position: relative;
}

.coupon-extra-campaign-draws__header-graphics {
  position: absolute;
  top: -1.3rem;
  max-width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  overflow: visible;
}

.coupon-extra-campaign-draws__headline {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

.coupon-extra-campaign-draws__table {
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  gap: 0.4rem;
}

.coupon-extra-campaign-draws__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.coupon-extra-campaign-draws__cell {
  padding: 0 0.3rem;
}

.coupon-extra-campaign-draws__cell--name {
  font-weight: 700;
}