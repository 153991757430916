/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view {
  overflow: hidden;
  padding: 4vw 0;
  position: relative;
  background-color: #eee;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view {
    padding: 3rem 0 4.5rem;
    background-color: transparent;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper {
  width: 80%;
  transition: transform .25s linear;
  box-sizing: border-box;
  position: relative;
  left: 10%;
  display: block;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper {
    width: inherit;
    min-width: 100%;
    height: 25rem;
    padding: 0 15rem;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    left: inherit;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper:before {
    content: '';
    width: 100%;
    height: 6px;
    background-color: #c9c9c9;
    position: absolute;
    bottom: -6px;
    left: 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper {
  height: 8vw;
  position: relative;
  display: block;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper {
    height: 100%;
    width: 3.5rem;
    display: inline-block;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:after {
    content: '';
    height: 6px;
    background-color: #c9c9c9;
    position: absolute;
    bottom: -6px;
    left: -0.35rem;
    right: -0.35rem;
    z-index: 1;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:first-child:after {
  left: 0;
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:last-child:after {
  right: 0;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:first-child:before,
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:nth-child(5n):before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #c9c9c9;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar {
  height: 80%;
  border-radius: 0 0 0 0;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: .4vw;
  transition: background-color 0.15s linear, height 0.3s linear, width 0.3s linear;
  cursor: pointer;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar {
    width: 80%;
    border-radius: 6px 6px 0 0;
    top: inherit;
    left: 0;
    right: 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount {
  width: 9vw;
  text-align: center;
  line-height: 1.6;
  padding: 0 3vw;
  border-radius: 0 6px 6px 0;
  position: absolute;
  right: -9vw;
  z-index: 0;
  transition: background-color 0.15s linear, color 0.15s linear;
  box-sizing: border-box;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount {
    width: 100%;
    line-height: normal;
    color: inherit;
    padding: .2rem 0 .6rem;
    border-radius: 6px 6px 0 0;
    top: .6rem;
    right: inherit;
    transform: translateY(-100%);
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount:before {
    content: '';
    width: 100%;
    height: .6rem;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 6px 6px 0 0;
    transition: background-color 0.15s linear;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-most-drawn,
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-least-drawn {
  width: 3.5vw;
  height: 5vw;
  margin: auto;
  position: absolute;
  top: .5vw;
  left: 2vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-most-drawn,
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-least-drawn {
    width: 1.2rem;
    height: 1.8rem;
    top: inherit;
    left: 0;
    right: 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-most-drawn {
  fill: #fff;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-most-drawn {
    top: .5rem;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-least-drawn {
  fill: #171717;
  left: auto;
  right: -14vw;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .icon-least-drawn {
    top: -4.5rem;
    left: 0;
    right: 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
  font-size: 2.8vw;
  line-height: 2.6;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  position: absolute;
  left: -8vw;
  bottom: .5vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  transition: transform 0.15s linear, background-color 0.15s linear, color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
    font-size: inherit;
    line-height: inherit;
    font-weight: normal;
    left: inherit;
    bottom: -1.3rem;
    transform: translateY(100%);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button {
  width: 10vw;
  height: 100%;
  background: red;
  position: absolute;
  top: 0;
  z-index: 1;
  display: none;
  cursor: pointer;
  transition: transform .15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button {
    display: block;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.right {
  right: 0;
  transform: translateX(100%);
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.right.active {
  transform: translateX(0);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.right.active:hover {
    transform: translateX(10%);
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.right.active:hover .icon {
    transform: translate(-50%, -50%);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.left {
  left: 0;
  transform: translateX(-100%);
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.left.active {
  transform: translateX(0);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.left.active:hover {
    transform: translateX(-10%);
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button.left.active:hover .icon {
    transform: translate(-50%, -50%);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  transition: transform .15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-large-view .statistics-module-graph-slide-button .icon {
    width: 4rem;
    height: 6rem;
  }
}
