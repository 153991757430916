﻿@import url("../Mixins/_mixins.less");

.statistics-module {
  background-color: #f9f9f9;
  overflow: hidden;
  user-select: none;
}

.statistics-module-header-wrapper {
  overflow: hidden;
  border-top: 1px solid rgba(0,0,0, 0.5);
  border-bottom: 1px solid rgba(0,0,0, 0.5);

  .header-inner-wrapper {
    width: 94vw;
    color: @numbergames-white;
    position: relative;
    margin: 0 auto;
    text-align: center;

    @media (min-device-width: @desktop-width) {
      width: 96rem;
    }

    .headline {
      font-size: 5vw;
      font-weight: bold;
      margin: 3vw 0 1vw;

      @media (min-device-width: @desktop-width) {
        font-size: 3.4rem;
        margin: 2rem 0 .5rem;
      }
    }

    .description {
      font-size: 3vw;
      margin: 0 0 2vw;

      @media (min-device-width: @desktop-width) {
        font-size: 1.4rem;
        margin: 0 0 2rem;
      }
    }
  }

  .region-eurojackpot & {
    background-color: @eurojackpot-carbon;
  }

  .region-lotto & {
    background-color: @lotto-red;
  }

  .region-vikinglotto & {
    background-color: @vikinglotto-blue;
  }

  .region-keno & {
    background-color: @keno-royalblue;
  }
}

.statistics-module-gameversion-conflict {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  padding: 6rem 1rem;
  font-size: 5vw;
  text-align: center;
  
  @media (min-device-width: @desktop-width) {
    font-size: 2rem;
  }

  .region-vikinglotto & {
    background-color: @vikinglotto-blue;
    color: @numbergames-white;
  }

  .statistics-module-gameversion-conflict-message {
    max-width: 65rem;
  }

  .icon {
    width: 6rem;
    height: 6rem;
    margin-bottom: 2rem;
    fill: @numbergames-white;
  }
}