@import url("../Mixins/_mixins.less");

.eurojackpot-lightning {
  position: relative;
  text-align: center;
  //overflow: hidden;
  .eurojackpot-section {
    background-color: @eurojackpot-gray;
    padding-top: 3vw;

    .eurojackpot-content-wrapper {

      .game-not-available-wrapper {
        padding: 14vw 0;

        @media (min-width: @eurojackpot-desktop-width) {
          padding: 0 0 5rem;
        }

        img {
          width: 17.33vw;
          height: 17.33vw;
          margin-bottom: 2vw;
          margin-top: -8vw;

          @media (min-width: @eurojackpot-desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
            margin-bottom: 1rem;
            margin-top: 0;
          }
        }
      }
    }
  }



  .headline {
    font-size: 10.666vw;
    font-weight: bold;
    color: #fff;
    margin: 0;

    @media (min-width: @eurojackpot-mobile-width) {
      font-size: 4rem;
    }
  }

  .subheadline {
    font-size: 3.733vw;
    color: #fff;
    padding-top: 1vw;
    margin: 0;

    @media (min-width: @eurojackpot-mobile-width) {
      font-size: 2rem;
    }

    &.not-available {
      padding-bottom: 9vw;

      @media (min-width: @eurojackpot-desktop-width) {
        padding-bottom: 5rem;
      }
    }
  }

  .tagline {
    font-size: 3.733vw;
    color: #fff;
    padding-top: 3vw;
    margin: 0;
    font-weight: normal;
    margin-bottom: 9vw;

    @media (min-width: @eurojackpot-desktop-width) {
      font-size: 2rem;
    }
  }

  .eurojackpot-slider-selector {
    width: 100%;
    text-align: center;
    margin: 6.5vw auto 4vw;
    position: relative;

    @media (min-width: @eurojackpot-desktop-width) {
      width: 58rem;
      margin: 5rem auto 5rem;
    }

    .number {
      text-transform: uppercase;
    }
  }
}
