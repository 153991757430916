@import url("../Mixins/_mixins.less");

.one-column-spot {
  @media (min-width: @desktop-width) {
    display: flex;
  }

  .column-wrapper {
    width: 100%;

    &.grey {
      background-color: @numbergames-slight-grey;
    }


    .column-spots {
      margin: 0 auto;

      @media (min-width: @desktop-width) {
        max-width: 96rem;
        box-sizing: border-box;
      }

      &.center {
        @media (min-width: @desktop-width) {
          width: 37rem;
          padding: 1.5rem 2rem 2rem;
          margin-bottom: 0 auto 0 auto;
        }
      }

      &.fillcolumn {
        padding: 4vw 0;

        @media (min-width: @desktop-width) {
          padding: 1.6rem 1.6rem 0.8rem;
          margin-bottom: 0;
        }
      }

      &.justify {
        @media (min-width: @desktop-width) {
          padding: 0;
          margin-bottom: -4px;
        }
      }
    }
  }
}
