@import url("../Mixins/_mixins.less");

.informationswitcherspotview {
  padding: 15vw 0;
  text-align: center;

  @media (min-width: @desktop-width) {
    padding: 5rem 0;
  }


  .imagespot.numbergame {
    padding: 3vw 0 0;

    @media(min-width:@desktop-width) {
      padding: 3rem 0 0;
    }
  }

  .select-dropdown {
    max-width: 86.667vw;
    margin: auto;
    display: block;

    @media (min-width: @desktop-width) {
      max-width: 58rem;
    }

    .cs-placeholder {
      color: white;
      border: none;
      font-size: 3.733vw;
      line-height: 1;
      text-transform: uppercase;
      transition: color .25s;

      @media (min-width: @desktop-width) {
        font-size: 1.8rem;
        line-height: 5rem;
      }
    }

    .select-options {
      border-color: white;
      border-width: 1px 0 1px 0;

      ul li {
        border-color: white;
        color: white;
      }
    }

    span {
      font-size: 3.733vw;
      line-height: 1;
      padding: 6.2vw 5vw 6.2vw;
      text-transform: uppercase;

      @media (min-width: @desktop-width) {
        font-size: 1.8rem;
        line-height: 5rem;
        padding: .6rem 2.5rem .2rem;
      }
    }
  }
}
