.multi-client-draw-options .multi-columns-wrapper__column .draw-date.unavailable {
  text-decoration: line-through;
}
.multi-client-draw-options .multi-columns-wrapper__column .draw-date__icon {
  width: 1.6rem;
  height: 2rem;
  margin-left: 0.6rem;
  margin-top: -0.6rem;
  position: relative;
  top: 0.5rem;
}
.multi-client-draw-options .check-button {
  margin-bottom: 1rem;
}
.multi-client-draw-options.eurojackpot .check-button {
  background-color: rgba(232, 163, 50, 0.1);
  color: #E8A332;
}
.multi-client-draw-options.eurojackpot .check-button .check-button__dot {
  border-color: rgba(232, 163, 50, 0.1);
}
.multi-client-draw-options.eurojackpot .check-button::before {
  background-color: #E8A332;
}
.multi-client-draw-options.eurojackpot .check-button .check-button__dot::after {
  background-color: #E8A332;
}
.multi-client-draw-options.eurojackpot .check-button.check-button--selected {
  color: white;
}
.multi-client-draw-options.eurojackpot .check-button.check-button--selected .check-button__dot {
  border-color: rgba(232, 163, 50, 0.5);
}
.multi-client-draw-options.vikinglotto .check-button {
  background-color: rgba(0, 0, 255, 0.1);
  color: #0000ff;
}
.multi-client-draw-options.vikinglotto .check-button .check-button__dot {
  border-color: rgba(0, 0, 255, 0.1);
}
.multi-client-draw-options.vikinglotto .check-button::before {
  background-color: #0000ff;
}
.multi-client-draw-options.vikinglotto .check-button .check-button__dot::after {
  background-color: #0000ff;
}
.multi-client-draw-options.vikinglotto .check-button.check-button--selected {
  color: white;
}
.multi-client-draw-options.vikinglotto .check-button.check-button--selected .check-button__dot {
  border-color: rgba(0, 0, 255, 0.5);
}
.multi-client-draw-options.lotto .check-button {
  background-color: rgba(197, 0, 5, 0.1);
  color: #c50005;
}
.multi-client-draw-options.lotto .check-button .check-button__dot {
  border-color: rgba(197, 0, 5, 0.1);
}
.multi-client-draw-options.lotto .check-button::before {
  background-color: #c50005;
}
.multi-client-draw-options.lotto .check-button .check-button__dot::after {
  background-color: #c50005;
}
.multi-client-draw-options.lotto .check-button.check-button--selected {
  color: white;
}
.multi-client-draw-options.lotto .check-button.check-button--selected .check-button__dot {
  border-color: rgba(197, 0, 5, 0.5);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-draw-options .check-button {
  background-color: rgba(233, 88, 1, 0.1);
  color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-draw-options .check-button .check-button__dot {
  border-color: rgba(233, 88, 1, 0.1);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-draw-options .check-button::before {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-draw-options .check-button .check-button__dot::after {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-draw-options .check-button.check-button--selected {
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client-draw-options .check-button.check-button--selected .check-button__dot {
  border-color: rgba(233, 88, 1, 0.5);
}
