/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-module .statistics-module-wrapper.lotto-statistics {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper {
  background-color: #a30003;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-placeholder .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list {
  background-color: #a30003;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list .game-client-dropdown-list-item:hover {
  background-color: #7c0001;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option {
  color: #4f5254;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-numbers-type-switch-wrapper {
  background-color: #c50005;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper {
  border-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper {
  background-color: #950002;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper:hover {
  background-color: #7c0001;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active {
  background-color: #fff;
  color: #4f5254;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active:hover {
    background-color: #fff;
  }
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view {
  background-color: #a30003;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper {
  background: repeating-linear-gradient(0deg, #fff, #fff 19px, #777777 20px, #777777 20px);
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered .bar {
  background-color: #c50105;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar {
  background-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar.selected {
  background-color: #c50005;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:before,
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:after {
  background-color: #a30003;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view {
  background-color: #950002;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-graph-slide-button.right {
  background: linear-gradient(to right, rgba(149, 0, 2, 0) 0%, rgba(149, 0, 2, 0.65) 25%, rgba(149, 0, 2, 0.8) 50%, rgba(149, 0, 2, 0.95) 100%);
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-graph-slide-button.left {
  background: linear-gradient(to right, rgba(149, 0, 2, 0.95) 0%, rgba(149, 0, 2, 0.8) 50%, rgba(149, 0, 2, 0.65) 75%, rgba(149, 0, 2, 0) 100%);
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-graph-slide-button .icon {
  fill: #eee;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
  background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
    background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
  }
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar {
  background-color: #c50005;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar .amount:before {
  background-color: #c50005;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .number {
  color: #fff;
  background-color: #c50005;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar {
    background-color: #c50105;
  }
  .statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar .amount:before {
    background-color: #c50105;
  }
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special .bar.most-drawn .amount {
  background-color: #c50105;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .bar {
  background-color: #950002;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .bar .amount:before {
  background-color: #950002;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .number {
  background-color: #74080a;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar {
  background-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.most-drawn .amount {
  color: #fff;
  background-color: #c50005;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.least-drawn .amount {
  color: #fff;
  background-color: #950002;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount {
  background-color: #fff;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount:before {
  background-color: #505050;
}
.statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
  width: 7vw;
  height: 7vw;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.lotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
    width: 80%;
    height: initial;
    line-height: 1.6;
    padding: 0.3rem 0;
    left: 0;
    right: 0;
  }
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper {
  background-color: #000078;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-placeholder .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list {
  background-color: #000078;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list .game-client-dropdown-list-item:hover {
  background-color: #0000b4;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option {
  color: #4f5254;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-numbers-type-switch-wrapper {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper {
  border-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper {
  background-color: #0000b4;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper:hover {
  background-color: #000078;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active {
  background-color: #fff;
  color: #4f5254;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active:hover {
    background-color: #fff;
  }
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view {
  background-color: #000078;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper {
  background: repeating-linear-gradient(0deg, #fff, #fff 19px, #777777 20px, #777777 20px);
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered .bar {
  background-color: #0000b4;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar {
  background-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar.selected {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:before,
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:after {
  background-color: #000078;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view {
  background-color: #0000b4;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view {
    background-color: #0000ff;
  }
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-graph-slide-button.right {
  background: linear-gradient(to right, rgba(0, 0, 255, 0) 0%, rgba(0, 0, 255, 0.65) 25%, rgba(0, 0, 255, 0.8) 50%, rgba(0, 0, 255, 0.95) 100%);
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-graph-slide-button.left {
  background: linear-gradient(to right, rgba(0, 0, 255, 0.95) 0%, rgba(0, 0, 255, 0.8) 50%, rgba(0, 0, 255, 0.65) 75%, rgba(0, 0, 255, 0) 100%);
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-graph-slide-button .icon {
  fill: #eee;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
  background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
    background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
  }
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar .amount:before {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .number {
  background-color: #feb700;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar {
    background-color: #0000b4;
  }
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar .amount:before {
    background-color: #0000b4;
  }
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special .number {
  line-height: 1.3;
  border-radius: 0;
  padding: 0.45rem 0 0.65rem;
  color: #fff;
  background-color: transparent;
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-white.svg);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special .number {
    font-size: 1.3rem;
    line-height: 1.6;
  }
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .bar {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .bar .amount:before {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .number {
  color: #feb700;
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-yellow.svg);
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar {
  background-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.most-drawn .amount {
  color: #fff;
  background-color: #000078;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.least-drawn .amount {
  color: #fff;
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount {
  background-color: #fff;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount:before {
  background-color: #505050;
}
.statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
  width: 7vw;
  height: 7vw;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.vikinglotto-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
    width: 80%;
    height: initial;
    line-height: 1.6;
    padding: 0.3rem 0;
    left: 0;
    right: 0;
  }
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper {
  color: #fff;
  background-color: #0A0A0A;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-placeholder {
  background-color: rgba(255, 255, 255, 0.3);
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-placeholder .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list {
  color: #0A0A0A;
  background-color: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option {
  color: #0A0A0A;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option .filter-icon {
  fill: #0A0A0A;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-numbers-type-switch-wrapper {
  background-color: #171717;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper {
  border-color: #dcdcdc;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper {
  background-color: #eee;
  border-color: #dcdcdc;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper:hover {
  background-color: #e4e4e4;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active {
  background-color: #dcdcdc;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active:hover {
    background-color: #dcdcdc;
  }
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view {
  background-color: #0A0A0A;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button {
  color: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper {
  background: repeating-linear-gradient(0deg, #eee, #eee 19px, #777777 20px, #777777 20px);
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered .bar {
  background-color: #bb7f36;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar {
  color: #fff;
  background-color: #505050;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar.selected {
  background-color: #bb7f36;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view {
  background-color: #201e20;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-graph-slide-button.right {
  background: linear-gradient(to right, rgba(32, 30, 32, 0) 0%, rgba(32, 30, 32, 0.65) 25%, rgba(32, 30, 32, 0.8) 50%, rgba(32, 30, 32, 0.95) 100%);
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-graph-slide-button.left {
  background: linear-gradient(to right, rgba(32, 30, 32, 0.95) 0%, rgba(32, 30, 32, 0.8) 50%, rgba(32, 30, 32, 0.65) 75%, rgba(32, 30, 32, 0) 100%);
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-graph-slide-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
  background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
    background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
  }
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar {
  background-color: #bb7f36;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar .amount:before {
  background-color: #bb7f36;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .number {
  color: #fff;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-gold_with-gradient.svg);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar {
    background-color: #74653c;
  }
  .statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar .amount:before {
    background-color: #74653c;
  }
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special .number {
  height: 3.2rem;
  padding-top: .7rem;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-grey.svg);
  bottom: -0.8rem;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.special.selected .number {
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar {
  background-color: #505050;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.most-drawn .amount {
  color: #fff;
  background-color: #bb7f36;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.least-drawn .amount {
  color: #fff;
  background-color: #171717;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount {
  background-color: #fff;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount:before {
  background-color: #505050;
}
.statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
  width: 7vw;
  height: 7vw;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-joker-numbershape_use);
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
    background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-joker-numbershape.svg);
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.eurojackpot-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
    width: 100%;
    height: 3rem;
    padding-top: .5rem;
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics {
  line-height: 1.6;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper {
  background-color: #0091cf;
  color: #fff;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button {
    padding: 1.2rem 0 0.6rem;
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .icon {
  fill: #fff;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header {
    padding: 1rem 0 0.7rem 2rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-placeholder {
    padding: 1rem 2rem 0.7rem;
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-placeholder .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list {
  background-color: #0091cf;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-header .period-filter-game-client-dropdown .game-client-dropdown-list .game-client-dropdown-list-item:hover {
  background-color: #1e55a0;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-name .filter-row-reset .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-content-wrapper .period-filter-row .filter-row-options .option {
  color: #4f5254;
  line-height: 1.4;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-numbers-type-switch-wrapper {
  background-color: #0000ff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper {
  border-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper {
  background-color: #0000b4;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper:hover {
  background-color: #000078;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active {
  background-color: #fff;
  color: #4f5254;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active:hover {
    background-color: #fff;
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view {
  background-color: #00a4eb;
  color: #fff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-module-graph-overview-expand-wrapper .graph-overview-button .icon {
  fill: #fff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper {
  background: repeating-linear-gradient(0deg, #fff, #fff 19px, #777777 20px, #777777 20px);
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper.hovered .bar {
  background-color: #1e55a0;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar {
  background-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-bars-wrapper .statistics-small-bar-wrapper .bar.selected {
  background-color: #21375f;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:before,
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-small-view .statistics-module-graph-overview-inner-wrapper .statistics-internal-wrapper .statistics-zoom-finder-wrapper .zoom-finder:after {
  background-color: #00a4eb;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view {
  background-color: #00a5eb;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view {
    background-color: #006590;
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-graph-slide-button.right {
  background: linear-gradient(to right, rgba(0, 101, 144, 0) 0%, rgba(0, 101, 144, 0.65) 25%, rgba(0, 101, 144, 0.8) 50%, rgba(0, 101, 144, 0.95) 100%);
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-graph-slide-button.left {
  background: linear-gradient(to right, rgba(0, 101, 144, 0.95) 0%, rgba(0, 101, 144, 0.8) 50%, rgba(0, 101, 144, 0.65) 75%, rgba(0, 101, 144, 0) 100%);
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-graph-slide-button .icon {
  fill: #eee;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
  background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper {
    background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar {
  background-color: #21375f;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .bar .amount:before {
  background-color: #21375f;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .number {
  background-color: #1e55a0;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper.selected .number {
    background-color: #21375f;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar {
    background-color: #1e55a0;
  }
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper:hover .bar .amount:before {
    background-color: #1e55a0;
  }
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar {
  background-color: #4f5254;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.most-drawn .amount {
  color: #fff;
  background-color: #21375f;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar.least-drawn .amount {
  color: #fff;
  background-color: #1e55a0;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount {
  background-color: #fff;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .bar .amount:before {
  background-color: #505050;
}
.statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
  width: 7vw;
  height: 7vw;
  margin: auto;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
  background-color: #0091cf;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper.keno-statistics .statistics-module-large-view .statistics-module-large-internal-wrapper .statistics-large-bar-wrapper .number {
    width: 80%;
    height: initial;
    padding: .4rem 0 .2rem;
    left: 0;
    right: 0;
    background-color: #0091cf;
  }
}
.region-keno .statistics-module .statistics-module-wrapper .statistics-module-period-filter-wrapper .period-filter-inner-wrapper .period-filter-toggle-buttons-wrapper .period-filter-toggle-button .text {
  top: 0;
}
