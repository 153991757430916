.btn-with-x-padding {
  padding-left: 2rem;
}
@keyframes btn-pulse-inner-loading-animation {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  95%,
  100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0);
  }
}
@keyframes btn-pulse-loading-animation {
  38% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  65%,
  100% {
    transform: scale(1);
  }
}
.multi-client-top-spot {
  color: var(--multi-client-top-spot__color, white);
  position: relative;
  z-index: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 0 7rem;
  font-size: 1.2rem;
  line-height: 1.3;
  letter-spacing: 0.1rem;
  font-weight: 800;
}
.region-eurojackpot .multi-client-top-spot {
  background-color: #000000;
}
.region-lotto .multi-client-top-spot {
  background-color: #c50005;
}
.region-vikinglotto .multi-client-top-spot {
  background-color: #0000ff;
}
.multi-client-top-spot + #multi-client {
  margin-top: -4.5rem;
}
.multi-client-top-spot--layout-1 .multi-client-top-spot__count-down-large {
  display: none;
}
.multi-client-top-spot--layout-2 {
  padding-top: 3.5rem;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__pool-size,
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-small {
  display: none;
}
@media screen and (min-width: 1440px) {
  .multi-client-top-spot {
    font-size: 2rem;
    padding: 4rem 0 9rem;
  }
}
.multi-client-top-spot > .responsive-bg {
  position: absolute;
  z-index: -2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.multi-client-top-spot > .responsive-bg picture {
  width: 100%;
  height: 100%;
}
.multi-client-top-spot > .responsive-bg picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.multi-client-top-spot__lottie {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.multi-client-top-spot__lottie .multi-client-top-spot__lottie-player {
  position: absolute;
  top: 0;
  width: 500rem;
  height: 100%;
}
.multi-client-top-spot__title {
  opacity: 0.8;
}
.multi-client-top-spot--layout-1 .multi-client-top-spot__title {
  margin-bottom: 0.7rem;
}
@media screen and (min-width: 1440px) {
  .multi-client-top-spot--layout-1 .multi-client-top-spot__title {
    margin-bottom: 1.2rem;
  }
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__title {
  margin-bottom: 0.1rem;
}
@media screen and (min-width: 1440px) {
  .multi-client-top-spot--layout-2 .multi-client-top-spot__title {
    margin-bottom: 0.5rem;
  }
}
.multi-client-top-spot--layout-1 .multi-client-top-spot__title--layout-2,
.multi-client-top-spot--layout-2 .multi-client-top-spot__title--layout-1 {
  display: none;
}
.multi-client-top-spot__pool-size {
  font-size: 7.6rem;
  line-height: 0.9;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__pool-size {
  display: none;
}
@media screen and (min-width: 1440px) {
  .multi-client-top-spot__pool-size {
    font-size: 8rem;
    margin-bottom: 1.5rem;
  }
}
.multi-client-top-spot__cta {
  cursor: pointer;
  height: 5.6rem;
  min-width: 24rem;
  background-color: #FEB700;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000000;
  font-size: 1.2rem;
}
.multi-client-top-spot__count-down {
  display: flex;
}
.multi-client-top-spot--layout-1 .multi-client-top-spot__count-down {
  margin-bottom: 2.4rem;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down {
  margin-bottom: 5rem;
}
.multi-client-top-spot__count-down-label {
  opacity: 0.8;
  margin-right: 0.5rem;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-label {
  display: none;
}
.multi-client-top-spot--layout-1 .multi-client-top-spot__count-down-time span:not(:last-child) {
  position: relative;
  padding-right: 1rem;
}
.multi-client-top-spot--layout-1 .multi-client-top-spot__count-down-time span:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 0.1rem;
  top: 0.3rem;
  bottom: 0.3rem;
  right: 0.5rem;
  background-color: var(--multi-client-top-spot__color, white);
  opacity: 0.5;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time {
  font-size: 4.4rem;
  letter-spacing: normal;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span {
  position: relative;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:not(:last-child) {
  padding-right: 3rem;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 0.1rem;
  top: 1.2rem;
  bottom: 0.9rem;
  right: 1.4rem;
  background-color: var(--multi-client-top-spot__color, white);
  opacity: 0.5;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span::before {
  text-transform: uppercase;
  position: absolute;
  bottom: -1.3rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:not(:last-child)::before {
  padding-right: 3rem;
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:first-child::before {
  content: "Dage";
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:nth-child(2)::before {
  content: "Timer";
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:nth-child(3)::before {
  content: "Min.";
}
.multi-client-top-spot--layout-2 .multi-client-top-spot__count-down-time span:nth-child(4)::before {
  content: "Sek.";
}
.multi-client-top-spot__count-down-small {
  display: flex;
  margin-bottom: 2.4rem;
}
.multi-client-top-spot__count-down-small-label {
  opacity: 0.8;
  margin-right: 0.5rem;
}
.multi-client-top-spot__count-down-small-time span:not(:last-child) {
  position: relative;
  padding-right: 1rem;
}
.multi-client-top-spot__count-down-small-time span:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 0.1rem;
  top: 0.3rem;
  bottom: 0.3rem;
  right: 0.5rem;
  background-color: var(--multi-client-top-spot__color, white);
  opacity: 0.5;
}
.multi-client-top-spot__count-down-large {
  display: flex;
  margin-bottom: 5rem;
}
.multi-client-top-spot__count-down-large-time {
  font-size: 4.4rem;
  letter-spacing: normal;
}
.multi-client-top-spot__count-down-large-time span {
  position: relative;
}
.multi-client-top-spot__count-down-large-time span:not(:last-child) {
  padding-right: 3rem;
}
.multi-client-top-spot__count-down-large-time span:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 0.1rem;
  top: 1.2rem;
  bottom: 0.9rem;
  right: 1.4rem;
  background-color: var(--multi-client-top-spot__color, white);
  opacity: 0.5;
}
.multi-client-top-spot__count-down-large-time span::before {
  text-transform: uppercase;
  position: absolute;
  bottom: -1.3rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}
.multi-client-top-spot__count-down-large-time span:not(:last-child)::before {
  padding-right: 3rem;
}
.multi-client-top-spot__count-down-large-time span:first-child::before {
  content: "Timer";
}
.multi-client-top-spot__count-down-large-time span:nth-child(2)::before {
  content: "Min.";
}
.multi-client-top-spot__count-down-large-time span:nth-child(3)::before {
  content: "Sek.";
}
