@import (reference) "../../Mixins/_mixins.less";

// Packages confirm:
.multi-packages-purchase-flow {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.multi-packages-purchase-flow__headline {
  max-width: 68rem;
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 700;
  text-align: center;
  margin: auto;
  @media @sm {
    font-size: 4rem;
    line-height: 4.3rem;
  }
}
.multi-packages-purchase-flow__content {
  width: 100%;
  flex: 1;
}
.multi-packages-confirm__content {
  display: flex;
  gap: 3.8rem;
  margin: 3.2rem auto;
  flex-direction: column;
  align-items: center;
  padding: 0 2.6rem;
  overflow: clip;

  @media @md {
    gap: 5rem;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
  }
}

.multi-packages-confirm__content__back-button {
  position: absolute;
  top: 2rem;
  left: 2.4rem;
  padding: 1rem;
  cursor: pointer;
}

.multi-packages-confirm__content__back-button-icon {
  width: 2.4rem;
  height: 1.2rem;
}

.multi-packages-purchase-flow__loader {
  position: relative;
  top: 50%;
}

.multi-packages-confirm__packages-wrapper {
  width: 100%;
  max-width: 70rem;
  background-color: @ds-gray-100;
  border-radius: 32px;
  display: flex;
  padding: 2.2rem 1.6rem;
  gap: 2rem;
  flex-direction: column;
  box-shadow: 4px 4px 0 @ds-gray-175;
  margin: 0 0.4rem 0.4rem 0;
  @media @sm {
    padding: 3.3rem 2.6rem;
    gap: 3.2rem;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.multi-packages-confirm-game-card {
  width: 100%;
  background-color: @ds-white;
  border-radius: 16px;
  padding: 2.4rem 1.2rem 2.2rem;
  box-sizing: border-box;
  align-self: flex-start;
  @media @sm {
    width: ~"calc(50% - 1.6rem)";
    padding: 3rem 3rem 2rem;
  }

  &--allornothing {
    color: @ds-dustypurple-900;
  }
  &--lotto {
    color: @ds-red;
  }
  &--vikinglotto {
    color: @ds-blue;
  }
  &--eurojackpot {
    color: @ds-honey;
  }
  &--keno {
    color: @ds-light-blue;
  }
}

.multi-packages-confirm-game-card__joker {
  color: @ds-lightgreen-400;
  margin-top: 0.8rem;
  border-top: 1px dashed @ds-gray-200;
  padding-top: 1.4rem;
}
.multi-packages-confirm-game-card__header {
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
}
.multi-packages-confirm-game-card__logo {
  &--lotto {
    height: 2rem;
    width: 9rem;
    margin-top: 0.3rem;
  }

  &--vikinglotto {
    height: 2.6rem;
    width: 13.5rem;
    margin-bottom: -0.3rem;
  }

  &--eurojackpot {
    height: 2rem;
    width: 12.8rem;
  }

  &--joker {
    height: 1.4rem;
    width: 8.8rem;
  }
}
.multi-packages-confirm-game-card__day {
  color: @ds-black;
  opacity: 0.45;
  font-size: 1.2rem;
  font-weight: 300;
  font-style: italic;
}

.multi-packages-confirm-game-card__details {
  margin-top: 0.4rem;
}

.multi-packages-confirm-game-card__details-header {
  display: flex;
  padding: 0.8rem 0;

  &.multi-packages-confirm-game-card__details-header--collapsible {
    cursor: pointer;
  }
}

.multi-packages-confirm-game-card__details-header-icon-cell {
  height: 2rem;
  width: 2rem;
  margin-right: 2rem;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.multi-packages-confirm-game-card__details-header-label-cell,
.multi-packages-confirm-game-card__details-header-value-cell {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.4rem;
}

.multi-packages-confirm-game-card__details-header-label-cell {
  flex: 1;
  opacity: 0.6;
}

.multi-packages-confirm-game-card__details-header-value-cell {
  margin-left: 0.8rem;
}

.multi-packages-confirm-game-card__details-header-collapsible-icon {
  width: 1rem;
  height: 0.6rem;
  color: @ds-black;
  margin-top: 0.9rem;
  transform: rotate(180deg);
  transition: transform 0.15s linear;
}

.multi-packages-confirm-game-card__details-row--opened {
  .multi-packages-confirm-game-card__details-header-collapsible-icon {
    transform: rotate(0deg);
  }
}

.multi-packages-confirm-game-card__details-game-rows {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s linear;
}

.multi-packages-confirm-game-card__details-game-rows-inner {
  padding: 0.4rem 0;
}

.multi-packages-confirm-game-card__details-game-row {
  display: flex;
  font-size: 1.1rem;
  padding: 0.6rem 1.2rem;
  position: relative;

  &:nth-child(odd)::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    opacity: 0.2;
  }
}

.multi-packages-confirm-game-card__details-game-row-count,
.multi-packages-confirm-game-card__details-game-row-numbers {
  color: @ds-black;
  position: relative;
}

.multi-packages-confirm-game-card__details-game-row-count {
  width: 2.2rem;
  margin-right: 1.1rem;
  font-weight: 800;
}

.multi-packages-confirm-game-card__details-game-row-numbers {
  flex: 1;
  display: flex;
  gap: 0.8rem;

  span {
    flex: 1;
    text-align: center;
  }
}

.multi-packages-confirm__basket {
  width: 100%;
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  @media @md {
    width: 24.3rem;
    top: 0;
    bottom: auto;
  }

  &::after {
    content: '';
    width: 100vw;
    height: ~"calc(100% + 2.4rem)";
    pointer-events: none;
    background: linear-gradient(0deg, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50vw) translateY(0%);
    z-index: -1;
    @media @md {
      width: 100%;
      transform: translateX(-50%) translateY(0%);
    }
  }
}
.multi-packages-confirm__basket-inner {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  position: sticky;
  top: 0;
  @media @md {
    padding-bottom: 2.4rem;
  }
}
.multi-packages-confirm__basket-overview {
  width: 24.3rem;
  border-bottom: 1px dashed @ds-gray-200;
  padding-top: 2.4rem;
  margin: auto;
}
.multi-packages-confirm__basket-item {
  display: flex;
  align-items: center;
}
.multi-packages-confirm__basket-item-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 2rem;
  padding: 0.7rem 0;
  opacity: 0.6;
}
.multi-packages-confirm__basket-item-label,
.multi-packages-confirm__basket-item-value {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 700;
}
.multi-packages-confirm__basket-item-label {
  flex: 1;
}
.multi-packages-confirm__basket-buttons {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  @media @sm {
    flex-direction: row;
  }
  @media @md {
    flex-direction: column;
  }
}

.multi-packages-confirm__basket-subscription-option {
  @media @md {
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: @ds-gray-175;
    padding: 1.2rem 0;
  }
}

.multi-packages-confirm__basket-subscription-icon {
  height: 2.2rem;
  margin: 0 auto 1rem;
  display: none;
  @media @md {
    display: block;
  }
}

.multi-packages-confirm__basket-subscription-description {
  max-width: 90%;
  display: none;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
  margin: 0 auto 1rem;
  text-align: center;
  @media @md {
    display: block;
  }
}

.multi-packages-receipt__buy-as-subscription {
  max-width: 32rem;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: @ds-gray-175;
  padding: 1.2rem 0;
  margin: -3rem auto 0;
}

.multi-packages-receipt__buy-as-subscription-description {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
  margin: 0 auto 1rem;
  text-align: center;
}

.multi-packages-purchase-flow__inner-section {
  max-width: 46rem;
  margin: auto;
  text-align: center;
  padding: 3rem 2rem;

  .multi-packages-button {
    display: inline-flex;
  }
}
.multi-packages-purchase-flow__inner-section-heading {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 700;
  color: @ds-black;
  margin-bottom: 2.4rem;
  @media @sm {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.multi-packages-purchase-flow__inner-section-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: @ds-gray-450;
  margin-bottom: 2.4rem;
  @media @sm {
    font-size: 1.6rem;
  }
}

// multi-packages receipt:
.multi-packages-receipt {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  @media @sm {
    background-color: @ds-gray-100;
    flex-direction: row;
  }

  .multi-packages-receipt__left,
  .multi-packages-receipt__right {
    width: 100%;
    text-align: center;
    position: relative;

    @media @sm {
      width: 50%;
      padding: 4rem 0;
    }
  }

  .multi-packages-receipt__left {
    @media @sm {
      background-color: @ds-white;
      border-radius: 0 32px 32px 0;
    }
  }

  .multi-packages-receipt__header {
    max-width: 42rem;
    padding: 0 3rem 3rem;
    margin: 0 auto;
    @media @sm {
      padding: 5rem 0;
    }
  }

  .multi-packages-receipt__heading {
    font-size: 2.8rem;
    line-height: 3.4rem;
    font-weight: 700;
    color: @ds-black;
    margin-bottom: 2.4rem;
    @media @sm {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  .multi-packages-receipt__subheading {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    color: @ds-gray-450;
    @media @sm {
      font-size: 1.6rem;
    }
  }

  .multi-packages-receipt__games {
    margin: 0 auto 2rem;
    @media @sm {
      max-width: 43.4rem;
    }
  }
  .multi-packages-receipt__game-countdown {
    width: 29.5rem;
    margin: auto;
    @media @sm {
      width: 43.4rem;
    }
    .countdown {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 2rem;
      border-radius: 3rem;
      text-align: center;
      cursor: pointer;
      opacity: 0.2;
      @media @sm {
        padding: 3.5rem 4.2rem;
      }

      .countdown-headline {
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin: 0 0 1rem;
        text-transform: uppercase;
        @media @sm {
          font-size: 2.4rem;
          line-height: 2.6rem;
          font-weight: 700;
        }
      }

      .number-container {
        flex: 1;

        span {
          display: block;
        }

        .number {
          font-size: 5rem;
          line-height: 4.7rem;
          font-weight: 800;
          padding-bottom: 0.7rem;
          position: relative;
          @media @sm {
            font-size: 6.8rem;
            line-height: 7.8rem;
          }

          &::before {
            content: "";
            width: 0.3rem;
            background-color: fade(@ds-white, 40%);
            position: absolute;
            left: 0;
            top: 1rem;
            bottom: 1rem;
            transform: translateX(-50%);
          }
        }

        .time-denominator {
          font-size: 1rem;
          line-height: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          @media @sm {
            font-size: 1.7rem;
            line-height: 1.5rem;
          }
        }

        &:first-of-type .number::before {
          display: none;
        }
      }
    }

    &.multi-packages-receipt__game-countdown--eurojackpot {
      .countdown {
        background-color: @eurojackpot-gold-medium;
        color: @ds-white;
      }
    }
  
    &.multi-packages-receipt__game-countdown--lotto {
      .countdown {
        background-color: @lotto-red;
        color: @ds-white;
      }
    }
  
    &.multi-packages-receipt__game-countdown--vikinglotto {
      .countdown {
        background-color: @vikinglotto-blue;
        color: @ds-white;
      }
    }

    &.multi-packages-receipt__game-countdown--selected {
      .countdown {
        opacity: 1;
        display: flex;
      }
    }
  }

  .multi-packages-receipt__games-tabs {
    margin: 0 auto;
    position: sticky;
    top: 0;
    z-index: 2;

    @media @sm {
      margin: 0 auto 4rem;
    }

    .multi-packages-tabs {
      width: ~"calc(100% - 6rem)";
      background-color: @ds-white;
      @media @sm {
        width: auto;
        background-color: @ds-gray-100;
      }
    }
  }

  .fireworks-animation {
    height: 50rem;
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;

    &--lottosaturday {
      filter: brightness(0.1) contrast(0.5) saturate(200) hue-rotate(340deg);
    }

    &--vikinglotto {
      filter: brightness(0.2) contrast(0.8) saturate(150) hue-rotate(220deg);
    }
  }
}

.multi-packages-subscription-options-modal {
  .multi-packages-subscription-options-modal__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .multi-packages-subscription-options-modal__heading {
    max-width: 40rem;
    font-size: 2.6rem;
    line-height: 3rem;
    font-weight: 700;
    color: @ds-black;
    margin-bottom: 1.2rem;
  }

  .multi-packages-subscription-options-modal__body {
    max-width: 50rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: @ds-gray-450;
    margin-bottom: 1.2rem;
  }

  .multi-packages-subscription-options-modal__options {
    max-width: 44rem;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-bottom: 1.4rem;
  }
  .multi-packages-subscription-options-modal__option {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    align-items: center;
    cursor: pointer;

    .multi-packages-button {
      width: ~"calc(100% - 4rem)";
      margin-top: 1rem;
    }
  }
  .multi-packages-subscription-options-modal__option-icon-wrapper {
    width: 12rem;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @ds-green;
    border-radius: 24px;
    margin-bottom: 1rem;

    .region-lotto & {
      background-color: @lotto-red;
    }
    .region-vikinglotto & {
      background-color: @vikinglotto-blue;
    }
    .region-eurojackpot & {
      background-color: @eurojackpot-gold-medium;
    }
    .region-keno & {
      background-color: @ds-light-blue;
    }
    .region-allornothing & {
      background-color: @ds-dustypurple-900;
    }
  }
  .multi-packages-subscription-options-modal__option-title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;

    .region-lotto & {
      color: @lotto-red;
    }
    .region-vikinglotto & {
      color: @vikinglotto-blue;
    }
    .region-eurojackpot & {
      color: @eurojackpot-gold-medium;
    }
    .region-keno & {
      color: @ds-light-blue;
    }
    .region-allornothing & {
      color: @ds-dustypurple-900;
    }
  }
  .multi-packages-subscription-options-modal__option-description {
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: auto;
  }
  .multi-packages-subscription-options-modal__options-divider {
    font-size: 2rem;
    line-height: 3.2rem;
    font-family: "Shadows Into Light", cursive;
    font-weight: bold;
    color: #92969E;
    padding: 4.4rem 1.2rem 0;
  }
}