@import url("../../Mixins/_mixins");

.winning-numbers-winner-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 2rem;
  border-style: hidden;
  box-shadow: 0 0 0 0.2rem @ds-gray-75 inset;
  margin-bottom: 2rem;
  color: @ds-black;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.05rem;
  overflow: hidden;

  @media @xxl {
    font-size: 1.6rem;
    margin-bottom: auto;
  }
}

.winning-numbers-winner-table__header {
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.1rem;

  th {
    color: @ds-white;
    padding: 1.8rem 1.5rem;

    @media @xxl {
      padding: 2.4rem 3rem;
    }

    &:first-child {
      border-top-left-radius: 2rem;

      @media @xxl {
        text-align: left;
      }
    }

    &:last-child {
      border-top-right-radius: 2rem;

      @media @xxl {
        text-align: right;
      }
    }

    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .winning-numbers-winner-table--lotto & th {
    background-color: @ds-red;
  }

  .winning-numbers-winner-table--vikinglotto & th {
    background-color: @ds-blue;
  }

  .winning-numbers-winner-table--eurojackpot & th {
    background-color: @ds-black;
  }

  .winning-numbers-winner-table--joker & th {
    background-color: @ds-lightgreen;
  }
}

.winning-numbers-winner-table__row {

  td {
    padding: 2rem 1.6rem;

    @media @xxl {
      padding: 2.3rem 3rem;
    }

    &:first-child {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      text-align: right;
      padding-bottom: 2.3rem;

      @media @xxl {
        padding-bottom: 2.6rem;
      }
    }
  }

  &:nth-child(2) td {
    padding-top: 2.3rem;

    @media @xxl {
      padding-top: 2.6rem;
    }
  }

  &:nth-child(even) {
    .winning-numbers-winner-table--lotto & {
      background-color: fade(@ds-red, 5%);
    }

    .winning-numbers-winner-table--vikinglotto & {
      background-color: fade(@ds-blue, 5%);
    }

    .winning-numbers-winner-table--eurojackpot & {
      background-color: fade(@ds-black, 5%);
    }

    .winning-numbers-winner-table--joker & {
      background-color: fade(@ds-lightgreen, 7%);
    }
  }
}
