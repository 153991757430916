.flip-clock {
  /* VARIABLES */
  /* FUNCTIONS */
  /* SIZES */
  /* STYLES */
  display: block;
  padding: 0.5rem 0.5rem 2.5rem;
  /* PLAY */
  /* SHADOW */
  /* DOWN */
  /* NO FLIPPING */
}
.flip-clock ul.flip {
  border-radius: 0.33333333rem;
  width: 6.5rem;
  height: 5rem;
  font-size: 3.5rem;
  line-height: 5rem;
  box-shadow: 0 0.11111111rem 0.16666667rem rgba(0, 0, 0, 0.7);
}
.flip-clock ul.flip:after {
  top: 5rem;
  font-size: 1.2rem;
  height: 2.64rem;
  line-height: 2.64rem;
}
.flip-clock.flip-clock--xsmall ul.flip:after {
  font-size: 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
}
.flip-clock.flip-clock--small ul.flip:after {
  font-size: 1.1rem;
  height: 2.42rem;
  line-height: 2.42rem;
}
.flip-clock.flip-clock--xlarge ul.flip:after {
  font-size: 1.3rem;
  height: 2.86rem;
  line-height: 2.86rem;
}
.flip-clock ul.flip li .flipper div.up:after {
  top: calc(( 5rem  / 2) - 1px);
}
.flip-clock ul.flip li .flipper div div.inn {
  border-radius: 0.33333333rem;
}
.flip-clock ul.flip:nth-child(1):after {
  content: "Dage";
}
.flip-clock ul.flip:nth-child(2):after {
  content: "Timer";
}
.flip-clock ul.flip:nth-child(3):after {
  content: "Minutter";
}
.flip-clock ul.flip:nth-child(4):after {
  content: "Sekunder";
}
.flip-clock.flip-clock--xsmall ul.flip:nth-child(3):after,
.flip-clock.flip-clock--small ul.flip:nth-child(3):after {
  content: "Min.";
}
.flip-clock.flip-clock--xsmall ul.flip:nth-child(4):after,
.flip-clock.flip-clock--small ul.flip:nth-child(4):after {
  content: "Sek.";
}
.flip-clock.flip-clock--xsmall ul.flip {
  border-radius: 0.2rem;
  width: 3.9rem;
  height: 3rem;
  font-size: 2.1rem;
  line-height: 3rem;
  box-shadow: 0 0.06666667rem 0.1rem rgba(0, 0, 0, 0.7);
}
.flip-clock.flip-clock--xsmall ul.flip:after {
  top: 3rem;
  font-size: 1.2rem;
  height: 2.64rem;
  line-height: 2.64rem;
}
.flip-clock.flip-clock--xsmall.flip-clock--xsmall ul.flip:after {
  font-size: 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
}
.flip-clock.flip-clock--xsmall.flip-clock--small ul.flip:after {
  font-size: 1.1rem;
  height: 2.42rem;
  line-height: 2.42rem;
}
.flip-clock.flip-clock--xsmall.flip-clock--xlarge ul.flip:after {
  font-size: 1.3rem;
  height: 2.86rem;
  line-height: 2.86rem;
}
.flip-clock.flip-clock--xsmall ul.flip li .flipper div.up:after {
  top: calc(( 3rem  / 2) - 1px);
}
.flip-clock.flip-clock--xsmall ul.flip li .flipper div div.inn {
  border-radius: 0.2rem;
}
.flip-clock.flip-clock--xsmall ul.flip:nth-child(1):after {
  content: "Dage";
}
.flip-clock.flip-clock--xsmall ul.flip:nth-child(2):after {
  content: "Timer";
}
.flip-clock.flip-clock--xsmall ul.flip:nth-child(3):after {
  content: "Minutter";
}
.flip-clock.flip-clock--xsmall ul.flip:nth-child(4):after {
  content: "Sekunder";
}
.flip-clock.flip-clock--xsmall.flip-clock--xsmall ul.flip:nth-child(3):after,
.flip-clock.flip-clock--xsmall.flip-clock--small ul.flip:nth-child(3):after {
  content: "Min.";
}
.flip-clock.flip-clock--xsmall.flip-clock--xsmall ul.flip:nth-child(4):after,
.flip-clock.flip-clock--xsmall.flip-clock--small ul.flip:nth-child(4):after {
  content: "Sek.";
}
.flip-clock.flip-clock--small ul.flip {
  border-radius: 0.26666667rem;
  width: 5.2rem;
  height: 4rem;
  font-size: 2.8rem;
  line-height: 4rem;
  box-shadow: 0 0.08888889rem 0.13333333rem rgba(0, 0, 0, 0.7);
}
.flip-clock.flip-clock--small ul.flip:after {
  top: 4rem;
  font-size: 1.2rem;
  height: 2.64rem;
  line-height: 2.64rem;
}
.flip-clock.flip-clock--small.flip-clock--xsmall ul.flip:after {
  font-size: 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
}
.flip-clock.flip-clock--small.flip-clock--small ul.flip:after {
  font-size: 1.1rem;
  height: 2.42rem;
  line-height: 2.42rem;
}
.flip-clock.flip-clock--small.flip-clock--xlarge ul.flip:after {
  font-size: 1.3rem;
  height: 2.86rem;
  line-height: 2.86rem;
}
.flip-clock.flip-clock--small ul.flip li .flipper div.up:after {
  top: calc(( 4rem  / 2) - 1px);
}
.flip-clock.flip-clock--small ul.flip li .flipper div div.inn {
  border-radius: 0.26666667rem;
}
.flip-clock.flip-clock--small ul.flip:nth-child(1):after {
  content: "Dage";
}
.flip-clock.flip-clock--small ul.flip:nth-child(2):after {
  content: "Timer";
}
.flip-clock.flip-clock--small ul.flip:nth-child(3):after {
  content: "Minutter";
}
.flip-clock.flip-clock--small ul.flip:nth-child(4):after {
  content: "Sekunder";
}
.flip-clock.flip-clock--small.flip-clock--xsmall ul.flip:nth-child(3):after,
.flip-clock.flip-clock--small.flip-clock--small ul.flip:nth-child(3):after {
  content: "Min.";
}
.flip-clock.flip-clock--small.flip-clock--xsmall ul.flip:nth-child(4):after,
.flip-clock.flip-clock--small.flip-clock--small ul.flip:nth-child(4):after {
  content: "Sek.";
}
.flip-clock.flip-clock--large ul.flip {
  border-radius: 0.4rem;
  width: 7.8rem;
  height: 6rem;
  font-size: 4.2rem;
  line-height: 6rem;
  box-shadow: 0 0.13333333rem 0.2rem rgba(0, 0, 0, 0.7);
}
.flip-clock.flip-clock--large ul.flip:after {
  top: 6rem;
  font-size: 1.2rem;
  height: 2.64rem;
  line-height: 2.64rem;
}
.flip-clock.flip-clock--large.flip-clock--xsmall ul.flip:after {
  font-size: 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
}
.flip-clock.flip-clock--large.flip-clock--small ul.flip:after {
  font-size: 1.1rem;
  height: 2.42rem;
  line-height: 2.42rem;
}
.flip-clock.flip-clock--large.flip-clock--xlarge ul.flip:after {
  font-size: 1.3rem;
  height: 2.86rem;
  line-height: 2.86rem;
}
.flip-clock.flip-clock--large ul.flip li .flipper div.up:after {
  top: calc(( 6rem  / 2) - 1px);
}
.flip-clock.flip-clock--large ul.flip li .flipper div div.inn {
  border-radius: 0.4rem;
}
.flip-clock.flip-clock--large ul.flip:nth-child(1):after {
  content: "Dage";
}
.flip-clock.flip-clock--large ul.flip:nth-child(2):after {
  content: "Timer";
}
.flip-clock.flip-clock--large ul.flip:nth-child(3):after {
  content: "Minutter";
}
.flip-clock.flip-clock--large ul.flip:nth-child(4):after {
  content: "Sekunder";
}
.flip-clock.flip-clock--large.flip-clock--xsmall ul.flip:nth-child(3):after,
.flip-clock.flip-clock--large.flip-clock--small ul.flip:nth-child(3):after {
  content: "Min.";
}
.flip-clock.flip-clock--large.flip-clock--xsmall ul.flip:nth-child(4):after,
.flip-clock.flip-clock--large.flip-clock--small ul.flip:nth-child(4):after {
  content: "Sek.";
}
.flip-clock.flip-clock--xlarge ul.flip {
  border-radius: 0.46666667rem;
  width: 9.1rem;
  height: 7rem;
  font-size: 4.9rem;
  line-height: 7rem;
  box-shadow: 0 0.15555556rem 0.23333333rem rgba(0, 0, 0, 0.7);
}
.flip-clock.flip-clock--xlarge ul.flip:after {
  top: 7rem;
  font-size: 1.2rem;
  height: 2.64rem;
  line-height: 2.64rem;
}
.flip-clock.flip-clock--xlarge.flip-clock--xsmall ul.flip:after {
  font-size: 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
}
.flip-clock.flip-clock--xlarge.flip-clock--small ul.flip:after {
  font-size: 1.1rem;
  height: 2.42rem;
  line-height: 2.42rem;
}
.flip-clock.flip-clock--xlarge.flip-clock--xlarge ul.flip:after {
  font-size: 1.3rem;
  height: 2.86rem;
  line-height: 2.86rem;
}
.flip-clock.flip-clock--xlarge ul.flip li .flipper div.up:after {
  top: calc(( 7rem  / 2) - 1px);
}
.flip-clock.flip-clock--xlarge ul.flip li .flipper div div.inn {
  border-radius: 0.46666667rem;
}
.flip-clock.flip-clock--xlarge ul.flip:nth-child(1):after {
  content: "Dage";
}
.flip-clock.flip-clock--xlarge ul.flip:nth-child(2):after {
  content: "Timer";
}
.flip-clock.flip-clock--xlarge ul.flip:nth-child(3):after {
  content: "Minutter";
}
.flip-clock.flip-clock--xlarge ul.flip:nth-child(4):after {
  content: "Sekunder";
}
.flip-clock.flip-clock--xlarge.flip-clock--xsmall ul.flip:nth-child(3):after,
.flip-clock.flip-clock--xlarge.flip-clock--small ul.flip:nth-child(3):after {
  content: "Min.";
}
.flip-clock.flip-clock--xlarge.flip-clock--xsmall ul.flip:nth-child(4):after,
.flip-clock.flip-clock--xlarge.flip-clock--small ul.flip:nth-child(4):after {
  content: "Sek.";
}
.flip-clock.flip-clock--invert ul.flip li .flipper div div.inn {
  background-color: #ddd;
  color: #333;
}
.flip-clock.flip-clock--hidden {
  opacity: 0;
}
.flip-clock ul.flip {
  transition: all 200ms ease-out;
  user-select: none;
  position: relative;
  list-style-type: none;
  padding: 0;
  float: left;
  margin: 0 0.08em;
  font-weight: 600;
}
.flip-clock ul.flip:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  font-weight: 600;
  text-align: center;
  color: #222;
}
.flip-clock.flip-clock--label-light ul.flip:after {
  color: #eee;
}
.flip-clock ul.flip li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.flip-clock ul.flip li:first-child {
  z-index: 2;
}
.flip-clock ul.flip li .flipper {
  display: block;
  height: 100%;
}
.flip-clock ul.flip li .flipper div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.flip-clock ul.flip li .flipper div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.flip-clock ul.flip li .flipper div.up {
  transform-origin: 50% 100%;
  top: 0;
}
.flip-clock ul.flip li .flipper div.up:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 0.3rem;
  background-color: rgba(0, 0, 0, 0.4);
}
.flip-clock ul.flip li .flipper div.down {
  transform-origin: 50% 0;
  bottom: 0;
}
.flip-clock ul.flip li .flipper div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #ddd;
  text-align: center;
  background-color: #333;
}
.flip-clock ul.flip li .flipper div div.invert {
  background-color: #ddd;
  color: #333;
}
.flip-clock.flip-clock--light ul.flip li .flipper div div.inn {
  color: #444;
  background-color: #eee;
}
.flip-clock ul.flip li .flipper div.up div.inn {
  top: 0;
}
.flip-clock ul.flip li .flipper div.down div.inn {
  bottom: 0;
}
.flip-clock.play ul li.before {
  z-index: 3;
}
.flip-clock.play ul li.active {
  animation: flipclock-change-overlay 200ms 200ms linear both;
  z-index: 2;
}
@keyframes flipclock-change-overlay {
  0% {
    z-index: 2;
  }
  5% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}
.flip-clock.play ul li.active .down {
  z-index: 2;
  animation: flipclock-turn 200ms 200ms linear both;
}
@keyframes flipclock-turn {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
.flip-clock.play ul li.before .up {
  z-index: 2;
  animation: flipclock-turn2 200ms linear both;
}
@keyframes flipclock-turn2 {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-90deg);
  }
}
.flip-clock.play ul li.before .up .shadow {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, #000000 100%);
  animation: flipclock-show 200ms linear both;
}
.flip-clock.play ul li.active .up .shadow {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, #000000 100%);
  animation: flipclock-hide 200ms 200ms linear both;
}
.flip-clock.flip-clock--label-light.play ul li.active .up .shadow,
.flip-clock.flip-clock--label-light.play ul li.active .up .shadow {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.flip-clock.play ul li.before .down .shadow {
  background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0.1) 100%);
  animation: flipclock-show 200ms linear both;
}
.flip-clock.play ul li.active .down .shadow {
  background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0.1) 100%);
  animation: flipclock-hide 200ms 200ms linear both;
}
.flip-clock.flip-clock--label-light.play ul li.before .down .shadow,
.flip-clock.flip-clock--label-light.play ul li.active .down .shadow {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
}
@keyframes flipclock-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flipclock-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.flip-clock.flip-clock--static.play ul li.active .up,
.flip-clock.flip-clock--static.play ul li.before .up,
.flip-clock.flip-clock--static.play ul li.active .up .shadow,
.flip-clock.flip-clock--static.play ul li.before .up .shadow,
.flip-clock.flip-clock--static.play ul li.active .down,
.flip-clock.flip-clock--static.play ul li.before .down,
.flip-clock.flip-clock--static.play ul li.active .down .shadow,
.flip-clock.flip-clock--static.play ul li.before .down .shadow {
  z-index: 1;
  animation: none;
}
