/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (min-width: 960px) {
  .region-vikinglotto .two-column-spot .column-wrapper.separated:nth-child(2n+1) {
    border-right: 0.1rem solid #0000ff;
  }
}
.region-vikinglotto .two-column-spot .column-wrapper.fill:nth-child(2n+0) {
  background-color: #000078;
}
.region-vikinglotto .two-column-spot .column-wrapper.fill:nth-child(2n+1) {
  background-color: #0000b4;
}
