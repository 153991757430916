/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.how-to-play-popup {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 100vw;
  transform: translate3d(0, 0, 0);
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
}
.how-to-play-popup.show {
  transform: translate3d(-100vw, 0, 0);
  opacity: 1;
}
.how-to-play-popup.show .close-button-wrapper {
  display: block;
}
.how-to-play-section .headline {
  max-width: 78vw;
  font-size: 10.7vw;
  color: #c50005;
  line-height: 9.4vw;
  text-align: center;
  margin: 18vw auto 4vw;
}
@media (min-width: 768px) {
  .how-to-play-section .headline {
    width: 41rem;
    font-size: 4rem;
    line-height: 4.5rem;
    margin: 10rem auto 3rem;
  }
}
.how-to-play-section .text {
  max-width: 80.4vw;
  font-size: 4vw;
  line-height: 5.1vw;
  color: #4f5254;
  text-align: center;
  margin: 0 auto 8vw;
}
@media (min-width: 768px) {
  .how-to-play-section .text {
    max-width: 55rem;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 2.5rem;
    margin: 0 auto 0;
  }
}
.how-to-play-section .video-headline {
  max-width: 86.7vw;
  font-size: 5.4vw;
  line-height: 5.6vw;
  text-align: center;
  margin: 5.5vw auto 0;
}
@media (min-width: 768px) {
  .how-to-play-section .video-headline {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 4.9rem auto 0;
  }
}
.how-to-play-section .close-button-wrapper {
  position: fixed;
  top: 4.6vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 86.7vw;
  height: 5rem;
  z-index: 999;
}
@media (min-width: 960px) {
  .how-to-play-section .close-button-wrapper {
    max-width: 96rem;
    top: 0;
    display: none;
  }
}
.how-to-play-section .btn.close {
  min-width: 4rem;
  height: 5rem;
}
@media (min-width: 960px) {
  .how-to-play-section .btn.close {
    min-width: 5rem;
    height: 4rem;
    top: 1.5rem;
    right: 1rem;
  }
}
.how-to-play-section .btn.close:before,
.how-to-play-section .btn.close:after {
  left: 47%;
  top: 18%;
  width: 1.2vw;
  height: 6.5vw;
  background-color: #4f5254;
  border-top: 0.1rem solid white;
  border-bottom: 0.1rem solid white;
}
@media (min-width: 960px) {
  .how-to-play-section .btn.close:before,
  .how-to-play-section .btn.close:after {
    width: 0.5rem;
    height: 2.5rem;
  }
}
.open .how-to-play-section .btn.close {
  opacity: 1;
}
@media (min-width: 960px) {
  .how-to-play-section .video-list {
    max-width: 96rem;
    margin: auto;
  }
}
@media (min-width: 960px) {
  .how-to-play-section .video-element {
    width: 50%;
    padding: 0 2.5rem;
    box-sizing: border-box;
    float: left;
  }
}
.how-to-play-section .video-element .video-box {
  position: relative;
  max-width: 86.7vw;
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 format */
  margin: 4.7vw auto;
}
@media (min-width: 960px) {
  .how-to-play-section .video-element .video-box {
    margin: 3rem auto;
  }
}
.how-to-play-section .video-element .video-box.video-playing .video-image,
.how-to-play-section .video-element .video-box.video-playing .video-overlay,
.how-to-play-section .video-element .video-box.video-playing .video-play-button {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8) translateZ(0);
}
.how-to-play-section .video-element .video-box.video-ready .play-button {
  display: block;
}
.how-to-play-section .video-element .video-box.video-ready .play-spinner {
  display: none;
}
.how-to-play-section .video-element .video-box iframe {
  width: 100%;
  height: 100%;
}
.how-to-play-section .video-element .video-image {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s 0.1s, visibility 0.3s 0.1s, transform 0.3s 0.1s;
}
@media (min-width: 960px) {
  .how-to-play-section .video-element .video-image {
    display: block;
  }
}
.how-to-play-section .video-element .video-frame {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.how-to-play-section .video-element .video-frame > div {
  display: none;
}
.how-to-play-section .video-element .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.how-to-play-section .video-element .video-play-button {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15.9vw;
  height: 15.9vw;
  background: #fff;
  border-radius: 50%;
  margin: -7.95vw 0 0 -7.95vw;
  cursor: pointer;
}
@media (min-width: 960px) {
  .how-to-play-section .video-element .video-play-button {
    display: block;
    width: 8rem;
    height: 8rem;
    margin: -4rem 0 0 -4rem;
  }
}
.how-to-play-section .video-element .video-play-button .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -2vw 0 0 -1.4vw;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2.25vw 0 2.25vw 4.5vw;
  border-color: transparent transparent transparent #c50005;
  display: none;
}
@media (min-width: 960px) {
  .how-to-play-section .video-element .video-play-button .play-button {
    margin: -1.2rem 0 0 -0.8rem;
    border-width: 1.2rem 0 1.2rem 2.4rem;
  }
}
.how-to-play-section .video-element .video-play-button .play-spinner {
  top: 50%;
  left: 50%;
  color: #000;
  font-size: 1.5vw;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: relative;
  animation: spinner 1.3s infinite linear;
  transform: translateZ(0);
  margin-left: -0.75em;
  margin-top: -0.75em;
}
@media (min-width: 960px) {
  .how-to-play-section .video-element .video-play-button .play-spinner {
    font-size: 0.7rem;
    margin-left: -0.9em;
    margin-top: -0.9em;
  }
}
.how-to-play-section .how-to-play-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 7rem;
  transition: transform 0.5s cubic-bezier(0.5, 0, 0.35, 1.25), opacity 0.5s ease-out;
  z-index: 10;
  overflow: auto;
  background-color: #fff;
  box-sizing: border-box;
}
.how-to-play-section .how-to-play-content {
  margin: 0 auto;
}
@media (min-width: 960px) {
  .how-to-play-section .how-to-play-content {
    max-width: 96rem;
  }
}
.how-to-play .how-to-play-section .how-to-play-content-wrapper {
  position: relative;
  overflow-x: hidden;
}
.open .top-navigation {
  display: none;
}
.mode-edit .how-to-play-popup .how-to-play-content-wrapper {
  opacity: 1;
  position: relative;
  overflow: hidden;
  height: auto;
}
.mode-edit .how-to-play-popup .how-to-play-content-wrapper div.scLooseFrameZone {
  overflow-y: unset;
}
.mode-edit.open.bleed,
.mode-edit.open.lock {
  padding: 0 !important;
  overflow: auto;
}
@keyframes spinner {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
