@import url("../../Mixins/_mixins.less");

.region-vikinglotto {
  .news-list {

    .section {
      min-height: 355px;
      // News list-overview
      &.manchete.top {
        padding-top: 5rem;
      }

      .news-heading {
        padding: 10vw 0;
        background-color: @vikinglotto-darkblue;

        @media (min-width: @desktop-width) {
          min-height: 38rem;
          padding: 5rem 0;
        }

        .topspot-headline {
          text-transform: uppercase;
          margin: 8vw auto 5vw;

          @media (min-width: @desktop-width) {
            margin: 4rem auto 3rem;
          }
        }

        .topspot-subheadline {
          margin-top: 2vw;

          @media (min-width: @desktop-width) {
            margin-top: 2rem;
          }
        }
      }

      .content-wrapper {

        .news-block {

          .date {
            color: @vikinglotto-lightgray;
          }

          a.heading-link {
            .headline {
              font-weight: 900;
              font-style: italic;
              text-transform: uppercase;
              color: @vikinglotto-darkblue;
            }
          }
        }
      }
      // News single item view
      .content-wrapper {

        .news-block.single {

          .news-item-headline {
            text-transform: uppercase;
            color: @vikinglotto-darkblue;

            @media (min-width: @desktop-width) {
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}
