@import url("../../Mixins/_mixins.less");

.region-lotto {
  .two-column-spot {

    .column-wrapper {

      &.separated {
        border-bottom: .1rem solid @lotto-maroonred;

        @media (min-width: @desktop-width) {
          border-bottom: none;
        }

        &:nth-child(2n+1) {
          @media (min-width: @desktop-width) {
            border-bottom: none;
            border-right: .1rem solid @lotto-maroonred;
          }
        }
      }

      &.fill {
        &:nth-child(2n+0) {
          background-color: @lotto-red;
        }

        &:nth-child(2n+1) {
          background-color: @lotto-red;
        }
      }

      .column-spots {
        &.justify {
          @media (min-width: @desktop-width) {
            width: 48rem;
          }
        }
      }
    }
  }
}
