@import (reference) "../../../../Mixins/_mixins.less";

.multi-client__flow-step-confirm {
  .multi-client__flow-step-confirm-wrapper {
    width: 100%;
    .confirm-page__content {
      width: 100%;
      max-width: 54rem;
      padding: 0 1rem;
      margin:0 auto 5.5rem;
      box-sizing: border-box;
      background-color: transparent;
    }

    .confirm-page__total-label {
      flex: 1;
    }

    .confirm-page__collapsible-icon-campaign {
      width: 3.2rem;
      height: 3.2rem;
    }

    .confirm-page__extra-campaigns {
      width: ~"calc(100% - 4rem)";
      max-width: 49rem;
    
      @media @lg {
        max-width: 54rem;
      }

      .confirm-page__collapsible {
        max-width: 100%;
        width: 100%;

        .confirm-page__collapsible-top-row-text {
          text-align: right;
          color: @ds-red;
          flex: 0;
        }

        .confirm-page__collapsible-top-row-highlight-text {
          flex: 1;
        }

        .confirm-page__collapsible-top-row-read-more {
          font-size: 1.2rem;
          line-height: 2rem;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .confirm-page__extra-campaigns-headline {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 700;
      padding-top: 2.2rem;
      text-transform: uppercase;
    }
  }

  // Eurojackpot theming
  .multi-client-wrapper__eurojackpot & {
    .confirm-page__collapsible-top-row-icon {
      color: @eurojackpot-gold;
    }
  }

  
  // VikingLotto theming
  .multi-client-wrapper__vikinglotto & {
    .confirm-page__collapsible-top-row-icon {
      color: @vikinglotto-blue;
    }
  }

  // Lotto theming
  .multi-client-wrapper__lotto & {
    .confirm-page__collapsible-top-row-icon {
      color: @lotto-red;
    }
  }

  // SummerLotto theming
  .multi-client-wrapper__campaign-Sommerlotto & {
    .confirm-page__collapsible-top-row-icon {
      color: @summer-lotto-orange;
    }

    .confirm-page__collapsible-top-row-highlight-text {
      color: @summer-lotto-orange;
    }
  }
}
