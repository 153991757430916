/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.number-games-print-wrapper {
  display: none !important;
  visibility: hidden !important;
}
@media print {
  .lotto-system-game,
  .header-static-fix,
  .top-navigation,
  header,
  .notifications-static-fix,
  .global-footer,
  .lotto-footer-navigation,
  .eurojackpot-system-game,
  .eurojackpot-footer-navigation,
  .vikinglotto-system-game,
  .vikinglotto-footer-navigation,
  .js-boostAi {
    visibility: hidden;
    display: none !important;
  }
  .number-games-print-wrapper {
    min-height: calc(90vh);
    display: block !important;
    visibility: visible !important;
    padding: 5mm 0;
  }
  .region-eurojackpot .number-games-print-wrapper {
    background-color: #FFFFFF;
  }
  .number-games-print-wrapper .print-wrapper-header {
    text-align: center;
    padding-bottom: 7mm;
  }
  .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon {
    line-height: 1;
  }
  .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon .icon {
    width: 40mm;
    height: 7mm;
  }
  .region-eurojackpot .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon .icon {
    width: 54mm;
    height: 7.2mm;
  }
  .region-vikinglotto .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon .icon {
    width: 51mm;
    height: 9mm;
    position: relative;
    top: 2mm;
  }
  .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon .text {
    font-size: 10mm;
    font-weight: 600;
    color: #c50005;
    text-transform: uppercase;
  }
  .region-eurojackpot .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon .text {
    color: #171717;
  }
  .region-vikinglotto .number-games-print-wrapper .print-wrapper-header .print-wrapper-header-icon .text {
    color: #0000ff;
  }
  .number-games-print-wrapper .print-wrapper-subheader .row {
    font-size: 3.5mm;
    font-weight: 500;
    color: #4f5254;
  }
  .number-games-print-wrapper .print-wrapper-subheader .row .numbers {
    font-weight: bold;
    color: #c50005;
    word-spacing: 3mm;
    padding-left: 3mm;
  }
  .region-eurojackpot .number-games-print-wrapper .print-wrapper-subheader .row .numbers {
    color: #171717;
  }
  .region-vikinglotto .number-games-print-wrapper .print-wrapper-subheader .row .numbers {
    color: #0000ff;
  }
  .number-games-print-wrapper .print-wrapper-subheader .row .description-text {
    font-size: 3mm;
    font-weight: normal;
    color: #a3a6ab;
  }
  .number-games-print-wrapper .print-wrapper-body {
    padding-bottom: 40mm;
  }
  .number-games-print-wrapper .print-wrapper-body .hidden {
    opacity: 0;
  }
  .number-games-print-wrapper .print-wrapper-footer {
    width: 100%;
    position: absolute;
    bottom: 0mm;
    page-break-inside: avoid;
  }
  .number-games-print-wrapper .print-wrapper-footer .footer-notes {
    line-height: 0;
    padding-bottom: 3mm;
  }
  .number-games-print-wrapper .print-wrapper-footer .footer-notes p {
    font-size: 3mm;
    font-weight: normal;
    font-style: italic;
    color: #4f5254;
  }
  .number-games-print-wrapper .print-wrapper-footer .footer-bar {
    font-size: 0;
    line-height: 0;
    border-radius: 1.5mm;
    padding: 4mm 5mm 3.8mm;
    background-color: #c50005;
    -webkit-print-color-adjust: exact;
    box-sizing: border-box;
    page-break-inside: avoid;
  }
  .region-eurojackpot .number-games-print-wrapper .print-wrapper-footer .footer-bar {
    background: #bb7f36;
    background: -moz-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
    background: -webkit-linear-gradient(left, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
    background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb7f36', endColorstr='#bb7f36', GradientType=1);
  }
  .region-vikinglotto .number-games-print-wrapper .print-wrapper-footer .footer-bar {
    background-color: #0000ff;
  }
  .number-games-print-wrapper .print-wrapper-footer .footer-bar .icon-cube,
  .number-games-print-wrapper .print-wrapper-footer .footer-bar .icon-logo {
    height: 6mm;
  }
  .number-games-print-wrapper .print-wrapper-footer .footer-bar .icon-cube {
    width: 6mm;
  }
  .number-games-print-wrapper .print-wrapper-footer .footer-bar .icon-logo {
    width: 27mm;
    float: right;
  }
  .region-eurojackpot .number-games-print-wrapper .print-wrapper-footer .footer-bar .icon-logo {
    width: 35mm;
  }
}
