﻿@import url("../../../Styles/Mixins/_mixins.less");

.numbers-rotary-selector {
  font-size: 1vw;
  margin: auto;
  overflow: hidden;
  user-select: none;

  @media @sm {
    // this is the root font-size for the rotary selector as it uses em
    // in this case we need to use px for consistency across devices
    font-size: 5.5px;
  }

  .selector-container {
    height: 41em;
    max-width: 140em;
    position: relative;
    padding: 6em 0;
    perspective: 150em;
    margin: auto;
    box-sizing: content-box;
  }

  .selector-numbers {
    width: 100vw;
    height: 0;
    padding-bottom: 150vw;
    position: absolute;
    transform-origin: center center;
    transform-style: preserve-3d;
    transition: transform .5s cubic-bezier(0.34, 1.61, 0.7, 1);
    transform: rotateX(-25deg) rotateZ(0deg) translateZ(-45em);
    top: 83vw;

    @media @sm {
      width: 140em;
      padding-bottom: 140em;
      top: 75em;
    }

    .number-wrapper {
      width: 36.366em;
      height: 100%;
      position: absolute;
      left: 50%;
      top: -50%;
      margin-left: -18.183em;
      text-align: center;
      transform-origin: center bottom;
      transform-style: preserve-3d;
      pointer-events: none;

      /* Define two variables as the loop limits */
      @from: 0;
      @to: 20;

      /* Create a Parametric mixin and add a guard operation */
      .loop(@index) when (@index =< @to) {
        /* As the mixin is called CSS is outputted */
        &:nth-child(@{index}n+0) {
          transform: rotate(unit((@index - 1) * 8, deg)) translateZ(0);
        }
        /* Interation call and operation */
        .loop(@index + 1);
      }
      /* the mixin is called, css outputted and iterations called */
      .loop(@from);

      .number {
        font-size: 22em;
        font-weight: 800;
        position: relative;
        top: 0em;
        transform: scale(.6) translateZ(0) rotateX(0deg) translateY(0em);
        opacity: 0;
        transition: transform .3s, opacity .25s;
        line-height: 1.4; /* Fix for Signa font */
        pointer-events: none;
      }

      &.in-view {
        .number {
          cursor: pointer;
          transform: scale(.7) translateY(-0em) translateZ(0) rotateX(0deg);
          opacity: .85;
          z-index: 1;
          pointer-events: auto;
        }
      }

      @scaleHoverOffset: 0.05;
      @xHoverOffset: -5;

      @scale-1: 0.7;
      @translateY-1: 5%;
      @translateX-1: 75%;
      @rotation-1: 4deg;
      @opacity-1: .5;
      @mobileScale-1: 0.6;
      @mobileTranslateY-1: -10%;
      @mobileTranslateX-1: 45%;
      @mobileRotation-1: 3deg;
      &.in-view[data-id='plus1']{
        .number {
          transform: scale(@mobileScale-1) translateY(@mobileTranslateY-1) translateX(@mobileTranslateX-1) rotate(-@mobileRotation-1);
          opacity: @opacity-1;
          @media @sm {
            transform: scale(@scale-1) translateY(@translateY-1) translateX(@translateX-1) rotate(-@rotation-1);
          }

          &:hover {
            @media @sm {
              transform: scale((@scale-1 + @scaleHoverOffset)) translateY(@translateY-1) translateX((@translateX-1 + @xHoverOffset)) rotate(-@rotation-1);
            }
          }
        }
      }
      &.in-view[data-id='minus1'] {
        .number {
          transform: scale(@scale-1) translateY(@mobileTranslateY-1) translateX(-@mobileTranslateX-1) rotate(@mobileRotation-1);
          opacity: @opacity-1;
          @media @sm {
            transform: scale(@scale-1) translateY(@translateY-1) translateX(-@translateX-1) rotate(@rotation-1);
          }

          &:hover {
            @media @sm {
              transform: scale((@scale-1 + @scaleHoverOffset)) translateY(@translateY-1) translateX((-@translateX-1 - @xHoverOffset)) rotate(@rotation-1);
            }
          }
        }
      }

      @scale-2: 0.55;
      @translateY-2: -20%;
      @translateX-2: 120%;
      @rotation-2: 8deg;
      @opacity-2: .3;
      @mobileScale-2: 0.45;
      @mobileTranslateY-2: -30%;
      @mobileTranslateX-2: 60%;
      @mobileRotation-2: 4deg;
      &.in-view[data-id='plus2']{
        .number {
          transform: scale(@mobileScale-2) translateY(@mobileTranslateY-2) translateX(@mobileTranslateX-2) rotate(-@mobileRotation-2);
          opacity: @opacity-2;
          @media @sm {
            transform: scale(@scale-2) translateY(@translateY-2) translateX(@translateX-2) rotate(-@rotation-2);
          }

          &:hover {
            @media @sm {
              transform: scale((@scale-2 + @scaleHoverOffset)) translateY(@translateY-2) translateX((@translateX-2 + (@xHoverOffset * 2))) rotate(-@rotation-2);
            }
          }
        }
      }
      &.in-view[data-id='minus2'] {
        .number {
          transform: scale(@mobileScale-2) translateY(@mobileTranslateY-2) translateX(-@mobileTranslateX-2) rotate(@mobileRotation-2);
          opacity: @opacity-2;
          @media @sm {
            transform: scale(@scale-2) translateY(@translateY-2) translateX(-@translateX-2) rotate(@rotation-2);
          }

          &:hover {
            @media @sm {
              transform: scale((@scale-2 + @scaleHoverOffset)) translateY(@translateY-2) translateX((-@translateX-2 - (@xHoverOffset * 2))) rotate(@rotation-2);
            }
          }
        }
      }

      @scale-3: 0.45;
      @translateY-3: -65%;
      @translateX-3: 150%;
      @rotation-3: 10deg;
      @opacity-3: .1;
      &.in-view[data-id='plus3']{
        .number {
          transform: scale(@scale-3) translateY(@translateY-3) translateX(@translateX-3) rotate(-@rotation-3);
          opacity: @opacity-3;

          &:hover {
            transform: scale((@scale-3 + @scaleHoverOffset)) translateY(@translateY-3 - @xHoverOffset) translateX((@translateX-3 + (@xHoverOffset * 3))) rotate(-@rotation-3);
          }
        }
      }
      &.in-view[data-id='minus3'] {
        .number {
          transform: scale(@scale-3) translateY(@translateY-3) translateX(-@translateX-3) rotate(@rotation-3);
          opacity: @opacity-3;

          &:hover {
            transform: scale((@scale-3 + @scaleHoverOffset)) translateY(@translateY-3 - @xHoverOffset) translateX((-@translateX-3 - (@xHoverOffset * 3))) rotate(@rotation-3);
          }
        }
      }

      @scale-4: 0.35;
      @translateY-4: -135%;
      @translateX-4: -160%;
      @rotation-4: 12deg;
      @opacity-4: .05;
      &.in-view[data-id='plus4']{
        .number {
          transform: scale(@scale-4) translateY(@translateY-4) translateX(-@translateX-4) rotate(-@rotation-4);
          opacity: @opacity-4;

          &:hover {
            transform: scale((@scale-4 + @scaleHoverOffset)) translateY(@translateY-4 - @xHoverOffset) translateX((-@translateX-4 + (@xHoverOffset * 3))) rotate(-@rotation-4);
          }
        }
      }
      &.in-view[data-id='minus4'] {
        .number {
          transform: scale(@scale-4) translateY(@translateY-4) translateX(@translateX-4) rotate(@rotation-4);
          opacity: @opacity-4;

          &:hover {
            transform: scale((@scale-4 + @scaleHoverOffset)) translateY(@translateY-4 - @xHoverOffset) translateX((@translateX-4 - (@xHoverOffset * 3))) rotate(@rotation-4);
          }
        }
      }

      &.in-focus {
        .number {
          transform: scale(.9);
          opacity: .95;
        }
      }
    }
  }

  .selector-foreground,
  .selector-background {
    width: 36.366em;
    height: 36.366em;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 7.4em;
  }

  .selector-foreground {
    .navigation-button {
      width: 3em;
      height: 6em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: default;
      transition: transform .15s linear, opacity .1s linear;
      opacity: 0;
      display: none;
      @media @sm {
        display: block;
      }

      .icon {
        width: 100%;
        height: 100%;
      }

      &.active {
        opacity: 1;
        cursor: pointer;
      }

      &.next {
        right: -6.5em;

        &:hover {
          transform: translateY(-50%) translateX(20%);
        }
      }

      &.previous {
        left: -6.5em;

        &:hover {
          transform: translateY(-50%) translateX(-20%);
        }
      }
    }

    .label {
      font-size: 3.6em;
      font-weight: 800;
      color: @ds-white;
      text-align: center;
      margin: auto;
      position: absolute;
      top: 7.8em;
      left: 0;
      right: 0;
    }
  }

  .selector-background {
    background-color: @ds-black;
  }

  .selected-number-wrapper {
    width: 36.366em;
    height: 36.366em;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
    overflow: hidden;
    .selector-numbers {
      left: -32.3vw;
      top: 75vw;
      @media @sm {
        left: -52em;
        top: 69em;
      }
    }
    .number-wrapper {
      @scale-2-1: 1;
      @translateY-2-1: 3%;
      @translateX-2-1: 60%;
      @mobileScale-2-1: 0.9;
      @mobileTranslateY-2-1: -2%;
      @mobileTranslateX-2-1: 43%;
      @rotation-2-1: 4deg;
      @opacity-2-1: .9;

      .number {
        color: @ds-white;
      }

      &.in-view[data-id='plus1']{
        .number {
          transform: scale(@mobileScale-2-1) translateY(@mobileTranslateY-2-1) translateX(@mobileTranslateX-2-1) rotate(-@rotation-2-1);
          opacity: @opacity-2-1;
          @media @sm {
            transform: scale(@scale-2-1) translateY(@translateY-2-1) translateX(@translateX-2-1) rotate(-@rotation-2-1);
          }
        }
      }
      &.in-view[data-id='minus1'] {
        .number {
          transform: scale(@mobileScale-2-1) translateY(@mobileTranslateY-2-1) translateX(-@mobileTranslateX-2-1) rotate(@rotation-2-1);
          opacity: @opacity-2-1;
          @media @sm {
            transform: scale(@scale-2-1) translateY(@translateY-2-1) translateX(-@translateX-2-1) rotate(@rotation-2-1);
          }
        }
      }

      &.in-focus {
        .number {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  // Eurojackpot theming
  &.eurojackpot {
    .selector-numbers {
      .number {
        color: @eurojackpot-gold-medium;
      }
    }

    .selector-foreground {
      box-shadow: inset 0px 0px 5em fade(@eurojackpot-gold-medium, 90%);

      .navigation-button {
        .icon {
          color: @eurojackpot-gold-medium;
        }
      }

      .multi-client__client-game-description {
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        transform: translateY(100%);
        margin-bottom: 0;
        padding-top: 1rem;

        .client-game-description__question-button {
          width: 4rem;
          height: 4rem;
        }
      }
    }

    .selector-background {
      background-color: @eurojackpot-gold-medium;
    }

    .selected-number-wrapper {
      .number {
        color: @ds-white;
      }
    }
  }

  // Vikinglotto theming
  &.vikinglotto {
    .selector-numbers {
      .number {
        color: @vikinglotto-blue;
      }
    }

    .selector-foreground {
      box-shadow: inset 0px 0px 5em fade(@vikinglotto-blue, 90%);

      .navigation-button {
        .icon {
          color: @vikinglotto-blue;
        }
      }
    }

    .selector-background {
      background-color: @vikinglotto-blue;
    }

    .selected-number-wrapper {
      .number {
        color: @ds-white;
      }
    }
  }

  // Lotto theming
  &.lotto {
    .selector-numbers {
      .number {
        color: @lotto-red;
      }
    }

    .selector-foreground {
      box-shadow: inset 0px 0px 5em fade(@lotto-red, 90%);

      .navigation-button {
        .icon {
          color: @lotto-red;
        }
      }
    }

    .selector-background {
      background-color: @lotto-red;
    }

    .selected-number-wrapper {
      .number {
        color: @ds-white;
      }
    }
  }

  // AllOrNothing theming
  &.allornothing {
    font-family: MergePro, "Open Sans", Helvetica, Arial, sans-serif;

    .selector-numbers {
      .number {
        color: @ds-white;
      }
    }

    .selector-foreground {
      box-shadow: inset 0px 0px 5em fade(@all-or-nothing-dark-purple, 90%);

      .navigation-button {
        .icon {
          color: @ds-white;
        }
      }
    }

    .selector-background {
      background-color: @all-or-nothing-dark-purple;
    }

    .selected-number-wrapper {
      .number {
        color: @ds-white;
      }
    }
  }

  // Keno theming
  &.keno {
    .selector-numbers {
      .number {
        color: @keno-powderblue;
      }
    }

    .selector-foreground {
      box-shadow: inset 0px 0px 5em fade(@keno-powderblue, 90%);

      .navigation-button {
        .icon {
          color: @keno-powderblue;
        }
      }
    }

    .selector-background {
      background-color: @keno-powderblue;
    }

    .selected-number-wrapper {
      .number {
        color: @ds-white;
      }
    }
  }
}
