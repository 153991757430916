@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    font-size: calc(1em * 0.625);
  }
}
.region-eurojackpot {
  background-color: #000;
  width: 100%;
  font-size: 4vw;
}
@media (min-width: 950px) {
  .region-eurojackpot {
    font-size: 1.4rem;
  }
}
.region-eurojackpot .container {
  width: 100vw;
}
@media (min-width: 950px) {
  .region-eurojackpot .container {
    width: 100%;
  }
}
.region-eurojackpot .page-box .container {
  width: 100%;
}
.eurojackpot-section {
  position: relative;
  font-style: normal;
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.eurojackpot-section.bg-white {
  background-color: #fff;
  border-color: #fff;
}
.eurojackpot-section.bg-gray {
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}
.eurojackpot-section.bg-deepgold {
  background-color: #3b372a;
  border-color: #3b372a;
}
.eurojackpot-section.bg-lightdeepgold {
  background-color: #47412f;
  border-color: #47412f;
}
.eurojackpot-section > .eurojackpot-content-wrapper {
  margin: auto;
  text-align: center;
}
.eurojackpot-section > .eurojackpot-content-wrapper .a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}
.eurojackpot-section > .eurojackpot-content-wrapper .a.hd {
  text-decoration: none;
}
.eurojackpot-section > .eurojackpot-content-wrapper .p {
  width: 100%;
  max-width: 86.5vw;
  margin: auto;
  color: #ffffff;
  font-size: 4vw;
  line-height: normal;
}
@media (min-width: 950px) {
  .eurojackpot-section > .eurojackpot-content-wrapper .p {
    font-size: 1.5rem;
    width: 950px;
  }
}
.eurojackpot-section > .eurojackpot-content-wrapper .p.dark {
  color: #111111;
}
.eurojackpot-section > .eurojackpot-content-wrapper .p.dark .a {
  color: inherit;
  border-bottom: 0.1rem solid #111111;
}
.eurojackpot-section > .eurojackpot-content-wrapper .hr {
  width: 86.5vw;
  border-width: .1rem;
  border-style: solid;
  border-top: 0;
  margin: 6vw auto;
}
.eurojackpot-section > .eurojackpot-content-wrapper .hr.gold {
  border-color: #E8A332;
}
@media (min-width: 950px) {
  .eurojackpot-section > .eurojackpot-content-wrapper .hr {
    margin: 2.5rem auto;
    max-width: 950px;
  }
}
.mobile-only {
  display: inherit;
}
@media (min-width: 950px) {
  .mobile-only {
    display: none;
  }
}
.mobile-hide {
  display: none;
}
@media (min-width: 950px) {
  .mobile-hide {
    display: inherit;
  }
}
.box-wide {
  width: 100%;
}
@media (min-width: 950px) {
  .box-wide {
    width: 96rem;
    margin: auto;
  }
}
.box-medium {
  width: 100%;
}
@media (min-width: 950px) {
  .box-medium {
    width: 58rem;
    margin: auto;
  }
}
.box-half {
  width: 100%;
}
@media (min-width: 950px) {
  .box-half {
    width: 50%;
    margin: auto;
    float: left;
  }
}
@media (min-width: 950px) {
  .side-by-side .eurojackpot-section {
    width: 50%;
    display: inline-block;
  }
  .side-by-side .eurojackpot-section:first-child {
    text-align: right;
  }
  .side-by-side .eurojackpot-section > .eurojackpot-content-wrapper {
    width: 100%;
    max-width: 48rem;
    display: inline-block;
  }
}
