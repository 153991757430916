/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.coupon {
  width: 31rem;
  font-size: 1.3rem;
  padding: 0.4rem 0.7rem 0;
  text-align: center;
  margin: 0 auto;
  position: relative;
}
.coupon .coupon__printer {
  width: 100%;
  height: 0.9rem;
  background-color: #92969E;
  border-radius: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
}
.coupon .coupon__printer::before {
  content: '';
  height: 0.5rem;
  background: linear-gradient(0deg, rgba(146, 150, 158, 0) 0%, #92969e 95%, #92969e 100%);
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  right: 0.4rem;
  z-index: 1;
}
.coupon.loading .loading-content {
  animation: pulsate 2s infinite;
}
.coupon p {
  line-height: 2rem;
  padding: 0 2rem;
}
.coupon .play-type {
  text-transform: uppercase;
  margin: 0.5rem 0 0;
}
.coupon .campaign__tickets-counter {
  margin: 1rem;
}
.coupon p.rebuy a,
.coupon .playtogether-winning-section a {
  background: #feb700;
  border: solid 0.05rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: #000000;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 4rem;
  padding: 1.2rem;
  text-decoration: none;
}
.coupon p.rebuy a:visited,
.coupon .playtogether-winning-section a:visited {
  color: #000000;
}
.coupon p.rebuy.rebuy--inactive a {
  cursor: not-allowed;
  background: #a3a6ab;
  color: #f1f2f2;
}
.coupon p.rebuy.rebuy--inactive .rebuy-inactive-notice {
  display: block;
  margin-top: 1.2rem;
  color: #c50005;
}
.coupon .coupon-paper .playtogether-winning-section .total {
  margin-bottom: 2rem;
}
.coupon .coupon-paper {
  margin: 0 auto;
  padding: 2.4rem 0 1.2rem;
  background-color: #FFFFFF;
  background-repeat: repeat;
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/coupon-background.jpg");
  border-radius: 0 0 2.4rem 2.4rem;
  position: relative;
  transition: max-height 0.15s linear;
  overflow: hidden;
  box-sizing: content-box;
}
.coupon .coupon-paper > div {
  overflow: hidden;
}
@keyframes coupon-paper-content-fade-in {
  to {
    opacity: 1;
  }
}
.coupon .coupon-paper .coupon-paper__content {
  opacity: 0;
  animation: coupon-paper-content-fade-in 100ms 50ms linear forwards;
}
.coupon .coupon-paper .play-type {
  font-weight: bold;
}
.coupon .coupon-paper .multi-wager-count {
  text-transform: uppercase;
  margin: 0;
}
.coupon .coupon-paper .transaction-date {
  margin-bottom: 0;
}
.coupon .coupon-paper .coupon-id {
  font-size: 1rem;
  margin-top: 0;
}
.coupon .coupon-paper .chosen-system-numbers-heading {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}
.coupon .coupon-paper .chosen-system-numbers .chosen-numbers {
  background-color: rgba(195, 8, 21, 0.08);
  padding: 1.5rem 0 0.9rem;
}
.coupon .coupon-paper .chosen-system-numbers .chosen-numbers .number-table {
  background-color: transparent;
  max-width: 80%;
  margin: auto;
}
.coupon .coupon-paper .chosen-system-numbers .chosen-numbers .number-table tr:nth-child(odd) {
  background-color: transparent;
}
.coupon .coupon-paper .chosen-system-numbers .chosen-numbers .number-table td {
  width: 2.7rem;
  padding: 0 0 0.3rem;
  display: inline-block;
}
.coupon .coupon-paper .chosen-system-numbers .chosen-numbers .number-table td.additional {
  color: #c50005;
}
.coupon .coupon-paper .chosen-system-numbers .chosen-numbers .number-table td.correct {
  font-weight: bold;
}
.coupon .coupon-paper .chosen-system-numbers .number-rows {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.8s;
}
.coupon .coupon-paper .chosen-system-numbers .number-rows tr:nth-child(odd) {
  background-color: rgba(195, 8, 21, 0.08);
}
.coupon .coupon-paper .chosen-system-numbers .toggle-row {
  padding: 1.5rem 0;
}
.coupon .coupon-paper .chosen-system-numbers .toggle-row .toggle-link {
  font-size: 1.1rem;
  color: #0000ff;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
.coupon .coupon-paper .chosen-system-numbers .toggle-row .toggle-link.open {
  display: block;
}
.coupon .coupon-paper .chosen-system-numbers .toggle-row .toggle-link.close {
  display: none;
}
.coupon .coupon-paper .chosen-system-numbers.active .toggle-link.open {
  display: none;
}
.coupon .coupon-paper .chosen-system-numbers.active .toggle-link.close {
  display: block;
}
.coupon .coupon-paper .number-tables__wrapper .number-table__wrapper:nth-child(odd) {
  background-color: rgba(195, 8, 21, 0.08);
}
.coupon .coupon-paper .number-tables__wrapper .number-table__wrapper .number-table tr {
  background-color: transparent !important;
}
.coupon .coupon-paper .number-table {
  table-layout: fixed;
  width: 100%;
}
.coupon .coupon-paper .number-table tr:nth-child(odd) {
  background-color: rgba(195, 8, 21, 0.08);
}
.coupon .coupon-paper .number-table tr td {
  padding: .4rem 0 .5rem 0;
  width: 2.2rem;
}
.coupon .coupon-paper .number-table tr td.row-number {
  font-weight: bold;
  width: 4.4rem;
  padding-right: 1.2rem;
  text-align: right;
}
.coupon .coupon-paper .number-table tr td.divider {
  width: 1.6rem;
}
.coupon .coupon-paper .number-table tr td.additional {
  color: #c50005;
}
.coupon .coupon-paper .number-table tr td.correct {
  font-weight: bold;
}
.coupon .coupon-paper .number-table tr td.won-indicator {
  position: relative;
  padding-left: 0.5rem;
}
.coupon .coupon-paper .number-table tr td.won-indicator svg {
  display: none;
}
.coupon .coupon-paper .number-table tr.row-won td.won-indicator svg {
  width: 1.1rem;
  height: 1.1rem;
  display: block;
  padding: 0;
  margin: 0;
}
.coupon .coupon-paper .multi-wager-list {
  margin: 0 2rem;
  border-top: 0.1rem solid #000000;
}
.coupon .coupon-paper .multi-wager-list table {
  width: 100%;
  margin: 1.2rem 0;
  text-align: right;
  text-transform: uppercase;
}
.coupon .coupon-paper .multi-wager-list table td {
  padding: .3rem .6rem;
  white-space: nowrap;
}
.coupon .coupon-paper .multi-wager-list table td:first-child {
  padding-left: 0;
}
.coupon .coupon-paper .multi-wager-list table td:last-child {
  padding-right: 0;
}
.coupon .coupon-paper .multi-wager-list table svg.joker-hat {
  width: 2rem;
  height: 1.2rem;
}
.coupon .coupon-paper .multi-wager-list table svg.campaign-ticket {
  width: 1.1rem;
  height: 1.6rem;
  position: relative;
  top: 0.2rem;
}
.coupon .coupon-paper .coupon-separator {
  height: 1.5rem;
  width: 100%;
  position: relative;
}
.coupon .coupon-paper .coupon-separator::Before,
.coupon .coupon-paper .coupon-separator::after {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  background-color: #eeeeee;
  border-radius: 50%;
  position: absolute;
  top: 0;
}
.coupon .coupon-paper .coupon-separator::before {
  right: 0;
  transform: translateX(50%);
}
.coupon .coupon-paper .coupon-separator::after {
  left: 0;
  transform: translateX(-50%);
}
.coupon .coupon-paper .coupon-separator .coupon-separator__line {
  width: 100%;
  height: 0.3rem;
  color: #eeeeee;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.coupon .coupon-paper .draw-week {
  font-size: 1rem;
  font-weight: bold;
}
.coupon .coupon-paper .draw-week .draw-date {
  display: block;
}
.coupon .coupon-paper .keno-draw-date-title {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
.coupon .coupon-paper .keno-draw-date {
  font-weight: normal;
  display: inline-block;
  margin: 0.5rem 0 0 0;
}
.coupon .coupon-paper .keno-draw-date:last-child {
  margin-right: 0;
}
.coupon .coupon-paper .keno-wager-title {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
.coupon .coupon-paper .keno-wager {
  font-weight: normal;
  display: inline-block;
  padding: 0.5rem 0;
}
.coupon .coupon-paper .keno-wager:last-child {
  margin-right: 0;
}
.coupon .coupon-paper .game-type {
  font-weight: bold;
  border-top: 0.1rem solid #000000;
  padding: 1.5rem 0 0 0;
  margin: 0 2rem;
}
.coupon .coupon-paper .jokernumbers {
  background-color: rgba(195, 8, 21, 0.08);
  letter-spacing: 0.5rem;
  padding: 0.8rem 2rem 0.8rem;
}
.coupon .coupon-paper .jokernumbers .line {
  position: relative;
}
.coupon .coupon-paper .jokernumbers .number {
  padding: 0 0.4rem;
}
.coupon .coupon-paper .jokernumbers .number.additional {
  color: #c50005;
}
.coupon .coupon-paper .jokernumbers .number.correct {
  font-weight: bold;
}
.coupon .coupon-paper .jokernumbers span.won-indicator {
  width: 1.1rem;
  height: 1.1rem;
  padding-left: 0.5rem;
  display: inline-block;
}
.coupon .coupon-paper .jokernumbers span.won-indicator svg {
  display: none;
}
.coupon .coupon-paper .jokernumbers .row-won span.won-indicator svg {
  width: 1.1rem;
  height: 1.1rem;
  display: block;
  padding: 0;
  margin: 0;
}
.coupon .coupon-paper.prizes-available .correct {
  font-weight: bold !important;
}
.coupon .coupon-paper.prizes-available .correct.additional {
  color: #c50005;
}
.coupon .coupon-paper .total {
  font-size: 1.6rem;
  line-height: 1.8rem;
  padding: 1.4rem 0 1.2rem 0;
  margin: 0 2rem;
  font-weight: bold;
  text-align: left;
  border-top: 0.1rem solid #000000;
  border-bottom: 0.1rem solid #000000;
}
.coupon .coupon-paper .total span {
  float: right;
}
.coupon .coupon-paper .company-name {
  font-size: 1rem;
  font-style: italic;
}
.coupon .coupon-paper .campaign-footer {
  padding: 1rem 2rem 0;
}
.coupon .coupon-paper .campaign-footer__logo {
  width: 100%;
  height: 7rem;
}
.coupon .coupon-paper .campaign-footer__logo.Sommerlotto {
  height: 25rem;
  margin: -3rem 0;
}
.coupon .coupon-paper .campaign-footer__text {
  margin-top: 1rem;
}
.coupon .coupon-paper .campaign-footer__tickets-count {
  display: none;
}
.coupon .coupon-paper .prizes .prizes-header {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 1.5rem 0 1rem;
  margin: 0 2rem;
}
.coupon .coupon-paper .prizes .prize-section {
  margin-bottom: 3rem;
}
.coupon .coupon-paper .prizes .prize-section-header {
  font-size: 1.3rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  margin: 1rem 0 0;
}
.coupon .coupon-paper .prizes .prize-section-date {
  font-size: 1rem;
  margin-top: 0;
}
.coupon .coupon-paper .prizes .prize-section-date span {
  text-transform: uppercase;
}
.coupon .coupon-paper .prizes .prizes-table {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 1rem;
}
.coupon .coupon-paper .prizes .prizes-table tr td,
.coupon .coupon-paper .prizes .prizes-table tr th {
  font-size: 1.3rem;
  padding: .4rem 0 .5rem 0;
  width: 2.9rem;
}
.coupon .coupon-paper .prizes .prizes-table tr th {
  text-transform: uppercase;
}
.coupon.game-AllOrNothing .coupon-paper .icon {
  width: 11rem;
  height: 2.5rem;
  fill: #3a3a3a;
}
.coupon.game-AllOrNothing .coupon-paper .keno-title-table {
  width: 100%;
}
.coupon.game-AllOrNothing .coupon-paper .keno-title-table .keno-row-title {
  width: 100%;
  padding-top: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}
.coupon.game-AllOrNothing .coupon-paper .number-table {
  table-layout: fixed;
  width: 100%;
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr:nth-child(odd) {
  background-color: rgba(195, 8, 21, 0.08);
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr td {
  width: 2.05rem;
  padding: 0 0 .5rem 0;
  display: inline-block;
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr td.won-indicator {
  width: 0;
  text-align: center;
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr td.won-indicator svg.svg {
  display: inline-block;
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr td.row-number {
  display: none;
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr td.divider {
  width: 1.6rem;
}
.coupon.game-AllOrNothing .coupon-paper .number-table tr td.hide {
  display: none;
}
.coupon.game-LottoSaturday .coupon-paper .icon {
  width: 10.5rem;
  height: 2.5rem;
}
.coupon.game-LottoSaturday .coupon-paper .chosen-numbers {
  background-color: #FBD2DA;
}
.coupon.game-LottoSaturday .coupon-paper .number-tables__wrapper .number-table__wrapper:nth-child(odd) {
  background-color: #FBD2DA;
}
.coupon.game-LottoSaturday .coupon-paper .jokernumbers {
  background-color: transparent;
  padding: 0;
}
.coupon.game-LottoSaturday .coupon-paper .jokernumbers .line {
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  background-color: #FBD2DA;
}
.coupon.game-LottoSaturday .campaign-footer--campaign-christmas .campaign-footer__logo,
.coupon.game-LottoSaturday .campaign-footer--JuleLotto2022 .campaign-footer__logo {
  height: 8rem;
  margin-top: 2rem;
}
.coupon.game-LottoSaturday .campaign-footer--campaign-christmas .campaign-footer__tickets-count,
.coupon.game-LottoSaturday .campaign-footer--JuleLotto2022 .campaign-footer__tickets-count {
  font-family: "OliveVillage";
  font-size: 5.6rem;
  line-height: 8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: -1rem;
}
.coupon.game-LottoSaturday .campaign-footer--campaign-christmas .campaign-footer__tickets-count::before,
.coupon.game-LottoSaturday .campaign-footer--JuleLotto2022 .campaign-footer__tickets-count::before,
.coupon.game-LottoSaturday .campaign-footer--campaign-christmas .campaign-footer__tickets-count::after,
.coupon.game-LottoSaturday .campaign-footer--JuleLotto2022 .campaign-footer__tickets-count::after {
  content: "";
  width: 3.6rem;
  height: 1.2rem;
  background-image: url("/Components/DanskeSpil/Domain/Lotto/Graphics/Campaigns/Christmas/christmas-tickets-counter-vector.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-top: -1rem;
}
.coupon.game-LottoSaturday .campaign-footer--campaign-christmas .campaign-footer__tickets-count::before,
.coupon.game-LottoSaturday .campaign-footer--JuleLotto2022 .campaign-footer__tickets-count::before {
  transform: rotate(180deg);
}
.coupon.game-LottoSaturday .campaign-footer--campaign-christmas .campaign-footer__text,
.coupon.game-LottoSaturday .campaign-footer--JuleLotto2022 .campaign-footer__text {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 2rem;
}
.coupon .campaign-header-icon {
  max-width: calc(100% - 4rem);
}
.coupon.game-VikingLotto .coupon-paper .icon {
  width: 24rem;
  height: 4.7rem;
}
.coupon.game-VikingLotto .coupon-paper .chosen-numbers {
  background-color: #E5E6FD;
}
.coupon.game-VikingLotto .coupon-paper .number-tables__wrapper .number-table__wrapper:nth-child(odd) {
  background-color: #E5E6FD;
}
.coupon.game-VikingLotto .coupon-paper .jokernumbers {
  background-color: transparent;
  padding: 0;
}
.coupon.game-VikingLotto .coupon-paper .jokernumbers .line {
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  background-color: #E5E6FD;
}
.coupon.game-VikingLotto .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table {
  margin: 0 auto;
}
.coupon.game-VikingLotto .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container {
  width: 3.4rem;
  position: relative;
  display: inline-block;
  margin: 0.2rem;
}
.coupon.game-VikingLotto .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container .ekstra-number {
  width: 1.7rem;
  position: relative;
  z-index: 1;
  padding-top: 0.8rem;
}
.coupon.game-VikingLotto .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container .additional {
  color: red;
}
.coupon.game-VikingLotto .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container .correct {
  font-weight: bold;
}
.coupon.game-VikingLotto .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container svg {
  width: 3.2rem;
  height: 3rem;
  left: 0.1rem;
  top: 0.1rem;
  position: absolute;
  margin: 0;
  padding: 0;
}
.coupon.game-Eurojackpot .coupon-paper .icon {
  width: 17.8rem;
  height: 2.5rem;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table {
  margin: 0 auto;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container {
  width: 3.4rem;
  position: relative;
  display: inline-block;
  margin: -0.3rem 0.3rem;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container td {
  width: 1.7rem;
  position: relative;
  z-index: 1;
  padding-top: 1rem;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container td.additional {
  color: red;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container td.correct {
  font-weight: bold;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-system-numbers .chosen-numbers .ekstra-number-table .ekstra-number-container svg {
  width: 3.2rem;
  height: 3rem;
  left: 0.1rem;
  top: 0.2rem;
  position: absolute;
  margin: 0;
  padding: 0;
}
.coupon.game-Eurojackpot .coupon-paper .number-table tr td.row-number {
  width: 3.4rem;
}
.coupon.game-Eurojackpot .coupon-paper .chosen-numbers {
  background-color: #FAEDD6;
}
.coupon.game-Eurojackpot .coupon-paper .number-tables__wrapper .number-table__wrapper:nth-child(odd) {
  background-color: #FAEDD6;
}
.coupon.game-Eurojackpot .coupon-paper .jokernumbers {
  background-color: transparent;
  padding: 0;
}
.coupon.game-Eurojackpot .coupon-paper .jokernumbers .line {
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  background-color: #FAEDD6;
}
.coupon.game-Keno .coupon-paper .icon {
  width: 10.5rem;
  height: 2.5rem;
}
.coupon.game-Keno .coupon-paper .chosen-system-numbers-heading {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: normal;
  text-transform: uppercase;
}
.coupon.game-Keno .coupon-paper .keno-title-table {
  width: 100%;
}
.coupon.game-Keno .coupon-paper .keno-title-table .keno-row-title {
  width: 100%;
  padding-top: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}
.coupon.game-Keno .coupon-paper .number-table {
  table-layout: fixed;
  width: 100%;
}
.coupon.game-Keno .coupon-paper .number-table tr:nth-child(odd) {
  background-color: rgba(195, 8, 21, 0.08);
}
.coupon.game-Keno .coupon-paper .number-table tr td {
  width: 2.2rem;
  padding: 0 0 .5rem 0;
  display: inline-block;
}
.coupon.game-Keno .coupon-paper .number-table tr td.row-number {
  display: none;
}
.coupon.game-Keno .coupon-paper .number-table tr td.divider {
  width: 1.6rem;
}
.coupon .play-together-title {
  font-size: 2rem;
  font-weight: bold;
}
.coupon .play-together {
  text-align: left;
  line-height: 1.5;
  margin-bottom: 2rem;
}
.coupon .play-together-rows,
.coupon .play-together-amount {
  padding: 0 2rem;
}
.coupon .play-together-club-name {
  font-style: italic;
  font-weight: bold;
}
.coupon .play-together-label {
  font-weight: bold;
}
.coupon .campaign-footer--campaign-engine {
  line-height: 1;
}
.coupon .campaign-footer--campaign-engine .campaign-footer__logo {
  margin-bottom: 0.6rem;
}
.coupon .campaign-footer--campaign-engine .campaign-footer__tickets-count {
  display: block;
  font-size: 5rem;
  font-weight: 700;
}
.coupon .campaign-footer--campaign-engine .campaign-footer__tickets-count-label {
  font-size: 1.6rem;
  font-weight: 600;
}
@keyframes pulsate {
  50% {
    opacity: 0.5;
    transform: scale(0.97);
  }
}
.coupon-extra-campaign-draws {
  margin-bottom: 2.4rem;
  position: relative;
}
.coupon-extra-campaign-draws__header-graphics {
  position: absolute;
  top: -1.3rem;
  max-width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  overflow: visible;
}
.coupon-extra-campaign-draws__headline {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.coupon-extra-campaign-draws__table {
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  gap: 0.4rem;
}
.coupon-extra-campaign-draws__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.coupon-extra-campaign-draws__cell {
  padding: 0 0.3rem;
}
.coupon-extra-campaign-draws__cell--name {
  font-weight: 700;
}
