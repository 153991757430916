﻿@import "../_imports";
@import "../Mixins/mixins";

.tutorial-footer {
  background-color: var(--tutorial-primary);
  position: relative;
  padding: 0 2rem;
  border-top: solid 0.1rem @ds-white;

  &--disable {
    display: none;
  }
  
  @media @xl {
    width: 100%;
    margin: 0 auto;
    padding: 0 4rem;
  }

  .tutorial-footer-inner {
    max-width: 144rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    
    @media @sm {
      align-items: flex-start;
    }
    
    @media @lg {
      flex-direction: row;
    }

    .tutorial-footer__text-wrapper {
      width: 100%;
      
      @media @xl {
        flex-basis: 50%;
      }

      .tutorial-footer__title {
        padding-top: 2.9rem;
        font-weight: 800;
        font-family: @font-impact;
        font-size: 2.5rem;
        font-style: italic;
        line-height: 4rem;
        color: @ds-white;

        @media @sm {
          font-size: 2.3rem;
          line-height: 6rem;
        }
      }

      .tutorial-footer__text {
        font-size: 1.4rem;
        color: @ds-white;
        padding: 2rem 0;

        p {
          margin: 0;
        }
      }
    }

    .tutorial-footer__footerElement {
      width: 100%;

      @media @sm {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        justify-content: space-between;
        padding-right: 6rem;
      }

      @media @lg {
        
        justify-content: center;
        grid-column-gap: 3rem;
        padding-top: 6.6rem;
        padding-bottom: 5.7rem;
        width: auto;
      }

      .tutorial-footer__footerElement-wrapper {
        display: flex;
        padding-bottom: 3.3rem;
        align-items: center;

        @media @sm {
          flex-basis: 50%;
        }

        .tutorial-footer__footerElement-image-wrapper {
          width: 5.5rem;
          height: 5.5rem;
          background-color: @ds-white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2rem;
          box-shadow: 0 0.4rem 2rem rgba(0, 27, 48, 0.2);

          @media @sm {
            margin-right: 3rem;
          }

          .tutorial-footer__footerElement-image {
            width: 50%;
            height: 50%;
          }
        }

        .tutorial-footer__footerElement-text-wrapper {

          .tutorial-footer__footerElement-title {
            font-weight: bold;
            font-size: 2rem;
            color: @ds-white;
            padding-bottom: 0.2rem;
          }

          .tutorial-footer__footerElement-text {
            font-size: 1.4rem;
            color: @ds-white;
            font-weight: 400;
          }
        }
      }
    }
  }
}
