@import (reference) "../../Mixins/_mixins.less";
@import url("../../Mixins/_button.less");

.multi-client-wrapper__outer,
.multi-client-wrapper__outer-grid {
  background-color: @ds-white;
  border-radius: 3rem 3rem 0 0;
  overflow: clip;
  position: relative;
  transform: translateZ(0);

  .popover-drawer {
    height: 100%;
  }

  .popover-drawer__card {
    height: 100%;
  }
}

.multi-client-wrapper__outer {
  margin-top: -2.5rem;

  .ds-cube-loader-wrapper {
    height: 40rem;
    position: relative;
  }
}

.multi-client-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: @ds-white;
  overflow: clip;
  position: relative;
  font-size: 1.4rem;
  transition: background-color .15s linear;

  .multi-client-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 2rem;
    z-index: 1;
    pointer-events: none;

    @media @sm {
      padding: 0 5rem;
      top: 5rem;
    }
  }

  .multi-client__button-wrapper {
    width: 3.4rem;
    display: flex;
    pointer-events: auto;

    & > div {
      cursor: pointer;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  .multi-client__back-button {
    width: 2.3rem;
    height: 1.4rem;
  }

  .multi-client__question-button {
    width: 3.4rem;
    height: 3.4rem;
    transition: opacity 150ms linear;

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .multi-client-body {
    width: 100%;
    position: relative;
    z-index: 0;
  }

  .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests) {
    .btn();
    .btn-primary();
    .btn-height-default();
    .btn-width-default();
    .btn-with-x-padding();
    .btn-hover-scale();
    display: flex;
    padding: 0;
    margin-bottom: 3rem;
  }

  .multi-client-games-wrapper {
    background-color: @ds-white;
  }

  .multi-client-loader-wrapper {
    width: 100%;
    height: 40rem;
    position: relative;
    margin-top: 6rem;

    @keyframes multi-client-loader-overlay-fadeIn {
      to {
        opacity: 1;
      }
    }

    &.multi-client-loader-wrapper__as-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: fade(@ds-white, 80%);
      opacity: 0;
      animation: multi-client-loader-overlay-fadeIn .2s linear forwards;
      z-index: 2;

      .ds-cube-loader-wrapper {
        opacity: 0;
        animation: multi-client-loader-overlay-fadeIn .2s .15s linear forwards;
      }
    }
  }

  .multi-client__no-open-draw {
    text-align: center;
    padding: 10rem 0;
  }
}

.multi-client-wrapper__outer-grid {
  .outer-grid__row {
    display: flex;
    flex-direction: column-reverse;
    @media @sm {
      flex-direction: row;
    }
  }

  .outer-grid__column {
    display: flex;
    flex-direction: column;
  }

  .outer-grid--text-content {
    padding: 0 3rem 5rem;
    font-size: 12px;

    @media @sm {
      padding-top: 5rem;
    }

    p + p {
      margin-top: 0px;
    }
  }

  .outer-grid--multi-client {
    max-width: 100%;
    flex-shrink: 0;
    @media @sm {
      width: 62rem;
      .multi-client-rotary-selector {
        font-size: 2.8px;
      }
    }
  }

  .multi-client-wrapper__outer {
    border-radius: 0;
    margin-top: 0;
  }
}

.multi-client-wrapper__outer-grid-receipt {
  display: none;
  min-height: 30rem;
  flex-direction: column;
  align-items: center;

  .multi-client-wrapper__outer-grid--has-added-hoejpulje-to-basket & {
    display: flex;
  }
}

.multi-client-wrapper__outer-grid--has-added-hoejpulje-to-basket {
  padding: 6rem 2rem 0 !important;
  
  .outer-grid__row {
    display: none;
  }
}

.multi-client-wrapper__outer-grid-receipt-title {
  color: @ds-black;
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  line-height: 1.21;
  margin: 0 auto 2rem;
  text-align: center;
  padding: 0 2rem;
}

.multi-client-wrapper__outer-grid-receipt-note {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
  
  img {
    height: 14rem;
    margin-bottom: 2rem;
    max-width: 100%;
  }
}

.multi-client-wrapper--has-added-hoejpulje-to-basket {
  .check-button,
  .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests) {
    opacity: 0.5;
    pointer-events: none;
  }

  .multi-client__progress-bar,
  .multi-client__back-button {
    display: none;
  }
}