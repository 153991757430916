.arrow-icon {
  width: 3.467vw;
  height: 2.135vw;
  position: absolute;
  right: 4.5vw;
  -webkit-transition: opacity 100ms linear;
  -moz-transition: opacity 100ms linear;
  -ms-transition: opacity 100ms linear;
  -o-transition: opacity 100ms linear;
  transition: opacity 100ms linear;
}
.arrow-icon.hide {
  opacity: 0;
}
.arrow-icon svg {
  width: 3.467vw;
  height: 2.135vw;
}
@media (min-width: 950px) {
  .arrow-icon {
    width: 1.2rem;
    height: .7rem;
    top: 0;
    right: 2rem;
  }
  .arrow-icon svg {
    width: 1.2rem;
    height: .7rem;
  }
}
