@import (reference) "../../Mixins/_mixins.less";

@keyframes popover-drawer-fade-in-animation {
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@keyframes popover-drawer-fade-out-animation {
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes popover-drawer-card-slide-in-animation {
  100% {
    transform: translateY(0);
  }
}
@keyframes popover-drawer-card-slide-out-animation {
  100% {
    transform: translateY(100%);
  }
}

.popover-drawer {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  @popoverCardHeight: 80%;
  .popover-drawer__card {
    width: 100%;
    height: 90%;
    background-color: @ds-white;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 32px 32px 0 0;
    padding-top: 6rem;
    box-sizing: border-box;
    overflow: clip;
    animation-duration: 0.15s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    @media @sm {
      height: @popoverCardHeight;
    }
  }

  &.popover-drawer--2 {
    .popover-drawer__card {
      height: ~'calc(@{popoverCardHeight} - 4rem)';
    }
  }

  .popover-drawer__close-button {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -1rem);
    padding: 2.6rem 2.6rem 2.5rem;
    border: none;
    transition: transform 0.15s linear;
    line-height: 0;
    @media @sm {
      top: 1.2rem;
      left: auto;
      right: 2.4rem;
      transform: none;
      padding: 1.4rem;
    }

    &::before {
      content: '';
      background-color: @ds-gray-100;
      border-radius: 0 0 28px 28px;
      transition: transform 0.15s linear;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      @media @sm {
        border-radius: 50%;
      }
    }

    .icon-x {
      width: 1rem;
      height: 1rem;
      display: none;
      @media @sm {
        display: block;
      }
    }

    .icon-down-arrow {
      width: 1.6rem;
      height: 1rem;
      @media @sm {
        display: none;
      }
    }

    &:hover {
      @media @sm {
        &::before {
          transform: scale(1.1);
        }
      }
    }
  }

  .popover-drawer__card-content {
    height: 100%;
    overflow-y: auto;
  }

  &.popover-drawer--fade-in {
    animation-name: popover-drawer-fade-in-animation;

    .popover-drawer__card {
      animation-delay: 0.25s;
      animation-name: popover-drawer-card-slide-in-animation;
    }
  }

  &.popover-drawer--fade-out {
    animation-delay: 0.25s;
    animation-name: popover-drawer-fade-out-animation;

    .popover-drawer__card {
      animation-name: popover-drawer-card-slide-out-animation;
    }
  }

  &.popover-drawer--active {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;

    .popover-drawer__card {
      transform: translateY(0);
    }
  }
}