@import (reference) "Mixins/_mixins.less";

.arrow-icon {
  width: 3.467vw;
  height: 2.135vw;
  position: absolute;
  right: 4.5vw;
  .transition(opacity 100ms linear);
  &.hide {
    opacity: 0;
  }
  svg {
    width: 3.467vw;
    height: 2.135vw;
  }
  @media (min-width: @eurojackpot-desktop-width) {
    width: 1.2rem;
    height: .7rem;
    top: 0;
    right: 2rem;
    svg {
      width: 1.2rem;
      height: .7rem;
    }
  }
}