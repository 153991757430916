.eurojackpot-winning-numbers-spot .eurojackpot-section {
  position: relative;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper {
    max-width: 75%;
    margin: 0 auto;
  }
}
.eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section {
  background-color: #171717;
}
.eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo {
  padding-bottom: 5vw;
  fill: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo {
    padding-bottom: 2.5rem;
  }
}
.eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo svg {
  width: 30vw;
  height: 9vw;
  display: block;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo svg {
    width: 11rem;
    height: 3.5rem;
  }
}
.eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo svg.joker-hat {
  width: 17.2vw;
  height: 8.67vw;
  padding: 0 0 2.67vw;
  flex: 100%;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo svg.joker-hat {
    width: 6.5rem;
    height: 3.2rem;
    padding: 0 0 1rem;
  }
}
.eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo svg.joker-logo {
  width: 29.6vw;
  height: 6.8vw;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo svg.joker-logo {
    width: 11.2rem;
    height: 2.6rem;
  }
}
.eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo .joker-text {
  font-size: 8vw;
  font-style: italic;
  color: #fff;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .eurojackpot-section .eurojackpot-content-wrapper.joker-section .logo .joker-text {
    font-size: 2.9rem;
    margin-top: -0.2rem;
  }
}
.eurojackpot-winning-numbers-spot .select-date-section {
  padding: 0 7vw;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .select-date-section {
    padding: 0;
  }
}
.eurojackpot-winning-numbers-spot .select-date-section .cs-placeholder {
  color: #0a0a0a;
  background-color: #f2f2f2;
  text-transform: initial;
  transition: background-color 0.15s;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot {
    margin: 0 auto;
    font-size: 4rem;
  }
}
.eurojackpot-winning-numbers-spot .bottom-gradient {
  width: 100%;
  height: 30vw;
  position: absolute;
  bottom: 0;
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 50%, #000000 60%, #000 100%);
  transition: opacity 0.5s;
  text-align: center;
  z-index: 1;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .bottom-gradient {
    display: none;
  }
}
.eurojackpot-winning-numbers-spot .bottom-gradient .see-winning-number-btn {
  top: 5vw;
  padding-right: 10vw;
  z-index: 10;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .bottom-gradient .see-winning-number-btn {
    padding-right: 4vw;
  }
}
.eurojackpot-winning-numbers-spot .select-dropdown span {
  font-size: 3.4vw;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .select-dropdown span {
    font-size: 1.8rem;
  }
}
.eurojackpot-winning-numbers-spot .headline {
  margin: 0 auto;
  max-width: 78vw;
  font-size: 10.7vw;
  font-weight: bold;
  text-transform: initial;
  color: #fff;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .headline {
    font-size: 5rem;
    line-height: 4rem;
    margin: 0 auto 2rem;
  }
}
.eurojackpot-winning-numbers-spot .subheadline {
  max-width: 86.5vw;
  font-size: 3.7vw;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0 5rem;
  margin: 0 auto 4.7vw;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .subheadline {
    font-size: 2rem;
    line-height: 4.2rem;
    padding: 0;
    margin: 0 auto 2rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-list {
  max-width: 86.7vw;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-list {
    max-width: 96rem;
  }
  .eurojackpot-winning-numbers-spot .winning-numbers-list:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.eurojackpot-winning-numbers-spot .disclaimer-info-text {
  max-width: 86.7vw;
  font-size: 4.3vw;
  font-weight: 900;
  color: #8e8e8e;
  padding-top: 6vw;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .disclaimer-info-text {
    max-width: 96rem;
    font-size: 2rem;
    padding: 2rem 0 0 0;
    margin: 0 auto;
  }
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element {
    box-sizing: border-box;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers {
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid #616161;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container {
  width: 100%;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container {
    max-width: 44.5rem;
    display: inline-block;
    margin-right: 3rem;
    vertical-align: top;
  }
  .eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container:nth-child(2) {
    margin-right: 0;
    margin-left: 3rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container .numbers-headline {
  max-width: 86.7vw;
  color: #fff;
  margin: 5.5vw auto 0;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container .numbers-headline {
    font-size: 3rem;
    line-height: 3.6rem;
    text-transform: none;
    margin: 3rem auto 1.5rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container ul,
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li {
  margin: 0;
  padding: 0;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container ul {
  border-top: 0.1rem solid #616161;
  list-style-type: none;
  padding: 2.5rem 0 1.7rem;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li {
  display: inline-block;
  margin: 0 2.7vw;
  width: 10.5vw;
  height: 10.5vw;
  border-radius: 100%;
  font-size: 3.5vw;
  color: #fff;
  line-height: 3.2;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li {
    width: 5.5rem;
    height: 5.5rem;
    font-size: 1.8rem;
    margin: 0 1.2rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li .number-icon {
  fill: #fff;
  position: absolute;
  width: 11.2vw;
  height: 11.2vw;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li .number-icon.star {
  width: 12.4vw;
  height: 12.4vw;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li .number-icon.joker {
  width: 60%;
  height: 60%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li .number-icon {
    width: 5.4rem;
    height: 5.4rem;
  }
  .eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li .number-icon.star {
    width: 6rem;
    height: 6rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .numbers .number-container li span {
  position: absolute;
  z-index: 1;
  display: block;
  width: inherit;
  height: inherit;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table,
.eurojackpot-winning-numbers-spot .winning-numbers-element .results th,
.eurojackpot-winning-numbers-spot .winning-numbers-element .results td {
  border: 0.1rem solid #fff;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table {
  table-layout: fixed;
  border-bottom: 0;
  border-left: 0;
  border-collapse: inherit;
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table {
    width: 45rem;
    margin: auto;
    padding-bottom: 0;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table td,
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table th {
  border-top: 0;
  border-right: 0;
  padding: 3.5vw;
  font-size: 3.2vw;
  color: #000;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table td,
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table th {
    font-size: 1.8rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table td,
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table th {
    padding: 1.4rem;
    font-size: 1.5rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table th {
  font-weight: 400;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table tr td {
  border-left: 1px solid #000;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table tr:nth-child(odd) {
  background-color: #fff;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element .results table thead th {
  font-weight: bold;
  font-size: 3.7vw;
  color: #fff;
  background-color: #1f1f1f;
  padding: 4vw;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table thead th {
    padding: 3rem 2rem;
    font-size: 2rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element .results table thead th {
    padding: 1.9rem;
    font-size: 1.6rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element.eurojackpot-winning-numbers .logo {
    margin-top: 6rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element.joker-winning-numbers .numbers .number-container {
  margin-right: 0;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element.joker-winning-numbers .numbers .number-container {
    max-width: 55.3rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element.joker-winning-numbers .numbers .number-container ul {
  border-top: none;
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .winning-numbers-element.joker-winning-numbers .numbers .number-container ul {
    padding-top: 1rem;
  }
}
.eurojackpot-winning-numbers-spot .winning-numbers-element.joker-winning-numbers .numbers .number-container ul li {
  background-color: #c50005;
  background-image: linear-gradient(45deg, #009a60 25%, transparent 25%, transparent 75%, #009a60 75%, #009a60), linear-gradient(45deg, #009a60 25%, transparent 25%, transparent 75%, #009a60 75%, #009a60);
  background-size: 30% 30%;
  background-position: 0 20%, 20% 0;
}
.eurojackpot-winning-numbers-spot .winning-numbers-element.joker-winning-numbers .numbers .number-container ul li span {
  color: #0a0a0a;
}
.eurojackpot-winning-numbers-spot .eurojackpot-content-wrapper {
  padding: 5vh 0;
  box-sizing: border-box;
  min-height: 50vh;
}
.eurojackpot-winning-numbers-spot .eurojackpot-content-wrapper .hidden {
  display: none !important;
}
.eurojackpot-winning-numbers-spot .select-date-section {
  margin: 2rem auto 1rem;
}
.eurojackpot-winning-numbers-spot .featured-winners {
  font-size: 3.2vw;
  border: 0.1rem solid #fff;
  border-radius: 3px;
  color: #fff;
  margin: 1rem auto;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-winning-numbers-spot .featured-winners {
    font-size: 1.8rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-winning-numbers-spot .featured-winners {
    width: 45rem;
    font-size: 1.5rem;
  }
}
.eurojackpot-winning-numbers-spot .featured-winners .header {
  text-align: left;
  font-weight: 600;
  padding: 2vw 2rem;
  color: #fff;
  border-bottom: 1px solid #8e8e8e;
  position: relative;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .featured-winners .header {
    padding: 1.9rem;
  }
}
.eurojackpot-winning-numbers-spot .featured-winners .header .icon-arrow {
  position: absolute;
  width: 3.2vw;
  height: 3.2vw;
  right: 2rem;
  top: 2.5vw;
  fill: #fff;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .featured-winners .header .icon-arrow {
    top: 2.2rem;
    width: 2rem;
    height: 2rem;
  }
}
.eurojackpot-winning-numbers-spot .featured-winners .text {
  text-align: left;
  padding: 2vw 2rem;
  line-height: 5vw;
  font-style: italic;
}
@media (min-width: 768px) {
  .eurojackpot-winning-numbers-spot .featured-winners .text {
    line-height: 3rem;
    padding: 1rem 2rem;
  }
}
.eurojackpot-winning-numbers-spot .featured-winners .text p {
  margin: 0;
}
.eurojackpot-winning-numbers-spot .featured-winners.hide .header {
  border-bottom: none;
}
.eurojackpot-winning-numbers-spot .featured-winners.hide .header .icon-arrow {
  transform: rotate(180deg);
}
.eurojackpot-winning-numbers-spot .featured-winners.hide .header:after {
  display: none;
}
.eurojackpot-winning-numbers-spot .featured-winners.hide .text {
  display: none;
}
