﻿@import url("../Mixins/_mixins.less");

.statistics-module {
  .statistics-module-wrapper {
    // Statistics module theming for Lotto
    &.lotto-statistics {
      font-family: "Open Sans", Arial, Helvetica, sans-serif;

      .statistics-module-period-filter-wrapper {
        background-color: @lotto-maroonred;
        color: #fff;

        .period-filter-inner-wrapper {
          .period-filter-toggle-buttons-wrapper {
            .period-filter-toggle-button {
              .icon {
                fill: #fff;
              }
            }
          }

          .period-filter-content-wrapper {
            .period-filter-header {
              .period-filter-game-client-dropdown {
                .game-client-dropdown-placeholder {
                  .icon {
                    fill: #fff;
                  }
                }

                .game-client-dropdown-list {
                  background-color: @lotto-maroonred;

                  .game-client-dropdown-list-item {
                    &:hover {
                      background-color: #7c0001;
                    }
                  }
                }
              }
            }

            .period-filter-row {
              .filter-row-name {
                .filter-row-reset {
                  .icon {
                    fill: #fff;
                  }
                }
              }

              .filter-row-options {
                .option {
                  color: #4f5254;
                }
              }
            }
          }
        }
      }

      .statistics-module-numbers-type-switch-wrapper {
        background-color: @lotto-red;

        .numbers-type-switch-inner-wrapper {
          border-color: #4f5254;

          .numbers-type-switch-button-wrapper {
            background-color: #950002;
            color: #fff;

            &:hover {
              background-color: #7c0001;
            }

            &.active {
              background-color: #fff;
              color: #4f5254;

              &:hover {
                @media (min-device-width: @desktop-width) {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }

      .statistics-module-small-view {
        background-color: @lotto-maroonred;
        color: #fff;

        .statistics-module-graph-overview-inner-wrapper {
          .statistics-module-graph-overview-expand-wrapper {
            .graph-overview-button {
              .icon {
                fill: #fff;
              }
            }
          }

          .statistics-internal-wrapper {
            background: repeating-linear-gradient(0deg, #fff, #fff 19px, #777777 20px, #777777 20px);

            .statistics-bars-wrapper {
              .statistics-small-bar-wrapper {
                &.hovered {
                  .bar {
                    background-color: @lotto-lightred;
                  }
                }

                .bar {
                  background-color: #4f5254;

                  &.selected {
                    background-color: @lotto-red;
                  }
                }
              }
            }

            .statistics-zoom-finder-wrapper {
              .zoom-finder {
                &:before, &:after {
                  background-color: @lotto-maroonred;
                }
              }
            }
          }
        }
      }

      .statistics-module-large-view {
        background-color: @lotto-darkred;

        .statistics-module-graph-slide-button {
          &.right {
            background: linear-gradient(to right, rgba(149, 0, 2,0) 0%,rgba(149, 0, 2,0.65) 25%,rgba(149, 0, 2,0.8) 50%,rgba(149, 0, 2,0.95) 100%);
          }

          &.left {
            background: linear-gradient(to right, rgba(149, 0, 2,0.95) 0%,rgba(149, 0, 2,0.8) 50%,rgba(149, 0, 2,0.65) 75%,rgba(149, 0, 2,0) 100%);
          }

          .icon {
            fill: #eee;
          }
        }

        .statistics-module-large-internal-wrapper {
          background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);

          @media (min-device-width: @desktop-width) {
            background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
          }

          .statistics-large-bar-wrapper {
            &.selected {
              .bar {
                background-color: @lotto-red;

                .amount {
                  &:before {
                    background-color: @lotto-red;
                  }
                }
              }

              .number {
                color: #fff;
                background-color: @lotto-red;
              }
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                .bar {
                  background-color: @lotto-lightred;

                  .amount {
                    &:before {
                      background-color: @lotto-lightred;
                    }
                  }
                }
              }
            }

            &.special {
              .bar {

                &.most-drawn {
                  .amount {
                    background-color: @lotto-lightred;
                  }
                }
              }

              &.selected {
                .bar {
                  background-color: #950002;

                  .amount {
                    &:before {
                      background-color: #950002;
                    }
                  }
                }

                .number {
                  background-color: #74080a;
                }
              }
            }

            .bar {
              background-color: #4f5254;

              &.most-drawn {
                .amount {
                  color: #fff;
                  background-color: @lotto-red;
                }
              }

              &.least-drawn {
                .amount {
                  color: #fff;
                  background-color: @lotto-darkred;
                }
              }

              .amount {
                background-color: #fff;

                &:before {
                  background-color: #505050;
                }
              }
            }

            .number {
              width: 7vw;
              height: 7vw;
              margin: auto;
              border-radius: 50%;
              background-color: #fff;

              @media (min-device-width: @desktop-width) {
                width: 80%;
                height: initial;
                line-height: 1.6;
                padding: 0.3rem 0;
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
    }
    // Statistics module theming for VikingLotto
    &.vikinglotto-statistics {
      font-family: "Open Sans", Arial, Helvetica, sans-serif;

      .statistics-module-period-filter-wrapper {
        background-color: @vikinglotto-darkblue;
        color: #fff;

        .period-filter-inner-wrapper {
          .period-filter-toggle-buttons-wrapper {
            .period-filter-toggle-button {
              .icon {
                fill: #fff;
              }
            }
          }

          .period-filter-content-wrapper {
            .period-filter-header {
              .period-filter-game-client-dropdown {
                .game-client-dropdown-placeholder {
                  .icon {
                    fill: #fff;
                  }
                }

                .game-client-dropdown-list {
                  background-color: @vikinglotto-darkblue;

                  .game-client-dropdown-list-item {
                    &:hover {
                      background-color: @vikinglotto-mediumblue;
                    }
                  }
                }
              }
            }

            .period-filter-row {
              .filter-row-name {
                .filter-row-reset {
                  .icon {
                    fill: #fff;
                  }
                }
              }

              .filter-row-options {
                .option {
                  color: #4f5254;
                }
              }
            }
          }
        }
      }

      .statistics-module-numbers-type-switch-wrapper {
        background-color: @vikinglotto-blue;

        .numbers-type-switch-inner-wrapper {
          border-color: #4f5254;

          .numbers-type-switch-button-wrapper {
            background-color: @vikinglotto-mediumblue;
            color: #fff;

            &:hover {
              background-color: @vikinglotto-darkblue;
            }

            &.active {
              background-color: #fff;
              color: #4f5254;

              &:hover {
                @media (min-device-width: @desktop-width) {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }

      .statistics-module-small-view {
        background-color: @vikinglotto-darkblue;
        color: #fff;

        .statistics-module-graph-overview-inner-wrapper {
          .statistics-module-graph-overview-expand-wrapper {
            .graph-overview-button {
              .icon {
                fill: #fff;
              }
            }
          }

          .statistics-internal-wrapper {
            background: repeating-linear-gradient(0deg, #fff, #fff 19px, #777777 20px, #777777 20px);

            .statistics-bars-wrapper {
              .statistics-small-bar-wrapper {
                &.hovered {
                  .bar {
                    background-color: @vikinglotto-mediumblue;
                  }
                }

                .bar {
                  background-color: #4f5254;

                  &.selected {
                    background-color: @vikinglotto-blue;
                  }
                }
              }
            }

            .statistics-zoom-finder-wrapper {
              .zoom-finder {
                &:before, &:after {
                  background-color: #000078;
                }
              }
            }
          }
        }
      }

      .statistics-module-large-view {
        background-color: @vikinglotto-mediumblue;

        @media (min-device-width: @desktop-width) {
          background-color: @vikinglotto-blue;
        }

        .statistics-module-graph-slide-button {
          &.right {
            background: linear-gradient(to right, rgba(0, 0, 255,0) 0%,rgba(0, 0, 255,0.65) 25%,rgba(0, 0, 255,0.8) 50%,rgba(0, 0, 255,0.95) 100%);
          }

          &.left {
            background: linear-gradient(to right, rgba(0, 0, 255,0.95) 0%,rgba(0, 0, 255,0.8) 50%,rgba(0, 0, 255,0.65) 75%,rgba(0, 0, 255,0) 100%);
          }

          .icon {
            fill: #eee;
          }
        }

        .statistics-module-large-internal-wrapper {
          background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);

          @media (min-device-width: @desktop-width) {
            background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
          }

          .statistics-large-bar-wrapper {
            &.selected {
              .bar {
                background-color: @vikinglotto-blue;

                .amount {
                  &:before {
                    background-color: @vikinglotto-blue;
                  }
                }
              }

              .number {
                background-color: @numbergames-cta-yellow;
              }
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                .bar {
                  background-color: @vikinglotto-mediumblue;

                  .amount {
                    &:before {
                      background-color: @vikinglotto-mediumblue;
                    }
                  }
                }
              }
            }

            &.special {

              .number {
                line-height: 1.3;
                border-radius: 0;
                padding: 0.45rem 0 0.65rem;
                color: #fff;
                background-color: transparent;
                background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-white.svg);

                @media (min-device-width: @desktop-width) {
                  font-size: 1.3rem;
                  line-height: 1.6;
                }
              }

              &.selected {
                .bar {
                  background-color: @vikinglotto-blue;

                  .amount {
                    &:before {
                      background-color: @vikinglotto-blue;
                    }
                  }
                }

                .number {
                  color: @numbergames-cta-yellow;
                  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-yellow.svg);
                }
              }
            }

            .bar {
              background-color: #4f5254;

              &.most-drawn {
                .amount {
                  color: #fff;
                  background-color: @vikinglotto-darkblue;
                }
              }

              &.least-drawn {
                .amount {
                  color: #fff;
                  background-color: @vikinglotto-blue;
                }
              }

              .amount {
                background-color: #fff;

                &:before {
                  background-color: #505050;
                }
              }
            }

            .number {
              width: 7vw;
              height: 7vw;
              margin: auto;
              border-radius: 50%;
              background-color: #fff;

              @media (min-device-width: @desktop-width) {
                width: 80%;
                height: initial;
                line-height: 1.6;
                padding: 0.3rem 0;
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
    }
    // Statistics module theming for Eurojackpot
    &.eurojackpot-statistics {
      .statistics-module-period-filter-wrapper {
        color: #fff;
        background-color: #0A0A0A;

        .period-filter-inner-wrapper {
          .period-filter-toggle-buttons-wrapper {
            .period-filter-toggle-button {
              .icon {
                fill: #fff;
              }
            }
          }

          .period-filter-content-wrapper {
            .period-filter-header {
              .period-filter-game-client-dropdown {
                .game-client-dropdown-placeholder {
                  background-color: rgba(255,255,255,.3);

                  .icon {
                    fill: #fff;
                  }
                }

                .game-client-dropdown-list {
                  color: #0A0A0A;
                  background-color: #fff;
                }
              }
            }

            .period-filter-row {
              .filter-row-name {
                .filter-row-reset {
                  .icon {
                    fill: #fff;
                  }
                }
              }

              .filter-row-options {
                .option {
                  color: #0A0A0A;

                  .filter-icon {
                    fill: #0A0A0A;
                  }
                }
              }
            }
          }
        }
      }

      .statistics-module-numbers-type-switch-wrapper {
        background-color: #171717;

        .numbers-type-switch-inner-wrapper {
          border-color: #dcdcdc;

          .numbers-type-switch-button-wrapper {
            background-color: #eee;
            border-color: #dcdcdc;

            &:hover {
              background-color: #e4e4e4;
            }

            &.active {
              background-color: #dcdcdc;

              &:hover {
                @media (min-device-width: @desktop-width) {
                  background-color: #dcdcdc;
                }
              }
            }
          }
        }
      }

      .statistics-module-small-view {
        background-color: #0A0A0A;

        .statistics-module-graph-overview-inner-wrapper {
          .statistics-module-graph-overview-expand-wrapper {
            .graph-overview-button {
              color: #fff;

              .icon {
                fill: #fff;
              }
            }
          }

          .statistics-internal-wrapper {
            background: repeating-linear-gradient(0deg, #eee, #eee 19px, #777777 20px, #777777 20px);

            .statistics-bars-wrapper {
              .statistics-small-bar-wrapper {
                &.hovered {
                  .bar {
                    background-color: @eurojackpot-gold;
                  }
                }

                .bar {
                  color: #fff;
                  background-color: #505050;

                  &.selected {
                    background-color: @eurojackpot-gold;
                  }
                }
              }
            }
          }
        }
      }

      .statistics-module-large-view {
        background-color: @eurojackpot-darksmoke;

        .statistics-module-graph-slide-button {
          &.right {
            background: linear-gradient(to right, rgba(32, 30, 32,0) 0%,rgba(32, 30, 32,0.65) 25%,rgba(32, 30, 32,0.8) 50%,rgba(32, 30, 32,0.95) 100%);
          }

          &.left {
            background: linear-gradient(to right, rgba(32, 30, 32,0.95) 0%,rgba(32, 30, 32,0.8) 50%,rgba(32, 30, 32,0.65) 75%,rgba(32, 30, 32,0) 100%);
          }

          .icon {
            fill: #fff;
          }
        }

        .statistics-module-large-internal-wrapper {
          background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);

          @media (min-device-width: @desktop-width) {
            background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
          }

          .statistics-large-bar-wrapper {
            &.selected {
              .bar {
                background-color: @eurojackpot-gold;

                .amount {
                  &:before {
                    background-color: @eurojackpot-gold;
                  }
                }
              }

              .number {
                color: #fff;
                background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/square-shape-gold_with-gradient.svg);
              }
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                .bar {
                  background-color: @eurojackpot-graygold;

                  .amount {
                    &:before {
                      background-color: @eurojackpot-graygold;
                    }
                  }
                }
              }
            }

            &.special {
              .number {
                height: 3.2rem;
                padding-top: .7rem;
                background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-grey.svg);
                bottom: -.8rem;
              }

              &.selected {
                .number {
                  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
                }
              }
            }

            .bar {
              background-color: #505050;

              &.most-drawn {
                .amount {
                  color: #fff;
                  background-color: @eurojackpot-gold;
                }
              }

              &.least-drawn {
                .amount {
                  color: #fff;
                  background-color: @eurojackpot-carbon;
                }
              }

              .amount {
                background-color: #fff;

                &:before {
                  background-color: #505050;
                }
              }
            }

            .number {
              width: 7vw;
              height: 7vw;
              background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons.svg#icon-joker-numbershape_use);

              @media screen and (-webkit-min-device-pixel-ratio:2) {
                background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-joker-numbershape.svg);
              }

              @media (min-device-width: @desktop-width) {
                width: 100%;
                height: 3rem;
                padding-top: .5rem;
              }
            }
          }
        }
      }
    }
    // Statistics module theming for Keno
    &.keno-statistics {
      line-height: 1.6;

      .statistics-module-period-filter-wrapper {
        background-color: #0091cf;
        color: #fff;

        .period-filter-inner-wrapper {
          .period-filter-toggle-buttons-wrapper {
            .period-filter-toggle-button {
              @media (min-device-width: @desktop-width) {
                padding: 1.2rem 0 0.6rem;
              }

              .icon {
                fill: #fff;
              }
            }
          }

          .period-filter-content-wrapper {
            .period-filter-header {
              @media (min-device-width: @desktop-width) {
                padding: 1rem 0 0.7rem 2rem;
              }

              .period-filter-game-client-dropdown {
                .game-client-dropdown-placeholder {
                  @media (min-device-width: @desktop-width) {
                    padding: 1rem 2rem 0.7rem;
                  }

                  .icon {
                    fill: #fff;
                  }
                }

                .game-client-dropdown-list {
                  background-color: #0091cf;

                  .game-client-dropdown-list-item {
                    &:hover {
                      background-color: #1e55a0;
                    }
                  }
                }
              }
            }

            .period-filter-row {
              .filter-row-name {
                .filter-row-reset {
                  .icon {
                    fill: #fff;
                  }
                }
              }

              .filter-row-options {
                .option {
                  color: #4f5254;
                  line-height: 1.4;
                }
              }
            }
          }
        }
      }

      .statistics-module-numbers-type-switch-wrapper {
        background-color: @vikinglotto-blue;

        .numbers-type-switch-inner-wrapper {
          border-color: #4f5254;

          .numbers-type-switch-button-wrapper {
            background-color: @vikinglotto-mediumblue;
            color: #fff;

            &:hover {
              background-color: @vikinglotto-darkblue;
            }

            &.active {
              background-color: #fff;
              color: #4f5254;

              &:hover {
                @media (min-device-width: @desktop-width) {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }

      .statistics-module-small-view {
        background-color: #00a4eb;
        color: #fff;

        .statistics-module-graph-overview-inner-wrapper {
          .statistics-module-graph-overview-expand-wrapper {
            .graph-overview-button {
              .icon {
                fill: #fff;
              }
            }
          }

          .statistics-internal-wrapper {
            background: repeating-linear-gradient(0deg, #fff, #fff 19px, #777777 20px, #777777 20px);

            .statistics-bars-wrapper {
              .statistics-small-bar-wrapper {
                &.hovered {
                  .bar {
                    background-color: #1e55a0;
                  }
                }

                .bar {
                  background-color: #4f5254;

                  &.selected {
                    background-color: #21375f;
                  }
                }
              }
            }

            .statistics-zoom-finder-wrapper {
              .zoom-finder {
                &:before, &:after {
                  background-color: #00a4eb;
                }
              }
            }
          }
        }
      }

      .statistics-module-large-view {
        background-color: @keno-powderblue;

        @media (min-device-width: @desktop-width) {
          background-color: #006590;
        }

        .statistics-module-graph-slide-button {
          &.right {
            background: linear-gradient(to right, rgba(0, 101, 144,0) 0%,rgba(0, 101, 144,0.65) 25%,rgba(0, 101, 144,0.8) 50%,rgba(0, 101, 144,0.95) 100%);
          }

          &.left {
            background: linear-gradient(to right, rgba(0, 101, 144,0.95) 0%,rgba(0, 101, 144,0.8) 50%,rgba(0, 101, 144,0.65) 75%,rgba(0, 101, 144,0) 100%);
          }

          .icon {
            fill: #eee;
          }
        }

        .statistics-module-large-internal-wrapper {
          background: repeating-linear-gradient(90deg, #777777 0%, #777777 0.2%, #eee 0.3%, #eee 19.6%, #777777 20%, #777777 20%);

          @media (min-device-width: @desktop-width) {
            background: repeating-linear-gradient(0deg, #eee, #eee 19.7%, #777777 20%, #777777 20%);
          }

          .statistics-large-bar-wrapper {
            &.selected {
              .bar {
                background-color: #21375f;

                .amount {
                  &:before {
                    background-color: #21375f;
                  }
                }
              }

              .number {
                background-color: @keno-royalblue;

                @media (min-device-width: @desktop-width) {
                  background-color: #21375f;
                }
              }
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                .bar {
                  background-color: #1e55a0;

                  .amount {
                    &:before {
                      background-color: #1e55a0;
                    }
                  }
                }
              }
            }

            .bar {
              background-color: #4f5254;

              &.most-drawn {
                .amount {
                  color: #fff;
                  background-color: #21375f;
                }
              }

              &.least-drawn {
                .amount {
                  color: #fff;
                  background-color: @keno-royalblue;
                }
              }

              .amount {
                background-color: #fff;

                &:before {
                  background-color: #505050;
                }
              }
            }

            .number {
              width: 7vw;
              height: 7vw;
              margin: auto;
              color: #fff;
              font-weight: bold;
              border-radius: 6px;
              background-color: #0091cf;

              @media (min-device-width: @desktop-width) {
                width: 80%;
                height: initial;
                padding: .4rem 0 .2rem;
                left: 0;
                right: 0;
                background-color: #0091cf;
              }
            }
          }
        }
      }
    }
  }
}

.region-keno {
  .statistics-module {
    .statistics-module-wrapper {
      .statistics-module-period-filter-wrapper {
        .period-filter-inner-wrapper {
          .period-filter-toggle-buttons-wrapper {
            .period-filter-toggle-button {
              .text {
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}
