@import (reference) "../Mixins/_mixins.less";
@color-ds-cta-active: #f5da00;
.btn() {
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: @ds-gray-200;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;

  &::after {
    border-radius: 2.8rem;
    transition: transform 200ms ease-out;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    border: 0.1rem solid transparent;
  }
}

.btn-width-default() {
  min-width: 18rem;

  @media @xxs {
    min-width: 21.4rem;
  }
}

.btn-width-narrow() {
  width: 8rem;
}

.btn-width-stretch() {
  width: 100%;
}

.btn-height-default() {
  height: 5.6rem;
}

.btn-height-medium() {
  height: 4.5rem;
}

.btn-height-slim() {
  height: 3rem;
}

.btn-with-x-padding {
  padding-left: 2rem;
}

@keyframes btn-pulse-inner-loading-animation {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 fade(@color-ds-cta-active, 80%);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px fade(@color-ds-cta-active, 0%);
  }
  
  95%, 100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 fade(@color-ds-cta-active, 0%);
  }
}
@keyframes btn-pulse-loading-animation {
  38% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 0 10px fade(@color-ds-cta-active, 0%);
  }
  
  65%, 100% {
    transform: scale(1);
  }
}

.btn-primary() {
  color: @ds-black;
  transition: color 200ms ease-out;

  &::after {
    background-color: @ds-yellow;
    border-color: @ds-yellow;
  }

  &:not(.btn--disabled):not(.btn--loading):active::after {
    background-color: @color-ds-cta-active;
    border-color: @color-ds-cta-active;
  }

  &.btn--disabled {
    color: fade(@ds-black, 30%);
    cursor: default;
  }

  &.btn--loading {
    color: fade(@ds-black, 30%);
    cursor: default;

    &::before {
      content: "";
      border-radius: 2.8rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      transform: scale(0.8);
      box-shadow: 0 0 0 0 @ds-yellow-100;
      animation: btn-pulse-inner-loading-animation 1.3s infinite;
    }

    &::after {
      animation: btn-pulse-loading-animation 1.3s infinite;
    }
  }
}

.btn-secondary() {
  color: @ds-black;

  &::after {
    background-color: @ds-white;
    border-color: @ds-black;
  }
}

.btn-square() {
  overflow: hidden;
  font-size: 3rem;
  height: 8rem;
  width: 8rem;
  border-radius: 2.8rem;
  padding: 0;
  color: @ds-black;

  &::after {
    background-color: @ds-white;
    border-color: @ds-grey-200;
  }

  &:hover::after {
    background-color: @ds-white;
    border-color: @ds-yellow;
  }

  &:active {
    background-color: @ds-white;
    border-color: @color-black;
  }

  &.btn--selected::after  {
    background-color: @ds-grey-240;
    border-color: @ds-yellow;
  }
}

.btn-flip-label() {
  @wizardButtonTextTransition: cubic-bezier(0, 0.4, 0.3, 1);
  @wizardButtonTextTransitionTime: 400ms;

  &::before,
  &::after {
    content: attr(label);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    transition: transform @wizardButtonTextTransitionTime @wizardButtonTextTransition, opacity @wizardButtonTextTransitionTime @wizardButtonTextTransition;
  }

  &::before {
    opacity: 1;
  }

  &::after {
    transform: translateY(40%) scale(0.9);
    opacity: 0;
  }

  &:hover {
    &::before {
      transform: translateY(-40%) scale(0.9);
      opacity: 0;
    }

    &::after {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.btn--selected {
    &::before {
      transition: transform 0s;
    }

    &::after {
      transition: transform 0s;
    }
  }
}

.btn-hover-scale() {
  &:not(.btn--disabled):hover::after {
    transform: scale(1.03);
  }
}

// Christmas style
@christmas-red: #852825;
@christmas-darkred: #791105;
@christmas-gold: #BA9142;

@christmas-red-radial-gradient: radial-gradient(122.78% 122.08% at 12.14% 5.08%, #B71817 0%, #791105 100%);
@christmas-gold-linear-gradient: linear-gradient(19.13deg, #FAC826 -69%, #FFFACB -2.4%, #F8EEBA 11.61%, #E7D08D 39.41%, #CBA145 78.1%, #C2912E 89.92%, #EDD688 164.09%, #FFFACB 198.9%);

@summer-orange-radial-gradient: radial-gradient(64.86% 53.9% at 67.2% 46.1%, #ED911B 0%, #D56429 100%);

// Christmas button
.christmas-button() {
  width: 23.5rem;
  color: @christmas-red;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 16px;
  border: none;
  border-radius: 30px;
  background: @christmas-gold;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: color .15s linear, filter .15s linear;
  z-index: 0;
  overflow: hidden;
  min-height: 5.6rem;
  letter-spacing: 0.1rem;

  &::before {
    content: '';
    position: absolute;
    top: .2rem;
    right: .2rem;
    bottom: .2rem;
    left: .2rem;
    border-radius: 30px;
    border: 1px solid currentColor;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -2rem;
    bottom: 0;
    right: 0rem;
    background: linear-gradient(234.19deg, #F2C74E -22.41%, #FDF8CF 29.4%, #BA9142 100%);
    z-index: -1;
    transition: transform .15s linear, opacity .15s linear;
  }


  &:hover:not(.inactive) {
    filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.4));

    &::after {
      transform: translateX(2rem);
    }
  }

  &.inactive {
    background: #791105;
    color: #B71817;
    cursor: default;

    &::after {
      opacity: 0;
    }
  }

  @keyframes lotto-christmas-loader-fade-animation {
    100% {
      max-width: 5rem;
      margin-left: .6rem;
      opacity: 1;
    }
  }

  @keyframes lotto-christmas-loader-dot-animation {
    0%, 100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-100%);
    }
  }

  .loader {
    max-width: 0;
    display: inline-block;
    opacity: 0;
    white-space: nowrap;

    .loader__dot {
      width: .6rem;
      height: .6rem;
      margin-left: .2rem;
      border-radius: 50%;
      background-color: @christmas-red;
      display: inline-block;
    }
  }

  &.loading {
    cursor: default;

    .loader {
      animation: lotto-christmas-loader-fade-animation .1s linear forwards;
    }

    .loader__dot {
      &:nth-child(1n) {
        animation: lotto-christmas-loader-dot-animation .5s ease-in-out -.81s infinite;
      }

      &:nth-child(2n) {
        animation: lotto-christmas-loader-dot-animation .5s ease-in-out -.48s infinite;
      }

      &:nth-child(3n) {
        animation: lotto-christmas-loader-dot-animation .5s ease-in-out -1.15s infinite;
      }
    }
  }

  &.btn--disabled {
    opacity: 0.5;
  }

  &.outlined {
    &::before {
      background-color: @christmas-darkred;
    }

    span {
      background: @christmas-gold-linear-gradient;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      position: relative;
      z-index: 1;
    }
  }
}
