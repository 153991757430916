@import url("../Mixins/_mixins.less");

.eurojackpot-winning-numbers-spot {

  .eurojackpot-section {
    position: relative;
    background-color: @eurojackpot-almost-black;
    border-color: @eurojackpot-almost-black;

    .eurojackpot-content-wrapper {
      @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
        max-width: 75%;
        margin: 0 auto;
      }

      &.joker-section {
        background-color: @eurojackpot-darkgray;

        .logo {
          padding-bottom: 5vw;
          fill: @eurojackpot-white;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          @media (min-width: @eurojackpot-desktop-width) {
            padding-bottom: 2.5rem;
          }

          svg {
            width: 30vw;
            height: 9vw;
            display: block;

            @media (min-width: @eurojackpot-desktop-width) {
              width: 11rem;
              height: 3.5rem;
            }

            &.joker-hat {
              width: 17.2vw;
              height: 8.67vw;
              padding: 0 0 2.67vw;
              flex: 100%;

              @media (min-width: @eurojackpot-desktop-width) {
                width: 6.5rem;
                height: 3.2rem;
                padding: 0 0 1rem;
              }
            }

            &.joker-logo {
              width: 29.6vw;
              height: 6.8vw;

              @media (min-width: @eurojackpot-desktop-width) {
                width: 11.2rem;
                height: 2.6rem;
              }
            }
          }

          .joker-text {
            font-size: 8vw;
            font-style: italic;
            color: @eurojackpot-white;

            @media (min-width: @eurojackpot-desktop-width) {
              font-size: 2.9rem;
              margin-top: -.2rem;
            }
          }
        }
      }
    }
  }

  .select-date-section {
    padding: 0 7vw;

    @media (min-width: @eurojackpot-desktop-width) {
      padding: 0;
    }

    .cs-placeholder {
      color: @eurojackpot-almost-black;
      background-color: @eurojackpot-whitesmoke;
      text-transform: initial;
      transition: background-color .15s;
    }
  }

  @media (min-width: @eurojackpot-desktop-width) {
    margin: 0 auto;
    font-size: 4rem;
  }

  .bottom-gradient {
    width: 100%;
    height: 30vw;
    position: absolute;
    bottom: 0;
    display: block;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 1) 60%, #000 100%);
    transition: opacity 0.5s;
    text-align: center;
    z-index: 1;

    @media (min-width: @eurojackpot-desktop-width) {
      display: none;
    }

    .see-winning-number-btn {
      top: 5vw;
      padding-right: 10vw;
      z-index: 10;

      @media (min-width: @eurojackpot-desktop-width) {
        padding-right: 4vw;
      }
    }
  }

  .select-dropdown {
    span {
      font-size: 3.4vw;

      @media (min-width: @eurojackpot-mobile-width) {
        font-size: 1.8rem;
      }
    }
  }

  .headline {
    margin: 0 auto;
    max-width: 78vw;
    font-size: 10.7vw;
    font-weight: bold;
    text-transform: initial;
    color: @eurojackpot-white;

    @media (min-width: @eurojackpot-mobile-width) {
      font-size: 5rem;
      line-height: 4rem;
      margin: 0 auto 2rem;
    }
  }

  .subheadline {
    max-width: 86.5vw;
    font-size: 3.7vw;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0 5rem;
    margin: 0 auto 4.7vw;

    @media (min-width: @eurojackpot-mobile-width) {
      font-size: 2rem;
      line-height: 4.2rem;
      padding: 0;
      margin: 0 auto 2rem;
    }
  }

  .winning-numbers-list {
    max-width: 86.7vw;
    margin: auto;

    @media (min-width: @eurojackpot-desktop-width) {
      max-width: 96rem;
      .clearfix();
    }
  }

  .disclaimer-info-text {
    max-width: 86.7vw;
    font-size: 4.3vw;
    font-weight: 900;
    color: @eurojackpot-lightgray;
    padding-top: 6vw;
    margin: 0 auto;

    @media (min-width: @eurojackpot-mobile-width) {
      max-width: 96rem;
      font-size: 2rem;
      padding: 2rem 0 0 0;
      margin: 0 auto;
    }
  }

  .winning-numbers-element {
    @media (min-width: @eurojackpot-desktop-width) {
      box-sizing: border-box;
    }

    .numbers {
      margin-bottom: 4rem;
      border-bottom: .1rem solid @eurojackpot-mediumgray;

      .number-container {
        width: 100%;

        @media (min-width: @eurojackpot-desktop-width) {
          max-width: 44.5rem;
          display: inline-block;
          margin-right: 3rem;
          vertical-align: top;

          &:nth-child(2) {
            margin-right: 0;
            margin-left: 3rem;
          }
        }

        .numbers-headline {
          max-width: 86.7vw;
          color: #fff;
          margin: 5.5vw auto 0;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 3rem;
            line-height: 3.6rem;
            text-transform: none;
            margin: 3rem auto 1.5rem;
          }
        }

        ul, li {
          margin: 0;
          padding: 0;
        }

        ul {
          border-top: .1rem solid @eurojackpot-mediumgray;
          list-style-type: none;
          padding: 2.5rem 0 1.7rem;
        }

        li {
          display: inline-block;
          margin: 0 2.7vw;
          width: 10.5vw;
          height: 10.5vw;
          border-radius: 100%;
          font-size: 3.5vw;
          color: @eurojackpot-white;
          line-height: 3.2;
          text-align: center;
          position: relative;

          @media (min-width: @eurojackpot-mobile-width) {
            width: 5.5rem;
            height: 5.5rem;
            font-size: 1.8rem;
            margin: 0 1.2rem;
          }

          .number-icon {
            fill: @eurojackpot-white;
            position: absolute;
            width: 11.2vw;
            height: 11.2vw;
            transform: translate(-50%,-50%);
            top: 50%;
            left: 50%;

            &.star {
              width: 12.4vw;
              height: 12.4vw;
            }

            &.joker {
              width: 60%;
              height: 60%;
              transform: translate(-50%,-50%);
              top: 50%;
              left: 50%;
            }

            @media (min-width: @eurojackpot-mobile-width) {
              width: 5.4rem;
              height: 5.4rem;

              &.star {
                width: 6rem;
                height: 6rem;
              }
            }
          }

          span {
            position: absolute;
            z-index: 1;
            display: block;
            width: inherit;
            height: inherit;
          }
        }
      }
    }

    .results {
      table, th, td {
        border: 0.1rem solid @eurojackpot-white;
      }

      table {
        table-layout: fixed;
        border-bottom: 0;
        border-left: 0;
        border-collapse: inherit;
        border-radius: 3px;
        width: 100%;
        overflow: hidden;

        @media (min-width: @eurojackpot-desktop-width) {
          width: 45rem;
          margin: auto;
          padding-bottom: 0;
        }


        td, th {
          border-top: 0;
          border-right: 0;
          padding: 3.5vw;
          font-size: 3.2vw;
          color: @eurojackpot-black;

          @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
            font-size: 1.8rem;
          }

          @media (min-width: @eurojackpot-desktop-width) {
            padding: 1.4rem;
            font-size: 1.5rem;
          }
        }

        th {
          font-weight: 400;
        }

        tr {
          td {
            border-left: 1px solid @eurojackpot-black;
          }

          &:nth-child(odd) {
            background-color: @eurojackpot-white;
          }

          &:nth-child(even) {
            background-color: @eurojackpot-whitesmoke;
          }
        }

        thead {
          th {
            font-weight: bold;
            font-size: 3.7vw;
            color: @eurojackpot-white;
            background-color: @eurojackpot-gray;
            padding: 4vw;

            @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
              padding: 3rem 2rem;
              font-size: 2rem;
            }

            @media (min-width: @eurojackpot-desktop-width) {
              padding: 1.9rem;
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    &.eurojackpot-winning-numbers {
      .logo {
        @media (min-width: @eurojackpot-desktop-width) {
          margin-top: 6rem;
        }
      }
    }


    &.joker-winning-numbers {
      .numbers {
        .number-container {
          margin-right: 0;

          @media (min-width: @eurojackpot-desktop-width) {
            max-width: 55.3rem;
          }

          ul {
            border-top: none;

            @media (min-width: @eurojackpot-desktop-width) {
              padding-top: 1rem;
            }

            li {
              background-color: #c50005;
              background-image: linear-gradient(45deg, #009a60 25%, transparent 25%, transparent 75%, #009a60 75%, #009a60), linear-gradient(45deg, #009a60 25%, transparent 25%, transparent 75%, #009a60 75%, #009a60);
              background-size: 30% 30%;
              background-position: 0 20%, 20% 0;

              span {
                color: @eurojackpot-almost-black;
              }
            }
          }
        }
      }
    }
  }

  .eurojackpot-content-wrapper {
    padding: 5vh 0;
    box-sizing: border-box;
    min-height: 50vh; // This is a hack, to ensure there is always space for the dropdown
    .hidden {
      display: none !important;
    }
  }

  .select-date-section {
    margin: 2rem auto 1rem;
  }

  .featured-winners {
    font-size: 3.2vw;
    border: 0.1rem solid @eurojackpot-white;
    border-radius: 3px;
    color: @eurojackpot-white;
    margin: 1rem auto;

    @media (min-width: @eurojackpot-mobile-width) and (max-width: @eurojackpot-desktop-width) {
      font-size: 1.8rem;
    }

    @media (min-width: @eurojackpot-desktop-width) {
      width: 45rem;
      font-size: 1.5rem;
    }

    .header {
      text-align: left;
      font-weight: 600;
      padding: 2vw 2rem;
      color: @eurojackpot-white;
      border-bottom: 1px solid @eurojackpot-lightgray;
      position: relative;

      @media (min-width: @eurojackpot-mobile-width) {
        padding: 1.9rem;
      }

      .icon-arrow {
        position: absolute;
        width: 3.2vw;
        height: 3.2vw;
        right: 2rem;
        top: 2.5vw;
        fill: #fff;

        @media (min-width: @eurojackpot-mobile-width) {
          top: 2.2rem;
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .text {
      text-align: left;
      padding: 2vw 2rem;
      line-height: 5vw;
      font-style: italic;

      @media (min-width: @eurojackpot-mobile-width) {
        line-height: 3rem;
        padding: 1rem 2rem;
      }

      p {
        margin: 0;
      }
    }

    &.hide {
      .header {
        border-bottom: none;

        .icon-arrow {
          transform: rotate(180deg);
        }
      }

      .header:after {
        display: none;
      }

      .text {
        display: none;
      }
    }
  }
}
