﻿@desktop-width: 960px;

html {
  // base font size: 1em = 10px
  // CAUTION: changing this, will effect
  // font-sizes on the entire site.
  font-size: 62.5%;

  // Fix for Windows default 125% zoom level
  @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @desktop-width) {
    font-size: 50%;
  }

  @media screen and (min-resolution: 2dppx) and (min-device-width: @desktop-width) { // This was not on Keno
    font-size: 62.5%;
  }

  // removing pesking touch highlighting
  -webkit-tap-highlight-color: transparent;
  // lets our fonts be all they can be!
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

html, body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  height: auto;
  overflow-x: clip;

  &.mode-edit {
    height: auto;
    overflow-y: auto;
  }

  &.lock {
    // MS IE11 & FIREFOX HACK TO ENSURE THAT IT SHOWS PROPERLY
    overflow: clip;
  }
}

// We use .page for scrolling instead of
// body to get consitent behavior on iphone
.page {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;

  .mode-edit & {
    height: auto;
    padding-top: 0 !important;
  }

  &.lock {
    overflow: clip;
  }
}

.page-box {
  // Must be relative to get correct offset coordinates via script/jQuery
  position: relative;
}

.numbergames-pageeditplaceholder {
  height: 10rem;
  background-color: crimson;
  text-align: center;
  font-size: 3.5rem;
  padding-top: 3rem;
}
