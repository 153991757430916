.btn-with-x-padding {
  padding-left: 2rem;
}
@keyframes btn-pulse-inner-loading-animation {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  95%,
  100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0);
  }
}
@keyframes btn-pulse-loading-animation {
  38% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  65%,
  100% {
    transform: scale(1);
  }
}
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.lottie-top-spot {
  color: var(--text-color, white);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  z-index: 1;
}
.lottie-top-spot .responsive-bg {
  background-color: var(--background-color, #004b32);
  position: absolute;
  z-index: -2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.lottie-top-spot .responsive-bg picture {
  width: 100%;
  height: 100%;
}
.lottie-top-spot .responsive-bg picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.lottie-top-spot__content-wrapper {
  text-align: center;
  padding: 4rem 0 5rem;
}
.lottie-top-spot__header {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 3rem;
}
.lottie-top-spot__logo:not(:empty) {
  padding: 0 2rem;
}
.lottie-top-spot__logo:not(:empty) img {
  max-width: 100%;
  height: auto;
}
.lottie-top-spot__description {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 700;
  white-space: break-spaces;
  margin-top: 3rem;
}
.lottie-top-spot__cta {
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: #d4d4d4;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  min-width: 18rem;
  height: 5.6rem;
  color: #000000;
  transition: color 200ms ease-out;
  display: inline-flex;
  margin-top: 1.8rem;
}
.lottie-top-spot__cta::after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
@media screen and (min-width: 375px) {
  .lottie-top-spot__cta {
    min-width: 21.4rem;
  }
}
.lottie-top-spot__cta::after {
  background-color: #FEB700;
  border-color: #FEB700;
}
.lottie-top-spot__cta:not(.btn--disabled):not(.btn--loading):active::after {
  background-color: #f5da00;
  border-color: #f5da00;
}
.lottie-top-spot__cta.btn--disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.lottie-top-spot__cta.btn--loading {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.lottie-top-spot__cta.btn--loading::before {
  content: "";
  border-radius: 2.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 0 0 #ffce51;
  animation: btn-pulse-inner-loading-animation 1.3s infinite;
}
.lottie-top-spot__cta.btn--loading::after {
  animation: btn-pulse-loading-animation 1.3s infinite;
}
.lottie-top-spot__cta:not(.btn--disabled):hover::after {
  transform: scale(1.03);
}
.lottie-top-spot__lottie {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.lottie-top-spot__lottie .lottie-top-spot__lottie-inner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.lottie-top-spot__lottie .lottie-top-spot__lottie-player {
  position: absolute;
  top: 0;
  width: 500rem;
  height: 100%;
}
.lottie-top-spot--as-banner {
  max-width: 1280px;
  width: calc(96%);
  margin: auto;
  overflow: hidden;
  padding: 2rem 0;
}
.lottie-top-spot--as-banner .responsive-bg {
  border-radius: 40px;
  overflow: hidden;
  top: 2rem;
  bottom: 2rem;
}
.lottie-top-spot--as-banner .lottie-top-spot__content {
  max-width: 100%;
  padding: 0 2.4rem;
}
.lottie-top-spot--as-banner .lottie-top-spot__content-wrapper {
  padding: 5.6rem 0;
}
.lottie-top-spot--as-banner .lottie-top-spot__header {
  margin-bottom: 2.4rem;
}
.lottie-top-spot--as-banner .lottie-top-spot__description {
  font-size: 10rem;
  line-height: 1;
  font-weight: 800;
  margin-top: 0;
}
.lottie-top-spot--as-banner .lottie-top-spot__cta {
  margin-top: 4rem;
}
