﻿@import url("../../Mixins/_mixins");

.winning-numbers-date-picker {
  width: 100%;
  max-width: 31.4rem;
  margin: 0 auto 4.5rem;
  border: 0.1rem solid @ds-gray-100;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: @ds-black;
  letter-spacing: 0.1rem;

  @media @xxl {
    margin-bottom: auto;
  }
}

.winning-numbers-date-picker__slip {
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: @ds-gray-100;
  border-radius: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: normal;
  width: ~"calc(100% - 0.4rem)";
  padding: 0.3rem;
  margin: -0.1rem;

  @media @lg {
    pointer-events: none;
    padding: 0.7rem;
    width: ~"calc(100% - 1.2rem)";
  }
}

.winning-numbers-date-picker__slip-week {
  position: relative;
  background-color: @ds-white;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.winning-numbers-date-picker__slip-week-label,
.winning-numbers-date-picker__slip-week-year {
  position: absolute;
  top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.winning-numbers-date-picker__slip-week-year {
  top: auto;
  bottom: 0.5rem;
  color: @numbergames-blue-grey;
}

.winning-numbers-date-picker__slip-week-week {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0.2rem;
}

.winning-numbers-date-picker__slip-date {
  text-transform: uppercase;
  flex: 1;
  padding-left: 1.6rem;
}

.winning-numbers-date-picker__slip-date-day {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}

.winning-numbers-date-picker__slip-date-date {
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 1.2;
  white-space: nowrap;
}

.winning-numbers-date-picker__slip-toggle {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 5rem;
  height: 5rem;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 1.7rem;
    left: 2rem;
    height: 1rem;
    width: 1rem;
    border: 0.2rem solid @ds-black;
    border-left-width: 0;
    border-bottom-width: 0;
    transform: rotate(135deg);
    transform-origin: center;

    .winning-numbers-date-picker--open & {
      transform: rotate(315deg);
      top: 2.3rem;
    }
  }

  @media @lg {
    display: none;
  }
}

.winning-numbers-date-picker__calendar {
  line-height: 1.2;
  display: none;
  padding: 0 0 2rem;

  .winning-numbers-date-picker--open & {
    display: block;
  }

  @media @lg {
    display: block;
    padding: 0 0 2.5rem;
  }
}

.winning-numbers-date-picker__toggle {
  margin: 1.3rem auto 0;
  width: 100%;
  max-width: 25rem;
  font-size: 1.6rem;
  line-height: 0.9;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media @sm {
    margin-bottom: 0.8rem;
  }
}

.winning-numbers-date-picker__toggle-prev,
.winning-numbers-date-picker__toggle-next {
  cursor: pointer;
  position: relative;
  width: 5rem;
  height: 5rem;

  &::after {
    content: "";
    position: absolute;
    top: 2rem;
    left: 2rem;
    height: 0.8rem;
    width: 0.8rem;
    border: 0.2rem solid @ds-black;
    border-left-width: 0;
    border-bottom-width: 0;
    transform: rotate(-135deg);
    transition: border-color 200ms ease-out;
  }

  &--disabled {
    pointer-events: none;

    &::after {
      border-color: @ds-gray-300;
    }
  }
}

.winning-numbers-date-picker__toggle-next::after {
  transform: rotate(45deg);
}

.winning-numbers-date-picker__headers,
.winning-numbers-date-picker__days {
  line-height: 0.9;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 27.2rem;
  color: @numbergames-blue-grey;
}

.winning-numbers-date-picker__header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 3.2rem;
  height: 3.2rem;
  font-size: 1.2rem;

  &:nth-child(8n + 1) {
    margin-right: 1.5rem;
  }
}

.winning-numbers-date-picker__day {
  position: relative;
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 3.2rem;
  height: 3.2rem;
  font-size: 1.4rem;
  font-weight: 600;

  &:nth-child(8n + 1) {
    margin-right: 1.5rem;
  }

  &--current-week {
    font-weight: 800;
    color: @ds-black;
  }

  &--current-month {
    color: @ds-black;
  }

  &--draw {
    pointer-events: all;
    cursor: pointer;
    font-weight: 800;

    .winning-numbers-date-picker--lotto & {
      color: @ds-red;
    }

    .winning-numbers-date-picker--vikinglotto & {
      color: @ds-blue;
    }

    .winning-numbers-date-picker--eurojackpot & {
      color: @eurojackpot-gold-medium;
    }

    .winning-numbers-date-picker--joker & {
      color: @ds-black;
    }
  }

  &--selected {
    position: relative;
    font-weight: 800;

    .winning-numbers-date-picker--lotto &,
    .winning-numbers-date-picker--vikinglotto &,
    .winning-numbers-date-picker--eurojackpot &,
    .winning-numbers-date-picker--joker & {
      color: @ds-white;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      pointer-events: none;
      top: -0.2rem;
      bottom: -0.2rem;
      left: -0.2rem;
      right: -0.2rem;
      border-radius: 100%;

      .winning-numbers-date-picker--lotto & {
        background-color: @ds-red;
      }

      .winning-numbers-date-picker--vikinglotto & {
        background-color: @ds-blue;
      }

      .winning-numbers-date-picker--eurojackpot & {
        background-color: @eurojackpot-gold-medium;
      }

      .winning-numbers-date-picker--joker & {
        background-color: @ds-lightgreen;
      }
    }
  }
}
