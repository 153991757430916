// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.win-message-container {
  background-color: @eurojackpot-black;
  padding: 4vw 14.13vw 3vw;

  @media (min-width: @eurojackpot-desktop-width) {
    padding: 3rem;
  }

  .receive-winner-message-icon {
    width: 10vw;
    height: 10vw;
    margin: 0 auto 2.5vw;

    @media (min-width: @eurojackpot-desktop-width) {
      width: 6.4rem;
      height: 6.4rem;
      padding: 0 0 1.5rem 0;
      margin: 0 auto;
    }
  }

  .notice-step {
    color: #FFF;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .headline {
      width: 100%;
      max-width: 86.5vw;
      font-size: 5.5vw;
      line-height: normal;
      margin: 0 auto;

      @media (min-width: @eurojackpot-desktop-width) {
        font-size: 3rem;
        line-height: normal;
      }
    }

    .subheadline {
      width: 100%;
      max-width: 60vw;
      font-size: 4.27vw;
      padding: 1rem 0;
      margin: 0 auto;

      @media (min-width: @eurojackpot-desktop-width) {
        max-width: 100%;
        font-size: 2.2rem;
        line-height: normal;
      }
    }

    .checkbox-container {

      @media (min-width: @eurojackpot-desktop-width) {
        display: flex;
      }

      .eurojackpot-checkbox {
        display: flex;
        margin: 3.34vw 0;

        @media (min-width: @eurojackpot-desktop-width) {
          margin: 2rem 2rem 0rem;
        }

        label {
          position: relative;

          &:not(.ios-skin) {
            position: relative;
            padding: 0.2vw 0 0 3.33vw;

            @media (min-width: @eurojackpot-desktop-width) {
              padding: 0 0 0 2rem;
            }
          }

          & .media-text {
            width: auto;
            font-size: 4.27vw;
            color: @eurojackpot-white;
            line-height: 1.1;
            margin: 0;

            @media (min-width: @eurojackpot-desktop-width) {
              font-size: 1.8rem;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}
