@import (reference) "../../../Domain/AvalonComponents/Styles/_imports";

.responsive-bg-fn() {
  &-top-center {
    justify-content: center;
    align-items: flex-start;
  }

  &-bottom-center {
    justify-content: center;
    align-items: flex-end;
  }

  &-center-left {
    justify-content: flex-start;
    align-items: center;
  }

  &-center-right {
    justify-content: flex-end;
    align-items: center;
  }

  &-center {
    justify-content: center;
    align-items: center;
  }

  &-cover {
    justify-content: center;
    align-items: center;

    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
}

.responsive-bg {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  @media (max-width: (@sm-breakpoint - 1px)) {
    &--mobile {
      .responsive-bg-fn();
    }
  }

  @media (min-width: @sm-breakpoint) and (max-width: (@lg-breakpoint - 1px)) {
    &--sm {
      .responsive-bg-fn();
    }
  }

  @media (min-width: @lg-breakpoint) and (max-width: (@xxl-breakpoint - 1px)) {
    &--lg {
      .responsive-bg-fn();
    }
  }

  @media (min-width: @xxl-breakpoint) {
    &--xxl {
      .responsive-bg-fn();
    }
  }

  picture {
    line-height: 0;
  }
}

.responsive-lottie-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .responsive-lottie-bg__lottie-player {
    position: absolute;
    top: 0;
    width: 500rem;
    height: 100%;
  }
}