/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper {
  max-height: 9vw;
  text-align: center;
  overflow: hidden;
  font-size: 0;
  opacity: 1;
  padding: 3.2vw 0;
  transition: max-height 0.15s linear, opacity 0.15s linear, padding 0.15s linear, background-color 0.15s linear;
  will-change: max-height;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper {
    max-height: 6rem;
    padding: 1rem 0;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper.hide {
  max-height: 0;
  opacity: 0;
  padding: 0;
}
.statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper {
  display: inline-block;
  border-radius: 6px;
  border: 1px solid;
  overflow: hidden;
}
.statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper {
  font-size: 3.2vw;
  padding: 2vw 3.2vw;
  display: inline-block;
  border-right: 1px solid;
  transition: background-color 0.15s linear, border-color 0.15s linear;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper {
    font-size: 1.4rem;
    padding: .9rem 1rem 1rem;
  }
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper:hover {
    cursor: pointer;
  }
}
.statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper:last-child {
  border-right: none;
}
@media (min-device-width: 960px) {
  .statistics-module .statistics-module-wrapper .statistics-module-numbers-type-switch-wrapper .numbers-type-switch-inner-wrapper .numbers-type-switch-button-wrapper.active:hover {
    cursor: default;
  }
}
