@import (reference) "../../Mixins/_mixins.less";

.multi-packages-custom-package-flow__inner-section {
  margin: auto;
  text-align: center;
  padding: 2.4rem 0;

  @media @sm {
    padding: 3rem 2rem;
  }

  .multi-packages-package-card__outer {
    display: inline-block;
    margin: auto;
  }

  .multi-packages-package-card {
    width: 32.6rem;
    overflow: clip;
  }

  .multi-packages-package-card__footer {
    background-color: @ds-gray-100;
    background: linear-gradient(0deg, @ds-gray-100 0%, @ds-gray-100 80%, fade(@ds-gray-100, 0) 100%);
  }
}

.multi-packages-custom-package-flow__inner-section-heading {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 700;
  color: @ds-black;
  margin-bottom: 2.4rem;
  @media @sm {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.multi-packages-custom-package-flow__inner-section-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: @ds-gray-450;
  margin-bottom: 2.4rem;
  @media @sm {
    font-size: 1.6rem;
  }
}

.multi-packages-custom-package-flow__joker-icon {
  width: 10.4rem;
  height: 5rem;
  margin-bottom: 2.2rem;
}

.multi-packages-custom-package-flow__back-button {
  position: absolute;
  top: 2rem;
  left: 2.4rem;
  padding: 1rem;
  cursor: pointer;
}

.multi-packages-custom-package-flow__back-button-icon {
  width: 2.4rem;
  height: 1.2rem;
}

.custom-package__games-options {
  display: flex;
  gap: 1.4rem;
  flex-direction: column;
}

.custom-package__games-options-item {
  background-color: @ds-white;
  padding: 0.8rem;
  border-radius: 16px;
  display: flex;
  gap: 1.6rem;
  text-align: left;

  .multi-packages-packages-list & {
    cursor: pointer;
  }
}

.custom-package__games-options-item--disabled {
  background-color: @ds-gray-100;
  border: 1px dashed @ds-gray-175;
  cursor: not-allowed;
}

.custom-package__games-options-item-badge {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 16px;
}

.custom-package__games-options-item-details {
  padding-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-right: auto;
}

.multi-packages-coupon-card__actions-buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: @ds-white;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 2.2rem;

  &::before {
    content: "";
    height: 1.6rem;
    width: 100%;
    position: absolute;
    top: -1.6rem;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, @ds-white 100%);
  }

  .multi-packages-coupon-card__action-button {
    margin: 0;
  }
}

.multi-packages-package-card__coupon-card:hover .multi-packages-coupon-card__actions-buttons {
  transform: translateY(0);
}

.multi-packages-coupon-card__action-button {
  border-radius: 50%;
  background-color: @ds-yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;

  &:hover {
    .multi-packages-coupon-card__action-button-icon {
      transform: scale(1.2);
    }
  }

  .multi-packages-coupon-card__action-button-icon {
    width: 1.8rem;
    height: 1.8rem;
    transition: transform 0.15s ease-in-out;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center, center;
  }
}

.custom-package__games-options-game-logo {
  &--lotto {
    width: 7.5rem;
    height: 1.6rem;
  }
  &--eurojackpot {
    width: 9.2rem;
    height: 1.4rem;
  }
  &--vikinglotto {
    width: 11rem;
    height: 2rem;
    margin-bottom: -0.3rem;
  }
}

.custom-package__games-options-game-heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}

.custom-package__games-options-game-subheading {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.6rem;
  text-transform: uppercase;
}

.custom-package-flow__joker-selection {
  max-width: 72rem;
  margin: auto;
  display: grid;
  grid-gap: 1.2rem;
  margin-bottom: 2.4rem;
  padding: 0 2.4rem;
  @media @sm {
    padding: 0;
    grid-gap: 2.4rem;
    grid-template-columns: 50% 50%;
  }

  @green-haze: #009a64;
  .check-button {
    background-color: fade(@green-haze, 10%);
    color: @green-haze;

    .check-button__dot {
      border-color: fade(@green-haze, 10%);
    }

    &::before {
      background-color: @green-haze;
    }

    .check-button__dot::after {
      background-color: @green-haze;
    }

    &.check-button--selected {
      color: @ds-white;

      .check-button__dot {
        border-color: fade(@green-haze, 50%);
      }
    }
  }
}

.custom-package-flow__footer .multi-packages-button {
  width: fit-content;
  min-width: 24rem;
  margin: auto;
}