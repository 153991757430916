/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.winning-numbers-date-picker {
  width: 100%;
  max-width: 31.4rem;
  margin: 0 auto 4.5rem;
  border: 0.1rem solid #eeeeee;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  letter-spacing: 0.1rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-date-picker {
    margin-bottom: auto;
  }
}
.winning-numbers-date-picker__slip {
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: #eeeeee;
  border-radius: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: normal;
  width: calc(100% - 0.4rem);
  padding: 0.3rem;
  margin: -0.1rem;
}
@media screen and (min-width: 1024px) {
  .winning-numbers-date-picker__slip {
    pointer-events: none;
    padding: 0.7rem;
    width: calc(100% - 1.2rem);
  }
}
.winning-numbers-date-picker__slip-week {
  position: relative;
  background-color: white;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.winning-numbers-date-picker__slip-week-label,
.winning-numbers-date-picker__slip-week-year {
  position: absolute;
  top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}
.winning-numbers-date-picker__slip-week-year {
  top: auto;
  bottom: 0.5rem;
  color: #92969E;
}
.winning-numbers-date-picker__slip-week-week {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0.2rem;
}
.winning-numbers-date-picker__slip-date {
  text-transform: uppercase;
  flex: 1;
  padding-left: 1.6rem;
}
.winning-numbers-date-picker__slip-date-day {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}
.winning-numbers-date-picker__slip-date-date {
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 1.2;
  white-space: nowrap;
}
.winning-numbers-date-picker__slip-toggle {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 5rem;
  height: 5rem;
}
.winning-numbers-date-picker__slip-toggle::after {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 1.7rem;
  left: 2rem;
  height: 1rem;
  width: 1rem;
  border: 0.2rem solid #000000;
  border-left-width: 0;
  border-bottom-width: 0;
  transform: rotate(135deg);
  transform-origin: center;
}
.winning-numbers-date-picker--open .winning-numbers-date-picker__slip-toggle::after {
  transform: rotate(315deg);
  top: 2.3rem;
}
@media screen and (min-width: 1024px) {
  .winning-numbers-date-picker__slip-toggle {
    display: none;
  }
}
.winning-numbers-date-picker__calendar {
  line-height: 1.2;
  display: none;
  padding: 0 0 2rem;
}
.winning-numbers-date-picker--open .winning-numbers-date-picker__calendar {
  display: block;
}
@media screen and (min-width: 1024px) {
  .winning-numbers-date-picker__calendar {
    display: block;
    padding: 0 0 2.5rem;
  }
}
.winning-numbers-date-picker__toggle {
  margin: 1.3rem auto 0;
  width: 100%;
  max-width: 25rem;
  font-size: 1.6rem;
  line-height: 0.9;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .winning-numbers-date-picker__toggle {
    margin-bottom: 0.8rem;
  }
}
.winning-numbers-date-picker__toggle-prev,
.winning-numbers-date-picker__toggle-next {
  cursor: pointer;
  position: relative;
  width: 5rem;
  height: 5rem;
}
.winning-numbers-date-picker__toggle-prev::after,
.winning-numbers-date-picker__toggle-next::after {
  content: "";
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 0.8rem;
  width: 0.8rem;
  border: 0.2rem solid #000000;
  border-left-width: 0;
  border-bottom-width: 0;
  transform: rotate(-135deg);
  transition: border-color 200ms ease-out;
}
.winning-numbers-date-picker__toggle-prev--disabled,
.winning-numbers-date-picker__toggle-next--disabled {
  pointer-events: none;
}
.winning-numbers-date-picker__toggle-prev--disabled::after,
.winning-numbers-date-picker__toggle-next--disabled::after {
  border-color: #bbbbbb;
}
.winning-numbers-date-picker__toggle-next::after {
  transform: rotate(45deg);
}
.winning-numbers-date-picker__headers,
.winning-numbers-date-picker__days {
  line-height: 0.9;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 27.2rem;
  color: #92969E;
}
.winning-numbers-date-picker__header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 3.2rem;
  height: 3.2rem;
  font-size: 1.2rem;
}
.winning-numbers-date-picker__header:nth-child(8n + 1) {
  margin-right: 1.5rem;
}
.winning-numbers-date-picker__day {
  position: relative;
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 3.2rem;
  height: 3.2rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.winning-numbers-date-picker__day:nth-child(8n + 1) {
  margin-right: 1.5rem;
}
.winning-numbers-date-picker__day--current-week {
  font-weight: 800;
  color: #000000;
}
.winning-numbers-date-picker__day--current-month {
  color: #000000;
}
.winning-numbers-date-picker__day--draw {
  pointer-events: all;
  cursor: pointer;
  font-weight: 800;
}
.winning-numbers-date-picker--lotto .winning-numbers-date-picker__day--draw {
  color: #c50005;
}
.winning-numbers-date-picker--vikinglotto .winning-numbers-date-picker__day--draw {
  color: #0000ff;
}
.winning-numbers-date-picker--eurojackpot .winning-numbers-date-picker__day--draw {
  color: #E8A332;
}
.winning-numbers-date-picker--joker .winning-numbers-date-picker__day--draw {
  color: #000000;
}
.winning-numbers-date-picker__day--selected {
  position: relative;
  font-weight: 800;
}
.winning-numbers-date-picker--lotto .winning-numbers-date-picker__day--selected,
.winning-numbers-date-picker--vikinglotto .winning-numbers-date-picker__day--selected,
.winning-numbers-date-picker--eurojackpot .winning-numbers-date-picker__day--selected,
.winning-numbers-date-picker--joker .winning-numbers-date-picker__day--selected {
  color: white;
}
.winning-numbers-date-picker__day--selected::after {
  content: "";
  position: absolute;
  z-index: -1;
  pointer-events: none;
  top: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  right: -0.2rem;
  border-radius: 100%;
}
.winning-numbers-date-picker--lotto .winning-numbers-date-picker__day--selected::after {
  background-color: #c50005;
}
.winning-numbers-date-picker--vikinglotto .winning-numbers-date-picker__day--selected::after {
  background-color: #0000ff;
}
.winning-numbers-date-picker--eurojackpot .winning-numbers-date-picker__day--selected::after {
  background-color: #E8A332;
}
.winning-numbers-date-picker--joker .winning-numbers-date-picker__day--selected::after {
  background-color: #009a60;
}
