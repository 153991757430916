@import url("../Mixins/_mixins.less");

.eurojackpot-top-spot {

  .eurojackpot-section {
    min-height: 55vw;
    position: relative;
    box-sizing: border-box;
    padding: 0;

    @media (min-width: @eurojackpot-desktop-width) {
      min-height: 40rem;
    }

    .eurojackpot-content-wrapper {
      padding: 5vw 0;

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 5rem 0;
      }
      // Position all other elements above background and video
      > * {
        position: relative;
        z-index: 2;
      }

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 2rem 0;
      }
    }

    #eurojackpot-winning-number-subheader {
      text-transform: none;
      margin: 0 auto 14vw;
      font-weight: 400;
      height: 0;
      font-size: 4.4vw;

      @media (min-width: @eurojackpot-desktop-width) {
        /*font-size: 16vw;
        margin: 4rem auto;*/
        margin: 0 auto 6rem;
        font-size: 3rem;
      }
    }

    .hd {
      font-size: 18vw;
      letter-spacing: .3rem;
      letter-spacing: .2rem;
      line-height: 1.2;
      margin: 2vw auto 0;

      @media (min-width: @eurojackpot-desktop-width) {
        height: 18rem;
        font-size: 11rem;
        word-spacing: normal;
        line-height: 7.2rem;
      }

      .small-text {
        font-size: 8vw;
        letter-spacing: .2rem;
        display: block;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 5rem;
          margin-bottom: 0;
        }
      }

      .big-text {
        font-size: 17.333vw;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 12rem;
          display: block;
          margin-top: 1.7rem;
        }

        .svg-text-wrapper {
          width: 100%;
          overflow: visible;
          position: relative;
          top: 4.5vw;

          @media (min-width: @eurojackpot-desktop-width) {
            top: .6rem;
          }
        }
      }

      &.winningnumbers {
        font-size: 12vw;
        height: unset;
        margin: 3vw auto;

        @media (min-width: @eurojackpot-desktop-width) {
          font-size: 8rem;
          margin: 3rem auto 4rem;
        }
      }
    }

    .btn {
      clear: both;
      margin-top: 2vw;

      @media (min-width: @eurojackpot-desktop-width) {
        margin-top: 3rem;
      }
    }

    .help-link {
      // changes to the underline of the hyperlink
      text-decoration: none;
      border-bottom: .1rem solid @eurojackpot-white;
      font-size: 3.7vw;
      line-height: 4.2vw;
      margin: 10.2vw 0 4.2vw;
      display: inline-block;

      &:hover {
        border-color: transparent;
      }

      @media (min-width: @eurojackpot-desktop-width) {
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 2.6rem;
        margin-top: 2.6rem;
      }
    }

    .eurojackpot-logo-wrapper {
      display: none;

      @media (min-width: @eurojackpot-desktop-width) {
        display: block;
      }
    }


    .eurojackpot-logo-wrapper-alt {
      display: block;

      @media (min-width: @eurojackpot-desktop-width) {
        display: block;
      }
    }
    // placeholder until image arrives
    .eurojackpot-logo {
      width: 33vw;
      margin: 0 auto 4vw;

      @media (min-width: @eurojackpot-desktop-width) {
        width: 12.6rem;
        margin: 0 auto 1rem;
      }

      img {
        width: 100%;
        height: auto;
      }

      &.logo-for-ugepakke {
        width: 26vw;
      }
    }
  }

  &.faq-top-spot {
    .eurojackpot-section {
      .eurojackpot-content-wrapper {
        padding: 15.2vw 0;

        @media (min-width: @eurojackpot-desktop-width) {
          padding: 9.5rem 0 4rem;
        }

        .eurojackpot-logo-wrapper-alt {
          .eurojackpot-logo {
            width: 13.6vw;

            @media (min-width: @eurojackpot-desktop-width) {
              width: 6.5rem;
            }
          }
        }

        .hd.winningnumbers {
          font-size: 8vw;
          margin: -4vw auto 5vw;
          line-height: 1;

          @media (min-width: @eurojackpot-desktop-width) {
            font-size: 5rem;
            margin: 0 auto 7.1rem;
          }

          .small-text {
            font-size: 4.266vw;
            margin-top: 3vw;

            @media (min-width: @eurojackpot-desktop-width) {
              font-size: 2.6rem;
              font-weight: 900;
              margin-top: 1rem;
            }
          }
        }

        #eurojackpot-winning-number-subheader {
          margin: 0 auto -1.5vw;
        }
      }
    }

    & + .informationswitcherspotview {
      padding: 0 0;

      @media (min-width: @eurojackpot-desktop-width) {
        padding: 3.5rem 0;
      }

      .informationswitcher-selector {
        margin-top: 9vw;

        @media (min-width: @eurojackpot-desktop-width) {
          margin-top: 0;
        }
      }
    }
  }
}
