.eurojackpot-confirm {
  background-color: #2E3535;
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header {
  background-color: #171717;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 13.33vw 0 8vw;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header {
    padding: 6.5rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .eurojackpot-video-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .eurojackpot-video {
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .draw-date {
  font-size: 3.47vw;
  color: #fff;
  position: relative;
  z-index: 1;
  padding-bottom: 0.5rem;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .draw-date {
    font-size: 1.8rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .confirm-headline {
  max-width: 75vw;
  font-size: 8vw;
  font-weight: 600;
  color: #fff;
  z-index: 1;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .confirm-headline {
    max-width: 83rem;
    font-size: 5rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .confirm-subheadline {
  font-size: 3.73vw;
  font-weight: normal;
  color: #fff;
  padding-top: .7rem;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .confirm-subheadline {
    font-size: 2.4rem;
    font-weight: normal;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container {
  display: block;
  padding-top: 2.4vw;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container {
    padding: 0rem 0 3.5rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container {
  margin-top: 1vw;
  font-style: italic;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container {
    display: inline-block;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container .text {
  width: auto;
  font-size: 4.27vw;
  line-height: 1;
  color: #fff;
  display: inline-block;
  font-weight: normal;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container .text {
    font-size: 2.2rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container .text.and {
    margin-left: 1rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container .text.joker-type {
  text-transform: uppercase;
  font-weight: 900;
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container svg.icon-joker_logo_vindertal {
  width: 28vw;
  height: 5.2vw;
  transform: translateY(0.6vw);
  margin: 0 1.5vw;
  fill: #fff;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .joker-container .joker-type-container svg.icon-joker_logo_vindertal {
    width: 15.3rem;
    height: 2.9rem;
    transform: translateY(0.5rem);
    margin: 0 1rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .alt-text {
  max-width: 86.5vw;
  font-size: 3.47vw;
  color: #fff;
  z-index: 1;
  padding: 3vw 0;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display > div.eurojackpot-confirm-page-header .alt-text {
    font-size: 2rem;
    padding: 1.5rem 0;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row {
  width: 100%;
  font-size: 4.266vw;
  background-color: #2E3535;
  padding: 5.4vw 6.666vw 5.135vw;
  position: relative;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row {
    font-size: 1.8rem;
    padding: 2.5rem 0 2.2rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row:nth-child(odd) {
  background-color: #111111;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row.hidden {
  display: none;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row.system .number-row-inner {
  width: auto;
  text-align: center;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row.system .number-row-inner .row-title {
  width: 100%;
  display: block;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row.system .number-row-inner .row-numbers {
  width: auto;
  line-height: 1.5;
  display: inline-block;
  padding-top: 1rem;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row.system .number-row-inner .plus-separator {
  width: auto;
  padding: 0 1rem;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row.system .number-row-inner .star-numbers {
  width: auto;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner {
  text-align: left;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner {
    width: 39.8rem;
    margin: auto;
    position: relative;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .row-title {
  width: 32%;
  font-size: 4vw;
  font-weight: normal;
  line-height: 1.3;
  color: #fff;
  display: inline-block;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .row-title {
    font-size: 1.8rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .row-numbers {
  width: 37%;
  font-size: 4vw;
  color: #fff;
  display: inline-block;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .row-numbers {
    font-size: 1.8rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .plus-separator {
  width: 6%;
  color: #fff;
  display: inline-block;
  padding: 0 0.9rem;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .star-numbers {
  color: #fff;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .delete-button {
  width: 8vw;
  height: 8vw;
  position: absolute;
  right: 4vw;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .delete-button {
    width: 1.2rem;
    height: 1.4rem;
    top: 50%;
    right: 0;
    cursor: pointer;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .delete-button.hidden {
  display: none;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .delete-button svg.icon-x {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  fill: #fff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .number-row .number-row-inner .delete-button svg.icon-x {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .bottom-gradient {
  width: 100%;
  height: 10.7vw;
  position: absolute;
  bottom: 0;
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 37%, #000000 65%, #000 100%);
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#FFF', GradientType=0);*/
  transition: opacity 0.5s;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .rows-container .bottom-gradient {
    height: 7.2rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .bottom-gradient.hidden {
  display: none;
}
.eurojackpot-confirm .eurojackpot-row-display .rows-container .bottom-gradient .show-more-button {
  text-transform: uppercase;
  color: #000;
  background-color: #feb700;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  margin: 0;
}
.eurojackpot-confirm .eurojackpot-row-display .terms-container {
  width: 100%;
  max-width: none;
  font-size: 3.47vw;
  background-color: #1f1f1f;
  color: #fff;
  padding: 5vw 0 7vw;
  margin: auto;
}
@media (min-width: 950px) {
  .eurojackpot-confirm .eurojackpot-row-display .terms-container {
    font-size: 1.8rem;
    padding: 2.5rem 0 3rem;
  }
}
.eurojackpot-confirm .eurojackpot-row-display .terms-container .terms-link {
  color: #fff;
  text-decoration: underline;
  display: block;
  padding-top: .5rem;
}
.eurojackpot-confirm .btn {
  margin: 6rem 1rem 2rem;
}
.eurojackpot-confirm .cta-deposit {
  margin: 2rem;
}
