﻿@import "_imports.less";

:root {
  --highlightBackground: @ds-gray-100;

  .region-vikinglotto, .region-avalon-vikinglotto {
    --highlightBackground: @ds-blue;
  }

  .region-allornothing, .region-avalon-allornothing {
    --highlightBackground: @all-or-nothing-dark-purple;
  }

  .region-eurojackpot, .region-avalon-eurojackpot {
    --highlightBackground: @ds-gray-950;
  }

  .region-keno, .region-avalon-keno {
    --highlightBackground: @ds-light-blue;
  }

  .region-lotto, .region-avalon-lotto {
    --highlightBackground: @ds-red;
  }

  .region-playtogether, .region-avalon-playtogether {
    --highlightBackground: @play-together-blue-700;
  }

  .region-quick, .region-avalon-quick {
    --highlightBackground: @ds-purple;
  }

  .region-subscription, .region-avalon-subscription {
    --highlightBackground: @color-dark-grey;
  }
}

.card-cta {
  background: @numbergames-white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 4rem;
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: @xxl-breakpoint;
}

.card-cta__wrapper {
  display: block;
  padding: 0 2rem;
  width: 100%;

  @media @sm {
    padding: 0 1.5rem;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  @media @xl {
    padding: 0 5rem;
    width: 100%;
    max-width: @xxl-breakpoint;
  }
}

.card-cta__highlight {
  color: @numbergames-white !important;
  background-color: var(--highlightBackground, @ds-gray-100) !important;
}

.card-cta__card {
  color: @numbergames-black;
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  flex-direction: column;
  background-color: @ds-gray-100;
  border-radius: 3rem;
  border-bottom: @ds-gray-175 0.4rem solid;
  border-right: @ds-gray-175 0.4rem solid;
  padding: 2.5rem 0;
  margin-bottom: 4rem;
  position: relative;

  @media @sm {
    width: 46%;
    margin: 1rem;
  }

  @media @xl {
    width: 0;
    flex: 1 1 0;
  }
}

@media @xl {
  .card-cta__card:first-child {
    margin-left: 0;
  }

  .card-cta__card:last-child {
    margin-right: 0;
  }
}

.card-cta__img {
  width: 12rem;
  height: 12rem;
  margin-left: auto;
  margin-right: auto;
}

.card-cta__small-header {
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.6rem;
  overflow-wrap: break-word;
  padding: 1rem 1.6rem 0;
  margin: 0;
  text-transform: uppercase;

  @media @sm {
    line-height: 1.6rem;
  }

  @media @xl {
    line-height: 1.6rem;
  }
}

.card-cta__text {
  line-height: 1.6rem;
  overflow-wrap: break-word;
  font-size: 1.2rem;
  padding: 0 1.6rem 2.2rem;
  margin: 0;
  font-weight: 600;

  @media @sm {
    padding: 0 1.6rem 2.2rem;
  }

  @media @md {
    margin-bottom: 0;
  }
}

.card-cta__large-header {
  font-size: 2rem;
  line-height: 2.7rem;
  margin: 0 0 0.5rem 0 !important;
  padding: 0 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
  overflow-wrap: break-word;

  @media @sm {
    padding: 0 1.6rem 0;
    font-size: 2rem !important;
    line-height: 2.7rem !important;
  }
}

.card-cta__cta {
  background: @numbergames-cta-yellow;
  color: @numbergames-black;
  border-radius: 2.5rem;
  font-size: 1.2rem;
  text-align: center;
  padding: 0;
  line-height: 4.3rem;
  padding-top: 0.1rem;
  font-weight: 800;
  display: inline-block;
  position: relative;
  outline: none;
  border: none;
  text-decoration: none;
  vertical-align: top;
  overflow: hidden;
  box-sizing: border-box;
  width: auto;
  text-transform: uppercase;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: 0.2s ease-out;
  margin: auto 1.6rem 0;
}

.card-cta__cta:hover {
  transition: 0.2s ease-in 0.05s;
  margin: auto 1.2rem 0;
  cursor: pointer;
}

.card-cta__cta:active,
.card-cta__cta:focus {
  background: @numbergames-cta-yellow-hover;
}

// Top left corner label for card-cta

.card-cta__label {
  width: 12.8rem;
  height: 8.7rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.card-cta__label-text {
  font-weight: 800;
  font-size: 1.2rem;
  color: @numbergames-white;
  text-align: center;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  position: relative;
  padding: 0.6rem 0;
  left: -5.2rem;
  top: 1.2rem;
  width: 17.4rem;
  background-color: @ds-red-100;
}
