.eurojackpot-classic-game .eurojackpot-section,
.eurojackpot-system-game .eurojackpot-section,
.eurojackpot-lucky-game .eurojackpot-section,
.eurojackpot-winning-numbers .eurojackpot-section {
  background-color: white;
}
.eurojackpot-classic-game .number-picker-footer,
.eurojackpot-system-game .number-picker-footer,
.eurojackpot-lucky-game .number-picker-footer,
.eurojackpot-winning-numbers .number-picker-footer {
  margin-top: 1.3vw;
  padding: 0 1.9% 0;
  position: relative;
}
.eurojackpot-classic-game .number-picker-footer:after,
.eurojackpot-system-game .number-picker-footer:after,
.eurojackpot-lucky-game .number-picker-footer:after,
.eurojackpot-winning-numbers .number-picker-footer:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer,
  .eurojackpot-system-game .number-picker-footer,
  .eurojackpot-lucky-game .number-picker-footer,
  .eurojackpot-winning-numbers .number-picker-footer {
    margin-top: 1.6rem;
    padding: 0 1rem 0;
    text-align: center;
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container,
.eurojackpot-system-game .number-picker-footer .buttons-container,
.eurojackpot-lucky-game .number-picker-footer .buttons-container,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container {
  padding: 4.034vw 0 2.134vw;
  border-top: 0.2rem solid #616161;
  white-space: nowrap;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container,
  .eurojackpot-system-game .number-picker-footer .buttons-container,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container {
    padding: 2.9rem 0 1.1rem;
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button,
.eurojackpot-system-game .number-picker-footer .buttons-container .button,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button {
  min-width: 23.2vw;
  font-size: 3.734vw;
  line-height: 1.36;
  font-weight: bold;
  color: white;
  text-align: left;
  background-color: #2E3535;
  margin-left: 3.334vw;
  padding: 2.8vw 3.6vw 2.8vw;
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  transition: opacity 0.25s, background-color 0.25s, color 0.25s, transform 0.25s;
  user-select: none;
  overflow: hidden;
  will-change: transform;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .button:hover,
  .eurojackpot-system-game .number-picker-footer .buttons-container .button:hover,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .button:hover,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .button:hover {
    transform: scale(1.05);
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button:first-child,
.eurojackpot-system-game .number-picker-footer .buttons-container .button:first-child,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button:first-child,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button:first-child {
  margin-left: 0;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .button,
  .eurojackpot-system-game .number-picker-footer .buttons-container .button,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .button,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .button {
    font-size: 1.7rem;
    text-align: center;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .button,
  .eurojackpot-system-game .number-picker-footer .buttons-container .button,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .button,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .button {
    min-width: initial;
    min-width: inherit;
    font-size: 1.6rem;
    margin-left: 2rem;
    padding: 1.3rem 1.8rem 1.2rem 1.8rem;
    cursor: pointer;
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.inactive,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.inactive,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.inactive,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.inactive {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  cursor: default;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .button.inactive:hover,
  .eurojackpot-system-game .number-picker-footer .buttons-container .button.inactive:hover,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .button.inactive:hover,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.inactive:hover {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers {
  opacity: .3;
}
@keyframes eurojackpot-autogenerate-button-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:after {
  content: '';
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background: radial-gradient(center, ellipse farthest-corner, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 0) 20%, rgba(43, 43, 43, 0.65) 45%, rgba(43, 43, 43, 0.9) 55%, rgba(43, 43, 43, 0.5) 65%, rgba(43, 43, 43, 0) 90%, rgba(43, 43, 43, 0) 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:before {
  animation: eurojackpot-autogenerate-button-animation 0.8s linear infinite;
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:after {
  animation: eurojackpot-autogenerate-button-animation 0.8s 0.3s linear infinite;
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:hover {
  cursor: default;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
  .eurojackpot-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:hover {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .button.reset-row-button,
.eurojackpot-system-game .number-picker-footer .buttons-container .button.reset-row-button,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .button.reset-row-button,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.reset-row-button {
  float: right;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .button.reset-row-button,
  .eurojackpot-system-game .number-picker-footer .buttons-container .button.reset-row-button,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .button.reset-row-button,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .button.reset-row-button {
    float: none;
  }
}
.eurojackpot-classic-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.eurojackpot-system-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .autogenerate-button .icon,
.eurojackpot-classic-game .number-picker-footer .buttons-container .reset-row-button .icon,
.eurojackpot-system-game .number-picker-footer .buttons-container .reset-row-button .icon,
.eurojackpot-lucky-game .number-picker-footer .buttons-container .reset-row-button .icon,
.eurojackpot-winning-numbers .number-picker-footer .buttons-container .reset-row-button .icon {
  width: 2.934vw;
  height: 3.7vw;
  fill: #4f5254;
  position: absolute;
  top: 3.4vw;
  right: 3.7vw;
  -webkit-transition: fill 0.25s;
  -moz-transition: fill 0.25s;
  -ms-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
  overflow: visible;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .eurojackpot-system-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .autogenerate-button .icon,
  .eurojackpot-classic-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .eurojackpot-system-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .eurojackpot-lucky-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .eurojackpot-winning-numbers .number-picker-footer .buttons-container .reset-row-button .icon {
    width: 1.6rem;
    height: 1.6rem;
    top: 1.1rem;
    right: 1.4rem;
  }
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box,
.eurojackpot-system-game .number-picker-footer .autogenerate-box,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box {
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .autogenerate-button,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .autogenerate-button,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .autogenerate-button,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .autogenerate-button {
  position: relative;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options {
  width: 75.235vw;
  background-color: #fff;
  position: absolute;
  top: 16.4vw;
  left: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: none;
  z-index: 1;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options,
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options,
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options,
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options {
    width: 58rem;
    top: 8rem;
  }
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item {
  width: 50%;
  font-size: 3.2vw;
  font-weight: bold;
  color: #0891d8;
  text-align: center;
  float: left;
  padding: 4vw 0;
  border-width: 0 .2rem .2rem 0;
  border-style: solid;
  border-color: #cdd3d7;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item,
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item,
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item,
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item {
    width: 20%;
    font-size: 1.4rem;
    padding: 1.1rem 0 1rem;
    border-width: 0 .2rem .2rem 0;
    cursor: pointer;
  }
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:first-child:before {
  content: '';
  height: 3.4vw;
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  position: absolute;
  top: 0;
  left: 4vw;
  right: -1vw;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:first-child:before {
    height: 3.4rem;
    left: 1rem;
    right: 80%;
  }
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item.hidden,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item.hidden,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item.hidden,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item.hidden,
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item.hidden.inactive,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item.hidden.inactive,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item.hidden.inactive,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item.hidden.inactive {
  color: #fff;
  cursor: default;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-child(2n) {
  border-width: 0 0 .2rem 0;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:last-child,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:last-child,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:last-child,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:last-child {
  border-width: 0 0 0 0;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(2),
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(2),
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(2),
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-last-child(2) {
  border-width: 0 .2rem 0 0;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-child(2n) {
    border-width: 0 .2rem .2rem 0;
  }
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:nth-child(5n),
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:nth-child(5n),
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:nth-child(5n),
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-child(5n) {
    border-width: 0 0 .2rem 0;
  }
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(-n+5),
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(-n+5),
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(-n+5),
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-last-child(-n+5) {
    border-width: 0 .2rem 0 0;
  }
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item:last-child,
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item:last-child,
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item:last-child,
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item:last-child {
    border-width: 0 0 0 0;
  }
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item.inactive,
.eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item.inactive,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item.inactive,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item.inactive {
  color: #cdd3d7;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-footer .autogenerate-box .options .item.inactive,
  .eurojackpot-system-game .number-picker-footer .autogenerate-box .options .item.inactive,
  .eurojackpot-lucky-game .number-picker-footer .autogenerate-box .options .item.inactive,
  .eurojackpot-winning-numbers .number-picker-footer .autogenerate-box .options .item.inactive {
    cursor: default;
  }
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box.active .button:after,
.eurojackpot-system-game .number-picker-footer .autogenerate-box.active .button:after,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box.active .button:after,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box.active .button:after {
  display: block;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box.active .button .icon,
.eurojackpot-system-game .number-picker-footer .autogenerate-box.active .button .icon,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box.active .button .icon,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box.active .button .icon {
  fill: #fff;
}
.eurojackpot-classic-game .number-picker-footer .autogenerate-box.active .options,
.eurojackpot-system-game .number-picker-footer .autogenerate-box.active .options,
.eurojackpot-lucky-game .number-picker-footer .autogenerate-box.active .options,
.eurojackpot-winning-numbers .number-picker-footer .autogenerate-box.active .options {
  display: block;
}
@-webkit-keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes eurojackpot-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes eurojackpot-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.eurojackpot-classic-game .row-container.shake-state .header-rules-text,
.eurojackpot-system-game .row-container.shake-state .header-rules-text,
.eurojackpot-lucky-game .row-container.shake-state .header-rules-text,
.eurojackpot-winning-numbers .row-container.shake-state .header-rules-text,
.eurojackpot-classic-game .row-container.shake-star-numbers-state .header-rules-text,
.eurojackpot-system-game .row-container.shake-star-numbers-state .header-rules-text,
.eurojackpot-lucky-game .row-container.shake-star-numbers-state .header-rules-text,
.eurojackpot-winning-numbers .row-container.shake-star-numbers-state .header-rules-text {
  -webkit-animation: eurojackpot-rules-pulse-animation 0.8s forwards;
  -moz-animation: eurojackpot-rules-pulse-animation 0.8s forwards;
  -ms-animation: eurojackpot-rules-pulse-animation 0.8s forwards;
  animation: eurojackpot-rules-pulse-animation 0.8s forwards;
}
.eurojackpot-classic-game .row-container.shake-state .row-header .tab-game-rules-text,
.eurojackpot-system-game .row-container.shake-state .row-header .tab-game-rules-text,
.eurojackpot-lucky-game .row-container.shake-state .row-header .tab-game-rules-text,
.eurojackpot-winning-numbers .row-container.shake-state .row-header .tab-game-rules-text,
.eurojackpot-classic-game .row-container.shake-star-numbers-state .row-header .tab-game-rules-text,
.eurojackpot-system-game .row-container.shake-star-numbers-state .row-header .tab-game-rules-text,
.eurojackpot-lucky-game .row-container.shake-star-numbers-state .row-header .tab-game-rules-text,
.eurojackpot-winning-numbers .row-container.shake-star-numbers-state .row-header .tab-game-rules-text {
  display: inline-block;
  animation: eurojackpot-rules-pulse-animation 0.8s forwards;
}
.eurojackpot-classic-game .row-container.shake-state .cells-container .selected,
.eurojackpot-system-game .row-container.shake-state .cells-container .selected,
.eurojackpot-lucky-game .row-container.shake-state .cells-container .selected,
.eurojackpot-winning-numbers .row-container.shake-state .cells-container .selected {
  -webkit-animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
  -moz-animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
  -ms-animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
  animation: eurojackpot-number-cell-shake-animation 0.8s forwards;
}
.eurojackpot-classic-game .row-container.shake-star-numbers-state .row-stars-container .selected,
.eurojackpot-system-game .row-container.shake-star-numbers-state .row-stars-container .selected,
.eurojackpot-lucky-game .row-container.shake-star-numbers-state .row-stars-container .selected,
.eurojackpot-winning-numbers .row-container.shake-star-numbers-state .row-stars-container .selected {
  -webkit-animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
  -moz-animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
  -ms-animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
  animation: eurojackpot-number-cell-shake-animation 0.6s forwards;
}
.eurojackpot-classic-game .row-header,
.eurojackpot-system-game .row-header,
.eurojackpot-lucky-game .row-header,
.eurojackpot-winning-numbers .row-header {
  font-size: 3.734vw;
  line-height: 1.3;
  color: #000000;
  padding-bottom: 1.634vw;
  border-bottom: 0.2rem solid #616161;
  margin: 1.8vw 1.4vw 1.3vw;
}
.eurojackpot-classic-game .row-header:after,
.eurojackpot-system-game .row-header:after,
.eurojackpot-lucky-game .row-header:after,
.eurojackpot-winning-numbers .row-header:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header,
  .eurojackpot-system-game .row-header,
  .eurojackpot-lucky-game .row-header,
  .eurojackpot-winning-numbers .row-header {
    font-size: 1.8rem;
    padding-bottom: 2.2rem;
    margin: 2.3rem 1rem 2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .row-header,
  .eurojackpot-system-game .row-header,
  .eurojackpot-lucky-game .row-header,
  .eurojackpot-winning-numbers .row-header {
    font-size: 2rem;
    margin: 1.8vw 0 1.3vw;
  }
}
.eurojackpot-classic-game .row-header .counter,
.eurojackpot-system-game .row-header .counter,
.eurojackpot-lucky-game .row-header .counter,
.eurojackpot-winning-numbers .row-header .counter {
  width: 83%;
  text-align: center;
  float: left;
  opacity: .6;
  color: #959595;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .counter,
  .eurojackpot-system-game .row-header .counter,
  .eurojackpot-lucky-game .row-header .counter,
  .eurojackpot-winning-numbers .row-header .counter {
    width: auto;
    padding-right: 2rem;
    text-align: left;
    float: inherit;
  }
}
.eurojackpot-classic-game .row-header .right-side,
.eurojackpot-system-game .row-header .right-side,
.eurojackpot-lucky-game .row-header .right-side,
.eurojackpot-winning-numbers .row-header .right-side {
  padding-right: 2.667vw;
  position: relative;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .right-side,
  .eurojackpot-system-game .row-header .right-side,
  .eurojackpot-lucky-game .row-header .right-side,
  .eurojackpot-winning-numbers .row-header .right-side {
    padding-right: 1.5rem;
    display: inline-block;
    position: static;
  }
}
.eurojackpot-classic-game .row-header .right-side .row-delete-button,
.eurojackpot-system-game .row-header .right-side .row-delete-button,
.eurojackpot-lucky-game .row-header .right-side .row-delete-button,
.eurojackpot-winning-numbers .row-header .right-side .row-delete-button {
  display: block;
  position: absolute;
  top: 2.15vw;
  right: 3.5vw;
  cursor: pointer;
  transition: opacity .25s, transform .15s;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .right-side .row-delete-button,
  .eurojackpot-system-game .row-header .right-side .row-delete-button,
  .eurojackpot-lucky-game .row-header .right-side .row-delete-button,
  .eurojackpot-winning-numbers .row-header .right-side .row-delete-button {
    top: .1rem;
    right: 1rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .right-side .row-delete-button:hover,
  .eurojackpot-system-game .row-header .right-side .row-delete-button:hover,
  .eurojackpot-lucky-game .row-header .right-side .row-delete-button:hover,
  .eurojackpot-winning-numbers .row-header .right-side .row-delete-button:hover {
    transform: scale(1.4);
  }
}
.eurojackpot-classic-game .row-header .right-side .row-delete-button .icon,
.eurojackpot-system-game .row-header .right-side .row-delete-button .icon,
.eurojackpot-lucky-game .row-header .right-side .row-delete-button .icon,
.eurojackpot-winning-numbers .row-header .right-side .row-delete-button .icon {
  width: 2.667vw;
  height: 2.667vw;
  fill: #000000;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .right-side .row-delete-button .icon,
  .eurojackpot-system-game .row-header .right-side .row-delete-button .icon,
  .eurojackpot-lucky-game .row-header .right-side .row-delete-button .icon,
  .eurojackpot-winning-numbers .row-header .right-side .row-delete-button .icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.eurojackpot-classic-game .row-header .right-side .row-delete-button.inactive,
.eurojackpot-system-game .row-header .right-side .row-delete-button.inactive,
.eurojackpot-lucky-game .row-header .right-side .row-delete-button.inactive,
.eurojackpot-winning-numbers .row-header .right-side .row-delete-button.inactive {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  cursor: default;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .right-side .row-delete-button.inactive:hover,
  .eurojackpot-system-game .row-header .right-side .row-delete-button.inactive:hover,
  .eurojackpot-lucky-game .row-header .right-side .row-delete-button.inactive:hover,
  .eurojackpot-winning-numbers .row-header .right-side .row-delete-button.inactive:hover {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .row-header .header-rules-text,
.eurojackpot-system-game .row-header .header-rules-text,
.eurojackpot-lucky-game .row-header .header-rules-text,
.eurojackpot-winning-numbers .row-header .header-rules-text {
  width: 86%;
  display: inline-block;
  text-align: center;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .row-header .header-rules-text,
  .eurojackpot-system-game .row-header .header-rules-text,
  .eurojackpot-lucky-game .row-header .header-rules-text,
  .eurojackpot-winning-numbers .row-header .header-rules-text {
    width: 100%;
    text-align: left;
  }
}
.eurojackpot-classic-game .number-picker-wrapper,
.eurojackpot-system-game .number-picker-wrapper,
.eurojackpot-lucky-game .number-picker-wrapper,
.eurojackpot-winning-numbers .number-picker-wrapper {
  position: relative;
  display: flex;
}
@keyframes eurojackpot-complete-grow {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.1);
  }
  85% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper,
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper,
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper,
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper {
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@keyframes eurojackpot-sparkle {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  20% {
    opacity: 1;
  }
  50% {
    transform: rotate(80deg);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(160deg);
  }
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star,
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star,
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star,
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star {
  width: 20%;
  height: 20%;
  background: -moz-radial-gradient(center, ellipse cover, #f7f5ed 0%, #f7f5ed 6%, rgba(247, 245, 237, 0.1) 70%, rgba(247, 245, 237, 0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, #f7f5ed 0%, #f7f5ed 6%, rgba(247, 245, 237, 0.1) 70%, rgba(247, 245, 237, 0) 100%);
  background: radial-gradient(ellipse at center, #f7f5ed 0%, #f7f5ed 6%, rgba(247, 245, 237, 0.1) 70%, rgba(247, 245, 237, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f5ed', endColorstr='#00f7f5ed', GradientType=1);
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  animation: eurojackpot-sparkle 0.8s ease-in-out forwards;
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(1),
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(1),
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(1),
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(1) {
  top: -2%;
  animation-delay: .5s;
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(2),
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(2),
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(2),
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(2) {
  top: 50%;
  left: 0;
  animation-delay: .8s;
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(3),
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(3),
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(3),
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:nth-child(3) {
  top: 70%;
  right: 0;
  animation-delay: 1s;
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:after,
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:after,
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:after,
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:after,
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before,
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before,
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before,
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before {
  height: 300%;
  width: 50%;
  content: ' ';
  background: -moz-radial-gradient(center, ellipse cover, #f7f5ed 0%, #f7f5ed 6%, rgba(247, 245, 237, 0.1) 70%, rgba(247, 245, 237, 0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, #f7f5ed 0%, #f7f5ed 6%, rgba(247, 245, 237, 0.1) 70%, rgba(247, 245, 237, 0) 100%);
  background: radial-gradient(ellipse at center, #f7f5ed 0%, #f7f5ed 6%, rgba(247, 245, 237, 0.1) 70%, rgba(247, 245, 237, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f5ed', endColorstr='#00f7f5ed', GradientType=1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.eurojackpot-classic-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before,
.eurojackpot-system-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before,
.eurojackpot-lucky-game .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before,
.eurojackpot-winning-numbers .number-picker-wrapper.complete-row .shine-star-wrapper .shine-star:before {
  transform: translate(-50%, -50%) rotate(90deg);
}
.eurojackpot-classic-game .number-picker-container,
.eurojackpot-system-game .number-picker-container,
.eurojackpot-lucky-game .number-picker-container,
.eurojackpot-winning-numbers .number-picker-container {
  margin: 0 -1.9%;
  position: relative;
  white-space: normal;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container,
  .eurojackpot-system-game .number-picker-container,
  .eurojackpot-lucky-game .number-picker-container,
  .eurojackpot-winning-numbers .number-picker-container {
    margin: 0 -1rem;
  }
}
.eurojackpot-classic-game .number-picker-container .cells-container,
.eurojackpot-system-game .number-picker-container .cells-container,
.eurojackpot-lucky-game .number-picker-container .cells-container,
.eurojackpot-winning-numbers .number-picker-container .cells-container {
  width: 61.068vw;
  position: relative;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cells-container,
  .eurojackpot-system-game .number-picker-container .cells-container,
  .eurojackpot-lucky-game .number-picker-container .cells-container,
  .eurojackpot-winning-numbers .number-picker-container .cells-container {
    width: 100%;
    margin-right: 2.5vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cells-container,
  .eurojackpot-system-game .number-picker-container .cells-container,
  .eurojackpot-lucky-game .number-picker-container .cells-container,
  .eurojackpot-winning-numbers .number-picker-container .cells-container {
    width: 63rem;
    margin-right: 2.8rem;
  }
}
.eurojackpot-classic-game .number-picker-container .cell,
.eurojackpot-system-game .number-picker-container .cell,
.eurojackpot-lucky-game .number-picker-container .cell,
.eurojackpot-winning-numbers .number-picker-container .cell {
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 1.334vw;
  transition: transform .15s linear;
  will-change: transform;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell,
  .eurojackpot-system-game .number-picker-container .cell,
  .eurojackpot-lucky-game .number-picker-container .cell,
  .eurojackpot-winning-numbers .number-picker-container .cell {
    margin: 2.075vw 0.648vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell,
  .eurojackpot-system-game .number-picker-container .cell,
  .eurojackpot-lucky-game .number-picker-container .cell,
  .eurojackpot-winning-numbers .number-picker-container .cell {
    margin: 0.8rem 1.2rem;
    cursor: pointer;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell:hover,
  .eurojackpot-system-game .number-picker-container .cell:hover,
  .eurojackpot-lucky-game .number-picker-container .cell:hover,
  .eurojackpot-winning-numbers .number-picker-container .cell:hover {
    transform: scale(1.2);
  }
}
.eurojackpot-classic-game .number-picker-container .cell:after,
.eurojackpot-system-game .number-picker-container .cell:after,
.eurojackpot-lucky-game .number-picker-container .cell:after,
.eurojackpot-winning-numbers .number-picker-container .cell:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.eurojackpot-classic-game .number-picker-container .cell .cell-container,
.eurojackpot-system-game .number-picker-container .cell .cell-container,
.eurojackpot-lucky-game .number-picker-container .cell .cell-container,
.eurojackpot-winning-numbers .number-picker-container .cell .cell-container {
  width: 12.267vw;
  height: 12.267vw;
  position: relative;
  left: 0;
  top: 0;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell .cell-container,
  .eurojackpot-system-game .number-picker-container .cell .cell-container,
  .eurojackpot-lucky-game .number-picker-container .cell .cell-container,
  .eurojackpot-winning-numbers .number-picker-container .cell .cell-container {
    width: 4.6rem;
    height: 4.6rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell .cell-container,
  .eurojackpot-system-game .number-picker-container .cell .cell-container,
  .eurojackpot-lucky-game .number-picker-container .cell .cell-container,
  .eurojackpot-winning-numbers .number-picker-container .cell .cell-container {
    width: 5.967vw;
    height: 5.967vw;
  }
}
.eurojackpot-classic-game .number-picker-container .cell .face,
.eurojackpot-system-game .number-picker-container .cell .face,
.eurojackpot-lucky-game .number-picker-container .cell .face,
.eurojackpot-winning-numbers .number-picker-container .cell .face {
  width: 100%;
  height: 100%;
  font-size: 3.733vw;
  line-height: 3.2;
  font-weight: 800;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 50%;
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell .face,
  .eurojackpot-system-game .number-picker-container .cell .face,
  .eurojackpot-lucky-game .number-picker-container .cell .face,
  .eurojackpot-winning-numbers .number-picker-container .cell .face {
    font-size: 1.4rem;
    line-height: 4.7rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .cell .face,
  .eurojackpot-system-game .number-picker-container .cell .face,
  .eurojackpot-lucky-game .number-picker-container .cell .face,
  .eurojackpot-winning-numbers .number-picker-container .cell .face {
    font-size: 1.815vw;
    line-height: 5.967vw;
  }
}
.eurojackpot-classic-game .number-picker-container .cell .face:before,
.eurojackpot-system-game .number-picker-container .cell .face:before,
.eurojackpot-lucky-game .number-picker-container .cell .face:before,
.eurojackpot-winning-numbers .number-picker-container .cell .face:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}
.eurojackpot-classic-game .number-picker-container .cell .face.front,
.eurojackpot-system-game .number-picker-container .cell .face.front,
.eurojackpot-lucky-game .number-picker-container .cell .face.front,
.eurojackpot-winning-numbers .number-picker-container .cell .face.front {
  background-color: #2E3535;
}
.eurojackpot-classic-game .number-picker-container .cell .face.back,
.eurojackpot-system-game .number-picker-container .cell .face.back,
.eurojackpot-lucky-game .number-picker-container .cell .face.back,
.eurojackpot-winning-numbers .number-picker-container .cell .face.back {
  visibility: hidden;
  background-color: #E8A332;
}
.eurojackpot-classic-game .number-picker-container .cell .face.back .number,
.eurojackpot-system-game .number-picker-container .cell .face.back .number,
.eurojackpot-lucky-game .number-picker-container .cell .face.back .number,
.eurojackpot-winning-numbers .number-picker-container .cell .face.back .number {
  position: relative;
  z-index: 2;
}
@keyframes eurojackpot-animation-flip-timer {
  0% {
    will-change: transform;
  }
  100% {
    will-change: transform;
  }
}
@keyframes eurojackpot-animation-flip-timer-IE {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes eurojackpot-check {
  0% {
    transform: rotateY(0deg) scale(1);
  }
  50% {
    transform: rotateY(-195deg) scale(1.1);
  }
  75% {
    transform: rotateY(-165deg) scale(1.1);
  }
  100% {
    transform: rotateY(-180deg) scale(1);
  }
}
@keyframes eurojackpot-uncheck {
  0% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(-15deg);
  }
  75% {
    transform: rotateY(15deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress {
  perspective: 10rem;
  animation: eurojackpot-animation-flip-timer 0.7s forwards;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress {
    animation: eurojackpot-animation-flip-timer-IE 0.4s forwards;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress .cell-container,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress .cell-container,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress .cell-container,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress .cell-container {
  transform-style: preserve-3d;
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress .cell-container .face {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.front,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.front,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.front,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress .cell-container .face.front {
  visibility: visible;
  transform: rotateY(0deg);
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back {
  visibility: visible;
  transform: rotateY(180deg);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress .cell-container .face.back {
    transform: rotateY(0);
  }
}
@keyframes eurojackpot-shine {
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  25% {
    opacity: .4;
  }
  50% {
    opacity: .6;
  }
  75% {
    opacity: .4;
  }
  100% {
    transform: translate(0%, 80%);
    opacity: 0;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container {
  transform: rotateY(-180deg) scale(1);
  animation: eurojackpot-check 0.7s;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container {
    transform: rotateY(0) scale(1);
    animation: none;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front {
  visibility: visible;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.front {
    opacity: 0;
    transition: opacity .25s, visibility 0 .25s;
    z-index: 1;
  }
}
@keyframes number-scale {
  0% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number {
  transform: scale(1.1);
  animation: eurojackpot-number-scale 0.7s linear forwards;
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .number {
    transform: scale(1);
    animation: none;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 42%;
  overflow: hidden;
  mask: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotCommonIcons/EurojackpotSquareShape.svg);
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine {
    border-radius: 0;
    overflow: visible;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine:after,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine:after,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine:after,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .cell-container .face.back .shine:after {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(-45deg, rgba(247, 245, 237, 0) 0%, rgba(247, 245, 237, 0) 40%, #f7f5ed 50%, #f7f5ed 52%, rgba(247, 245, 237, 0) 67%, rgba(247, 245, 237, 0) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(247, 245, 237, 0) 0%, rgba(247, 245, 237, 0) 40%, #f7f5ed 50%, #f7f5ed 52%, rgba(247, 245, 237, 0) 67%, rgba(247, 245, 237, 0) 100%);
  background: linear-gradient(135deg, rgba(247, 245, 237, 0) 0%, rgba(247, 245, 237, 0) 40%, #f7f5ed 50%, #f7f5ed 52%, rgba(247, 245, 237, 0) 67%, rgba(247, 245, 237, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00f7f5ed', endColorstr='#00f7f5ed', GradientType=1);
  transform: translate(-50%, -100%);
  animation: eurojackpot-shine 0.5s 0.8s ease-in-out forwards;
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container {
  animation: eurojackpot-uncheck 0.5s linear;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container {
    animation: none;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.front,
.eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.front,
.eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.front,
.eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.front {
  opacity: 1;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .eurojackpot-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.back,
  .eurojackpot-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.back,
  .eurojackpot-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.back,
  .eurojackpot-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .cell-container .face.back {
    opacity: 0;
    transition: opacity .25s, visibility 0 .25s;
    z-index: 1;
  }
}
.eurojackpot-classic-game .number-picker-container .cell.selected .cell-container .face.front,
.eurojackpot-system-game .number-picker-container .cell.selected .cell-container .face.front,
.eurojackpot-lucky-game .number-picker-container .cell.selected .cell-container .face.front,
.eurojackpot-winning-numbers .number-picker-container .cell.selected .cell-container .face.front {
  visibility: hidden;
}
.eurojackpot-classic-game .number-picker-container .cell.selected .cell-container .face.back,
.eurojackpot-system-game .number-picker-container .cell.selected .cell-container .face.back,
.eurojackpot-lucky-game .number-picker-container .cell.selected .cell-container .face.back,
.eurojackpot-winning-numbers .number-picker-container .cell.selected .cell-container .face.back {
  visibility: visible;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container,
.eurojackpot-system-game .number-picker-container .row-stars-container,
.eurojackpot-lucky-game .number-picker-container .row-stars-container,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container {
  width: 13.335vw;
  text-align: center;
  padding: 1.334vw 0 1.334vw;
  box-sizing: content-box;
  position: relative;
  right: -0.4vw;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container,
  .eurojackpot-system-game .number-picker-container .row-stars-container,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container {
    align-content: space-between;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container,
  .eurojackpot-system-game .number-picker-container .row-stars-container,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container {
    width: 16.075vw;
    padding: 2.075vw 0 2.075vw 3.242vw;
    right: 0;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container,
  .eurojackpot-system-game .number-picker-container .row-stars-container,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container {
    width: 12rem;
    padding: .8rem 0 .8rem 4rem;
    right: -0.3rem;
  }
}
.eurojackpot-classic-game .number-picker-container .row-stars-container::before,
.eurojackpot-system-game .number-picker-container .row-stars-container::before,
.eurojackpot-lucky-game .number-picker-container .row-stars-container::before,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container::before {
  content: '';
  width: .2rem;
  background-color: #616161;
  position: absolute;
  left: 0;
  transform: translateX(-50%);
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container::before,
  .eurojackpot-system-game .number-picker-container .row-stars-container::before,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container::before,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container::before {
    top: 2.075vw;
    bottom: 2.075vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container::before,
  .eurojackpot-system-game .number-picker-container .row-stars-container::before,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container::before,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container::before {
    top: 0.8rem;
    bottom: 0.8rem;
  }
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .shine-star-wrapper,
.eurojackpot-system-game .number-picker-container .row-stars-container .shine-star-wrapper,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .shine-star-wrapper,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .shine-star-wrapper {
  width: 70%;
  padding-top: 70%;
  margin-left: 15%;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box {
  width: 12vw;
  height: 12vw;
  margin-bottom: 2.9vw;
  box-sizing: border-box;
  position: relative;
  transition: transform .1s linear;
}
@media (min-width: 768px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-system-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-system-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box {
    width: 5.836vw;
    height: 5.836vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-system-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box {
    width: 4.5rem;
    height: 4.5rem;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container .star-box:hover,
  .eurojackpot-system-game .number-picker-container .row-stars-container .star-box:hover,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box:hover,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box:hover {
    transform: scale(1.2);
  }
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .number,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .number,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .number,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .number {
  width: 100%;
  font-size: 4vw;
  font-weight: 800;
  line-height: 3.5;
  display: block;
  color: #E8A332;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 768px) and (max-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .number,
  .eurojackpot-system-game .number-picker-container .row-stars-container .star-box .number,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .number,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .number {
    font-size: 1.815vw;
  }
}
@media (min-width: 950px) {
  .eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .number,
  .eurojackpot-system-game .number-picker-container .row-stars-container .star-box .number,
  .eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .number,
  .eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .number {
    font-size: 1.4rem;
  }
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.main-outline,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.main-outline,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.main-outline,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.main-outline {
  stroke: #E8A332;
  transform: scale(1);
  transition: transform .15s;
  stroke-width: 1rem;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.gold,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.gold,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.gold,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.gold {
  transform: scale(0);
  opacity: 0;
  animation: eurojackpot-star-implosition 0.15s linear forwards;
  background-image: url(/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotIcons/icon-star-gold.svg);
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.outline-3,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.outline-3,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.outline-3,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.outline-3 {
  stroke: #E8A332;
  transform: scale(0);
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.outline-1,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.outline-1 {
  stroke-width: 1rem;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.outline-2,
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.outline-3,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.outline-3,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.outline-3,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.outline-3 {
  stroke-width: .5rem;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box .icon.icon-small-star,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box .icon.icon-small-star,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box .icon.icon-small-star,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box .icon.icon-small-star {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  opacity: 0;
}
@keyframes eurojackpot-star-implosition {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes eurojackpot-star-expansion {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    transform: scale(1.4);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes eurojackpot-star-expansion-otline {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.4);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes eurojackpot-small-star-explosion-1 {
  40% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-400%) translateX(220%) scale(1.2);
    opacity: 0;
  }
}
@keyframes eurojackpot-small-star-explosion-2 {
  30% {
    opacity: 1;
  }
  100% {
    transform: translateY(-300%) translateX(200%) scale(0.6);
    opacity: 0;
  }
}
@keyframes eurojackpot-small-star-explosion-3 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-230%) translateX(160%) scale(1);
    opacity: 0.9;
  }
}
@keyframes eurojackpot-small-star-explosion-4 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-250%) translateX(-240%) scale(1);
    opacity: 0.7;
  }
}
@keyframes eurojackpot-small-star-explosion-5 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-190%) translateX(-110%) scale(0.6);
    opacity: 0.8;
  }
}
@keyframes eurojackpot-small-star-explosion-6 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(190%) translateX(0%) scale(1.05);
    opacity: 0.9;
  }
}
@keyframes eurojackpot-small-star-explosion-7 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(70%) translateX(-280%) scale(0.92);
    opacity: 0.9;
  }
}
@keyframes eurojackpot-small-star-explosion-8 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(80%) translateX(180%) scale(0.5);
    opacity: 0.4;
  }
}
@keyframes eurojackpot-small-star-explosion-9 {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(50%) translateX(300%) scale(1.1);
    opacity: 0.8;
  }
}
@keyframes eurojackpot-small-star-explosion-10 {
  30% {
    opacity: 1;
  }
  100% {
    transform: translateY(100%) translateX(400%) scale(1.3);
    opacity: 0;
  }
}
@keyframes eurojackpot-small-star-explosion-11 {
  30% {
    opacity: 1;
  }
  100% {
    transform: translateY(-120%) translateX(-500%) scale(1.3);
    opacity: 0;
  }
}
@keyframes eurojackpot-small-star-explosion-12 {
  0%,
  100% {
    transform: translateY(140%) translateX(-230%) scale(0);
    opacity: 0;
  }
  50% {
    transform: translateY(140%) translateX(-230%) scale(0.92);
    opacity: 0.7;
  }
}
@keyframes eurojackpot-small-star-explosion-13 {
  0%,
  100% {
    transform: translateY(-230%) translateX(160%) scale(0.6);
    opacity: 0;
  }
  50% {
    transform: translateY(-230%) translateX(160%) scale(1.2);
    opacity: 0.7;
  }
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .number,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .number,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .number,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .number {
  color: white;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon.main-outline,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon.main-outline,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon.main-outline,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon.main-outline {
  transform: scale(0.9);
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon.gold,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon.gold,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon.gold,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon.gold {
  transform: scale(1);
  opacity: 1;
  animation: eurojackpot-star-expansion 0.3s linear forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-1,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-1,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-1,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon.outline-1 {
  animation: eurojackpot-star-expansion-otline 0.3s linear forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-2,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-2,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-2,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon.outline-2 {
  animation: eurojackpot-star-expansion-otline 0.3s 0.15s linear forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-3,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-3,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon.outline-3,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon.outline-3 {
  animation: eurojackpot-star-expansion-otline 0.15s 0.15s linear forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--1,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--1,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--1,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--1 {
  animation: eurojackpot-small-star-explosion-1 0.5s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--2,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--2,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--2,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--2 {
  animation: eurojackpot-small-star-explosion-2 0.4s 0.2s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--3,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--3,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--3,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--3 {
  animation: eurojackpot-small-star-explosion-3 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--4,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--4,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--4,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--4 {
  animation: eurojackpot-small-star-explosion-4 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--5,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--5,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--5,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--5 {
  animation: eurojackpot-small-star-explosion-5 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--6,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--6,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--6,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--6 {
  animation: eurojackpot-small-star-explosion-6 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--7,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--7,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--7,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--7 {
  animation: eurojackpot-small-star-explosion-7 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--8,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--8,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--8,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--8 {
  animation: eurojackpot-small-star-explosion-8 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--9,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--9,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--9,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--9 {
  animation: eurojackpot-small-star-explosion-9 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--10,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--10,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--10,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--10 {
  animation: eurojackpot-small-star-explosion-10 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--11,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--11,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--11,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--11 {
  animation: eurojackpot-small-star-explosion-11 0.3s 0.1s forwards;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--12,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--12,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--12,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--12 {
  animation: eurojackpot-small-star-explosion-12 0.15s 0.05s forwards;
  animation-iteration-count: 2;
}
.eurojackpot-classic-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--13,
.eurojackpot-system-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--13,
.eurojackpot-lucky-game .number-picker-container .row-stars-container .star-box.selected .icon-small-star--13,
.eurojackpot-winning-numbers .number-picker-container .row-stars-container .star-box.selected .icon-small-star--13 {
  animation: eurojackpot-small-star-explosion-13 0.15s 0.05s forwards;
  animation-iteration-count: 2;
}
