@import (reference) "../../../../Mixins/_mixins.less";

.multi-client-draw-options {
  .multi-columns-wrapper__column {
    .draw-date {
      &.unavailable {
        text-decoration: line-through;
      }

      &__icon {
        width: 1.6rem;
        height: 2rem;
        margin-left: 0.6rem;
        margin-top: -0.6rem;
        position: relative;
        top: 0.5rem;
      }
    }
  }

  .check-button {
    margin-bottom: 1rem;
  }

  // Eurojackpot theming
  &.eurojackpot {
    .check-button {
      background-color: fade(@eurojackpot-gold-medium, 10%);
      color: @eurojackpot-gold-medium;

      .check-button__dot {
        border-color: fade(@eurojackpot-gold-medium, 10%);
      }

      &::before {
        background-color: @eurojackpot-gold-medium;
      }

      .check-button__dot::after {
        background-color: @eurojackpot-gold-medium;
      }

      &.check-button--selected {
        color: @ds-white;
        .check-button__dot {
          border-color: fade(@eurojackpot-gold-medium, 50%);
        }
      }
    }
  }

  // Vikinglotto theming
  &.vikinglotto {
    .check-button {
      background-color: fade(@vikinglotto-blue, 10%);
      color: @vikinglotto-blue;

      .check-button__dot {
        border-color: fade(@vikinglotto-blue, 10%);
      }

      &::before {
        background-color: @vikinglotto-blue;
      }

      .check-button__dot::after {
        background-color: @vikinglotto-blue;
      }

      &.check-button--selected {
        color: @ds-white;
        .check-button__dot {
          border-color: fade(@vikinglotto-blue, 50%);
        }
      }
    }
  }

  // Lotto theming
  &.lotto {
    .check-button {
      background-color: fade(@lotto-red, 10%);
      color: @lotto-red;

      .check-button__dot {
        border-color: fade(@lotto-red, 10%);
      }

      &::before {
        background-color: @lotto-red;
      }

      .check-button__dot::after {
        background-color: @lotto-red;
      }

      &.check-button--selected {
        color: @ds-white;
        .check-button__dot {
          border-color: fade(@lotto-red, 50%);
        }
      }
    }
  }

  // SummerLotto theming
  .multi-client-wrapper__campaign-Sommerlotto & {
    .check-button {
      background-color: fade(@summer-lotto-orange, 10%);
      color: @summer-lotto-orange;

      .check-button__dot {
        border-color: fade(@summer-lotto-orange, 10%);
      }

      &::before {
        background-color: @summer-lotto-orange;
      }

      .check-button__dot::after {
        background-color: @summer-lotto-orange;
      }

      &.check-button--selected {
        color: @ds-white;
        .check-button__dot {
          border-color: fade(@summer-lotto-orange, 50%);
        }
      }
    }
  }
}