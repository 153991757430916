@import url("../../Mixins/_mixins.less");

.region-eurojackpot {
  .news-list {

    .section {
      background-color: @eurojackpot-gray;
      border-color: @eurojackpot-gray;

      &:first-of-type {
        background-color: @numbergames-black;
        border-color: @numbergames-black;
      }
      // News list-overview
      .news-heading {
        padding: 8vw 0;

        @media (min-width: @desktop-width) {
          min-height: 32rem;
          padding: 5rem 0;
        }

        .topspot-headline {
          margin-bottom: 5vw;

          @media (min-width: @desktop-width) {
            margin-bottom: 3rem;
          }
        }
      }

      .content-wrapper {
        color: @numbergames-white;

        .news-block {

          .date {
            color: @eurojackpot-whitesmoke;
          }

          a.heading-link {
            .headline {
              color: @numbergames-white;
              line-height: 6.8vw;

              @media (min-width: @desktop-width) {
                line-height: 4.5rem;
              }
            }
          }

          .teaser-details {
            color: @numbergames-white;
          }
        }
      }
      // News single item view
      .content-wrapper {

        .news-block.single {

          .news-item-headline {

            @media (min-width: @desktop-width) {
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}
