body.region-eurojackpot .seo-text-container,
body.region-avalon-eurojackpot .seo-text-container,
body.color-eurojackpot-gold .seo-text-container {
  background-color: #74653c;
}
body.region-eurojackpot footer .info-bar,
body.region-avalon-eurojackpot footer .info-bar,
body.color-eurojackpot-gold footer .info-bar {
  background: linear-gradient(to right, #bb7f36 0%, #efb03c 25%, #bb7f36 50%, #efb03c 75%, #bb7f36 100%);
}
