@import url("../Mixins/_mixins");

.winning-numbers-latest__component {
  background-color: @ds-white;
  padding: 4rem 2rem;
}

.winning-numbers-latest {
  position: relative;
  max-width: 38rem;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media @sm {
    max-width: 45rem;
  }

  @media @xxl {
    max-width: 65rem;
  }
}

.winning-numbers-latest__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}

.winning-numbers-latest__logo {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-bottom: 1.5rem;
  height: 2.5rem;

  .winning-numbers-latest--lotto & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#lotto_use");
  }

  .winning-numbers-latest--vikinglotto & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#vikinglotto_use");
  }

  .winning-numbers-latest--eurojackpot & {
    background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#eurojackpot_use");
  }

  @media @sm {
    height: 3.2rem;
  }
}

.winning-numbers-latest__draw-date {
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: @ds-black;
  margin-bottom: 1.5rem;
}

.winning-numbers-latest__fun-text {
  position: absolute;
  top: 18rem;
  right: -4rem;
  width: 25rem;
  padding-top: 1rem;
  font-family: "Shadows Into Light", cursive;
  color: @numbergames-blue-grey;
  transform: rotate(-15deg);
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: bold;
  letter-spacing: 0.15rem;

  @media @sm {
    transform: rotate(25deg);
    font-size: 2.1rem;
    right: calc(~'(100% - 10rem)');
    top: 17rem;
  }

  @media @xxl {
    right: calc(~'(100% - 5rem)');
  }

  &::after {
    content: "";
    position: absolute;
    top: -3rem;
    right: 15rem;
    transform: rotate(-70deg) scale(0.7, 0.7);
    width: 4.6rem;
    height: 3.6rem;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA1OCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTUzMTIgMzguNjcwOUM4Ljk1MzEyIDM4LjY3MDkgMTguODA5NyAxOS40MDI0IDQyLjk4ODIgMTguMzk2MyIgc3Ryb2tlPSIjOTI5NjlFIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMzguMzQ5NiAyNy43NzkzTDQ0LjIwMDIgMTguODkyOUwzNi41NTIyIDEwLjk2OTUiIHN0cm9rZT0iIzkyOTY5RSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);

    @media @sm {
      top: -3.2rem;
      right: 6rem;
      transform: rotate(-90deg) scale(0.8, -0.8);
    }
    @media @xxl {
      top: -4rem;
      right: 9rem;
      transform: rotate(-26deg);
    }
  }
}

.winning-numbers-latest__cta-wrapper {
  display: flex;
  justify-content: center;

  @media @xxl {
    padding-top: 1rem;
  }
}

.winning-numbers-latest__cta {
  .btn();
  .btn-primary();
  .btn-height-default();
  .btn-hover-scale();
  min-width: 17.4rem;
  display: inline-flex;
}
