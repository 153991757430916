@import (reference) "../../../../Mixins/_mixins.less";
@green-haze: #009a64;

.multi-client__joker-hat {
  width: 5.6rem;
  height: 2.8rem;
  margin: 0 auto 1rem;

  @media @sm {
    width: 10rem;
    height: 5rem;
    margin: 0 auto 3rem;
    position: absolute;
    top: -8rem;
    left: 0;
    right: 0;
  }
}

.multi-client-joker-options {
  .check-button {
    margin-bottom: 1rem;
    background-color: fade(@green-haze, 10%);
    color: @green-haze;

    .check-button__dot {
      border-color: fade(@green-haze, 10%);
    }

    &::before {
      background-color: @green-haze;
    }

    .check-button__dot::after {
      background-color: @green-haze;
    }

    &.check-button--selected {
      color: @ds-white;

      .check-button__dot {
        border-color: fade(@green-haze, 50%);
      }
    }
  }
}
