/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/**
 * Colors for Subscription
 */
/**
 * Primary
 */
/**
 * Text
 * Mobile font sizes have been halved
 */
/**
 * Buttons
 * Mobile font sizes have been halved
 */
.content-size-xs,
.content-size-sm,
.content-size-md,
.content-size-lg,
.content-size-full {
  width: 100%;
  margin: 0 auto 2rem;
}
.content-size-xs {
  max-width: 42rem;
}
.content-size-sm {
  max-width: 48rem;
}
.content-size-md {
  max-width: 52rem;
}
.content-size-lg {
  max-width: 60rem;
}
.content-size-full {
  max-width: none;
}
.content-size-extra-top {
  margin-top: 1rem !important;
}
.content-size-less-top {
  margin-top: -1rem !important;
}
.content-size-extra-bottom {
  margin-bottom: 3rem !important;
}
.content-size-less-bottom {
  margin-bottom: 1rem !important;
}
.header-subtitle {
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0;
  text-align: center;
  padding-top: 4rem;
  position: relative;
}
.header-subtitle__header {
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin-bottom: 0;
  padding: 0 1rem;
  color: #000000;
  font-weight: 900;
  margin-top: 0;
  overflow-wrap: break-word;
}
@media screen and (min-width: 768px) {
  .header-subtitle__header {
    font-size: 4rem;
    line-height: 4.6rem;
    margin-bottom: 1.6rem;
  }
}
.header-subtitle__sub-header {
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0 3rem;
  color: #a3a6ab;
  font-weight: 600;
  overflow-wrap: break-word;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .header-subtitle__sub-header {
    padding: 0 19rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
@media screen and (min-width: 950px) {
  .header-subtitle__sub-header {
    padding: 0 34%;
  }
}
