@import (reference) "../Mixins/Variables.less";
@borderWidth: .1rem;
@borderRadius: 2rem;

.campaign__tickets-counter {
  max-width: 29.5rem;
  padding: 1.5rem 2.5rem;
  border-radius: @borderRadius;
  display: flex;
  align-items: center;
  margin: 0 auto 4rem;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: @borderRadius;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &::after {
    content: "";
    border-radius: @borderRadius;
    position: absolute;
    top: @borderWidth;
    right: @borderWidth;
    bottom: @borderWidth;
    left: @borderWidth;
    z-index: -1;
  }

  .tickets-counter__icon {
    width: 2.4rem;
    height: 4rem;
    flex-shrink: 0;
    margin-right: 1.3rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .tickets-counter__text {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 700;
    color: white;
    text-align: left;
  }

  &.christmas-campaign {
    &::before {
      background: @christmas-gold-linear-gradient;
    }
    &::after {
      background: @christmas-red-radial-gradient;
    }
  }

  
  &.summer-campaign {
    &::after {
      background: @summer-orange-radial-gradient;
    }
  }
}


.view-coupon .campaign__tickets-counter {
  width: 96%;
  margin: 2rem auto;
}