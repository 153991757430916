/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.winning-numbers-latest__component {
  background-color: white;
  padding: 4rem 2rem;
}
.winning-numbers-latest {
  position: relative;
  max-width: 38rem;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .winning-numbers-latest {
    max-width: 45rem;
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers-latest {
    max-width: 65rem;
  }
}
.winning-numbers-latest__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}
.winning-numbers-latest__logo {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-bottom: 1.5rem;
  height: 2.5rem;
}
.winning-numbers-latest--lotto .winning-numbers-latest__logo {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#lotto_use");
}
.winning-numbers-latest--vikinglotto .winning-numbers-latest__logo {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#vikinglotto_use");
}
.winning-numbers-latest--eurojackpot .winning-numbers-latest__logo {
  background-image: url("/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/GameTypes.svg#eurojackpot_use");
}
@media screen and (min-width: 768px) {
  .winning-numbers-latest__logo {
    height: 3.2rem;
  }
}
.winning-numbers-latest__draw-date {
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 1.5rem;
}
.winning-numbers-latest__fun-text {
  position: absolute;
  top: 18rem;
  right: -4rem;
  width: 25rem;
  padding-top: 1rem;
  font-family: "Shadows Into Light", cursive;
  color: #92969E;
  transform: rotate(-15deg);
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: bold;
  letter-spacing: 0.15rem;
}
@media screen and (min-width: 768px) {
  .winning-numbers-latest__fun-text {
    transform: rotate(25deg);
    font-size: 2.1rem;
    right: calc((100% - 10rem));
    top: 17rem;
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers-latest__fun-text {
    right: calc((100% - 5rem));
  }
}
.winning-numbers-latest__fun-text::after {
  content: "";
  position: absolute;
  top: -3rem;
  right: 15rem;
  transform: rotate(-70deg) scale(0.7, 0.7);
  width: 4.6rem;
  height: 3.6rem;
  background-size: cover;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA1OCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTUzMTIgMzguNjcwOUM4Ljk1MzEyIDM4LjY3MDkgMTguODA5NyAxOS40MDI0IDQyLjk4ODIgMTguMzk2MyIgc3Ryb2tlPSIjOTI5NjlFIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMzguMzQ5NiAyNy43NzkzTDQ0LjIwMDIgMTguODkyOUwzNi41NTIyIDEwLjk2OTUiIHN0cm9rZT0iIzkyOTY5RSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
}
@media screen and (min-width: 768px) {
  .winning-numbers-latest__fun-text::after {
    top: -3.2rem;
    right: 6rem;
    transform: rotate(-90deg) scale(0.8, -0.8);
  }
}
@media screen and (min-width: 1440px) {
  .winning-numbers-latest__fun-text::after {
    top: -4rem;
    right: 9rem;
    transform: rotate(-26deg);
  }
}
.winning-numbers-latest__cta-wrapper {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-latest__cta-wrapper {
    padding-top: 1rem;
  }
}
.winning-numbers-latest__cta {
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: #d4d4d4;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  color: #000000;
  transition: color 200ms ease-out;
  height: 5.6rem;
  min-width: 17.4rem;
  display: inline-flex;
}
.winning-numbers-latest__cta::after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.winning-numbers-latest__cta::after {
  background-color: #FEB700;
  border-color: #FEB700;
}
.winning-numbers-latest__cta:not(.btn--disabled):not(.btn--loading):active::after {
  background-color: #f5da00;
  border-color: #f5da00;
}
.winning-numbers-latest__cta.btn--disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.winning-numbers-latest__cta.btn--loading {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.winning-numbers-latest__cta.btn--loading::before {
  content: "";
  border-radius: 2.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 0 0 #ffce51;
  animation: btn-pulse-inner-loading-animation 1.3s infinite;
}
.winning-numbers-latest__cta.btn--loading::after {
  animation: btn-pulse-loading-animation 1.3s infinite;
}
.winning-numbers-latest__cta:not(.btn--disabled):hover::after {
  transform: scale(1.03);
}
