.campaign__tickets-counter {
  max-width: 29.5rem;
  padding: 1.5rem 2.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  margin: 0 auto 4rem;
  position: relative;
  z-index: 0;
}
.campaign__tickets-counter::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}
.campaign__tickets-counter::after {
  content: "";
  border-radius: 2rem;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  z-index: -1;
}
.campaign__tickets-counter .tickets-counter__icon {
  width: 2.4rem;
  height: 4rem;
  flex-shrink: 0;
  margin-right: 1.3rem;
}
.campaign__tickets-counter .tickets-counter__icon svg {
  width: 100%;
  height: 100%;
}
.campaign__tickets-counter .tickets-counter__text {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 700;
  color: white;
  text-align: left;
}
.campaign__tickets-counter.christmas-campaign::before {
  background: linear-gradient(19.13deg, #FAC826 -69%, #FFFACB -2.4%, #F8EEBA 11.61%, #E7D08D 39.41%, #CBA145 78.1%, #C2912E 89.92%, #EDD688 164.09%, #FFFACB 198.9%);
}
.campaign__tickets-counter.christmas-campaign::after {
  background: radial-gradient(202.09% 190.85% at 11.13% 9.15%, #B71817 0%, #791105 100%);
}
.campaign__tickets-counter.summer-campaign::after {
  background: radial-gradient(64.86% 53.9% at 67.2% 46.1%, #ED911B 0%, #D56429 100%);
}
.view-coupon .campaign__tickets-counter {
  width: 96%;
  margin: 2rem auto;
}
