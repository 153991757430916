@import (reference) "../../../../Mixins/_mixins.less";

.multi-client-wrapper.multi-client-wrapper__receipt-step {
  & > .multi-client-header {
    top: 2rem;
  }
}

.receipt-step {
  width: 100%;
  background-color: @ds-gray-100;
  display: flex;
  flex-direction: column;
  @media @sm {
    flex-direction: row;
  }

  & > div {
    flex: 1 1 50%;
    padding: 3rem 0;
    position: relative;
    @media @sm {
      padding: 6rem 0;
    }
  }

  .receipt-step__countdown {
    border-radius: 2.4rem 2.4rem 0 0;
    background-color: @ds-white;
    display: flex;
    justify-content: center;
    padding: 0 3rem;
    z-index: 1;
    @media @sm {
      border-radius: 0 2.4rem 2.4rem 0;
      padding: 0 2rem;
    }

    .receipt-step__countdown-inner {
      max-width: 42.4rem;
      margin-top: 5rem;
      @media @sm {
        margin-top: 13rem;
      }

      .multi-client__flow-step-header {
        padding-bottom: 0;
      }
    }

    .receipt-step__countdown-heading {
      font-size: 4rem;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 2.4rem;
    }

    .receipt-step__countdown-subheading {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: @ds-gray-450;
    }

    .countdown {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 3rem;
      text-align: center;
      padding: 2rem;
      @media @sm {
        padding: 3.5rem 4.2rem;
      }

      .countdown-headline {
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin: 0 0 1rem;
        @media @sm {
          font-size: 2.4rem;
          line-height: 2.6rem;
          font-weight: 700;
        }
      }

      .number-container {
        flex: 1;

        span {
          display: block;
        }

        .number {
          font-size: 5rem;
          line-height: 4.7rem;
          font-weight: 800;
          padding-bottom: 0.7rem;
          position: relative;
          @media @sm {
            font-size: 6.8rem;
            line-height: 7.8rem;
          }

          &::before {
            content: "";
            width: 0.3rem;
            background-color: fade(@ds-white, 40%);
            position: absolute;
            left: 0;
            top: 1rem;
            bottom: 1rem;
            transform: translateX(-50%);
          }
        }

        .time-denominator {
          font-size: 1rem;
          line-height: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          @media @sm {
            font-size: 1.7rem;
            line-height: 1.5rem;
          }
        }

        &:first-of-type .number::before {
          display: none;
        }
      }
    }

    .reminder-alert {
      margin: 5rem 0 0;
      font-size: 1.6rem;
      @media @sm {
        margin-bottom: -5rem;
      }

      input[type=checkbox] {
        display: none;

        &:checked + label .checkbox:after {
          content: '';
          position: absolute;
          border: 0.2rem solid @ds-black;
          border-left: 0;
          border-top: 0;
          font-size: 2rem;
          transform: rotate(45deg);
          left: 0.3em;
          top: 0.1em;
          width: 0.3em;
          height: 0.5em;
        }
      }

      label {
        background: @ds-gray-100;
        display: inline-block;
        padding: 2.5rem 2.5rem 2.5rem 6rem;
        border-radius: 2rem;
        position: relative;
        text-align: left;
        line-height: 1.5;
        user-select: none;

        .checkbox {
          background: @ds-white;
          width: 2.0rem;
          height: 2.0rem;
          left: 2.5rem;
          top: 2.6rem;
          border-radius: 0.4rem;
          content: '';
          display: inline-block;
          vertical-align: text-top;
          border: 0.1rem solid @ds-gray-400;
          position: absolute;
        }
      }

      &.reminder-alert--loading label {
        overflow: hidden;

        &:before {
          position: absolute;
          content: "";
          top: -50%;
          bottom: -50%;
          left: -200%;
          width: 3rem;
          background: fade(@ds-black, 50%);
          background: linear-gradient(90deg, fade(@ds-black, 0%) 0%, fade(@ds-black, 10%) 30%, fade(@ds-black, 50%) 50%, fade(@ds-black, 10%) 70%, fade(@ds-black, 0%) 100%);
          animation: reminder-alert--loading 1000ms linear infinite;
          transform: rotate(20deg);
        }

        &:after {
          position: absolute;
          content: "";
          top: -50%;
          bottom: -50%;
          left: -200%;
          width: 1.5rem;
          background: fade(@ds-black, 50%);
          background: linear-gradient(90deg, fade(@ds-black, 0%) 0%, fade(@ds-black, 10%) 30%, fade(@ds-black, 50%) 50%, fade(@ds-black, 10%) 70%, fade(@ds-black, 0%) 100%);
          animation: reminder-alert--loading 1000ms linear infinite;
          animation-delay: 500ms;
          transform: rotate(20deg) translateX(-2rem);
        }

        @keyframes reminder-alert--loading {
          100% {
            left: 300%;
          }
        }
      }
    }
  }

  .multi-client__countdown-item {
    margin: 3rem 0;
    opacity: 0.6;
    transition: opacity 0.15s linear;
    display: none;
    @media @sm {
      display: block;
      margin: 8rem 0 3rem;
    }

    &.multi-client__countdown-item--selected {
      opacity: 1;
      display: block;
    }

    & + .multi-client__countdown-item {
      margin-top: 3rem;
    }
  }

  .receipt-step__coupon {
    &::before {
      @media @sm {
        content: "";
        width: 3.1rem;
        height: 3.1rem;
        background-color: @ds-white;
        position: absolute;
        left: -0.3rem;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    .fireworks-animation {
      height: 50rem;
      position: absolute;
      top: 0;
      z-index: 1;
      pointer-events: none;
    }

    .view-coupon {
      width: 26.5rem;
    }
  }

  .receipt-step__coupon-promos {
    background-color: @ds-white;
    padding: 1.2rem 2.4rem 2rem;
    position: sticky;
    margin-top: 3rem;
    bottom: 0;

    &::before {
      content: "";
      width: 100%;
      height: 3rem;
      background-color: @ds-white;
      background: linear-gradient(180deg, fade(@ds-white, 0%) 0%, @ds-white 50%, @ds-white 100%);
      position: absolute;
      top: -3rem;
      left: 0;
    }

    .promo-card  {
      flex: 1;

      .promo-card__box {
        margin: 0;
      }
    }
  }

  .multi-client-wrapper__eurojackpot & {
    .receipt-step__countdown {
      .countdown {
        background-color: @eurojackpot-gold-medium;
        color: @ds-white;
      }
    }

    .receipt-step__promo-cards {
      .promo-card__box {
        background-color: @eurojackpot-gold-medium;
        color: @ds-white;
      }
    }
  }

  .multi-client-wrapper__lotto & {
    .receipt-step__countdown {
      .countdown {
        background-color: @lotto-red;
        color: @ds-white;
      }
    }

    .receipt-step__promo-cards {
      .promo-card__box {
        background-color: @lotto-red;
        color: @ds-white;
      }
    }

    .fireworks-animation {
      filter: brightness(0.1) contrast(0.5) saturate(200) hue-rotate(340deg);
    }
  }

  .multi-client-wrapper__vikinglotto & {
    .receipt-step__countdown {
      .countdown {
        background-color: @vikinglotto-blue;
        color: @ds-white;
      }
    }

    .receipt-step__promo-cards {
      .promo-card__box {
        background-color: @vikinglotto-blue;
        color: @ds-white;
      }
    }

    .fireworks-animation {
      filter: brightness(0.2) contrast(0.8) saturate(150) hue-rotate(220deg);
    }
  }

  .multi-client-wrapper__campaign-Sommerlotto & {
    .receipt-step__countdown {
      .countdown {
        background-color: @summer-lotto-orange;
        color: @ds-white;
      }
    }

    .fireworks-animation {
      filter: brightness(0.5) contrast(0.1) saturate(100) hue-rotate(340deg);
    }
  }

  .receipt-step__promo-cards {
    display: flex;
    flex-direction: row;
    @media @sm {
      flex-direction: column;
    }

    .promo-card__box {
      min-height: 16rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 6rem 4rem 1.5rem;
      box-sizing: border-box;
      border-radius: 3rem;
      margin: 3rem 0;
      text-align: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      align-items: flex-end;
      @media @sm {
        min-height: 20rem;
      }

      .promo-card__box-content {
        margin-top: auto;
        filter: drop-shadow(0 0 0.2rem @ds-black);

        .promo-card__title {
          font-size: 2.4rem;
          line-height: 2.6rem;
          font-weight: 800;
        }

        .promo-card__text {
          font-size: 1.6rem;
          line-height: 2rem;
        }

        .promo-card__title + .promo-card__text {
          margin-top: 0.8rem;
        }
      }

      .multi-client-cta-btn {
        margin: 1.6rem 0 0;
        height: 3.6rem;

        @media @sm {
          height: 5.6rem;
        }
      }
    }
  }
}

.multi-client__coupon-tabs-wrapper {
  display: flex;
  margin: 0 auto 2rem;
  position: sticky;
  top: 0;
  z-index: 2;

  @media @sm {
    margin: 0 auto 4rem;
  }

  .multi-client__coupon-tabs {
    width: ~"calc(100% - 6rem)";
    background-color: @ds-white;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    border: 1px solid #a2a2a2;
    border-radius: 28px;
    position: relative;
    @media @sm {
      width: auto;
      background-color: @ds-gray-100;
    }
  }

  .multi-client__coupon-tab {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0.8rem;
    transition: opacity 0.15s linear, color 0.15s 0.1s linear;
    cursor: pointer;
    position: relative;
    margin: 0 0.4rem;
    text-align: center;
    flex: 1;
    white-space: nowrap;

    @media @sm {
      font-size: 1.6rem;
      line-height: 1.4rem;
      padding: 1rem 2rem;
      font-weight: 800;
    }

    &:hover:not(.multi-client__coupon-tab--active) {
      opacity: 0.6;
    }

    &--active {
      color: @ds-white;
    }
  }

  .multi-client__coupon-tab-active-indicator {
    position: absolute;
    height: 100%;
    transition: width 0.15s linear, left 0.15s linear, background-color 0.15s linear;
    scale: 1.15;
    border-radius: 28px;
    left: 0;

    
    .region-lotto & {
      background-color: @ds-red;
    }
    .region-vikinglotto & {
      background-color: @ds-blue;
    }
    .region-eurojackpot & {
      background-color: @ds-honey;
    }
  }
}
