@import (reference) "Mixins/_mixins.less"; // ┬──┬﻿ ¯\_(ツ)

html {
  // Fix for IE not calculating the font-size properly
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    font-size: calc(~'1em * 0.625');
  }
}

.region-eurojackpot {
  background-color: @eurojackpot-black;
  width: 100%;
  font-size: 4vw;

  @media (min-width: @eurojackpot-desktop-width) {
    font-size: 1.4rem;
  }
  .container {
    width: 100vw;
    @media (min-width: @eurojackpot-desktop-width) {
      width: 100%;
    }
  }
  .page-box .container {
    width: 100%;
  }
}

// `eurojackpot-section` encapsulates each section entirely
// it also controls background visuals like back-
// ground images, form, and background color.
.eurojackpot-section {
  position: relative;
  // fonts
  font-style: normal;
  // background colors
  background-color: @eurojackpot-gray;
  border-color: @eurojackpot-gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &.bg-white {
    background-color: @eurojackpot-white;
    border-color: @eurojackpot-white;
  }

  &.bg-gray {
    background-color: @eurojackpot-almost-black;
    border-color: @eurojackpot-almost-black;
  }

  &.bg-deepgold {
    background-color: @eurojackpot-deepgold;
    border-color: @eurojackpot-deepgold;
  }
  &.bg-lightdeepgold {
    background-color: @eurojackpot-lightdeepgold;
    border-color: @eurojackpot-lightdeepgold;
  }

  // `eurojackpot-content-wrapper` controls layout of the
  // content for each section. It also serves as the
  // container for all components and elements.
  > .eurojackpot-content-wrapper {
    //max-width: 86.7vw;
    margin: auto;
    // centered text is a recurring theme, so we set it as a
    // default.
    text-align: center;
    //AAAAAAAAAAAAAAAAAAAAAAAAA
    .a {
      cursor: pointer;
      color: #fff;
      text-decoration: none;

      &.hd {
        text-decoration: none;
      }
    }
    // PPPPPPPPPPPPPPPPPP
    .p {
      width: 100%;
      max-width: @eurojackpot-device-width;
      margin: auto;
      color: #ffffff;
      font-size: 4vw;
      line-height: normal;

      @media (min-width: @eurojackpot-desktop-width) {
        font-size: 1.5rem;
        width: @eurojackpot-desktop-width;
      }

      &.dark {
        color: @eurojackpot-dark;

        .a {
          color: inherit;
          border-bottom: .1rem solid @eurojackpot-dark;
        }
      }
    }
    // HRHRHRHRHRHRHRHRHR
    .hr {
      width: @eurojackpot-device-width;
      border-width: .1rem;
      border-style: solid;
      border-top: 0;
      margin: 6vw auto;

      &.gold {
        border-color: @eurojackpot-gold;
      }

      @media (min-width: @eurojackpot-desktop-width) {
        margin: 2.5rem auto;
        max-width: @eurojackpot-desktop-width;
      }
    }
  }
  // eurojackpot-content-wrapper´ END
}

// eurojackpot-section´ END

.mobile-only {
  display: inherit;

  @media (min-width: @eurojackpot-desktop-width) {
    display: none;
  }
}

.mobile-hide {
  display: none;

  @media (min-width: @eurojackpot-desktop-width) {
    display: inherit;
  }
}

.box-wide {
  width: 100%;

  @media (min-width: @eurojackpot-desktop-width) {
    width: 96rem;
    margin: auto;
  }
}

.box-medium {
  width: 100%;

  @media (min-width: @eurojackpot-desktop-width) {
    width: 58rem;
    margin: auto;
  }
}

.box-half {
  width: 100%;

  @media (min-width: @eurojackpot-desktop-width) {
    width: 50%;
    margin: auto;
    float: left;
  }
}

// side-by-side wrapper is used when two or more
// eurojackpot-section´'s need to be side by side at
// particular screen/device sizes
.side-by-side {
  @media (min-width: @eurojackpot-desktop-width) {
    .eurojackpot-section {
      width: 50%;
      display: inline-block;

      &:first-child {
        text-align: right;
      }

      > .eurojackpot-content-wrapper {
        width: 100%;
        max-width: 48rem; // max width is 960
        display: inline-block;
      }
    }
  }
}
