.win-message-container {
  background-color: #000;
  padding: 4vw 14.13vw 3vw;
}
@media (min-width: 950px) {
  .win-message-container {
    padding: 3rem;
  }
}
.win-message-container .receive-winner-message-icon {
  width: 10vw;
  height: 10vw;
  margin: 0 auto 2.5vw;
}
@media (min-width: 950px) {
  .win-message-container .receive-winner-message-icon {
    width: 6.4rem;
    height: 6.4rem;
    padding: 0 0 1.5rem 0;
    margin: 0 auto;
  }
}
.win-message-container .notice-step {
  color: #FFF;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.win-message-container .notice-step .headline {
  width: 100%;
  max-width: 86.5vw;
  font-size: 5.5vw;
  line-height: normal;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .headline {
    font-size: 3rem;
    line-height: normal;
  }
}
.win-message-container .notice-step .subheadline {
  width: 100%;
  max-width: 60vw;
  font-size: 4.27vw;
  padding: 1rem 0;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .subheadline {
    max-width: 100%;
    font-size: 2.2rem;
    line-height: normal;
  }
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container {
    display: flex;
  }
}
.win-message-container .notice-step .checkbox-container .eurojackpot-checkbox {
  display: flex;
  margin: 3.34vw 0;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .eurojackpot-checkbox {
    margin: 2rem 2rem 0rem;
  }
}
.win-message-container .notice-step .checkbox-container .eurojackpot-checkbox label {
  position: relative;
}
.win-message-container .notice-step .checkbox-container .eurojackpot-checkbox label:not(.ios-skin) {
  position: relative;
  padding: 0.2vw 0 0 3.33vw;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .eurojackpot-checkbox label:not(.ios-skin) {
    padding: 0 0 0 2rem;
  }
}
.win-message-container .notice-step .checkbox-container .eurojackpot-checkbox label .media-text {
  width: auto;
  font-size: 4.27vw;
  color: #fff;
  line-height: 1.1;
  margin: 0;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .eurojackpot-checkbox label .media-text {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}
