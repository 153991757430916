html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 960px) {
  html {
    font-size: 50%;
  }
}
@media screen and (min-resolution: 2dppx) and (min-device-width: 960px) {
  html {
    font-size: 62.5%;
  }
}
html,
body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  height: auto;
  overflow-x: clip;
}
html.mode-edit,
body.mode-edit {
  height: auto;
  overflow-y: auto;
}
html.lock,
body.lock {
  overflow: clip;
}
.page {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
.mode-edit .page {
  height: auto;
  padding-top: 0 !important;
}
.page.lock {
  overflow: clip;
}
.page-box {
  position: relative;
}
.numbergames-pageeditplaceholder {
  height: 10rem;
  background-color: crimson;
  text-align: center;
  font-size: 3.5rem;
  padding-top: 3rem;
}
