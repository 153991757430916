﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.region-eurojackpot {
  // Video
  .video-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    video::-webkit-media-controls {
      display: none !important;
    }

    video::-webkit-media-controls-start-playback-button {
      display: none !important;
    }

    video {
      object-fit: inherit;
    }

    @media (min-width: @eurojackpot-desktop-width) {
      display: block;
    }
  }

  .video {
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    transition: opacity 0.5s ease-in-out;

    &.is-loading {
      opacity: 0;
    }

    &.can-playthrough {
      opacity: 1;
    }
  }
}
