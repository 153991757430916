@import (reference) '../../../../Styles/Mixins/_mixins.less';
@import url('../../../../Styles/Mixins/_button.less');

.most-winning-numbers {
  --right-image-height: 40rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  padding: 5.6rem 2.4rem;
  max-width: @content-max-width;
  margin: 0 auto;

  @media @lg {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
  }

  .responsive-bg {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: var(--right-image-height);
    }

    .responsive-lottie-bg {
      z-index: 0;
    }
  }
  
  &--eurojackpot .most-winning-numbers__select-wrapper {
    background: @eurojackpot-gold-medium;
    color: @numbergames-white;
  }
  
  &--lotto .most-winning-numbers__select-wrapper {
    background: @lotto-whitesmoke;
    color: @lotto-red;
  }
  
  &--vikinglotto .most-winning-numbers__select-wrapper {
    background: @vikinglotto-blue;
    color: @numbergames-white;
  }
}

.most-winning-numbers__text-container {
  box-sizing: border-box;
  padding-right: 0.6rem;
  margin-bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  max-width: 43rem;

  @media @lg {
    flex-basis: 50%;
    align-items: start;
  }
}

.most-winning-numbers__title {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.6rem;

  @media @lg {
    font-size: 4rem;
    line-height: 6.4rem;
  }
}

.most-winning-numbers__select-wrapper {
  position: relative;
  display: inline;
  margin-left: 1.2rem;
  border-radius: 1.6rem;
}

.most-winning-numbers__select {
  border: 0;
  cursor: pointer;
  appearance: none;
  background: inherit;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1;
  letter-spacing: 0.5px;
  padding-right: 5.7rem;
  padding-left: 1.6rem;
  color: inherit;
  border-radius: 1.6rem;

  @media @lg {
    font-size: 4rem;
    line-height: 6.4rem;
  }

  &:focus {
    outline: none;
  }
}

.most-winning-numbers__select-option {
  color: inherit;
  background: inherit;
  font-weight: inherit;
}

.most-winning-numbers__select-arrow {
  position: absolute;
  top: 0.6rem;
  right: 1.6rem;
  pointer-events: none;
  height: 2.4rem;
  width: 2.4rem;

  @media @lg {
    top: 1.5rem;
  }
}

.most-winning-numbers__text {
  color: @numbergames-blue-grey;
  font-size: 1.4rem;
  text-align: center;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  font-weight: 600;

  @media @sm {
    text-align: initial;
  }

  @media @lg {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.most-winning-numbers__lottie-wrapper {
  position: relative;
  max-width: 51rem;
  width: 100%;
  height: var(--right-image-height);
  margin-bottom: 4.8rem;

  @media @lg {
    flex-basis: 50%;
    margin-bottom: 0;
  }
}

.most-winning-numbers__loader {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 12rem;
  margin-bottom: 4.8rem;

  @media @lg {
    flex-basis: 50%;
    height: 40rem;
    margin-bottom: 0;
  }
}

.most-winning-numbers__cta {
  cursor: pointer;
  height: 5.6rem;
  min-width: 24rem;
  background-color: @ds-yellow;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @ds-black;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.1rem;

  &--desktop {
    display: none;

    @media @lg {
      display: flex;
      margin-top: 3.2rem;
    }
  }

  &--mobile {
    display: flex;

    @media @lg {
      display: none;
    }
  }
}
