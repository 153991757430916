.btn-with-x-padding {
  padding-left: 2rem;
}
@keyframes btn-pulse-inner-loading-animation {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  95%,
  100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(245, 218, 0, 0);
  }
}
@keyframes btn-pulse-loading-animation {
  38% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 0 10px rgba(245, 218, 0, 0);
  }
  65%,
  100% {
    transform: scale(1);
  }
}
.multi-client-wrapper__outer,
.multi-client-wrapper__outer-grid {
  background-color: white;
  border-radius: 3rem 3rem 0 0;
  overflow: clip;
  position: relative;
  transform: translateZ(0);
}
.multi-client-wrapper__outer .popover-drawer,
.multi-client-wrapper__outer-grid .popover-drawer {
  height: 100%;
}
.multi-client-wrapper__outer .popover-drawer__card,
.multi-client-wrapper__outer-grid .popover-drawer__card {
  height: 100%;
}
.multi-client-wrapper__outer {
  margin-top: -2.5rem;
}
.multi-client-wrapper__outer .ds-cube-loader-wrapper {
  height: 40rem;
  position: relative;
}
.multi-client-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  overflow: clip;
  position: relative;
  font-size: 1.4rem;
  transition: background-color 0.15s linear;
}
.multi-client-wrapper .multi-client-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 2rem;
  z-index: 1;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper .multi-client-header {
    padding: 0 5rem;
    top: 5rem;
  }
}
.multi-client-wrapper .multi-client__button-wrapper {
  width: 3.4rem;
  display: flex;
  pointer-events: auto;
}
.multi-client-wrapper .multi-client__button-wrapper > div {
  cursor: pointer;
}
.multi-client-wrapper .multi-client__button-wrapper .icon {
  width: 100%;
  height: 100%;
}
.multi-client-wrapper .multi-client__back-button {
  width: 2.3rem;
  height: 1.4rem;
}
.multi-client-wrapper .multi-client__question-button {
  width: 3.4rem;
  height: 3.4rem;
  transition: opacity 150ms linear;
}
.multi-client-wrapper .multi-client__question-button--hidden {
  opacity: 0;
  pointer-events: none;
}
.multi-client-wrapper .multi-client-body {
  width: 100%;
  position: relative;
  z-index: 0;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests) {
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  min-width: auto;
  color: #d4d4d4;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  color: #000000;
  transition: color 200ms ease-out;
  height: 5.6rem;
  min-width: 18rem;
  padding-left: 2rem;
  display: flex;
  padding: 0;
  margin-bottom: 3rem;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests)::after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0.1rem solid transparent;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests)::after {
  background-color: #FEB700;
  border-color: #FEB700;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests):not(.btn--disabled):not(.btn--loading):active::after {
  background-color: #f5da00;
  border-color: #f5da00;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests).btn--disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests).btn--loading {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests).btn--loading::before {
  content: "";
  border-radius: 2.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 0 0 #ffce51;
  animation: btn-pulse-inner-loading-animation 1.3s infinite;
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests).btn--loading::after {
  animation: btn-pulse-loading-animation 1.3s infinite;
}
@media screen and (min-width: 375px) {
  .multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests) {
    min-width: 21.4rem;
  }
}
.multi-client-wrapper .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests):not(.btn--disabled):hover::after {
  transform: scale(1.03);
}
.multi-client-wrapper .multi-client-games-wrapper {
  background-color: white;
}
.multi-client-wrapper .multi-client-loader-wrapper {
  width: 100%;
  height: 40rem;
  position: relative;
  margin-top: 6rem;
}
@keyframes multi-client-loader-overlay-fadeIn {
  to {
    opacity: 1;
  }
}
.multi-client-wrapper .multi-client-loader-wrapper.multi-client-loader-wrapper__as-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  animation: multi-client-loader-overlay-fadeIn 0.2s linear forwards;
  z-index: 2;
}
.multi-client-wrapper .multi-client-loader-wrapper.multi-client-loader-wrapper__as-overlay .ds-cube-loader-wrapper {
  opacity: 0;
  animation: multi-client-loader-overlay-fadeIn 0.2s 0.15s linear forwards;
}
.multi-client-wrapper .multi-client__no-open-draw {
  text-align: center;
  padding: 10rem 0;
}
.multi-client-wrapper__outer-grid .outer-grid__row {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__outer-grid .outer-grid__row {
    flex-direction: row;
  }
}
.multi-client-wrapper__outer-grid .outer-grid__column {
  display: flex;
  flex-direction: column;
}
.multi-client-wrapper__outer-grid .outer-grid--text-content {
  padding: 0 3rem 5rem;
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__outer-grid .outer-grid--text-content {
    padding-top: 5rem;
  }
}
.multi-client-wrapper__outer-grid .outer-grid--text-content p + p {
  margin-top: 0px;
}
.multi-client-wrapper__outer-grid .outer-grid--multi-client {
  max-width: 100%;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .multi-client-wrapper__outer-grid .outer-grid--multi-client {
    width: 62rem;
  }
  .multi-client-wrapper__outer-grid .outer-grid--multi-client .multi-client-rotary-selector {
    font-size: 2.8px;
  }
}
.multi-client-wrapper__outer-grid .multi-client-wrapper__outer {
  border-radius: 0;
  margin-top: 0;
}
.multi-client-wrapper__outer-grid-receipt {
  display: none;
  min-height: 30rem;
  flex-direction: column;
  align-items: center;
}
.multi-client-wrapper__outer-grid--has-added-hoejpulje-to-basket .multi-client-wrapper__outer-grid-receipt {
  display: flex;
}
.multi-client-wrapper__outer-grid--has-added-hoejpulje-to-basket {
  padding: 6rem 2rem 0 !important;
}
.multi-client-wrapper__outer-grid--has-added-hoejpulje-to-basket .outer-grid__row {
  display: none;
}
.multi-client-wrapper__outer-grid-receipt-title {
  color: #000000;
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  line-height: 1.21;
  margin: 0 auto 2rem;
  text-align: center;
  padding: 0 2rem;
}
.multi-client-wrapper__outer-grid-receipt-note {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
}
.multi-client-wrapper__outer-grid-receipt-note img {
  height: 14rem;
  margin-bottom: 2rem;
  max-width: 100%;
}
.multi-client-wrapper--has-added-hoejpulje-to-basket .check-button,
.multi-client-wrapper--has-added-hoejpulje-to-basket .multi-client-cta-btn:not(.use-tailwind-styling-class-only-for-automatic-tests) {
  opacity: 0.5;
  pointer-events: none;
}
.multi-client-wrapper--has-added-hoejpulje-to-basket .multi-client__progress-bar,
.multi-client-wrapper--has-added-hoejpulje-to-basket .multi-client__back-button {
  display: none;
}
