.eurojackpot-lightning {
  position: relative;
  text-align: center;
}
.eurojackpot-lightning .eurojackpot-section {
  background-color: #1f1f1f;
  padding-top: 3vw;
}
.eurojackpot-lightning .eurojackpot-section .eurojackpot-content-wrapper .game-not-available-wrapper {
  padding: 14vw 0;
}
@media (min-width: 950px) {
  .eurojackpot-lightning .eurojackpot-section .eurojackpot-content-wrapper .game-not-available-wrapper {
    padding: 0 0 5rem;
  }
}
.eurojackpot-lightning .eurojackpot-section .eurojackpot-content-wrapper .game-not-available-wrapper img {
  width: 17.33vw;
  height: 17.33vw;
  margin-bottom: 2vw;
  margin-top: -8vw;
}
@media (min-width: 950px) {
  .eurojackpot-lightning .eurojackpot-section .eurojackpot-content-wrapper .game-not-available-wrapper img {
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }
}
.eurojackpot-lightning .headline {
  font-size: 10.666vw;
  font-weight: bold;
  color: #fff;
  margin: 0;
}
@media (min-width: 768px) {
  .eurojackpot-lightning .headline {
    font-size: 4rem;
  }
}
.eurojackpot-lightning .subheadline {
  font-size: 3.733vw;
  color: #fff;
  padding-top: 1vw;
  margin: 0;
}
@media (min-width: 768px) {
  .eurojackpot-lightning .subheadline {
    font-size: 2rem;
  }
}
.eurojackpot-lightning .subheadline.not-available {
  padding-bottom: 9vw;
}
@media (min-width: 950px) {
  .eurojackpot-lightning .subheadline.not-available {
    padding-bottom: 5rem;
  }
}
.eurojackpot-lightning .tagline {
  font-size: 3.733vw;
  color: #fff;
  padding-top: 3vw;
  margin: 0;
  font-weight: normal;
  margin-bottom: 9vw;
}
@media (min-width: 950px) {
  .eurojackpot-lightning .tagline {
    font-size: 2rem;
  }
}
.eurojackpot-lightning .eurojackpot-slider-selector {
  width: 100%;
  text-align: center;
  margin: 6.5vw auto 4vw;
  position: relative;
}
@media (min-width: 950px) {
  .eurojackpot-lightning .eurojackpot-slider-selector {
    width: 58rem;
    margin: 5rem auto 5rem;
  }
}
.eurojackpot-lightning .eurojackpot-slider-selector .number {
  text-transform: uppercase;
}
