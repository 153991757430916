.multi-columns-wrapper {
  display: flex;
  margin-bottom: 1rem;
}
.multi-columns-wrapper .multi-columns-wrapper__column {
  flex: 1;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .multi-columns-wrapper .multi-columns-wrapper__column {
    padding: 1.2rem;
  }
}
