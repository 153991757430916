﻿@import url("../../Mixins/_mixins.less");

.statistics-module {
  .statistics-module-wrapper {
    .statistics-module-period-filter-wrapper {
      font-size: 0;
      overflow: hidden;
      transition: background-color .15s linear;

      &.opened {
        .period-filter-inner-wrapper {
          .period-filter-content-wrapper {
            .period-filter-header {
              @media (min-device-width: @desktop-width) {
                margin-bottom: 1rem;
              }
            }

            .period-filter-row {
              width: 100%;
              max-height: 30vw;
              padding: 3vw 0 0 30vw;
              opacity: 1;
              overflow: hidden;
              box-sizing: border-box;

              @media (min-device-width: @desktop-width) {
                max-height: 6rem;
                padding: 1rem 0 .5rem 2rem;
                overflow: inherit;
              }

              &:last-child {
                padding-bottom: 1.5rem;
              }
            }
          }
        }
      }

      .period-filter-inner-wrapper {
        margin: auto;

        @media (min-device-width: @desktop-width) {
          width: 96rem;
          display: flex;
        }

        .period-filter-toggle-buttons-wrapper {
          .period-filter-toggle-button {
            width: 50vw;
            padding: 3.035vw 4vw;
            box-sizing: border-box;
            float: left;

            @media (min-device-width: @desktop-width) {
              width: 15rem;
              padding: 1.1rem 0 .9rem;
              float: none;
            }

            &:hover {
              @media (min-device-width: @desktop-width) {
                cursor: pointer;
              }
            }

            .icon {
              width: 5vw;
              height: 5vw;

              @media (min-device-width: @desktop-width) {
                width: 2rem;
                height: 2.1rem;
                margin: -.1rem 0;
              }
            }

            .text {
              position: relative;
              font-size: 3.2vw;
              padding-left: 3vw;
              top: -1vw;

              @media (min-device-width: @desktop-width) {
                font-size: 1.4rem;
                padding-left: .5rem;
                top: -.4rem;
              }
            }
          }
        }

        .period-filter-content-wrapper {
          width: 100%;

          .period-filter-header {
            width: 100%;
            box-sizing: border-box;
            transition: margin .15s linear;

            @media (min-device-width: @desktop-width) {
              height: 4.2rem;
              font-size: 1.6rem;
              padding: 1rem 0 1rem 2rem;
            }

            .filter-text-wrapper {
              @media (min-device-width: @desktop-width) {
                width: 61rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                float: left;
              }
            }
          }

          .period-filter-row {
            max-height: 0;
            position: relative;
            opacity: 0;
            transition: max-height .15s linear, opacity .15s linear, padding-top .15s linear, padding-bottom .15s linear;

            @media (min-device-width: @desktop-width) {
              padding: 0 0 0 2rem;
            }

            .filter-row-name {
              font-size: 3.4vw;
              padding: 1vw 0;
              position: absolute;
              left: 5vw;

              @media (min-device-width: @desktop-width) {
                font-size: 1.4rem;
                padding: .4rem 0;
                left: 0;
                transform: translateX(-100%);
              }

              .filter-row-reset {
                width: 3vw;
                height: 3vw;
                font-size: 0;
                padding-right: 1vw;
                position: absolute;
                top: 1.7vw;
                left: 0;
                opacity: 0;
                transform: translateX(-100%);
                transform-origin: center;
                transition: transform .15s linear, opacity .15s linear;

                @media (min-device-width: @desktop-width) {
                  width: 1.2rem;
                  height: 1.2rem;
                  padding-right: .5rem;
                  top: .7rem;
                }

                &.active {
                  opacity: 1;

                  &:hover {
                    @media (min-device-width: @desktop-width) {
                      cursor: pointer;
                      transform: translateX(-100%);
                    }

                    .icon {
                      @media (min-device-width: @desktop-width) {
                        transform: rotate(45deg);
                      }
                    }
                  }
                }

                .icon {
                  width: 100%;
                  height: 100%;
                  transition: transform .15s linear;
                  will-change: transform;

                }
              }
            }

            .filter-row-options {
              .option {
                font-size: 3vw;
                padding: 1vw 1vw;
                margin-right: 1vw;
                margin-bottom: 1vw;
                display: inline-block;
                border: 1px solid #777;
                border-radius: 6px;
                background-color: #f9f9f9;
                transition: color .15s linear, background-color .15s linear, opacity .15s linear;

                @media (min-device-width: @desktop-width) {
                  font-size: 1.2rem;
                  padding: .5rem .8rem;
                  margin-right: .5rem;
                  margin-bottom: .5rem;
                  box-sizing: border-box;
                  cursor: pointer;
                }

                &:hover {
                  @media (min-device-width: @desktop-width) {
                    background-color: #c9ccd0;
                  }
                }

                .filter-icon {
                  width: 4vw;
                  height: 4vw;
                  margin: -1vw 0;
                  transition: fill .15s linear;
                  fill: #4f5254;

                  @media (min-device-width: @desktop-width) {
                    height: 2rem;
                    width: 2rem;
                    margin: -.5rem 0;
                  }
                }

                &.active {
                  color: #f9f9f9;
                  background-color: #777;

                  .filter-icon {
                    fill: #fff;
                  }
                }

                &.inactive {
                  opacity: .5;
                  cursor: default;

                  &.active {
                    &:hover {
                      @media (min-device-width: @desktop-width) {
                        background-color: #777;
                      }
                    }
                  }

                  &:hover {
                    @media (min-device-width: @desktop-width) {
                      background-color: #f9f9f9;
                    }
                  }
                }

                &.filter-desc-button, &.filter-normal-button, &.filter-asc-button {
                  padding: 1vw 3vw;

                  @media (min-device-width: @desktop-width) {
                    padding: .5rem 1.5rem;
                  }
                }

                &.filter-desc-button {
                  margin-right: 0;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-right: none;
                }

                &.filter-normal-button {
                  margin-right: 0;
                  border-radius: 0;
                }

                &.filter-asc-button {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-left: none;
                }
              }
            }
          }
        }
      }

      .period-filter-game-client-dropdown {
        width: 50vw;
        display: inline-block;
        text-align: center;
        font-size: 0;
        float: right;

        @media (min-device-width: @desktop-width) {
          width: 16rem;
          margin-top: -1rem;
        }

        &.active {
          .game-client-dropdown-list {
            transform: scaleY(1);
          }
        }

        .game-client-dropdown-placeholder {
          font-size: 3.2vw;
          padding: 3.867vw 4vw;
          cursor: pointer;
          text-align: left;
          background-color: rgba(0,0,0,.3);
          position: relative;
          transition: background-color .15s linear;

          @media (min-device-width: @desktop-width) {
            font-size: 1.6rem;
            padding: 1rem 2rem;
          }

          &:hover {
            @media (min-device-width: @desktop-width) {
              background-color: rgba(0,0,0,.5);
            }
          }

          .icon {
            width: 3.2vw;
            height: 3vw;
            position: absolute;
            right: 3vw;
            top: 5vw;

            @media (min-device-width: @desktop-width) {
              width: 1.1rem;
              height: 1rem;
              margin-left: 1rem;
              top: 1.8rem;
              right: 2rem;
            }
          }


          &:after {
            content: '';
            width: 200vw;
            background: rgba(0,0,0,.5);
            position: absolute;
            left: -100vw;

            @media (min-device-width: @desktop-width) {
              height: .1rem;
              margin-top: 3.2rem;
            }
          }
        }

        .game-client-dropdown-list {
          width: 50vw;
          position: absolute;
          border-radius: 0 0 6px 6px;
          border: 1px solid #dcdcdc;
          border-top: none;
          overflow: hidden;
          box-sizing: border-box;
          background-color: #fff;
          transform-origin: top;
          transform: scaleY(0);
          transition: transform .2s linear;
          z-index: 2;

          @media (min-device-width: @desktop-width) {
            width: 16rem;
          }

          .game-client-dropdown-list-item {
            font-size: 3.2vw;
            padding: 3.867vw 4vw;
            cursor: pointer;
            border-top: 1px solid #dcdcdc;
            transition: background-color .15s linear;

            @media (min-device-width: @desktop-width) {
              font-size: 1.6rem;
              padding: .5rem 3.3rem;
            }

            &:hover {
              background-color: #eee;
            }
          }
        }
      }
    }
  }
}
