/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/**
 * Colors for Subscription
 */
/**
 * Primary
 */
/**
 * Text
 * Mobile font sizes have been halved
 */
/**
 * Buttons
 * Mobile font sizes have been halved
 */
.content-size-xs,
.content-size-sm,
.content-size-md,
.content-size-lg,
.content-size-full {
  width: 100%;
  margin: 0 auto 2rem;
}
.content-size-xs {
  max-width: 42rem;
}
.content-size-sm {
  max-width: 48rem;
}
.content-size-md {
  max-width: 52rem;
}
.content-size-lg {
  max-width: 60rem;
}
.content-size-full {
  max-width: none;
}
.content-size-extra-top {
  margin-top: 1rem !important;
}
.content-size-less-top {
  margin-top: -1rem !important;
}
.content-size-extra-bottom {
  margin-bottom: 3rem !important;
}
.content-size-less-bottom {
  margin-bottom: 1rem !important;
}
:root {
  --multiStepsBannerBackgroundColor: #1c1c1c;
}
:root .region-vikinglotto,
:root .region-avalon-vikinglotto {
  --multiStepsBannerBackgroundColor: #0000ff;
}
:root .region-allornothing,
:root .region-avalon-allornothing {
  --multiStepsBannerBackgroundColor: #191339;
}
:root .region-eurojackpot,
:root .region-avalon-eurojackpot {
  --multiStepsBannerBackgroundColor: #151515;
}
:root .region-keno,
:root .region-avalon-keno {
  --multiStepsBannerBackgroundColor: #00a5eb;
}
:root .region-lotto,
:root .region-avalon-lotto {
  --multiStepsBannerBackgroundColor: #c50005;
}
:root .region-playtogether,
:root .region-avalon-playtogether {
  --multiStepsBannerBackgroundColor: #094366;
}
:root .region-quick,
:root .region-avalon-quick {
  --multiStepsBannerBackgroundColor: #7f4488;
}
:root .region-subscription,
:root .region-avalon-subscription {
  --multiStepsBannerBackgroundColor: #2e3535;
}
.multi-steps-banner {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  height: auto;
  justify-content: center;
  align-content: center;
  display: flex;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.multi-steps-banner__wrapper {
  background-color: var(--multiStepsBannerBackgroundColor, #1c1c1c);
  border-radius: 3rem;
  padding: 3rem 2rem;
  display: inline-block;
  width: 100%;
  margin: 0 2rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .multi-steps-banner__wrapper {
    padding: 3rem;
    margin: 0 5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1216px) {
  .multi-steps-banner__wrapper {
    display: flex;
    padding: 0;
    width: 100%;
    text-align: center;
    max-width: 1440px;
    margin: 0 5rem;
  }
}
.multi-steps-banner__step {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .multi-steps-banner__step {
    width: 33.3%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1216px) {
  .multi-steps-banner__step {
    width: 0;
    flex: 1 1 0;
    padding: 5rem 0;
    margin-top: 0;
  }
}
.multi-steps-banner__img {
  width: 10rem;
  height: 10rem;
  margin-bottom: 1.2rem;
}
.multi-steps-banner__text {
  font-size: 1.6rem;
  line-height: 2.8rem;
  padding: 0 1rem;
  color: #FFFFFF;
  font-weight: 700;
  overflow-wrap: break-word;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .multi-steps-banner__text {
    padding: 0 4rem;
  }
}
.multi-steps-banner__text a {
  color: #FFFFFF;
  text-decoration: underline;
}
