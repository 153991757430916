/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.winning-numbers-winner-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 2rem;
  border-style: hidden;
  box-shadow: 0 0 0 0.2rem #f4f4f4 inset;
  margin-bottom: 2rem;
  color: #000000;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.05rem;
  overflow: hidden;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table {
    font-size: 1.6rem;
    margin-bottom: auto;
  }
}
.winning-numbers-winner-table__header {
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.1rem;
}
.winning-numbers-winner-table__header th {
  color: white;
  padding: 1.8rem 1.5rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table__header th {
    padding: 2.4rem 3rem;
  }
}
.winning-numbers-winner-table__header th:first-child {
  border-top-left-radius: 2rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table__header th:first-child {
    text-align: left;
  }
}
.winning-numbers-winner-table__header th:last-child {
  border-top-right-radius: 2rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table__header th:last-child {
    text-align: right;
  }
}
.winning-numbers-winner-table__header th:nth-child(2) {
  padding-left: 0;
  padding-right: 0;
}
.winning-numbers-winner-table--lotto .winning-numbers-winner-table__header th {
  background-color: #c50005;
}
.winning-numbers-winner-table--vikinglotto .winning-numbers-winner-table__header th {
  background-color: #0000ff;
}
.winning-numbers-winner-table--eurojackpot .winning-numbers-winner-table__header th {
  background-color: #000000;
}
.winning-numbers-winner-table--joker .winning-numbers-winner-table__header th {
  background-color: #009a60;
}
.winning-numbers-winner-table__row td {
  padding: 2rem 1.6rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table__row td {
    padding: 2.3rem 3rem;
  }
}
.winning-numbers-winner-table__row td:first-child {
  text-align: left;
}
.winning-numbers-winner-table__row td:nth-child(2) {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.winning-numbers-winner-table__row td:last-child {
  text-align: right;
  padding-bottom: 2.3rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table__row td:last-child {
    padding-bottom: 2.6rem;
  }
}
.winning-numbers-winner-table__row:nth-child(2) td {
  padding-top: 2.3rem;
}
@media screen and (min-width: 1440px) {
  .winning-numbers-winner-table__row:nth-child(2) td {
    padding-top: 2.6rem;
  }
}
.winning-numbers-winner-table--lotto .winning-numbers-winner-table__row:nth-child(even) {
  background-color: rgba(197, 0, 5, 0.05);
}
.winning-numbers-winner-table--vikinglotto .winning-numbers-winner-table__row:nth-child(even) {
  background-color: rgba(0, 0, 255, 0.05);
}
.winning-numbers-winner-table--eurojackpot .winning-numbers-winner-table__row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.winning-numbers-winner-table--joker .winning-numbers-winner-table__row:nth-child(even) {
  background-color: rgba(0, 154, 96, 0.07);
}
