@import (reference) "../../Mixins/_mixins.less";

@blurFactor: 3px;

@keyframes generic-modal-fade-in-animation {
  100% {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(@blurFactor);
  }
}

@keyframes generic-modal-fade-out-animation {
  100% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
  }
}

@keyframes generic-modal-card-slide-in-animation {
  100% {
    opacity: 1;
    transform: translateY(-50%);
  }
}

@keyframes generic-modal-card-slide-out-animation {
  100% {
    opacity: 0;
    transform: translateY(0%);
  }
}

.generic-modal-active {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.generic-modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  backdrop-filter: blur(0);


  .generic-modal__loading {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;
    margin: auto;
    transform: translateY(-50%);
  }

  .generic-modal__card {
    width: 90%;
    max-width: 64rem;
    min-height: 20rem;
    max-height: 80%;
    background-color: @ds-white;
    border-radius: 1.6rem;
    opacity: 0;
    margin: auto;
    padding: 4rem 1.2rem 2.4rem 1.2rem;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(0%);
    overflow: clip;
    animation-duration: 0.15s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    @media @sm {
      padding: 5rem 2.4rem 2.4rem 2.4rem;
    }
  }

  .generic-modal__close-button {
    background-color: transparent;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    padding: 1.4rem;
    border: none;
    line-height: 0;

    @media @sm {
      right: 2.4rem;
    }


    &::before {
      content: '';
      background-color: @ds-gray-100;
      border-radius: 50%;
      transition: transform 0.15s linear;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .icon-x {
      width: 1rem;
      height: 1rem;
      display: block;
    }

    &:hover {
      @media @sm {
        &::before {
          transform: scale(1.1);
        }
      }
    }
  }

  &.generic-modal--fade-in {
    animation-name: generic-modal-fade-in-animation;

    .generic-modal__card {
      animation-delay: 0.25s;
      animation-name: generic-modal-card-slide-in-animation;
    }
  }

  &.generic-modal--fade-out {
    animation-delay: 0.25s;
    animation-name: generic-modal-fade-out-animation;

    .generic-modal__card {
      animation-name: generic-modal-card-slide-out-animation;
    }
  }

  &.generic-modal--active {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;
    backdrop-filter: blur(@blurFactor);

    .generic-modal__card {
      transform: translateY(-50%);
      opacity: 1;
    }
  }
}

.multi-banner-spot__modal {
  .generic-modal {
    .generic-modal__card {
      padding: 1.8rem 1.4rem 1.4rem 1.4rem;

      @media @sm {
        padding: 2.8rem 2.4rem 2.4rem 2.4rem;
      }
    }

    .generic-modal__close-button {
      z-index: 1;
      top: 2.4rem;
      right: 2rem;

      @media @sm {
        top: 3.4rem;
        right: 3.2rem;
      }
    }
  }
}
