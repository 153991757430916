@import url("../../Mixins/_mixins.less");

.region-eurojackpot {
  .how-to-play-section {
    .how-to-play-content-wrapper {
      background-color: @eurojackpot-carbon;

      .headline {
        color: @numbergames-white;
      }

      .text {
        color: @numbergames-white;
      }

      .video-headline {
        color: @numbergames-white;
      }

      .video-element {
        .video-frame {
          background: @eurojackpot-gold;
        }

        .video-play-button {
          background: transparent;
          border-radius: unset;
          background-image: url(/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#square-shape-grey_use);

          @media screen and (-webkit-min-device-pixel-ratio:2) {
            background-image: url(/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons/square-shape-grey.svg);
          }

          .play-button {
            border-color: transparent transparent transparent @eurojackpot-gray;
          }
        }
      }
    }
  }
}
