@import (reference) "../../../AvalonComponents/Styles/Colors/_colors";
@import (reference) "../../../AvalonComponents/Styles/Variables/breakpoints";

// Defaults
@eurojackpot-desktop-width: 950px;
@eurojackpot-mobile-width: 768px;
@eurojackpot-tablet-width: 1024px;
@eurojackpot-device-width: 86.5vw;

// Colors
@eurojackpot-black: #000;
@eurojackpot-almost-black: #0a0a0a;
@eurojackpot-dark: #111111;
@eurojackpot-slate: #111111;
@eurojackpot-carbon: #2E3535;
@eurojackpot-red: pink;

@eurojackpot-gray: #1f1f1f;
@eurojackpot-darkgray: #171717;
@eurojackpot-button-gray: #4f5254;
@eurojackpot-mediumgray: #616161;
@eurojackpot-lightgray: #8e8e8e;
@eurojackpot-lightgrayyellow: #817247;
@eurojackpot-button-lightgray: #d1d2d5;

@eurojackpot-superlightgray: #d7d7d7;

@eurojackpot-darksmoke: #201e20;
@eurojackpot-whitesmoke: #f2f2f2;
@eurojackpot-white: #fff;

@eurojackpot-gold-light: #ecbb47;
@eurojackpot-gold: #E8A332;
@eurojackpot-graygold: #74653c;
@eurojackpot-lightgold: #514830;
@eurojackpot-darkgold: #595036;
@eurojackpot-deepgold: #3b372a;
@eurojackpot-brightgold: #635839;
@eurojackpot-lightdeepgold: #47412f;

@eurojackpot-dark-yellow: #feb700;
@eurojackpot-cta-yellow: #feb700;
@eurojackpot-cta-yellow-light: #fff8e5;
@eurojackpot-cta-yellow-hover: #f5d900;

@eurojackpot-btn-color: #fff;
@eurojackpot-btn-color-hover: #e0f4fd;

.text-shadow (@string: 0 .1rem .3rem rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}
.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow:    @string;
  box-shadow:         @string;
}
.drop-shadow (@x: 0, @y: .1rem, @blur: .2rem, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:		@x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.inner-shadow (@x: 0, @y: .1rem, @blur: .2rem, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:    inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:         inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing:    @type;
  box-sizing:         @type;
}

.border-radius (@radius: .5rem) {
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  border-radius:         @radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}
.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius:    @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius:  @bottomleft;
  -webkit-border-top-left-radius:     @topleft;

  -moz-border-radius-topright:        @topright;
  -moz-border-radius-bottomright:     @bottomright;
  -moz-border-radius-bottomleft:      @bottomleft;
  -moz-border-radius-topleft:         @topleft;

  border-top-right-radius:            @topright;
  border-bottom-right-radius:         @bottomright;
  border-bottom-left-radius:          @bottomleft;
  border-top-left-radius:             @topleft;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: 	@opacity;
  -moz-opacity: 		@opacity;
  opacity: 		@opacity;
}

.gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}
.horizontal-gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
}

.animation (@name) {
  -webkit-animation: @name;
  -moz-animation:    @name;
  -ms-animation:	   @name;
  animation:		   @name;
}

.keyframes(@name; @arguments) {
  @-webkit-keyframes @name { @arguments(); }
  @-moz-keyframes @name { @arguments(); }
  @-ms-keyframes @name { @arguments(); }
  @keyframes @name { @arguments(); }
}

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition:    @transition;
  -ms-transition:     @transition;
  -o-transition:      @transition;
  transition:      @transition;
}
.transform (@string){
  -webkit-transform: @string;
  -moz-transform: 	 @string;
  -ms-transform: 		 @string;
  -o-transform: 		 @string;
  transform: 		 @string;
}
.transitionWithTransform (@transition) {
  -webkit-transition: e("-webkit-@{transition}");
  -moz-transition:    e("-moz-@{transition}");
  -ms-transition:     e("-ms-@{transition}");
  -o-transition:      e("-o-@{transition}");
  transition:			@transition;
}
.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: 	 scale(@factor);
  -ms-transform: 		 scale(@factor);
  -o-transform: 		 scale(@factor);
}
.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: 	 rotate(@deg);
  -ms-transform: 		 rotate(@deg);
  -o-transform: 		 rotate(@deg);
  transform: 		 rotate(@deg);
}
.skew (@deg, @deg2) {
  -webkit-transform:       skew(@deg, @deg2);
  -moz-transform: 	 skew(@deg, @deg2);
  -ms-transform: 		 skew(@deg, @deg2);
  -o-transform: 		 skew(@deg, @deg2);
}
.translate (@x, @y:0) {
  -webkit-transform:       translate(@x, @y);
  -moz-transform: 	 translate(@x, @y);
  -ms-transform: 		 translate(@x, @y);
  -o-transform: 		 translate(@x, @y);
}
.translate3d (@x, @y: 0, @z: 0) {
  -webkit-transform:       translate3d(@x, @y, @z);
  -moz-transform: 	 translate3d(@x, @y, @z);
  -ms-transform: 		 translate3d(@x, @y, @z);
  -o-transform: 		 translate3d(@x, @y, @z);
}
.perspective (@value: 1000) {
  -webkit-perspective: 	@value;
  -moz-perspective: 	@value;
  -ms-perspective: 	@value;
  perspective: 		@value;
}
.transform-origin (@x:center, @y:center) {
  -webkit-transform-origin: @x @y;
  -moz-transform-origin:    @x @y;
  -ms-transform-origin:     @x @y;
  -o-transform-origin:      @x @y;
  transform-origin:      @x @y;
}

.transform-style(@option) {
  -webkit-transform-style:@option;
  -moz-transform-style:@option;
  -ms-transform-style:@option;
  transform-style: @option;
}

.blur(@value){
  -webkit-filter: blur(@value);
  -moz-filter: blur(@value);
  -ms-filter: blur(@value);
  filter: blur(@value);
}

.user-select (@option) {
  -webkit-user-select:@option;
  -khtml-user-select:@option;
  -moz-user-select:@option;
  -ms-user-select:@option;
  user-select:@option;
}

.clearfix() {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
