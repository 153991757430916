.multi-client__flow-step-header {
  padding: 0 0 3rem;
  text-align: center;
  position: relative;
}
.multi-client__flow-step-title {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 700;
  padding-bottom: 1.4rem;
}
@media screen and (min-width: 768px) {
  .multi-client__flow-step-title {
    font-size: 4rem;
    line-height: 3.4rem;
    padding-bottom: 2.4rem;
  }
}
.multi-client__flow-step-title span {
  display: inline-block;
}
.multi-client__flow-step-subtitle {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: #92969E;
}
@media screen and (min-width: 768px) {
  .multi-client__flow-step-subtitle {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}
.multi-client__flow-step-subtitle strong {
  font-weight: 800;
  color: #000000;
}
.multi-client__flow-step-description {
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 768px) {
  .multi-client__flow-step-description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}
.multi-client__flow-step-description a {
  color: inherit;
}
.multi-client__client-wrapper {
  display: flex;
}
.multi-client__client-wrapper .multi-client__client-game,
.multi-client__client-wrapper .multi-client__flow-wrapper {
  width: 100%;
  flex-shrink: 0;
}
.multi-client__client-wrapper .multi-client__client-game {
  padding-top: 11rem;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.15s linear, opacity 0.1s linear;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .multi-client__client-wrapper .multi-client__client-game {
    padding-top: 10rem;
  }
}
.multi-client__client-wrapper .multi-client__client-game .multi-client-rotary-selector .selector-numbers .number-wrapper.in-view .number {
  pointer-events: none;
}
.multi-client__client-wrapper .multi-client__client-game.active {
  max-height: max-content;
  opacity: 1;
  pointer-events: all;
}
.multi-client__client-wrapper .multi-client__client-game.active .multi-client-rotary-selector .selector-numbers .number-wrapper.in-view .number {
  pointer-events: all;
}
.multi-client__client-wrapper .multi-client__client-game .multi-client__flow-step-header {
  padding: 1rem 3rem 1rem;
}
@media screen and (min-width: 768px) {
  .multi-client__client-wrapper .multi-client__client-game .multi-client__flow-step-header {
    padding: 5rem 3rem 1rem;
  }
}
.multi-client__client-wrapper .multi-client__client-game .multi-client-rotary-selector {
  margin-bottom: 1rem;
}
.multi-client__client-wrapper .multi-client__client-game .multi-client-rotary-selector__outer + .multi-client__client-game-description {
  width: 25rem;
  position: relative;
  margin-top: -7.4rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}
.multi-client__client-wrapper .multi-client__client-game .eurojackpot-games-subnavigation-bar,
.multi-client__client-wrapper .multi-client__client-game .lotto-games-subnavigation-bar,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-games-subnavigation-bar {
  display: none;
}
.multi-client__client-wrapper .multi-client__client-game .eurojackpot-classic-game .rows-container,
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .rows-container,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .rows-container {
  margin-top: 0;
}
.multi-client__client-wrapper .multi-client__client-game .shake-overlay-wrapper {
  display: none;
}
@media screen and (min-width: 768px) {
  .multi-client__client-wrapper .multi-client__client-game .tabs-container {
    width: calc(100% - 28vw);
    padding-right: 14vw;
  }
}
@media screen and (min-width: 950px) {
  .multi-client__client-wrapper .multi-client__client-game .tabs-container {
    width: calc(100% - 14rem);
    padding-right: 7rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .multi-client__client-wrapper .multi-client__client-game .lotto-game-tabs-wrapper .rows-container {
    width: 80.21%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .multi-client__client-wrapper .multi-client__client-game .vikinglotto-game-tabs-wrapper .rows-container {
    width: 71.3%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .multi-client__client-wrapper .multi-client__client-game .eurojackpot-game-tabs-wrapper .rows-container {
    width: 82%;
  }
}
.multi-client__client-wrapper .multi-client__client-game .multi-client__client-game-description {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #92969E;
  text-align: center;
  margin: 0 auto 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}
.multi-client__client-wrapper .multi-client__client-game .client-game-description__question-button {
  width: 4rem;
  height: 4rem;
  color: #000000;
  position: relative;
  top: 0.2rem;
  cursor: pointer;
}
.multi-client__client-wrapper .multi-client__client-game .client-game-description__question-button .icon {
  width: 100%;
  height: 100%;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .left-gradient,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .left-gradient {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .right-gradient,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .right-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
}
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .lotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .lotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .vikinglotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .vikinglotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .vikinglotto-game-tabs-wrapper .tabs-container .inner-wrapper,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .vikinglotto-game-tabs-wrapper .tabs-container .inner-wrapper {
  color: #282828;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-footer,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-footer,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-footer,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-footer {
  border-top-color: transparent;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header {
  border-bottom-color: #a60004;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .row-header {
  border-bottom-color: #0000ff;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-game-tabs-wrapper .tabs-container,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-game-tabs-wrapper .tabs-container {
  background-color: white;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell .front-face,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell .front-face,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell .front-face,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell .front-face {
  background-color: #eecece;
  color: #a60004;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell .back-face,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell .back-face,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell .back-face,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell .back-face {
  background-color: #a60004;
  color: white;
}
@keyframes multiclient-lotto-flip-face-animation {
  0% {
    color: #a60004;
    background-color: #eecece;
  }
  35% {
    color: #a60004;
    background-color: #eecece;
  }
  65% {
    color: #a60004;
    background-color: #eecece;
  }
  80% {
    color: white;
    background-color: #a60004;
  }
  100% {
    color: white;
    background-color: #a60004;
  }
}
@keyframes multiclient-lotto-reverse-flip-face-animation {
  0% {
    color: white;
    background-color: #a60004;
  }
  35% {
    color: white;
    background-color: #a60004;
  }
  65% {
    color: white;
    background-color: #a60004;
  }
  80% {
    color: #a60004;
    background-color: #eecece;
  }
  100% {
    color: #a60004;
    background-color: #eecece;
  }
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation {
  animation-name: multiclient-lotto-flip-face-animation;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation {
  animation-name: multiclient-lotto-reverse-flip-face-animation;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section {
  background-color: white;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .row-header,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .row-header,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .row-header {
  color: #a60004;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header .header-rules-text,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .row-header .header-rules-text,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .row-header .header-rules-text,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .row-header .header-rules-text {
  color: #a60004;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .row-header .right-side .row-delete-button .icon {
  fill: #a60004;
}
.multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .number-picker-footer .buttons-container .button {
  background-color: #a60004;
  color: white;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .numbers-vikings-separator,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .numbers-vikings-separator,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .numbers-vikings-separator,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .numbers-vikings-separator {
  background-color: #E6E6FE;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .icon-viking.white,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .icon-viking.white,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .icon-viking.white,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .icon-viking.white {
  filter: brightness(0) saturate(100%) invert(90%) sepia(85%) saturate(5600%) hue-rotate(180deg) brightness(100%) contrast(113%);
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .number,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .number,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .number,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .number {
  color: #0000ff;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .cell .front-face,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .cell .front-face,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .cell .front-face,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .cell .front-face {
  background-color: #E6E6FE;
  color: #0000ff;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .cell .back-face,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .cell .back-face,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .cell .back-face,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .cell .back-face {
  background-color: #0000ff;
  color: white;
}
@keyframes multiclient-vikinglotto-flip-face-animation {
  0% {
    color: #0000ff;
    background-color: #E6E6FE;
  }
  35% {
    color: #0000ff;
    background-color: #E6E6FE;
  }
  65% {
    color: #0000ff;
    background-color: #E6E6FE;
  }
  80% {
    color: white;
    background-color: #0000ff;
  }
  100% {
    color: white;
    background-color: #0000ff;
  }
}
@keyframes multiclient-vikinglotto-reverse-flip-face-animation {
  0% {
    color: white;
    background-color: #0000ff;
  }
  35% {
    color: white;
    background-color: #0000ff;
  }
  65% {
    color: white;
    background-color: #0000ff;
  }
  80% {
    color: #0000ff;
    background-color: #E6E6FE;
  }
  100% {
    color: #0000ff;
    background-color: #E6E6FE;
  }
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation {
  animation-name: multiclient-vikinglotto-flip-face-animation;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation {
  animation-name: multiclient-vikinglotto-reverse-flip-face-animation;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .vikinglotto-section,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .vikinglotto-section,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .vikinglotto-section {
  background-color: white;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .row-header,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .vikinglotto-section .row-header,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .vikinglotto-section .row-header,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .vikinglotto-section .row-header {
  color: #0000ff;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .row-header .header-rules-text,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .vikinglotto-section .row-header .header-rules-text,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .vikinglotto-section .row-header .header-rules-text,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .vikinglotto-section .row-header .header-rules-text {
  color: #0000ff;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .row-header .right-side .row-delete-button .icon,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .vikinglotto-section .row-header .right-side .row-delete-button .icon,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .vikinglotto-section .row-header .right-side .row-delete-button .icon,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .vikinglotto-section .row-header .right-side .row-delete-button .icon {
  fill: #0000ff;
}
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-classic-game .vikinglotto-section .number-picker-footer .buttons-container .button,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-system-game .vikinglotto-section .number-picker-footer .buttons-container .button,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-lucky-game .vikinglotto-section .number-picker-footer .buttons-container .button,
.multi-client__client-wrapper .multi-client__client-game .vikinglotto-winning-numbers .vikinglotto-section .number-picker-footer .buttons-container .button {
  background-color: #0000ff;
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header {
  border-bottom-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .number-picker-footer {
  border-top-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title {
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
  color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item.active {
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
  fill: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-system-game-header,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-system-game-header {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-system-game-header .game-navigation-item,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-system-game-header .game-navigation-item {
  color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-system-game-header .game-navigation-item.active,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-system-game-header .game-navigation-item.active {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-system-game-header .game-navigation-item.active .tab-navigation-icon,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-system-game-header .game-navigation-item.active .tab-navigation-icon {
  fill: white;
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .game-switch-wrapper,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .game-switch-wrapper {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .game-switch-wrapper .switch-button.next,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .game-switch-wrapper .switch-button.next {
  background: linear-gradient(to right, rgba(233, 88, 1, 0) 0%, #E95801 100%);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .game-switch-wrapper .switch-button.prev,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .game-switch-wrapper .switch-button.prev {
  background: linear-gradient(to right, #E95801 0%, rgba(233, 88, 1, 0) 100%);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-game-tabs-wrapper,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-game-tabs-wrapper {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active {
  background-color: rgba(255, 255, 255, 0.3);
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active .tab-navigation-icon,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active .tab-navigation-icon {
  fill: white;
  color: white;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-content-wrapper .rows-container:before,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-content-wrapper .rows-container:before {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-content-wrapper .rows-container .row-container .row-header:before,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-content-wrapper .rows-container .row-container .row-header:before {
  background-color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell .front-face,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell .front-face,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell .front-face,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell .front-face {
  background-color: #fcdfce;
  color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell .back-face,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell .back-face,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell .back-face,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell .back-face {
  background-color: #E95801;
  color: white;
}
@keyframes multiclient-summer-lotto-flip-face-animation {
  0% {
    color: #a60004;
    background-color: #fcdfce;
  }
  35% {
    color: #a60004;
    background-color: #fcdfce;
  }
  65% {
    color: #a60004;
    background-color: #fcdfce;
  }
  80% {
    color: white;
    background-color: #a60004;
  }
  100% {
    color: white;
    background-color: #a60004;
  }
}
@keyframes multiclient-summer-lotto-reverse-flip-face-animation {
  0% {
    color: white;
    background-color: #a60004;
  }
  35% {
    color: white;
    background-color: #a60004;
  }
  65% {
    color: white;
    background-color: #a60004;
  }
  80% {
    color: #a60004;
    background-color: #fcdfce;
  }
  100% {
    color: #a60004;
    background-color: #fcdfce;
  }
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation {
  animation-name: multiclient-summer-lotto-flip-face-animation;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation {
  animation-name: multiclient-summer-lotto-reverse-flip-face-animation;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .row-header,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .row-header,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .row-header {
  color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header .header-rules-text,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .row-header .header-rules-text,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .row-header .header-rules-text,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .row-header .header-rules-text {
  color: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .row-header .right-side .row-delete-button .icon,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .row-header .right-side .row-delete-button .icon {
  fill: #E95801;
}
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-classic-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-system-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-lucky-game .lotto-section .number-picker-footer .buttons-container .button,
.multi-client-wrapper__campaign-Sommerlotto .multi-client__client-wrapper .multi-client__client-game .lotto-winning-numbers .lotto-section .number-picker-footer .buttons-container .button {
  background-color: #E95801;
}
.multi-client__flow-wrapper {
  display: flex;
  z-index: 2;
  transition: transform .15s linear;
}
.multi-client__flow-step {
  flex: 0 0 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  position: relative;
  opacity: 0;
  transition: opacity 0.15s linear, background-color 0.15s linear;
}
.multi-client__flow-step:not(.multi-client__flow-step-receipt) {
  padding-top: 8rem;
}
@media screen and (min-width: 768px) {
  .multi-client__flow-step:not(.multi-client__flow-step-receipt) {
    padding-top: 14rem;
  }
}
.multi-client__flow-step.multi-client__flow-step--active {
  opacity: 1;
}
.multi-client__flow-step .multi-client__flow-step-inner {
  width: 100%;
  max-width: 72rem;
  padding: 0 3rem;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .multi-client__flow-step .multi-client__flow-step-inner {
    padding: 0;
  }
}
.multi-client__flow-step-pre-client {
  width: 100%;
  flex-shrink: 0;
  z-index: 1;
}
.multi-client__flow-step-pre-client:not(.multi-client__flow-step-pre-client--active) {
  display: none;
}
.multi-client__flow-step-pre-client.multi-client__flow-step-pre-client--active + .multi-client__client-game {
  display: none;
}
@media print {
  .top-spot-page-header,
  .eurojackpot-top-spot-responsive-frame,
  .lotto-winning-numbers-spot,
  .lotto-top-spot,
  .vikinglotto-top-spot,
  .vikinglotto-winning-numbers-spot,
  .multi-client-header,
  .multi-client__flow-step-header,
  .multi-client-footer,
  .eurojackpot-winning-numbers-spot,
  .plus-sign-up,
  .plus-sign-up__content,
  .promotion-spot,
  .package-game,
  .two-column-spot,
  .time-consuming-notification-static-fix {
    visibility: hidden;
    display: none !important;
  }
  .multi-client__client-wrapper .multi-client__client-game {
    padding-top: 0;
  }
}
