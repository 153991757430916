.multi-banner-spot {
  --button-line-gradient: linear-gradient(239deg, #00ffae -55.52%, #027d56 2.15%, #01291c 106.16%);
  position: relative;
}
.multi-banner-spot--eurojackpot .responsive-bg {
  background-color: #000000;
}
.multi-banner-spot--lotto .responsive-bg {
  background-color: #c50005;
}
.multi-banner-spot--vikinglotto .responsive-bg {
  background-color: #0000ff;
}
.multi-banner-spot[data-variant='variant-7'] .responsive-bg {
  background-color: transparent;
}
.multi-banner-spot.multi-banner-spot--top + #multi-client {
  margin-top: -3.5rem;
}
.multi-banner-spot.multi-banner-spot--banner {
  max-width: 1280px;
  padding: 3.2rem 2rem;
  margin: 0 auto;
  overflow: hidden;
  z-index: 0;
}
.multi-banner-spot.multi-banner-spot--banner .multi-banner-spot-content-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot.multi-banner-spot--banner {
    padding: 6rem 2rem;
  }
}
.multi-banner-spot.multi-banner-spot--banner .responsive-bg {
  max-width: 1280px;
  margin: 0 auto;
  border-radius: 4rem;
  overflow: hidden;
  top: 2rem;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
}
.multi-banner-spot .responsive-bg picture {
  width: 100%;
  height: 100%;
}
.multi-banner-spot .responsive-bg picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.multi-banner-spot .multi-banner-spot__lottie {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.multi-banner-spot .multi-banner-spot__lottie .multi-banner-spot__lottie-player {
  position: absolute;
  top: 0;
  width: 500rem;
  height: 100%;
}
.multi-banner-spot .multi-banner-spot-content {
  color: var(--multi-banner-spot__color, white);
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 2rem 7rem;
  font-size: 1.2rem;
  line-height: 1.3;
  letter-spacing: 0.1rem;
  font-weight: 800;
}
@media screen and (min-width: 1440px) {
  .multi-banner-spot .multi-banner-spot-content {
    font-size: 2rem;
    padding: 4rem 2rem 9rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content--with-footer {
  padding: 2rem 2rem 10rem;
}
@media screen and (min-width: 1440px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content--with-footer {
    padding: 4rem 2rem 12rem;
  }
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__logo {
  margin-bottom: 2.4rem;
  max-width: 100%;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__title {
  opacity: 0.8;
  margin-bottom: 0.7rem;
}
@media screen and (min-width: 1440px) {
  .multi-banner-spot .multi-banner-spot-content .multi-banner-spot__title {
    margin-bottom: 1.2rem;
  }
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__big-text {
  font-size: 7.6rem;
  line-height: 1;
  letter-spacing: 0.2rem;
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 1440px) {
  .multi-banner-spot .multi-banner-spot-content .multi-banner-spot__big-text {
    font-size: 8rem;
  }
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__count-down .multi-banner-spot__count-down-content-wrapper {
  display: flex;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__count-down .multi-banner-spot__count-down-label {
  opacity: 0.8;
  margin-right: 0.5rem;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:not(:last-child) {
  position: relative;
  padding-right: 1rem;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 0.1rem;
  top: 0.3rem;
  bottom: 0.3rem;
  right: 0.5rem;
  background-color: var(--multi-client-top-spot__color, white);
  opacity: 0.5;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__count-down .multi-banner-spot__count-down-flashy-text--dimmed {
  opacity: 0.8;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__cta {
  cursor: pointer;
  height: 5.6rem;
  min-width: 24rem;
  background-color: #FEB700;
  border-radius: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000000;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: 2.4rem;
}
.multi-banner-spot .multi-banner-spot-content .multi-banner-spot__variant5-image {
  height: 100%;
  width: 100%;
  max-width: 1280px;
  object-fit: contain;
  padding: 0 2.4rem;
  box-sizing: border-box;
}
.multi-banner-spot .multi-banner-spot-content .hidden {
  display: none !important;
}
.multi-banner-spot.multi-banner-spot--banner .multi-banner-spot-content {
  padding: 3.2rem 2rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot.multi-banner-spot--banner .multi-banner-spot-content {
    padding: 6rem 2rem;
  }
}
.multi-banner-spot .multi-banner-spot__footer {
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  height: 3rem;
  width: 100%;
  background: white;
  border-radius: 3rem 3rem 0 0;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__title {
  opacity: 1;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__big-text {
  display: none;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down {
  margin-bottom: 1.3rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-content-wrapper {
  justify-content: center;
  flex-wrap: wrap;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-label {
  width: 100%;
}
@media screen and (min-width: 1440px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-label {
    font-size: 1.6rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time {
  font-size: 4.4rem;
  letter-spacing: normal;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span {
  position: relative;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:not(:last-child) {
  padding-right: 3rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 0.1rem;
  top: 1.2rem;
  bottom: 0.9rem;
  right: 1.4rem;
  background-color: var(--multi-client-top-spot__color, white);
  opacity: 0.5;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span::before {
  text-transform: uppercase;
  position: absolute;
  bottom: -1.3rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:not(:last-child)::before {
  padding-right: 3rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:first-child::before {
  content: 'Dage';
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:nth-child(2)::before {
  content: 'Timer';
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:nth-child(3)::before {
  content: 'Min.';
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-3 .multi-banner-spot__count-down .multi-banner-spot__count-down-time span:nth-child(4)::before {
  content: 'Sek.';
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-6 .multi-banner-spot__title {
  display: none;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-6 .multi-banner-spot__big-text {
  font-size: 4rem;
  max-width: 50rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-6 .multi-banner-spot__count-down {
  display: none;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-6 .multi-banner-spot__cta {
  margin-top: 1.2rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 {
  text-transform: none;
  padding: 0;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__title {
  color: var(--multi-banner-spot__color, #000000);
  font-size: 2.8rem;
  font-weight: 700;
  line-height: normal;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__title {
    font-size: 4rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__sub-title {
  color: #828282;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  margin-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__sub-title {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 4.8rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__big-text,
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__count-down {
  display: none;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__video-thumbnail-wrapper {
  position: relative;
  width: 100%;
  height: 32rem;
  overflow: hidden;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 110rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__icon-gradient {
  display: flex;
  position: absolute;
  top: auto;
  left: auto;
  height: 4.8rem;
  width: 4.8rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--button-line-gradient);
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__icon-gradient {
    height: 8.8rem;
    width: 8.8rem;
    top: calc(50% - 4.4rem);
    left: calc(50% - 4.4rem);
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__play-icon {
  height: 1.6rem;
  width: 1.6rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__play-icon {
    height: 2.4rem;
    width: 2.4rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__thumbnail {
  display: block;
  width: 100%;
  height: 100%;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-7 .multi-banner-spot__thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 {
  align-items: start;
  padding: 2rem 4rem 4.8rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 * {
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 {
    padding: 13rem 6.4rem 4.8rem 11rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8.multi-banner-spot-content--with-footer {
  padding: 2rem 4rem 7.8rem;
}
@media screen and (min-width: 1440px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8.multi-banner-spot-content--with-footer {
    padding: 13rem 6.4rem 7.8rem 11rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__title {
  order: 2;
  opacity: 1;
  font-size: 2rem;
  line-height: 2.2rem;
  width: 100%;
  text-align: left;
  margin-top: 3rem;
  box-sizing: border-box;
  text-transform: none;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__title {
    width: initial;
    text-align: initial;
    line-height: 3.2rem;
    font-size: 2.4rem;
    margin-top: auto;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__sub-title {
  order: 3;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.2rem;
  width: 100%;
  text-align: left;
  margin-bottom: 4.2rem;
  padding: 0 0 2rem 0;
  box-sizing: border-box;
  margin-top: -0.75rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__sub-title {
    width: initial;
    text-align: initial;
    margin-bottom: 7.2rem;
    margin-top: inherit;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__big-text {
  order: 1;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: initial;
  box-sizing: border-box;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__big-text {
    font-size: 4rem;
    line-height: 4.8rem;
    max-width: 48rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__secondary-call-to-action {
  position: absolute;
  bottom: 5.2rem;
  right: 3.2rem;
  order: 5;
  background: white;
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 2.4rem;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__secondary-call-to-action {
    width: 22rem;
    height: 5.6rem;
    border-radius: 3.2rem;
    right: 6.4rem;
  }
}
@media screen and (min-width: 950px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__secondary-call-to-action {
    right: 14rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8.multi-banner-spot-content--with-footer .multi-banner-spot__secondary-call-to-action {
  bottom: 7.8rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__secondary-call-to-action-inner-play-icon {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  order: 5;
  background: var(--button-line-gradient);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__secondary-call-to-action-inner-play-icon {
    top: 0.8rem;
    left: 0.8rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__play-icon {
  position: absolute;
  top: 1.1rem;
  left: 1.3rem;
  height: 1.6rem;
  width: 1.6rem;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot_secondary-call-to-action-text {
  display: none;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot_secondary-call-to-action-text {
    display: block;
    width: 13.4rem;
    text-transform: none;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: .05rem;
    position: absolute;
    top: 1.2rem;
    left: 5.6rem;
    text-align: left;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot_secondary-call-to-action-text b {
  font-weight: 800;
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__cta {
  order: 4;
  min-width: 22rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__cta {
    min-width: 24rem;
  }
}
.multi-banner-spot .multi-banner-spot-content.multi-banner-spot-content-variants--variant-8 .multi-banner-spot__video-teaser {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  display: flex;
  object-fit: cover;
}
.multi-banner-spot__modal .generic-modal__card {
  max-width: 110rem;
  max-height: 90%;
  width: 96%;
  padding: 2.4rem 0.8rem 2.4rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot__modal .generic-modal__card {
    width: 90%;
    padding: 2.8rem 2.4rem 2.4rem 2.4rem;
  }
}
.multi-banner-spot__modal .generic-modal__close-button {
  right: 1.2rem;
}
@media screen and (min-width: 768px) {
  .multi-banner-spot__modal .generic-modal__close-button {
    right: 2.4rem;
  }
}
.multi-banner-spot__video {
  width: 100%;
  max-height: 70vh;
}
