﻿@import "../_imports";
@import "../Mixins/mixins";

.tutorial__hero {
  background-color: var(--tutorial-primary);
  position: relative;

  &--disable {
    display: none;
  }
  
  .tutorial__hero-arrow {
    background-color: @ds-white;
    border-radius: 50%;
    height: 4.1rem;
    width: 4.2rem;
    margin-left: 1.6rem;
    position: absolute;
    top: 5rem;
    box-shadow: 0.2rem 1.5rem 1.5rem 0.2rem rgba(0, 0, 0, 0.15);

    @media @sm {
      margin-left: 6.8rem;
    }

    @media @lg {
      top: 3rem;
    }

    &::after {
      content: "";
      width: 1.75rem;
      height: 1.75rem;
      border-bottom: 0.4rem solid @ds-black;
      border-right: 0.4rem solid @ds-black;
      border-radius: 10%;
      display: block;
      transform: rotate(135deg);
      position: absolute;
      top: 30%;
      left: 35%;
    }
  }

  .tutorial__hero-title {
    .hero-title();
  }

  @media @lg {
    padding: 2.9rem;
  }
}
