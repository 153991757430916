@import (reference) "./Mixins/_mixins.less";
.multi-columns-wrapper {
  display: flex;
  margin-bottom: 1rem;

  .multi-columns-wrapper__column {
    flex: 1;
    box-sizing: border-box;
    @media @sm {
      padding: 1.2rem;
    }
  }
}