.multi-packages-custom-package-flow__inner-section {
  margin: auto;
  text-align: center;
  padding: 2.4rem 0;
}
@media screen and (min-width: 768px) {
  .multi-packages-custom-package-flow__inner-section {
    padding: 3rem 2rem;
  }
}
.multi-packages-custom-package-flow__inner-section .multi-packages-package-card__outer {
  display: inline-block;
  margin: auto;
}
.multi-packages-custom-package-flow__inner-section .multi-packages-package-card {
  width: 32.6rem;
  overflow: clip;
}
.multi-packages-custom-package-flow__inner-section .multi-packages-package-card__footer {
  background-color: #eeeeee;
  background: linear-gradient(0deg, #eeeeee 0%, #eeeeee 80%, rgba(238, 238, 238, 0) 100%);
}
.multi-packages-custom-package-flow__inner-section-heading {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: 2.4rem;
}
@media screen and (min-width: 768px) {
  .multi-packages-custom-package-flow__inner-section-heading {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.multi-packages-custom-package-flow__inner-section-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: #959595;
  margin-bottom: 2.4rem;
}
@media screen and (min-width: 768px) {
  .multi-packages-custom-package-flow__inner-section-text {
    font-size: 1.6rem;
  }
}
.multi-packages-custom-package-flow__joker-icon {
  width: 10.4rem;
  height: 5rem;
  margin-bottom: 2.2rem;
}
.multi-packages-custom-package-flow__back-button {
  position: absolute;
  top: 2rem;
  left: 2.4rem;
  padding: 1rem;
  cursor: pointer;
}
.multi-packages-custom-package-flow__back-button-icon {
  width: 2.4rem;
  height: 1.2rem;
}
.custom-package__games-options {
  display: flex;
  gap: 1.4rem;
  flex-direction: column;
}
.custom-package__games-options-item {
  background-color: white;
  padding: 0.8rem;
  border-radius: 16px;
  display: flex;
  gap: 1.6rem;
  text-align: left;
}
.multi-packages-packages-list .custom-package__games-options-item {
  cursor: pointer;
}
.custom-package__games-options-item--disabled {
  background-color: #eeeeee;
  border: 1px dashed #dbdbdb;
  cursor: not-allowed;
}
.custom-package__games-options-item-badge {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 16px;
}
.custom-package__games-options-item-details {
  padding-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-right: auto;
}
.multi-packages-coupon-card__actions-buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 2.2rem;
}
.multi-packages-coupon-card__actions-buttons::before {
  content: "";
  height: 1.6rem;
  width: 100%;
  position: absolute;
  top: -1.6rem;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%);
}
.multi-packages-coupon-card__actions-buttons .multi-packages-coupon-card__action-button {
  margin: 0;
}
.multi-packages-package-card__coupon-card:hover .multi-packages-coupon-card__actions-buttons {
  transform: translateY(0);
}
.multi-packages-coupon-card__action-button {
  border-radius: 50%;
  background-color: #FEB700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
}
.multi-packages-coupon-card__action-button:hover .multi-packages-coupon-card__action-button-icon {
  transform: scale(1.2);
}
.multi-packages-coupon-card__action-button .multi-packages-coupon-card__action-button-icon {
  width: 1.8rem;
  height: 1.8rem;
  transition: transform 0.15s ease-in-out;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center, center;
}
.custom-package__games-options-game-logo--lotto {
  width: 7.5rem;
  height: 1.6rem;
}
.custom-package__games-options-game-logo--eurojackpot {
  width: 9.2rem;
  height: 1.4rem;
}
.custom-package__games-options-game-logo--vikinglotto {
  width: 11rem;
  height: 2rem;
  margin-bottom: -0.3rem;
}
.custom-package__games-options-game-heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}
.custom-package__games-options-game-subheading {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.6rem;
  text-transform: uppercase;
}
.custom-package-flow__joker-selection {
  max-width: 72rem;
  margin: auto;
  display: grid;
  grid-gap: 1.2rem;
  margin-bottom: 2.4rem;
  padding: 0 2.4rem;
}
@media screen and (min-width: 768px) {
  .custom-package-flow__joker-selection {
    padding: 0;
    grid-gap: 2.4rem;
    grid-template-columns: 50% 50%;
  }
}
.custom-package-flow__joker-selection .check-button {
  background-color: rgba(0, 154, 100, 0.1);
  color: #009a64;
}
.custom-package-flow__joker-selection .check-button .check-button__dot {
  border-color: rgba(0, 154, 100, 0.1);
}
.custom-package-flow__joker-selection .check-button::before {
  background-color: #009a64;
}
.custom-package-flow__joker-selection .check-button .check-button__dot::after {
  background-color: #009a64;
}
.custom-package-flow__joker-selection .check-button.check-button--selected {
  color: white;
}
.custom-package-flow__joker-selection .check-button.check-button--selected .check-button__dot {
  border-color: rgba(0, 154, 100, 0.5);
}
.custom-package-flow__footer .multi-packages-button {
  width: fit-content;
  min-width: 24rem;
  margin: auto;
}
