@import "../Mixins/Variables";

.content-size-xs,
.content-size-sm,
.content-size-md,
.content-size-lg,
.content-size-full {
  width: 100%;
  margin: 0 auto 2rem;
}

.content-size-xs {
  max-width: 42rem;
}

.content-size-sm {
  max-width: 48rem;
}

.content-size-md {
  max-width: 52rem;
}

.content-size-lg {
  max-width: 60rem;
}

.content-size-full {
  max-width: none;
}

.content-size-extra-top {
  margin-top: 1rem !important;
}

.content-size-less-top {
  margin-top: -1rem !important;
}

.content-size-extra-bottom {
  margin-bottom: 3rem !important;
}

.content-size-less-bottom {
  margin-bottom: 1rem !important;
}
